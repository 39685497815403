import InfiniteScroll from 'infinite-scroll';
import { getBaseUrl } from '../../../core/endpoint';
import { getParentByClassname } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';
import { assigned } from '../../../utils/helper';


export class TwcInfiniteScroll extends TWebComponent {
    IsInitialized: boolean;
    infScroll: any; // es gibt leider (noch) keine TypeScript Typen hierfür -> https://github.com/metafizzy/infinite-scroll/issues/876

    
    override initComponent() {
        super.initComponent();
    
        this.classtype = 'TwcInfiniteScroll';
        this.IsInitialized = false;
        this.initScrolling();    
    }

    isInsideModal(): boolean {
        return assigned(getParentByClassname(document.getElementById(this.obj.dataset.compid), 'modal-body'));
    }

    isInsideSidebar(): boolean {
        return assigned(getParentByClassname(document.getElementById(this.obj.dataset.compid), 'sidebar-section'));
    }

    initScrolling() {
        // wir dürfen uns nur einmal initialisieren
        if (this.IsInitialized) {
            return;
        }

        // wenn wir in einer sidebar-section sind, nehmen wir dessen Scrollbalken, ansonsten den vom window (default)
        let scroll;
        if (this.isInsideSidebar())
            scroll = '.sidebar-section';
        else if (this.isInsideModal())
            scroll = '.modal-body';
        else
            scroll = false;

        // https://infinite-scroll.com/options.html
        this.infScroll = new InfiniteScroll('#' + this.obj.dataset.compid, {
            path: getBaseUrl(this.obj.dataset.compid) + '&page={{#}}',
            append: '.infinite-scroll-item',
            status: '.infinite-scroll-page-load-status',
            checkLastPage: '.infinite-scroll-item',
			responseBody: 'text',
            loadOnScroll: true,
            history: false,
            historyTitle: false,
            prefill: true,
            elementScroll: scroll,
        });
        this.IsInitialized = true;
    }

    writeProperties(key: string, value: string): void {
        // nothing
    }
}
