﻿import { Bounds, Point, SizeDefinition } from '../../../classes/geometry';
import { WebCompEventHandlerAsync } from '../../../core/communication';
import { assigned } from '../../../utils/helper';
import { TCustomActionGroup } from './Actions/TCustomActionGroup';
import { TEdgeExtraPointsAction } from './Actions/TEdgeExtraPointsAction';
import { TNodePositionAction } from './Actions/TNodePositionAction';
import { TNodeSizeAction } from './Actions/TNodeSizeAction';
import { TwsProcessEditorCustom } from './class.web.comp.process.editor.custom';
import { TEdgeBase } from './Edges/TEdgeBase';
import { TBaseShape } from './Shapes/TBaseShape';
import { getIDSuffix, SVGNS } from './Utils/TProcessEditorIDUtils';

export enum gridCellElement {
    gcBackground = 'background',
    gcTop = 'top',
    gcRight = 'right',
    gcBottom = 'bottom',
    gcLeft = 'left',
}

export class TwsProcessEditorCluster extends TwsProcessEditorCustom {

    /**
     * The SizeDefinitions of the virtual cluster grid.
     */
    virtualGridSize: SizeDefinition;
    virtualGridPadding: number;
    /**
     * The cells of the virtual cluster grid 
     * @remarks format: [col][row]
     */
    gridCellArray: Array<Array<SizeDefinition>>;

    override initComponent() {
        super.initComponent();

        let virtualSizeX = parseInt(this.obj.dataset.virtualGridSizeX ?? '10');
        let virtualSizeY = parseInt(this.obj.dataset.virtualGridSizeY ?? '2');
        this.virtualGridSize = { width: virtualSizeX, height: virtualSizeY };
        this.virtualGridPadding = 2 // Padding bei Clustermodus; 1 ist unschön wegen halber Kästchen und Rundungen
        this.gridCellArray = [];
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Cluster.AlignShape':
                let shapeAsBounds = shape => new Bounds(shape.x, shape.y, shape.x + shape.w, shape.y + shape.h);;

                let data = params.split('#');
                let shapeId = data[0];
                let cell: Point = null;

                if (data.length === 2) {
                    let locations = data[1].split(';');
                    cell = new Point(parseInt(locations[0]), parseInt(locations[1]));
                }

                let shape = this.objectById(shapeId) as TBaseShape;
                if (!assigned(shape)) {
                    return;
                }

                // ab hier wissen wir, dass das Shape valid ist.
                this.beginUpdate();
                try {
                    let oldBounds = shapeAsBounds(shape);
                    // erstmal das Shape in eine Zelle platzieren

                    // Todo: Freie Zelle finden
                    let newPos: Bounds = null;
                    if (assigned(cell)) {
                        newPos = this.snapShapeToCell(shape, 0, 0);
                    }
                    else {
                        newPos = this.snapShapeToUsedGrid(shape);
                    }

                    if (!assigned(newPos)) {
                        console.debug('No position assigned.');
                        return;
                    }

                    let targetBounds = new Bounds(newPos.left, newPos.top, newPos.right, newPos.bottom);
                    if (!targetBounds.equals(oldBounds)) {
                        let actionGroup = new TCustomActionGroup(null, this);
                        let moveAction = new TNodePositionAction(shape, this);
                        moveAction.setValue(newPos.left - shape.x, newPos.top - shape.y);
                        actionGroup.actions.push(moveAction);

                        if (shape.isResizePossible) {
                            let resizeAction = new TNodeSizeAction(shape, this);
                            resizeAction.setValue(newPos.right - newPos.left, newPos.bottom - newPos.top);
                            actionGroup.actions.push(resizeAction);
                        }

                        // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
                        actionGroup.performAction();

                        this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                            let edge = obj as TEdgeBase;

                            let edgeAction = new TEdgeExtraPointsAction(edge, this);
                            let newPoints = edge.recalcExtraPoints();

                            edgeAction.setValue(newPoints);
                            edgeAction.performAction(); // jetzt die neuen Kanten anwenden
                            actionGroup.actions.push(edgeAction);
                        });

                        // und senden
                        this.actionList.addClientAction(actionGroup);
                    }
                }
                finally {
                    this.endUpdate();
                }
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    override renderGrids() {
        super.renderGrids();

        if (this.gridCellArray.length < 1 || this.gridCellArray[0].length < 1) {
            return;
        }

        // initial margin for [0][0]
        let xpos = this.sizeMarginLeft * this.gridSize.width;
        let ypos = this.sizeMarginTop * this.gridSize.height;

        let registerEvents = false;

        this.gridCellArray.forEach(
            (array: Array<SizeDefinition>, col) => {
                array.forEach(
                    (cell: SizeDefinition, row) => {

                        function createGridcellElement(editor: TwsProcessEditorCluster, gridElement: gridCellElement): SVGPathElement {
                            let element = document.getElementById(getIDSuffix(editor.svgID, `${col}-${row}-${gridElement}`)) as unknown as SVGPathElement;

                            if (!assigned(element)) {
                                element = document.createElementNS(SVGNS, 'path');
                                element.setAttribute('id', getIDSuffix(editor.svgID, `${col}-${row}-${gridElement}`));
                                editor.editorSVG.insertBefore(element, editor.backgroundImage.nextSibling);
                                registerEvents = true;
                            }

                            return element;
                        }

                        function getGridcellElementPath(gridElement: gridCellElement): string {
                            let path = '';

                            // Fallunterscheidung:
                            switch (gridElement) {
                                case gridCellElement.gcBackground:
                                    path = [
                                        // kompletter Rahmen
                                        'M', xpos, ypos,
                                        'L', xpos + cell.width, ypos,
                                        'L', xpos + cell.width, ypos + cell.height,
                                        'L', xpos, ypos + cell.height,
                                        'Z',
                                    ].join(' ');
                                    break;
                                case gridCellElement.gcTop:
                                    path = [
                                        'M', xpos, ypos,
                                        'L', xpos + cell.width, ypos,
                                    ].join(' ');
                                    break;
                                case gridCellElement.gcRight:
                                    path = [
                                        'M', xpos + cell.width, ypos,
                                        'L', xpos + cell.width, ypos + cell.height,
                                    ].join(' ');
                                    break;
                                case gridCellElement.gcBottom:
                                    path = [
                                        'M', xpos, ypos + cell.height,
                                        'L', xpos + cell.width, ypos + cell.height,
                                    ].join(' ');
                                    break;
                                case gridCellElement.gcLeft:
                                    path = [
                                        'M', xpos, ypos,
                                        'L', xpos, ypos + cell.height,
                                    ].join(' ');
                                    break;
                            }

                            return path;
                        }

                        function setGridcellAttributes(editor: TwsProcessEditorCluster, gridCell: SVGPathElement, gridElement: gridCellElement) {
                            gridCell.setAttribute('d', getGridcellElementPath(gridElement));
                            // nur Background
                            if (gridElement === gridCellElement.gcBackground) {
                                gridCell.setAttribute('fill', editor.backgroundColor.hex());
                                gridCell.setAttribute('stroke-opacity', '0');
                                if (registerEvents) {
                                    gridCell.addEventListener('contextmenu', event => {
                                        event.preventDefault();

                                        let pt = new Point(parseInt(gridCell.dataset.x), parseInt(gridCell.dataset.y));
                                        WebCompEventHandlerAsync('OnContextMenu', editor.id, null, `${Math.round(pt.x)};${Math.round(pt.y)}`);
                                    });
                                }
                            }
                            // style attributes for dashed lines
                            // gridCell.setAttribute('stroke-dasharray', '6');
                            // gridCell.setAttribute('stroke', editor.backgroundColor.darken(0.1).hex());
                            gridCell.setAttribute('stroke', setGridelementStrokeColor(editor, gridElement));
                            gridCell.setAttribute('stroke-width', '2');
                            gridCell.setAttribute('data-col', String(col));
                            gridCell.setAttribute('data-row', String(row));
                            gridCell.setAttribute('data-x', String(xpos));
                            gridCell.setAttribute('data-y', String(ypos));
                            gridCell.classList.add('cs-gridcell');
                            gridCell.classList.add(getIDSuffix('cs-gridcell', gridElement));

                            if (registerEvents) {
                                setGridcellEvents(editor, gridCell);
                            }
                        }

                        function setGridelementStrokeColor(editor: TwsProcessEditorCluster, gridElement: gridCellElement): string {
                            switch (gridElement) {
                                // todo DCy: bei dunklen Farben wollen wir den Kontrast erhöhen (-> 0.2)
                                case gridCellElement.gcBackground:
                                    return editor.backgroundColor.hex();
                                case gridCellElement.gcTop:
                                    return editor.backgroundColor.lighten(0.1).hex();
                                case gridCellElement.gcRight:
                                    return editor.backgroundColor.darken(0.1).hex();
                                case gridCellElement.gcBottom:
                                    return editor.backgroundColor.darken(0.1).hex();
                                case gridCellElement.gcLeft:
                                    return editor.backgroundColor.lighten(0.1).hex();
                                default:
                                    return editor.backgroundColor.hex();
                            }
                        }

                        function setGridcellEvents(editor: TwsProcessEditorCluster, gridCell: SVGPathElement) {
                            gridCell.addEventListener('mousedown', event => {
                                editor.clearSelectedElements();

                                // Und noch die selektierten Elemente übergeben
                                editor.notifySelectedElementsChanged();
                            });
                            gridCell.addEventListener('mouseenter', event => {
                                gridCell.style.fill = editor.backgroundColor.darken(0.05).hex();
                            });
                            gridCell.addEventListener('mouseleave', event => {
                                gridCell.style.fill = editor.backgroundColor.hex();
                            });

                            registerEvents = false;
                        }

                        // elements of a cell
                        let cellBackground = createGridcellElement(this, gridCellElement.gcBackground);
                        let cellTop = createGridcellElement(this, gridCellElement.gcTop);
                        let cellRight = createGridcellElement(this, gridCellElement.gcRight);
                        let cellBottom = createGridcellElement(this, gridCellElement.gcBottom);
                        let cellLeft = createGridcellElement(this, gridCellElement.gcLeft);

                        setGridcellAttributes(this, cellBackground, gridCellElement.gcBackground);
                        setGridcellAttributes(this, cellTop, gridCellElement.gcTop);
                        setGridcellAttributes(this, cellRight, gridCellElement.gcRight);
                        setGridcellAttributes(this, cellBottom, gridCellElement.gcBottom);
                        setGridcellAttributes(this, cellLeft, gridCellElement.gcLeft);

                        ypos += cell.height + this.getGridPadding().height;
                    });
                // reset y position after completing a row
                ypos = this.sizeMarginTop * this.gridSize.height;
                // increment x position by the set width
                xpos += this.gridCellArray[col][0].width + this.getGridPadding().width;
            });

        // delete elements
        let cells = this.editorSVG.querySelectorAll(`.cs-grid-cell`);
        cells.forEach(cell => {
            // cols
            if (Number(cell.getAttribute('data-col')) >= this.gridCellArray.length) {
                if (assigned(cell)) {
                    this.editorSVG.removeChild(cell);
                    return;
                }
            }
            // rows
            if (this.gridCellArray.length > 0)
                if (Number(cell.getAttribute('data-row')) >= this.gridCellArray[0].length) {
                    if (assigned(cell)) {
                        this.editorSVG.removeChild(cell);
                    }
                }
        });
    }

    getSvgCellByPosition(col: number, row: number): SVGRectElement | null {
        return this.editorSVG.querySelector(`.cs-grid-cell[data-col="${col}"][data-row="${row}"]`) as unknown as SVGRectElement ?? null;
    }

    /**
     * Get the amount of rows in the grid
     * @returns rowCount of grid
     */
    getRowCount(): number {
        return this.gridCellArray[0].length;
    }

    /**
     * Get the amount of columns in the grid
     * @returns colCount of grid
     */
    getColCount(): number {
        return this.gridCellArray.length;
    }

    /**
     * sets data of the underlying grid
     */
    updateGridCellData() {
        // size of visible processEditor / standardsize of a gridCell + 1 for spacing
        let visibleCols = Math.floor(Math.max(this.width, this.editorElement.clientWidth / this.zoomFactor) / (this.getUsedGrid().width + this.getGridPadding().width)) + 1;
        let visibleRows = Math.floor(Math.max(this.height, this.editorElement.clientHeight / this.zoomFactor) / (this.getUsedGrid().height + this.getGridPadding().height)) + 1;

        // iterate for columns 
        for (let col = 0; col < visibleCols; col++) {
            // push only new columns
            if (!assigned(this.gridCellArray[col])) {
                this.gridCellArray.push(new Array<SizeDefinition>());
            }

            if (assigned(this.gridCellArray[col])) {
                // iterate for rows inside columns
                for (let row = 0; row < visibleRows; row++) {
                    let data = {
                        width: this.getUsedGrid().width,
                        height: this.getUsedGrid().height
                    };

                    // if row doesn't exist, push new entry
                    if (!assigned(this.gridCellArray[col][row])) {
                        this.gridCellArray[col].push(data);
                    }
                    // else set data in existing entry
                    else {
                        this.gridCellArray[col][row] = data;
                    }
                }
                // cut inner array to length
                this.gridCellArray[col].length = visibleRows;
            }
        }
        // cut array to length
        this.gridCellArray.length = visibleCols;
    }

    /**
     * set width of a column to a new value
     * @param index index of column
     * @param value new width
     */
    setColWidth(index: number, value: number): void {
        this.gridCellArray[index].forEach(col => {
            col.width = value;
        });

        this.invalidate();
    }

    /**
     * set height of a row to a new value
     * @param index index of row
     * @param value new height
     */
    setRowHeight(index: number, value: number): void {
        this.gridCellArray.forEach(col => {
            col.forEach((row, i) => {
                if (i === index) {
                    row.height = value;
                }
            });
        });

        this.invalidate();
    }

    /**
     * Get cellData of the virtual grid by index
     * @param col index of column
     * @param row index of row
     * @returns Width and height of a cell
     */
    getGridCellByIndex(col: number, row: number): SizeDefinition {
        if (col < this.gridCellArray.length && row < this.gridCellArray[col].length)
            return this.gridCellArray[col][row];
        else
            return null;
    }

    override checkEditorSize(): void {
        super.checkEditorSize();

        // update gridCellArray
        this.updateGridCellData();
        // wir müssen die UI auf die neue Size update
        this.invalidate();
    }

    //#region Grid Utils

    /**
     * @remarks Always returns true in Cluster mode.
     */
    protected override hasGrid(): boolean {
        return true;
    }

    /**
     * @returns Either the gridSize or, if virtual grid is used, the virtualGridSize for clusters.
     */
    getUsedGrid(): SizeDefinition {
        return {
            width: this.virtualGridSize.width * this.gridSize.width,
            height: this.virtualGridSize.height * this.gridSize.height
        };
    }

    /**
     * @returns Either the grid padding of individual cells or, if virtual grid is used, the padding between clusters.
     */
    getGridPadding(): SizeDefinition {
        return {
            width: this.virtualGridPadding * this.gridSize.width,
            height: this.virtualGridPadding * this.gridSize.height
        }
    }

    /**
     * Snaps a given value on the x-axis to the used grid
     * @param x Value to snap to grid
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    snapToUsedGridX(x: number): number {
        let tempX = this.sizeMarginLeft * this.gridSize.width;
        let res = tempX;

        if (this.gridCellArray.length > 0) {
            this.gridCellArray.forEach(col => {
                tempX -= this.getGridPadding().width * 0.5;
                if (x >= tempX) {
                    tempX += this.getGridPadding().width * 0.5;
                    res = tempX;
                }
                tempX += col[0].width + this.getGridPadding().width;
            });
        }

        return res;
    }

    /**
     * Snaps a given value on the y-axis to the used grid
     * @param y Value to snap to grid
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    snapToUsedGridY(y: number): number {
        let tempY = this.sizeMarginTop * this.gridSize.height;
        let res = tempY;

        if (this.gridCellArray.length > 0) {
            this.gridCellArray[0].forEach(row => {
                tempY -= this.getGridPadding().height * 0.5;
                if (y >= tempY) {
                    tempY += this.getGridPadding().height * 0.5;
                    res = tempY;
                }
                tempY += row.height + this.getGridPadding().height;
            });
        }

        return res;
    }

    getCellByPos(pos: Point): SizeDefinition {
        let colIndex = 0;
        let rowIndex = 0;
        let tempX = this.getGridPadding().width;
        let tempY = this.getGridPadding().height;

        if (this.gridCellArray.length > 0) {
            this.gridCellArray.forEach((col, i) => {
                if (pos.x >= tempX) {
                    colIndex = i;
                }
                tempX += col[0].width + this.getGridPadding().width;
            });

            this.gridCellArray[0].forEach((row, i) => {
                if (pos.y >= tempY) {
                    rowIndex = i;
                }
                tempY += row.height + this.getGridPadding().height;
            });
        }
        return this.getGridCellByIndex(colIndex, rowIndex);
    }

    /**
     * Snaps a shape to the used grid. Optionally can handle a new target position.
     * @param shape Shape to snap to the grid.
     * @param newPos Target position. If not set, the current shape position will be used instead.
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    snapShapeToUsedGrid(shape: TBaseShape, newPos?: Point): Bounds {
        if (!assigned(newPos)) {
            // wir müssen im cluster mode die Shapemitte als Basispunkte nehmen, da sonst Shapes, die größer als die Box sind in andere Boxen verschoben werden       
            return this.snapShapeToUsedGrid(shape, new Point(shape.x + shape.w / 2, shape.y + shape.h / 2));
        }

        // Erstmal die Positionen bestimmen:
        let newX = this.snapToUsedGridX(newPos.x);
        let newY = this.snapToUsedGridY(newPos.y);
        let cell = this.getCellByPos(new Point(newX, newY));

        // wir wollen das Shape innerhalb des virtual Grid Blocks zentrieren
        let blockWidth = this.gridSize.width * this.virtualGridSize.width;
        let blockHeight = this.gridSize.height * this.virtualGridSize.height;

        if (assigned(cell)) {
            blockWidth = cell.width;
            blockHeight = cell.height;
        }

        // Shapes, die nicht resized werden dürfen, zentrieren wir in der Zelle
        if (!shape.isResizePossible) {
            if (shape.w !== blockWidth) {
                let offsetX = Math.round((blockWidth - shape.w) / 2);

                newX += offsetX;
            }

            if (shape.h !== blockHeight) {
                let offsetY = Math.round((blockHeight - shape.h) / 2);

                newY += offsetY;
            }

            return new Bounds(newX, newY, newX + shape.w, newY + shape.h);
        }

        return new Bounds(newX, newY, newX + blockWidth, newY + blockHeight);
    }

    snapShapeToCell(shape: TBaseShape, column: number, row: number): Bounds | null {
        let svgCell = this.getSvgCellByPosition(column, row);

        if (assigned(svgCell)) {
            return this.snapShapeToUsedGrid(shape, new Point(parseFloat(svgCell.dataset.x), parseFloat(svgCell.dataset.y)))
        }

        return null;
    }
    //#endregion
}