import Color from 'color';
import { Bounds, degToRad, Point, Rectangle, turnPoint } from '../../../classes/geometry';
import { assigned } from '../../../utils/helper';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { getIDSuffix, svgArcArea, svgClear, svgClosePath, svgCreatePathElement, svgCreateTextElement, svgLineTo, svgMoveTo, SVGNS, svgSetSize } from '../../../utils/svg';
import { TRenderWebComponent } from '../../base/class.web.comps';

class TachoSection {
    minValue: number;
    maxValue: number;
    color: Color
    colorSecondary: Color;

    constructor(minValue: number, maxValue: number, color: Color, colorSecondary: Color) {
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.color = color;
        this.colorSecondary = colorSecondary;
    }
}

class TachoOptions {
    drawMode: DrawMode;
    valid: boolean;
    width: number;
    height: number;
    minValue: number;
    maxValue: number;
    value: number;
    targetValue: number;
    showValuePointer: boolean;
    showTargetValuePointer: boolean;
    showCaption: boolean;
    showValue: boolean;
    captionFontName: string;
    captionFontSize: number;
    captionFontColor: Color;
    valueFontName: string;
    valueFontSize: number;
    valueFontColor: Color;
    limitFontName: string;
    limitFontSize: number;
    limitFontColor: Color;
    caption: string;
    margins: Bounds;
    padding: Bounds;
    sections: Array<TachoSection>;
    valueUnit: string;

    constructor(drawMode: DrawMode, valid: boolean, width: number, height: number, minValue: number, maxValue: number, value: number, targetValue: number, showValuePointer: boolean, showTargetValuePointer: boolean, showCaption: boolean, showValue: boolean, captionFontName: string, captionFontSize: number, captionFontColor: Color, valueFontName: string, valueFontSize: number, valueFontColor: Color, limitFontName: string, limitFontSize: number, limitFontColor: Color, caption: string, margins: Bounds, padding: Bounds, sections: Array<TachoSection>, valueUnit: string) {
        this.drawMode = drawMode;
        this.valid = valid;
        this.width = width;
        this.height = height;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.value = value;
        this.targetValue = targetValue;
        this.showValuePointer = showValuePointer;
        this.showTargetValuePointer = showTargetValuePointer;
        this.showCaption = showCaption;
        this.showValue = showValue;
        this.captionFontName = captionFontName;
        this.captionFontSize = captionFontSize;
        this.captionFontColor = Color(captionFontColor);
        this.valueFontName = valueFontName;
        this.valueFontSize = valueFontSize;
        this.valueFontColor = Color(valueFontColor);
        this.limitFontName = limitFontName;
        this.limitFontSize = limitFontSize;
        this.limitFontColor = Color(limitFontColor);
        this.caption = caption;
        this.margins = margins;
        this.padding = padding;
        this.sections = sections;
        this.valueUnit = valueUnit;
    }
}

const OFFSET = 243;
const AREA = 234;

enum DrawMode {
    dmSpeedo,
    dmTile,
    dmBarVertical,
    dmBarHorizontal
};

export class TwcChartTacho extends TRenderWebComponent {
    options: TachoOptions;
    drawMode: DrawMode;
    svg: SVGSVGElement;
    width: number;
    height: number;
    initialized: boolean;
    visible: boolean

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcChartTacho';
        try {
            let optionsParsed = csJSONParse(this.obj.dataset?.options);
            this.options = new TachoOptions(optionsParsed.drawMode, optionsParsed.valid, optionsParsed.width, optionsParsed.height, optionsParsed.minValue, optionsParsed.maxValue,
                optionsParsed.value, optionsParsed.targetValue, optionsParsed.showValuePointer, optionsParsed.showTargetValuePointer, optionsParsed.showCaption, optionsParsed.showValue,
                optionsParsed.captionFontName, optionsParsed.captionFontSize, optionsParsed.captionFontColor, optionsParsed.valueFontName, optionsParsed.valueFontSize, optionsParsed.valueFontColor,
                optionsParsed.limitFontName, optionsParsed.limitFontSize, optionsParsed.limitFontColor, optionsParsed.caption, optionsParsed.margins, optionsParsed.padding,
                optionsParsed.sections, optionsParsed.valueUnit);
        } catch (e) {
            this.options = null;
            console.debug(e);
        }
        this.drawMode = this.options?.drawMode ?? DrawMode.dmSpeedo;
        this.width = this.options?.width ?? 240;
        this.height = this.options?.height ?? 240;
        this.initialized = false;
        this.visible = boolFromStr(this.obj.dataset?.visible);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.svg = document.createElementNS(SVGNS, 'svg');
        this.svg.setAttribute('id', getIDSuffix(this.id, 'svg'));
        this.obj.appendChild(this.svg);
        svgSetSize(this.svg, this.width, this.height);

        let observer = new ResizeObserver(() => {
            this.invalidate();
        });
        observer.observe(this.obj);
    }

    setSVGSpeedo() {
        // wenn wir keine sections haben, zeichnen wir nichts
        if (this.options.sections.length === 0)
            return;

        // init speedo sections
        this.options.sections.forEach((section: TachoSection, index: number) => {
            svgCreatePathElement(this.svg, getIDSuffix(this.id, getIDSuffix('tachoSection', String(index))), '', String(section.color));
            svgCreatePathElement(this.svg, getIDSuffix(this.id, getIDSuffix('tachoSectionInner', String(index))), '', String(section.colorSecondary));
        });

        // init needle
        if (this.options.showValuePointer && !(this.options.value < this.options.minValue || this.options.value > this.options.maxValue)) {
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'tachoNeedleCircleGray'), '', '#666666');
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'tachoNeedlePointerGray'), '', '#666666');
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'tachoNeedleCircleBlack'), '', '#000000');
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'tachoNeedlePointerBlack'), '', '#000000');
        }

        // init targetIndicator
        if (this.options.showTargetValuePointer && !(this.options.targetValue < this.options.minValue || this.options.targetValue > this.options.maxValue))
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'tachoTargetIndicator'), '', '#000000');

        // caption
        if (this.options.showCaption)
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'caption'), new Point(0, 0), this.options.caption, this.options.captionFontName, this.options.captionFontSize, this.options.captionFontColor.hex());

        // valueCaption
        if (this.options.showValue)
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'value'), new Point(0, 0), this.options.value + (this.options.valueUnit ? ' ' + this.options.valueUnit : ''),
                this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());

        // limitCaptions
        svgCreateTextElement(this.svg, getIDSuffix(this.id, 'minValue'), new Point(0, 0), String(this.options.minValue), this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());
        svgCreateTextElement(this.svg, getIDSuffix(this.id, 'maxValue'), new Point(0, 0), String(this.options.maxValue), this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());
    }

    setSVGTile() {
        // wenn wir keine sections haben, zeichnen wir nichts
        if (this.options.sections.length === 0)
            return;

        // rect
        svgCreatePathElement(this.svg, getIDSuffix(this.id, 'tileRect'), '', '#FFFFFF');

        // caption
        if (this.options.showCaption)
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'caption'), new Point(0, 0), this.options.caption, this.options.captionFontName, this.options.captionFontSize, this.options.captionFontColor.hex());

        // valueCaption
        if (this.options.showValue)
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'value'), new Point(0, 0), this.options.value + (this.options.valueUnit ? ' ' + this.options.valueUnit : ''),
                this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());
    }

    setSVGBar() {
        // wenn wir keine sections haben, zeichnen wir nichts
        if (this.options.sections.length === 0)
            return;

        // für alle sections ein rect
        this.options.sections.forEach((section: TachoSection, index: number) => {
            svgCreatePathElement(this.svg, getIDSuffix(this.id, getIDSuffix('barSection', String(index))), '', String(section.color));
            svgCreatePathElement(this.svg, getIDSuffix(this.id, getIDSuffix('barSectionInner', String(index))), '', String(section.colorSecondary));
        });

        // init needle
        if (this.options.showValuePointer && this.isValueInLimits(this.options.value)) {
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'barNeedleGray'), '', '#666666');
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'barNeedleBlack'), '', '#000000');
        }

        // init targetIndicator
        if (this.options.showTargetValuePointer && this.isValueInLimits(this.options.targetValue))
            svgCreatePathElement(this.svg, getIDSuffix(this.id, 'targetValuePointer'), '', '#000000');

        // caption
        if (this.options.showCaption)
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'caption'), new Point(0, 0), this.options.caption, this.options.captionFontName, this.options.captionFontSize, this.options.captionFontColor.hex());

        // valueCaption
        if (this.options.showValue)
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'value'), new Point(0, 0), this.options.value + (this.options.valueUnit ? ' ' + this.options.valueUnit : ''),
                this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());

        // limitCaptions
        svgCreateTextElement(this.svg, getIDSuffix(this.id, 'minValue'), new Point(0, 0), String(this.options.minValue), this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());
        svgCreateTextElement(this.svg, getIDSuffix(this.id, 'maxValue'), new Point(0, 0), String(this.options.maxValue), this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());
    }

    setDrawModeSVG() {
        // wir entfernen unser altes SVG
        svgClear(this.svg);

        switch (this.drawMode) {
            case DrawMode.dmSpeedo:
                this.setSVGSpeedo();
                break;
            case DrawMode.dmTile:
                this.setSVGTile();
                break;
            case DrawMode.dmBarVertical:
                this.setSVGBar();
                break;
            case DrawMode.dmBarHorizontal:
                this.setSVGBar();
                break;
        }

        this.initialized = true;
    }

    updateOptions() {
        if (!assigned(this.options)) {
            return;
        }
        this.width = this.options?.width ?? 240;
        this.height = this.options?.height ?? 240;
        this.drawMode = this.options?.drawMode ?? DrawMode.dmSpeedo;

        this.initialized = false;
        this.invalidate();
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        this.svg.classList.toggle('d-none', !this.visible);

        // Wenn wir keine Daten haben, abbrechen
        if (!assigned(this.options))
            return;

        // Je nach DrawMode die SVG Elemente vorbereiten
        if (!this.initialized)
            this.setDrawModeSVG();

        // factor
        let rFactor = Math.min((this.obj.clientWidth / this.options.width), (this.obj.clientHeight / this.options.height));
        if (rFactor === 0) {
            rFactor = 1;
        }

        // fit to parentObj
        this.width = this.options.width * rFactor;
        this.height = this.options.height * rFactor;

        svgSetSize(this.svg, this.width, this.height);

        // wir haben vier verschiedene Zeichenmodi - vgl Suite
        switch (this.drawMode) {
            case DrawMode.dmSpeedo:
                this.drawSpeedo();
                break;
            case DrawMode.dmTile:
                this.drawTile();
                break;
            case DrawMode.dmBarVertical:
                this.drawBar();
                break;
            case DrawMode.dmBarHorizontal:
                this.drawBar();
                break;
        }
    }

    drawSpeedo() {
        // real paintarea
        let rect = new Rectangle(0 + this.options.margins.left, 0 + this.options.margins.top, this.width - this.options.margins.right, this.height - this.options.margins.bottom);

        // --------- Speedo Area --------- //
        let tachoRect = new Rectangle(rect.left + this.options.padding.left, rect.top + this.options.padding.top, rect.right - this.options.padding.right, rect.bottom - this.options.padding.bottom);
        let tachoCenter = new Point((tachoRect.left + tachoRect.width) / 2, (tachoRect.top + tachoRect.height) / 2);
        let tachoSize = Math.min(tachoRect.width, tachoRect.height);

        if (!this.options.valid) {
            svgCreateTextElement(this.svg, getIDSuffix(this.id, 'value'), new Point(tachoCenter.x, rect.bottom), this.options.valueUnit, this.options.valueFontName, this.options.valueFontSize, this.options.captionFontColor.hex());
            return;
        }

        // --------- Speedo sections --------- //
        // radii
        let rInner = tachoSize * 0.56 / 2;
        let rMiddle = tachoSize * 0.7 / 2;
        let rOuter = tachoSize * 1 / 2;

        this.options.sections.forEach((section: TachoSection, index: number) => {
            let alpha = (((section.minValue - this.options.minValue) / (this.options.maxValue - this.options.minValue)) * AREA) + OFFSET;
            let beta = (((section.maxValue - this.options.minValue) / (this.options.maxValue - this.options.minValue)) * AREA) + OFFSET;

            let tachoSection = document.getElementById(getIDSuffix(this.id, getIDSuffix('tachoSection', String(index))));
            if (assigned(tachoSection)) {
                tachoSection.setAttribute('d', svgArcArea(tachoCenter, rMiddle, rOuter, alpha, beta));
                tachoSection.setAttribute('stroke', String(section.color));
                tachoSection.setAttribute('fill', String(section.color));
            }

            let tachoSectionInner = document.getElementById(getIDSuffix(this.id, getIDSuffix('tachoSectionInner', String(index))));
            if (assigned(tachoSectionInner)) {
                tachoSectionInner.setAttribute('d', svgArcArea(tachoCenter, rInner, rMiddle, alpha, beta));
                tachoSectionInner.setAttribute('stroke', String(section.colorSecondary));
                tachoSectionInner.setAttribute('fill', String(section.colorSecondary));
            }
        });

        // --------- Speedo needle --------- //
        if (this.options.showValuePointer && !(this.options.value < this.options.minValue || this.options.value > this.options.maxValue)) {// radii
            let rOuterNeedle = tachoSize * 0.0514;
            let rInnerNeedle = tachoSize * 0.0294;
            let needleOffset = tachoCenter.y - tachoSize * 0.0426;
            let needleWidth = tachoSize * 0.0588;
            let needleLength = tachoSize * 0.2882;
            let alpha = ((this.options.value - this.options.minValue) / (this.options.maxValue - this.options.minValue) * AREA) + OFFSET;
            let pathPointerGray = svgMoveTo(turnPoint(alpha, tachoCenter.x, needleOffset, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x - needleWidth / 2, needleOffset, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x, needleOffset - needleLength, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x, needleOffset, tachoCenter))
                + svgClosePath();
            let pathPointerBlack = svgMoveTo(turnPoint(alpha, tachoCenter.x, needleOffset, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x + needleWidth / 2, needleOffset, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x, needleOffset - needleLength, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x, needleOffset, tachoCenter))
                + svgClosePath();

            let tachoNeedleCircleGray = document.getElementById(getIDSuffix(this.id, 'tachoNeedleCircleGray'));
            if (assigned(tachoNeedleCircleGray))
                tachoNeedleCircleGray.setAttribute('d', svgArcArea(tachoCenter, rInnerNeedle, rOuterNeedle, alpha + 180, alpha + 360));
            let tachoNeedlePointerGray = document.getElementById(getIDSuffix(this.id, 'tachoNeedlePointerGray'));
            if (assigned(tachoNeedlePointerGray))
                tachoNeedlePointerGray.setAttribute('d', pathPointerGray);
            let tachoNeedleCircleBlack = document.getElementById(getIDSuffix(this.id, 'tachoNeedleCircleBlack'));
            if (assigned(tachoNeedleCircleBlack))
                tachoNeedleCircleBlack.setAttribute('d', svgArcArea(tachoCenter, rInnerNeedle, rOuterNeedle, alpha, alpha + 180));
            let tachoNeedlePointerBlack = document.getElementById(getIDSuffix(this.id, 'tachoNeedlePointerBlack'));
            if (assigned(tachoNeedlePointerBlack))
                tachoNeedlePointerBlack.setAttribute('d', pathPointerBlack);
        }

        // --------- Target Value --------- //
        if (this.options.showTargetValuePointer && !(this.options.targetValue < this.options.minValue || this.options.targetValue > this.options.maxValue)) {
            let alpha = ((this.options.targetValue - this.options.minValue) / (this.options.maxValue - this.options.minValue) * AREA) + OFFSET;
            let pointerBottom = tachoCenter.y - tachoSize / 2 + tachoSize * 0.0882 * 0.66;
            let indicatorPath = svgMoveTo(turnPoint(alpha, tachoCenter.x, pointerBottom, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x + tachoSize * 0.0808 / 2, pointerBottom - tachoSize * 0.0882, tachoCenter))
                + svgLineTo(turnPoint(alpha, tachoCenter.x - tachoSize * 0.0808 / 2, pointerBottom - tachoSize * 0.0882, tachoCenter))
                + svgClosePath();

            let tachoTargetIndicator = document.getElementById(getIDSuffix(this.id, 'tachoTargetIndicator'));
            if (assigned(tachoTargetIndicator))
                tachoTargetIndicator.setAttribute('d', indicatorPath);
        }

        // --------- Caption --------- //
        if (this.options.showCaption) {
            let captionPos = new Point(rect.left, rect.top + this.options.valueFontSize);
            let textElement = document.getElementById(getIDSuffix(this.id, 'caption'));
            if (assigned(textElement)) {
                textElement.setAttribute('x', String(captionPos.x));
                textElement.setAttribute('y', String(captionPos.y));
            }
        }

        // --------- Value --------- //
        if (this.options.showValue) {
            let valuePos = new Point(tachoCenter.x, rect.bottom);
            let textElement = document.getElementById(getIDSuffix(this.id, 'value'));
            if (assigned(textElement)) {
                textElement.setAttribute('x', String(valuePos.x - (textElement as any).getComputedTextLength() / 2));
                textElement.setAttribute('y', String(valuePos.y));
            }
        }

        // --------- Limit Captions --------- //
        let offsetWidth = rInner * Math.cos(degToRad(27));
        let offsetHeight = rOuter * Math.sin(degToRad(27));

        let ptMin = new Point(tachoCenter.x - offsetWidth, tachoCenter.y + offsetHeight + this.options.limitFontSize);
        let ptMax = new Point(tachoCenter.x + offsetWidth, tachoCenter.y + offsetHeight + this.options.limitFontSize);

        let textElementMin = document.getElementById(getIDSuffix(this.id, 'minValue'));
        if (assigned(textElementMin)) {
            textElementMin.setAttribute('x', String(ptMin.x - (textElementMin as any).getComputedTextLength() / 2));
            textElementMin.setAttribute('y', String(ptMin.y));
        }

        let textElementMax = document.getElementById(getIDSuffix(this.id, 'maxValue'));
        if (assigned(textElementMax)) {
            textElementMax.setAttribute('x', String(ptMax.x));
            textElementMax.setAttribute('y', String(ptMax.y));
        }
    }

    drawBar() {
        let rect = new Rectangle(0 + this.options.margins.left, 0 + this.options.margins.top, this.width - this.options.margins.right, this.height - this.options.margins.bottom);
        let barRect = new Rectangle(rect.left + this.options.padding.left, rect.top + this.options.padding.top, rect.right - this.options.padding.right, rect.bottom - this.options.padding.bottom);

        let barHeight = 0.16 * barRect.height; // 16% Bar Höhe... grober Schätzwert
        let barHeightPrimary = 0.7 * barHeight;// 70% Primärfarbe -> anderes drittel sekundärfarbe
        let barTopPosition = barRect.height / 2 - barHeight / 2 + barRect.top;

        let barWidth = 0.16 * barRect.width; // 16% Bar Höhe... grober Schätzwert
        let barWidthPrimary = 0.7 * barWidth;// 70% Primärfarbe -> anderes drittel sekundärfarbe
        let barLeftPosition = barRect.width / 2 - barWidth / 2 + barRect.left;

        let valueRange = this.options.maxValue - this.options.minValue;

        let barSectionPath = '';
        let barSectionInnerPath = '';

        this.options.sections.forEach((section: TachoSection, index: number) => {
            if (section.minValue >= this.options.minValue && section.minValue <= this.options.maxValue
                && section.maxValue >= this.options.minValue && section.maxValue <= this.options.maxValue
                && section.minValue !== section.maxValue) {

                // Ist die Bar horizontal oder vertikal?
                if (this.options.drawMode === DrawMode.dmBarHorizontal) {
                    let leftPos = (section.minValue - this.options.minValue) / valueRange * barRect.width + barRect.left;
                    let rightPos = (section.maxValue - this.options.minValue) / valueRange * barRect.width + barRect.left;

                    barSectionPath = svgMoveTo(new Point(leftPos, barTopPosition))
                        + svgLineTo(new Point(rightPos, barTopPosition))
                        + svgLineTo(new Point(rightPos, barTopPosition + barHeightPrimary))
                        + svgLineTo(new Point(leftPos, barTopPosition + barHeightPrimary))
                        + svgClosePath();

                    barSectionInnerPath = svgMoveTo(new Point(leftPos, barTopPosition + barHeightPrimary))
                        + svgLineTo(new Point(rightPos, barTopPosition + barHeightPrimary))
                        + svgLineTo(new Point(rightPos, barTopPosition + barHeight))
                        + svgLineTo(new Point(leftPos, barTopPosition + barHeight))
                        + svgClosePath();
                }
                else if (this.options.drawMode === DrawMode.dmBarVertical) {
                    let topPos = barRect.bottom - (section.maxValue - this.options.minValue) / valueRange * barRect.height;
                    let bottomPos = barRect.bottom - (section.minValue - this.options.minValue) / valueRange * barRect.height;

                    barSectionPath = svgMoveTo(new Point(barLeftPosition, topPos))
                        + svgLineTo(new Point(barLeftPosition + barWidthPrimary, topPos))
                        + svgLineTo(new Point(barLeftPosition + barWidthPrimary, bottomPos))
                        + svgLineTo(new Point(barLeftPosition, bottomPos))
                        + svgClosePath();

                    barSectionInnerPath = svgMoveTo(new Point(barLeftPosition + barWidthPrimary, topPos))
                        + svgLineTo(new Point(barLeftPosition + barWidth, topPos))
                        + svgLineTo(new Point(barLeftPosition + barWidth, bottomPos))
                        + svgLineTo(new Point(barLeftPosition + barWidthPrimary, bottomPos))
                        + svgClosePath();
                }

                let barSection = document.getElementById(getIDSuffix(this.id, getIDSuffix('barSection', String(index))));
                if (assigned(barSection)) {
                    barSection.setAttribute('d', barSectionPath);
                    barSection.setAttribute('stroke', String(section.color));
                    barSection.setAttribute('fill', String(section.color));
                }

                let barSectionInner = document.getElementById(getIDSuffix(this.id, getIDSuffix('barSectionInner', String(index))));
                if (assigned(barSectionInner)) {
                    barSectionInner.setAttribute('d', barSectionInnerPath);
                    barSectionInner.setAttribute('stroke', String(section.colorSecondary));
                    barSectionInner.setAttribute('fill', String(section.colorSecondary));
                }
            }
        });

        // --------- Wertenadel --------- //
        if (this.options.showValuePointer && this.isValueInLimits(this.options.value)) {
            let needleHeight: number;
            let needleWidth: number;
            let barNeedleBlackPath: string;
            let barNeedleGrayPath: string;

            // horizontal
            if (this.options.drawMode === DrawMode.dmBarHorizontal) {
                let valueX = (this.options.value - this.options.minValue) / valueRange * barRect.width + barRect.left;
                needleHeight = barHeightPrimary * 1.375; // etwas höher als obere Bar
                needleWidth = barRect.width * 0.0584; // identisch zum Tacho

                barNeedleBlackPath = svgMoveTo(new Point(valueX, barTopPosition + barHeightPrimary))
                    + svgLineTo(new Point(valueX - needleWidth / 2, barTopPosition + barHeightPrimary - needleHeight))
                    + svgLineTo(new Point(valueX, barTopPosition + barHeightPrimary - needleHeight))
                    + svgClosePath();

                barNeedleGrayPath = svgMoveTo(new Point(valueX, barTopPosition + barHeightPrimary))
                    + svgLineTo(new Point(valueX + needleWidth / 2, barTopPosition + barHeightPrimary - needleHeight))
                    + svgLineTo(new Point(valueX, barTopPosition + barHeightPrimary - needleHeight))
                    + svgClosePath();
            }
            // vertikal
            else if (this.options.drawMode === DrawMode.dmBarVertical) {
                let valueY = barRect.bottom - (this.options.value - this.options.minValue) / valueRange * barRect.height;
                needleWidth = barWidthPrimary * 1.375; // etwas höher als obere Bar
                needleHeight = barRect.height * 0.0584; // identisch zum Tacho

                barNeedleBlackPath = svgMoveTo(new Point(barLeftPosition + barWidthPrimary, valueY))
                    + svgLineTo(new Point(barLeftPosition + barWidthPrimary - needleWidth, valueY + needleHeight / 2))
                    + svgLineTo(new Point(barLeftPosition + barWidthPrimary - needleWidth, valueY))
                    + svgClosePath();

                barNeedleGrayPath = svgMoveTo(new Point(barLeftPosition + barWidthPrimary, valueY))
                    + svgLineTo(new Point(barLeftPosition + barWidthPrimary - needleWidth, valueY - needleHeight / 2))
                    + svgLineTo(new Point(barLeftPosition + barWidthPrimary - needleWidth, valueY))
                    + svgClosePath();
            }

            let barNeedleBlack = document.getElementById(getIDSuffix(this.id, 'barNeedleBlack'));
            if (assigned(barNeedleBlack)) {
                barNeedleBlack.setAttribute('d', barNeedleBlackPath);
                barNeedleBlack.setAttribute('stroke', '#000000');
                barNeedleBlack.setAttribute('fill', '#000000');
            }

            let barNeedleGray = document.getElementById(getIDSuffix(this.id, 'barNeedleGray'));
            if (assigned(barNeedleGray)) {
                barNeedleGray.setAttribute('d', barNeedleGrayPath);
                barNeedleGray.setAttribute('stroke', '#666666');
                barNeedleGray.setAttribute('fill', '#666666');
            }
        }

        // --------- Sollwert --------- //
        if (this.options.showTargetValuePointer && this.isValueInLimits(this.options.targetValue)) {
            let targetPointerPath: string;

            if (this.options.drawMode === DrawMode.dmBarHorizontal) {
                let valueX = (this.options.targetValue - this.options.minValue) / valueRange * barRect.width + barRect.left;
                let needleWidth = barRect.width * 0.0584; // identisch zum Value

                targetPointerPath = svgMoveTo(new Point(valueX, barTopPosition + barHeightPrimary))
                    + svgLineTo(new Point(valueX + needleWidth / 2, barTopPosition + barHeight))
                    + svgLineTo(new Point(valueX - needleWidth / 2, barTopPosition + barHeight))
                    + svgClosePath();

            }
            else if (this.options.drawMode === DrawMode.dmBarVertical) {
                let valueY = barRect.bottom - (this.options.targetValue - this.options.minValue) / valueRange * barRect.height;
                let needleHeight = barRect.height * 0.0584; // identisch zum Value

                targetPointerPath = svgMoveTo(new Point(barLeftPosition + barWidthPrimary, valueY))
                    + svgLineTo(new Point(barLeftPosition + barWidth, valueY + needleHeight / 2))
                    + svgLineTo(new Point(barLeftPosition + barWidth, valueY - needleHeight / 2))
                    + svgClosePath();
            }

            // Der Default Pointer sitzt oben, daher müssen wir nochmal den Winkel manipulieren
            let targetPointer = document.getElementById(getIDSuffix(this.id, 'targetValuePointer'));
            if (assigned(targetPointer)) {
                targetPointer.setAttribute('d', targetPointerPath);
                targetPointer.setAttribute('stroke', '#000000');
                targetPointer.setAttribute('fill', '#000000');
            }
        }

        // --------- Caption --------- //
        if (this.options.showCaption) {
            let captionPos = new Point(rect.left, rect.top + this.options.valueFontSize);

            let textElement = document.getElementById(getIDSuffix(this.id, 'caption'));
            if (assigned(textElement)) {
                textElement.setAttribute('x', String(captionPos.x));
                textElement.setAttribute('y', String(captionPos.y));
            }
        }

        // --------- Value --------- //
        if (this.options.showValue) {
            let valuePos: Point;
            let needleHeight = barHeightPrimary * 1.375;

            if (this.options.drawMode === DrawMode.dmBarHorizontal) {
                valuePos = new Point(barRect.right, barTopPosition - needleHeight);
            }
            else if (this.options.drawMode === DrawMode.dmBarVertical) {
                valuePos = new Point(barRect.left + (barLeftPosition - barRect.left) / 2, barRect.bottom);
            }

            let textElement = document.getElementById(getIDSuffix(this.id, 'value'));
            if (this.options.drawMode === DrawMode.dmBarHorizontal)
                valuePos.x = valuePos.x - (textElement as any).getComputedTextLength();
            else if (this.options.drawMode === DrawMode.dmBarVertical)
                valuePos.x = valuePos.x - (textElement as any).getComputedTextLength() / 2;

            if (assigned(textElement)) {
                textElement.setAttribute('x', String(valuePos.x));
                textElement.setAttribute('y', String(valuePos.y));
            }
        }

        // --------- Limit Captions --------- //
        let ptMin: Point;
        let ptMax: Point;

        if (this.options.drawMode === DrawMode.dmBarHorizontal) {
            ptMin = new Point(barRect.left, barTopPosition + barHeight + this.options.limitFontSize);
            ptMax = new Point(barRect.right, barTopPosition + barHeight + this.options.limitFontSize);
        }
        else if (this.options.drawMode === DrawMode.dmBarVertical) {
            ptMin = new Point(barLeftPosition + barWidth + 10, barRect.bottom);
            ptMax = new Point(barLeftPosition + barWidth + 10, barRect.top + this.options.limitFontSize);
        }

        let textElementMin = document.getElementById(getIDSuffix(this.id, 'minValue'));
        if (assigned(textElementMin)) {
            textElementMin.setAttribute('x', String(ptMin.x));
            textElementMin.setAttribute('y', String(ptMin.y));
        }

        let textElementMax = document.getElementById(getIDSuffix(this.id, 'maxValue'));
        if (this.options.drawMode === DrawMode.dmBarHorizontal)
            ptMax.x = ptMax.x - (textElementMax as any).getComputedTextLength();
        if (assigned(textElementMax)) {
            textElementMax.setAttribute('x', String(ptMax.x));
            textElementMax.setAttribute('y', String(ptMax.y));
        }

    }

    drawTile() {
        // real paintarea
        let rect = new Rectangle(0 + this.options.margins.left, 0 + this.options.margins.top, this.width - this.options.margins.right, this.height - this.options.margins.bottom);
        let colorRect = new Rectangle(rect.left + this.options.padding.left, rect.top + 2 * (rect.height / 3) + this.options.padding.top, rect.right - this.options.padding.right, rect.bottom - this.options.padding.bottom);

        // Farbe finden! -> in welcher Section liegt der Wert?
        let color: Color;
        let colorDefined = false;
        let value = this.options.value;
        this.options.sections.forEach((section: TachoSection, index: number) => {
            // Wenn der Wert innerhalb der Grenzen liegt müssen wir prüfen, innerhalb welcher Section wir sind
            if (this.isValueInLimits(value)) {
                if (value >= section.minValue && value <= section.maxValue) {
                    color = section.color;
                    colorDefined = true;
                    return;
                }
            }
            // Außerhalb der Grenzen müssen wir die "Randsection" finden und deren Farbe nehmen
            else {
                if ((value < this.options.minValue && section.minValue === this.options.minValue)
                    || (value > this.options.maxValue && section.maxValue === this.options.maxValue)) {
                    color = section.color;
                    colorDefined = true;
                    return;
                }
            }
        });

        if (!colorDefined) {
            console.debug('No section area found for value ' + this.options.value + '.');
            return;
        }

        // Farben füllen und größe der Tile setzen
        let rectPath = svgMoveTo(new Point(colorRect.left, colorRect.top))
            + svgLineTo(new Point(colorRect.right, colorRect.top))
            + svgLineTo(new Point(colorRect.right, colorRect.bottom))
            + svgLineTo(new Point(colorRect.left, colorRect.bottom))
            + svgClosePath();

        let rectElement = document.getElementById(getIDSuffix(this.id, 'tileRect'));
        if (assigned(rectElement)) {
            rectElement.setAttribute('d', rectPath);
            rectElement.setAttribute('stroke', String(color));
            rectElement.setAttribute('fill', String(color));
        }

        // --------- Caption --------- //
        if (this.options.showCaption) {
            let captionPos = new Point(rect.left, rect.top + this.options.valueFontSize);
            let textElement = document.getElementById(getIDSuffix(this.id, 'caption'));
            textElement.setAttribute('x', String(captionPos.x));
            textElement.setAttribute('y', String(captionPos.y));
        }

        // --------- Value --------- //
        if (this.options.showValue) {
            let valuePos = new Point(colorRect.width, colorRect.bottom);
            let textElement = document.getElementById(getIDSuffix(this.id, 'value'));
            textElement.setAttribute('x', String(valuePos.x - (textElement as any).getComputedTextLength()));
            textElement.setAttribute('y', String(valuePos.y));
        }
    }

    isValueInLimits(value: number): boolean {
        return (value >= this.options.minValue) && (value <= this.options.maxValue);
    }

    writeProperties(key: string, value: string): void {
        // visible?
        switch (key) {
            case 'Visible':
                this.visible = boolFromStr(value);
                break;
            case 'Options':
                try {
                    let optionsParsed = csJSONParse(value);
                    this.options = new TachoOptions(optionsParsed.drawMode, optionsParsed.valid, optionsParsed.width, optionsParsed.height, optionsParsed.minValue, optionsParsed.maxValue,
                        optionsParsed.value, optionsParsed.targetValue, optionsParsed.showValuePointer, optionsParsed.showTargetValuePointer, optionsParsed.showCaption, optionsParsed.showValue,
                        optionsParsed.captionFontName, optionsParsed.captionFontSize, optionsParsed.captionFontColor, optionsParsed.valueFontName, optionsParsed.valueFontSize, optionsParsed.valueFontColor,
                        optionsParsed.limitFontName, optionsParsed.limitFontSize, optionsParsed.limitFontColor, optionsParsed.caption, optionsParsed.margins, optionsParsed.padding,
                        optionsParsed.sections, optionsParsed.valueUnit);
                } catch (e) {
                    this.options = null;
                    console.debug(e);
                }
                this.updateOptions();
                break;
        }
    }
}