import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeMarginAction extends TCustomTutorialShapeAction {
    oldMarginLeft: number;
    oldMarginTop: number;
    oldMarginRight: number;
    oldMarginBottom: number;
    newMarginLeft: number;
    newMarginTop: number;
    newMarginRight: number;
    newMarginBottom: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldMarginLeft = this.refElement.margin.left;
        this.oldMarginTop = this.refElement.margin.top;
        this.oldMarginRight = this.refElement.margin.right;
        this.oldMarginBottom = this.refElement.margin.bottom;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeMargins;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldMarginLeft = obj.oldMarginLeft;
        this.oldMarginTop = obj.oldMarginTop;
        this.oldMarginRight = obj.oldMarginRight;
        this.oldMarginBottom = obj.oldMarginBottom;

        this.newMarginLeft = obj.newMarginLeft;
        this.newMarginTop = obj.newMarginTop;
        this.newMarginRight = obj.newMarginRight;
        this.newMarginBottom = obj.newMarginBottom;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldMarginLeft = this.oldMarginLeft;
        obj.oldMarginLeft = this.oldMarginTop;
        obj.oldMarginLeft = this.oldMarginRight;
        obj.oldMarginLeft = this.oldMarginBottom;

        obj.newMarginLeft = this.newMarginLeft;
        obj.newMarginTop = this.newMarginTop;
        obj.newMarginRight = this.newMarginRight;
        obj.newMarginBottom = this.newMarginBottom;

        return obj;
    }

    setValue(left: number, top: number, right: number, bottom: number): void {
        this.newMarginLeft = left;
        this.newMarginTop = top;
        this.newMarginRight = right;
        this.newMarginBottom = bottom;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setMargin(this.newMarginLeft, this.newMarginTop, this.newMarginRight, this.newMarginBottom);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeMarginAction(this.refElement, this.svgEditor);
        result.setValue(this.oldMarginLeft, this.oldMarginTop, this.oldMarginRight, this.oldMarginBottom);

        return result;
    }
}