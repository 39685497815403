import Color from 'color';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TEdgeBase } from '../Edges/TEdgeBase';
import { ActionType, TCustomEdgeAction } from './TCustomAction';

export class TEdgeBackgroundColorAction extends TCustomEdgeAction {
    oldBackgroundColor: Color;
    newBackgroundColor: Color;

    constructor(refElement: TEdgeBase, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.oldBackgroundColor = Color('#000');
        this.newBackgroundColor = Color('#000');
    }

    typeID(): ActionType {
        return ActionType.patEdgeBackgroundColor;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldBackgroundColor = Color(obj.oldBackgroundColor);
        this.newBackgroundColor = Color(obj.newBackgroundColor);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldBackgroundColor = this.oldBackgroundColor.hex();
        obj.newBackgroundColor = this.newBackgroundColor.hex();

        return obj;
    }

    setValue(newBackgroundColor: Color): void {
        this.newBackgroundColor = newBackgroundColor;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setBackgroundColor(this.newBackgroundColor);
    }

    inverseAction(): TCustomEdgeAction {
        let result = new TEdgeBackgroundColorAction(this.refElement, this.svgEditor);
        result.setValue(this.oldBackgroundColor);

        return result;
    }
}
