import * as bootstrap from "bootstrap";
import $ from 'jquery';
import initComponentFramework from './comps/base/controlling';
import { registerComponents } from './consense.components';
import initGlobalDispatcher from './core/dispatcher';
import * as Helper from './utils/helper';


Helper.onReady(() => {
    registerComponents();

    initComponentFramework();
    initGlobalDispatcher();

    //   
    initSelect2();
    initDataTables();
});


//#region GLOBAL HELPERS

// Globale Einstellung für Select2
function initSelect2() {
    $.fn.select2.defaults.set('width', '100%');
};

function initDataTables(){
    // UPS // -> Das geht besser https://datatables.net/forums/discussion/comment/195633/#Comment_195633 aber da ist die Version noch nicht veröffentlicht :/
    window.bootstrap = bootstrap;
}
//#endregion