﻿import { WebCompEventHandlerAsync } from "../../../core/communication";
import { AsType } from "../../../utils/html";
import { boolFromStr, csJSONParse } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";
import { ComponentProperty } from "../../interfaces/class.web.comps.intf";
export class TwsFilterSelectionItem extends TWebComponent {
    hasOnClick: boolean;
    hasRemoveClick: boolean;
    removeElement: HTMLButtonElement;
    textElement: HTMLElement;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwsFilterSelectionItem';

        this.removeElement = AsType<HTMLButtonElement>(document.getElementById(`${this.id}-remove`));
        this.textElement = AsType<HTMLElement>(document.getElementById(`${this.id}-text`));
        this.hasOnClick = boolFromStr(this.obj.dataset.hasonclick);
        this.hasRemoveClick = boolFromStr(this.obj.dataset.hasonremoveclick);
    }

    override initDomElement() {
        super.initDomElement();
        this.removeElement.addEventListener('click', event => this.handleRemoveBadge(event));
        this.textElement.addEventListener('click', event => this.handleOnClick(event));
    }

    handleOnClick(event): void {
        if (this.hasOnClick) {
            WebCompEventHandlerAsync('OnClick', this.id);
            this.toggleActive(true); 
        }
    }

    handleRemoveBadge(event): void {
        if (this.hasRemoveClick) {
            WebCompEventHandlerAsync('OnRemoveClick', this.id);
        }
    }

    toggleActive(value): void {
        this.obj.classList.toggle('active', value);
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}
export class TwsFilterSelection extends TWebComponent {
    textElement: HTMLElement;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwsFilterSelection';

        this.textElement = document.getElementById(`${this.id}-text`) as HTMLElement;
    }

    override execAction(action: string, params: string): void {
        try {
            switch (action) {
                case 'Action.Refresh':
                    let ActionSet = csJSONParse(params);
                    this.textElement.innerHTML = ActionSet.ActionValue;
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}