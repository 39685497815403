import { MessageType, MsgNotify } from "../../../core/utils/msgnotify";
import { assigned } from "../../../utils/helper";
import { TRenderWebComponent } from "../../base/class.web.comps";
import { getComponentForElement } from '../../base/controlling';
import { TwcCustomFileTransfer } from "./class.web.comp.filetransfer.base";

export class TwcFileTransferSingleCheckout extends TRenderWebComponent {
    uploadInput: HTMLInputElement;
    fileTransfer: TwcCustomFileTransfer;
    fileTransferID: string;
    fileSizetext: string;
    maxFileSize: number;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcFileTransferSingleCheckout';
        this.uploadInput = this.obj.querySelector(`#${this.id}-input`);
        let datasetElem = this.obj.querySelector(`#${this.id}-dataset`) as HTMLElement;
        this.fileSizetext = datasetElem.dataset.maxfilesizetext;
        this.maxFileSize = parseInt(datasetElem.dataset.maxfilesize);

        // wenn der Upload nicht da ist, dann haben wir den auch nicht mitgesendet und sind fertig
        if (!assigned(this.uploadInput)) {
            return;
        }

        this.fileTransferID = this.obj.dataset.filetransferid;
    }

    override initDomElement(): void {
        super.initDomElement();

        // wenn der Upload nicht da ist, dann haben wir den auch nicht mitgesendet und sind fertig
        if (!assigned(this.uploadInput)) {
            return;
        }

        this.uploadInput.addEventListener('change', (event) => this.handleFileSelect(event));
        this.obj.querySelector('.upload-button').addEventListener('click', () => { this.uploadInput.click(); });
    }

    handleFileSelect(e: Event) {
        let file = this.uploadInput.files[0]
        if (!file) {
            this.uploadInput.value = '';
            return;
        }

        // wir geben die MaxSize serverseitig in kB an
        if (this.maxFileSize > 0 && file.size > this.maxFileSize * 1024) {
            let text = this.fileSizetext.replace('${filename}', file.name);
            MsgNotify(text, MessageType.Danger);
            this.uploadInput.value = '';
            return;
        }

        if (!assigned(this.fileTransfer)) {
            this.fileTransfer = getComponentForElement(this.obj.querySelector(`#${this.fileTransferID}`)) as TwcCustomFileTransfer;

            this.fileTransfer.onError.subscribe(() => this.onFileTransferError());
            this.fileTransfer.onSuccess.subscribe(() => this.onFileTransferError());
        }

        this.fileTransfer.uploadFile(file);
    }

    onFileTransferError() {
        this.uploadInput.value = '';
    }

    onFileTransferSuccess() {
        this.uploadInput.value = '';
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'FileExtensions':
                this.uploadInput.accept = value;
                break;
        }
    }
}
