import { Bounds, Point } from '../../../classes/geometry';
import { sendComponentRequestGetJsonAsync } from '../../../core/communication';
import { removeItem } from '../../../utils/arrays';
import { ConsoleMessageType, CsConsole } from '../../../utils/console';
import { addHtml } from '../../../utils/domUtils';
import { EventObj } from '../../../utils/events';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { requireComponent } from '../../base/controlling';
import { TwcTileboardItem } from './class.web.comp.tileboard.item';


export class TwcTileboard extends TRenderWebComponent {
    items: Array<TwcTileboardItem>;
    tileboard: this;
    draggingId: string;
    isSimulating: boolean;
    animationIds: Array<number>;
    itemTargetPosition: any;
    allowDrag: boolean;
    resizeObserver: ResizeObserver;
    resizeAdjustmentRequired: boolean;
    resizeTimer: number;

    private columnCount: number;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcTileboard';

        this.items = [];
        this.tileboard = this;
        this.draggingId = '';
        this.isSimulating = false;
        this.animationIds = [];
        this.resizeAdjustmentRequired = false;
        this.itemTargetPosition = undefined;

        this.columnCount = parseInt(this.obj.dataset.columnCount);
        if (isNaN(this.columnCount)) {
            this.columnCount = 6;
        }
    }

    override initDomElement(): void {
        super.initDomElement();

        this.obj.addEventListener('contextmenu', (ev) => this.handleOnTileboardContextmenu(new EventObj<MouseEvent>(ev)), false);
        this.obj.addEventListener('transitionend', (ev) => this.handleTransitionEnd(), false);

        // Resize Observer
        this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
            if (this.isEditMode()) {
                return;
            }

            entries.forEach(entry => {
                if (entry.target === this.obj) {
                    if (assigned(this.resizeTimer)) {
                        window.clearTimeout(this.resizeTimer);
                    }

                    // nicht kontinuierlich, erst wenn das Resize stoppt
                    this.resizeTimer = window.setTimeout(() => {
                        this.resizeAdjustmentRequired = true;
                        this.invalidate();
                    }, 100);
                }
            })
        });

        this.resizeObserver.observe(this.obj);
    }

    registerTileboardItem(item: TwcTileboardItem): void {
        this.items.push(item);

        item.obj.addEventListener('contextmenu', (ev) => this.handleOnTileboardItemContextmenu(new EventObj<MouseEvent>(ev), item), false);
    }

    unregisterTileboardItem(item: TwcTileboardItem): void {
        this.items = removeItem<TwcTileboardItem>(this.items, item);

        // UPS // Das Contextmenü können wir ohne Named Function nicht entfernen. Named Function können wir aber aufgrund der Implementierung aktuell nicht so einfach nutzen...
    }

    afterTileboardItemChanged(item: TwcTileboardItem): void {
        // do nothing
    }

    //#region Config
    maxColumnCount(): number {
        return this.columnCount;
    }

    usedColumnCount(): number {
        const CELL_MIN_WIDTH = 180;

        let result = this.maxColumnCount();

        // Bei Anschauen verkleinern wird ggf.
        if (!this.isEditMode()) {
            let realWidth = this.obj.clientWidth;
            result = Math.min(Math.floor(realWidth / CELL_MIN_WIDTH), this.maxColumnCount());
        }

        return result;
    }

    usedRowHeight(): number {
        return 150;
    }

    usedRowHeightAsPx(): string {
        return `${this.usedRowHeight()}px`;
    }

    usedColumnSpaceAsPx(): string {
        return '8px';
    }

    usedRowSpaceAsPx(): string {
        return '8px';
    }
    //#endregion

    //#region Komponenten Methoden

    isEditMode(): boolean {
        return false;
    }

    handleTransitionEnd(): void {
        this.obj.classList.remove('resizing');
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'DragState':
                if (value) {
                    this.obj.setAttribute('disabled', '');
                }
                else {
                    this.obj.removeAttribute('disabled');
                }
            case 'ColumnCount':
                {
                    let count = parseInt(value);
                    if (!isNaN(count)) {
                        this.columnCount = count;
                    }
                }
        }
    }

    async requestTileAsync(tileId: string): Promise<void> {
        // Wenn wir das schon kennen können wir abbrechen
        if (this.items.filter(item => item.id === tileId).length > 0) {
            return;
        }

        let response = await sendComponentRequestGetJsonAsync(this.id, { action: 'requestTile', data: tileId });
        if (assigned(response)) {
            addHtml(this.id, (<any>response)?.html);

            let item = requireComponent<TwcTileboardItem>(tileId)
            this.afterTileboardItemChanged(item);
        }
    }

    private removeTile(tileId: string): void {
        let item = this.items.find(item => item.id === tileId);
        if (assigned(item)) {
            // aus der Liste rausnehmenn
            this.items = removeItem<TwcTileboardItem>(this.items, item);
            // und auch aus dem DOM
            item.obj.remove();

            // Wenn wir in der Bearbeitung sind optimieren wir die Darstellung
            if (this.isEditMode()) {
                this.condenseVertically();
            }

            this.invalidate();
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.InsertItem':
                this.requestTileAsync(params);
                break;
            case 'Action.RemoveItem':
                this.removeTile(params);
                break;
            case 'Action.CondenseVertically':
                this.condenseVertically();
                break;
            default: super.execAction(action, params);
        }
    }

    private fitItemsToSize(): void {
        if (!this.resizeAdjustmentRequired) {
            return;
        }

        let lastRenderedState = parseInt(this.obj.dataset?.lastRenderedColCount ?? String(this.maxColumnCount()));
        let targetColCount = this.usedColumnCount();

        try {
            // Seit dem letzten Mal ist noch alles gleich
            if (targetColCount === lastRenderedState) {
                return;
            }

            // "Ursprungs"zustand generieren
            this.items.forEach(item => {
                item.resetPendingChanges();
            });

            // Wenn wir die maximale Größe haben wollen sind wir fertig
            if (targetColCount === this.maxColumnCount()) {
                return;
            }

            // Wir versuchen jedem Item einen "Wert" zuzuordnen, der die Platzierung bestimmt. Die Reihe hat dabei mehr Gewichtung als die Spalte.
            // Das bilden wir ab indem wir die "Zahlen" bilden als ReiheSpalte mit genormten Größen
            let colFactor = this.maxColumnCount().toString().length;
            let rowFactor = Math.pow(10, colFactor);

            let sortedItemWeightMapping = this.items.map(item => ({
                weight: item.getCurrentPosition().left * colFactor + item.getCurrentPosition().top * rowFactor,
                obj: item
            })).sort((a, b) => a.weight - b.weight);

            // und nun fangen wir an zu schieben
            let lastX = 0;
            let lastY = 0;

            try {
                sortedItemWeightMapping.forEach(item => {
                    let realItem = item.obj;

                    let usedWidth = realItem.width;

                    // Ist es zu breit? Fall verkleinern!
                    if (usedWidth > targetColCount) {
                        usedWidth = targetColCount;
                        realItem.setVirtualWidth(usedWidth);
                    }

                    // Da manche Shapes höher sind, müssen wir aber schauen ob da schon was ist. 
                    let tempX = lastX;
                    let tempY = lastY;

                    let spaceOccupied = false;
                    do {
                        // sind wir rechts drüber? Dann müssen wir shiften
                        if (tempX + usedWidth - 1 >= targetColCount) {
                            tempX = 0;
                            tempY++;
                        }

                        // Auf Überlappung mit anderen bereits platzierten Elementen prüfen
                        for (let i = 0; i < sortedItemWeightMapping.indexOf(item); i++) {
                            let otherItem = sortedItemWeightMapping[i].obj;

                            // bitte nicht mit uns selber prüfen....
                            if (realItem.obj === otherItem.obj) {
                                continue;
                            }

                            // Überlappen wir? Falls ja müssen wir weiter schieben
                            if (otherItem.getCurrentPosition().checkPointIsInside(new Point(tempX, tempY))) {
                                spaceOccupied = true;
                                tempX++;
                            }
                            else {
                                spaceOccupied = false;
                            }
                        }

                    } while (spaceOccupied || tempX + usedWidth - 1 >= targetColCount);

                    // und umsetzen
                    realItem.locked = true; // wir setzen das Item locked, bis wir alles vershcoben haben und im Anschluss unlocken wir die!
                    this.virtualMoveToPosition(realItem, new Bounds(tempX, tempY, tempX + usedWidth - 1, tempY + realItem.height - 1));

                    // fürs nächste merken
                    lastX = tempX + 1;
                    lastY = tempY;
                });
            }
            finally {
                sortedItemWeightMapping.forEach(item => {
                    item.obj.locked = false;
                });
            }

            // Am Ende ggf. frei Flächen verdichten
            this.condenseHorizontally();
            this.condenseVertically();
        }
        finally {
            this.obj.dataset.lastRenderedColCount = String(targetColCount);
            this.resizeAdjustmentRequired = false;
        }
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        try {
            this.items.forEach(item => {
                item.beginUpdate();
            });

            // Rearrange items in view mode
            if (!this.isEditMode()) {
                this.fitItemsToSize();
            }

            // Update own height
            // Die unterste Position ermitteln, die wir darstellen müssen
            let rowCount = Math.max(...this.items.map(item => item.getCurrentPosition().bottom));

            // +1 offSet auf den RowCount da der 0 basiert ist.
            this.obj.classList.add('resizing'); // Resizing Transition -> Die Klasse darf erst nach der Transition entfernt werden, damit diese richtig played. Siehe handleTransitionEnd().
            this.obj.style.height = `calc(${rowCount + 1} * ${this.usedRowHeightAsPx()} + ${rowCount + 1} * ${this.usedRowSpaceAsPx()})`;
        } finally {
            this.items.forEach(item => {
                item.endUpdate();
            });
        }
    }
    //#endregion

    //#region Calculation Utilities

    findItemByDomElement(element: Element): TwcTileboardItem {
        for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];
            if (item.obj === element)
                return item;
        }

        return undefined;
    }

    positionInDashboard(x: number, y: number): Point {
        let rect = this.obj.getBoundingClientRect();
        return new Point(x - rect.left, y - rect.top);
    }

    positionToPoint(x: number, y: number): Point {
        var rect = this.obj.getBoundingClientRect();

        if (rect.width == 0) {
            CsConsole('This should never happen!', ConsoleMessageType.Error);
        }

        return new Point(Math.floor(x * this.maxColumnCount() / rect.width), Math.floor(y / this.usedRowHeight()));
    }

    doItemsOverlapX(item: TwcTileboardItem, otherItem: TwcTileboardItem): boolean {
        let pos = item.getCurrentPosition();
        let posOther = otherItem.getCurrentPosition();

        return !(posOther.right < pos.left || pos.right < posOther.left);
    }

    doItemsOverlapY(item: TwcTileboardItem, otherItem: TwcTileboardItem): boolean {
        let pos = item.getCurrentPosition();
        let posOther = otherItem.getCurrentPosition();

        return !(posOther.bottom < pos.top || pos.bottom < posOther.top);
    }

    doItemsOverlap(item: TwcTileboardItem, otheritem: TwcTileboardItem): boolean {
        return this.doItemsOverlapX(item, otheritem) && this.doItemsOverlapY(item, otheritem);
    }
    //#endregion

    //#region Kontext Menüs
    handleOnTileboardContextmenu(eventObj: EventObj<MouseEvent>): void {
        // wenn wir von den Items oder so kommen ignorieren wir das Event. 
        if (eventObj.Event.target !== this.obj) {
            eventObj.Handled = true;
            return;
        }

        if (!this.isEditMode()) {
            // Später vllt. mal Actions im Ansehen Modus
        }
    }

    handleOnTileboardItemContextmenu(eventObj: EventObj<MouseEvent>, targetTileboardItem: TwcTileboardItem): void {
        if (!assigned(targetTileboardItem)) {
            eventObj.Handled = true; // dann muss auch nirgendwo in abgeleiteten Klassen weiter gemacht werden....
            return;
        }

        if (!this.isEditMode()) {
            // Später vllt. mal Actions im Ansehen Modus
        }
    }
    //#endregion

    //#region Moving Functions
    virtualMoveToPosition(itemToMove: TwcTileboardItem, position: Bounds): void {
        // virtual move
        itemToMove.setVirtualPosition(position.left, position.top);

        // andere Item im weg nach unten wegschieben
        this.items.forEach(item => {
            if (item.obj === itemToMove.obj) {
                return; // return simuliert continue in forEach
            }

            while (item.isInPosition(position)) {
                this.virtualMoveDown(item);
            }
        });
    }

    virtualMoveDown(itemToMove: TwcTileboardItem): void {
        // virtual move
        itemToMove.setVirtualY(itemToMove.getCurrentPosition().top + 1);

        this.items.forEach(item => {
            if (item.obj === itemToMove.obj) {
                return;
            }

            if (item.locked != true && itemToMove.isInPosition(item.getCurrentPosition())) {
                this.virtualMoveDown(item);
            }
        });
    }

    virtualMoveUp(itemToMove: TwcTileboardItem): void {
        // virtual move
        itemToMove.setVirtualY(itemToMove.getCurrentPosition().top - 1);

        this.items.forEach(item => {
            if (item.obj === itemToMove.obj) {
                return;
            }

            if (itemToMove.isInPosition(item.getCurrentPosition())) {
                this.virtualMoveDown(item);
            }
        });
    }

    virtualMoveRight(itemToMove: TwcTileboardItem): void {
        // virtual move
        itemToMove.setVirtualX(itemToMove.getCurrentPosition().left + 1);

        this.items.forEach(item => {
            if (item.obj === itemToMove.obj) {
                return;
            }

            while (itemToMove.isInPosition(item.getCurrentPosition())) {
                // simuliert den move wieder zurück
                itemToMove.setVirtualX(itemToMove.getCurrentPosition().left - 1);
                // Position des blockenden Items fixen
                this.virtualMoveDown(item);
                // und wieder selber moven
                itemToMove.setVirtualX(itemToMove.getCurrentPosition().left + 1);
            }
        });
    }

    virtualMoveLeft(itemToMove: TwcTileboardItem): void {
        // virtual move
        itemToMove.setVirtualX(itemToMove.getCurrentPosition().left - 1);

        this.items.forEach(item => {
            if (item.obj === itemToMove.obj) {
                return;
            }

            while (itemToMove.isInPosition(item.getCurrentPosition())) {
                // simuliert den move wieder zurück
                itemToMove.setVirtualX(itemToMove.getCurrentPosition().left + 1);
                // Position des blockenden Items fixen
                this.virtualMoveDown(item);
                // und wieder selber moven
                itemToMove.setVirtualX(itemToMove.getCurrentPosition().left - 1);
            }
        });
    }

    checkCanCondenseVertically(itemToMove: TwcTileboardItem): boolean {
        if (itemToMove.locked == true) {
            return false;
        }

        let p = itemToMove.getCurrentPosition();

        // wenn wir schon oben sind können wir nicht verdichten
        if (p.top === 0) {
            return false;
        }

        // jetzt können wir gucken ob da noch was geht
        for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];

            // uns selber können wir überspringen
            if (item.obj === itemToMove.obj) {
                continue;
            }

            let otherP = item.getCurrentPosition();
            if (p.top - 1 <= otherP.bottom && p.bottom - 1 >= otherP.top && this.doItemsOverlapX(itemToMove, item)) {
                // wenn das Tile oberhalb gelockt ist verdichten wir ggf. darüber hinaus, falls möglich...
                if (item.locked == true) {
                    // dazu simulieren wir den moveUp
                    itemToMove.setVirtualY(p.top - 1);
                    // wenn wir von da aus verdichten können, dann perfekt
                    if (this.checkCanCondenseVertically(itemToMove)) {
                        return true;
                    }
                    // ansonsten reverten wir die Aktion
                    else {
                        itemToMove.setVirtualY(p.top);
                    }
                }

                return false;
            }
        }

        this.virtualMoveUp(itemToMove);
        return true;
    }

    checkCanCondenseHorizontally(itemToMove: TwcTileboardItem): boolean {
        if (itemToMove.locked == true) {
            return false;
        }

        let p = itemToMove.getCurrentPosition();

        // wenn wir schon links sind können wir nicht verdichten
        if (p.left === 0) {
            return false;
        }

        // jetzt können wir gucken ob da noch was geht
        for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];

            // uns selber können wir überspringen
            if (item.obj === itemToMove.obj) {
                continue;
            }

            let otherP = item.getCurrentPosition();
            if (p.left - 1 <= otherP.right && p.right - 1 >= otherP.left && this.doItemsOverlapY(itemToMove, item)) {
                // wenn das Tile links gelockt ist verdichten wir ggf. darüber hinaus, falls möglich...
                if (item.locked == true) {
                    // dazu similieren wir den moveLeft
                    item.setVirtualX(p.left - 1);
                    // wenn wir von da aus verdichten können, dann perfekt
                    if (this.checkCanCondenseHorizontally(itemToMove)) {
                        return true;
                    }
                    else {
                        item.setVirtualX(p.left);
                    }
                }

                return false;
            }
        }

        this.virtualMoveLeft(itemToMove);
        return true;
    }

    condenseVertically(): void {
        let wasChanged = false;

        do {
            wasChanged = false;
            this.items.forEach(item => {
                wasChanged = wasChanged || this.checkCanCondenseVertically(item);
            });
        } while (wasChanged);
    }

    condenseHorizontally(): void {
        let wasChanged = false;

        do {
            wasChanged = false;
            this.items.forEach(item => {
                wasChanged = wasChanged || this.checkCanCondenseHorizontally(item);
            });
        } while (wasChanged);
    }
    //#endregion
}