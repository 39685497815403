import { ISignal, SignalDispatcher } from "strongly-typed-events";
import { LinkedList } from "../../classes/collections/linkedList";
import { notifyGlobalDirty } from "../../core/dispatcher";
import { MsgNotifyDEV } from "../../core/utils/msgnotify";
import { isDev } from "../../utils/devUtils";
import { assigned } from "../../utils/helper";
import { csJSONParse } from "../../utils/strings";
import { ComponentProperty, IWebComponent } from "../interfaces/class.web.comps.intf";
import { getComponentForElement, isRegisteredComponent } from "./controlling";

export abstract class TWebComponent implements IWebComponent {

    public webComponentDiscriminator: 'I-AM-IWebComponent' = 'I-AM-IWebComponent';

    // Following two properties only exist to monitor if super() methos are called correctly!
    private superInitComponentCalled: boolean;
    private superInitDomElementCalled: boolean;

    // normal properties
    private knownProperties: Map<string, ComponentProperty>;
    private transferDirty: boolean;
    private updatingLockCount: number;

    // Events
    private _onRegister = new SignalDispatcher();

    classtype: string;
    obj: HTMLElement;

    public get id(): string {
        return this.obj.id;
    }

    constructor(obj: HTMLElement) {

        // initialize super monitoring 
        this.superInitComponentCalled = false;
        this.superInitDomElementCalled = false;

        this.classtype = 'TWebComponent';
        this.obj = obj;
        this.updatingLockCount = 0;

        this.beginUpdate();
        try {
            this.initComponent();
            this.initDomElement();
        }
        finally {
            this.endUpdate();
        }

        if (isDev()) {
            this.validateSuperCalls();
        }
    }

    doInternalRegister(): void {
        this._onRegister.dispatchAsync();
    }

    public onRegister(): ISignal {
        return this._onRegister.asEvent();
    }

    isRegistered(): boolean {
        return isRegisteredComponent(this);
    }

    initComponent(): void {
        this.superInitComponentCalled = true;

        this.knownProperties = new Map<string, ComponentProperty>();
        this.transferDirty = false; // wurde die Komponente seit der letzten Übertragung an den Server verändert?
    }

    initDomElement(): void {
        this.superInitDomElementCalled = true;

        let dispatcherActions = csJSONParse(this.obj.dataset?.dispatcheractions ?? '[]');
        if (dispatcherActions !== undefined) {
            dispatcherActions.forEach((obj: { action: string; params: string; }) => {
                this.execAction(obj.action, obj.params);
            });
            this.obj.removeAttribute('data-dispatcheractions');
        }
    }

    private validateSuperCalls() {
        if (isDev()) {
            if (!this.superInitComponentCalled) {
                MsgNotifyDEV(`DEV NOTE 📓: You must call super.initComponent() in ${this.constructor.name}!`);
            }

            if (!this.superInitDomElementCalled) {
                MsgNotifyDEV(`DEV NOTE 📓: You must call super.initDomElement() in ${this.constructor.name}!`);
            }
        }
    }

    beginUpdate(): void {
        this.updatingLockCount++;
    }

    endUpdate(): void {
        this.updatingLockCount--;
    }

    isUpdating(): boolean {
        return this.updatingLockCount !== 0;
    }

    supportsTransferDirty(): boolean {
        return false;
    }

    isTransferDirty(): boolean {
        return this.transferDirty;
    }

    resetTransferDirty(): void {
        this.transferDirty = false;
    }

    notifyComponentChanged(): void {
        // we ignore changes if component is being updated
        if (this.isUpdating()) {
            return;
        }

        console.debug(`${this.id}: Content has changed.`);

        this.transferDirty = true;

        notifyGlobalDirty(this);
    }

    setFocus(): void {
        this.obj.focus();
    }

    bringToFront(): void {
        window.requestAnimationFrame(() => {
            let list = this.getComponentsPath();
            let currentComponentNode = list.head;

            while (assigned(currentComponentNode)) {
                currentComponentNode.value.setFocus();

                currentComponentNode = currentComponentNode.next;
            }
        });
    }

    getParentWebComponent(): IWebComponent | null {
        let element = this.obj.parentElement;
        while (assigned(element)) {
            let component = getComponentForElement(element);
            if (assigned(component)) {
                return component;
            }

            element = element.parentElement;
        }

        return null;
    }

    getComponentsPath(): LinkedList<IWebComponent> {
        let list = new LinkedList<IWebComponent>(this);
        let parentComponent = this.getParentWebComponent();

        while (assigned(parentComponent)) {
            list.prepend(parentComponent);

            parentComponent = parentComponent.getParentWebComponent();
        }

        return list;
    }

    execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.SetFocus':
                this.bringToFront();
                break;
        }
    }

    readProperties(): Array<ComponentProperty> {
        return [];
    }

    abstract writeProperties(key: string, value: string): void;

    getChangedProperties(): Array<ComponentProperty> {
        let result: Array<ComponentProperty> = [];
        let properties = this.readProperties();

        // nicht alle Komponenten implementieren ein readProperties
        if (!assigned(properties))
            return [];

        properties.forEach(prop => {
            let key = prop[1];
            let value = prop[2];

            if (this.knownProperties[key] != value) {
                result.push(prop);

                this.knownProperties[key] = value;
            }
        });

        return result;
    }

    // über knownProperties merken wir uns, was wir aktuell für properties haben, deswegen bei Änderung aktuell halten
    writeChangedProperties(key: string, value: string): void {
        // Change to update state to prevent notifyDirty due to server changes
        this.beginUpdate();
        try {
            this.knownProperties[key] = value;
            this.writeProperties(key, value);
        }
        finally {
            // reset update state
            this.endUpdate();
        }
    }
}

export type AfterRenderCallback = (timestamp?: DOMHighResTimeStamp) => void;

export abstract class TRenderWebComponent extends TWebComponent {
    private pendingAnimationFrames: Array<number>;

    override initComponent() {
        super.initComponent();

        this.pendingAnimationFrames = [];
    }

    override initDomElement() {
        super.initDomElement();

        // initial Darstellen
        this.invalidate();
    }

    private doInvalidate(forced?: boolean, afterRender?: AfterRenderCallback) {
        // clear old requests
        this.pendingAnimationFrames.forEach(animationHandle => {
            window.cancelAnimationFrame(animationHandle)
        });

        let renderProcedure = (timestamp: DOMHighResTimeStamp): void => {
            this.doRender(timestamp);

            if (assigned(afterRender)) {
                afterRender(timestamp);
            }
        }

        if (forced) {
            renderProcedure(performance.now());
        }
        else {
            this.pendingAnimationFrames.push(window.requestAnimationFrame(timestamp => {
                renderProcedure(timestamp);
            }));
        }
    }

    protected doRender(timestamp: DOMHighResTimeStamp): void {

    }

    invalidate(forced?: boolean, afterRender?: AfterRenderCallback) {
        // wenn wir updaten wird das eh am Ende automatisch einmal ausgelöst
        if (!this.isUpdating() || forced) {
            this.doInvalidate(forced, afterRender);
        }
    }

    override endUpdate() {
        super.endUpdate();

        // Fertig? Dann neu Darstellen
        if (!this.isUpdating()) {
            this.doInvalidate();
        }
    }
}

// UPS //
// Solange Prozesseditor und Viewer noch ausgelagert sind müssen wir es auch freigeben
window[TWebComponent.name] = TWebComponent;