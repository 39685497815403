import { LinkedList } from "../../classes/collections/linkedList";

export type ComponentProperty = [string, string, string];

export interface IWebComponent {
    webComponentDiscriminator: 'I-AM-IWebComponent';

    // properties
    obj: HTMLElement;
    classtype: string;
    id: string;

    doInternalRegister(): void;

    // methods
    beginUpdate(): void;
    endUpdate(): void;
    isUpdating(): boolean;

    supportsTransferDirty(): boolean;
    isTransferDirty(): boolean;
    resetTransferDirty(): void;
    notifyComponentChanged(): void;

    execAction(action: string, params: string): void;
    readProperties(): Array<ComponentProperty>;
    writeProperties(key: string, value: string): void;

    getChangedProperties(): Array<ComponentProperty>;
    writeChangedProperties(key: string, value: string): void

    getParentWebComponent(): IWebComponent | null;
    getComponentsPath(): LinkedList<IWebComponent>;

    /**
     * Sets the focus of the internal DOM object.
     * @remarks This does not ensures a component is visible in the front end.
     * @see bringToFront If you want to ensure, that the component is visible in the front end, use bringToFront instead.
     */
    setFocus(): void;
    /**
     * Ensures a component is visible in the front end and the focus is set to its DOM object.
     * @see setFocus If you only want a component to focus its DOM element, without making it visible, use setFocus instead.
     */
    bringToFront(): void;
}

export function isIWebComponent(object: any): boolean {
    return object.webComponentDiscriminator === 'I-AM-IWebComponent';
}

export function tryGetAsWebComponent(object: any): IWebComponent | null {
    if (isIWebComponent(object)) {
        return object as IWebComponent;
    }

    return null;
}