﻿import $ from 'jquery';
import 'long-press-event';
import UAParser from 'ua-parser-js';
import { WebCompEventHandlerAsync } from '../../../core/communication';
import { getBaseUrl } from '../../../core/endpoint';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { assigned } from '../../../utils/helper';
import { getParents } from '../../../utils/html';
import { b64DecodeUnicode, boolFromStr, csJSONParse } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


require('jquery.fancytree/dist/modules/jquery.fancytree');
require('jquery.fancytree/dist/modules/jquery.fancytree.glyph');
require('jquery.fancytree/dist/modules/jquery.fancytree.table');
require('jquery.fancytree/dist/modules/jquery.fancytree.dnd5');
require('jquery.fancytree/dist/modules/jquery.fancytree.filter');

export class TWebTree extends TWebComponent {
    InitialSelectedNode: string;
    IsMobile: boolean;
    IsMasterDetail: boolean;
    HasOnChange: boolean;
    HasOnRightClick: boolean;
    HasOnDblClick: boolean;
    Source: string;
    FullExpand: boolean;
    OnMobileClickEvent: any;
    toggleEffect: boolean;
    IsDragAndDrop: boolean;
    MainColumnIndex: number;
    treeContainer: HTMLElement;
    UseFilter: boolean;
    SearchField: HTMLInputElement;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TWebTree';
        this.InitialSelectedNode = '';
        this.IsMobile = false;
        this.IsMasterDetail = false;
        this.HasOnChange = false;
        this.Source = '';
        this.FullExpand = false;
        this.OnMobileClickEvent = null;
        this.toggleEffect = false;
        this.IsDragAndDrop = false;
        this.MainColumnIndex = 0;
        this.treeContainer = document.getElementById(`${this.id}-treecontainer`);
        this.UseFilter = false;

        this.HasOnChange = boolFromStr(this.obj.dataset.hasonchange);
        this.HasOnRightClick = boolFromStr(this.obj.dataset.hasonrightclick);
        this.HasOnDblClick = boolFromStr(this.obj.dataset.hasondblclick);
    }

    override initDomElement() {
        super.initDomElement();

        if (['mobile', 'tablet'].includes(new UAParser().getDevice().type)) {
            this.obj.addEventListener('long-press', event => this.internalOnRightClick(event));
        }
        else {
            this.obj.addEventListener('contextmenu', event => this.internalOnRightClick(event));
        }
    }

    setSourceB64(dataB64: string) {
        this.Source = String.raw`${b64DecodeUnicode(dataB64)}`;
    }

    getSource(): string | any {
        if (this.Source != '') {
            try {
                return csJSONParse(this.Source);
            } catch (err) {
                console.error('Fehler beim Laden des Baumes');
                console.error(err);
                return '';
            }
        } else {
            return {
                url: getBaseUrl(this.id),
                cache: true,
                data: {
                    key: '',
                    page: 'nextTreeLevel'
                },
                async: false,
                success: function (data) { } // wow...
            };
        }
    }

    /**
     * Event, wenn wir im MasterDetail UND mobilen Modus sind
     * mit anderen Worten: Wir haben auf den rechten Pfeil geklickt!
     * @param event 
     * @param data 
     */
    onMobileActivateClick(event: JQueryEventObject, data: Fancytree.EventData): void {
        let self: TWebTree = data.options.tree_obj as TWebTree;
        if (self.IsMobile && self.IsMasterDetail) {
            // erst disablen wir den Baum, damit wir keinen Doppelklick triggern können
            $('#' + this.id).fancytree('disable').removeClass('ui-fancytree-disabled');
            // jetzt verändern wir den Pfeil zum Loading-Spinner
            let chevron = data.node.tr.querySelector('span.cs-tree-mobile-chevron-marker');
            chevron.classList.remove('fa', 'fa-chevron-right');
            chevron.classList.add('has-cs-expander-spinner');
            chevron.innerHTML = '<i class="spinner-border spinner-border-sm" style="margin-left:-5px"></i>';

            self.onChange(event, data, () => {
                // jetzt verändern wir den Loading-Spinner zum Pfeil zurück
                let chevron = data.node.tr.querySelector('span.cs-tree-mobile-chevron-marker');
                chevron.innerHTML = '';
                chevron.classList.remove('has-cs-expander-spinner');
                chevron.classList.add('fa', 'fa-chevron-right');
                // Der Request ist fertig, wir können also den Tree wieder enablen
                $('#' + this.id).fancytree('enable');
                // und nun noch ggf. das OnMobileClickEvent
                if (self.OnMobileClickEvent) {
                    self.OnMobileClickEvent();
                }
                return true;
            });
        }
    }

    onActivateEntry(event: JQueryEventObject, data: Fancytree.EventData): void {
        let self: TWebTree = data.options.tree_obj as TWebTree;
        // mobile klicks werden anders gehandelt und zwar in onMobileActivateClick
        if (self.IsMobile && self.IsMasterDetail) {
            return;
        }
        // doppelklick-protection: Wir disablen den kompletten Tree (ohne, dass es sichtbar ist)
        $('#' + this.id).fancytree('disable').removeClass('ui-fancytree-disabled');
        // und onchange triggern
        self.onChange(event, data, () => {
            // Der Request ist fertig, wir können also den Tree wieder enablen
            $('#' + this.id).fancytree('enable');
            return true;
        });
    }

    onChange(event: JQueryEventObject, data: Fancytree.EventData, callbackOnError: (() => boolean)): void {
        let self: TWebTree = data.options.tree_obj as TWebTree;
        // wenn wir nicht initialisiert sind, wollen wir das onchange event nicht zum server senden,
        if (!self.HasOnChange) {
            $('#' + this.id).fancytree('enable');
            return;
        }
        // wenn wir uns serverseitig wechseln oder durch den click unterdrücken wir das doppelte Event
        if (self.isUpdating()) {
            $('#' + this.id).fancytree('enable');
            return;
        }
        WebCompEventHandlerAsync('OnSelectionChanged', event.target.id, callbackOnError);
    }

    renderColumns(event: JQueryEventObject, data: Fancytree.EventData): void {
        let treeObj = data.options.tree_obj;
        if (treeObj.IsMobile && treeObj.IsMasterDetail) {
            let node = data.node;
            if (node.data.sidebar) {
                let cells = node.tr.cells;
                let chevron = document.createElement('span');
                chevron.classList.add('fancytree-custom-icon', 'fa', 'fa-chevron-right', 'float-end', 'cs-tree-mobile-chevron-marker');
                cells[0].appendChild(chevron);
            }
        }
    }

    // Selektiert das erste Element, falls noch keins selektiert wurde
    activateFirstEntry() {
        let tree = $.ui.fancytree.getTree('#' + this.id);
        let node = tree.getActiveNode();
        // wir haben noch keine node
        if (node === null) {
            let node = tree.getFirstChild();
            if (node != null) {
                // das erste Element steht ganz oben, da muss man nichts scrollen
                tree.activateKey(node.key);
                node.scrollIntoView(false);
            }
        }
    }

    afterRender(event: JQueryEventObject, data: Fancytree.EventData): void {
        let tree_obj = data.options.tree_obj;
        tree_obj.beginUpdate();
        try {
            tree_obj.markTreeInitial();
        } finally {
            tree_obj.endUpdate();
        }
    }

    markTreeInitial(): void {
        let key = undefined;

        // Soll ein Node initial selektiert werden?
        if (this.InitialSelectedNode.trim() != '') {
            key = this.InitialSelectedNode;
        }

        this.markTree(key);

        if (assigned(key) && this.IsMobile) {
            // wenn wir per Url-Aufruf starten, wollen wir den Content einblenden, also die Sidebar anzeigen
            $(function () {
                $('[data-bs-toggle="sidebar"]')[0].click();
            });
        }
    }

    markTree(key: string): void {
        if (key !== undefined) {
            this.openTreepath(key);
        } else {
            this.activateFirstEntry();
        }
    }

    getTreeExtensions(IsMobile: boolean): string[] {
        let result = ['glyph'];
        if (IsMobile) {
            result.push('table');
        };
        if (this.IsDragAndDrop) {
            result.push('dnd5');
        };
        if (this.UseFilter) {
            result.push('filter');
            let self = this;
            let searchComponent = document.getElementById(`${this.id}-input`);
            searchComponent.addEventListener('keyup', function (event) {
                try {
                    let tree = $.ui.fancytree.getTree(self.obj);
                    let opts = {};
                    let filterFunc = tree.filterNodes;
                    let match = $(this).val();
                    (match !== '') ? filterFunc.call(tree, match, opts) : tree.clearFilter();
                } catch (error) {
                    console.log(error);
                }

            });
        }
        return result;
    }

    onDblClick(event: JQueryEventObject, data: Fancytree.EventData): boolean {
        // this ist hier der Baum, nicht das Component Object!
        let tree_obj = data.options.tree_obj;
        if (tree_obj.HasOnDblClick) {
            event.preventDefault();
            WebCompEventHandlerAsync('OnDblClick', this.id);
        }
        return true;
    }

    internalOnRightClick(event: Event): void {
        if (this.HasOnRightClick) {
            // wenn wir im mobilen modus sind und eine MasterDetail Ansicht haben
            if (this.IsMobile && this.IsMasterDetail && this.HasOnChange) {
                if (!this.isUpdating()) {
                    // hier sagen wir den Server, dass wir eine neue Selection haben.
                    // Ohne diese Info bezieht sich der rechte Klick im mobilen Modus auf das davor gewählte Objekt
                    WebCompEventHandlerAsync('OnSelectionChanged', this.id);
                }
            }
            event.preventDefault();
            let node = $.ui.fancytree.getNode(event.target as Element);
            this.setActiveNode(node);
            WebCompEventHandlerAsync('OnRightClick', this.id);
        }
    }

    internalOnClick(event: JQueryEventObject, data: Fancytree.EventData): boolean {
        let self = data.options.tree_obj as TWebTree;
        if (data.targetType != 'expander') {
            if (self.IsMobile) {
                if (self.IsMasterDetail) {
                    // Mobile und MasterDetail
                    // wenn wir mobil sind, dann laden wir nur die Daten, wenn auf den rechten Pfeil geklickt wurde
                    if (data.targetType == 'icon' && data.originalEvent.target.classList.contains('cs-tree-mobile-chevron-marker')) {
                        // hier müssen wir nachhelfen! Leider wird der klick zuerst ausgelöst und das aktive Node ist dann noch das, 
                        // was vorher selektiert war
                        let node = $.ui.fancytree.getNode(data.originalEvent.target as Element);
                        self.setActiveNode(node);
                        // und nun unser mobiler klick
                        self.onMobileActivateClick(event, data);
                    } else {
                        if (data.node.isActive()) {
                            data.node.setExpanded(!data.node.expanded);
                            // MsgNotify('Expand/Collapse', 2);
                        }
                    }
                } else {
                    if (data.node.isActive()) {
                        data.node.setExpanded(!data.node.expanded);
                        // MsgNotify('Expand/Collapse', 2);
                    }
                }
            }
        }
        return true;
    }

    internalOnSelect(event: JQueryEventObject, data: Fancytree.EventData): boolean {
        let self = data.options.tree_obj as TWebTree;

        // Wir sagen dem Server, welcher Key checked/unchecked wurde
        let request = {};
        request['checked'] = data.node.isSelected();
        request['key'] = data.node.key;
        WebCompEventHandlerAsync('OnChecked', self.id, () => { }, JSON.stringify(request));

        return true;
    }


    override readProperties(): Array<ComponentProperty> {
        let tree = $.ui.fancytree.getTree('#' + this.id);
        let node = tree.getActiveNode();
        let selectedNode = '';
        // wir haben noch keine node
        if (assigned(node)) {
            selectedNode = node.key;
        }
        let properties = [];
        properties.push([this.id, 'SelectedNode', selectedNode]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'HasOnChange':
                this.HasOnChange = value == '1';
                break;
            case 'IsMasterDetail':
                this.IsMasterDetail = value == '1';
                break;
            case 'CloseToSidebar':
                if (this.OnMobileClickEvent) {
                    this.OnMobileClickEvent();
                }
                break;
            case 'SelectedNode':
                this.markTree(value);
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.ReloadTree':
                this.Source = params;
                this.reloadTree();
                break;
            case 'Action.ActivateEntry':
                this.markTree(params);
                break;
            case 'Action.RefreshItem':
                this.refreshItem(csJSONParse(params));
                break;
            case 'Action.ExpandTree':
                this.expandTree();
                break;
            case 'Action.CollapseTree':
                this.collapseTree();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    expandTree() {
        this.toggleExpandTree(true);
    }

    collapseTree() {
        this.toggleExpandTree(false);
    }

    async setTreeExpanded(tree: Fancytree.Fancytree): Promise<any> {
        let promiseArr = [];
        // wir füllen erstmal unser promise array
        tree.visit(function (node) {
            let p1 = node.setExpanded(true, { noAnimation: true, noEvents: false, scrollIntoView: false });
            promiseArr.push(p1);
        });
        // und dann starten wir alle durch
        return Promise.all(promiseArr);
    }

    async toggleExpandTree(doExpandTree) {
        let tree = $.ui.fancytree.getTree('#' + this.id);
        if (doExpandTree) {
            // laut doku sollte das hier gehen, macht es aber nicht
            // tree.expandAll(true);
            // deswegen so: erstmal alles aufklappen per Promise
            await this.setTreeExpanded(tree);
            // und dann zum aktiven node springen, wenn es eins gibt
            let node = tree.getActiveNode();
            if (node) {
                node.makeVisible({ scrollIntoView: true });
            }
        } else {
            tree.expandAll(false);
        }
    }
    setTreeOptions(selector: string): void {
        $(selector).fancytree('option', 'activeVisible', true);
        // Standardmäßig immer zum selektieren Element scrollen
        $(selector).fancytree('option', 'autoScroll', true);
        $(selector).fancytree('option', 'focusOnSelect', true);
        $(selector).fancytree('option', 'toggleEffect', false);
        $(selector).fancytree('option', 'keyboard', true);
        $(selector).fancytree('option', 'autoActivate', false);
        $(selector).fancytree('option', 'clickFolderMode', 1);
    }

    nodeRender(event: JQueryEventObject, data: Fancytree.EventData): void {
        let node = data.node;
        if (node.data) {
            let $span = $(node.span);

            // wenn wir mobil sind, dann müssen wir etwas kleiner sein, da wir noch rechts den klick-pfeil haben
            if (data.options.tree_obj.IsMobile) {
                $span.find('span.fancytree-title').css({
                    'white-space': 'nowrap',
                    'margin': '0 0 0 5px',
                    'text-overflow': 'ellipsis',
                    'overflow': 'hidden'
                });
            } else {
                $span.find('span.fancytree-title').css({
                    'white-space': 'nowrap',
                    'margin': '0 0 0 5px'
                });
            }

            $(node.tr).find('>td').eq(1).css({
                'width': '10%'
            });
        }
    }

    postProcess(event: JQueryEventObject, data: Fancytree.EventData): void {
        data.result = data.response.nodes;
    }

    lazyLoad(event: JQueryEventObject, data: Fancytree.EventData) {
        let BaseURL = getBaseUrl(event.target.id);
        let node = data.node;
        data.result = {
            url: BaseURL,
            data: {
                key: node.key,
                page: 'nextTreeLevel'
            }
        }
    }

    initTree() {
        let tree_obj = this;
        let selector = '#' + this.id;
        $(selector).fancytree({
            extensions: tree_obj.getTreeExtensions(tree_obj.IsMobile),
            glyph: {
                preset: 'awesome5',
                map: {
                    expanderClosed: 'fa-solid fa-chevron-right',
                    expanderLazy: 'fa-solid fa-chevron-right',
                    expanderOpen: 'fa-solid fa-chevron-down',
                    loading: 'spinner-border cs-expander-spinner',
                    dropMarker: 'fa-solid fa-arrow-alt-right',
                    nodata: '',
                }
            },
            strings: {
                loading: 'Laden...', // &#8230; would be escaped when escapeTitles is true
                loadError: 'Ladefehler!',
                moreData: 'Weitere Daten laden...',
                noData: '', // MUSS VIA VIEW SELBST IMPLEMENTIERT WERDEN!!!
            },
            dnd5: {
                // https://github.com/mar10/fancytree/wiki/ExtDnd5
                autoExpandMS: 1500,
                dropMarkerOffsetX: -50,
                dropMarkerInsertOffsetX: -18,
                multiSource: false,
                preventForeignNodes: true,
                preventNonNodes: false,
                preventRecursion: true,
                preventVoidMoves: true,
                scroll: true,
                scrollSensitivity: 20,
                scrollSpeed: 5,
                dragStart: function (node: Fancytree.FancytreeNode, data: any) {
                    // wir müssen den Marken in die richtige z-Index Position setzen..zb. im Modalen dialog
                    let arrow = document.getElementById('fancytree-drop-marker');
                    let parents = getParents(document.getElementById(tree_obj.id));
                    for (let i = 0; i < parents.length; i++) {
                        if (parents[i]?.classList?.contains('modal')) {
                            // dann setzen wir unseren z-Index neu!
                            arrow.style.zIndex = String((parents[i] as HTMLElement).style.zIndex + 1);
                        }
                    }

                    data.effectAllowed = "all";  // or 'copyMove', 'link'', ...
                    data.dropEffect = data.dropEffectSuggested;
                    return this.IsDragAndDrop;
                },
                dragDrag: function (node: Fancytree.FancytreeNode, data: any) {
                    // do nothing
                },
                dragEnd: function (node: Fancytree.FancytreeNode, data: any) {
                    if (assigned(node.tr)) {
                        // wir löschen unsere css klasse, die für den hover bestimt ist
                        const rows = node.tr.parentElement.querySelectorAll('tr');
                        for (let i = 0; i < rows.length; i++) {
                            rows[i].classList.remove('fancy-drag-hover');
                        }
                    }
                },
                dragEnter: function (node: Fancytree.FancytreeNode, data: any) {
                    if (assigned(node.tr)) {
                        const rows = node.tr.parentElement.querySelectorAll('tr');
                        for (let i = 0; i < rows.length; i++) {
                            // wir löschen unsere css klasse, die für den hover bestimt ist
                            rows[i].classList.remove('fancy-drag-hover');
                        }
                        node.tr.classList.add('fancy-drag-hover');
                    }

                    return true;
                },
                dragOver: function (node: Fancytree.FancytreeNode, data: any) {
                    data.dropEffect = data.dropEffectSuggested;
                },
                dragExpand: function (node: Fancytree.FancytreeNode, data: any) {
                    // do nothing
                },
                dragLeave: function (node: Fancytree.FancytreeNode, data: any) {
                    // do nothing
                },
                dragDrop: function (node: Fancytree.FancytreeNode, data: any) {
                    let tree_ID = data.options.tree_obj.id;
                    let BaseURL = getBaseUrl(tree_ID);
                    // Hier lösen wir das via Ajax aus, d.h. wir könnten im Server noch entscheiden, ob wir 
                    // via Rückgabe das Verschieben verweigern (dann müssten wir hier ein weiteres Moveto im Done ausführen)
                    $.ajax({
                        url: BaseURL,
                        data: {
                            key: data.otherNode.key,
                            newParentKey: node.key,
                            hitMode: data.hitMode,
                            page: 'nodeDropRequest'
                        },
                        async: false,
                        dataType: 'json',
                        success: function (result) {
                            // Wenn alles erfolgreich gelaufen ist, dann geben wir die Info erneut zum Server, diesmal via Dispatcher
                            if (result.success) {
                                WebCompEventHandlerAsync('nodeDropFinished', tree_ID, () => { });
                                // wir dürfen im Web verschieben
                                node.setExpanded(true).always(function () {
                                    // Wait until expand finished, then add the additional child
                                    data.otherNode.moveTo(node, data.hitMode);
                                    // Ausklappen und unsere verschobene Node wieder auswählen
                                    node.setExpanded(true);
                                    data.otherNode.setActive(true);
                                });
                            } else {
                                MsgNotify(result.errorMsg, MessageType.Warning);
                            }
                        }
                    })
                    return true;
                }
            },
            source: tree_obj.getSource(),
            lazyLoad: tree_obj.lazyLoad,
            postProcess: tree_obj.postProcess,
            init: tree_obj.afterRender,
            renderNode: tree_obj.nodeRender,
            tooltip: true,
            click: tree_obj.internalOnClick,
            dblclick: tree_obj.onDblClick,
            select: tree_obj.internalOnSelect,
            activate: tree_obj.onActivateEntry,
            table: {
                checkboxColumnIdx: 0,
                indentation: 16, // default
                nodeColumnIdx: tree_obj.MainColumnIndex // default
            },
            renderColumns: tree_obj.renderColumns,
            // // toggleEffect animation can get stuck
            // // https://github.com/mar10/fancytree/issues/864
            toggleEffect: false,
            // // Das ist nachher in data.Options in Events sichtbar
            tree_obj: tree_obj,
            quicksearch: this.UseFilter,
            filter: {
                autoApply: true,   // Re-apply last filter if lazy data is loaded
                autoExpand: true, // Expand all branches that contain matches while filtered
                counter: false,     // Show a badge with number of matching child nodes near parent icons
                fuzzy: false,      // Match single characters in order, e.g. 'fb' will match 'FooBar'
                hideExpandedCounter: true,  // Hide counter badge if parent is expanded
                hideExpanders: false,       // Hide expanders if all child nodes are hidden by filter
                highlight: true,   // Highlight matches by wrapping inside <mark> tags
                leavesOnly: false, // Match end nodes only
                nodata: true,      // Display a 'no data' status node if result is empty
                mode: "hide"       // Grayout unmatched nodes (pass "hide" to remove unmatched node instead)
            },
        });
        tree_obj.setTreeOptions(selector);

        // inital alles aufklappen?
        // der Filter funktioniert komischerweise nur wenn vorher einmal aufgeklappt wurde
        if (this.FullExpand) {
            this.expandTree();
        }

        this.initHistoryHandling();
        this.initFilterInput();
    }

    initFilterInput() {
        let searchComponent = document.getElementById(`${this.id}-input`);
        // nur falls wir die auch nutzen
        if (assigned(searchComponent)) {
            let self = this;
            searchComponent.addEventListener('keyup', function (event) {
                let tree = $.ui.fancytree.getTree(self.obj);
                let opts = {};
                let filterFunc = tree.filterNodes;
                let match = $(this).val();
                (match !== '') ? filterFunc.call(tree, match, opts) : tree.clearFilter();
            });
        }
    }

    initHistoryHandling(): void {
        window.addEventListener('popstate', event => this.handlePopStateChaned(event));
    }

    handlePopStateChaned(event): void {
        location.reload();
    }

    refreshItem(itemJSON) {
        // Wir holen den Node aus dem Baum
        let tree = $.ui.fancytree.getTree(this.obj);
        let node = tree.getNodeByKey(itemJSON.key);
        if (node) {
            // Im Moment brauchen wir hier nur den Title, kann beliebig erweitert werden
            // abhängig davon, was der Server alles liefert
            node.title = itemJSON.title;
            node.icon = itemJSON.icon;
            node.setSelected(itemJSON.selected);
            // Aktualisieren
            node.render(true);
        }
    }

    reloadTree(): void {
        // Source neu einladen
        let tree = $.ui.fancytree.getTree('#' + this.id);
        tree.reload(this.getSource());
    }

    async setActiveNode(node: Fancytree.FancytreeNode): Promise<void> {
        if (node) {
            await node.setActive(true);
            // zum aktiven Node expandieren und ggf. scrollen
            await node.makeVisible({ scrollIntoView: true });
        }
    }

    async openTreepath(key: string): Promise<void> {
        // Wenn wir keinen Key haben, können wir uns die Anfrage sparen...
        if (!key) {
            return;
        }

        let tree = $.ui.fancytree.getTree(this.obj);
        let node = tree.getNodeByKey(key);
        // Falls wir das Element schon kennen, können wir uns die Anfrage sparen...
        if (node) {
            // promise!
            this.beginUpdate();
            try {
                await this.setActiveNode(node);
                return;
            } finally {
                this.endUpdate();
            }
        } else {
            this.openTreepathAjax(key);
        }
    }

    openTreepathAjax($key: string | boolean): any {

        // Wenn wir keinen Key haben, können wir uns die Anfrage sparen...
        if (!$key) {
            return;
        }

        function getData(page, aBaseURL, $key) {
            let $data;
            $.ajax({
                url: aBaseURL,
                data: {
                    key: $key,
                    page: page
                },
                async: false,
                dataType: 'json'
            }).done(function (data) {
                $data = data;
            });
            return $data;
        }

        let BaseURL = getBaseUrl(this.id);
        let tree = $.ui.fancytree.getTree(this.obj);
        let nodes = getData('treePath', BaseURL, $key).nodes;

        nodes.reverse();

        let activeNode: Fancytree.FancytreeNode = null;
        for (let i = 0, len = nodes.length; i < len; i++) {
            let jsonObj = getData('nextTreeLevel', BaseURL, nodes[i]).nodes;
            activeNode = tree.getNodeByKey(nodes[i]);
            if (activeNode) {
                if (!activeNode.hasChildren()) {
                    activeNode.addChildren(jsonObj);
                }
            }
        }

        if (assigned(activeNode)) {
            tree.activateKey($key);
        }
    }
}

// Delphi greift da direkt drauf zu 🤮
window[TWebTree.name] = TWebTree;