import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr, strFromBool } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TWebRadioButton extends TWebComponent {
    radioObj: HTMLInputElement;
    groupId: string;
    hasOnChange: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TWebRadioButton';

        this.radioObj = AsType<HTMLInputElement>(this.obj);
        this.groupId = this.radioObj.dataset.groupid;
        this.hasOnChange = boolFromStr(this.radioObj.dataset.hasonchange);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.radioObj.addEventListener('change', () => this.handleOnChanged());
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleOnChanged() {
        // da wir ein anderes Element in der Gruppe automatisch deselktieren müssen die anderen Elemente der Gruppe korrigiert werden
        let otherRadioButtons = document.querySelectorAll(`[data-type="${this.classtype}"][name="${this.groupId}"]`);
        otherRadioButtons.forEach(radiobutton => {
            let comp = findComponent(radiobutton.id);
            comp.notifyComponentChanged(); // durch das automatische deselektieren müssen wir die auch dirty setzen
        });

        if (this.hasOnChange)
            WebCompEventHandler('OnChange', this.id);
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Checked', strFromBool(this.radioObj.checked, true)]);
        return properties;
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Checked':
                this.radioObj.checked = boolFromStr(value);
                break;
            case 'Enabled':
                this.radioObj.disabled = !boolFromStr(value);
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}