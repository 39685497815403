﻿import { WebCompEventHandler, WebCompEventHandlerAsync } from '../../../core/communication';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';
import { TwcTabItem } from './class.web.comp.tabitem';

export class TwcTabPanel extends TWebComponent {
    protected activeTabID: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTabPanel';
        this.activeTabID = undefined;
    }

    override initDomElement() {
        super.initDomElement();

        // Falls wir im Modal sind, wollen wir immer den z-index des Modals nehmen
        let modalContainer = getModalDialogContainer(this.obj) as HTMLElement;
        if (assigned(modalContainer)) {
            (this.obj.querySelector('.nav') as HTMLElement).style.zIndex = modalContainer.parentElement.style.zIndex;
        }
    }

    setActiveTab(componentOrId: TwcTabItem | string): void {
        if (componentOrId instanceof TwcTabItem) {
            let tabItem = componentOrId;
            this.activeTabID = tabItem.id;

            tabItem.show();
            WebCompEventHandlerAsync('OnChange', this.id);
        }
        else {
            let component = findComponent(componentOrId);
            if (!assigned(component)) {
                throw `Cannot find component for ${componentOrId}.`;
            }
            if (!(component instanceof TwcTabItem)) {
                throw `Component ${componentOrId} is not a valid tab item.`;
            }

            this.setActiveTab(component);
        }
    }

    getActiveTabID(): string {
        if (!assigned(this.activeTabID)) {
            let activeTab = $('#' + this.id + '-UL li.nav-item').find('.active');
            if (activeTab.length == 1) {
                // wenn wir ein Tabpanel haben aber keine Tabs drin sind, klappt split nicht!
                this.activeTabID = activeTab.attr('id').split('-')[0]; // '-tab' absplitten;
            }
        }

        return this.activeTabID;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([$(this.obj).attr('id'), 'ActiveTabID', this.getActiveTabID()]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'ActiveTabID':
                this.setActiveTab(value);
                break;
            case 'Visible':
                if (value == '1') {
                    $('#' + this.id + '-tabpnlcontainer').removeClass('d-none');
                } else {
                    $('#' + this.id + '-tabpnlcontainer').addClass('d-none');
                }
        }
    }
}

$(document).on('shown.bs.tab', 'a[data-bs-toggle="tab"]', function (e) {
    // content event
    let tabItemId = $(e.target).data('contentid');
    let tabPanelId = document.getElementById(tabItemId)?.dataset?.tabid;

    if (tabPanelId !== undefined) {
        let tabPanelComponent = findComponent(tabPanelId) as TwcTabPanel;
        tabPanelComponent.setActiveTab(tabItemId);
    }

    // UPS // Wir feuern hier beide Events onClick und onClicked direkt nacheinander, ist das richtig so?
    if ($('#' + tabItemId).data('onclickevent') == true) {
        // wenn OnClick Assigned ->  triggern wir das OnClick durch die Nutzung der Tab-Eventlistener
        WebCompEventHandler('OnClick', tabItemId);
    }

    if ($('#' + tabItemId).data('onclickedevent') == true) {
        // wenn OnClicked Assigned -> triggern wir das OnClicked durch die Nutzung der Tab-Eventlistener
        WebCompEventHandler('OnClicked', tabItemId);
    }
});
