import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeLineHeightAction extends TCustomTutorialShapeAction {
    oldLineHeight: number;
    newLineHeight: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldLineHeight = this.refElement.margin.left;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeMargins;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldLineHeight = obj.oldLineHeight;
        this.newLineHeight = obj.newLineHeight;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        
        obj.oldLineHeight = this.oldLineHeight;
        obj.newLineHeight = this.newLineHeight;

        return obj;
    }

    setValue(lineHeight: number): void {
        this.newLineHeight = lineHeight;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setLineHeight(this.newLineHeight);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeLineHeightAction(this.refElement, this.svgEditor);
        result.setValue(this.oldLineHeight);

        return result;
    }
}