import { sendComponentRequestGetJson } from "../../../core/communication";
import { AsType } from "../../../utils/html";
import { FileSelectorCommand, FileSelectorRequests, TwcCustomFileSelector } from "./class.web.comp.fileselector.base";

export class TwcSingleFileSelector extends TwcCustomFileSelector {

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcSingleFileSelector';
    }

    override initDomElement(): void {
        super.initDomElement();

        $(this.htmlNode).children('.upload-button').click(event => this.triggerFileSelect());
        $(this.htmlNode).children('.upload-hidden').change(event => this.handleFileSelect(event));
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        // den upload Button bei readonly deaktivieren
        if (this.readOnly) {
            $(this.htmlNode).children('.upload-button').addClass('d-none');
        }
        else {
            $(this.htmlNode).children('.upload-button').removeClass('d-none');
        }

        let previewHTML = "";

        if (this.files.length === 1) {
            previewHTML += '<div id="' + this.files[0].id + '-link" class="file-thumbnail cursor-pointer float-start">'
            previewHTML += this.files[0].fileName + ' ' + this.bytesToSize(this.files[0].fileSize);
            previewHTML += '</div>';
            if (!this.readOnly) {
                previewHTML += '<div id="' + this.files[0].id + '-delete" class="cursor-pointer float-start">';
                previewHTML += '<i class="fa-light fa-trash"></i>';
                previewHTML += '</div>';
            }

            $(this.htmlNode).find('.preview').html(previewHTML);

            $('#' + this.files[0].id + '-link').click(function () {
                let cmd = new FileSelectorCommand();
                cmd.command = FileSelectorRequests.downloadFile;
                cmd.data = this.files[0].id;
                this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd))
            }.bind(this));

            if (!this.readOnly) {
                $('#' + this.files[0].id + '-delete').click(function () {
                    let cmd = new FileSelectorCommand();
                    cmd.command = FileSelectorRequests.removeFile;
                    cmd.data = this.files[0].id;
                    this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd))
                    this.updateRemoteFiles();
                }.bind(this));
            }
        }
        else {
            $(this.htmlNode).find('.preview').html('');
        }

    }

    triggerFileSelect() {
        $(this.htmlNode).children('.upload-hidden').trigger('click');
    }

    handleFileSelect(evt: JQuery.ChangeEvent<HTMLElement, null, HTMLElement, HTMLElement>) {
        evt.stopPropagation();
        evt.preventDefault();

        let localFiles = $(this.htmlNode).children('.upload-hidden').prop('files');

        for (let index = 0; index < localFiles.length; index++) {
            this.uploadFile(localFiles[index]);
        }

        // danach müssen wir das input Feld resetten, falls man die selbe Datei nochmals auswählen will.
        AsType<HTMLInputElement>(this.htmlNode.querySelector('.upload-hidden')).value = '';
    }
}