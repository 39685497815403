import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeHorizontalAlignAction extends TCustomTutorialShapeAction {
    oldAlign: number;
    newAlign: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldAlign = this.refElement.fontSize;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeRotation;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldAlign = obj.oldAlign;
        this.newAlign = obj.newAlign;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldAlign = this.oldAlign;
        obj.newAlign = this.newAlign;

        return obj;
    }

    setValue(newAlign: number): void {
        this.newAlign = Math.round(newAlign);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setAlignHorizontal(this.newAlign);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeHorizontalAlignAction(this.refElement, this.svgEditor);
        result.setValue(this.oldAlign);

        return result;
    }
}