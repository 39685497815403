//import { TabulatorFull as Tabulator } from 'tabulator-tables';
//import { Tabulator } from 'tabulator-tables';
//import {Tabulator} from 'https://unpkg.com/tabulator-tables@5.3.1/dist/js/tabulator.min.js';

import { WebCompEventHandler } from '../../../core/communication';
import { getBaseUrl } from '../../../core/endpoint';
import { isComponentActive } from '../../../core/utils/componentUtils';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { getParentByClassname } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


var Tabulator = require('tabulator-tables');
export class TwcTableGrid extends TRenderWebComponent {

    tableElement: typeof Tabulator;
    tableId: any;
    headerColumns: Array<Object>;
    selectedRowIndex: string;
    initialSelectedRowDone: boolean;
    hasOnChange: boolean;
    hasOnRightClick: boolean;
    hasOnDblClick: boolean;
    hasCanSelect: boolean;
    modalContainer: Element;
    optimizePosAllowed: boolean;
    needsOptimization: boolean;
    tableHeightUsed: boolean;
    minHeight: number;
    private resizeObserver: ResizeObserver;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcTableGrid';
        this.tableId = `#${this.id}-table`;

        this.hasOnChange = boolFromStr(this.obj.dataset.hasonchange);
        this.hasOnRightClick = boolFromStr(this.obj.dataset.hasonrightclick);
        this.hasOnDblClick = boolFromStr(this.obj.dataset.hasondblclick);
        this.hasCanSelect = boolFromStr(this.obj.dataset.hascanselect);
        this.selectedRowIndex = this.obj.dataset.selectedrowindex;
        this.initialSelectedRowDone = false;
        // initial Laden, damit wir das später nicht immer machen müssen
        this.modalContainer = getModalDialogContainer(this.obj);
        // Height renderering erst erlauben, wenn Daten soweit sind
        this.optimizePosAllowed = false;
        this.needsOptimization = false;
        this.tableHeightUsed = true;
        // initiale min-height merken
        this.minHeight = parseInt(this.obj.style.height);
    }

    override initDomElement() {
        super.initDomElement();

        this.initHeaderColumns();
        this.initTable();

        // Wenn wir das Browserfenster verändern, muss ggf. die Höhe der Tabelle angepasst werden
        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                this.needsOptimization = true;
                // wenn wir aber im modalen dialog sind sollten wir bein letzen schließen hier nichts mehr optimieren
                // deswegen suchen wir nach einer validen id im DOM (beim schließen gibt es die nicht mehr...)
                if (this.isInsideModal() && !assigned(document.getElementById(this.id))) {
                    this.needsOptimization = false;
                }
                this.invalidate();
            });
        });

        if (this.isInsideModal()) {
            // obacht: wir geben wegen einem scoll-Bug den modalen Dialogen eine Klasse modal-noscroll-
            // dadurch verändert sich document.body nicht mehr in der Höhe, solange ein Modal geöffnet ist
            // deswegen horchen wsir hier auf den modalen dialog
            this.resizeObserver.observe(this.modalContainer);
        } else {
            this.resizeObserver.observe(this.obj);
        }
    }

    initHeaderColumns(): void {
        let headercols = this.obj.dataset?.headercols;
        if (headercols != '') {
            try {
                this.headerColumns = JSON.parse(headercols);
            } catch (err) {
                console.error('Fehler beim Laden der Header');
                console.error(err);
            }
        }
    }

    initTable(): void {
        let self = this;
        this.tableElement = new Tabulator(this.tableId, {
            placeholder: this.obj.dataset.placeholder,
            paginationSize: this.obj.dataset.paginationsize,
            // https://tabulator.info/docs/5.4/data#array-initial sortmode
            ajaxLoaderLoading: '<span>Loading Data</span>',
            progressiveLoad: 'scroll',
            // progressiveLoadScrollMargin:300, //trigger next ajax load when scroll bar is 300px or less from the bottom of the table. default is 2*tableheight
            height: '100%', // ohne height-Angabe, laden wir endlos!
            columns: this.headerColumns,
            ajaxResponse: function (url, params, response) {
                //url - the URL of the request
                //params - the parameters passed with the request
                //response - the JSON object returned in the body of the response.
                return response; //return the tableData property of a response json object
            },
            sortMode: 'remote', //send sort data to the server instead of processing locally
            columnHeaderSortMulti: false, // im ersten Step nur eine Spalte erlauben
            selectable: this.hasCanSelect ? 1 : false,
            selectableCheck: function (row: any) {
                let selRows = self.tableElement.getSelectedData();
                let rowData = row.getData();
                // ist in den selRows unsere übergebene RowIndex enthalten? dann ist die Selektion verboten
                if (selRows.some(data => data.id === rowData.id)) {
                    return false;
                }

                return true;
            },
            columnDefaults: {
                tooltip: function (e, cell, onRendered) {
                    // falls wir in der Zelle HTML haben, wollen wir das nicht!
                    return cell.getValue();
                },
            },
            headerSortElement: function (column, dir) {
                switch (dir) {
                    case 'asc':
                        return '<i class="fa-duotone fa-sort-up">';
                    case 'desc':
                        return '<i class="fa-duotone fa-sort-down">';
                    default:
                        // Spalten ohne Sortierung zeigen kein Icon an
                        return '';
                }
            },
            initialSort: [
                { column: this.obj.dataset.sortcol, dir: boolFromStr(this.obj.dataset.sortasc) ? 'asc' : 'desc' }
            ],
            layout: 'fitColumns',
            // tooltipDelay: 500,
        });

        // Tooltip immer zu machen, wenn wir aktiv klicken, da es sonst ggf. nicht mehr weg geht
        this.tableElement.on('cellClick', () => {
            this.tableElement.modules.tooltip.clearPopup();
        });
        this.tableElement.on('cellDblClick', () => {
            this.tableElement.modules.tooltip.clearPopup();
        });
        this.tableElement.on('cellContext', () => {
            this.tableElement.modules.tooltip.clearPopup();
        });

        this.tableElement.on('tableBuilt', () => {
            // jetzt füllen wir die Tabelle mit Daten
            let baseURL = getBaseUrl(this.id);
            this.tableElement.setData(baseURL);
            // Tabelle wurde mit den ersten Daten gefüllt, daher darf nun die Höhe optimiert werden
            this.optimizePosAllowed = true;
        });

        this.tableElement.on('rowSelected', (row: any) => {
            // wir speichern die aktuelle row die ausgewählt ist mal zwischen
            let currentSelectedRowIndex = this.selectedRowIndex;
            this.selectedRowIndex = String(row.getData().id);

            // ist die alte id ungleich der neuen, dann change event 
            if (currentSelectedRowIndex !== this.selectedRowIndex || !this.initialSelectedRowDone) {
                this.handleSelectionChanged();
            }
        });

        // rechtsclick
        this.tableElement.on('rowContext', (e: PointerEvent, row: any) => {
            if (!this.hasOnRightClick) {
                return;
            }
            // Standard Kontextmenü unterbinden
            e.preventDefault();
            // Event "contextmenu" verhindern, sodass TwcContextMenu an der richtigen Stelle aufgeht
            e.stopPropagation();

            WebCompEventHandler('OnRightClick', this.id);

            // wenn wir auf eine andere Row klicken, selektieren wir diese
            if (this.selectedRowIndex !== String(row.getData().id)) {
                this.tableElement.deselectRow(this.selectedRowIndex);
                row.select();
            }
        });

        this.tableElement.on('rowDblClick', (e: PointerEvent, row: any) => {
            if (!this.hasOnDblClick) {
                return;
            }
            // voher triggert der "einfache" Klick, daher brauch man hier die Selektion nicht ändern
            WebCompEventHandler('OnDblClick', this.id);
        });

        this.tableElement.on('renderComplete', () => {
            // wenn wir Daten nachladen, müssen wir ggf. die Row neu selektieren, da der Status sonst verloren geht
            // Aber: erst wenn wir daten in der Tabelle haben, können wir etwas selektieren...
            if (this.selectedRowIndex !== '-1' && this.tableElement.getDataCount() > 0 && this.tableElement.getDataCount() > parseInt(this.selectedRowIndex) && this.hasCanSelect) {
                if (!this.initialSelectedRowDone) {
                    let selectedData = this.tableElement.getSelectedData();
                    if (selectedData.length > 0) {
                        // beim erstem mal haben wir noch noch keine daten selektiert. 
                        this.initialSelectedRowDone = true;
                    }
                }
                this.tableElement.deselectRow();
                this.tableElement.selectRow(this.selectedRowIndex);
            }

            if (this.tableHeightUsed) {
                this.needsOptimization = true;
                this.invalidate();
            }
        });

        this.tableElement.on('dataLoadError', function (error) {
            MsgNotify('Error on loading data', MessageType.Danger);
        });

        this.tableElement.on('dataLoaded', (data: any) => {
            if (this.tableElement.getDataCount() < parseInt(this.selectedRowIndex)) {
                this.tableElement.nextPage();
            }
        });
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        if (!isComponentActive(this)) {
            return;
        }
        this.optimizePosition();
    }

    isInsideModal(): boolean {
        return assigned(this.modalContainer);
    }

    optimizePosition(): void {
        // erst wenn wir dürfen, so sparen wir uns unnötige Berechnungen und Sprünge am Anfang
        if (!this.optimizePosAllowed) {
            return;
        }

        if (!this.needsOptimization) {
            return;
        }

        let availableSpaceVertical = 0, height = 0;
        let tableRect = this.obj.getBoundingClientRect();
        let tableHeader = this.obj.querySelector('.tabulator-header') as HTMLElement;
        let tableBody = this.obj.querySelector('.tabulator-table') as HTMLElement;
        tableBody.style.display = 'inline-block';
        // es gibt keine Daten? Dann soll die Tabelle minimal klein werden
        if (this.tableElement.getPageMax() == -1) {
            this.obj.style.setProperty('max-height', '100px');
            this.obj.style.setProperty('height', '100px');
            tableBody.style.display = 'none';
            return;
        }

        // noch keine Elemente vorhanden? Dann raus
        if (tableBody.childElementCount == 0) {
            return;
        }

        let computedStyleModal;
        if (this.isInsideModal()) {
            // im modalen contianer
            let modalBody = this.modalContainer.querySelector('.modal-body');
            let parentRectModalBody = modalBody.getBoundingClientRect();
            let tableBodyHeight = tableBody.getBoundingClientRect().height;
            let totalTableHeight = tableBodyHeight + tableHeader.getBoundingClientRect().height;
            computedStyleModal = window.getComputedStyle(modalBody);
            availableSpaceVertical = Math.floor(parentRectModalBody.height - (tableRect.y - parentRectModalBody.y));
            // Tabelle kleiner als der verfügbare Platz, es werden keine Pages mehr geladen und alle Pages passen in den verfügbaren Platz?
            if ((availableSpaceVertical > totalTableHeight) && !(this.tableElement.getPage() < this.tableElement.getPageMax() && height * this.tableElement.getPageMax() > availableSpaceVertical)) {
                this.tableHeightUsed = true;
                height = totalTableHeight + 15; // noch ein bisschen hin
            } else {
                // sonst die Tabelle auf die Höhe des verfügbaren Platzes setzen
                this.tableHeightUsed = false;
                height = availableSpaceVertical - parseFloat(computedStyleModal.paddingBottom);
            }

            // nach umstellung der neuen Dialoge, müssen wir noch die form und das fieldset mitrechnen
            let fieldset = getParentByClassname(this.obj, 'form-group');
            if (assigned(fieldset)) {
                height -= parseFloat(window.getComputedStyle(fieldset).marginBottom);
            }
        } else {
            availableSpaceVertical = document.documentElement.clientHeight - tableRect.y;
            // schauen ob wir mehr Platz benötigen als wir haben.
            let tableBodyHeight = tableBody.getBoundingClientRect().height;
            let totalTableHeight = tableBodyHeight + tableHeader.getBoundingClientRect().height;

            // Tabelle kleiner als der verfügbare Platz, es werden keine Pages mehr geladen und alle Pages passen in den verfügbaren Platz?
            if ((availableSpaceVertical > totalTableHeight) && !(this.tableElement.getPage() < this.tableElement.getPageMax() && height * this.tableElement.getPageMax() > availableSpaceVertical)) {
                this.tableHeightUsed = true;
                height = totalTableHeight + 15; // noch ein bisschen hin
            } else {
                // sonst die Tabelle auf die Höhe des verfügbaren Platzes setzen
                this.tableHeightUsed = false;
                height = availableSpaceVertical - tableHeader.clientHeight - 15; // noch ein bisschen weg
            }
        }

        // UPS / wenn es mehrere Pages gibt, dann niemals kleiner als der initiale Wert, da die Tabelle sonst ggf. auf eine Zeile
        // verkleinert wird, wo aber 1000 Elemente scrollbar sind
        // (oder es gibt zwei Tables -> die obere nimmt sich den verfügbaren Platz, die untere wird ganz klein...)
        if (!this.isInsideModal() && this.tableElement.getPageMax() > 1 && (height < this.minHeight)) {
            this.obj.style.setProperty('max-height', tableRect.height + 'px');
            this.obj.style.setProperty('height', tableRect.height + 'px');
        } else {
            this.obj.style.setProperty('max-height', height + 'px');
            this.obj.style.setProperty('height', height + 'px');
        }
        this.needsOptimization = false;
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleSelectionChanged() {
        this.notifyComponentChanged();
        if (this.hasOnChange) {
            WebCompEventHandler('OnSelectionChanged', this.id);
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'SelectedRowIndex', this.selectedRowIndex]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            //
        }
    }

}
