import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialStreamAction extends TCustomTutorialShapeAction {
    setValue(...args: any[]): void {
        throw new Error('Method not implemented.');
    }
    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatStream;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);
    }

    override toJSON(): any {
        return super.toJSON();
    }

    performAction(): void {
    }

    inverseAction(): TCustomTutorialShapeAction {
        return new TTutorialStreamAction(this.refElement, this.svgEditor);
    }
}
