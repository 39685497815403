import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr, strFromBool } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TWebCheckbox extends TWebComponent {
    checkbox: HTMLInputElement;
    hasServerChangeEvent: boolean;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TWebCheckbox';
        this.checkbox = AsType<HTMLInputElement>(this.obj);

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent ?? 'false');
    }

    override initDomElement() {
        super.initDomElement();

        this.checkbox.addEventListener('input', () => this.handleCheckboxChanged());
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleCheckboxChanged() {
        this.notifyComponentChanged();

        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Checked', strFromBool(this.checkbox.checked, true)]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Checked':
                this.checkbox.checked = boolFromStr(value);
                break;
            case 'Enabled':
                this.checkbox.disabled = !boolFromStr(value);
                break;
            case 'Visible':
                // Visible muss auf dem -DIV gesetzt werden
                this.obj.parentElement.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Caption':
                document.getElementById(`${this.id}-LABEL`).innerHTML = value;
                break;
        }
    }
}