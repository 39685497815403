import { WebCompEventHandlerAsync } from "../../../core/communication";
import { EventObj } from "../../../utils/events";
import { assigned } from "../../../utils/helper";
import { getParentBySelectors } from "../../../utils/html";
import { findComponent, requireComponent, unregisterComponent } from "../../base/controlling";
import { TwcTileboard } from "./class.web.comp.tileboard";
import { TwcTileboardItem } from "./class.web.comp.tileboard.item";

export class TwcTileboardEditor extends TwcTileboard {
    groupId: string;
    autoApplyChanges: boolean;
    ItemDragStartEvent: (event: DragEvent) => void;
    ItemDragOverEvent: (event: DragEvent) => void;
    ItemDragEndEvent: (event: DragEvent) => void;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcTileboardEditor';

        this.groupId = this.obj.dataset?.groupId ?? '';

        this.autoApplyChanges = true; // Später vllt deaktivierbar machen, aktuell wollen wir das immer direkt übernehmen


        this.ItemDragStartEvent = ((event: DragEvent) => this.handleItemDrag(event));
        this.ItemDragOverEvent = ((event: DragEvent) => this.handleItemDragOver(event));
        this.ItemDragEndEvent = ((event: DragEvent) => this.handleDragEnd(event));
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initEditEvents();
        this.updateTileboard();
    }

    private initEditEvents(): void {
        // register events
        this.obj.addEventListener('dragover', (ev) => this.handleItemDragOver(ev), false);
        this.obj.addEventListener('dragend', (ev) => this.handleDragEnd(ev), false);
    }

    override isEditMode(): boolean {
        return true;
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'DragState':
                if (value) {
                    this.obj.setAttribute('disabled', '');
                }
                else {
                    this.obj.removeAttribute('disabled');
                }

                this.updateTileboard();
                break;
            case 'GroupID':
                this.groupId = value;
                break;
            default:
                super.writeProperties(key, value);
        }
    }

    updateTileboard(): void {
        this.allowDrag = this.isEditMode();
    }

    override registerTileboardItem(item: TwcTileboardItem): void {
        super.registerTileboardItem(item);

        item.obj.addEventListener('dragstart', this.ItemDragStartEvent, false);
        item.obj.addEventListener('dragover', this.ItemDragOverEvent, false);
        item.obj.addEventListener('dragend', this.ItemDragEndEvent, false);
    }

    override unregisterTileboardItem(item: TwcTileboardItem): void {
        super.unregisterTileboardItem(item);

        item.obj.removeEventListener('dragstart', this.ItemDragStartEvent, false);
        item.obj.removeEventListener('dragover', this.ItemDragOverEvent, false);
        item.obj.removeEventListener('dragend', this.ItemDragEndEvent, false);
    }

    override afterTileboardItemChanged(item: TwcTileboardItem): void {
        // Dadurch bringen wir die anderen Elemente dazu, sich neu auszurichten etc, nachdem sich das Item geändert hat z. B. Größe
        item.locked = true;
        this.beginUpdate();
        try {
            // ggf. ist es durch die Movements oder Resizes out of bounds?
            if (item.width > this.usedColumnCount())
                item.width = this.usedColumnCount();
            if (item.x < 0)
                item.x = 0;
            if (item.y < 0)
                item.y = 0;
            if (item.x + item.width > this.usedColumnCount())
                item.x = this.usedColumnCount() - item.width
            // nach unten können wir die Komponente beliebig wachsen lassen, da gibt es kein Outof Bounds

            this.virtualMoveToPosition(item, item.getCurrentPosition());
            this.condenseVertically();

            // Und übernehmen
            this.removeDeprecatedChanges();
            this.updateDataset();

            if (this.autoApplyChanges) {
                this.applyPendingChanges();
            }
        }
        finally {
            item.locked = false;
            this.endUpdate();
        }
    }

    override execAction(action: string, params: string) {
        switch (action) {
            case 'Action.Store':
                this.applyPendingChanges();
                break;
            default: super.execAction(action, params);
        }
    }

    override handleOnTileboardContextmenu(eventObj: EventObj<MouseEvent>): void {
        super.handleOnTileboardContextmenu(eventObj);

        // Wenn die Basisklasse sich schon drum gekümmert hat ist alles super
        if (eventObj.Handled) {
            return;
        }

        if (this.isEditMode()) {
            this.obj.classList.add('waiting');
            WebCompEventHandlerAsync('OnTileboardContextMenu', this.id, () => this.obj.classList.remove('waiting'));
            eventObj.Event.preventDefault();
        }
    }

    override handleOnTileboardItemContextmenu(eventObj: EventObj<MouseEvent>, targetTileboardItem: TwcTileboardItem): void {
        super.handleOnTileboardItemContextmenu(eventObj, targetTileboardItem);

        // Wenn die Basisklasse sich schon drum gekümmert hat ist alles super
        if (eventObj.Handled) {
            return;
        }

        if (this.isEditMode()) {
            this.obj.classList.add('waiting');
            WebCompEventHandlerAsync('OnTileboardItemContextMenu', this.id, () => this.obj.classList.remove('waiting'), targetTileboardItem.id);
            eventObj.Event.preventDefault();
        }
    }

    //#region Edit Methods

    applyPendingChanges(): void {
        this.items.forEach(item => {
            item.applyPendingChanges();
        });

        // Wir brauchen ein Event nachdem alle Tiles angepasst wurden, da das Core Dirty nur einmal beim Ersten feuert und Änderungen in anderen Tiles dann ggf. nicht übertragen sind 
        // -> Ist im Dirty Improvment Item in Jira bereits vermerkt
        WebCompEventHandlerAsync('OnChange', this.id);
    }

    resetPendingChanges(): void {
        this.items.forEach(item => {
            item.resetPendingChanges();
        });
    }

    removeDeprecatedChanges(): void {
        this.items.forEach(item => {
            item.removeDeprecatedChanges();
        });
    }

    updateDataset(): void {
        this.items.forEach(item => {
            item.updateDataset();
        });

        this.notifyComponentChanged();
    }

    //#endregion

    //#region Drag & Drop
    handleItemDrag(event: DragEvent): void {
        // ist draggen überhaupt erlaubt?
        if (!this.allowDrag) {
            return;
        }

        let target = (event.target as HTMLElement);

        // Ggf. ist das kein Tileboard Item sondern der Inhalt, welcher der Nutzer gedraggt hat. Das müssen wir im Speicher dann korrigieren
        let comp = findComponent(target);
        if (!(comp instanceof TwcTileboardItem)) {
            target = getParentBySelectors(target, '[data-type="TwcTileboardItem"]') as HTMLElement;

            // nichts gefunden? Äußerst schade 😥
            if (!assigned(target)) {
                return;
            }
        }

        // INFO: Im Drag Event sind die Werte nicht abrufbar, nur die Typen (erster Parameter), daher fügen wir die IDs auch als Typ doppelt rein, um diese
        // abfragen zu können. Voller Zugriff via getData ist nur im Drop Event erlaubt: https://stackoverflow.com/a/28487486/6244709
        event.dataTransfer.setData(this.id, ''); // via Event merken zu welcher Dashboard Instanz wir gehören 

        event.dataTransfer.setData(`TILE${target.id}`, '');
        event.dataTransfer.setData('id', target.id);

        event.dataTransfer.setData(this.groupId, '');
        event.dataTransfer.setData('groupId', this.groupId);

        target.classList.add('dragging');
        this.draggingId = target.id;
        this.invalidate();
    }

    handleItemDragOver(event: DragEvent): void {
        // ist draggen überhaupt erlaubt?
        if (!this.allowDrag) {
            return;
        }

        // als erstes checken, ob das zu unserem Dashboard gehört
        let expression = this.groupId !== '' ? `(${this.id})|(${this.groupId})` : this.id;
        let regex = new RegExp(expression, 'i');
        if (!regex.test(event.dataTransfer.types.join())) { // Stringvergleich via includes und id geht nicht, da case sensitive
            return;
        }

        let targetId = this.draggingId ?? '';

        // Bei gruppen müssen wir uns die ID ggf. aus dem Event holen
        if (targetId === '' && this.groupId !== '') {
            let idReg = new RegExp('TILEWC\\d+', 'i');

            let m = event.dataTransfer.types.join().match(idReg);
            targetId = m?.join()?.replace(/TILEWC/gi, 'WC') ?? ''; // Replace via RegEx und Case Insenstive
        }

        if (targetId === '') {
            throw `Could not indetify dragged item.`;
        }

        let element = document.getElementById(targetId);
        let item = this.findItemByDomElement(element);

        // ggf. aus fremden Tileboard?
        if (!assigned(item) && this.groupId !== '') {
            document.querySelectorAll(`[data-type="${this.classtype}"][data-group-id="${this.groupId}"]`).forEach(tileboardElements => {
                let otherTileboard = findComponent(tileboardElements);
                if (assigned(otherTileboard) && otherTileboard instanceof TwcTileboardEditor) {
                    item = otherTileboard.findItemByDomElement(element);
                    if (assigned(item)) {
                        let itemObj = item.obj;

                        // Zuerst aus dem anderen Tileboard rausnehmen
                        otherTileboard.unregisterTileboardItem(item);
                        unregisterComponent(itemObj.id);
                        otherTileboard.draggingId = '';

                        // Dann auch im DOM verschieben
                        this.obj.appendChild(itemObj);

                        // Und dann erstellen wir auch die Komponente neu mit dem korrekten Owner.                      
                        // Dadurch initialisiert das Framework automatisch die neue Komponente, welche sich eigenständig im Tileboard registriert
                        item = requireComponent<TwcTileboardItem>(itemObj);
                        this.draggingId = item.id;

                        // Zum Schluss aktualisieren wir das andere Tileboard noch mal, falls da Platz freigeworden ist.
                        otherTileboard.condenseVertically();
                        otherTileboard.invalidate();

                        // Und wir informieren den Server, dass er die Komponente in einen anderen owner shcieben muss
                        WebCompEventHandlerAsync('OnForeignTileAdded', this.id, null, `${otherTileboard.id}#${item.id}`);

                        return;
                    }
                }
            });
        }

        // immer noch keine Infos?
        if (!assigned(item)) {
            throw `TileboardItem not found for id=${targetId}.`;
        }

        // Und default direkt auch unterbinden
        event.preventDefault();

        // Schnelle Abbruchbedingungen
        // es wird schon nen Movement berechnet
        if (this.isSimulating) {
            return;
        }

        let p = item.getCurrentPosition();
        let newP = item.getCurrentPosition();

        let xp = this.positionInDashboard(event.clientX, event.clientY);
        let xxp = this.positionToPoint(xp.x, xp.y);

        // offsets zur neuen Position
        let offsetX = xxp.x - p.left;
        let offsetY = xxp.y - p.top;

        newP.left = p.left + offsetX;
        newP.right = p.right + offsetX;
        newP.top = p.top + offsetY;
        newP.bottom = p.bottom + offsetY;

        // horizontales out of bounds? -> dann korrigieren
        if (newP.left < 0) {
            offsetX = -newP.left; // auf 0 nach rechts schieben
            newP.left = newP.left + offsetX;
            newP.right = newP.right + offsetX;
        }
        else if (newP.right > this.maxColumnCount() - 1) {
            offsetX = -(newP.right - (this.maxColumnCount() - 1)); // Auf MaxColumns - 1 (da nullbasiert) schieben

            -newP.right + this.maxColumnCount() - 1

            newP.left = newP.left + offsetX;
            newP.right = newP.right + offsetX;
        }

        // vertikales out of bounds? -> verhindern
        let rowCount = Math.max(1, ...this.items.filter(obj => obj !== item).map(obj => obj.getCurrentPosition().bottom));
        if (newP.top > rowCount + 1) {
            newP.top = rowCount + 1;// Weiter unten als letzte Reihe + 1 macht keinen Sinn
        }

        // Position ist unverändert
        if (p.top == newP.top && p.left == newP.left) {
            return;
        }

        // Targetposition wird schon berechnet
        if (this.itemTargetPosition !== undefined && this.itemTargetPosition.top == newP.top && this.itemTargetPosition == newP.left) {
            return;
        }

        this.itemTargetPosition = newP;

        // alte Berechnungen stoppen
        this.animationIds.forEach(id => {
            window.cancelAnimationFrame(id);
        });

        // wir starten die Simulation 
        this.isSimulating = true;

        this.animationIds.push(window.requestAnimationFrame(timestamp => {
            try {
                // falls die targetposition schon ne neue ist sparen wir uns den Rest verdichten
                if (newP.left != this.itemTargetPosition.left || newP.top != this.itemTargetPosition.top) {
                    return;
                }

                // falls einer der Werte durch komische Rundungen negativ ist, dann können wir uns das verschieben auch sparen
                if (newP.left < 0 || newP.top < 0) {
                    return;
                }

                item.locked = true;
                this.virtualMoveToPosition(item, newP);

                // falls die targetposition schon ne neue ist sparen wir uns den Rest verdichten
                if (newP.left != this.itemTargetPosition.left || newP.top != this.itemTargetPosition.top) {
                    return;
                }

                // verdichten
                this.condenseVertically();
            }
            finally {
                // item wieder freigeben
                item.locked = false;
                this.invalidate();
                this.isSimulating = false;
            }
        }));
    }

    handleDragEnd(event: DragEvent): void {
        // ist draggen überhaupt erlaubt?
        if (!this.allowDrag) {
            return;
        }

        if (this.draggingId !== '') {
            let item = document.querySelectorAll(`#${this.draggingId}`)[0];
            item.classList.remove('dragging');
            this.draggingId = '';
        }

        this.removeDeprecatedChanges();
        this.updateDataset();

        if (this.autoApplyChanges) {
            this.applyPendingChanges();
        }
    }
    //#endregion
}