﻿import { WebCompEventHandler } from '../../../core/communication';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent, TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';
import { TwcDropDownButton } from '../Button/class.web.comp.button';

export class TwcDropDownMenuForLookupTabs extends TwcDropDownButton {
    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcDropDownMenuForLookupTabs';
    }
}
export class TwcLookupContainer extends TWebComponent {
    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcLookupContainer';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}
export class TwcLookupTabItem extends TWebComponent {
    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcLookupTabItem';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                let captionElement = document.getElementById(`${this.id}-tabitem`);
                if (assigned(captionElement)) {
                    captionElement.innerHTML = value;
                }
                break;

        }
    }
}
export class TwcLookupTabs extends TRenderWebComponent {
    private isClickLocked: boolean;
    protected selectedTabItem: HTMLElement;

    tabList: HTMLUListElement;
    tabElements: NodeListOf<Element>;
    clickTimer: number;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcLookupTabs';

        this.tabList = document.getElementById(`${this.id}-nav-list`) as HTMLUListElement;
        this.tabElements = this.tabList.querySelectorAll('[role="tab"]');
        this.selectedTabItem = this.tabList.querySelector('.activated') as HTMLElement;
        // Click Handler
        this.isClickLocked = false;
    }

    override initDomElement(): void {
        super.initDomElement();
        this.tabElements.forEach(element => {
            element.addEventListener('click', e => {
                this.handleClickEvent(e);
            });
        });
    }

    handleClickEvent(e: Event): void {
        let target = e.target as HTMLElement;
        if (this.isClickLocked) {
            console.debug('Fast multi click discarded!');
            return;
        }

        this.isClickLocked = true;

        // Timer für ClickUnlock setzen
        this.clickTimer = window.setTimeout(() => {
            this.isClickLocked = false;
        }, 500); // 500 ms ist der Standard in Windows für Doppelklicks, siehe https://docs.microsoft.com/de-de/windows/win32/controls/ttm-setdelaytime?redirectedfrom=MSDN

        this.selectedTabItem = target;
        this.invalidate();

        WebCompEventHandler('OnClick', target.dataset.tabid);
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        if (assigned(this.selectedTabItem)) {
            this.clearActiveStyles();
            this.setActiveStyles(this.selectedTabItem);
        }
    }

    clearActiveStyles(): void {
        this.tabList.querySelectorAll('.btn-notin-group').forEach(element => {
            element.classList.remove('activated', 'focus', 'active');
            element.setAttribute('aria-selected', 'false');
        });
        this.tabList.querySelectorAll('.btn-in-group').forEach(element => {
            element.classList.remove('activated', 'focus', 'active');
            element.setAttribute('aria-selected', 'false');
        });
        this.tabList.querySelectorAll('.dropdown-toggle-split').forEach(element => {
            element.classList.remove('activated', 'focus', 'active');
            element.setAttribute('aria-selected', 'false');
        });
    }

    setActiveStyles(aTarget: HTMLElement): void {
        if (aTarget.classList.contains('btn-notin-group')) {
            aTarget.classList.add('activated');
        }
        if (aTarget.classList.contains('btn-in-group')) {
            aTarget.classList.remove('focus');
            aTarget.classList.add('activated');
            aTarget.setAttribute('aria-selected', 'true');
            aTarget.nextElementSibling.classList.add('activated');
        }
    }

    setActiveTabIndex(index: string): void {
        this.selectedTabItem = this.tabElements[index];
        // doRender macht dann der mutationsserver
    }

    getActiveTabIndex(): number {
        return parseInt(this.selectedTabItem.dataset.tabindex);
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ActiveTabIndex', this.getActiveTabIndex()]);
        return properties;
    }


    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'ActiveTabIndex':
                this.setActiveTabIndex(value);
                break;
        }
    }
}

