import { TWebComponent } from '../../base/class.web.comps';

export class TWebComponentLabel extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TWebComponentLabel';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                this.obj.innerHTML = value;
                break;
        }
    }
}