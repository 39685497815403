import Color from 'color';
import { Bounds, Point, Rectangle } from '../../../../classes/geometry';
import { WebCompEventHandlerAsync } from '../../../../core/communication';
import { getBaseUrl } from '../../../../core/endpoint';
import { assigned } from '../../../../utils/helper';
import { SVGNS, getIDSuffix } from '../../../../utils/svg';
import { TBaseTutorialObject, TutorialFontSizeFactor, TutorialHorizontalAlignment, TutorialVerticalAlignment } from '../TBaseTutorialObject';
import { TTutorialTextBase, TutorialFontStyles } from '../Text/TTutorialTextBase';
import { ResizeDirection } from '../Utils/TTutorialEditorResizeUtils';
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';


export abstract class TBaseTutorialShape implements TBaseTutorialObject {
    private pendingAnimationFrames: Array<number>;
    private updatingLockCount: number;

    id: string;
    cid: number;
    guid: string;

    editor: TwsTutorialEditor;
    svgElement: SVGElement;

    protected realBounds: Rectangle;
    protected draftBounds: Rectangle;

    protected textContent: string;
    protected displayedTextContent: string;
    roundCorners: boolean;

    textContentHorzAlignment: TutorialHorizontalAlignment;
    textContentVertAlignment: TutorialVerticalAlignment;
    wordWrap: boolean;
    ownerPos: number;
    rotation: number;

    public get boundingRect(): Rectangle { return Rectangle.fromBounds(assigned(this.draftBounds) ? this.draftBounds : this.realBounds) }
    public get realBoundingRect(): Rectangle { return Rectangle.fromBounds(this.realBounds) };

    public get x(): number { return this.realBounds.left }
    public get y(): number { return this.realBounds.top };
    public get w(): number { return this.realBounds.width };
    public get h(): number { return this.realBounds.height };

    gradient: number;

    selected: boolean;
    isMoveable: boolean;
    isResizePossible: boolean;
    isRotatable: boolean;
    inlineEdit: boolean;

    strokeWidth: number;

    colorFixed: boolean;
    backgroundColor: Color;
    borderColor: Color;
    fontColor: Color;
    fontStyles: Array<TutorialFontStyles>;

    shadow: boolean;
    transparent: boolean;

    textContentElement: TTutorialTextBase;

    image: SVGImageElement;
    imgUrl: string;
    hasImage: boolean;

    backgroundOpacity: number;
    borderOpacity: number;

    fontSize: TutorialFontSizeFactor;

    borderWidth: number;

    margin: Bounds;

    lineHeight: number;

    get isDraft(): boolean { return assigned(this.draftBounds) };

    constructor(editor: TwsTutorialEditor) {
        this.pendingAnimationFrames = [];
        this.updatingLockCount = 0;

        this.id = '';
        this.cid = undefined;
        this.guid = '';

        this.editor = editor;

        // hier merken wir uns das SVG Element
        this.svgElement = null;

        // Property für leichte Ellipsen
        this.roundCorners = false;

        // Position und Größe
        this.realBounds = new Rectangle(0, 0, 0, 0);
        this.draftBounds = null;

        this.textContent = '';
        this.displayedTextContent = '';
        this.textContentElement = null;

        this.fontColor = Color('#000');
        this.fontStyles = [];

        this.textContentHorzAlignment = TutorialHorizontalAlignment.taLeftJustify;
        this.textContentVertAlignment = TutorialVerticalAlignment.taAlignTop;
        this.wordWrap = true;

        // Properties des Shapes
        this.gradient = undefined;
        // this.setColor(Color('#006edc'));
        this.backgroundColor = Color('#fff');
        this.borderColor = Color('#000');
        this.strokeWidth = 1;

        this.selected = false;
        this.isMoveable = true;
        this.isResizePossible = true;
        this.inlineEdit = true;
        this.isRotatable = true;

        this.transparent = false;
        this.shadow = false;
        this.colorFixed = false;

        this.ownerPos = 0;
        this.rotation = 0;

        this.image = null;
        this.imgUrl = 'assets/images/shapes/tutorial/Image_Placeholder.svg';
        this.hasImage = false;

        this.backgroundOpacity = 100;
        this.borderOpacity = 100;

        this.fontSize = TutorialFontSizeFactor.fsf150;

        this.borderWidth = 1;

        this.margin = new Bounds(0, 0, 0, 0);

        this.lineHeight = 12;

        // Das brauchen wir teilweise relativ früh, also erstellen wir es direkt!
        // TODO: Ganzes SHape mal ordentlich initilaisieren
        this.svgElement = document.createElementNS(SVGNS, this.getSvgElementType());
    }

    beginUpdate(): void {
        this.updatingLockCount++;
    }

    endUpdate(): void {
        this.updatingLockCount--;

        // Fertig? Dann neu Darstellen
        if (!this.isUpdating()) {
            this.invalidate();
        }
    }

    isUpdating(): boolean {
        return this.updatingLockCount !== 0;
    }

    abstract getSvgElementType(): string;

    create() {
        this.editor.svg.appendChild(this.svgElement);

        // set Attributes
        this.svgElement.setAttribute('id', this.id);
        this.svgElement.setAttribute('fill', this.backgroundColor.hex());
        this.svgElement.setAttribute('stroke', this.borderColor.hex());
        this.svgElement.setAttribute('stroke-width', String(this.strokeWidth));

        // set Classes
        this.setClasses();

        // set Position / Size:
        switch (this.getSvgElementType()) {
            case 'path':
                this.svgElement.setAttribute('d', this.getSVGPath());
                break;
            case 'rect':
                this.svgElement.setAttribute('x', String(this.x));
                this.svgElement.setAttribute('y', String(this.y));
                if (this.roundCorners) {
                    this.svgElement.setAttribute('rx', '10'); // String(this.h / 2)
                    this.svgElement.setAttribute('ry', '10');
                }
                this.svgElement.setAttribute('width', String(this.w));
                this.svgElement.setAttribute('height', String(this.h));
                break;
            case 'circle':
                this.svgElement.setAttribute('cx', String(this.x + this.w / 2));
                this.svgElement.setAttribute('cy', String(this.y + this.w / 2));
                this.svgElement.setAttribute('r', String(this.w / 2));
                break;
            case 'ellipse':
                this.svgElement.setAttribute('cx', String(this.x + this.w / 2));
                this.svgElement.setAttribute('cy', String(this.y + this.h / 2));
                this.svgElement.setAttribute('rx', String(this.w / 2));
                this.svgElement.setAttribute('ry', String(this.h / 2));
                break;
            default:
                throw 'unknown svg type';
        }

        this.textContent = '';
        this.displayedTextContent = '';

        this.textContentElement = new TTutorialTextBase(this);
        this.textContentElement.createTextContent();

        if (this.hasImage) {
            // hier holen wir uns das Image für das Shape über einen RequestHandler
            this.imgUrl = getBaseUrl(this.editor.id) + '&data=' + JSON.stringify({ Action: 'GetImage' }) + '&shapeID=' + this.id + '&t=' + new Date().getTime();

            this.image = document.createElementNS(SVGNS, 'image');
            this.image.setAttribute('id', getIDSuffix(this.id, 'image'));
            this.editor.svg.appendChild(this.image);

            this.image.setAttribute('pointer-events', 'none');
            this.image.setAttribute('x', String(this.boundingRect.left));
            this.image.setAttribute('y', String(this.boundingRect.top));
            this.image.setAttribute('width', String(this.boundingRect.width));
            this.image.setAttribute('height', String(this.boundingRect.height));
            this.image.setAttribute('transform', `rotate(${this.rotation} ${this.boundingRect.left + this.boundingRect.width / 2} ${this.boundingRect.top + this.boundingRect.height / 2})`);


            // #time zur URL hinzugefügt, da sonst der RequestHandler nicht aufgerufen wird
            // Aber nur wenn sich der Wert auch ändert, sonst spammen wir den Server zu.
            if (this.image.getAttribute('href') !== this.imgUrl) {
                this.image.setAttribute('href', this.imgUrl);
            }
        }

        this.initEvents();
    }

    getSVGPath(): string {
        let w2 = this.boundingRect.width;
        let h2 = this.boundingRect.height;

        let path = [
            // Kante oben
            'M', this.boundingRect.left, this.boundingRect.top,
            'L', this.boundingRect.left + w2, this.boundingRect.top,
            'L', this.boundingRect.left + w2, this.boundingRect.top + h2,
            'L', this.boundingRect.left, this.boundingRect.top + h2,
            'Z'
        ].join(' ');

        return path;
    }

    setImage() {
        if (!this.hasImage)
            return;

        this.imgUrl = getBaseUrl(this.editor.id) + '&data=' + JSON.stringify({ Action: 'GetImage' }) + '&shapeID=' + this.id + '&t=' + new Date().getTime();
        this.image.setAttribute('href', this.imgUrl);
        this.editor.notifyComponentChanged();
    }

    delete() {
        if (assigned(this.editor.svg.getElementById(this.id))) {
            // Selection aufheben
            this.selected = false;

            this.editor.svg.removeChild(this.editor.svg.getElementById(this.id));
            this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, 'text')));


            // Und die ResizeHandler entfernen
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.north))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.north)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.northEast))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.northEast)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.east))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.east)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.southEast))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.southEast)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.south))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.south)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.southWest))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.southWest)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.west))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.west)));
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.northWest))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, ResizeDirection.northWest)));

            // das rotate icon entfernen
            if (assigned(this.editor.svg.getElementById(getIDSuffix(this.id, 'rotateHandler'))))
                this.editor.svg.removeChild(this.editor.svg.getElementById(getIDSuffix(this.id, 'rotateHandler')));

            // und das SVG Element für das Bild löschen
            if (this.hasImage)
                this.image.remove();

            this.editor.notifySelectedElementsChanged();
        }
    }

    invalidate(): void {
        if (this.isUpdating()) {
            return;
        }

        // clear old requests
        this.pendingAnimationFrames.forEach(animationHandle => {
            window.cancelAnimationFrame(animationHandle)
        });

        // new request
        this.pendingAnimationFrames.push(window.requestAnimationFrame(() => {
            this.repaint();
        }));
    }

    protected repaint() {
        // Wir sind noch in der Initialisierung
        if (this.w + this.h === 0) {
            return;
        }

        switch (this.getSvgElementType()) {
            case 'path':
                this.svgElement.setAttribute('d', this.getSVGPath());
                break;
            case 'rect':
                this.svgElement.setAttribute('x', String(this.boundingRect.left));
                this.svgElement.setAttribute('y', String(this.boundingRect.top));
                if (this.roundCorners) {
                    this.svgElement.setAttribute('rx', '10'); // das ist kein copy paste fehler sondern hier wollen wir tatsächlich zweimal height, sonst haben wir ein Oval -- String(this.boundingRect.height / 2)
                    this.svgElement.setAttribute('ry', '10');
                }
                this.svgElement.setAttribute('width', String(this.boundingRect.width));
                this.svgElement.setAttribute('height', String(this.boundingRect.height));
                break;
            case 'circle':
                this.svgElement.setAttribute('cx', String(this.boundingRect.left + this.boundingRect.width / 2));
                this.svgElement.setAttribute('cy', String(this.boundingRect.top + this.boundingRect.height / 2));
                this.svgElement.setAttribute('r', String(this.boundingRect.width / 2));
                break;
            case 'ellipse':
                this.svgElement.setAttribute('cx', String(this.boundingRect.left + this.boundingRect.width / 2));
                this.svgElement.setAttribute('cy', String(this.boundingRect.top + this.boundingRect.height / 2));
                this.svgElement.setAttribute('rx', String(this.boundingRect.width / 2));
                this.svgElement.setAttribute('ry', String(this.boundingRect.height / 2));
                break;
            default:
                throw 'unknown svg type';
        }

        // Und die ResizeHandler
        this.repaintResizeHandlers();

        // rotateHandler
        this.repaintRotateHandler();

        // Und der Text
        this.textContentElement.repaintTextContent();

        // und der test für rotation (ohne comms)
        this.applyRotation();

        // Und das Bild anpassen
        if (this.hasImage) {
            this.image.setAttribute('x', String(this.boundingRect.left));
            this.image.setAttribute('y', String(this.boundingRect.top));
            this.image.setAttribute('width', String(this.boundingRect.width));
            this.image.setAttribute('height', String(this.boundingRect.height));

            // #time zur URL hinzugefügt, da sonst der RequestHandler nicht aufgerufen wird
            // Aber nur wenn sich der Wert auch ändert, sonst spammen wir den Server zu.
            if (this.image.getAttribute('href') !== this.imgUrl) {
                this.image.setAttribute('href', this.imgUrl);
            }
        }
    }

    repaintResizeHandlers() {
        const SIZE = 5;
        const OFFSET = SIZE / 2;
        const CLASS_PREFIX = 'resize';

        function getHandler(owner: TBaseTutorialShape, direction: ResizeDirection): SVGRectElement {
            let id = getIDSuffix(owner.id, direction);

            let result: SVGRectElement = owner.editor.svg.getElementById(id) as any; // upps - type

            // wenn wir keine haben, erstellen wir sie neu
            if (!assigned(result)) {
                result = document.createElementNS(SVGNS, 'rect');
                owner.editor.svg.appendChild(result);
                result.setAttribute('id', id);
                result.dataset.ownerId = owner.id;
            }

            return result;
        }

        function buildResizeClassName(direction: ResizeDirection): string {
            return `${CLASS_PREFIX}-${direction}`;
        }

        function deleteHandler(owner: TBaseTutorialShape, direction: ResizeDirection): void {
            let id = getIDSuffix(owner.id, direction);

            let handler = owner.editor.svg.getElementById(id);

            if (assigned(handler))
                owner.editor.svg.removeChild(handler);
        }

        function setAttributes(element: SVGRectElement, x: number, y: number, w: number, h: number, cl: string): void {
            element.setAttribute('x', String(x));
            element.setAttribute('y', String(y));
            element.setAttribute('width', String(w));
            element.setAttribute('height', String(h));
            element.setAttribute('stroke', '#2a66c8');
            element.setAttribute('fill', '#fff');
            element.classList.add('resizeHandle');
            element.classList.add(cl);
        }

        if (this.selected && this.isResizePossible) {
            let n: SVGRectElement, ne: SVGRectElement, e: SVGRectElement, se: SVGRectElement,
                s: SVGRectElement, sw: SVGRectElement, w: SVGRectElement, nw: SVGRectElement;

            n = getHandler(this, ResizeDirection.north);
            ne = getHandler(this, ResizeDirection.northEast);
            e = getHandler(this, ResizeDirection.east);
            se = getHandler(this, ResizeDirection.southEast);
            s = getHandler(this, ResizeDirection.south);
            sw = getHandler(this, ResizeDirection.southWest);
            w = getHandler(this, ResizeDirection.west);
            nw = getHandler(this, ResizeDirection.northWest);

            setAttributes(n, this.boundingRect.left + this.boundingRect.width / 2 - OFFSET, this.boundingRect.top - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.north));
            setAttributes(ne, this.boundingRect.right - OFFSET, this.boundingRect.top - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.northEast));
            setAttributes(e, this.boundingRect.right - OFFSET, this.boundingRect.top + this.boundingRect.height / 2 - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.east));
            setAttributes(se, this.boundingRect.right - OFFSET, this.boundingRect.bottom - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.southEast));
            setAttributes(s, this.boundingRect.left + this.boundingRect.width / 2 - OFFSET, this.boundingRect.bottom - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.south));
            setAttributes(sw, this.boundingRect.left - OFFSET, this.boundingRect.bottom - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.southWest));
            setAttributes(w, this.boundingRect.left - OFFSET, this.boundingRect.top + this.boundingRect.height / 2 - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.west));
            setAttributes(nw, this.boundingRect.left - OFFSET, this.boundingRect.top - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.northWest));
        }
        else {
            deleteHandler(this, ResizeDirection.north);
            deleteHandler(this, ResizeDirection.northEast);
            deleteHandler(this, ResizeDirection.east);
            deleteHandler(this, ResizeDirection.southEast);
            deleteHandler(this, ResizeDirection.south);
            deleteHandler(this, ResizeDirection.southWest);
            deleteHandler(this, ResizeDirection.west);
            deleteHandler(this, ResizeDirection.northWest);
        }
    }

    repaintRotateHandler() {
        if (this.selected && this.isRotatable) {
            let id = getIDSuffix(this.id, 'rotateHandler');

            let result: SVGRectElement = this.editor.svg.getElementById(id) as any;

            // wenn wir keine haben, erstellen wir sie neu
            if (!assigned(result)) {
                result = document.createElementNS(SVGNS, 'rect');
                this.editor.svg.appendChild(result);
                result.setAttribute('id', id);
                result.dataset.ownerId = this.id;
            }

            result.setAttribute('x', String(this.boundingRect.right + 20));
            result.setAttribute('y', String(this.boundingRect.top + 20));
            result.setAttribute('width', String(10));
            result.setAttribute('height', String(10));
            result.setAttribute('stroke', '#000');
            result.setAttribute('fill', '#f00');
            result.classList.add('rotateHandler');
        }
        else {
            let id = getIDSuffix(this.id, 'rotateHandler');

            let handler = this.editor.svg.getElementById(id);

            if (assigned(handler))
                this.editor.svg.removeChild(handler);
        }
    }

    private checkStartDraft(): void {
        if (!assigned(this.draftBounds)) {
            this.draftBounds = Rectangle.fromBounds(this.realBounds);
        }
    }

    setNewDraftPosition(x: number, y: number, applyImmediately?: boolean): void {
        this.checkStartDraft();

        let dx = x - this.draftBounds.left;
        let dy = y - this.draftBounds.top;

        this.draftBounds.left = x;
        this.draftBounds.top = y;
        this.draftBounds.right += dx;
        this.draftBounds.bottom += dy;

        if (applyImmediately) {
            this.applyDraftBounds();
        }
    }

    setNewDraftSize(width: number, height: number, applyImmediately?: boolean): void {
        this.checkStartDraft();

        this.draftBounds.right = this.draftBounds.left + width;
        this.draftBounds.bottom = this.draftBounds.top + height;

        if (applyImmediately) {
            this.applyDraftBounds();
        }
    }

    setRotation(rotation: number, applyImmediately?: boolean): void {
        this.rotation = rotation;

        if (applyImmediately) {
            this.applyRotation();
        }
    }

    getRotation() {
        return this.rotation;
    }

    applyDraftBounds(): void {
        if (this.isDraft) {
            this.realBounds = Rectangle.fromBounds(this.draftBounds);
            this.resetDraftBounds();
        }
    }

    applyRotation() {
        this.svgElement.setAttribute('transform', `rotate(${this.rotation} ${this.boundingRect.left + this.boundingRect.width / 2} ${this.boundingRect.top + this.boundingRect.height / 2})`);
        this.textContentElement.innerDiv.style.rotate = `${this.rotation}deg`;

        // Und das Bild anpassen
        if (this.hasImage)
            this.image.setAttribute('transform', `rotate(${this.rotation} ${this.boundingRect.left + this.boundingRect.width / 2} ${this.boundingRect.top + this.boundingRect.height / 2})`);
    }

    resetDraftBounds(): void {
        this.draftBounds = null;
    }

    // buildGradientID(): string {
    //     return `gradient-${this.gradientBegin.hex()}-${this.gradientEnd.hex()}`;
    // }

    setClasses() {
        this.svgElement.classList.toggle('draggable', this.isMoveable);
        this.svgElement.classList.toggle('shapeSelected', this.selected);
    }

    setColor(color: Color) {
        this.backgroundColor = color;
        this.borderColor = this.backgroundColor.darken(0.2);

        // Font Color berechnen
        // Bei Gradient erst bei dunkleren Farben wegen des helleren Farbverlaufs!!!
        // let brightnessTreshhold = this.editor.colorMode === 1 ? 85 : 140;
        let rgb = [color.red(), color.green(), color.blue()];
        let yiq = (rgb[0] * 300 + rgb[1] * 590 + rgb[2] * 110) / 1000;

        // this.fontColor = (yiq < brightnessTreshhold) ? new Color('#fff') : new Color('#000');
    }

    setBackgroundColor(backgroundColor: Color) {
        this.backgroundColor = backgroundColor;
        this.svgElement.setAttribute('fill', this.backgroundColor.hex());
    }

    setBackgroundOpacity(backgroundOpacity: number) {
        this.backgroundOpacity = backgroundOpacity;
        this.svgElement.setAttribute('fill-opacity', String(this.backgroundOpacity / 100));
    }

    setBorderColor(borderColor: Color) {
        this.borderColor = borderColor;
        this.svgElement.setAttribute('stroke', this.borderColor.hex());
    }

    setBorderOpacity(borderOpacity: number) {
        this.borderOpacity = borderOpacity;
        this.svgElement.setAttribute('stroke-opacity', String(this.borderOpacity / 100));
    }

    setBorderWidth(borderWidth: number) {
        this.borderWidth = borderWidth;
        this.svgElement.setAttribute('stroke-width', String(this.borderWidth));
    }

    setMargin(left: number, top: number, right: number, bottom: number) {
        this.margin = new Bounds(left, top, right, bottom);
    }

    setLineHeight(lineHeight: number) {
        this.lineHeight = lineHeight;
    }

    setAlignHorizontal(align: TutorialHorizontalAlignment) {
        this.textContentHorzAlignment = align;
    }

    setAlignVertical(align: TutorialVerticalAlignment) {
        this.textContentVertAlignment = align;
    }

    setFontSize(fontSize: TutorialFontSizeFactor) {
        this.fontSize = fontSize;
        let fontSizeRem = '';

        switch (this.fontSize) {
            case TutorialFontSizeFactor.fsf100:
                fontSizeRem = '1rem';
                break;
            case TutorialFontSizeFactor.fsf150:
                fontSizeRem = '1.5rem';
                break;
            case TutorialFontSizeFactor.fsf200:
                fontSizeRem = '2rem';
                break;
            default:
                fontSizeRem = '1.5rem';
                break;
        }

        this.textContentElement.innerDiv.style.fontSize = fontSizeRem;
    }

    setFontColor(fontColor: Color) {
        this.fontColor = fontColor;
        this.textContentElement.innerDiv.style.color = fontColor.hex();
    }

    setTextContent(textContent: string): void {
        this.textContent = textContent;
    }

    getTextContent(): string {
        return this.textContent;
    }

    setDisplayedTextContent(displayedTextContent: string): void {
        this.displayedTextContent = displayedTextContent;

        if (assigned(this.textContentElement)) {
            this.textContentElement.innerDiv.innerHTML = displayedTextContent;
        }
    }

    getDisplayedTextContent(): string {
        return this.displayedTextContent;
    }

    setOwnerPos(pos: number) {
        this.ownerPos = pos;
    }

    getOwnerPos(): number {
        return this.ownerPos;
    }

    initEvents() {
        // todo: move und click müssen eventuell beide über das mouseup event gesteuert werden 
        // - dabei sollte dann ein delta wert eingeführt werden, ab dem die beiden events unterschieden werden

        this.svgElement.addEventListener('mousedown', event => {

            // Wenn wir eigentlich garkein inteagierbares Element sind, tun wir so als ob das auf dem Editor passiert wäre
            if (this.isFixedElement()) {
                this.editor.handleMouseDownEvent(event);
                return;
            }

            if (!this.selected) {
                if (!(event.shiftKey || event.ctrlKey)) {
                    this.editor.clearSelectedElements();
                }
                this.selected = true;
            }
            // selected and modifier key
            else if (event.shiftKey || event.ctrlKey) {
                this.selected = false;
            }

            // Und noch die selektierten Elemente übergeben
            this.editor.notifySelectedElementsChanged();

        });

        this.svgElement.addEventListener('dblclick', () => {

            this.editor.clearSelectedElements();
            this.selected = true;
            this.editor.invalidate();

            this.editor.notifySelectedElementsChanged();
            WebCompEventHandlerAsync('OnShapeDoubleClick', this.editor.id);

        });
    }

    /**
     * Returns boolean if element is a fixed and not interactable part of the process
     * @returns
     */
    isFixedElement(): boolean {
        return false;
    }

    center(): Point {
        return new Point(this.x + (this.w / 2), this.y + (this.h / 2));
    }
}

// UPPS!!!! Export/Import, extend auflösen!
// https://app.clickup.com/t/36644329/IMS-23437

export class TTutorialShapeText extends TBaseTutorialShape {
    constructor(editor: TwsTutorialEditor) {
        super(editor);
        this.cid = 100;
    }

    getSvgElementType(): string {
        return 'rect';
    }
}

export class TTutorialShapeRect extends TBaseTutorialShape {
    constructor(editor: TwsTutorialEditor) {
        super(editor);
        this.cid = 600;
    }

    getSvgElementType(): string {
        return 'rect';
    }
}

export class TTutorialShapeRoundRect extends TBaseTutorialShape {
    constructor(editor: TwsTutorialEditor) {
        super(editor);
        this.cid = 200;
        this.roundCorners = true;
    }

    getSvgElementType(): string {
        return 'rect';
    }
}

export class TTutorialShapeEllipse extends TBaseTutorialShape {
    constructor(editor: TwsTutorialEditor) {
        super(editor);
        this.cid = 300;
    }

    getSvgElementType(): string {
        return 'ellipse';
    }
}

export class TTutorialShapeArrow extends TBaseTutorialShape {
    constructor(editor: TwsTutorialEditor) {
        super(editor);
        this.cid = 400;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let w2 = this.boundingRect.width;
        let h2 = this.boundingRect.height;

        // how to arrow..

        let path = [
            'M', this.boundingRect.left, this.boundingRect.top + 15,
            'L', this.boundingRect.left, this.boundingRect.top + this.boundingRect.height - 15,
            'L', this.boundingRect.left + this.boundingRect.width - 50, this.boundingRect.top + this.boundingRect.height - 15,
            'L', this.boundingRect.left + this.boundingRect.width - 50, this.boundingRect.top + this.boundingRect.height,
            'L', this.boundingRect.left + this.boundingRect.width, this.boundingRect.top + this.boundingRect.height / 2,
            'L', this.boundingRect.left + this.boundingRect.width - 50, this.boundingRect.top,
            'L', this.boundingRect.left + this.boundingRect.width - 50, this.boundingRect.top + 15,
            'Z'
        ].join(' ');

        return path;
    }
}

export class TTutorialShapeRectImage extends TBaseTutorialShape {
    constructor(editor: TwsTutorialEditor) {
        super(editor);
        this.cid = 500;
        this.hasImage = true;
    }

    getSvgElementType(): string {
        return 'path';
    }
}