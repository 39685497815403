import ApexCharts from "apexcharts";
import { sendComponentRequestGetJson } from "../../../core/communication";
import { boolFromStr } from "../../../utils/strings";
import { TRenderWebComponent } from "../../base/class.web.comps";

export class TwcHeatmapChart extends TRenderWebComponent {
    chart: ApexCharts;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcHeatmapChart';
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initChart();
    }

    initChart(): void {
        let options = sendComponentRequestGetJson(this.id, 'getData');
        this.chart = new ApexCharts(this.obj, options);
        this.chart.render();
    }


    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.RefreshChart':
                this.chart.updateOptions(sendComponentRequestGetJson(this.id, 'getData'));
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}