import { assigned } from '../../utils/helper';
import { isIWebComponent, IWebComponent, tryGetAsWebComponent } from '../interfaces/class.web.comps.intf';

export const KnownTypes = new Map();

/* observer */
let domObserver = new MutationObserver(handleMutation)

const CS_OBJ_KEY = '__csObj';

export default function initComponentFramework(): void {
    domObserver.observe(document.querySelector('body'), {
        attributes: false,
        childList: true,
        subtree: true,
    });

    document.querySelectorAll('[data-type]').forEach(element => {
        if (element instanceof HTMLElement && !assigned(element[CS_OBJ_KEY])) {
            initElement(element);
        }
    })

    // Wir geben einige Funktionen ans Window damit wir die auch aus Delphi heraus aufrufen können :/
    window[findComponent.name] = findComponent;
    window[registerComponent.name] = registerComponent;
}

function handleMutation(mutations: Array<MutationRecord>, observer: MutationObserver): void {
    mutations.forEach(mutation => {
        if (mutation.type !== 'childList') {
            return;
        }

        mutation.addedNodes.forEach(node => {
            let iterateFunc = (node: Node) => {
                // Nur die HTMLElement Nodes können eine Komponente sein!
                if (node instanceof HTMLElement) {
                    if (assigned(node.dataset?.type) && !assigned(node[CS_OBJ_KEY]) && !(node.dataset.type === 'true' || node.dataset.type === 'false')) {
                        console.debug(`Init: ${node}`);
                        initElement(node);
                    }
                }

                node.childNodes.forEach(child => {
                    iterateFunc(child);
                });
            }

            iterateFunc(node);
        });
    });
}

export function findComponent(identifier: string | Element): IWebComponent {

    if (identifier instanceof Element) {
        return identifier[CS_OBJ_KEY] as IWebComponent ?? initComponent(identifier.id);
    }
    else {
        let element = document.getElementById(identifier);

        if (!assigned(element)) {
            console.debug(`Element "${identifier}" does not exist.`)
            return null;
        }

        return findComponent(element);
    }
}

export function requireComponent<T extends IWebComponent>(identifier: string | Element): T {
    let component = findComponent(identifier);

    if (!assigned(component)) {
        throw `Component for key cannot be found or created [${identifier instanceof Element ? identifier.id : identifier}].`;
    }

    let result = component as T;
    return result;
}

export function getComponentForElement(element: Element): IWebComponent | null {
    let obj = element[CS_OBJ_KEY];

    if (assigned(obj)) {
        return tryGetAsWebComponent(obj);
    }

    return null;
}

// key ist die ID 
export function registerComponent(key: string, component: IWebComponent): IWebComponent {
    if ((key ?? '').trim().length === 0) {
        throw 'Empty key is not allowed for webcomponent.';
    }

    let element = document.getElementById(key);

    if (!assigned(element))
        throw `${key} is not a valid element.`;

    if (assigned(component)) {
        element[CS_OBJ_KEY] = component;
        component.doInternalRegister();
        return component;
    }
    else {
        delete element[CS_OBJ_KEY];
        return null;
    }
}

export function unregisterComponent(key: string): void {
    console.debug(`Unregistering ${key}`);
    registerComponent(key, null);
}


// Manche Objekte wollen wir initialsieren damit sie auch vor dem ersten Event schon Objekte sind
// und wir somit Events im JS registrieren koennen
// Wenn wir das schon hatten entfernen wir es aus dem Cache (passiert z.B. bei Reload)
export function initComponent(key: string): IWebComponent {
    let element = document.getElementById(key);

    return initElement(element);
}

export function initElement(element: HTMLElement): IWebComponent {
    let classType = element?.dataset?.type;

    if (!assigned(classType)) {
        console.debug(`CS: Classtype "${classType}" not defined.`);
        return null;
    }

    try {
        let classConstructor = KnownTypes.get(classType);
        if (!assigned(classConstructor)) {
            console.error(`CS: Could not find component [Type: ${classType}].`);
            return null;
        }

        let component = new classConstructor(element) as IWebComponent;
        if (!assigned(component)) {
            console.error(`CS: Could not create component [Type: ${component}].`);
            return null;
        }

        return registerComponent(element.id, component);
    } catch (error) {
        console.debug(element); // Darüber bekommen wir das Fehlerhafte Element!
        console.error(error);
        return null;
    }
}

export function isRegisteredComponent(obj: HTMLElement | IWebComponent): boolean {
    if (isIWebComponent(obj)) {
        return isRegisteredComponent((obj as IWebComponent).obj);
    }

    return assigned(obj[CS_OBJ_KEY]);
}

export function getActiveComponent(searchParents?: boolean): IWebComponent | null {
    let result = null;
    let activeElement = document.activeElement;

    while (!assigned(result) && assigned(activeElement)) {
        result = getComponentForElement(activeElement);

        activeElement = searchParents ? activeElement.parentElement : null;
    }

    return result;
}