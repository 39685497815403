import { WebCompEventHandler } from '../../../core/communication';
import { showPopoverForGroup, showPopoverForUser } from '../../../core/utils/popover';
import { AsType } from '../../../utils/html';
import { TRenderWebComponent, TWebComponent } from "../../base/class.web.comps";

export class TwcKanbanBoard extends TWebComponent {

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcKanbanBoard';
    }

    override initDomElement(): void {
        super.initDomElement();

        this.modifyHTML();
    }

    writeProperties(key: string, value: string): void {

    }

    modifyHTML(): void {
        let listViewCaptions = this.obj.querySelectorAll('a.user-group-link');
        let badgeFilter = this.obj.querySelectorAll('button.cs-badge-btnremove');

        listViewCaptions.forEach(caption => {
            if (caption instanceof HTMLElement)
                caption.addEventListener('click', e => {
                    e.preventDefault();
                    e.stopImmediatePropagation();

                    if (caption.dataset.key.indexOf('U') === 0)
                        showPopoverForUser(e.target, caption.dataset.key);
                    else if (caption.dataset.key.indexOf('G') === 0)
                        showPopoverForGroup(e.target, caption.dataset.key);
                });
        });

        badgeFilter.forEach(badge => {
            if (badge instanceof HTMLElement) {
                badge.addEventListener('click', e => {
                    e.preventDefault();
                    e.stopImmediatePropagation();

                    this.notifyComponentChanged();
                    WebCompEventHandler('OnChange', this.id, badge.dataset.key)
                });
            }
        });
    }
}

export class TwcKanbanRow extends TRenderWebComponent {
    private headerColorString: string;
    headerCardElement: HTMLElement;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcKanbanRow';

        this.headerColorString = this.obj.dataset?.headerColor ?? '';
        this.headerCardElement = AsType<HTMLElement>(document.querySelector(`#${this.id}-container>.kanban-row-header`));
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        if (this.headerColorString !== '') {
            this.headerCardElement.style.borderTop = `3px solid ${this.headerColorString}`;
        }
        else {
            this.headerCardElement.style.borderTop = null;
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'HeaderColor':
                this.headerColorString = value;
        }
    }
}