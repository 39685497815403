﻿import { Tab } from 'bootstrap';
import { ISignal, SignalDispatcher } from 'ste-signals';
import { UnX, assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ISupportsActive } from '../../interfaces/supportsActive.intf';
import { TwcTabPanel } from './class.web.comp.tabpanel';

export class TwcTabItem extends TRenderWebComponent implements ISupportsActive {

    public supportsActiveDiscriminator: 'I-Supports-Active' = 'I-Supports-Active';

    private _onShow = new SignalDispatcher();

    protected tabElement: HTMLElement;
    protected get tab(): Tab {
        return new Tab(this.tabElement);
    }
    protected owner: TwcTabPanel;
    protected visible: boolean;
    protected enabled: boolean;
    protected caption: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTabItem';
        this.tabElement = document.getElementById(`${this.id}-tab`);

        this.owner = findComponent(this.obj.dataset.tabid) as TwcTabPanel;
        if (!assigned(this.owner)) {
            throw `Tab item ${this.id} has no owner`;
        }

        this.visible = boolFromStr(this.obj.dataset?.visible, true);
        this.enabled = boolFromStr(this.obj.dataset?.enabled, true);
        this.caption = UnX(this.obj.dataset?.caption ?? '');
    }

    public writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                this.caption = value;
                break;
            case 'Visible':
                this.visible = boolFromStr(value);
                break;
            case 'Enabled':
                this.enabled = boolFromStr(value);
                break;
        }
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        this.tabElement.classList.toggle('d-none', !this.visible);
        this.tabElement.classList.toggle('disabled', !this.enabled);

        this.tabElement.innerHTML = this.caption;
    }

    public override setFocus(): void {
        this.owner.setActiveTab(this);
    }

    public show(): void {
        this.tab.show();

        this._onShow.dispatchAsync();
    }

    public onShow(): ISignal {
        return this._onShow.asEvent();
    }

    public isActive(): boolean {
        return this.owner.getActiveTabID() === this.id;
    }
}