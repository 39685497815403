﻿import { TWebComponent } from './comps/base/class.web.comps';
import { KnownTypes } from './comps/base/controlling';
import { TwcAccordionContainer, TwcAccordionSection } from './comps/comps/Accordion/class.web.comp.accordion';
import { TwcAction } from './comps/comps/Action/class.web.comp.action';
import { TwsActivityComment, TwsActivityItem } from './comps/comps/ActivityStream/class.web.comp.activitystream';
import { TwcAlert } from './comps/comps/Alert/class.web.comp.alert';
import { TwcBackgroundTask } from './comps/comps/BackgroundTask/class.web.comp.backgroundtask';
import { TwcBanner } from './comps/comps/Banner/class.web.comp.banner';
import { TwcCustomButton, TwcCustomURLButton, TwcDropDownButton, TwcIconButton } from './comps/comps/Button/class.web.comp.button';
import { TwcCustomCanvas } from './comps/comps/Canvas/class.web.comp.canvas';
import { TwcCard, TwcCardExtended, TwcCardPanel, TwcCardPanelItem, TwcCardSection } from './comps/comps/Card/class.web.comp.card';
import { TwcCarousel } from './comps/comps/carousel/class.web.comp.carousel';
import { TwcChartBar, TwcChartDoughnut, TwcChartLine, TwcChartPie, TwcChartPolarArea, TwcChartRadar, TwcCustomChart } from './comps/comps/Charts/class.web.comp.charts';
import { TwcChartTacho } from './comps/comps/Charts/class.web.comp.charts.tacho';
import { TWebCheckbox } from './comps/comps/Checkbox/class.web.comp.checkbox';
import { TwcClipboard } from './comps/comps/Clipboard/class.web.comp.clipboard';
import { TwcCollapsePreview, TwcCollapseWrapper } from './comps/comps/Collapse/class.web.comp.collapse';
import { TWebFormCombobox } from './comps/comps/Combobox/class.web.comp.combobox';
import { TwcComboMenu } from './comps/comps/Combobox/class.web.comps.combomenu';
import { TwcContainer, TwcContainerItem } from './comps/comps/Container/class.web.comp.container';
import { TwcContentContainer, TwcContentContainerGroup } from './comps/comps/Container/class.web.comp.contentcontainer';
import { TwcContextMenu } from './comps/comps/ContextMenu/class.web.comp.contextmenu';
import { TwsCSObject } from './comps/comps/CSObject/class.web.comp.csobject';
import { TwcObjectListSelector, TwcObjectListSelectorPopDown } from './comps/comps/CSObject/class.web.comp.csobjectlist';
// import { TwcD3jsChart } from './comps/comps/D3Chart/class.web.comp.d3.chart';
// import { TwcD3jsGraph } from './comps/comps/D3Graphs/class.web.comp.d3.graph';
import { TwcHeatmapChart } from './comps/comps/Charts/class.web.comp.heatmap';
import { TwcEditDate } from './comps/comps/Date/class.web.comp.date';
import { TwcDateInterval } from './comps/comps/Date/class.web.comp.dateinterval';
import { TwcDispatcher } from './comps/comps/Dispatcher/class.web.comp.dispatcher';
import { TwcDivEx } from './comps/comps/Div/class.web.comp.div';
import { TwcDownloadPicker } from './comps/comps/DownloadPicker/class.web.comp.downloadpicker';
import { TwcDragAndDrop } from './comps/comps/DragAndDrop/class.web.comp.dragAndDrop';
import { TwcDropDownItem, TwcDropDownItemSeperator, TwcDropDownList, TwcDropDownSubMenu } from './comps/comps/Dropdown/class.web.comp.dropdown';
import { TwcEmbed } from './comps/comps/Embed/class.web.comp.embed';
import { TwcCustomFileSelector } from './comps/comps/FileSelector/class.web.comp.fileselector.base';
import { TwcDropZoneFileSelector, TwcFileZoneFileSelector } from './comps/comps/FileSelector/class.web.comp.fileselector.dropzone';
import { TwcSingleFileSelector } from './comps/comps/FileSelector/class.web.comp.fileselector.single';
import { TwsTemplateSelect } from './comps/comps/FileSelector/class.web.comp.fileselector.templateSelect';
import { TwcFileTransferAttachments } from './comps/comps/FileTransfer/class.web.comp.filetransfer.attachment';
import { TwcCustomFileTransfer } from './comps/comps/FileTransfer/class.web.comp.filetransfer.base';
import { TwcFileTransferSingleCheckout } from './comps/comps/FileTransfer/class.web.comp.filetransfer.single';
import { TwsFilterSelection, TwsFilterSelectionItem } from './comps/comps/FilterSelection/class.web.comp.filterselection';
import { TwcFormSection, TwcFormSectionItem } from './comps/comps/FormGroup/class.web.comp.form';
import { TwcFormGroup, TwcFormSectionGroup, TwcFormSectionSubGroup } from './comps/comps/FormGroup/class.web.comp.formGroup';
import { TwsEditorFroala } from './comps/comps/Froala/class.web.comp.froala.editor';
import { TwsPreviewFroala } from './comps/comps/Froala/class.web.comp.froala.preview';
import { TwcFullPage, TwcFullPageSection } from './comps/comps/FullPage/class.web.comp.fullpage';
import { TwcGenericInput } from './comps/comps/Generic/class.web.comp.generic';
import { TWebComponentHtmlArea } from './comps/comps/HTMArea/class.web.comp.htmlarea';
import { TwcIcon } from './comps/comps/Icon/class.web.comp.icon';
import { TwcImage } from './comps/comps/Image/class.web.comp.image';
import { TwcImageEx } from './comps/comps/Image/class.web.comp.imageex';
import { TwcImageCropper, TwcImageCropperDialog } from './comps/comps/ImageCropper/class.web.comp.imageCropper';
import { TwcInfiniteScroll } from './comps/comps/InfiniteScroll/class.web.comp.infinitescroll';
import { TwcJumbotron, TwcJumbotronCS } from './comps/comps/Jumbotron/class.web.comp.jumbotron';
import { TwcKanbanBoard, TwcKanbanRow } from './comps/comps/KanbanBoard/class.web.comp.kanbanboard';
import { TWebComponentLabel } from './comps/comps/Label/class.web.comp.label';
import { TwcLink } from './comps/comps/Link/class.web.comp.link';
import { TwcCheckListBox } from './comps/comps/ListBox/class.web.comp.checkedlistbox';
import { TwcListBox, TwcListBoxSearch } from './comps/comps/ListBox/class.web.comp.listbox';
import { TwcListSearch, TwsListFilterPanel } from './comps/comps/ListFilter/class.web.comp.listfilter';
import { TwcListView } from './comps/comps/ListView/class.web.comp.listview';
import { TwcLookupList } from './comps/comps/LookupList/class.web.comp.lookuplist';
import { TwcDropDownMenuForLookupTabs, TwcLookupContainer, TwcLookupTabItem, TwcLookupTabs } from './comps/comps/LookupTabs/class.web.comp.lookuptabs';
import { TwsMap } from './comps/comps/Map/class.web.comp.map';
import { TwcMasonry } from './comps/comps/Masonry/class.web.comp.masonry';
import { TwcMasonryInfinite } from './comps/comps/Masonry/class.web.comp.masonry.infinite';
import { TwcMenu } from './comps/comps/Menu/class.web.comp.menu';
import { TwcMenuItem } from './comps/comps/Menu/class.web.comp.menuitem';
import { TwcMetric, TwcMetricButton } from './comps/comps/Metric/class.web.comp.metric';
import { TwcModal } from './comps/comps/Modal/class.web.comp.modal';
import { TwcMonacoEditor } from './comps/comps/MonacoEditor/class.web.comp.monacoEditor';
import { TwcMultiSelect, TwcMultiSelectIcon, TwcMultiSelectImg } from './comps/comps/MultiSelect/class.web.comp.multiselect';
import { TwcNavBar, TwcNavBarItem } from './comps/comps/NavBar/class.web.comp.navbar';
import { TwsOrgRelEditor } from './comps/comps/OrgRelEditor/class.web.comp.orgreleditor';
import { TwcOverlay, TwcOverlayAnimation } from './comps/comps/Overlay/class.web.comp.overlay';
import { TwcOverlayEmpty } from './comps/comps/Overlay/class.web.comp.overlay.empty';
import { TwcPopup } from './comps/comps/PagePopup/class.web.comp.popup';
import { TwcRadioPicker } from './comps/comps/Picker/class.web.comp.picker';
import { TwcPopContainer, TwcPopContainerList } from './comps/comps/PopContainer/class.web.comp.popcontainer';
import { TwsProcessEditorCluster } from './comps/comps/ProcessEditor/class.web.comp.process.editor.cluster';
import { TwsProcessEditorStandard } from './comps/comps/ProcessEditor/class.web.comp.process.editor.standard';
import { TWebComponentProcessDiffViewerSVG } from './comps/comps/ProcessViewer/class.web.comp.process.diffview.svg';
import { TwsProcessViewerSVG } from './comps/comps/ProcessViewer/class.web.comp.process.viewer.svg';
import { TwcProgressbar } from './comps/comps/Progressbar/class.web.comp.progressbar';
import { TwcPublisherEx, TwcPublisherSimple } from './comps/comps/Publisher/class.web.comp.publisher';
import { TWebRadioButton } from './comps/comps/RadioButton/class.web.comp.radiobutton';
import { TwcRating } from './comps/comps/Rating/class.web.comp.rating';
import { TwsReport } from './comps/comps/Reports/class.web.comp.reports.tree';
import { TwsRiskMatrix } from './comps/comps/Riskmatrix/class.web.comp.riskmatrix';
import { TwcSlider } from './comps/comps/Slider/class.web.comp.slider';
import { TwsSmartPropertyList } from './comps/comps/SmartPropertyList/class.web.comp.smartpropertylist';
import { TwsSpeechToText, TwsFileSpeechToText } from './comps/comps/SpeechToText/class.web.comp.speechtotext';
import { TwcSuggestions, TwcTemplatedSuggestions } from './comps/comps/Suggestions/class.websuite.comp.suggestions';
import { TwcSuggestionsText } from './comps/comps/Suggestions/class.websuite.comp.suggestions.text';
import { TwcDataTable } from './comps/comps/Tables/class.web.comp.datatable';
import { TwcInfiniteDataTable } from './comps/comps/Tables/class.web.comp.infinitedatatable';
import { TwcTable } from './comps/comps/Tables/class.web.comp.table';
import { TwcTableGrid } from './comps/comps/Tables/class.web.comp.tablegrid';
import { TwcTabItem } from './comps/comps/Tabs/class.web.comp.tabitem';
import { TwcTabPanel } from './comps/comps/Tabs/class.web.comp.tabpanel';
import { TwsTagEditor } from './comps/comps/TagEditor/class.web.comp.tageditor';
import { TwcTagFilterView } from './comps/comps/TagFilterView/class.web.comp.tagfilterview';
import { TwcText } from './comps/comps/Text/class.web.comp.text';
import { TwcTextArea } from './comps/comps/Text/class.web.comp.textarea';
import { TwcButtonEdit, TwcButtonEditEx, TwcEditDouble, TwcEditNumber, TWebTextEdit } from './comps/comps/Text/class.web.comp.textedit';
import { TwcTextMemo } from './comps/comps/TextMemo/class.web.comp.textmemo';
import { TwcTileboard } from './comps/comps/Tileboard/class.web.comp.tileboard';
import { TwcTileboardEditor } from './comps/comps/Tileboard/class.web.comp.tileboard.editor';
import { TwcTileboardItem } from './comps/comps/Tileboard/class.web.comp.tileboard.item';
import { TwcTimegrid } from './comps/comps/Timegrid/class.web.comp.timegrid';
import { TwcTimeline } from './comps/comps/Timeline/class.web.comp.timeline';
import { TwcTimer } from './comps/comps/Timer/class.web.comp.timer';
import { TwcTitle } from './comps/comps/Title/class.web.comp.title';
import { TwcButtonToolbar } from './comps/comps/Toolbar/class.web.comp.toolbar';
import { TwcToolbarSeparator } from './comps/comps/Toolbar/class.web.comp.toolbar.separator';
import { TwcTourController } from './comps/comps/TourController/class.web.comp.tourcontroller';
import { TWebTree } from './comps/comps/Tree/class.web.comp.tree';
import { TwcTreeTable } from './comps/comps/Tree/class.web.comp.treetable';
import { TwsTutorialEditor } from './comps/comps/TutorialEditor/class.web.comp.tutorialeditor';
import { TwsTutorialViewer } from './comps/comps/TutorialViewer/class.web.comp.tutorialviewer';
import { TwcFileUpload } from './comps/comps/Upload/class.web.comp.upload';
import { TWebFrame } from './comps/comps/WebFrame/class.web.comp.webframe';
import { TWebComponentWorkflowDesigner } from './comps/comps/WorkflowDesigner/class.web.comp.workflowdesigner';
import { TwcWorkflowContext, TwcWorkflowContextItem } from './comps/comps/Workflows/class.web.comps.workflowcontextitem';
import { TwcZoomDiv } from './comps/comps/ZoomDiv/class.web.comp.zoomdiv';


export function registerComponents() {
    KnownTypes.set(TWebComponent.name, TWebComponent);
    KnownTypes.set(TwcAccordionSection.name, TwcAccordionSection);
    KnownTypes.set(TwcAccordionContainer.name, TwcAccordionContainer);
    KnownTypes.set(TwcAction.name, TwcAction);
    KnownTypes.set(TwsActivityItem.name, TwsActivityItem);
    KnownTypes.set(TwsActivityComment.name, TwsActivityComment);
    KnownTypes.set(TwcAlert.name, TwcAlert);
    KnownTypes.set(TwcBackgroundTask.name, TwcBackgroundTask);
    KnownTypes.set(TwcBanner.name, TwcBanner);
    KnownTypes.set(TwcButtonEdit.name, TwcButtonEdit);
    KnownTypes.set(TwcButtonEditEx.name, TwcButtonEditEx);
    KnownTypes.set(TwcCustomButton.name, TwcCustomButton);
    KnownTypes.set(TwcCustomURLButton.name, TwcCustomURLButton);
    KnownTypes.set(TwcIconButton.name, TwcIconButton);
    KnownTypes.set(TwcDropDownButton.name, TwcDropDownButton);
    KnownTypes.set(TwcCarousel.name, TwcCarousel);
    KnownTypes.set(TwcCustomCanvas.name, TwcCustomCanvas);
    KnownTypes.set(TwcCard.name, TwcCard);
    KnownTypes.set(TwcCardExtended.name, TwcCardExtended);
    KnownTypes.set(TwcCardSection.name, TwcCardSection);
    KnownTypes.set(TwcCardPanel.name, TwcCardPanel);
    KnownTypes.set(TwcCardPanelItem.name, TwcCardPanelItem);
    KnownTypes.set(TwcCustomChart.name, TwcCustomChart);
    KnownTypes.set(TwcChartBar.name, TwcChartBar);
    KnownTypes.set(TwcChartLine.name, TwcChartLine);
    KnownTypes.set(TwcChartDoughnut.name, TwcChartDoughnut);
    KnownTypes.set(TwcChartPie.name, TwcChartPie);
    KnownTypes.set(TwcChartPolarArea.name, TwcChartPolarArea);
    KnownTypes.set(TwcChartRadar.name, TwcChartRadar);
    KnownTypes.set(TwcChartTacho.name, TwcChartTacho);
    KnownTypes.set(TwcCustomFileTransfer.name, TwcCustomFileTransfer);
    KnownTypes.set(TwcFileTransferSingleCheckout.name, TwcFileTransferSingleCheckout);
    KnownTypes.set(TwcFileTransferAttachments.name, TwcFileTransferAttachments);
    KnownTypes.set(TWebCheckbox.name, TWebCheckbox);
    KnownTypes.set(TwcClipboard.name, TwcClipboard);
    KnownTypes.set(TwcCollapseWrapper.name, TwcCollapseWrapper);
    KnownTypes.set(TwcCollapsePreview.name, TwcCollapsePreview);
    KnownTypes.set(TWebFormCombobox.name, TWebFormCombobox);
    KnownTypes.set(TwcComboMenu.name, TwcComboMenu);
    KnownTypes.set(TwcContainerItem.name, TwcContainerItem);
    KnownTypes.set(TwcContainer.name, TwcContainer);
    KnownTypes.set(TwcContentContainer.name, TwcContentContainer);
    KnownTypes.set(TwcContentContainerGroup.name, TwcContentContainerGroup);
    KnownTypes.set(TwcContextMenu.name, TwcContextMenu);
    KnownTypes.set(TwcImageCropper.name, TwcImageCropper);
    KnownTypes.set(TwcImageCropperDialog.name, TwcImageCropperDialog);
    KnownTypes.set(TwsCSObject.name, TwsCSObject);
    KnownTypes.set(TwcObjectListSelector.name, TwcObjectListSelector);
    KnownTypes.set(TwcObjectListSelectorPopDown.name, TwcObjectListSelectorPopDown);
    KnownTypes.set(TwcEditDate.name, TwcEditDate);
    KnownTypes.set(TwsEditorFroala.name, TwsEditorFroala);
    KnownTypes.set(TwcEditDouble.name, TwcEditDouble);
    KnownTypes.set(TwcEditNumber.name, TwcEditNumber);
    KnownTypes.set(TwcEmbed.name, TwcEmbed);
    KnownTypes.set(TwcDateInterval.name, TwcDateInterval);
    KnownTypes.set(TwcDispatcher.name, TwcDispatcher);
    KnownTypes.set(TwcDivEx.name, TwcDivEx);
    KnownTypes.set(TwcDragAndDrop.name, TwcDragAndDrop);
    KnownTypes.set(TwcDownloadPicker.name, TwcDownloadPicker);
    KnownTypes.set(TwcDropDownItem.name, TwcDropDownItem);
    KnownTypes.set(TwcDropDownSubMenu.name, TwcDropDownSubMenu);
    KnownTypes.set(TwcDropDownItemSeperator.name, TwcDropDownItemSeperator);
    KnownTypes.set(TwcDropDownList.name, TwcDropDownList);
    KnownTypes.set(TwcCustomFileSelector.name, TwcCustomFileSelector);
    KnownTypes.set(TwcDropZoneFileSelector.name, TwcDropZoneFileSelector);
    KnownTypes.set(TwcFileZoneFileSelector.name, TwcFileZoneFileSelector);
    KnownTypes.set(TwcSingleFileSelector.name, TwcSingleFileSelector);
    KnownTypes.set(TwsTemplateSelect.name, TwsTemplateSelect);
    KnownTypes.set(TwcFormGroup.name, TwcFormGroup);
    KnownTypes.set(TwcFormSectionGroup.name, TwcFormSectionGroup);
    KnownTypes.set(TwcFormSectionSubGroup.name, TwcFormSectionSubGroup);
    KnownTypes.set(TwcFormSection.name, TwcFormSection);
    // KnownTypes.set(TwcD3jsChart.name, TwcD3jsChart);
    // KnownTypes.set(TwcD3jsGraph.name, TwcD3jsGraph);
    KnownTypes.set(TwcFormSectionItem.name, TwcFormSectionItem);
    KnownTypes.set(TwcFullPage.name, TwcFullPage);
    KnownTypes.set(TwcFullPageSection.name, TwcFullPageSection);
    KnownTypes.set(TwcGenericInput.name, TwcGenericInput);
    KnownTypes.set(TwcHeatmapChart.name, TwcHeatmapChart);
    KnownTypes.set(TWebComponentHtmlArea.name, TWebComponentHtmlArea);
    KnownTypes.set(TwcIcon.name, TwcIcon);
    KnownTypes.set(TwcImage.name, TwcImage);
    KnownTypes.set(TwcImageEx.name, TwcImageEx);
    KnownTypes.set(TwcInfiniteScroll.name, TwcInfiniteScroll);
    KnownTypes.set(TwcJumbotron.name, TwcJumbotron);
    KnownTypes.set(TwcJumbotronCS.name, TwcJumbotronCS);
    KnownTypes.set(TWebComponentLabel.name, TWebComponentLabel);
    KnownTypes.set(TwcKanbanBoard.name, TwcKanbanBoard);
    KnownTypes.set(TwcKanbanRow.name, TwcKanbanRow);
    KnownTypes.set(TwcLink.name, TwcLink);
    KnownTypes.set(TwcListBox.name, TwcListBox);
    KnownTypes.set(TwcListBoxSearch.name, TwcListBoxSearch);
    KnownTypes.set(TwcCheckListBox.name, TwcCheckListBox);
    KnownTypes.set(TwsListFilterPanel.name, TwsListFilterPanel);
    KnownTypes.set(TwcListSearch.name, TwcListSearch);
    KnownTypes.set(TwcListView.name, TwcListView);
    KnownTypes.set(TwcLookupList.name, TwcLookupList);
    KnownTypes.set(TwcLookupTabs.name, TwcLookupTabs);
    KnownTypes.set(TwcLookupTabItem.name, TwcLookupTabItem);
    KnownTypes.set(TwcLookupContainer.name, TwcLookupContainer);
    KnownTypes.set(TwcDropDownMenuForLookupTabs.name, TwcDropDownMenuForLookupTabs);
    KnownTypes.set(TwsMap.name, TwsMap);
    KnownTypes.set(TwcMasonry.name, TwcMasonry);
    KnownTypes.set(TwcMasonryInfinite.name, TwcMasonryInfinite);
    KnownTypes.set(TwcMetric.name, TwcMetric);
    KnownTypes.set(TwcMetricButton.name, TwcMetricButton);
    KnownTypes.set(TwcMenu.name, TwcMenu);
    KnownTypes.set(TwcMenuItem.name, TwcMenuItem);
    KnownTypes.set(TwcModal.name, TwcModal);
    KnownTypes.set(TwcMultiSelect.name, TwcMultiSelect);
    KnownTypes.set(TwcMultiSelectIcon.name, TwcMultiSelectIcon);
    KnownTypes.set(TwcMultiSelectImg.name, TwcMultiSelectImg);
    KnownTypes.set(TwsFilterSelectionItem.name, TwsFilterSelectionItem);
    KnownTypes.set(TwsFilterSelection.name, TwsFilterSelection);
    KnownTypes.set(TwcNavBar.name, TwcNavBar);
    KnownTypes.set(TwcNavBarItem.name, TwcNavBarItem);
    KnownTypes.set(TwsOrgRelEditor.name, TwsOrgRelEditor);
    KnownTypes.set(TwcOverlayEmpty.name, TwcOverlayEmpty);
    KnownTypes.set(TwcOverlay.name, TwcOverlay);
    KnownTypes.set(TwcOverlayAnimation.name, TwcOverlayAnimation);
    KnownTypes.set(TwcRadioPicker.name, TwcRadioPicker);
    KnownTypes.set(TwcPopup.name, TwcPopup);
    KnownTypes.set(TwsTutorialEditor.name, TwsTutorialEditor);
    KnownTypes.set(TwsTutorialViewer.name, TwsTutorialViewer);
    KnownTypes.set(TwsProcessEditorStandard.name, TwsProcessEditorStandard);
    KnownTypes.set(TwsProcessEditorCluster.name, TwsProcessEditorCluster);
    KnownTypes.set(TwsProcessViewerSVG.name, TwsProcessViewerSVG);
    KnownTypes.set(TWebComponentProcessDiffViewerSVG.name, TWebComponentProcessDiffViewerSVG);
    KnownTypes.set(TwcProgressbar.name, TwcProgressbar);
    KnownTypes.set(TwcPublisherEx.name, TwcPublisherEx);
    KnownTypes.set(TwcPublisherSimple.name, TwcPublisherSimple);
    KnownTypes.set(TwcPopContainerList.name, TwcPopContainerList);
    KnownTypes.set(TwcPopContainer.name, TwcPopContainer);
    KnownTypes.set(TWebRadioButton.name, TWebRadioButton);
    KnownTypes.set(TwcRating.name, TwcRating);
    KnownTypes.set(TwsPreviewFroala.name, TwsPreviewFroala);
    KnownTypes.set(TwsReport.name, TwsReport);
    KnownTypes.set(TwsRiskMatrix.name, TwsRiskMatrix);
    KnownTypes.set(TwsSmartPropertyList.name, TwsSmartPropertyList);
    KnownTypes.set(TwsSpeechToText.name, TwsSpeechToText);
    KnownTypes.set(TwsFileSpeechToText.name, TwsFileSpeechToText);
    KnownTypes.set(TwcSuggestions.name, TwcSuggestions);
    KnownTypes.set(TwcTemplatedSuggestions.name, TwcTemplatedSuggestions);
    KnownTypes.set(TwcSuggestionsText.name, TwcSuggestionsText);
    KnownTypes.set(TwcDataTable.name, TwcDataTable);
    KnownTypes.set(TwcInfiniteDataTable.name, TwcInfiniteDataTable);
    KnownTypes.set(TwcSlider.name, TwcSlider);
    KnownTypes.set(TwcTable.name, TwcTable);
    KnownTypes.set(TwcTableGrid.name, TwcTableGrid);
    KnownTypes.set(TwcTabItem.name, TwcTabItem);
    KnownTypes.set(TwcTabPanel.name, TwcTabPanel);
    KnownTypes.set(TwsTagEditor.name, TwsTagEditor);
    KnownTypes.set(TwcTagFilterView.name, TwcTagFilterView);
    KnownTypes.set(TwcText.name, TwcText);
    KnownTypes.set(TwcTextArea.name, TwcTextArea);
    KnownTypes.set(TWebTextEdit.name, TWebTextEdit);
    KnownTypes.set(TwcTextMemo.name, TwcTextMemo);
    KnownTypes.set(TwcTileboardItem.name, TwcTileboardItem);
    KnownTypes.set(TwcTileboard.name, TwcTileboard);
    KnownTypes.set(TwcTileboardEditor.name, TwcTileboardEditor);
    KnownTypes.set(TwcTimegrid.name, TwcTimegrid);
    KnownTypes.set(TwcTimeline.name, TwcTimeline);
    KnownTypes.set(TwcTimer.name, TwcTimer);
    KnownTypes.set(TwcTitle.name, TwcTitle);
    KnownTypes.set(TwcToolbarSeparator.name, TwcToolbarSeparator);
    KnownTypes.set(TwcButtonToolbar.name, TwcButtonToolbar);
    KnownTypes.set(TwcTourController.name, TwcTourController);
    KnownTypes.set(TWebTree.name, TWebTree);
    KnownTypes.set(TwcTreeTable.name, TwcTreeTable);
    KnownTypes.set(TwcFileUpload.name, TwcFileUpload);
    KnownTypes.set(TWebFrame.name, TWebFrame);
    KnownTypes.set(TWebComponentWorkflowDesigner.name, TWebComponentWorkflowDesigner);
    KnownTypes.set(TwcWorkflowContext.name, TwcWorkflowContext);
    KnownTypes.set(TwcWorkflowContextItem.name, TwcWorkflowContextItem);
    KnownTypes.set(TwcZoomDiv.name, TwcZoomDiv);
    KnownTypes.set(TwcMonacoEditor.name, TwcMonacoEditor);
}