﻿import Color from 'color';
import { WebCompEventHandlerAsync } from '../../../../core/communication';
import { assigned } from '../../../../utils/helper';
import { ProcessMode, TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TwsProcessEditorStandard } from '../class.web.comp.process.editor.standard';
import { HorizontalAlignment, VerticalAlignment } from '../TBaseGraphObject';
import { FontStyles } from '../Text/TTextBase';
import { SVGNS } from '../Utils/TProcessEditorIDUtils';
import { TBaseShape } from './TBaseShape';

export enum SwimLaneMode {
    Swimlane,
    Responsibility
}

export class TShapeLane extends TBaseShape {
    private mode: SwimLaneMode;
    grid: SVGRectElement;
    isFixed: boolean;

    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 800;

        // Caption oben links
        this.captionHorzAlignment = HorizontalAlignment.taLeftJustify;
        this.captionVertAlignment = VerticalAlignment.taAlignTop;

        // delphi clGray
        this.fontColor = Color('#808080');
        this.fontStyles = [FontStyles.fsBold];

        this.inlineEdit = false;

        // hier folgen viele optionen..
        // todo: hier muss der modus von außen gesetzt werden..
        this.setMode(SwimLaneMode.Swimlane);
    }

    getSvgElementType(): string {
        return 'rect';
    }

    setFixed(value: boolean) {
        if (this.mode === SwimLaneMode.Swimlane) {
            if (value !== this.isFixed) {
                this.isFixed = value;

                this.isMoveable = !value;
                this.isResizePossible = !value;

                // Hintergrundfarbe aktualisieren
                this.updateBackgroundColor();
            }
        }
    }

    override isFixedElement(): boolean {
        return this.isFixed;
    }

    override setBackgroundColor(backgroundColor: Color) {
        // Swimlanes haben, je nach Mode, immer eine feste Farbe, die unveränderlich ist
        // das aktualisieren wir separat
        this.updateBackgroundColor();
    }

    updateBackgroundColor() {
        super.setBackgroundColor(this.getColor(), this.gradient);

        if (this.mode === SwimLaneMode.Responsibility)
            this.svgElement.setAttribute('stroke', '#c8c8c8');
    }

    override create() {
        super.create();

        // erst hier wissen wir sicher, dass wir den editor kennen :///
        if (this.editor.processMode === ProcessMode.Normal)
            this.setMode(SwimLaneMode.Responsibility);

        this.setFixed((this.editor as TwsProcessEditorStandard)?.swimLanesFixed ?? false);

        // SwimLanes müssen den niedrigsten Z-Index haben und somit am Anfang des SVG stehen
        // todo: gucken ob die reihenfolge der swimlanes beim z-index beachtet werden soll
        if (this.mode === SwimLaneMode.Swimlane) {
            this.editor.editorSVG.insertBefore(this.svgElement, this.editor.background.nextSibling);
        }
    }

    override delete() {
        super.delete();

        if (assigned(this.grid)) {
            document.getElementById(this.editor.svgID).removeChild(this.grid);
            this.grid = null;
        }
    }

    override repaint() {
        super.repaint();

        if (this.mode === SwimLaneMode.Swimlane) {
            if (!assigned(this.grid)) {
                this.grid = document.createElementNS(SVGNS, 'rect');
            }

            this.grid.setAttribute('id', `${this.id}-grid`);
            this.grid.setAttribute('x', String(this.boundingRect.left));
            this.grid.setAttribute('y', String(this.boundingRect.top));
            this.grid.setAttribute('width', String(this.boundingRect.width));
            this.grid.setAttribute('height', String(this.boundingRect.height));
            this.grid.setAttribute('fill', 'url(#' + String(this.editor.svgID) + '-gridPattern)');
            this.grid.setAttribute('pointer-events', 'none');

            this.editor.editorSVG.insertBefore(this.grid, this.svgElement.nextSibling);
        }
    }

    getColor(): Color {
        if (this.mode === SwimLaneMode.Swimlane) {
            if (this.isFixed) {
                return Color('#f0eef0');
            }
            else {
                return Color('#e2eefc');
            }
        }
        else if (this.mode === SwimLaneMode.Responsibility) {
            return Color('#fffff0');
        }
        else {
            throw `Unknown swimlane mode [${this.mode}].`;
        }
    }

    setMode(newMode: SwimLaneMode): void {
        this.mode = newMode;

        if (this.mode === SwimLaneMode.Swimlane) {
            this.hasAnchors = false;
            this.gradient = 0;
        }
        else if (this.mode === SwimLaneMode.Responsibility) {
            this.hasAnchors = true;
            this.gradient = 0.2;
        }
        else {
            throw `Unknown swimlane mode [${this.mode}].`;
        }

        // Hintergrundfarbe aktualisieren
        this.updateBackgroundColor();
    }
}