import { v4 as uuidv4, validate } from 'uuid';
import { TBaseTutorialShape } from '../Shapes/TBaseTutorialShape';
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';

export enum TutorialActionType {
    tatShapeCreate, tatShapeDelete, tatShapePosition, tatShapeSize,
    tatGroupAction, tatShapeBackgroundColor, tatShapeBorderColor,
    tatShapeTextContent, tatShapeImage, tatShapeOwnerPos, tatShapeRotation,
    tatShapeBackgroundOpacity, tatShapeBorderOpacity, tatShapeFontSize,
    tatShapeHorizontalAlign, tatShapeVerticalAlign,
    tatShapeBorderWidth, tatShapeFontColor, tatShapeMargins, tatStream,
    tatShapeLineHeight
};

export abstract class TCustomTutorialEditorAction<T> {
    actionGuid: string;
    refElement: T;
    svgEditor: TwsTutorialEditor;

    constructor(refElement: T, editor: TwsTutorialEditor) {
        this.actionGuid = uuidv4();
        this.refElement = refElement;
        this.svgEditor = editor;
    }

    fromJSON(obj: any): void {
        if (!validate(obj.actionGuid))
            throw `Guid for action is not valid [${obj.actionGuid}].`;

        this.actionGuid = obj.actionGuid;
    }

    toJSON(): any {
        return { actionType: this.typeID(), actionGuid: this.actionGuid };
    }

    abstract typeID(): TutorialActionType;

    abstract performAction(): void;

    abstract setValue(...args: any[]): void;

    abstract inverseAction(): TCustomTutorialEditorAction<T>;
}

export abstract class TCustomTutorialShapeAction extends TCustomTutorialEditorAction<TBaseTutorialShape>{ }