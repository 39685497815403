import { TutorialActionType } from "../Actions/TTutorialEditorAction";
import { TTutorialGroupAction } from "../Actions/TTutorialGroupAction";
import { TTutorialShapeHorizontalAlignAction } from "../Actions/TTutorialHorizontalAlignAction";
import { TTutorialShapeBackgroundColorAction } from "../Actions/TTutorialShapeBackgroundColorAction";
import { TTutorialShapeBackgroundOpacityAction } from "../Actions/TTutorialShapeBackgroundOpacityAction";
import { TTutorialShapeBorderColorAction } from "../Actions/TTutorialShapeBorderColorActions";
import { TTutorialShapeBorderOpacityAction } from "../Actions/TTutorialShapeBorderOpacityAction";
import { TTutorialShapeBorderWidthAction } from "../Actions/TTutorialShapeBorderWidthAction";
import { TTutorialShapeCreateAction } from "../Actions/TTutorialShapeCreateAction";
import { TTutorialShapeDeleteAction } from "../Actions/TTutorialShapeDeleteAction";
import { TTutorialShapeFontColorAction } from "../Actions/TTutorialShapeFontColorAction";
import { TTutorialShapeFontSizeAction } from "../Actions/TTutorialShapeFontSizeAction";
import { TTutorialShapeImageAction } from "../Actions/TTutorialShapeImageAction";
import { TTutorialShapeLineHeightAction } from "../Actions/TTutorialShapeLineHeightAction";
import { TTutorialShapeMarginAction } from "../Actions/TTutorialShapeMarginAction";
import { TTutorialShapeOwnerPosAction } from "../Actions/TTutorialShapeOwnerPosAction";
import { TTutorialShapePositionAction } from "../Actions/TTutorialShapePositionAction";
import { TTutorialShapeRotationAction } from "../Actions/TTutorialShapeRotationAction";
import { TTutorialShapeSizeAction } from "../Actions/TTutorialShapeSizeAction";
import { TTutorialShapeTextContentAction } from "../Actions/TTutorialShapeTextContentAction";
import { TTutorialStreamAction } from "../Actions/TTutorialStreamAction";
import { TTutorialShapeVerticalAlignAction } from "../Actions/TTutorialVerticalAlignAction";
import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";

export function getTutorialActionByTypeID(actionTypeID: TutorialActionType, refElement: any, editor: TwsTutorialEditor) {
    switch (actionTypeID) {
        case TutorialActionType.tatShapeCreate:
            return new TTutorialShapeCreateAction(refElement, editor);
        case TutorialActionType.tatShapeDelete:
            return new TTutorialShapeDeleteAction(refElement, editor);
        case TutorialActionType.tatShapePosition:
            return new TTutorialShapePositionAction(refElement, editor);
        case TutorialActionType.tatShapeSize:
            return new TTutorialShapeSizeAction(refElement, editor);
        case TutorialActionType.tatGroupAction:
            return new TTutorialGroupAction(refElement, editor);
        case TutorialActionType.tatShapeBackgroundColor:
            return new TTutorialShapeBackgroundColorAction(refElement, editor);
        case TutorialActionType.tatShapeBorderColor:
            return new TTutorialShapeBorderColorAction(refElement, editor);
        case TutorialActionType.tatShapeTextContent:
            return new TTutorialShapeTextContentAction(refElement, editor);
        case TutorialActionType.tatShapeImage:
            return new TTutorialShapeImageAction(refElement, editor);
        case TutorialActionType.tatShapeOwnerPos:
            return new TTutorialShapeOwnerPosAction(refElement, editor);
        case TutorialActionType.tatShapeRotation:
            return new TTutorialShapeRotationAction(refElement, editor);
        case TutorialActionType.tatShapeBackgroundOpacity:
            return new TTutorialShapeBackgroundOpacityAction(refElement, editor);
        case TutorialActionType.tatShapeBorderOpacity:
            return new TTutorialShapeBorderOpacityAction(refElement, editor);
        case TutorialActionType.tatShapeFontSize:
            return new TTutorialShapeFontSizeAction(refElement, editor);
        case TutorialActionType.tatShapeHorizontalAlign:
            return new TTutorialShapeHorizontalAlignAction(refElement, editor);
        case TutorialActionType.tatShapeVerticalAlign:
            return new TTutorialShapeVerticalAlignAction(refElement, editor);
        case TutorialActionType.tatShapeBorderWidth:
            return new TTutorialShapeBorderWidthAction(refElement, editor);
        case TutorialActionType.tatShapeFontColor:
            return new TTutorialShapeFontColorAction(refElement, editor);
        case TutorialActionType.tatShapeMargins:
            return new TTutorialShapeMarginAction(refElement, editor);
        case TutorialActionType.tatStream:
            return new TTutorialStreamAction(refElement, editor);
        case TutorialActionType.tatShapeLineHeight:
            return new TTutorialShapeLineHeightAction(refElement, editor);

        default:
            throw `Invalid TutorialActionType [${actionTypeID}]!`;
    }
}