import { assigned } from '../../../../utils/helper';
import { TCustomAction } from './TCustomAction';

export type ActionListChangedEventHandler = (action: TCustomAction<any>) => void;

export class TCustomActionList {
    actions: Array<TCustomAction<any>>;
    listPointer: number;
    private changeHandler: ActionListChangedEventHandler;

    constructor() {
        this.reset();
    }

    private notifyActionEvent(action: TCustomAction<any>): void {
        if (assigned(this.changeHandler)) {
            this.changeHandler(action);
        }
    }

    reset(): void {
        this.actions = [];
        this.listPointer = -1;
    }

    /**
    * Adds a new action
    * @param action 
    * @returns New Pointer position.
    * @remarks Old Redo actions will be discarded.
    */
    addAction(action: TCustomAction<any>): number {
        // ab hier verfallen die redos
        this.actions.length = this.listPointer + 1;
        this.actions.push(action);

        return ++this.listPointer;
    }

    /**
     * Adds a new action performed in the client
     * @param action 
     * @returns New Pointer position.
     * @remarks Old Redo actions will be discarded.
     */
    addClientAction(action: TCustomAction<any>): number {
        let result = this.addAction(action);
        this.notifyActionEvent(action);
        return result;
    }

    /**
     * Adds a new action performed in the server
     * @param action 
     * @returns New Pointer position.
     * @remarks Old Redo actions will be discarded.
     */
    registerServerAction(action: TCustomAction<any>): number {
        return this.addAction(action);
    }

    getRedoAction(): TCustomAction<any> {
        if (this.listPointer + 1 >= this.actions.length)
            return null;

        this.listPointer++;
        let action = this.actions[this.listPointer];

        return action;
    }

    getUndoAction(): TCustomAction<any> {
        if (this.listPointer < 0)
            return null;

        let action = this.actions[this.listPointer];
        this.listPointer--;

        return action.inverseAction();
    }

    getCurrentStateActionGuid(): string {
        console.debug(this);
        return this.actions[this.listPointer].actionGuid;
    }

    toJson(): any {
        let actions = [];

        this.actions.forEach(action => actions.push(action.toJSON()));

        return actions;
    }

    toJsonAsString(): string {
        return JSON.stringify(this.toJson());
    }

    registerChangeEvent(handler: ActionListChangedEventHandler) {
        this.changeHandler = handler;
    }
}