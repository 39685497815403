import Color from 'color';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodeBackgroundColorAction extends TCustomNodeAction {
    oldGradient: number;
    newGradient: number;
    oldBackgroundColor: Color;
    newBackgroundColor: Color;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patShapeBackgroundColor;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldGradient = obj.oldGradient;
        this.newGradient = obj.newGradient;
        this.oldBackgroundColor = Color(obj.oldBackgroundColor);
        this.newBackgroundColor = Color(obj.newBackgroundColor);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldGradient = this.oldGradient;
        obj.newGradient = this.newGradient;
        obj.oldBackgroundColor = this.oldBackgroundColor.hex();
        obj.newBackgroundColor = this.newBackgroundColor.hex();

        return obj;
    }

    setValue(newBackgroundColor: Color, newUseGradient: number): void {
        this.newBackgroundColor = newBackgroundColor;
        this.newGradient = newUseGradient;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setBackgroundColor(this.newBackgroundColor, this.newGradient);
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeBackgroundColorAction(this.refElement, this.svgEditor);
        result.setValue(this.oldBackgroundColor, this.oldGradient);

        return result;
    }
}
