import { WebCompEventHandler } from "../../core/communication";
import { ConsoleMessageType, CsConsole } from "../../utils/console";
import { getUniqueId } from "../../utils/html";
import { findComponent } from "../base/controlling";

export enum ComponentProcessingMode {
    TProcessingModeNone = 0,
    TProcessingModeSpinnerEmbedded = 1,
    TProcessingModeSpinnerFullOverlay = 2,
    TProcessingModeSpinnerOverlay = 3,
}


export interface ISupportsProcessingMode {
    processingModeDiscriminator: 'I-SUPPORTS-PROCESSINGMODE';
    ProcessingMode: ComponentProcessingMode;
}

export function isISupportsProcessingMode(object: any): boolean {
    return object.processingModeDiscriminator === 'I-SUPPORTS-PROCESSINGMODE';
}

export function tryGetAsProcessingMode(object: any): ISupportsProcessingMode | null {
    if (isISupportsProcessingMode(object)) {
        return object as ISupportsProcessingMode;
    }

    return null;
}


export interface ILoadingSpinner {
    showLoadingSpinnerPromise(): Promise<any>;
    hideLoadingSpinner(): void;
}

function isLoadingSpinner(object: any): object is ILoadingSpinner {
    return 'showLoadingSpinnerPromise' in object && 'hideLoadingSpinner' in object;
}


/*------------------------------------------*/
/* OVERLAYS + SPINNER MODES                 */
/*------------------------------------------*/
export async function actProcessingModeSpinnerEmbedded(action: string, id: string): Promise<void> {
    let comp = findComponent(id);

    if (isLoadingSpinner(comp)) {
        try {
            let myPromise = await comp.showLoadingSpinnerPromise();
            $.when(myPromise).then(async function (receiveResult) {
                // UPS // Das WebCompEventHandler hat kein Return?
                let requestReady = await WebCompEventHandler(action, id);
                $.when(requestReady).done(function (e) {
                    if (isLoadingSpinner(comp)) {
                        comp.hideLoadingSpinner();
                    }
                });
            });
        } catch (error) {
            console.error(error);
        }
    }
}
/*------------------------------------------*/
/* full overlay                             */
/*------------------------------------------*/
export async function actProcessingSpinnerFullOverlay(action: string, id: string): Promise<void> {
    try {
        let myPromise = await showFullOverlayPromise();
        $.when(myPromise).then(async function (modalid) {
            // UPS // Das WebCompEventHandler hat kein Return?
            let requestReady = await WebCompEventHandler(action, id);
            $.when(requestReady).done(function (x) {
                hideFullOverlayPromise(modalid)
            });
        });
    } catch (error) {
        console.error(error);
    }
}

/*------------------------------------------*/
function hideFullOverlayPromise(modalid: string): void {
    $('#' + modalid + '').modal('hide');
    document.getElementById(`${modalid}`).remove();
}

/*------------------------------------------*/
function showFullOverlayPromise(): Promise<string> {
    let modalid = getUniqueId('dummyOverlayModal');
    let modal = $('<div class="modal modal-alert fade spinner-fulloverlay csspinner" id="' + modalid + '" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true"></div>');
    let modalDialog = $('<div class="modal-dialog modal-dialog-centered" role="document"></div>');
    modal.append(modalDialog);
    let modalContent = $('<div class="modal-content border-0 bg-transparent"></div>');
    modalDialog.append(modalContent);
    let modalBody = $('<div class="modal-body text-center"><div class="spinner-border text-secondary" style="width: 4rem; height: 4rem;" role="status"><span class="sr-only">Loading...</span></div></div>');
    modalContent.append(modalBody);
    $('body').append(modal);

    // Overlay ganz oben, default ist 1040
    modal.css('zIndex', '99999');

    return new Promise(function (resolve, reject) {
        modal.get(0).addEventListener('shown.bs.modal', function () {
            resolve(modalid);
        });

        modal.modal('show');
        $('.modal-backdrop.show').last().css('zIndex', '99998').addClass('csspinner');
    });
}

/*------------------------------------------*/
/* overlay                                  */
/*------------------------------------------*/
export async function actProcessingModeSpinnerOverlay(action: string, id: string): Promise<void> {
    try {
        let myPromise = await showSpinnerOverlayPromise();
        $.when(myPromise).then(async function (modalid) {
            // UPS // Das WebCompEventHandler hat kein Return?
            let requestReady = await WebCompEventHandler(action, id);
            $.when(requestReady).done(function (x) {
                hideSpinnerOverlayPromise(modalid);
            });
        });
    } catch (error) {
        let message = `Promise at ${error.stack} -> ${error}`;
        CsConsole(message, ConsoleMessageType.Error);
    }
}

/*------------------------------------------*/
function hideSpinnerOverlayPromise(modalid: string): void {
    $('#' + modalid + '').modal('hide');
    document.getElementById(`${modalid}`).remove();
}

/*------------------------------------------*/
function showSpinnerOverlayPromise(): Promise<string> {
    let modalid = getUniqueId('dummyOverlayModal');
    let modal = $('<div class="modal modal-alert fade spinner-overlay csspinner" id="' + modalid + '" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true"></div>');
    let modalDialog = $('<div class="modal-dialog modal-dialog-centered modal-sm" role="document"></div>');
    modal.append(modalDialog);
    let modalContent = $('<div class="modal-content py-4" style="background: rgba(34,34,48, .6);"></div>');
    modalDialog.append(modalContent);
    let modalBody = $('<div class="modal-body text-center"><div class="spinner-border text-white" style="width: 2rem; height: 2rem;" role="status"><span class="sr-only">Loading...</span></div></div>');
    modalContent.append(modalBody);
    $('body').append(modal);

    // Overlay ganz oben, default ist 1040
    modal.css('zIndex', '99999');

    return new Promise(function (resolve, reject) {
        modal.get(0).addEventListener('shown.bs.modal', function () {
            resolve(modalid);
        });

        modal.modal('show');
        $('.modal-backdrop.show').last().css('opacity', 0).addClass('csspinner');
    });
}