import { assigned } from "./helper";

export function boolFromStr(str: string, defaultValue: boolean = false): boolean {
    try {
        if (!assigned(str)) {
            return defaultValue;
        }

        let parsedValue = csJSONParse(str.toLowerCase());

        if (typeof parsedValue === 'boolean') {
            return parsedValue;
        }
        else if (typeof parsedValue === 'number') {
            return parsedValue !== 0;
        }
        else {
            return defaultValue;
        }
    } catch (error) {
        console.warn(`Error during boolean conversion. Defaulting to *${defaultValue}*. ${error}`);
        return defaultValue;
    }
}

export function strFromBool(value: boolean, asNumber: boolean = false): string {
    if (!asNumber) {
        return String(value);
    }
    return value ? '1' : '0';
}

/**
 * Checks if two strings are equal, ignoring their case.
 * @param a String a.
 * @param b String b.
 * @param ignoreAccents Pass "true" to also ignore accents, for example à and a and A are all treated as same charater.
 * @remarks Runs "localCompare" with "accent" sensitivity, unless @param ignoreAccents is not set to true.
 */
export function sameText(a: string, b: string, ignoreAccents?: boolean): boolean {
    // The { sensitivity: 'accent' } tells localeCompare() to treat two variants of the same base letter as the same unless they have different accents (as in the third example) above.
    // https://stackoverflow.com/a/2140723

    let compareLevel = 'accent';
    if (ignoreAccents) {
        compareLevel = 'base';
    }

    return a.localeCompare(b, undefined, { sensitivity: compareLevel }) === 0;
}


/**
 * Count Kbytes in a string's UTF-8 representation.
 * @param str 
 */
export function getStringSizeKB(str: string): number {
    var byteLen = 0;
    for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        byteLen += c < (1 << 7) ? 1 :
            c < (1 << 11) ? 2 :
                c < (1 << 16) ? 3 :
                    c < (1 << 21) ? 4 :
                        c < (1 << 26) ? 5 :
                            c < (1 << 31) ? 6 : Number.NaN;
    }
    return byteLen / 1000;
}

/*------------------------------------------*/
/* // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings/30106551#30106551
/*------------------------------------------*/
//Decoding base64 ⇢ UTF8 
export function b64DecodeUnicode(str): string {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}
// Encoding UTF8 ⇢ base64
export function b64EncodeUnicode(str): string {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

export function removeControlCodes(str: string): string {
    // wir entfernen alle Zeichen, die in https://en.wikipedia.org/wiki/List_of_Unicode_characters unter "Control Codes" stehen
    return str.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
}

export function csJSONParse(str: string): any {
    return JSON.parse(removeControlCodes(str));
}

// kürzt den Text auf n Zeichen und packt ggf. noch ... hinten dran
export function truncateText(str: string, n: number, useDots: boolean) {
    return (str.length > n) ? str.slice(0, n - 1) + (useDots ? '&hellip;' : '') : str;
};