'use strict';

import {BarController} from 'chart.js';
import {merge} from 'chart.js/helpers';
import {CustomElementBar} from './element.custom.bar';

export class CustomControllerBar extends BarController {

	draw() {
		super.draw();
	}
}

CustomControllerBar.id = 'customControllerBar';
CustomControllerBar.defaults = merge(BarController.defaults, {dataElementType: CustomElementBar.id});
