﻿import { WebCompEventHandler, WebCompEventHandlerAsync } from '../../../core/communication';
import { isComponentActive } from '../../../core/utils/componentUtils';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { getParentByClassname } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

require('datatables.net-bs5')(window, $);
require('datatables.net-responsive-bs5')(window, $);
require('datatables.net-scroller-bs5')(window, $);



export class TwcInfiniteDataTable extends TRenderWebComponent {
    table: DataTables.Api;
    tableID: string;
    tableElement: HTMLTableElement;
    modalContainer: Element;

    HasOnChange: boolean;
    HasOnRightClick: boolean;
    HasOnDblClick: boolean;
    canSelect: boolean;
    selectedRowId: string;

    needsOptimization: boolean;
    optimizePosAllowed: boolean;

    private resizeObserver: ResizeObserver;
    autoSelectFirstElement: boolean;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcInfiniteDataTable';
        this.tableID = this.obj.dataset.tableid;
        this.tableElement = document.getElementById(this.tableID) as HTMLTableElement;
        this.modalContainer = getModalDialogContainer(this.obj);

        this.canSelect = boolFromStr(this.obj.dataset.hascanselect);
        this.HasOnChange = boolFromStr(this.obj.dataset.hasonchange);
        this.HasOnRightClick = boolFromStr(this.obj.dataset.hasonrightclick);
        this.HasOnDblClick = boolFromStr(this.obj.dataset.hasondblclick);
        this.autoSelectFirstElement = boolFromStr(this.obj.dataset.selectFirst);

        this.selectedRowId = this.obj.dataset.selectedrowid;
        this.needsOptimization = false;
        this.optimizePosAllowed = false;
    }

    override initDomElement() {
        super.initDomElement();

        this.initThisTable();

        this.obj.addEventListener('contextmenu', event => this.onRightClick(event));
        this.obj.addEventListener('dblclick', event => this.onDblClick(event));

        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                this.needsOptimization = true;
                // wenn wir aber im modalen dialog sind sollten wir bein letzen schließen hier nichts mehr optimieren
                // deswegen suchen wir nach einer validen id im DOM (beim schließen gibt es die nicht mehr...)
                if (this.isInsideModal() && !assigned(document.getElementById(this.id))) {
                    this.needsOptimization = false;
                }
                this.invalidate();
            });
        });

        if (this.isInsideModal()) {
            // obacht: wir geben wegen einem scoll-Bug den modalen Dialogen eine Klasse modal-noscroll-
            // dadurch verändert sich document.body nicht mehr in der Höhe, solange ein Modal geöffnet ist
            // deswegen horchen wsir hier auf den modalen dialog
            this.resizeObserver.observe(this.modalContainer);
        } else {
            this.resizeObserver.observe(this.obj);
        }
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        if (!isComponentActive(this)) {
            return;
        }

        this.optimizePosition();

        this.tableElement.querySelectorAll('[data-id]').forEach(element => {
            element.classList.remove('selectedRow');
        });

        this.tableElement.querySelector(`[data-id="${this.selectedRowId}"]`)?.classList?.add('selectedRow');

        if (this.autoSelectFirstElement && this.canSelect && this.selectedRowId === '') {
            this.setSelectedRow(this.tableElement.querySelector('[data-id]:first-of-type') as HTMLElement);
        }
        this.optimizeTableCells();
    }

    isInsideModal(): boolean {
        return assigned(this.modalContainer);
    }

    initThisTable() {
        this.table = $('#' + this.tableID).DataTable();
        let _this = this;
        this.table.on('draw', function () {
            if (_this.canSelect) {
                _this.initCanSelect();
            }
            _this.optimizePosAllowed = true;
            _this.invalidate();
        });

        this.table.on('xhr.dt', function (e, settings, json, xhr) {
            _this.invalidate();
        });
    }

    onDblClick(event: MouseEvent): void {
        // ups hier doppelter code mit internalOnRightClick
        if (this.HasOnDblClick) {
            // bei TH gehen wir raus
            if ((event.target as Element).nodeName == 'TH') {
                return;
            }
            let parentElement = (event.target as Element).parentElement;
            // leider kann man z.b. genau mit der maustaste auch das icon treffen und dann geht das menü nicht auf.
            // hier suchen wir nach dem richtigen parent. Abbruch auch wenn wir ausserhalb unserer Komponente landen sollten
            while (!(parentElement instanceof HTMLTableRowElement) && this.obj.contains(parentElement)) {
                parentElement = parentElement.parentElement;
            }

            // wir wollen nur im tbody die klicks. TH darf nicht geklickt werden, unser Parent muss also TR sein
            if (parentElement.nodeName == 'TR') {
                event.preventDefault();
                this.handleRowClicked(parentElement as HTMLTableRowElement);
                // this.notifyComponentChanged();
                WebCompEventHandlerAsync('OnDblClick', this.id);
            }
        }
    }

    onRightClick(event: MouseEvent): void {
        // ups hier doppelter code mit onDblClick
        if (this.HasOnRightClick) {
            // bei TH gehen wir raus
            if ((event.target as Element).nodeName == 'TH') {
                return;
            }
            let parentElement = (event.target as Element).parentElement;
            // leider kann man z.b. genau mit der rechten maustaste auch das icon treffen und dann geht das menü nicht auf.
            // hier suchen wir nach dem richtigen parent. Abbruch auch wenn wir ausserhalb unserer Komponente landen sollten
            while (!(parentElement instanceof HTMLTableRowElement) && this.obj.contains(parentElement)) {
                parentElement = parentElement.parentElement;
            }
            // wir wollen nur im tbody die klicks. TH darf nicht geklickt werden, unser Parent muss also TR sein
            if (parentElement.nodeName == 'TR') {
                event.preventDefault();
                this.handleRowClicked(parentElement as HTMLTableRowElement);
                // this.notifyComponentChanged();
                WebCompEventHandlerAsync('OnRightClick', this.id);
            }
        }
    }

    handleSelectionChanged() {
        this.notifyComponentChanged();
        if (this.HasOnChange) {
            WebCompEventHandler('OnSelectionChanged', this.id);
        }
    }

    initCanSelect() {
        let rows = this.tableElement.tBodies[0]?.rows;

        // wenn wir nur das Element zur Kennezichnung der leeren Tabelle haben -> nicht selektierbar
        if (rows.length == 1) {
            let child: any = rows[0].childNodes[0];
            if (child.classList.contains('dataTables_empty')) {
                this.obj.classList.add('emptyTable');
                return;
            } else {
                // Es kann auch nur 1 Element vorhanden sein
                this.obj.classList.remove('emptyTable');
            }
        } else if (rows.length > 1) {
            // Die Tabelle kann vorher leer gewesen sein.
            this.obj.classList.remove('emptyTable');
        }

        for (let i = 0; i < rows.length; i++) {
            // damit blur und focus auf einer Table funktioniert, muss man tabindex setzten
            rows[i].setAttribute('tabindex', '0');
            rows[i].addEventListener('blur', event => {
                this.tableElement.classList.remove('selectedRow-focused');
            });
            rows[i].addEventListener('focus', event => {
                this.tableElement.classList.add('selectedRow-focused');
            });
            rows[i].addEventListener('click', e => {
                this.handleRowClicked(rows[i]);
            });
        }
        // nun setzen wir noch das row
        this.setSelectedRow(this.selectedRowId);
    }

    handleRowClicked(row: HTMLTableRowElement): void {
        this.setSelectedRow(row);
    }

    setSelectedRow(row: HTMLElement | string | null): void {
        let newRowId = (row instanceof Element ? row.dataset.id : row) ?? '';

        if (newRowId !== this.selectedRowId) {
            this.selectedRowId = newRowId;
            this.notifyComponentChanged();

            if (this.HasOnChange) {
                WebCompEventHandler('OnSelectionChanged', this.id);
            }

            this.invalidate();
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'SelectedRow', this.selectedRowId]);
        return properties;
    }

    override execAction(action: string, params: string): void {
        try {
            switch (action) {
                case 'Action.Reload':
                    this.table.ajax.reload();
                    break;
                default:
                    super.execAction(action, params);
            }
        } catch (e) {
            console.error(e);
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'SelectedRow':
                this.setSelectedRow(value);
                break;
        }
    }

    optimizeTableCells(): void {
        let tableHeader = this.obj.querySelector('.dataTables_scrollHead') as HTMLElement;
        let tableBody = this.obj.querySelector('.dataTables_scrollBody') as HTMLElement;

        let allHeaderCells = tableHeader.querySelectorAll('th');
        allHeaderCells.forEach((headerCell, index) => {
            if (headerCell.classList.contains('cellToOptimize') && parseInt(headerCell.style.width) == 0) {
                let allTableCells = tableBody.querySelectorAll('tbody tr td:nth-child(' + (index + 1) + ')');
                let width = 0;
                headerCell.classList.remove('d-none');
                allTableCells.forEach(tableCell => {
                    // wenn wir nachladen, dann removen wir die d-nones erstmal
                    (tableCell as HTMLTableCellElement).classList.remove('d-none');
                    // jetzt schauen wir mal nach dem Inhalt
                    if ((tableCell as HTMLTableCellElement).outerText != '') {
                        let scrollwidth = (tableCell as HTMLTableCellElement).scrollWidth;
                        width = Math.max(width, scrollwidth);
                    }
                });
                if (width != 0) {
                    let hiddenTableHeaderCell = tableBody.querySelector('thead tr th:nth-child(' + (index + 1) + ')');
                    (hiddenTableHeaderCell as HTMLTableCellElement).style.setProperty('width', width + 'px');
                    headerCell.style.setProperty('width', width + 'px');
                    let table = tableHeader.querySelector('table');
                    table.style.setProperty('padding-right', '15px'); //wir brauchen noch etwas padding, wegen der Scrollbar
                } else {
                    // dann d-none -> vorher alle d-none löschen, wenn man nachläd
                    headerCell.classList.add('d-none');
                    allTableCells.forEach(tableCell => {
                        (tableCell as HTMLTableCellElement).classList.add('d-none');
                    });
                }
            }
        });
    }

    optimizePosition(): void {
        // erst wenn wir dürfen, so sparen wir uns unnötige Berechnungen und Sprünge am Anfang
        if (!this.optimizePosAllowed) {
            return;
        }

        if (!this.needsOptimization) {
            return;
        }

        let availableSpaceHorizontal = 0, height = 0;
        let tableRect = this.obj.getBoundingClientRect();
        let tableHeader = this.obj.querySelector('.dataTables_scrollHead');
        let tableBody = this.obj.querySelector('.dataTables_scrollBody') as HTMLElement;

        let tableBodyScrollDivCalculate = false;

        if (this.isInsideModal()) {
            // im modalen contianer
            let modalBody = this.modalContainer.querySelector('.modal-body');
            let parentRectModalBody = modalBody.getBoundingClientRect();

            let computedStyleModal = window.getComputedStyle(modalBody);
            availableSpaceHorizontal = Math.floor(parentRectModalBody.height - (tableRect.y - parentRectModalBody.y));
            // nun noch den Header abziehen
            height = availableSpaceHorizontal - tableHeader.clientHeight - parseFloat(computedStyleModal.paddingBottom);

            // nach umstellung der neuen Dialoge, müssen wir noch die form und das fieldset mitrechnen
            let fieldset = getParentByClassname(this.obj, 'form-group');
            if (assigned(fieldset)) {
                height -= parseFloat(window.getComputedStyle(fieldset).marginBottom);
            }

        } else {
            availableSpaceHorizontal = document.documentElement.clientHeight - tableRect.y;
            // schauen ob wir mehr Platz benötigen als wir haben.
            let tableBodyHeight = tableBody.querySelector('table').getBoundingClientRect().height;
            let totalTableHeight = tableBodyHeight + tableHeader.getBoundingClientRect().height;
            if (availableSpaceHorizontal > totalTableHeight) {
                height = tableBodyHeight + 15; // noch ein bisschen hin
                // die Tabelle ist kleiner als der Space den wir zur verfügung haben, also brauchen wir keine scroller-berechnungen
                tableBodyScrollDivCalculate = true;
            } else {
                height = availableSpaceHorizontal - tableHeader.clientHeight - 15; // noch ein bisschen weg
            }
        }

        this.needsOptimization = false;

        // Datatables verwenden ein DIV, damit bestimmt werden kann, ab wann Elemente nachgeladen werden müssen
        // DIV sichtbar -> Ajax Request senden
        // DIV nur vorhanden, wenn Scroller mit LoadingIndicator verwendet wird (siehe Delphi)
        if (assigned(tableBody.children[1])) {
            // das wollen wir aber nur, wenn die Tabelle auch scrollbar ist
            let scrollerDIV = tableBody.children[1] as HTMLElement;
            if (tableBodyScrollDivCalculate) {
                // kein Scroll, dann DIV auf 0
                scrollerDIV.style.setProperty('height', '0px');
                scrollerDIV.style.setProperty('max-height', '0px');
            } else {
                let firstRow = tableBody.querySelector('tbody tr') as HTMLElement;
                // sollte immer da sein, da wir sonst keinen Scrollbereich hätten...
                if (assigned(firstRow)) {
                    $('#' + this.tableID).DataTable().scroller.measure(false);
                    $('#' + this.tableID).DataTable().draw();
                }
            }
        }

        tableBody.style.setProperty('max-height', height + 'px');
        tableBody.style.setProperty('height', height + 'px');
        $('#' + this.tableID).DataTable().columns.adjust();
    }
}

$(document).on('shown.bs.modal', function (e) {
    let modal = $(e.target);
    // Modal vorhanden?
    if (modal.length > 0) {
        let table = modal.find('[data-type="TwcInfiniteDataTable"]').find('.table');
        // Datatable vorhanden?
        if (table.length > 0) {
            // Header anpassen, da table jetzt erst sichtbar...
            // verzögert, da scroll die columns wieder resized
            if (!table.hasClass('dataTables_fixed')) {
                window.setTimeout(() => table.DataTable().columns.adjust(), 150);
            }
            // nur wenn responsive
            if (table.hasClass('responsive')) {
                window.setTimeout(() => table.DataTable().responsive.recalc(), 150);
            }
        }
    }
});

// die Infinite Tabellen können kein onclick übergeben bekommen. Deshalb setzen wir das hier selber auf die Rows
function InfiniteDataTableLinker(row: any, data: any[]) {
    var n = 0;
    $(row).find('td').each(function () {
        var clickEvent = $(data[n]).data('clicktarget');
        if (typeof clickEvent != 'undefined') {
            $(this).addClass('cursor-pointer');
            $(this).click(function () { javascript: window.location.href = clickEvent; });
        }
        n++;
    });
}
// Das müssen wir für Delphi auch freigeben
window[InfiniteDataTableLinker.name] = InfiniteDataTableLinker;
