import { TWebComponent } from '../../base/class.web.comps';

export class TwcIcon extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcIcon';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Icon':
                this.obj.classList.value = value;
                break;
        }
    }
}