﻿import bootstrap from 'bootstrap';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { getParentByClassname } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProcessingMode, ILoadingSpinner, ISupportsProcessingMode } from '../../interfaces/processingMode';
import { ContextMenuEventNames } from '../ContextMenu/class.web.comp.contextmenu';
import { TwcInfiniteDataTable } from '../Tables/class.web.comp.infinitedatatable';
import { TwcTreeTable } from '../Tree/class.web.comp.treetable';

// LoadingSpinner INTF
export class TwcDropDownItem extends TWebComponent implements ILoadingSpinner, ISupportsProcessingMode {
    Caption: string;
    Content: string;
    IconStyleClass: string;
    processingModeDiscriminator: 'I-SUPPORTS-PROCESSINGMODE';
    ProcessingMode: ComponentProcessingMode;

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcDropDownItem';
        this.ProcessingMode = $(this.obj).data('processingmode') as unknown as ComponentProcessingMode ?? ComponentProcessingMode.TProcessingModeNone; // 0 ist Default;
        this.Caption = $('#' + this.id + '-caption').text();
        this.Content = $('#' + this.id).html();
        this.IconStyleClass = this.obj.dataset.iconstyleclass;
    }

    showLoadingSpinnerPromise(): Promise<string> {
        return new Promise(function (resolve, reject) {
            $('#' + this.id).contents().fadeOut(10);
            $('#' + this.id).html('<span id="' + this.id + '_spinner" style="display:none;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + this.Caption + '</span>');
            $('#' + this.id + '_spinner').fadeIn(100, function showNext() {
                resolve('Loading Spinner shown');
            });
        }.bind(this));
    }

    hideLoadingSpinner(): void {
        // zurücksetzen
        $('#' + this.id + '_spinner').hide();
        $('#' + this.id).html(this.Content);
        // hat sich mittlerweile die Caption geändert? 
        $('#' + this.id + '-Caption').text(this.Caption);
    }

    setProcessingMode(value: ComponentProcessingMode) {
        // Property anpassen
        this.ProcessingMode = value;
        // data-Attribut anpassen
        $(this.obj).data('processingmode', value);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                this.Caption = value;
                let captionElement = document.getElementById(`${this.id}-caption`);
                if (assigned(captionElement)) {
                    captionElement.innerHTML = value;
                }
                break;
            case 'Checked':
                if (value == '1') {
                    $('#' + this.id + '-icon').addClass(this.IconStyleClass);
                } else if (value == '0') {
                    $('#' + this.id + '-icon').removeClass(this.IconStyleClass);
                }
                break;
            case 'Enabled':
                if (value == '1') {
                    $(this.obj).removeClass('disabled');
                } else if (value == '0') {
                    $(this.obj).addClass('disabled');
                }
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
            case 'ProcessingMode':
                this.setProcessingMode(parseInt(value));
                break;
        }
    }
}

export class TwcDropDownSubMenu extends TwcDropDownItem {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcDropDownSubMenu';
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                this.obj.querySelector('.dropdown-item').classList.toggle('disabled', !boolFromStr(value));
                break;
            default:
                super.writeProperties(key, value);
        }
    }

}

export class TwcDropDownItemSeperator extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcDropDownItemSeperator';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
        }
    }
}

export class TwcDropDownList extends TWebComponent {
    closeOnScroll: boolean;
    isInContextMenu: boolean;
    scrollUp: HTMLElement;
    scrollDown: HTMLElement;
    itemlist: HTMLElement;
    parentid: string;
    parent: HTMLElement;
    parentButton: HTMLElement;

    hideAllSubmenuesFunction: () => void;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcDropDownList';

        this.closeOnScroll = boolFromStr(this.obj.dataset.closeonscroll);
        this.isInContextMenu = this.obj.classList.contains('dropdown-menu-contextmenu');

        this.scrollUp = document.getElementById(`${this.id}-scroll-up`);
        this.scrollDown = document.getElementById(`${this.id}-scroll-down`);
        this.itemlist = document.getElementById(`${this.id}-list`);
        // die parents initialisieren wir seperat
        this.parentid = this.obj.dataset.parentid;
        this.parent = null;
        this.parentButton = null;
        // um den eventlistener später zu removen müssen wir mit "named"-function arbeiten
        this.hideAllSubmenuesFunction = () => this.hideDropdownAllSubmenus();
    }

    override initDomElement() {
        super.initDomElement();
        // parent initialisieren
        this.initParentElement();
        // und die events
        this.initEventListener();
        this.initParentScrollListener();
        this.initEventListenerForSubmenu();
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
        }
    }

    initParentElement(): void {
        if (this.isInContextMenu) {
            this.parent = document.getElementById(`${this.parentid}`);
            /* Im Kontext Menü haben wir keinen Parent Button*/
            this.parentButton = null;
        } else {
            this.parent = document.getElementById(`${this.parentid}-div`);
            this.parentButton = document.getElementById(`${this.parentid}`);
        }
    }

    initEventListener(): void {
        if (this.isInContextMenu) {
            let parentComponent = findComponent(this.parent.id);
            if (assigned(parentComponent)) {
                this.parent.addEventListener(ContextMenuEventNames.AfterShow, event => {
                    this.calcMaxHeightAndWidthInContextMenu(event as CustomEvent);
                    this.toggleScrollButtons();
                });
                this.parent.addEventListener(ContextMenuEventNames.AfterHide, event => {
                    this.hideDropdownAllSubmenus();
                });

                window.addEventListener('resize', event => {
                    this.hideDropdown();
                    this.hideDropdownAllSubmenus();
                });
            }
        } else {
            if (assigned(this.parent)) {
                this.parent.addEventListener('shown.bs.dropdown', event => {
                    this.calcMaxHeightAndWidthOutsideContextMenu();
                    this.toggleScrollButtons();
                });
            }
        }
    }

    calcMaxHeightAndWidthInContextMenu(event: CustomEvent) {
        let availableSpaceHorizontal = 0;
        let availableSpaceVertical = 0;
        let distanceToBottom = 0;
        let distanceToRight = 0;
        let scrollTop = 0;
        // wir holen uns die wichtigsten daten vom gesamten Objekt
        let computedStyle = window.getComputedStyle(this.obj);
        let paddingTop = parseFloat(computedStyle.paddingTop);
        let paddingBottom = parseFloat(computedStyle.paddingBottom);

        let paddingRight = parseFloat(computedStyle.paddingRight);
        let paddingLeft = parseFloat(computedStyle.paddingLeft);

        let currentPositionTop = parseFloat(computedStyle.top);
        let currentPositionLeft = parseFloat(computedStyle.left);
        // nun wissen wir die höhe des Containers
        let containerHeight = this.itemlist.scrollHeight + paddingTop + paddingBottom;
        let contaierWidth = this.itemlist.scrollWidth + paddingRight + paddingLeft;

        // wir müssen einige Randdaten holen
        // erstmal checken wir mal ob wir im modalen Dialog sind
        let modalContainer = getModalDialogContainer(this.obj);
        if (assigned(modalContainer)) {
            let modalBody = modalContainer.querySelector('.modal-body');
            let parentRect = modalBody.getBoundingClientRect();
            let computedStyleModal = window.getComputedStyle(modalBody);
            // Wieviel Platz haben wir horizontal und vertikal
            availableSpaceHorizontal = parseFloat(computedStyleModal.height) - parseFloat(computedStyleModal.paddingTop) - parseFloat(computedStyleModal.paddingBottom);
            availableSpaceVertical = parseFloat(computedStyleModal.width) - parseFloat(computedStyleModal.paddingRight) - parseFloat(computedStyleModal.paddingLeft);
            // an welcher Positon sind wir gerade? 
            let clickPositionTop = event.detail.clientY - parentRect.y;
            let clickPositionLeft = event.detail.clientX - parentRect.x;
            // und nun haben wir die Distanz zum Boden
            distanceToBottom = availableSpaceHorizontal - clickPositionTop;
            distanceToRight = availableSpaceVertical - clickPositionLeft;
            //  wir müssen hier immer noch den z-index mitberechnen im modalen dialogen
            this.setZindex(parseInt((getParentByClassname(modalContainer, 'modal') as HTMLElement).style.zIndex) + 1);
            scrollTop = modalBody.scrollTop;
        } else {
            availableSpaceHorizontal = document.documentElement.clientHeight;
            availableSpaceVertical = document.documentElement.clientWidth;
            distanceToBottom = availableSpaceHorizontal - event.detail.clientY;
            distanceToRight = availableSpaceVertical - event.detail.clientX;
        }

        let displayHeight = Math.max(Math.min(containerHeight, distanceToBottom), Math.min(containerHeight, event.detail.clientY));
        let displayWidth = Math.max(Math.min(contaierWidth, distanceToRight), Math.min(contaierWidth, event.detail.clientX));
        let reduceMaxHeightValue = event.detail.clientY; // Zu Beginn gehen wir immer nach unten auf
        // wenn kein Platz nach unten, dann gehen wir nach oben auf
        if (displayHeight > distanceToBottom) {
            reduceMaxHeightValue = distanceToBottom;
            let newPositionTop = currentPositionTop - containerHeight - paddingBottom;
            if (newPositionTop < 0) {
                // wir brauchen zuerst den positiven Wert, um den wir die Größe reduzieren
                reduceMaxHeightValue = newPositionTop * -1;
                newPositionTop = 0;
            }
            // wenn wir gescrollt haben, dann muss das noch mitberechnet werden
            this.setTopPosition(newPositionTop + scrollTop);
        } else {
            // sonst noch den scroll Abstand dazu rechnen
            this.setTopPosition(currentPositionTop + scrollTop);
        }
        // wenn kein Platz nach rechts, dann gehen wir nach links
        if (displayWidth > distanceToRight) {
            let newPositionLeft = currentPositionLeft - contaierWidth - 15;
            if (newPositionLeft < 0) {
                newPositionLeft = 0;
            }
            this.setLeftPosition(newPositionLeft);
        }

        // erstmal wollen wir das nicht im modalen Dialog
        if (!assigned(modalContainer)) {
            this.setMaxheight(availableSpaceHorizontal - reduceMaxHeightValue);
        }
    }

    calcMaxHeightAndWidthOutsideContextMenu(): void {
        if (assigned(this.parentButton) && this.parentButton.dataset.display == 'static') {
            // im Statischen modus, berechnen wir die Höhe neu anhand der buttonposition
            // hier berechnen wir die Größe anhand des parents
            let computedStyleObj = window.getComputedStyle(this.obj);
            let minHeight = parseFloat(computedStyleObj.minHeight);

            // hier berechnen wir die Größe anhand des parents
            let parentRect = this.parent.getBoundingClientRect();
            let maxHeight = document.documentElement.clientHeight - parentRect.bottom;

            if (maxHeight > minHeight) {
                this.setMaxheight(maxHeight);
            }

            if (this.obj.classList.contains('dropdown-menu-right')) {
                let dropdownWidth = parseFloat(computedStyleObj.width);
                let isInSidebar = assigned(getParentByClassname(this.obj, 'page-sidebar'));
                let pageRect = null;
                if (isInSidebar) {
                    pageRect = document.querySelector('.page-sidebar').getBoundingClientRect();
                } else {
                    pageRect = document.querySelector('.page-inner').getBoundingClientRect();
                }
                let distanceToLeft = parentRect.left - pageRect.x;
                this.obj.classList.remove('dropdown-dynamic-left-position');
                if (dropdownWidth > distanceToLeft) {
                    this.obj.classList.add('dropdown-dynamic-left-position');
                }
            }
        }
    }

    setTopPosition(val: number): void {
        this.obj.style.setProperty('top', val + 'px');
    }

    setLeftPosition(val: number): void {
        this.obj.style.setProperty('left', val + 'px');
    }

    setMaxheight(val: number): void {
        this.obj.style.setProperty('max-height', val + 'px');
        // die 60px sind die scrollUp + scrollDown 
        this.itemlist.style.setProperty('max-height', (val - 60) + 'px');
    }

    setZindex(val: number): void {
        this.obj.style.setProperty('z-index', String(val));
    }

    toggleScrollButtons(): void {
        if ((this.itemlist.clientHeight < this.itemlist.scrollHeight)) {
            this.showScrollButtons();
        } else {
            this.hideScrollButtons();
        }
    }

    showScrollButtons(): void {
        // elemente haben ein overflow
        // jetzt zeigen wir den oberen Buttons an
        this.scrollUp.classList.remove('d-none');
        // wenn wir ganz oben sind, disablen wir den Button
        if (Math.floor(this.itemlist.scrollTop) == 0) {
            this.scrollUp.classList.add('disabled');
        }
        // jetzt zeigen wir den unteren Button an
        this.scrollDown.classList.remove('d-none');
        // wenn wir ganz unten sind, disablen wir den unteren Button
        let position = Math.ceil(this.itemlist.scrollTop + this.itemlist.offsetHeight);
        if (position >= this.itemlist.scrollHeight) {
            this.scrollDown.classList.add('disabled');
        }
        // wir geben unserem Dropdown noch eine Klasse mit
        this.obj.classList.add('dropdown-menu-with-arrows');
        this.initButtonClickListener();
        this.initButtonScrollListener();
    }

    hideScrollButtons(): void {
        this.obj.classList.remove('dropdown-menu-with-arrows');
        // elemente haben kein overflow
        this.scrollUp.classList.add('d-none');
        this.scrollUp.classList.remove('disabled');
        this.scrollDown.classList.add('d-none');
        this.scrollDown.classList.remove('disabled');
    }

    initButtonScrollListener(): void {
        // wir wollen die Buttons ausblenden, wenn wir oben bzw. unten angekommen sind
        this.itemlist.addEventListener('scroll', e => {
            // unterer Button weg
            // wir runden sicherheitshalber mal ab
            if (Math.floor(this.itemlist.scrollTop) == 0) {
                this.scrollUp.classList.add('disabled');
            } else {
                if (this.scrollUp.classList.contains('disabled')) {
                    this.scrollUp.classList.remove('disabled');
                }
            }
            // unterer Button weg 
            // hier aufrunden, weil in gewissen Browser-Höhen z.b. 55.67979 gegen 56 gecheckt wird
            var positon = Math.ceil(this.itemlist.scrollTop + this.itemlist.offsetHeight);
            if (positon >= this.itemlist.scrollHeight) {
                this.scrollDown.classList.add('disabled');
            } else {
                if (this.scrollDown.classList.contains('disabled')) {
                    this.scrollDown.classList.remove('disabled');
                }
            }
        });
    }

    initButtonClickListener(): void {

        this.scrollUp.addEventListener('click', e => {
            // wir wollen nicht das das dropdown zu geht beim klick auf die Buttons
            e.stopPropagation();
        });
        this.scrollDown.addEventListener('click', e => {
            // wir wollen nicht das das dropdown zu geht beim klick auf die Buttons
            e.stopPropagation();
        });
        const scrollSpeed = 5;

        // smooth scroll down
        let scrolldowntimer: number;
        this.scrollUp.addEventListener('mousedown', e => {
            e.stopPropagation();
            let position = this.itemlist.scrollTop;
            scrolldowntimer = window.setInterval(function () {
                position = position - scrollSpeed;
                this.itemlist.scrollTop = position;
            }.bind(this), 10);
        });
        this.scrollUp.addEventListener('mouseup', e => {
            e.stopPropagation();
            window.clearInterval(scrolldowntimer);
        });

        // smooth scroll up
        let scrolluptimer: number;
        this.scrollDown.addEventListener('mousedown', e => {
            e.stopPropagation();
            let position = this.itemlist.scrollTop;
            scrolluptimer = window.setInterval(function () {
                position = position + scrollSpeed;
                this.itemlist.scrollTop = position;
            }.bind(this), 10);
        });
        this.scrollDown.addEventListener('mouseup', e => {
            e.stopPropagation();
            window.clearInterval(scrolluptimer);
        });

    }

    initParentScrollListener(): void {
        // wenn die Property gesetzt ist, schalten wir die listener ein
        if (this.closeOnScroll && assigned(this.parent)) {
            // das scrollverhalten in der sidebar-section
            if ($(this.parent).parents('.sidebar-section').length > 0) {
                $(this.parent).parents('.sidebar-section')[0].addEventListener('scroll', e => {
                    this.hideDropdown();
                });
            }
            // das scrollverhalten in der Tabelle
            if ($(this.parent).parents('table').parents('.dataTables_scrollBody').length > 0) {
                $(this.parent).parents('table').parents('.dataTables_scrollBody')[0].addEventListener('scroll', e => {
                    this.hideDropdown();
                });
            }

            if (this.isInContextMenu) {
                // über das Kontextmenü holen wir uns die TreeTable
                let triggerId = this.parent.dataset.triggerid;
                let triggerComponent = findComponent(triggerId);
                if (triggerComponent instanceof TwcTreeTable) {
                    let treeContainer = document.getElementById(`${triggerId}-treecontainer`);
                    let scrollContainer = treeContainer;
                    scrollContainer?.addEventListener('scroll', e => {
                        this.hideDropdown();
                    });
                    // sind wir im modalen dialog, ist der ModaleDialog zusätzlich unser container
                    let modalContainer = getModalDialogContainer(treeContainer);
                    if (assigned(modalContainer)) {
                        scrollContainer = modalContainer.querySelector('.modal-body');
                        scrollContainer?.addEventListener('scroll', e => {
                            this.hideDropdown();
                        });
                    }
                } else if (triggerComponent instanceof TwcInfiniteDataTable) {
                    let scrollContainer = document.getElementById(triggerComponent.id).querySelector('.dataTables_scrollBody');
                    scrollContainer?.addEventListener('scroll', e => {
                        this.hideDropdown();
                    });
                }
            }

            // falls das komplette Fenster gescrollt wird
            document.addEventListener('scroll', e => {
                if (this.obj.classList.contains('show')) {
                    this.hideDropdown();
                }
            });
        }
    }

    hideDropdown(): void {
        if (assigned(this.parentButton)) {
            this.parentButton.blur();
            bootstrap.Dropdown.getInstance(this.parentButton).hide();
        }
        this.obj.classList.remove('show');
    }

    hideDropdownAllSubmenus(): void {
        let allOpenSubmenues = this.itemlist.querySelectorAll('.dropdown-submenu .show');
        for (let i = 0; i < allOpenSubmenues.length; i++) {
            allOpenSubmenues[i].classList.remove('show');
        }
    }

    initEventListenerForSubmenu(): void {
        // erstmal holen wir uns alle submenus und die "nicht-submenus"
        let togglerList = this.obj.querySelectorAll(`#${this.itemlist.id} > li > .dropdown-item`);

        for (let i = 0; i < togglerList.length; i++) {
            let togglerItem = togglerList[i];
            togglerItem.addEventListener('mouseover', (event: MouseEvent) => {
                if (!togglerItem.classList.contains('dropdown-toggle')) {
                    this.hideDropdownAllSubmenus();
                } else {
                    // jedes submenu hat einen submenuWrapper, den brauchen wir zuerst 
                    if (togglerItem.nextElementSibling.classList.contains('dropdown-submenu-wrapper')) {
                        // zuerst schließen wir alle anderen offenen submenus auf parent-ebene
                        let parentSubmenu = getParentByClassname(togglerItem, 'dropdown-menu');
                        if (assigned(parentSubmenu)) {
                            let otherOpenSubmenus = parentSubmenu.querySelectorAll('.show');
                            for (let i = 0; i < otherOpenSubmenus.length; i++) {
                                otherOpenSubmenus[i].classList.remove('show');
                            }
                        }
                        let submenuWrapper = togglerItem.nextElementSibling as HTMLElement;
                        // jetzt zeigen wir unser menü an
                        submenuWrapper.querySelector('.dropdown-menu')?.classList.add('show');
                        // nun brauchen wir die aktuelle position
                        let menuItemPos = togglerItem.parentElement.offsetTop;
                        // falls wir noch im menü scrollen können, gleichen wir das hier aus
                        menuItemPos -= togglerItem.parentElement?.parentElement?.scrollTop ?? 0;
                        let computedStyleToggler = window.getComputedStyle(togglerItem);
                        let togglerHeight = parseFloat(computedStyleToggler.height) - parseFloat(computedStyleToggler.paddingTop) - parseFloat(computedStyleToggler.paddingBottom);
                        submenuWrapper.style.setProperty('top', (menuItemPos - togglerHeight + 5) + 'px'); // wir haben noch ein wenig padding
                    }
                }
            });
            // bei Klick auf den Öffner passiert ertmal nichts
            if (togglerItem.classList.contains('dropdown-toggle')) {
                togglerItem.addEventListener('click', (event: MouseEvent) => {
                    event.stopPropagation();
                });
            }
        }
        // Wenn sich unser parent schließt, schließen wir alle die drunter sind
        if (assigned(this.parent)) {
            this.parent.addEventListener('hidden.bs.dropdown', this.hideAllSubmenuesFunction, false);
        }
        // bei scrollen schließen wir uns auch
        this.itemlist.removeEventListener('scroll', this.hideAllSubmenuesFunction, false);
        this.itemlist.addEventListener('scroll', this.hideAllSubmenuesFunction, false);
    }
}