import Color from 'color';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeKeyIndicatorNew extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 951;
        this.hasAnchors = false;
        this.gradient = 0;
        this.fontColor = Color('#fff');
        this.inlineEdit = false;
    }

    getSvgElementType(): string {
        return 'rect';
    }
    
    override setBackgroundColor(backgroundColor: Color, gradient: number) {
        super.setBackgroundColor(backgroundColor, gradient);

        this.svgElement.setAttribute('fill', this.color.hex());

        // set border
        this.strokeColor = this.color.darken(0.2);
        this.svgElement.setAttribute('stroke', this.strokeColor.hex());
    }
}