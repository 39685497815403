import { ISignal } from "strongly-typed-events";
import { assigned } from "../../utils/helper";

export interface ISupportsActive {
    supportsActiveDiscriminator: 'I-Supports-Active';

    isActive():boolean
    onShow(): ISignal;
}

export function supportsActive(object: any): boolean {
    return assigned(object.supportsActiveDiscriminator) && object.supportsActiveDiscriminator === 'I-Supports-Active';
}