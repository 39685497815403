import { assigned } from "../../utils/helper";

export type DateTimeFormatterHandler = (parts: Intl.DateTimeFormatPart[]) => string;

export let defaultFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric', // 2021
    weekday: 'long',  // Montag
    timeZoneName: 'short', // GMT +1
    second: '2-digit', // 01..60
    month: '2-digit', // 01..12
    minute: '2-digit', // 01..60
    localeMatcher: 'best fit', // kein plan, wird aber empfohlen 😂
    hour12: false, // 12h vs 24h
    hour: 'numeric', // 1..24
    formatMatcher: 'best fit', // wie localMatcher
    era: 'short',
    day: '2-digit', // 01..31
};

export let defaultDateTimeFormatter: DateTimeFormatterHandler = (parts: Intl.DateTimeFormatPart[]) => {
    return `${defaultDateFormatter(parts)} - ${defaultTimeFormatter(parts)}`;
}

export let defaultTimeFormatter: DateTimeFormatterHandler = (parts: Intl.DateTimeFormatPart[]) => {
    return `${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;
}

export let defaultDateFormatter: DateTimeFormatterHandler = (parts: Intl.DateTimeFormatPart[]) => {
    return `${parts.find(p => p.type === 'day').value}.${parts.find(p => p.type === 'month').value}.${parts.find(p => p.type === 'year').value}`;
}

export function formatDateTime(value: number | Date, options?: Intl.DateTimeFormatOptions, formatter?: DateTimeFormatterHandler): string {

    options = options ?? defaultFormatOptions;
    formatter = formatter ?? defaultDateTimeFormatter;

    if (value instanceof Date) {
        let dateFormat = new Intl.DateTimeFormat(preferredUserLocale(), options);
        return formatter(dateFormat.formatToParts(value));
    }
    else {
        return formatDateTime(new Date(value), options);
    }
}

export function preferredUserLocale(): string {
    if (assigned(navigator.languages)) {
        return navigator.languages[0]; // Array is sortiert nach Browsereinstellungen, was der User am besten mag. Vorne der höchste Wert
    }

    return navigator.language;
}