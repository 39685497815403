﻿import { getCurrentGlobalMousePosition } from '../../../app/consense.keyboard';
import { WebCompEventHandlerAsync } from '../../../core/communication';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { getParentByClassname, getParents } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { TwcDropDownList } from '../Dropdown/class.web.comp.dropdown';

export class TwcPopContainerList extends TWebComponent {

    captionElement: HTMLElement;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcPopContainerList';

        this.captionElement = this.obj.querySelector(`.popcontainer-caption`) as HTMLElement;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Caption':
                this.captionElement.innerHTML = value;
                this.captionElement.classList.toggle('d-none', value.length == 0);
                break;
        }
    }
}

export class TwcPopContainer extends TWebComponent {

    triggerId: string;
    poplist: HTMLElement;
    modalContainer: Element;

    active: boolean;
    openAtMousePosition: boolean;
    hasOnHide: boolean;

    documentClickFunction: (e: MouseEvent) => void;
    hideContainerFunction: () => void;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcPopContainer';

        this.poplist = document.querySelector(`[data-parentid="${this.id}"]`) as HTMLElement;
        this.modalContainer = getModalDialogContainer(this.obj);
        this.triggerId = this.obj.dataset.triggerid;

        this.active = false;
        this.openAtMousePosition = boolFromStr(this.obj.dataset.mouseposition, false);
        this.hasOnHide = boolFromStr(this.obj.dataset.hasonhide);
        // um den eventlistener später zu removen müssen wir mit "named"-function arbeiten
        this.documentClickFunction = e => this.handleDocumentClick(e);
        this.hideContainerFunction = () => this.hideContainer();

    }

    override initDomElement() {
        super.initDomElement();
        // schließen beim resize des Fensters
        window.addEventListener('resize', this.hideContainerFunction);

        // wir verschieben unsere Position im DOM, wenn wir im Modalen Dialog drin sind, wegen z-index 
        if (this.isInsideModal()) {
            // und nun setzten wir uns vor den Dialog im DOM
            this.modalContainer.parentElement.insertBefore(this.obj, this.modalContainer);
        }
    }

    isInsideModal(): boolean {
        return assigned(this.modalContainer);
    }

    updateTriggerComponent(): void {
        let triggerComp = document.getElementById(this.triggerId);
        // erste Anbindung nur für Buttons: Wie ändern noch einen Style per css, zb. :focus
        if (triggerComp?.classList?.contains('btn')) {
            triggerComp.classList.toggle('btn-popcontainer-trigger', this.active);
        }
    }

    handleDocumentClick(event: MouseEvent): void {
        let target = event.target as Element;
        // wenn wir aussenhalb geklick haben, können wir schließen
        let isClickInside = this.obj?.contains(target) ?? false;
        // wenn wir innerhalb geklickt haben, schauen wir, wo wir geklickt haben
        // wenn wir auf ein Item im Menü geklickt haben dann müssen wir nur schließen, wenn es ein Link ist
        let isHref = isClickInside && ((target?.tagName?.toLowerCase() === 'a') || assigned(getParents(target).find(item => item.tagName?.toLowerCase() === 'a')));
        // der Triggerer kümmert sich momentan noch selber um das auf und zuklappen
        let isClickOnTrigger = !isClickInside && (target?.classList?.contains('popmenu-position') || assigned(getParents(target).find(item => item.classList?.contains('popmenu-position'))));
        // beim flatpickr-calendar wollen wir das auch nicht
        let isDatepicker = !isClickInside && (target?.classList?.contains('flatpickr-calendar') || assigned(getParents(target).find(item => item.classList?.contains('flatpickr-calendar'))));

        let isOrgrel = false;
        // wenn wir einen OrgrelEditor haben, wird es etwas komplizierter, deshalb checken wir erstmal, ob wir überhaupt einen haben
        if (assigned(this.obj.querySelector("[data-type='TwsOrgRelEditor']"))) {
            // orgrel Editor -> wir bleiben offen
            let isOrgrelEditor = !isClickInside && (target?.classList?.contains('OrgRelEditor') || assigned(getParents(target).find(item => item.classList?.contains('OrgRelEditor'))));
            // orgrel Tree  -> wir bleiben offen
            let isOrgrelTreeSelection = !isClickInside && (target?.classList?.contains('cs-organisation-tree-modal') || assigned(getParents(target).find(item => item.classList?.contains('cs-organisation-tree-modal'))));
            isOrgrel = isOrgrelEditor || isOrgrelTreeSelection;
        }

        // visitenkarte -> wir bleiben offen
        if (isHref && target?.getAttribute('data-bs-toggle')) {
            isHref = false;
        }

        // rechte maustaste lassen wir auch zu 
        if ((isHref || !isClickInside || event.button == 2) && !isClickOnTrigger && !isDatepicker && !isOrgrel) {
            if (this.active) {
                this.hideContainer();
            }

            // eventlistener für close wieder löschen, sonst wird dieser jedes mal getrigger, auch wenn nicht komponente nicht sichtbar
            document.removeEventListener('mouseup', this.documentClickFunction, false);
        }
    }

    isPopListDropDown(): boolean {
        return this.poplist?.classList?.contains('dropdown-menu');
    }

    hideContainer(): void {
        if (this.active) {
            this.obj.classList.remove('show');
            this.active = false;
            // noch den trigger verlassen
            document.getElementById(this.triggerId)?.blur();

            this.hideSubmenus();

            if (this.hasOnHide) {
                WebCompEventHandlerAsync('OnHide', this.id);
            }
        }
    }

    hideSubmenus(): void {
        // eine Dropdownliste hat ggf Submenues
        if (this.isPopListDropDown()) {
            let dropDownComp = findComponent(this.poplist.id) as TwcDropDownList;
            if (assigned(dropDownComp)) {
                dropDownComp.hideDropdownAllSubmenus();
            }
        }
    }

    refreshListItems(): void {
        if (assigned(this.poplist)) {
            let items = this.poplist.querySelectorAll('.popcontainer-item');
            for (let i = 0; i < items.length; i++) {
                let visibleItems = Array.from(items[i].children).filter(item => !item.classList?.contains('d-none'));
                items[i].classList.toggle('d-none', visibleItems.length === 0);
            }
        }
    }

    initParentScrollListener(): void {

        if (this.isPopListDropDown()) {
            let triggerElement = document.getElementById(this.triggerId);
            let scrollContainer = null;
            // entweder sind wir im tree
            let treeContainer = getParentByClassname(triggerElement, 'cs-treecontainer');
            if (assigned(treeContainer)) {
                scrollContainer = treeContainer;
                let modalContainer = getModalDialogContainer(treeContainer);
                if (assigned(modalContainer)) {
                    modalContainer.querySelector('.modal-body')?.addEventListener('scroll', this.hideContainerFunction, false);
                }
            }

            if (!assigned(scrollContainer)) {
                // ok wir sind kein treecontainer. Dann vielleicht eine Tablle
                let tableContainer = getParentByClassname(triggerElement, 'dataTables_scrollBody');
                if (assigned(tableContainer)) {
                    scrollContainer = tableContainer;
                }
            }

            if (!assigned(scrollContainer)) {
                let sidebarsection = getParentByClassname(triggerElement, 'sidebar-section');
                if (assigned(sidebarsection)) {
                    scrollContainer = sidebarsection;
                }
            }
            // wir können das scroll event anlegen
            if (assigned(scrollContainer)) {
                scrollContainer.removeEventListener('scroll', this.hideContainerFunction, false);
                scrollContainer.addEventListener('scroll', this.hideContainerFunction, false);
            }
        }
    }

    openContainer(): void {
        if (this.active) {
            this.hideContainer();
        }
        this.refreshListItems();
        this.initParentScrollListener();
        this.showContainer();
        this.optimizePosition();
        this.optimizeHeight();
        // die Triggerkomponente kriegt auch noch was ab
        this.updateTriggerComponent();
        // eventlistener für close setzen
        document.addEventListener('mouseup', this.documentClickFunction, false);
    }

    updateArrowPosition(pos: string, isArrowRightPos = false) {
        // entweder
        // top-start, top-end, bottom-start, bottom-end
        let postfix = '-start';
        // wenn wir nach links aufgehen, dann zeigen wir den arrow auch rechts und umgekehrt
        // isArrowRightPos hat eine höhere Prio in der Abfrage (z.B. wenn rechts kein Platz, soll links)
        if (isArrowRightPos || (!isArrowRightPos && this.obj.classList.contains('popcontainer-pos-right'))) {
            postfix = '-end';
        }
        this.obj.setAttribute('x-placement', pos + postfix);
    }

    hasArrow() {
        return !this.obj.querySelector('.dropdown-arrow')?.classList.contains('d-none');
    }

    getPositionShiftWhenArrow(triggerElementWidth: number, rightPositon: boolean) {
        const arrowWidth = 10.5;
        const arrowShiftToleranz = 2;

        let positionShiftWhenArrow = 0;
        if (this.hasArrow() && triggerElementWidth < 40) {
            let computedStyleArrow = window.getComputedStyle(this.obj.querySelector('.dropdown-arrow'));
            positionShiftWhenArrow = (triggerElementWidth / 2);
            if (rightPositon) {
                positionShiftWhenArrow -= (arrowWidth / 2) + 8;
                positionShiftWhenArrow += arrowShiftToleranz;
            } else {
                positionShiftWhenArrow -= (arrowWidth / 2) + parseFloat(computedStyleArrow.left);
                positionShiftWhenArrow -= arrowShiftToleranz;
            }
        }
        return positionShiftWhenArrow;
    }

    optimizeHeight(): void {
        // erstmal wenn wir nur genau 1 mal popcontainer-mainlist-item haben
        let mainlistItems = this.obj.querySelectorAll('.popcontainer-mainlist-item');
        if (this.obj.classList?.contains('popcontainer-dynamic-height') && mainlistItems.length == 1) {
            // wir resetten erstmal und schauen uns erst dann die Größe an!
            (mainlistItems[0] as HTMLElement).style.maxHeight = 'none';
            let triggerElement = document.getElementById(this.triggerId);
            let containerHeight = parseFloat(window.getComputedStyle(this.obj).height);
            let availableSpaceVertical = document.documentElement.clientHeight;
            let triggerElementRect = triggerElement.getBoundingClientRect();
            if (assigned(triggerElementRect)) {
                // an welcher Positon sind wir gerade? 
                let positionTop = triggerElementRect.y + triggerElementRect.height;
                let distanceToBottom = availableSpaceVertical - positionTop;
                let displayHeight = Math.max(Math.min(containerHeight, distanceToBottom), Math.min(containerHeight, positionTop));
                if (Math.floor(displayHeight) >= Math.floor(distanceToBottom)) {
                    (mainlistItems[0] as HTMLElement).style.maxHeight = (distanceToBottom - 25) + 'px'; // wir nehmen noch etwas weg
                } else {
                    (mainlistItems[0] as HTMLElement).style.maxHeight = 'none';
                }
            }
        }
    }

    optimizePosition(): void {
        // falls das Fenster zu klein ist, ist der Popcontainer am rechten Rand und wir haben eine verfälschte containerWidth
        // somit holen wir uns zuerst die echte containerWidth
        this.obj.classList.add('position-static');
        let popContainerWidth = parseFloat(window.getComputedStyle(this.obj).width);
        this.obj.classList.remove('position-static');

        let triggerElement = document.getElementById(this.triggerId);
        // jetzt brauchen wir die Höhe & Weite des PopContainers
        let containerHeight = parseFloat(window.getComputedStyle(this.obj).height);
        let containerWidth = parseFloat(window.getComputedStyle(this.obj).width);
        // und wieviel Platz wir im Fenster haben
        let availableSpaceVertical = document.documentElement.clientHeight;
        let availableSpaceHorizontal = document.documentElement.clientWidth;

        const space_to_right = 25;

        // erstmal nur alle Buttons
        if (triggerElement?.classList?.contains('btn')) {
            let triggerElementRect = triggerElement.getBoundingClientRect();
            if (assigned(triggerElementRect)) {
                // an welcher Positon sind wir gerade? 
                let positionTop = triggerElementRect.y + triggerElementRect.height;
                let positionLeft = triggerElementRect.x;
                // und nun haben wir die Distanz zum Boden und nach rechts
                let distanceToBottom = availableSpaceVertical - positionTop;
                let distanceToRight = availableSpaceHorizontal - positionLeft;
                // wir nehmen noch etwas Spielraum weg, es soll ja nicht direkt am rang hängen
                distanceToRight -= space_to_right;
                // nun rechnen wir 
                let displayHeight = Math.max(Math.min(containerHeight, distanceToBottom), Math.min(containerHeight, positionTop));
                // den Pfeil erstmal oben positionieren, also wir gehen noch nach unten auf
                this.updateArrowPosition('bottom', containerWidth > distanceToRight);
                // und wenn kein platz ist, gehen wir nach oben auf
                if (Math.floor(displayHeight) >= Math.floor(distanceToBottom)) {
                    if (this.isInsideModal()) {
                        // nach oben aufgehen und die Postion neu berechnen. Mit etwas Spielraum (10px)
                        positionTop -= (triggerElementRect.height + containerHeight + 10);

                        // sonderlocke mit toolbar 
                        if (triggerElement.classList?.contains('cs-tablebutton')) {
                            let modalBody = this.modalContainer.querySelector('.modal-body');
                            let toolbar = modalBody?.querySelector('.cs-buttontoolbar-fixed .btn-toolbar');
                            if (assigned(toolbar)) {
                                let toolbarStyle = window.getComputedStyle(toolbar);
                                this.setZindex(parseInt(toolbarStyle.zIndex) + 1);
                            }
                        }
                    } else {
                        // wir müssen aber noch die top-bar/header Höhe Abziehen
                        let topBannerHeight = this.getBannerHeight();
                        positionTop -= (containerHeight - window.scrollY + topBannerHeight + triggerElementRect.height + 10);
                    }
                    // Pfeil drehen 
                    this.updateArrowPosition('top', containerWidth > distanceToRight);
                    // Position setzen
                    this.setTopPosition(positionTop);
                }

                // falls rechts genug Platz und Klasse gesetzt
                let arrowHasRightPos = !(containerWidth > distanceToRight) && this.obj.classList.contains('popcontainer-pos-right');

                // den Container links positionieren, falls rechts kein Platz ist oder falls der Pfeil rechts sein soll
                if ((Math.floor(containerWidth) >= Math.floor(distanceToRight)) || arrowHasRightPos) {
                    // nach links aufgehen und die Postion neu berechnen. 
                    let pageRectx = 0;
                    if (!this.isInsideModal()) {
                        let pageRect = this.getPageRect();
                        if (assigned(pageRect)) {
                            pageRectx = pageRect.x;
                        }
                    }
                    // jetzt ziehen wir nosch einiges ab
                    positionLeft -= (popContainerWidth - triggerElementRect.width);
                    positionLeft -= pageRectx;
                    positionLeft -= this.getPositionShiftWhenArrow(triggerElementRect.width, true);
                    this.setLeftPosition(positionLeft);
                } else {
                    let arrowShift = this.getPositionShiftWhenArrow(triggerElementRect.width, false);
                    if (arrowShift != 0) {
                        // wir haben eigentlich schon unsere optimale position, aber wir können noch nachjsutieren
                        positionLeft = parseFloat(this.obj.style.left) + arrowShift;
                        this.setLeftPosition(positionLeft);
                    }
                }
            }
        } else if (this.openAtMousePosition) {
            let positionLeft = getCurrentGlobalMousePosition().x;
            let positionTop = getCurrentGlobalMousePosition().y;

            let distanceToBottom = availableSpaceVertical - positionTop;
            let distanceToRight = availableSpaceHorizontal - positionLeft;

            // erst oben oder unten
            if (containerHeight > distanceToBottom) {
                // nach oben aufgehen und die Postion neu berechnen. 
                // wir müssen aber noch die top-bar Höhe Abziehen
                let topBannerHeight = this.getBannerHeight();
                positionTop -= (containerHeight - window.scrollY + topBannerHeight);
                // Position setzen
                this.setTopPosition(positionTop);
            }
            // jetzt rechts oder links
            // wir nehmen noch etwas Spielraum weg, es soll ja nicht direkt am rang hängen
            distanceToRight -= space_to_right;
            if (Math.floor(containerWidth) >= Math.floor(distanceToRight)) {

                // nach links aufgehen und die Postion neu berechnen. 
                let isInPageInner = assigned(getParentByClassname(this.obj, 'page-inner'));
                let containerX = 0;
                if (isInPageInner) {
                    containerX = document.querySelector('.page-inner').getBoundingClientRect().x;
                }
                positionLeft -= (containerWidth - window.scrollX + containerX);
                // Position setzen
                this.setLeftPosition(positionLeft);
            }
        } else {
            // z.B. wenn die Trigger Komponente kein button ist
            // console.log('hier ist was noch nicht angebunden');

            // wenn wir ein twc link haben, justieren wir auch noch etwas nach
            if (triggerElement?.dataset?.type == 'TwcLink') {
                let triggerElementRect = triggerElement.getBoundingClientRect();
                if (assigned(triggerElementRect)) {
                    let positionLeft = triggerElementRect.x;
                    let arrowShift = this.getPositionShiftWhenArrow(triggerElementRect.width, false);
                    if (arrowShift != 0) {
                        // wir haben eigentlich schon unsere optimale position, aber wir können noch nachjsutieren
                        positionLeft = parseFloat(this.obj.style.left) + arrowShift;
                        this.setLeftPosition(positionLeft);
                    }
                }
            }
        }
    }

    getBannerHeight(): number {
        let topBannerHeight = 0;

        if (assigned(document.querySelector('.top-bar'))) {
            topBannerHeight = document.querySelector('.top-bar').getBoundingClientRect().height;
        } else if (assigned(document.querySelector('.page-viewer-header'))) {
            topBannerHeight = document.querySelector('.page-viewer-header').getBoundingClientRect().height;
        }

        return topBannerHeight;
    }

    setZindex(val: number): void {
        this.obj.style.setProperty('z-index', String(val));
    }

    getPageRect(): DOMRect {
        let parentRect = null;
        // wir haben bei lookuppages andere Bezugselemente. Erstmal schauen, wo wir uns befinden
        let isInSidebar = assigned(getParentByClassname(this.obj, 'page-sidebar'));
        if (isInSidebar) {
            parentRect = document.querySelector('.page-sidebar').getBoundingClientRect();
        } else {
            let isInPageInner = assigned(getParentByClassname(this.obj, 'page-inner'));
            if (isInPageInner) {
                parentRect = document.querySelector('.page-inner').getBoundingClientRect();
            } else {
                parentRect = document.body.getBoundingClientRect();
            }
        }
        return parentRect;
    }

    showContainer(): void {
        let parentRect = null;
        let left = 0, top = 0;
        let triggerElement = document.getElementById(this.triggerId);
        let parentTriggerElementRect = triggerElement?.getBoundingClientRect();

        if (assigned(parentTriggerElementRect)) {
            if (this.isInsideModal()) {
                //  let modalBody = this.modalContainer.querySelector('.modal-body');
                //   parentRect = modalBody.getBoundingClientRect();
                left = parentTriggerElementRect.x;
                top = parentTriggerElementRect.y + parentTriggerElementRect.height + 5;
                //  wir müssen hier immer noch den z-index mitberechnen im modalen dialogen
                this.setZindex(parseInt((getParentByClassname(this.modalContainer, 'modal') as HTMLElement).style.zIndex) + 1);
            } else if (!this.isInsideModal() && assigned(getModalDialogContainer(document.getElementById(this.triggerId)))) {
                // wenn wir nicht im modalen Dialog hängen aber unser Trigger botton, 
                parentRect = document.querySelector('.page-inner').getBoundingClientRect();
                if (!assigned(parentRect)) {
                    // sonst fallback
                    parentRect = this.getPageRect();
                }
                left = parentTriggerElementRect.x - parentRect.x;
                top = parentTriggerElementRect.y - parentRect.y + parentTriggerElementRect.height + 5;
                // dann passen wir hier nochmal den Z-Index an
                this.setZindex(parseInt((getParentByClassname(document.getElementById(this.triggerId), 'modal') as HTMLElement).style.zIndex) + 1);
            } else {
                parentRect = this.getPageRect();
                if (assigned(parentRect)) {
                    if (this.openAtMousePosition) {
                        left = getCurrentGlobalMousePosition().x - parentRect.x + 5;
                        top = getCurrentGlobalMousePosition().y - parentRect.y - 5;
                    } else {
                        left = parentTriggerElementRect.x - parentRect.x;
                        top = parentTriggerElementRect.y - parentRect.y + parentTriggerElementRect.height + 5;
                    }
                }
            }

            this.obj.style.left = `${left}px`;
            this.obj.style.top = `${top}px`;
            this.obj.classList.add('show');
            this.active = true;
        }
    }

    setTopPosition(val: number): void {
        this.obj.style.setProperty('top', val + 'px');
    }
    setLeftPosition(val: number): void {
        this.obj.style.setProperty('left', val + 'px');
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'TriggerComponentId':
                this.triggerId = value;
                break;
            case 'OpenAtMousePosition':
                this.openAtMousePosition = boolFromStr(value);
                break;
            case 'ShowArrow':
                let items = this.obj.querySelectorAll('.dropdown-arrow');
                for (let i = 0; i < items.length; i++) {
                    items[i].classList.toggle('d-none', !boolFromStr(value));
                }
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.Show':
                this.openContainer();
                break;
            case 'Action.Hide':
                this.hideContainer();
                break;
            case 'Action.Toggle':
                if (this.active) {
                    this.hideContainer();
                } else {
                    this.openContainer();
                }
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}