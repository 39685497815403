import { TWebComponent } from '../../base/class.web.comps';

export class TwcAlert extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcAlert';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                document.getElementById(`${this.id}-alertCaption`).innerHTML = value;
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
            case 'Style':
                $(this.obj).removeClass(function (index, className) {
                    // wir löschen alles mit "alert-...."
                    return (className.match(/\balert-\S+/g) || []).join(' ');
                });
                $(this.obj).addClass('alert-' + value);
                /// und die iconfarbe für kleine icons
                $('#' + this.id + '-alertIcon').removeClass(function (index, className) {
                    return (className.match(/\balert-icon-\S+/g) || []).join(' ');
                });
                $('#' + this.id + '-alertIcon').addClass('alert-icon-' + value);
                break;
            case 'Icon':
                $('#' + this.id + '-alertIcon').find('i').removeClass(function (index, className) {
                    return (className.match(/\bfa-\S+/g) || []).join(' ');
                });
                $('#' + this.id + '-alertIcon').find('i').addClass('fa-solid ' +  value);
                break;
        }
    }
}