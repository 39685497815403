import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TEdgeBase } from '../Edges/TEdgeBase';
import { ActionType, TCustomEdgeAction as TCustomEdgeAction } from './TCustomAction';

export class TEdgeItemDataAction extends TCustomEdgeAction {
    oldItemData: string;
    newItemData: string;

    constructor(refElement: TEdgeBase, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patEdgeItemData;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldItemData = obj.oldItemData;
        this.newItemData = obj.newItemData;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldItemData = this.newItemData;
        obj.newItemData = this.oldItemData;

        return obj;
    }

    setValue(newEdgeStyle: string): void {
        this.newItemData = newEdgeStyle;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.itemData = this.newItemData;
        this.refElement.invalidate();
    }

    inverseAction(): TCustomEdgeAction {
        let result = new TEdgeItemDataAction(this.refElement, this.svgEditor);
        result.setValue(this.newItemData);

        return result;
    }
}