import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeProcessLink extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 600;

        this.canInPlaceEdit = false;
        this.inlineEdit = false;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let wc = this.boundingRect.width / 14;
        if (wc > 10) {
            wc = 10;
        }

        let path = [
            'M', this.boundingRect.left, this.boundingRect.top,
            'h', wc,
            'v', this.boundingRect.height,
            'h', -wc,
            'Z',
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'h', this.boundingRect.width - 2 * wc,
            'v', this.boundingRect.height,
            'h', -(this.boundingRect.width - 2 * wc),
            'Z',
            'M', this.boundingRect.left + this.boundingRect.width - wc, this.boundingRect.top,
            'h', wc,
            'v', this.boundingRect.height,
            'h', -wc,
            'Z'
        ].join(' ');

        return path;
    }
}

