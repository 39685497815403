import dragula, { Drake } from 'dragula';
import { sendComponentRequestGetJson, WebCompEventHandlerAsync } from '../../../core/communication';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

type RequestResult = {
    success: boolean,
    errorMsg: string
}

// typescript imported from https://www.npmjs.com/package/@types/dragula
// documentation check https://github.com/bevacqua/dragula

export class TwcDragAndDrop extends TWebComponent {
    thisDrake: Drake;
    containerIDs: string[];
    containerAdditionalClass: string;
    enabled: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcDragAndDrop';
        this.containerIDs = this.obj.dataset.containerids.split('|');
        this.containerAdditionalClass = this.obj.dataset.addcontainerclass;
        this.enabled = boolFromStr(this.obj.dataset.enabled, true);
    }

    override initDomElement(): void {
        super.initDomElement();
        this.initializeDrake();
    }

    initializeDrake(): void {
        if (this.thisDrake) {
            this.thisDrake.destroy();
        }
        this.thisDrake = dragula([], {
            direction: 'vertical',
            copy: false,
            revertOnSpill: true,
            removeOnSpill: false,
            ignoreInputTextSelection: true,
            accepts: this.handleAccept.bind(this),
            moves: this.handleMove.bind(this)
        });

        this.addAdditionalClasses();
        for (let i = 0; i < this.containerIDs.length; i++) {
            if (this.containerIDs[i]) {
                this.thisDrake.containers.push(document.getElementById(this.containerIDs[i]));
            }
        };

        this.thisDrake.on('drag', (el: Element, source: Element) => { this.handleDragStart(el, source) });
        this.thisDrake.on('drop', (el: Element, target: Element, source: Element, sibling: Element) => { this.handleDrop(el, target, source, sibling) });
        this.thisDrake.on('dragend', (el: Element) => { this.handleDragEnd(el) });

    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value))
                break;
            case 'Enabled':
                if (!boolFromStr(value)) {
                    this.obj.setAttribute('disabled', '');
                    this.enabled = false;
                }
                else {
                    this.obj.removeAttribute('disabled');
                    this.enabled = true;
                }
                break;
            case 'AdditionalClass':
                this.removeAdditionalClasses();
                this.containerAdditionalClass = value;
                this.addAdditionalClasses();
                break;
            case 'Containers':
                this.removeAdditionalClasses();
                this.containerIDs = value.split('|');
                this.initializeDrake();
                break;
        }
    }

    removeAdditionalClasses(): void {
        if (this.containerAdditionalClass) {
            for (let i = 0; i < this.containerIDs.length; i++) {
                document.getElementById(this.containerIDs[i])?.classList.remove(this.containerAdditionalClass);
            };
        }
    }

    addAdditionalClasses(): void {
        if (this.containerAdditionalClass) {
            for (let i = 0; i < this.containerIDs.length; i++) {
                document.getElementById(this.containerIDs[i])?.classList.add(this.containerAdditionalClass);
            };
        }
    }

    // drag start request - we ask delphi if this element should be dragged
    handleMove(el: Element, source: Element, handle: Element, sibling: Element): boolean {
        if (this.enabled) {
            // console.log('handleMove -> ' + el.id + ' from ' + source.id);
            let res = sendComponentRequestGetJson(this.id, {
                elementID: el.id,
                containerID: source.id,
                action: 'onAllowDragStart'
            }) as RequestResult;
            // console.log(res);
            if (res.success) {
                return true;
            } else {
                if (res.errorMsg) {
                    MsgNotify(res.errorMsg, MessageType.Warning);
                }
                return false;
            }
        } else {
            return false;
        }
    }

    // drag event started
    handleDragStart(el: Element, source: Element): void {
        // console.log('handleDragStart -> ' + el.id + ' from ' + source.id);
        WebCompEventHandlerAsync('onDragStart', this.id, null, el.id + '|' + source.id);
    }

    // hover-event while dragging - called very often during drag - 
    handleAccept(el: HTMLElement, target: HTMLElement, source: HTMLElement, sibling: HTMLElement): boolean {
        // console.log('handleAccept -> ' + el.id + ' from ' + source.id + ' to ' + target.id); 
        
        return target.dataset.containertype === source.dataset.containertype;
    }

    // element dropped on target
    handleDrop(el: Element, target: Element, source: Element, sibling: Element): void {
        const indexInTarget = Array.from(target.children).findIndex(e => e.id === el.id);

        // console.log('handleDrop -> ' + el.id + ' from ' + source.id + ' to ' + target.id + ' on index ' + indexInTarget);

        let res = sendComponentRequestGetJson(this.id, {
            elementID: el.id,
            sourceID: source.id,
            destID: target.id,
            action: 'onAllowDrop'
        }) as RequestResult;
        // console.log(res);
        if (res.success) {
            WebCompEventHandlerAsync('onDrop', this.id, null, el.id + '|' + source.id + '|' + target.id + '|' + indexInTarget);
        } else {
            if (res.errorMsg) {
                MsgNotify(res.errorMsg, MessageType.Warning);
            }
            this.thisDrake.cancel(true);
        }
    }

    // drag event finished
    handleDragEnd(el: Element): void {
        // console.log('handleDragEnd -> ' + el.id);
        if (assigned(el)) {
            WebCompEventHandlerAsync('onDragEnd', this.id, null, el.id);
        }

    }
}