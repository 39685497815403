import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { AnchorHandle, TEdgeBase } from '../Edges/TEdgeBase';
import { ActionType, TCustomEdgeAction } from './TCustomAction';
import { TEdgeCreateAction } from './TEdgeCreateAction';

export class TEdgeDeleteAction extends TCustomEdgeAction {
    fromNode: string;
    toNode: string;
    fromAnchor: AnchorHandle;
    toAnchor: AnchorHandle;

    constructor(refElement: TEdgeBase, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patEdgeDelete;
    }

    override fromJSON(obj: any) {
        super.fromJSON(obj);

        this.fromNode = obj.fromNode;
        this.toNode = obj.toNode;
        this.fromAnchor = obj.fromAnchor;
        this.toAnchor = obj.toAnchor;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        obj.fromNode = this.fromNode;
        obj.toNode = this.toNode;
        obj.fromAnchor = this.fromAnchor;
        obj.toAnchor = this.toAnchor;

        return obj;
    }

    setValue(fromNode: string, fromAnchor: AnchorHandle, toNode: string, toAnchor: AnchorHandle): void {
        this.fromNode = fromNode;
        this.fromAnchor = fromAnchor;
        this.toNode = toNode;
        this.toAnchor = toAnchor;
        this.svgEditor.unregisterObj(this.refElement);
    }

    performAction(): void {
        this.svgEditor = this.refElement.editor;
        this.fromNode = this.refElement.fromNode;
        this.fromAnchor = this.refElement.fromAnchor;
        this.toNode = this.refElement.toNode;
        this.toAnchor = this.refElement.toAnchor;
        this.refElement.delete();

        this.svgEditor.unregisterObj(this.refElement);
    }

    inverseAction(): TCustomEdgeAction {
        let result = new TEdgeCreateAction(this.refElement, this.svgEditor);
        result.setValue(this.fromNode, this.fromAnchor, this.toNode, this.toAnchor);
        this.svgEditor.registerObj(result.refElement);

        return result;
    }
}