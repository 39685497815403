import { EventDispatcher } from 'ste-events';
import { ISignal, SignalDispatcher } from 'ste-signals';
import { IEvent } from 'strongly-typed-events';
import { IWebComponent } from '../comps/interfaces/class.web.comps.intf';
import { getBaseUrl, getBaseUrlObj } from './endpoint';
import { MessageType, MsgNotify } from './utils/msgnotify';

let dispatcherIsLocked: boolean = false;
let __isUnloading: boolean = false;
let _disconnectSignal = new SignalDispatcher();
let _dirtySignal = new EventDispatcher<IWebComponent, unknown>();

export default function initGlobalDispatcher(): void {
    window.addEventListener('beforeunload', event => notifyUnload(event), false);
}

function notifyUnload(event): void {
    __isUnloading = true;
}

export function isUnloading(): boolean {
    return __isUnloading;
}

export function canUseDispatcher(showMessage: boolean = true): boolean {
    if (dispatcherIsLocked && showMessage) {
        MsgNotify('Diese Aktion kann aktuell nicht ausgeführt werden, da die Verbindung zum Server unterbrochen wurde.', MessageType.Danger);
    }

    return !dispatcherIsLocked
}


export function lockDispatcher(setLocked: boolean): boolean {
    console.warn(`Dispatcher lock changed [${setLocked}].`)
    dispatcherIsLocked = setLocked;

    return dispatcherIsLocked;
}

// Für den Dispatcher haben wir einen eigenen Request; der wird nicht mit gelockt und gibt auch das Result anders definiert...
export function sendComponentRequestDispatcher(id: string, obj: unknown): unknown {
    let url = getBaseUrl(id);
    let resultObj = {};

    $.ajax({
        async: false,
        method: 'POST',
        dataType: 'json',
        url: url,
        crossDomain: true,
        data: {
            data: JSON.stringify(obj)
        },
        success: function (result) {
            resultObj = result;
        },
        error: function (xhr, ajaxOptions, thrownError) {
            // 0 heißt nicht abgeschlossen
            if (xhr.status == 0) {
                resultObj = undefined;
            }
            // ansonsten ist der Server erreichbar aber was anderes ist faul, z. b. existiert der endpuntk nicht mehr
            else {
                resultObj = {};
            }
        }
    });


    return resultObj;
}

export function sendComponentBeacon(id: string, obj: unknown) {
    // Der Data Parameter bei Beacons ist nen Blob, damit wir das korrekt auswerten können senden wir die Daten hier also als Get Parameter...
    let url = getBaseUrlObj(id);
    url.searchParams.append('data', JSON.stringify(obj));

    navigator.sendBeacon(url.href, undefined);
}

export function notifyGlobalDisconnect(): void {
    _disconnectSignal.dispatch();
}

export function notifyGlobalDirty(sender: IWebComponent): void {
    _dirtySignal.dispatch(sender, null);
}

export function onDisconnect(): ISignal {
    return _disconnectSignal.asEvent();
}

export function onDirty(): IEvent<IWebComponent, unknown> {
    return _dirtySignal.asEvent();
}