import Color from 'color';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeSpot extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 650;
        this.inlineEdit = false;
    }

    getSvgElementType(): string {
        return 'rect';
    }

    override setBackgroundColor(backgroundColor: Color, gradient: number) {
        super.setBackgroundColor(backgroundColor, gradient);

        this.svgElement.setAttribute('fill-opacity', '0');
        this.svgElement.setAttribute('stroke-dasharray', '2, 4');
    }
}