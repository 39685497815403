import { TWebComponent } from '../../base/class.web.comps';
import { boolFromStr } from '../../../utils/strings';

export class TwcMenu extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcMenu';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}