import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcSlider extends TWebComponent {
    slider: HTMLInputElement;
    hasServerChangeEvent: boolean;
    output: HTMLElement;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcSlider';
        this.slider = AsType<HTMLInputElement>(this.obj);

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent ?? 'false');
        this.output = document.getElementById(this.id + '-output');
    }

    override initDomElement(): void {
        super.initDomElement();

        this.obj.addEventListener('change', () => this.handleSliderChanged());
        // für den output
        this.obj.addEventListener('input', () => {
            this.output.innerHTML = this.slider.value + '%';
        });
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleSliderChanged() {
        this.notifyComponentChanged();

        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Value', this.slider.value]);
        return properties;
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Enabled':
                this.slider.disabled = !boolFromStr(value);
                break;
            case 'Visible':
                // Visible muss auf dem -DIV gesetzt werden
                this.obj.parentElement.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Value':
                this.slider.value = value;
                this.output.innerHTML = this.slider.value + '%';
                break;
        }
    }
}