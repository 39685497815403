import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcPublisherSimple extends TWebComponent {
    inputID: string;
    submitbtnID: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcPublisherSimple';
        this.inputID = this.obj.dataset.inputid;
        this.submitbtnID = this.obj.dataset.submitid;
    }

    override initDomElement(): void {
        super.initDomElement();

        document.getElementById(this.inputID).addEventListener('keydown', e => this.handleKeydownEvent(e), false);
    }

    handleKeydownEvent(event: KeyboardEvent): void {
        this.notifyComponentChanged();
        // Ctrl-Enter in Input-Feld sendet den eingegeben Text
        if (event.ctrlKey && event.key == 'Enter') {
            document.getElementById(this.submitbtnID).click();
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}

export class TwcPublisherEx extends TwcPublisherSimple {
    placeholder: HTMLElement;
    content: HTMLElement;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcPublisherEx';

        this.placeholder = document.getElementById(this.id + '-placeholder');
        this.content = document.getElementById(this.inputID + '-content');
    }

    override initDomElement(): void {
        super.initDomElement();

        this.placeholder.addEventListener('click', e => this.handlePlaceholderClick(e), false);
        this.content.addEventListener('focusout', e => this.handleFocusOut(e), false);
    }

    handlePlaceholderClick(event: MouseEvent): void {
        this.placeholder.classList.add('d-none');
        this.content.classList.remove('d-none');
        this.content.focus();
    }

    handleFocusOut(event: FocusEvent) {
        let html = this.content.innerText.trim();

        // kein Text und auch keine Verlinkungen? dann Platzhalter einblenden
        if (html == '' && this.content.childElementCount == 0) {
            this.placeholder.classList.remove('d-none');
            this.content.classList.add('d-none');
        }
    }

    override setFocus(): void {
        this.placeholder.classList.add('d-none');
        this.content.classList.remove('d-none');
        this.content.focus();
    }
}