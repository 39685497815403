import { TWebComponent } from '../../base/class.web.comps';

export class TwcContentContainer extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcContentContainer';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
            case 'Enabled':
                this.obj.setAttribute('disabled', String(value == '0'));
                break;
        }
    }
}

export class TwcContentContainerGroup extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcContentContainerGroup';
    }
    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
            case 'Enabled':
                this.obj.toggleAttribute('disabled', value == '0');
                break;
        }
    }
}