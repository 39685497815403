import Color from 'color';
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';
import { TBaseTutorialShape } from '../Shapes/TBaseTutorialShape';
import { TCustomTutorialShapeAction, TutorialActionType } from './TTutorialEditorAction';

export class TTutorialShapeBackgroundColorAction extends TCustomTutorialShapeAction {
    oldBackgroundColor: Color;
    newBackgroundColor: Color;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeBackgroundColor;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldBackgroundColor = Color(obj.oldBackgroundColor);
        this.newBackgroundColor = Color(obj.newBackgroundColor);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldBackgroundColor = this.oldBackgroundColor.hex();
        obj.newBackgroundColor = this.newBackgroundColor.hex();

        return obj;
    }

    setValue(newBackgroundColor: Color): void {
        this.newBackgroundColor = newBackgroundColor;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setBackgroundColor(this.newBackgroundColor);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeBackgroundColorAction(this.refElement, this.svgEditor);
        result.setValue(this.oldBackgroundColor);

        return result;
    }
}
