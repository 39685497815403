import { Bounds, Point } from "../../../../classes/geometry";
import { assigned } from "../../../../utils/helper";
import { TTutorialGroupAction } from "../Actions/TTutorialGroupAction";
import { TTutorialShapePositionAction } from "../Actions/TTutorialShapePositionAction";
import { TTutorialShapeSizeAction } from "../Actions/TTutorialShapeSizeAction";
import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";


export enum ResizeDirection {
    north = 'n',
    northEast = 'ne',
    east = 'e',
    southEast = 'se',
    south = 's',
    southWest = 'sw',
    west = 'w',
    northWest = 'nw',
    horizontal = 'ew',
    vertical = 'ns',
    all = 'all',
}

export function initResizeEvents(editor: TwsTutorialEditor): void {

    initShapeResizeEvents(editor);
}

function initShapeResizeEvents(editor: TwsTutorialEditor): void {
    let resizingShape: TBaseTutorialShape = null;
    let resizeDirection: ResizeDirection = null;
    let selectedElements: Array<TBaseTutorialShape>;

    function buildResizeClassName(direction: ResizeDirection): string {
        const CLASS_PREFIX = 'resize';
        return `${CLASS_PREFIX}-${direction}`;
    }

    editor.svg.addEventListener('mousedown', event => {
        // die eventListener nur Hinzufügen, wenn wir resizen wollen
        if (!(event.target instanceof SVGElement && event.target.classList.contains('resizeHandle'))) {
            return;
        }

        for (const directionName in ResizeDirection) {
            let direction = ResizeDirection[directionName];
            let className = buildResizeClassName(ResizeDirection[directionName]);

            if (event.target.classList.contains(className)) {
                resizeDirection = direction;
                break;
            }
        }

        selectedElements = editor.objectList.filter(x => x instanceof TBaseTutorialShape && x.selected === true) as Array<TBaseTutorialShape>;
        selectedElements.forEach(element => {
            (element as TBaseTutorialShape).resetDraftBounds();
        });

        let ownerId = event.target.dataset.ownerId;
        resizingShape = editor.objectById(ownerId) as TBaseTutorialShape;

        // eventlistener intialisieren
        document.documentElement.addEventListener('mousemove', handleShapeResizeMouseMove);
        document.documentElement.addEventListener('mouseup', handleShapeResizeMouseUp);
        // editor.isElementEditMode = true;
    });

    function handleShapeResizeMouseMove(event: MouseEvent): void {

        function getResizeAnchorPosition(shape: TBaseTutorialShape, anchorDirection: ResizeDirection): Point {
            let x = shape.x;
            let y = shape.y;

            // Bei den Ankern rechts müssen wir die breite berücksichtigen
            if ([ResizeDirection.northEast, ResizeDirection.east, ResizeDirection.southEast].includes(anchorDirection)) {
                x += shape.w;
            };

            // Und unten die Höhe
            if ([ResizeDirection.southWest, ResizeDirection.south, ResizeDirection.southEast].includes(anchorDirection)) {
                y += shape.h;
            }

            return new Point(x, y);
        }

        // if (!editor.isElementEditMode) {
        //     stopShapeResize();
        //     return;
        // }

        if (assigned(resizingShape)) {
            let cursorPosition = editor.getLocalCoordinate(event);
            let anchorPosition = getResizeAnchorPosition(resizingShape, resizeDirection);

            // Resize Änderung
            let dx = cursorPosition.x - anchorPosition.x;
            let dy = cursorPosition.y - anchorPosition.y;
            // Minimale Size für Top/Left
            let minSize = 10;
            console.debug(`dx: ${dx}, dy: ${dy}`);

            selectedElements.forEach(element => {
                let shape = element as TBaseTutorialShape;

                if (assigned(shape)) {
                    if (!shape.isResizePossible) {
                        return;
                    }

                    // ACHTUNG: dx und dy sind Offsets auf Basis der alten Resizeankerpositionen. Daher müssen wir beim Rechnen (Snapping) immer auf den Originalzustand
                    // beziehen. Die unveränderten Werte müssen aber aus dem Draft Zustand kommen, damit wir uns beim Diagonalen Resize in zwei Richtungen
                    // nicht wieder die zuvor veränderte Richtung mit dem original überschreiben

                    // North
                    if ([ResizeDirection.north, ResizeDirection.northEast, ResizeDirection.northWest].includes(resizeDirection)) {
                        // dy auf position addieren zum verschieben
                        let y = shape.y + dy;
                        // höhe um dy reduzieren um untere Kante beizubehalten
                        let h = shape.h - dy;

                        // Boundings prüfen und anwenden
                        if (h >= minSize) {
                            shape.setNewDraftPosition(shape.boundingRect.left, y);
                            shape.setNewDraftSize(shape.boundingRect.width, h);
                        }
                    }
                    // South
                    else if ([ResizeDirection.south, ResizeDirection.southEast, ResizeDirection.southWest].includes(resizeDirection)) {
                        // höhe um dy erweitern
                        let h = shape.h + dy;
                        // Boundings prüfen und anwenden
                        if (h >= minSize) {
                            shape.setNewDraftSize(shape.boundingRect.width, h);
                        }
                    }

                    // West
                    if ([ResizeDirection.west, ResizeDirection.northWest, ResizeDirection.southWest].includes(resizeDirection)) {
                        // dx auf Position addieren zum verschieben
                        let x = shape.x + dx;
                        // breite um dx reduzieren um die rechte Kante beizubehalten
                        let w = shape.w - dx;

                        // Boundings prüfen und anwenden
                        if (w >= minSize) {
                            shape.setNewDraftPosition(x, shape.boundingRect.top);
                            shape.setNewDraftSize(w, shape.boundingRect.height);
                        }
                    }
                    // East
                    else if ([ResizeDirection.east, ResizeDirection.northEast, ResizeDirection.southEast].includes(resizeDirection)) {
                        // breite um dx erweitern
                        let w = shape.w + dx;

                        // Boundings prüfen und anwenden
                        if (w >= minSize) {
                            shape.setNewDraftSize(w, shape.boundingRect.height);
                        }
                    }

                    // Editorgröße anpassen -> macht implizit schon ein invalidate!
                    editor.checkEditorSize();
                }
            });
        }
    }

    function handleShapeResizeMouseUp(): void {
        // if (!editor.isElementEditMode) {
        //     stopShapeResize();
        //     return;
        // }

        let actionGroup = new TTutorialGroupAction(null, editor);

        selectedElements.forEach(element => {
            let selectedShape = element as TBaseTutorialShape;
            if (assigned(selectedShape)) {
                // und speichern uns das target wohin wir bewegen wollen
                let target = new Point(selectedShape.boundingRect.left, selectedShape.boundingRect.top);

                // wir vergleichen unsere bbox mit der orgbbox und erstellen daraus jeweils eine pos und eine size action
                // für das virtuelle Grid müssen wir jedoch zuerst die Größe aktualisieren, um es danach neu ausrichten zu können
                let sizeAction = new TTutorialShapeSizeAction(selectedShape, editor);
                sizeAction.setValue(selectedShape.boundingRect.width, selectedShape.boundingRect.height);
                actionGroup.actions.push(sizeAction);

                // nun die Position bestimmen
                let newPosition = new Bounds(target.x, target.y, target.x + selectedShape.w, target.y + selectedShape.h);
                // und als Action speichern
                let positionAction = new TTutorialShapePositionAction(selectedShape, editor);
                positionAction.setValue(newPosition.left - selectedShape.x, newPosition.top - selectedShape.y);
                actionGroup.actions.push(positionAction);

                selectedShape.resetDraftBounds(); // das eigentliche Anwenden passiert unten via Perform auf der Actionliste
            }
        });

        if (actionGroup.actions.length > 0) {
            editor.actionList.addClientAction(actionGroup);
            editor.actionList.actions[editor.actionList.listPointer].performAction();
        }

        // und nun die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleShapeResizeMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleShapeResizeMouseUp, false);
        // editor.isElementEditMode = false;
    }

    function stopShapeResize() {
        // alte Bounds wiederherstellen
        if (assigned(selectedElements) && selectedElements.length > 0) {
            selectedElements.forEach(selectedElement => {
                if (selectedElement instanceof TBaseTutorialShape) {
                    selectedElement.setNewDraftSize(selectedElement.w, selectedElement.h);
                    selectedElement.setNewDraftPosition(selectedElement.x, selectedElement.y, true);
                }
            });
        }

        // Editorgröße anpassen; -> macht implizit schon ein invalidate!
        editor.checkEditorSize();

        // eventlistener aufräumen
        document.documentElement.removeEventListener('mousemove', handleShapeResizeMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleShapeResizeMouseUp, false);
    }
}