import { Point, Range, Rectangle } from '../../../../classes/geometry';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { AnchorHandle } from '../Edges/TEdgeBase';
import { TBaseShape } from '../Shapes/TBaseShape';

export const EPS = 0.000001; // smallest positive value: less than that to be considered zero
export const EPSEPS = EPS * EPS; // and its square

export function recalcExtraPoints(editor: TwsProcessEditorCustom, source: TBaseShape, sourceHandle: AnchorHandle, destination: TBaseShape, destinationHandle: AnchorHandle): Array<Point> {
    let margins: { x: number, y: number };
    let aMedPoint: Point;
    let aSrcPoint: Point, aDstPoint: Point;
    let aSrcPointOrg: Point, aDstPointOrg: Point;
    let aSrcHandle: AnchorHandle, aDstHandle: AnchorHandle;
    let hRangeSrc: Range, hRangeDst: Range;
    let vRangeSrc: Range, vRangeDst: Range;

    let extraPoints: Array<Point>;

    aMedPoint = new Point(0, 0);
    aSrcPoint = new Point(0, 0);
    aDstPoint = new Point(0, 0);
    aSrcPointOrg = new Point(0, 0);
    aDstPointOrg = new Point(0, 0);

    extraPoints = [];

    function isEqual(aSrc: AnchorHandle, aDst: AnchorHandle): boolean {
        return (aSrcHandle === aSrc) && (aDstHandle === aDst);
    }

    function horzRectToRange(aRect: Rectangle): Range {
        let a = aRect.left;
        let b = aRect.right;
        return { a, b };
    }

    function inflateRange(aRange: Range, delta: number): Range {
        let result = { a: 0, b: 0 };

        // Delta > 0, Range wächst
        result.a = aRange.a - delta;
        result.b = aRange.b + delta;
        if (result.a > result.b)
            result.a = result.b;

        return result;
    }

    function vertRectToRange(aRect: Rectangle): Range {
        let a = aRect.top;
        let b = aRect.bottom;
        return { a, b };
    }

    function rangeHasPos(aRange: Range, aPos: number): boolean {
        return aPos < aRange.b && aPos > aRange.a;
    }

    function distancePointLinePoint(p: Point, a: Point, b: Point) {
        return distancePointLine(p.x, p.y, a.x, a.y, b.x, b.y);
    }

    function sqLineMagnitude(x1: number, y1: number, x2: number, y2: number): number {
        //
        // Returns the square of the magnitude of the line
        // to cut down on unnecessary Sqrt when in many cases
        // DistancePointLine() squares the result
        //

        return (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
    }

    // Funktion aus SmartVectors (line 123)
    function distancePointLine(px: number, py: number, x1: number, y1: number, x2: number, y2: number): number {
        // px, py is the point to test.
        // x1, y1, x2, y2 is the line to check distance.
        //
        // Returns distance from the line, or if the intersecting point on the line nearest
        // the point tested is outside the endpoints of the line, the distance to the
        // nearest endpoint.
        //
        // Returns -1 on zero-valued denominator conditions to return an illegal distance. (
        // modification of Brandon Crosby's VBA code)

        let sqLineMag: number, // square of line's magnitude (see note in function LineMagnitude)
            u: number, // see Paul Bourke's original article(s)
            ix: number, // intersecting point X
            iy: number, // intersecting point Y
            result: number;

        sqLineMag = sqLineMagnitude(x1, y1, x2, y2);
        if (sqLineMag < EPSEPS) {
            result = - 1;
            return result;
        }

        u = ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / sqLineMag;

        if ((u < EPS) || (u > 1)) {
            // Closest point does not fall within the line segment,
            // take the shorter distance to an endpoint
            ix = sqLineMagnitude(px, py, x1, y1);
            iy = sqLineMagnitude(px, py, x2, y2);
            result = Math.min(ix, iy);
        } // if (u < EPS) or (u > 1)
        else {
            // Intersecting point is on the line, use the formula
            ix = x1 + u * (x2 - x1);
            iy = y1 + u * (y2 - y1);
            result = sqLineMagnitude(px, py, ix, iy);
        } // else NOT (u < EPS) or (u > 1)

        // finally convert to actual distance not its square

        return Math.sqrt(result);
    }

    function anchor90(anchor: AnchorHandle): AnchorHandle {
        switch (anchor) {
            case AnchorHandle.ahLeft:
                return AnchorHandle.ahBottom;
            case AnchorHandle.ahTop:
                return AnchorHandle.ahLeft;
            case AnchorHandle.ahRight:
                return AnchorHandle.ahTop;
            case AnchorHandle.ahBottom:
                return AnchorHandle.ahRight;
            case AnchorHandle.ahCenter:
                return AnchorHandle.ahCenter;
            default:
                throw 'invalid AnchorHandle (anchor90)';
        }
    }

    function addExtraPoint(x: number, y: number): void {
        x = Math.round(x);
        y = Math.round(y);
        let aPoint: Point;
        {
            // Doppelte Punkte vermeiden! Auch wenn diese auf der selben Linie liegen...
            if ((extraPoints.length === 0) || !extraPoints[extraPoints.length - 1].equals(new Point(x, y))) {
                if (extraPoints.length > 1) {
                    aPoint = extraPoints[extraPoints.length - 2];
                }
                else {
                    aPoint = source.getAnchorPoint(sourceHandle);
                }

                if (extraPoints.length >= 1 && distancePointLinePoint(extraPoints[extraPoints.length - 1], aPoint, new Point(x, y)) <= EPS) {
                    extraPoints[extraPoints.length - 1] = new Point(x, y);
                }
                else {
                    extraPoints.push(new Point(x, y));
                }
            }
        }
    }

    function extraPointFinalize(): void {
        let aPoint: Point;

        // Obige Funktion war ja schon ganz toll, aber dummes Problem: der letzte Point kann
        // mit dem DesPoint ebenfalls obsolet sein!!!! Da wir aber nie wissen, wer der letzte ist
        // müssen wirs jetzt checken...
        if (extraPoints.length === 0) {
            return;
        }

        // Ist der letzte ExtraPoint mit dem destination-Point identisch?
        if (destination.getAnchorPoint(destinationHandle).equals(extraPoints[extraPoints.length - 1])) {
            extraPoints.splice(extraPoints.length - 1, 1);
            extraPointFinalize(); // ggf. rekursiv...
            return;
        }

        // Liegt der letzte auf einer Linie mit den anderen
        if (extraPoints.length > 1) {
            aPoint = extraPoints[extraPoints.length - 2];
        }
        else
            aPoint = source.getAnchorPoint(sourceHandle);

        if ((extraPoints.length >= 1) && (distancePointLinePoint(extraPoints[extraPoints.length - 1],
            aPoint, destination.getAnchorPoint(destinationHandle)) <= EPS)) {
            extraPoints.splice(extraPoints.length - 1, 1);
            extraPointFinalize(); // ggf. rekursiv...
            return;
        }
    }

    aSrcPoint = source.getAnchorPoint(sourceHandle);
    aDstPoint = destination.getAnchorPoint(destinationHandle);
    aSrcHandle = sourceHandle;
    aDstHandle = destinationHandle;

    margins = { x: editor.gridSize.width, y: editor.gridSize.height };
    extraPoints.length = 0;

    if ((Math.abs(aSrcPoint.x - aDstPoint.x) <= margins.x) && (Math.abs(aSrcPoint.y - aDstPoint.y) <= margins.y)) {
        return extraPoints;
    }

    hRangeSrc = inflateRange(horzRectToRange(source.boundingRect), margins.x);
    hRangeDst = inflateRange(horzRectToRange(destination.boundingRect), margins.x);
    vRangeSrc = inflateRange(vertRectToRange(source.boundingRect), margins.y);
    vRangeDst = inflateRange(vertRectToRange(destination.boundingRect), margins.y);

    switch (aSrcHandle) {
        case AnchorHandle.ahLeft:
            aSrcPoint.x = aSrcPoint.x - margins.x;
            break;
        case AnchorHandle.ahRight:
            aSrcPoint.x = aSrcPoint.x + margins.x;
            break;
        case AnchorHandle.ahTop:
            aSrcPoint.y = aSrcPoint.y - margins.y;
            break;
        case AnchorHandle.ahBottom:
            aSrcPoint.y = aSrcPoint.y + margins.y;
            break;
        case AnchorHandle.ahCenter:
            // nothing
            break;
        default:
            throw 'invalid AnchorHandle (src)';
    }

    switch (aDstHandle) {
        case AnchorHandle.ahLeft:
            aDstPoint.x = aDstPoint.x - margins.x;
            break;
        case AnchorHandle.ahRight:
            aDstPoint.x = aDstPoint.x + margins.x;
            break;
        case AnchorHandle.ahTop:
            aDstPoint.y = aDstPoint.y - margins.y;
            break;
        case AnchorHandle.ahBottom:
            aDstPoint.y = aDstPoint.y + margins.y;
            break;
        case AnchorHandle.ahCenter:
            // nothing
            break;
        default:
            throw 'invalid AnchorHandle (dst)';
    }

    aSrcPointOrg = aSrcPoint;
    aDstPointOrg = aDstPoint;

    if ((aSrcPoint.x <= aDstPoint.x) && (aSrcPoint.y <= aDstPoint.y)) {
        // Horizontal
        if (isEqual(AnchorHandle.ahBottom, AnchorHandle.ahBottom) || isEqual(AnchorHandle.ahLeft, AnchorHandle.ahBottom))
            if (rangeHasPos(hRangeDst, aSrcPoint.x))
                aSrcPoint.x = destination.boundingRect.left - margins.x;

        if (isEqual(AnchorHandle.ahTop, AnchorHandle.ahTop))
            if (rangeHasPos(hRangeSrc, aDstPoint.x))
                aDstPoint.x = source.boundingRect.right + margins.x;

        if (isEqual(AnchorHandle.ahTop, AnchorHandle.ahRight))
            if (rangeHasPos(hRangeSrc, aDstPoint.x))
                aDstPoint.x = source.boundingRect.right + margins.x;

        // Vertikal
        if (isEqual(AnchorHandle.ahLeft, AnchorHandle.ahLeft) || isEqual(AnchorHandle.ahLeft, AnchorHandle.ahBottom))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.bottom + margins.y;

        if (isEqual(AnchorHandle.ahTop, AnchorHandle.ahRight))
            if (rangeHasPos(vRangeDst, aSrcPoint.y))
                aSrcPoint.y = destination.boundingRect.top - margins.y;
    }
    else if ((aSrcPoint.x <= aDstPoint.x) && (aSrcPoint.y > aDstPoint.y)) {
        // Horizontal
        if (isEqual(AnchorHandle.ahBottom, AnchorHandle.ahBottom))
            if (rangeHasPos(hRangeSrc, aDstPoint.x))
                aDstPoint.x = source.boundingRect.right + margins.x;

        // Vertikal
        if (isEqual(AnchorHandle.ahLeft, AnchorHandle.ahLeft))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.top - margins.y;

        if (isEqual(AnchorHandle.ahLeft, AnchorHandle.ahTop))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.top - margins.y;

        aSrcHandle = anchor90(aSrcHandle);
        aDstHandle = anchor90(aDstHandle);
    }
    else if ((aSrcPoint.x > aDstPoint.x) && (aSrcPoint.y < aDstPoint.y)) {
        // Horizontal
        if (isEqual(AnchorHandle.ahBottom, AnchorHandle.ahBottom))
            if (rangeHasPos(hRangeDst, aSrcPoint.x))
                aSrcPoint.x = destination.boundingRect.right + margins.x;

        if (isEqual(AnchorHandle.ahTop, AnchorHandle.ahTop))
            if (rangeHasPos(hRangeSrc, aDstPoint.x))
                aDstPoint.x = source.boundingRect.left - margins.x;

        if (isEqual(AnchorHandle.ahTop, AnchorHandle.ahLeft))
            if (rangeHasPos(hRangeSrc, aDstPoint.x))
                aDstPoint.x = source.boundingRect.left - margins.x;

        if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahBottom))
            if (rangeHasPos(hRangeDst, aSrcPoint.x))
                aSrcPoint.x = destination.boundingRect.right + margins.x;

        // Vertikal
        if (isEqual(AnchorHandle.ahLeft, AnchorHandle.ahLeft))
            if (rangeHasPos(vRangeDst, aSrcPoint.y))
                aSrcPoint.y = destination.boundingRect.top - margins.y;

        if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahRight))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.bottom + margins.y;

        if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahBottom))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.bottom + margins.y;

        aSrcHandle = anchor90(anchor90(anchor90(aSrcHandle)));
        aDstHandle = anchor90(anchor90(anchor90(aDstHandle)));
    }
    else if ((aSrcPoint.x > aDstPoint.x) && (aSrcPoint.y > aDstPoint.y)) {
        // Horizontal
        if (isEqual(AnchorHandle.ahBottom, AnchorHandle.ahBottom) || isEqual(AnchorHandle.ahLeft, AnchorHandle.ahBottom))
            if (rangeHasPos(hRangeSrc, aDstPoint.x))
                aDstPoint.x = source.boundingRect.left - margins.x;

        if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahTop))
            if (rangeHasPos(hRangeDst, aSrcPoint.x))
                aSrcPoint.x = destination.boundingRect.right + margins.x;

        // Vertikal
        if (isEqual(AnchorHandle.ahLeft, AnchorHandle.ahLeft))
            if (rangeHasPos(vRangeDst, aSrcPoint.y))
                aSrcPoint.y = destination.boundingRect.bottom + margins.y;

        if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahTop))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.top - margins.y;

        if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahRight))
            if (rangeHasPos(vRangeSrc, aDstPoint.y))
                aDstPoint.y = source.boundingRect.top - margins.y;

        aSrcHandle = anchor90(anchor90(aSrcHandle));
        aDstHandle = anchor90(anchor90(aDstHandle));
    }

    aMedPoint.x = Math.floor((aSrcPoint.x + aDstPoint.x) / 2);
    aMedPoint.y = Math.floor((aSrcPoint.y + aDstPoint.y) / 2);

    // Wir dürfen nicht immer snappen!
    if (!(aSrcPoint.x === aDstPoint.x || aSrcPoint.y === aDstPoint.y)) {
        aMedPoint.x = editor.snapToRealGridX(aMedPoint.x);
        aMedPoint.y = editor.snapToRealGridY(aMedPoint.y);
    }

    // und erste Punkte...
    addExtraPoint(aSrcPointOrg.x, aSrcPointOrg.y);
    addExtraPoint(aSrcPoint.x, aSrcPoint.y);

    if (isEqual(AnchorHandle.ahLeft, AnchorHandle.ahLeft) || isEqual(AnchorHandle.ahBottom, AnchorHandle.ahLeft) || isEqual(AnchorHandle.ahBottom, AnchorHandle.ahBottom) ||
        isEqual(AnchorHandle.ahLeft, AnchorHandle.ahBottom)) {
        // VH
        addExtraPoint(aSrcPoint.x, aDstPoint.y);
    }

    else if (isEqual(AnchorHandle.ahRight, AnchorHandle.ahTop) || isEqual(AnchorHandle.ahTop, AnchorHandle.ahTop) || isEqual(AnchorHandle.ahRight, AnchorHandle.ahRight) ||
        isEqual(AnchorHandle.ahTop, AnchorHandle.ahRight)) {
        // HV
        addExtraPoint(aDstPoint.x, aSrcPoint.y);
    }

    else if (isEqual(AnchorHandle.ahTop, AnchorHandle.ahLeft) || isEqual(AnchorHandle.ahRight, AnchorHandle.ahLeft) || isEqual(AnchorHandle.ahTop, AnchorHandle.ahBottom) ||
        isEqual(AnchorHandle.ahRight, AnchorHandle.ahBottom)) {
        // HVH
        addExtraPoint(aMedPoint.x, aSrcPoint.y);
        addExtraPoint(aMedPoint.x, aDstPoint.y);
    }

    else if (isEqual(AnchorHandle.ahBottom, AnchorHandle.ahTop) || isEqual(AnchorHandle.ahLeft, AnchorHandle.ahTop) || isEqual(AnchorHandle.ahLeft, AnchorHandle.ahRight) ||
        isEqual(AnchorHandle.ahBottom, AnchorHandle.ahRight)) {
        // VHV
        addExtraPoint(aSrcPoint.x, aMedPoint.y);
        addExtraPoint(aDstPoint.x, aMedPoint.y);
    }

    addExtraPoint(aDstPoint.x, aDstPoint.y);
    addExtraPoint(aDstPointOrg.x, aDstPointOrg.y);

    extraPointFinalize();

    return extraPoints;
}