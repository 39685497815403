import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodeOwnerPosAction extends TCustomNodeAction {
    oldOwnerPos: number;
    newOwnerPos: number;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patShapeOwnerPos;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldOwnerPos = obj.oldOwnerPos;
        this.newOwnerPos = obj.newOwnerPos;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldOwnerPos = this.oldOwnerPos;
        obj.newOwnerPos = this.newOwnerPos;

        return obj;
    }

    setValue(newOwnerPos: number): void {
        this.newOwnerPos = newOwnerPos;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setOwnerPos(this.newOwnerPos);
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeOwnerPosAction(this.refElement, this.svgEditor);
        result.setValue(this.oldOwnerPos);

        return result;
    }
}
