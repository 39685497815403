import { Bounds, Point, SizeDefinition } from '../../../classes/geometry';
import { assigned } from '../../../utils/helper';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { TCustomActionGroup } from './Actions/TCustomActionGroup';
import { TEdgeExtraPointsAction } from './Actions/TEdgeExtraPointsAction';
import { TNodePositionAction } from './Actions/TNodePositionAction';
import { TNodeSizeAction } from './Actions/TNodeSizeAction';
import { ProcessMode, ProcessModesSwimLanes, ProcessModesWithGrid, TwsProcessEditorCustom } from './class.web.comp.process.editor.custom';
import { TEdgeBase } from './Edges/TEdgeBase';
import { TBaseShape } from './Shapes/TBaseShape';
import { TShapeLane } from './Shapes/TShapeLane';
import { getIDSuffix, SVGNS } from './Utils/TProcessEditorIDUtils';

export class TwsProcessEditorStandard extends TwsProcessEditorCustom {

    swimLanesFixed: boolean;

    override initComponent() {
        super.initComponent();

        this.swimLanesFixed = true; // per Default erstmal gesperrt.
    }

    override initSVGElements() {
        super.initSVGElements();

        let gridPadding = this.getGridPadding();

        let gridPattern = document.createElementNS(SVGNS, 'pattern');
        this.svgDefs.appendChild(gridPattern);

        let gridPath = document.createElementNS(SVGNS, 'path');
        gridPattern.appendChild(gridPath);

        let brightnessTreshhold = this.colorMode === 1 ? 85 : 140;
        let rgb = [this.backgroundColor.red(), this.backgroundColor.green(), this.backgroundColor.blue()];
        let yiq = (rgb[0] * 300 + rgb[1] * 590 + rgb[2] * 110) / 1000;
        let gridColor = (yiq < brightnessTreshhold) ? this.backgroundColor.lighten(0.2) : this.backgroundColor.darken(0.2);

        gridPattern.setAttribute('id', getIDSuffix(this.svgID, 'gridPattern'));
        gridPattern.setAttribute('width', String(this.getUsedGrid().width + gridPadding.width));
        gridPattern.setAttribute('height', String(this.getUsedGrid().height + gridPadding.height));
        gridPattern.setAttribute('patternUnits', 'userSpaceOnUse');
        gridPath.setAttribute('d', `M ${this.getUsedGrid().width} 0 L 0 0 0 ${this.getUsedGrid().height}`);
        gridPath.setAttribute('fill', 'none');
        gridPath.setAttribute('stroke', gridColor.hex()); // vorher gray
        gridPath.setAttribute('stroke-width', '2'); // vorher 0.5

        this.grid.setAttribute('width', '100%');
        this.grid.setAttribute('height', '100%');
        this.grid.setAttribute('fill', 'url(#' + getIDSuffix(this.svgID, 'gridPattern') + ')');
        this.grid.setAttribute('pointer-events', 'none');
    }

    override writeProperties(key: string, value: any): void {
        switch (key) {
            case 'SwimLanesFixed':
                this.swimLanesFixed = boolFromStr(value);
                this.updateSwimLanes();
                break;
            default: super.writeProperties(key, value);
        }
    }

    override execAction(action: string, params: string) {
        switch (action) {
            case 'Action.SwimlaneMoveNext':
                this.beginUpdate();
                try {
                    this.doSwimlaneMove(true);
                    this.checkEditorSize();
                } finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SwimlaneMovePrevious':
                this.beginUpdate();
                try {
                    this.doSwimlaneMove(false);
                    this.checkEditorSize();
                } finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SwimlaneInc':
                this.beginUpdate();
                try {
                    this.doSwimlaneResize(true);
                    this.checkEditorSize();
                } finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SwimlaneDec':
                this.beginUpdate();
                try {
                    this.doSwimlaneResize(false);
                    this.checkEditorSize();
                } finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SwimlaneResize':
                let json = csJSONParse(params);
                let width = parseInt(json.width);
                let height = parseInt(json.height);
                this.beginUpdate();
                try {
                    this.doSwimlaneResizeDefault(width, height);
                    this.checkEditorSize();
                } finally {
                    this.endUpdate();
                }
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    updateSwimLanes() {
        if (ProcessModesSwimLanes.includes(this.processMode)) {
            this.objectList.forEach(obj => {
                if (obj instanceof TShapeLane) {
                    (<TShapeLane>obj).setFixed(this.swimLanesFixed);
                    obj.invalidate();
                }
            });
        }
    }

    doSwimlaneMove(nextPos: boolean): void {
        // keine Swimlanes -> raus
        if (!(ProcessModesSwimLanes.includes(this.processMode)))
            return;

        this.beginUpdate();
        try {

            let selectedShapes = this.getSelectedShapes();
            let shapesToMove: Array<TBaseShape> = [];

            selectedShapes.forEach(shape => {
                // nur Swimlanes berücksichtigen
                if (!(shape instanceof TShapeLane))
                    return;

                shapesToMove.push(shape);

                let zIndex = shape.getOwnerPos();
                this.objectList.forEach(obj => {
                    if (obj instanceof TBaseShape && !(obj instanceof TShapeLane) && obj !== shape && obj.getOwnerPos() > zIndex) {
                        // liegt das Objekt vollständig innerhalb der Swimlane
                        if (shape.x <= obj.x && shape.x + shape.realBoundingRect.width >= obj.x + obj.realBoundingRect.width) {
                            if (shape.y <= obj.y && shape.y + shape.realBoundingRect.height >= obj.y + obj.realBoundingRect.height)
                                shapesToMove.push(obj);
                        }
                    }
                })
            });

            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let edgeActionGroup = new TCustomActionGroup(null, this);

            shapesToMove.forEach(shape => {
                let posDiff;
                if (nextPos)
                    posDiff = 100;
                else {
                    posDiff = -100;
                    // außerhalb des Editors?
                    if (((this.processMode == ProcessMode.SwimLaneHorizontal || this.processMode == ProcessMode.BPMN) && (shape.y + posDiff < 0)) || this.processMode == ProcessMode.SwimLaneVertical && (shape.x + posDiff < 0))
                        return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                if (this.processMode == ProcessMode.SwimLaneVertical)
                    moveAction.setValue(posDiff, 0);
                else
                    moveAction.setValue(0, posDiff);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doSwimlaneResize(bigger: boolean): void {
        // keine Swimlanes -> raus
        if (!(ProcessModesSwimLanes.includes(this.processMode)))
            return;

        this.beginUpdate()
        try {
            // Wir betrachten nur die SwimLanes
            let selectedShapes = this.getSelectedShapes().filter(obj => obj instanceof TShapeLane) as Array<TBaseShape>;
            let shapesToResize: Array<TBaseShape> = [];

            // Wenn keine Shapes gewählt sind, sollen alle angepasst werden
            if (selectedShapes.length === 0) {
                selectedShapes = this.objectList.filter(obj => obj instanceof TShapeLane) as Array<TBaseShape>;
            }

            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let edgeActionGroup = new TCustomActionGroup(null, this);

            selectedShapes.forEach(shape => {
                shapesToResize.push(shape);
            });

            shapesToResize.forEach(shape => {
                let sizeDiff: number;
                if (this.processMode == ProcessMode.SwimLaneHorizontal)
                    sizeDiff = 6 * this.gridSize.width;
                else
                    sizeDiff = 6 * this.gridSize.height;
                let width = shape.realBoundingRect.width;
                let height = shape.realBoundingRect.height;
                if (!bigger) {
                    sizeDiff = -sizeDiff;
                    // zu klein?
                    if (((this.processMode == ProcessMode.SwimLaneHorizontal || this.processMode == ProcessMode.BPMN) && (width + sizeDiff < 0)) || this.processMode == ProcessMode.SwimLaneVertical && (shape.realBoundingRect.height + sizeDiff < 0))
                        return;
                }

                let resizeAction = new TNodeSizeAction(shape, this);
                if (this.processMode == ProcessMode.SwimLaneVertical)
                    resizeAction.setValue(width, height + sizeDiff);
                else
                    resizeAction.setValue(width + sizeDiff, height);

                nodeActionGroup.actions.push(resizeAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doSwimlaneResizeDefault(width: number, height: number): void {
        // keine Swimlanes -> raus
        if (!(ProcessModesSwimLanes.includes(this.processMode)))
            return;

        this.beginUpdate()
        try {
            let selectedShapes = this.getSelectedShapes();
            let shapesToResize: Array<TBaseShape> = [];

            // Wenn keine Shapes gewählt sind, sollen alle angepasst werden
            if (selectedShapes.length === 0) {
                selectedShapes = this.objectList.filter(obj => obj instanceof TShapeLane) as Array<TBaseShape>;
            }

            selectedShapes.forEach(shape => {
                // nur Swimlanes berücksichtigen
                if (!(shape instanceof TShapeLane))
                    return;

                shapesToResize.push(shape);
            });

            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let edgeActionGroup = new TCustomActionGroup(null, this);

            shapesToResize.forEach(shape => {
                let resizeAction = new TNodeSizeAction(shape, this);
                resizeAction.setValue(width, height);

                nodeActionGroup.actions.push(resizeAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }


    override renderGrids() {
        super.renderGrids();

        //invalidate grid
        const showGrid = this.hasGrid() && ProcessModesWithGrid.includes(this.processMode);
        this.grid.classList.toggle('d-none', !showGrid);

        // Sonderhandling SwimLanes
        if (ProcessModesSwimLanes.includes(this.processMode)) {
            this.objectList.forEach(obj => {
                if (obj instanceof TShapeLane) {
                    (<TShapeLane>obj).grid.classList.toggle('d-none', !this.hasGrid());
                }
            });
        }
    }

    //#region Grid Utils

    /**
     * @returns Either the gridSize or, if virtual grid is used, the virtualGridSize for clusters.
     */
    getUsedGrid(): SizeDefinition {
        return this.gridSize;
    }

    /**
     * @returns Either the grid padding of individual cells or, if virtual grid is used, the padding between clusters.
     */
    getGridPadding(): SizeDefinition {
        return {
            width: 0,
            height: 0
        }
    }

    /**
     * Snaps a given value on the x-axis to the used grid
     * @param x Value to snap to grid
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    snapToUsedGridX(x: number): number {
        if (this.hasGrid()) {
            return this.snapToRealGridX(x);
        }
        else {
            return x;
        }
    }

    /**
     * Snaps a given value on the y-axis to the used grid
     * @param x Value to snap to grid
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    snapToUsedGridY(y: number): number {
        if (this.hasGrid()) {
            return this.snapToRealGridY(y)
        }
        else {
            return y;
        }
    }

    /**
     * Snaps a shape to the used grid. Optionally can handle a new target position.
     * @param shape Shape to snap to the grid.
     * @param newPos Target position. If not set, the current shape position will be used instead.
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    snapShapeToUsedGrid(shape: TBaseShape, newPos?: Point): Bounds {

        if (!assigned(newPos)) {
            return this.snapShapeToUsedGrid(shape, new Point(shape.x, shape.y));
        }

        // Erstmal die Positionen bestimmen:
        let newX = this.snapToUsedGridX(newPos.x);
        let newY = this.snapToUsedGridY(newPos.y);

        return new Bounds(newX, newY, newX + shape.w, newY + shape.h);
    }

    //#endregion
}