// Utils unit for TQMObject things

import { assigned, isNumeric } from "./helper";

/**
 * Interface to represent and OID
 */
export interface IOID {
    /**
     * Type of the object. '?' is unknown type.
     */
    ObjType: string;

    /**
     * Id of the object. 0 is unknown / default id.
     */
    Id: number;

    /**
     * Rev of the object. -1 is unknown rev or not applicable.
     */
    Rev: number;

    /**
     * Builds OID string as <Type><ID> pattern.
     */
    get OID(): string;

    /**
     * Build FullOID string as <Type><ID>:<Rev> pattern.
     */
    get FullOID(): string;
}

export class OID implements IOID {
    ObjType: string;
    Id: number;
    Rev: number;

    constructor(objType: string = '?', id: number = 0, rev: number = -1) {
        this.ObjType = objType;
        this.Id = id;
        this.Rev = rev;
    }

    get OID(): string {
        return `${this.ObjType}${this.Id}`;
    }

    get FullOID(): string {
        return `${this.OID}:${this.Rev}`;
    }
}

/**
 * Parses and OID string into a structured object.
 * @param oid OID string to parse
 * @returns Structured object of the OID. Returns null if the input is not a valid OID.
 */
export function parseOid(oid?: string): IOID | null {
    let objType = '?';
    let id = 0;
    let rev = -1;

    if (!assigned(oid)) {
        return null;
    }

    oid = oid.trim();

    if (oid === '') {
        return null;
    }

    objType = oid[0].toUpperCase();
    oid = oid.substring(1);

    // X = Meta Type -> More Information
    if (objType === 'X') {
        let c = '';
        do {
            c = oid[0];
            if (!isNumeric(c)) {
                objType += c;
                oid = oid.substring(1);
            }
        } while (!isNumeric(c) && oid !== '')
    }

    // split wegen rev
    let idData = oid.split(':');

    // invalid
    if (idData.length === 0) {
        return null;
    }

    id = parseInt(idData[0]);

    if (idData.length === 2) {
        rev = parseInt(idData[1]);
    }

    // commands haben wir im portal erstmal nicht, daher parsen wir die auch nicht

    return new OID(objType, id, rev)
}