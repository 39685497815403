import { TWebComponent } from '../../base/class.web.comps';


require('datatables.net-bs5')(window, $);
require('datatables.net-responsive-bs5')(window, $);
require('datatables.net-scroller-bs5')(window, $);


export class TwcDataTable extends TWebComponent {
    files: any[];
    table: DataTables.Api;
    tableID: string;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcDataTable';
        this.tableID = this.obj.dataset.tableid;
        this.files = [];
    }

    override initDomElement() {
        super.initDomElement();

        this.initThisTable();
    }

    initThisTable() {
        this.table = $('#' + this.tableID).DataTable();
    }

    override execAction(action: string, params: string): void {
        try {
            var ActionSet = JSON.parse(params);
            switch (action) {
                case 'Action.RowChange':
                    this.initThisTable();
                    let obj = JSON.parse(ActionSet.ActionValue);
                    if (obj.index < this.table.rows().count()) {
                        this.table.row(obj.index).data(obj.cells).draw();
                    } else {
                        this.table.row.add(obj.cells).draw();
                    }
                    break;
                case 'Action.RowAdd':
                    this.initThisTable();
                    this.table.row.add($(ActionSet.ActionValue)).draw();
                    this.table.columns.adjust().draw();
                    // UPS // fehlt in Typdefinitionen, ist laut API aber korrekt -> https://github.com/DefinitelyTyped/DefinitelyTyped/issues/49016                    
                    // Fix via manuellen Typefile
                    this.table.responsive.recalc();
                    break;
                case 'Action.RowDelete':
                    this.initThisTable();
                    // über die nodes laufen, weil sonst nur der sichtbare DOM durchlaufen wird. Bei mehreren Seiten werden sonst nicht alle Zeilen gelöscht.
                    let rows = this.table.rows().nodes();
                    let row = Array.prototype.find.call(rows, function (node) {
                        return node.getAttribute('data-id') === ActionSet.ActionValue;
                    });
                    // eigentlich sollte man hier auch noch direkt nach dem remove .draw() aufrufen können, aber anscheinend können wir das nicht
                    // deswegen muss später noch ein Action.Reload kommen
                    this.table.row(row).remove();
                    this.table.draw();
                    break;
                case 'Action.ClearRows':
                    this.initThisTable();
                    this.table.clear().draw();
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                if (value == '1') {
                    $('#' + this.id + '-DIV').removeClass('d-none');
                } else if (value == '0') {
                    $('#' + this.id + '-DIV').addClass('d-none');
                }
                break;
        }
    }
}


/*
 * check all cells of given datatable and hide each column containing only empty cells
 * ATTENTION: this will only work if responsive-property in datatables is set to true
 */
function hideEmptyColumns(selector) {

    var cells: JQuery<HTMLElement>;
    var emptyColumnsIndexes = []; // store index of empty columns here
    // check each column separately for empty cells
    $(selector).find('th').each(function (i) {
        // get all cells for current column
        cells = $(this).parents('table').find('tr td:nth-child(' + (i + 1) + ')');
        var emptyCells = 0;
        cells.each(function (cell) {
            // increase emptyCells if current cell is empty, trim string to remove possible spaces in cell
            if ($(this).html().trim() === '') {
                emptyCells++;
            }
        });

        // if all cells are empty push current column to emptyColumns
        if (emptyCells === $(cells).length) {
            emptyColumnsIndexes.push($(this).index());
        }
    });

    // only make changes if there are columns to hide
    if (emptyColumnsIndexes.length > 0 && cells.length > 0) {
        /* add class never to all empty columns
            never is a special class of the Responsive extension:
            Columns with class never will never be visible, regardless of the browser width, and the data will not be shown in a child row
        */
        $($(selector).DataTable().columns(emptyColumnsIndexes).header()).addClass('never');
        // Recalculate the column breakpoints based on the class information of the column header cells, class never will now be available to Responsive extension
        $(selector).DataTable().columns.adjust().responsive.rebuild();
        // immediatly call recalc to have Responsive extension updae the display for the cahnge in classes
        $(selector).DataTable().columns.adjust().responsive.recalc();
    }
}
// Die wird via Delphi aufgerufen
window[hideEmptyColumns.name] = hideEmptyColumns;


$(document).on('shown.bs.modal', function (e) {
    var modal = $(e.target);
    // Modal vorhanden?
    if (modal.length > 0) {
        var table = modal.find('[data-type="TwcDataTable"] table');
        // Datatable vorhanden?
        if (table.length > 0) {
            // Header anpassen, da table jetzt erst sichtbar...
            // verzögert, da scroll die columns wieder resized
            window.setTimeout(function () { table.DataTable().columns.adjust(); }, 150);

            // nur wenn responsive
            if (table.hasClass('responsive'))
                window.setTimeout(function () { table.DataTable().responsive.recalc(); }, 150);
        }
    }
})

$.fn.dataTable.ext.errMode = 'throw';