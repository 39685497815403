import isEmpty from 'lodash.isempty';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShapeBPMN } from './TBaseShapeBPMN ';

export class TShapeBPMNData extends TBaseShapeBPMN {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 975;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let cw = this.boundingRect.width / 8;
        if (cw > this.boundingRect.height / 4) {
            cw = this.boundingRect.height / 3;
        }

        let path = [
            'M', this.boundingRect.left, this.boundingRect.top,
            'L', this.boundingRect.left + this.boundingRect.width - cw * 2, this.boundingRect.top,
            'L', this.boundingRect.left + this.boundingRect.width, this.boundingRect.top + 2 * cw,
            'L', this.boundingRect.left + this.boundingRect.width, this.boundingRect.top + this.boundingRect.height,
            'L', this.boundingRect.left, this.boundingRect.top + this.boundingRect.height,
            'Z',
            // und die Ecke
            'M', this.boundingRect.left + this.boundingRect.width - cw * 2, this.boundingRect.top,
            'L', this.boundingRect.left + this.boundingRect.width - cw * 2, this.boundingRect.top + 2 * cw,
            'L', this.boundingRect.left + this.boundingRect.width, this.boundingRect.top + 2 * cw
        ].join(' ');

        return path;
    }

    iconByCode(code: string): string {
        switch (code.trim()) {
            case 'A':
                return 'bpmn-data-dataobject';
            case 'B':
                return 'bpmn-data-listobject';
            case 'C':
                return 'bpmn-data-input';
            case 'D':
                return 'bpmn-data-output';
            default:
                return '';
        }
    }

    repaintIconBPMN() {
        if (isEmpty(this.itemData)) {
            return;
        }
        let code = this.iconByCode(this.itemData.substr(0, 2));

        if (!isEmpty(code)) {
            let cw = this.boundingRect.width / 8;
            if (cw > this.boundingRect.height / 4) {
                cw = this.boundingRect.height / 3;
            }

            if (code === 'bpmn-data-listobject') {
                // Wir müssen das Icon unten mittig zeichnen
                this.setAttributesBPMN(code,
                    this.boundingRect.left + ((this.boundingRect.width - this.boundingRect.height / 4) / 2),
                    this.boundingRect.top + 3 * (this.boundingRect.height / 4),
                    this.boundingRect.width - (this.boundingRect.width - this.boundingRect.height / 4),
                    this.boundingRect.height / 4)
            }
            else {
                // icon oben mittig
                this.setAttributesBPMN(code,
                    this.boundingRect.left, this.boundingRect.top, this.boundingRect.width - 2 * cw, 2 * cw);
            }
        }

    }
}
