import { SyncImageLoader } from '../../../utils/imageloader';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcCarousel extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcCarousel';
    }

    override initDomElement() {
        super.initDomElement();
        this.obj.querySelectorAll('img').forEach(element => {
            if (element.hasAttribute('data-source')) {
                this.setImageSource(element, element.dataset.source);
                element.removeAttribute('data-source');
            }
        });       
    }

    setImageSource(imageElem: HTMLImageElement, imageSource: string): void {
        SyncImageLoader.instance.addImage(imageElem, imageSource);
    }


    writeProperties(key: string, value: string): void {}
}