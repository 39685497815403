﻿import { WebCompEventHandler } from "../../../core/communication";
import { AsType } from "../../../utils/html";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";

export class TwcGenericInput extends TWebComponent {
    editButton: HTMLElement;
    hasServerChangeEvent: boolean;

    override initComponent() {
        super.initComponent();
        
        this.classtype = 'TwcGenericInput';
        this.editButton = AsType<HTMLButtonElement>(document.getElementById(this.id + '-editBtn'));

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent ?? 'false');
    }

    override initDomElement(): void {
        super.initDomElement();

        this.editButton.addEventListener('click', () => this.handleClickEvent(), false);
    }

    handleClickEvent(): void {
        this.notifyComponentChanged();

        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnEdit', this.id);
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'content':
                document.getElementById(`${this.id}-text`).innerHTML = value;
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'State':
                if (value == '0') {
                    //Disabled
                    $('#' + this.id + '-editBtn').prop('disabled', true);
                    $('#' + this.id + '-editBtn').removeClass('d-none');
                    $('#' + this.id).attr('disabled', 'true');
                } else if (value == '1') {
                    // ReadOnly -> machen wir auch disabled
                    $('#' + this.id + '-editBtn').prop('disabled', true);
                    $('#' + this.id + '-editBtn').addClass('d-none');
                    $('#' + this.id).attr('disabled', 'true');
                } else if (value == '2') {
                    //Enabled
                    $('#' + this.id + '-editBtn').prop('disabled', false);
                    $('#' + this.id + '-editBtn').removeClass('d-none');
                    $('#' + this.id).attr('disabled', 'false');
                }
                break;
        }
    }
}
