import { IWebComponent } from "../../comps/interfaces/class.web.comps.intf";
import { ISupportsActive, supportsActive } from "../../comps/interfaces/supportsActive.intf";
import { assigned } from "../../utils/helper";

/**
 * Gibt einen Boolean zurück, ob eine Komponente zur Zeit aktiv ist, also in einem sichtbaren Komponentenpfad liegt.
 * @param component Komponente, welche geprüft werden soll.
 * @returns Boolean zum Aktiv Status. 
 */
export function isComponentActive(component: IWebComponent): boolean {
    // entweder wissen das die Parent Components
    let currentParentComponent = component.getComponentsPath().head;
    while (assigned(currentParentComponent)) {
        if (supportsActive(currentParentComponent.value) && currentParentComponent.value !== this) {
            let supportsActiveComponent = currentParentComponent.value as unknown as ISupportsActive;

            return supportsActiveComponent.isActive();
        }

        currentParentComponent = currentParentComponent.next;
    }

    // oder wir müssen uns als Aktiv identifizieren
    return true;
}