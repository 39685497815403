import { AsType } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

// 0 = not started
// 1 = active
// 2 = completed

enum WorkflowState {
    NotStarted = 0,
    Active = 1,
    Completed = 2
}


export class TwcWorkflowContextItem extends TWebComponent {
    private captionElement: HTMLParagraphElement;
    private descriptionElement: HTMLSpanElement;
    private iconContainer: HTMLSpanElement;
    private iconElement: HTMLLIElement;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcWorkflowContextItem';

        this.captionElement = AsType<HTMLParagraphElement>(document.getElementById(`${this.id}-caption`));
        this.descriptionElement = AsType<HTMLSpanElement>(document.getElementById(`${this.id}-description`));
        this.iconContainer = AsType<HTMLSpanElement>(document.getElementById(`${this.id}-circle`));
        this.iconElement = AsType<HTMLLIElement>(document.getElementById(`${this.id}-icon`));
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Caption':
                this.captionElement.innerHTML = value;
                break;
            case 'Description':
                this.descriptionElement.innerHTML = value;
                break;
            case 'WFState':
                this.setWorkflowState(parseInt(value));
                break;
        }
    }

    setWorkflowState(newState: WorkflowState): void {
        if (this.iconElement.classList.length > 0) {
            this.iconElement.classList.remove(...this.iconElement.classList.toString().split(' '))
        }

        this.iconContainer.classList.remove('bg-primary', 'bg-success');

        switch (newState) {
            case WorkflowState.NotStarted:
                this.iconElement.classList.add('d-none');
                break;
            case WorkflowState.Active:
                this.iconElement.classList.add('fa-regular', 'fa-angle-double-right');
                this.iconContainer.classList.add('bg-primary');
                break;
            case WorkflowState.Completed:
                this.iconElement.classList.add('fa-light', 'fa-check');
                this.iconContainer.classList.add('bg-success');
                break;
        }

        this.captionElement.classList.toggle('active-state', newState === WorkflowState.Active);
    }
}

export class TwcWorkflowContext extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcWorkflowContext';
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Caption', $(this.obj).val()]);
        return properties;
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Enabled':
                if (value == '1') {
                    $(this.obj).prop('disabled', false);
                } else if (value == '0') {
                    $(this.obj).prop('disabled', true);
                }
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
        }
    }
}