import { isDev } from "../../utils/devUtils";
import { assigned } from "../../utils/helper";

export class LinkedList<T> {
    private _headNode: LinkedNode<T>;
    private _tailNode: LinkedNode<T>;

    public constructor(headValue?: T) {
        if (assigned(headValue)) {
            this.add(headValue);
        }
    }

    public get head(): LinkedNode<T> | null {
        return this._headNode;
    }

    public get tail(): LinkedNode<T> | null {
        return this._tailNode;
    }

    public get size(): number {
        let count = 0;

        let currentNode = this.head;

        while (assigned(currentNode)) {
            count++;
            currentNode = currentNode.next;
        }

        return count;
    }

    public add(value: T): LinkedNode<T> {
        let node = new LinkedNode<T>(value);

        if (assigned(this._tailNode)) {
            this._tailNode._nextNode = node;
            node._previousNode = this._tailNode;

            this._tailNode = node;
        }
        else {
            this._headNode = node;
            this._tailNode = node;
        }

        return node;
    }

    public prepend(value: T): LinkedNode<T> {
        let node = new LinkedNode<T>(value);

        if (assigned(this._headNode)) {
            this._headNode._previousNode = node;
            node._nextNode = this._headNode;

            this._headNode = node;
        }
        else {
            this._headNode = node;
            this._tailNode = node;
        }

        return node;
    }

    public clear(): void {
        this._headNode = null;
        this._tailNode = null;
    }

    /**
     * Logs all values to console.
     * @remarks Only works in developer environment.
     */
    public debugToConsole(): void {
        if (isDev()) {
            let node = this.head;

            while (assigned(node)) {
                console.log(node.value);
                node = node.next;
            }
        }
    }
}

export class LinkedNode<T>{
    /**
     * @deprecated 'Use get property "nextNode" to access the value';
     * @see next
     */
    _nextNode: LinkedNode<T>;
    /**
     * @deprecated 'Use get property "previous" to access the value';
     * @see previous
     */
    _previousNode: LinkedNode<T>;

    public value: T;

    constructor(value: T) {
        this.value = value;
    }

    public get next(): LinkedNode<T> | null {
        return this._nextNode;
    }

    public get previous(): LinkedNode<T> | null {
        return this._previousNode;
    }
}