import 'chart.js';
import { CategoryScale, Chart, ChartType, Filler, LayoutPosition, Legend, LinearScale, LineController, LineElement, Plugin, RadarController, RadialLinearScale, Tooltip, TooltipItem } from 'chart.js';
import * as Utils from '../../../utils/domUtils';
import { assigned } from '../../../utils/helper';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { CustomControllerBar } from './StylePlugin/controller.custom.bar';
import { CustomControllerDoughnut } from './StylePlugin/controller.custom.doughnut';
import { CustomControllerLine } from './StylePlugin/controller.custom.line';
import { CustomControllerPie } from './StylePlugin/controller.custom.pie';
import { CustomControllerPolarArea } from './StylePlugin/controller.custom.polararea';
import { CustomElementArc } from './StylePlugin/element.custom.arc.js';
import { CustomElementBar } from './StylePlugin/element.custom.bar.js';
import { CustomElementLine } from './StylePlugin/element.custom.line';
import { CustomElementPoint } from './StylePlugin/element.custom.point';

// Generelle Charts
// Für Kennzahlencharts ->  comps\charts\class.web.comp.charts 
export class TwcCustomChart extends TRenderWebComponent {
    protected chart: Chart;

    protected chartConfig: Object;

    protected aspectRatio: number = 2;
    protected maintainAspectRatio: boolean = true;
    protected resizeDelay: number = 0;
    protected legendVisible: boolean = true;
    protected legendPosition: LayoutPosition = 'left';

    protected tooltip: 'default' | 'decimal' | 'none' = 'default';
    protected tooltipPostfix: string = '';
    protected tooltipDigits: Number = 0;

    protected useAnimations: boolean = true;

    protected chartController: string;

    // properties
    private p_tld: string;

    override initComponent() {
        super.initComponent();

        // Die ConSense Sprach TLDs unterscheiden sich leicht von den JavaScript TLDs
        // daher verwenden wir hier getter und setter für die tld-Property
        Object.defineProperty(this, "tld", {
            get: function () {
                return this.p_tld;
            },
            set: function (value: string) {
                if (value && value.includes('_')) {
                    this.p_tld = Utils.toJSLocale(value);
                } else {
                    this.p_tld = value;
                }
            },
            enumerable: true,
            configurable: false
        });

        this.chartController = this.obj.dataset.chartcontroller;
        this.chartConfig = csJSONParse(this.obj.dataset?.configuration ?? '{}');

        let canvas = document.createElement('canvas');
        try {
            this.chart = new Chart(canvas, {
                type: this.chartController as any,
                data: this.chartConfig['data'],
                options: this.chartConfig['options'],
            });
            (this.chart.options.plugins as any).csCenterBarText = false;
            (this.chart.options.plugins as any).csCenterDoughnutText = false;
            (this.chart as any).consense = {
                caption: null
            };

            if (this.chartConfig.hasOwnProperty('properties')) {
                this.updateProperties(this.chartConfig['properties']);
            }
        } catch (error) {
            console.log(error);
        }
        this.obj.appendChild(canvas);
    }

    override initDomElement(): void {
        super.initDomElement();

        let observer = new IntersectionObserver(() => {
            this.chart.render();
        });

        observer.observe(this.obj);
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        super.doRender(timestamp);

        this.chart.update();
    }


    updateOptions() {
        this.chart.options.maintainAspectRatio = this.maintainAspectRatio;
        this.chart.options.aspectRatio = this.aspectRatio;
        this.chart.options.resizeDelay = this.resizeDelay;
        this.chart.options.plugins.legend.display = this.legendVisible;
        this.chart.options.plugins.legend.position = this.legendPosition;
    }

    protected updateProperties(props: Object) {
        if (props.hasOwnProperty('tld')) {
            this['tld'] = props['tld'];
        }
        if (props.hasOwnProperty('tooltipDigits')) {
            this.tooltipDigits = props['tooltipDigits'];
        }
        if (props.hasOwnProperty('tooltipPostfix')) {
            this.tooltipPostfix = props['tooltipPostfix'];
        }
        if (props.hasOwnProperty('maintainAspectRatio')) {
            this.maintainAspectRatio = props['maintainAspectRatio'];
        }
        if (props.hasOwnProperty('aspectRatio')) {
            this.aspectRatio = props['aspectRatio'];
        }
        if (props.hasOwnProperty('resizeDelay')) {
            this.resizeDelay = props['resizeDelay'];
        }

        this.useAnimations = props['animations'];
    }

    protected tooltipPosition(): string {
        // Das ist der aktuelle chart.js default
        return 'average';
    }

    protected enableDecimalTooltip() {
        this.setOptions({
            plugins: {
                tooltip: {
                    enabled: true,
                    intersect: true,
                    mode: 'nearest',
                    position: this.tooltipPosition(),
                    callbacks: {
                        title: function (items: TooltipItem<ChartType>[]) {
                            return items[0].label;
                        },

                        label: function (item: TooltipItem<ChartType>) {
                            try {
                                return Number(item.raw).toLocaleString(this.tld, {
                                    minimumFractionDigits: this.tooltipDigits,
                                    maximumFractionDigits: this.tooltipDigits
                                }) + this.tooltipPostfix;
                            } catch (err) {
                                console.log(err);
                            }
                        }.bind(this)
                    }
                }
            }
        });
    }

    protected setOptions(options: Object) {
        Utils.importConfiguration(this.getOptions(), options);
    }

    protected getOptions() {
        return this.chart.options;
    }

    setConfiguration(configuration: Object) {
        if (configuration.hasOwnProperty('properties')) {
            this.updateProperties(configuration['properties']);
        }

        this.setOptions(configuration['options']);
        this.chart.data = configuration['data'];
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.UpdateDataset':
                this.chart.data = csJSONParse(params);
                this.invalidate();
                break;
            case 'Action.UpdateConfiguration':
                this.setConfiguration(csJSONParse(params));
                this.invalidate();
                break;
            case 'Action.UpdateOptions':
                this.setOptions(csJSONParse(params));
                this.invalidate();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    writeProperties(key, value) {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}

export class TwcChartBar extends TwcCustomChart {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcChartBar';
        (this.chart.options.plugins as any).csCenterBarText = true;
    }


    protected override updateProperties(props: Object) {
        super.updateProperties(props);

        if (props.hasOwnProperty('caption'))
            (this.chart as any).consense.caption = props['caption'];
    }

    public static drawCenterText(chart: Chart, args: any, options: any) {
        let txt = (chart as any).consense.caption;
        if (!txt || !assigned(chart.chartArea)) return;

        if (chart.data.datasets.length === 0) {
            //Get ctx from string
            var ctx = chart.ctx;

            //Get options from the center object fin options
            var fontStyle = 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif';
            var color = '#666';

            var sidePadding = 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.chartArea.right - chart.chartArea.left) * 2;

            //Start with a base font of 30px
            ctx.font = '30px ' + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.chartArea.right - chart.chartArea.left - sidePaddingCalculated;
            var elementHeight = chart.chartArea.bottom - chart.chartArea.top;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight, 45); // Die Fontsize soll nie größer als 50px werden                    

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + 'px ' + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }
    }
}

export class TwcChartLine extends TwcChartBar {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcChartLine';
    }

    protected override setOptions(options: object) {
        super.setOptions(options);

        this.chart.data.datasets.forEach((dataset: any) => {
            if (dataset.fill) {
                if (dataset.fill === 'true')
                    dataset.fill = true;
            }
        });
    }
}

export class TwcChartDoughnut extends TwcCustomChart {
    protected cutoutPercentage: number;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcChartDoughnut';
        this.cutoutPercentage = 60;
        (this.chart.options.plugins as any).csCenterDoughnutText = true;
    }

    override updateOptions() {
        this.enableDecimalTooltip();
        this.setOptions({
            cutout: this.cutoutPercentage + '%'
        });

        super.updateOptions();
    }

    protected override tooltipPosition(): string {
        // Tooltip an Mausposition + nur niemals in die Mitte öffnen
        // return 'doughnutCustom';
        return 'average';
    }

    protected override updateProperties(props: Object) {
        super.updateProperties(props);

        if (props.hasOwnProperty('cutoutPercentage')) {
            this.cutoutPercentage = props['cutoutPercentage'];
        }
        if (props.hasOwnProperty('caption')) {
            (this.chart as any).consense.caption = props['caption'];
        }
    }

    public static drawCenterText(chart: Chart, args: any, options: any) {
        let txt = (chart as any).consense.caption;
        if (!txt || !assigned(chart.chartArea)) return;

        //Get ctx from string   
        var ctx = chart.ctx;

        let data = (chart.getDatasetMeta(0).data[0] as any);
        let innerRadius: number;
        // wenn es keine Daten gibt, wollen wir das auch anzeigen
        if (assigned(data)) {
            innerRadius = (chart.getDatasetMeta(0).data[0] as any).innerRadius;
        } else {
            // Padding noch abziehen
            innerRadius = (ctx.canvas.width - 40) / 2;
        }

        //Get options from the center object in options
        var fontStyle = 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif';
        var color = '#666';
        var sidePadding = 20;
        var sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)
        //Start with a base font of 30px
        ctx.font = '30px ' + fontStyle;

        //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        var stringWidth = ctx.measureText(txt).width;
        var elementWidth = (innerRadius * 2) - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        var widthRatio = elementWidth / stringWidth;
        var newFontSize = Math.floor(30 * widthRatio);
        var elementHeight = (innerRadius * 2);

        // Pick a new font size so it will not be larger than the height of label.
        var fontSizeToUse = Math.min(newFontSize, elementHeight, 45); // Die Fontsize soll nie größer als 45px werden                                                                          

        //Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
        ctx.font = fontSizeToUse + 'px ' + fontStyle;
        ctx.fillStyle = color;

        //Draw text in center
        ctx.fillText(txt, centerX, centerY);

        // resize ganz am Ende triggern, da das Doughnut initial zu groß ist
        chart.resize();
        return true;
    };
}

export class TwcChartPie extends TwcCustomChart {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcChartPie';
    }

    override updateOptions() {
        this.enableDecimalTooltip();
        super.updateOptions();
    }
}

export class TwcChartPolarArea extends TwcCustomChart {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcChartPolarArea';
    }

    override updateOptions() {
        this.enableDecimalTooltip();
        this.setOptions({
            scale: {
                ticks: {
                    beginAtZero: true
                }
            }
        });
        super.updateOptions();
    }
}

export class TwcChartRadar extends TwcCustomChart {
    protected UseCustomScale: boolean;
    protected MaxScale: number;
    protected MinScale: number;
    protected StepCount: number;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcChartRadar';

        this.UseCustomScale = false;
        this.MaxScale = 100;
        this.MinScale = 0;
        this.StepCount = 10;
    }

    override writeProperties(key, value) {
        super.writeProperties(key, value);

        switch (key) {
            case 'UseCustomScale':
                this.UseCustomScale = boolFromStr(value);
                break;
            case 'MaxScale':
                this.MaxScale = parseInt(value);
                break;
            case 'MinScale':
                this.MinScale = parseInt(value);
                break;
            case 'StepCount':
                this.StepCount = parseInt(value);
                break;
        }
    }

    protected override updateProperties(props: Object) {
        super.updateProperties(props);

        if (props.hasOwnProperty('UseCustomScale')) {
            this.UseCustomScale = props['UseCustomScale'];
        }
        if (props.hasOwnProperty('MaxScale')) {
            this.MaxScale = parseInt(props['MaxScale']);
        }
        if (props.hasOwnProperty('MinScale')) {
            this.MinScale = parseInt(props['MinScale']);
        }
        if (props.hasOwnProperty('StepCount')) {
            this.StepCount = parseInt(props['StepCount']);
        }
    }

    override updateOptions() {
        this.enableDecimalTooltip();


        this.setOptions({
            scale: {
                ticks: {
                    beginAtZero: true
                }
            }
        });

        if (this.UseCustomScale) {
            this.setOptions({
                scales: {
                    r: {
                        min: this.MinScale,
                        max: this.MaxScale,
                        ticks: {
                            stepSize: (this.MaxScale-this.MinScale)/this.StepCount
                        }
                    }
                }
            });
        }
        super.updateOptions();
    }
}
Chart.register(Filler, LineController, LineElement, Tooltip, Legend, CategoryScale, LinearScale, RadialLinearScale, RadarController,
    CustomControllerBar, CustomControllerPie, CustomControllerDoughnut, CustomControllerPolarArea, LineController, CustomElementBar,
    CustomElementArc, CustomElementLine, CustomElementPoint);

Chart.register([{
    id: 'csCenterBarText',
    afterDraw: TwcChartBar.drawCenterText
} as Plugin,
{
    id: 'csCenterDoughnutText',
    afterDatasetsDraw: TwcChartDoughnut.drawCenterText
} as Plugin
]);

// Typescript kennt positioners nicht, wird aber in der Doku so vorgegeben
// https://www.chartjs.org/docs/3.5.1/configuration/tooltip.html#position-modes
// @ts-ignore
Tooltip.positioners.doughnutCustom = function (elements: any, eventPosition: any) {
    let chart = this._chart;
    let x: number;
    let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);

    // sind wir rechts, dann Tooltip nach rechts öffnen
    if (eventPosition.x > centerX) {
        this.xAlign = 'left';
        x = eventPosition.x + this.width + 10;
    } else if (eventPosition.x < centerX) {
        this.xAlign = 'right';
        x = eventPosition.x - this.width - 10;
    }
    else {
        this.xAlign = 'center';
        x = eventPosition.x - this.width;
    }

    // DropUp/DropDown disablen, da das sonst ggf. in die Mitte springt
    this.yAlign = 'center';

    return {
        x: x,
        y: eventPosition.y
    };
}