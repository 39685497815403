﻿import { WebCompEventHandler } from '../../../core/communication';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcLookupList extends TWebComponent {
    private ActiveItemIndex: number;
    private hasServerChangeEvent: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcLookupList';
        this.ActiveItemIndex = 0;

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent ?? 'false');
    }

    override initDomElement(): void {
        super.initDomElement();
        // Achtung, der klick löst das onchange aus!
        this.obj.querySelectorAll('a').forEach(
            (link: HTMLAnchorElement) => {
                link.addEventListener('click', () => this.handleClickEvent(), false);
            });
    }

    handleClickEvent(): void {
        this.notifyComponentChanged();
    
        let listItemindex = parseInt(this.obj.dataset.itemindex);
        this.setActiveItemIndex(listItemindex);
        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    setActiveItemIndex(set: number) {
        this.ActiveItemIndex = set;
    }

    getActiveItemIndex(): number {
        return this.ActiveItemIndex;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ItemIndex', this.getActiveItemIndex()]);
        return properties;
    }

    refresh(): void {
        // geht wahrscheinlich auch schöner über die ID, so klappt es aber erstmal auch
        // $(this.obj).find('ul li a[data-tabindex="' + this.ActiveTab + '"]').tab('show');
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'ItemIndex':
                this.setActiveItemIndex(parseInt(value));
                //this.refresh();
                break;
        }
    }
}