import { addVector2D, isParallelVector2D, lengthVector2D, multVector2D, normalizeVector2D, overlappingPoints, Point, subVector2D, Vector2D } from "../classes/geometry";

export function getIDSuffix(id: string, suffix: string) {
    return id + '-' + suffix;
}

export const SVGNS = 'http://www.w3.org/2000/svg';

export function svgClear(svg: SVGSVGElement) {
    while (svg.firstChild) {
        svg.removeChild(svg.firstChild);
    }
}

export function svgSetSize(svg: SVGSVGElement, width: number, height: number) {
    svg.setAttribute('width', String(width));
    svg.setAttribute('height', String(height));
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
}

export function svgCreatePathElement(parent: SVGElement, id: string, path: string, color: string) {
    let pathElement = document.createElementNS(SVGNS, 'path');
    pathElement.setAttribute('id', id);
    pathElement.setAttribute('d', path);
    pathElement.setAttribute('stroke', color);
    pathElement.setAttribute('fill', color);
    parent.appendChild(pathElement);
}

export function svgCreateTextElement(parent: SVGElement, id: string, anchor: Point, caption: string, fontFamily: string, fontSize: number, fontColor: string) {
    let textElement = document.createElementNS(SVGNS, 'text');
    textElement.setAttribute('id', id);
    textElement.setAttribute('x', String(anchor.x));
    textElement.setAttribute('y', String(anchor.y));
    textElement.setAttribute('fill', fontColor);
    textElement.innerHTML = caption;
    textElement.style.fontFamily = fontFamily;
    textElement.style.fontSize = fontSize + 'px';
    textElement.style.userSelect = 'none';
    parent.appendChild(textElement);
}

export function svgPolyLine(ptArray: Array<Point>): string {
    let res = '';
    res += `M ${ptArray[0].x},${ptArray[0].y}`;
    for (let i = 1; i < ptArray.length - 2; i++) {
        res += ` L ${ptArray[i].x},${ptArray[i].y}`
    }
    res += ` L ${ptArray[ptArray.length - 1].x},${ptArray[ptArray.length - 1].y}`;
    return res;
}

export function svgRoundPolyLine(ptArray: Array<Point>, cr: number): string {
    let fArr: Array<Point>;
    let oldPt = new Point(Number.MAX_VALUE, Number.MAX_VALUE);
    let a: Point, b: Point, c: Point, e: Point, f: Point;
    let ova: Vector2D, ovb: Vector2D, ovc: Vector2D, ove: Vector2D, ovf: Vector2D, vab: Vector2D, vbc: Vector2D, nva: Vector2D, nvb: Vector2D, sva: Vector2D;

    function copyAndPurgeArray() {
        ptArray.forEach(pt => {
            if (!pt.equals(oldPt)) {
                fArr.push(pt);
                oldPt = pt;
            }
        })
    }

    let res = '';
    fArr = [];

    // Sonderfall
    if (ptArray.length < 3 || cr === 0) {
        return svgPolyLine(ptArray);
    }

    // Doppelte Punkte müssen zwingend entfernt werden
    copyAndPurgeArray();

    // Zu Punkt 1 bewegen
    res += `M ${fArr[0].x},${fArr[0].y}`;

    for (let i = 1; i < fArr.length - 1; i++) {
        // Punkte auslesen
        a = fArr[i - 1];
        b = fArr[i];
        c = fArr[i + 1];

        // Ortsvektoren bestimmen
        ova = a.asVector();
        ovb = b.asVector();
        ovc = c.asVector();

        // Verbindungspunkte berechnen
        vab = subVector2D(ovb, ova);
        vbc = subVector2D(ovc, ovb);

        // Doppeleingabe abfangen
        if (lengthVector2D(vab) === 0 || lengthVector2D(vbc) === 0)
            continue;

        // Gerade Linie
        if (isParallelVector2D(vab, vbc)) {
            res += ` L ${c.x},${c.y}`;
        }

        // Richtungsvektoren normalisieren
        nva = normalizeVector2D(vab);
        nvb = normalizeVector2D(vbc);

        // Senkrechte zu Richtungsvektor A (perpendicular)
        sva = { x1: -nva.x2, x2: nva.x1 };

        // Randpunkte des Bogens bestimmen
        ove = addVector2D(ovb, multVector2D(multVector2D(nva, cr), -1));
        ovf = addVector2D(ovb, multVector2D(nvb, cr));
        e = new Point(ove.x1, ove.x2);
        f = new Point(ovf.x1, ovf.x2);

        // check if overlapping (sonderfall)
        if (overlappingPoints(f, c)) {
            // x-coords
            if (b.x != c.x)
                f.x = b.x - Math.floor((b.x - c.x) / 2);
            else
                f.x = b.x;

            // y-coords
            if (b.y != c.y)
                f.y = b.y - Math.floor((b.y - c.y) / 2);
            else
                f.y = b.y;
        }
        else if (overlappingPoints(e, a)) {
            // x-coords
            if (b.x != a.x)
                e.x = b.x + Math.floor((a.x - b.x) / 2);
            else
                e.x = b.x;

            // y-coords
            if (b.y != a.y)
                e.y = b.y + Math.floor((a.y - b.y) / 2);
            else
                e.y = b.y;
        }

        res += ` L ${e.x},${e.y}`;
        res += ` Q ${b.x},${b.y} ${f.x},${f.y}`;
    }

    // und dstPoint
    res += ` L ${fArr[fArr.length - 1].x},${fArr[fArr.length - 1].y}`;

    return res;
}

export function svgMoveTo(point: Point) {
    return ` M ${point.x}, ${point.y}`;
}

export function svgLineTo(point: Point): string {
    return ` L ${point.x}, ${point.y}`;
}

export function svgClosePath() {
    return ' Z';
}

/**
* @param {Point} center Center of the Arc Area
* @param {number} rInner Inner Radius of Area
* @param {number} rOuter Outer Radius of Area
* @param {number} startAngle startAngle in degree
* @param {number} endAngle endAngle in degree
* @return {string} 
*/
export function svgArcArea(center: Point, rInner: number, rOuter: number, startAngle: number, endAngle: number): string {
    function polarToCartesian(center: Point, radius: number, angleInDegrees: number): Point {
        let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return new Point(center.x + (radius * Math.cos(angleInRadians)), center.y + (radius * Math.sin(angleInRadians)));
    }

    let startInner = polarToCartesian(center, rInner, endAngle);
    let endInner = polarToCartesian(center, rInner, startAngle);
    let startOuter = polarToCartesian(center, rOuter, endAngle);
    let endOuter = polarToCartesian(center, rOuter, startAngle);

    let largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

    let d = [
        'M', startOuter.x, startOuter.y,
        'A', rOuter, rOuter, 0, largeArcFlag, 0, endOuter.x, endOuter.y,
        'L', endInner.x, endInner.y,
        'A', rInner, rInner, 1, largeArcFlag, 1, startInner.x, startInner.y,
        'Z'
    ].join(' ');

    return d;
}