import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { TBaseShapeBPMN } from '../Shapes/TBaseShapeBPMN ';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodeBPMNItemDataAction extends TCustomNodeAction {
    oldItemData: string;
    newItemData: string;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patShapeBPMNItemData;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldItemData = obj.oldItemData;
        this.newItemData = obj.newItemData;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldItemData = this.newItemData;
        obj.newItemData = this.oldItemData;

        return obj;
    }

    setValue(newIcons: string): void {
        this.newItemData = newIcons;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        (this.refElement as TBaseShapeBPMN).itemData = this.newItemData;
        (this.refElement as TBaseShapeBPMN).repaintIconBPMN();
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeBPMNItemDataAction(this.refElement, this.svgEditor);
        result.setValue(this.newItemData);

        return result;
    }
}