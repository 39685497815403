import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";
import { TTutorialShapeCreateAction } from "./TTutorialShapeCreateAction";

export class TTutorialShapeDeleteAction extends TCustomTutorialShapeAction {
    cid: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.cid = undefined;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeDelete;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.cid = obj.cid;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        return obj;
    }

    setValue(cid: number): void {
        this.cid = cid;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.delete();

        this.svgEditor.unregisterObj(this.refElement);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeCreateAction(this.refElement, this.svgEditor);
        result.setValue(this.cid);
        this.svgEditor.registerObj(result.refElement);

        return result;
    }
}