﻿import Color from 'color';
import { RequestIsLocked, sendAjaxRequestGetText, WebCompEventHandlerAsync } from '../../../core/communication';
import { getBaseUrl } from '../../../core/endpoint';
import { assigned } from '../../../utils/helper';
import { AsType } from '../../../utils/html';
import { IOID, parseOid } from '../../../utils/qmutils';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwsProcessViewerSVG extends TWebComponent {
    svg: string;
    ActiveShape: number;
    LastActiveShape: number;
    Zoom: number;
    svgWidth: number;
    svgHeight: number;
    FullOID: IOID;
    zoomButton: HTMLButtonElement;
    zoomSlider: HTMLDivElement;
    zoomIcon: HTMLLIElement;
    backgroundColor: Color;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsProcessViewerSVG';
        this.svg = '';
        this.ActiveShape = 0;
        this.LastActiveShape = -1;
        this.Zoom = parseInt(this.obj.dataset.zoom ?? '100');
        this.ActiveShape = parseInt(this.obj.dataset.activeshape ?? '-1');
        this.FullOID = parseOid(this.obj.dataset.fullOid);
        this.svgWidth = 0;
        this.svgHeight = 0;

        this.zoomButton = AsType<HTMLButtonElement>(this.obj.querySelector(`#${this.id}-zoom-button`));
        this.zoomSlider = AsType<HTMLDivElement>(this.zoomButton.querySelector(`#${this.id}-zoom-slider`));
        this.zoomIcon = AsType<HTMLLIElement>(this.zoomButton.querySelector(`#${this.id}-zoom-icon`));

        this.backgroundColor = new Color(this.obj.dataset.backgroundColor);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initClickHandler();
        this.changeZoom(this.Zoom);

        this.zoomButton.addEventListener('mouseenter', () => this.handleToggleZoom(true));
        this.zoomButton.addEventListener('mouseleave', () => this.handleToggleZoom(false));

        if (assigned(this.FullOID)) {
            this.refreshProcessOid(this.FullOID);
        }
    }

    getClassType(): string {
        return 'TwsProcessViewerSVG';
    }

    handleToggleZoom(isOpen: boolean) {
        this.zoomIcon.classList.toggle('d-none', isOpen);
        this.zoomSlider.classList.toggle('d-none', !isOpen);
    }

    initClickHandler(): void {
        var containerElement = $('#' + this.id + '_processview_container');
        var parent = this;

        containerElement.on('click', function (event) {
            // Ist der Request fertig?
            if (!RequestIsLocked) {
                // wir gucken, ob wir auf den viewer oder den container geklickt haben
                if (event.target.id == this.id || event.target.id == parent.id + '_processviewer' || event.target.id == '0') {
                    parent.setActiveShape(0);
                } else {
                    parent.setActiveShape(parseInt(event.target.id));
                }
                if (!parent.isLastActiveShape()) {
                    // Markierung wechseln
                    $('#' + parent.id + '_processviewer > #process_viewer').children().removeClass('active-shape');
                    if (parent.ActiveShape != 0) {
                        $('#' + event.target.id).addClass('active-shape');
                    }

                    WebCompEventHandlerAsync('OnClick', parent.id, function () { });
                }
            }
        });

        containerElement.on('dblclick', function (event) {
            // Ist der Request fertig?
            if (!RequestIsLocked) {
                if (event.target.id == this.id || event.target.id == parent.id + '_processviewer') {
                    parent.setActiveShape(0);
                } else {
                    parent.setActiveShape(parseInt(event.target.id));
                }

                // Markierung wechseln
                $('#' + parent.id + '_processviewer > #process_viewer').children().removeClass('active-shape');
                if (parent.ActiveShape != 0) {
                    $('#' + event.target.id).addClass('active-shape');
                }

                WebCompEventHandlerAsync('OnDblClick', parent.id, function () { });
            }
        });
    }

    changeZoom(val: number): number {
        this.Zoom = val;
        var zoomFactor = 1;

        if (val > 0) {
            zoomFactor = val / 100
        }

        var svgProcess = $('#' + this.id + '_processviewer > #process_viewer');
        svgProcess.attr('width', this.svgWidth * zoomFactor);
        svgProcess.attr('height', this.svgHeight * zoomFactor);

        return this.Zoom;
    }

    setActiveShape(shapeId: number): void {
        this.LastActiveShape = this.ActiveShape;
        this.ActiveShape = shapeId;
    }

    getActiveShapeId(): number {
        return this.ActiveShape;
    }

    isLastActiveShape(): boolean {
        return this.LastActiveShape == this.ActiveShape;
    }

    setBackgroundColor(): void {
        document.getElementById(this.id + '_processview_container').style.backgroundColor = this.backgroundColor.hex();
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ActiveShape', this.getActiveShapeId()]);
        properties.push([this.id, 'Zoom', this.Zoom]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'LoadProcess':
                let oid = parseOid(value);
                this.refreshProcessOid(oid);
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
            case 'Zoom':
                this.changeZoom(parseInt(value));
                break;
            case 'BackgroundColor':
                this.backgroundColor = new Color(value);
                this.setBackgroundColor();
                break;
        }

    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.RefreshProcess':
                let oid = parseOid(params);
                this.refreshProcessOid(oid);
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    refreshProcessOid(oid: IOID): void {
        this.refreshProcess(oid.OID, oid.Rev);
    }

    /**
     * @deprecated Use refreshProcessOid instead;
     */
    refreshProcess(oid: string, rev: number): void {
        requestAnimationFrame(() => {
            this.setActiveShape(this.ActiveShape);

            this.svg = sendAjaxRequestGetText(getBaseUrl(this.id) + '&data=getSVG&objoid=' + oid + '&objrev=' + rev) as string;
            $('#' + this.id + '_processviewer').html(this.svg);

            var svgProcess = $('#' + this.id + '_processviewer > #process_viewer');
            this.svgWidth = parseInt(svgProcess.attr('width'));
            this.svgHeight = parseInt(svgProcess.attr('height'));

            var backgroundImage = getBaseUrl(this.id) + '&data=getBackgroundImage&objoid=' + oid + '&objrev=' + rev;
            $('#' + this.id + '_processviewer > svg > .background-image').attr('href', backgroundImage);

            // Markierung wechseln
            $('#' + this.id + '_processviewer > #process_viewer').children().removeClass('active-shape');
            if (this.ActiveShape != 0) {
                $('#' + this.ActiveShape).addClass('active-shape');
            }

            this.changeZoom(this.Zoom);
            this.initClickHandler();
            this.setBackgroundColor();
        });
    }
}