import {canUseDispatcher} from '../dispatcher';
import {getParents} from '../../utils/html';
import {CSEndpointGuid} from "../endpoint";
import {assigned} from '../../utils/helper';

/*------------------------------------------*/
/* POPOVER **/
/*------------------------------------------*/
export function showPopoverCS(target) {
    showPopoverByUrl(target, target.dataset.contenturl, 'cs-popover');
}
window[showPopoverCS.name] = showPopoverCS;

export function showPopoverForUser(target, key) {
    showPopoverByUrl(target, `User/Details.Popup?oid=${key}`, 'cs-popover');
}
window[showPopoverForUser.name] = showPopoverForUser;

export function showPopoverForGroup(target, key) {
    showPopoverByUrl(target, `Group/Details.Popup?oid=${key}&guid=${CSEndpointGuid}`, 'cs-popover');
}
window[showPopoverForGroup.name] = showPopoverForGroup;

export function showPopoverByUrl(target, url, cssClass) {
    let a = $(target);
    // OnClick wird normalerweise entfernt...
    // Laut Doku ist dieser Code richtig und wird so vorgeschlagen: https://getbootstrap.com/docs/4.5/getting-started/javascript/#sanitizer
    // Von der Typisierung passt das für TypeScript aber leider nicht, weshalb wir hier bei JS bleiben...
    let allowList = $.fn.tooltip.Constructor.Default.allowList;
    allowList['a'].push('onclick');
    allowList['a'].push('TESTING-TAG');
    allowList['*'].push(/^data-[\w]+/);
    
    if (url.trim() != '' && canUseDispatcher()) {
        // Event nachdem Popover im DOM, aber noch nicht sichtbar
        // wir wollen die Popover immer ÜBER dem ParentModal haben
        a.on('inserted.bs.popover', function () {
            // gibt es überhaupt ein sichtbares Modal?
            if (!assigned(document.querySelector('.modal.show'))) {
                // Nein? dann raus und den Rest sparen wir uns
                return;
            }

            // ok, dann das direke ParentModal ermitteln
            let parents = getParents(target);

            for (let i = 0; i < parents.length; i++) {
                // haben wir unser Modal?
                if (parents[i]?.classList?.contains('modal')) {
                    // dann setzen wir unseren z-Index neu!
                    document.getElementById(target.getAttribute('aria-describedby')).style.zIndex = parseInt(parents[i].style.zIndex) + 1;
                    break;
                }
            }
        });

        const isScrollable = function(element) {
            if (element.clientHeight > 0) {
                const hasScrollableContent = element.scrollHeight > element.clientHeight;
                const isDiv = element.nodeName == 'DIV';

                const overflowYStyle = window.getComputedStyle(element).overflowY;
                const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

                return hasScrollableContent && isDiv && !isOverflowHidden;
            }
        };

        const getScrollableParent = function(element) {
            element = element.parentNode;
            if (element) {
                if (isScrollable(element)) return element;

                return getScrollableParent(element);
            } else {
                return null;
            }
        };

        $.get(url, function(d) {
            let scrollContainer = getScrollableParent(target);

            if (scrollContainer) {
                const hidePopover = (e) => {
                    a.popover('dispose');
                    sidscrollContainereBar.removeEventListener('scroll', hidePopover);

                    if (scrollContainer == document.body) {
                        document.removeEventListener('scroll', hidePopover);
                    }
                };

                scrollContainer.addEventListener('scroll', hidePopover);

                if (scrollContainer == document.body) {
                    document.addEventListener('scroll', hidePopover);
                }
            }

            a.popover({
                content: d,
                html: true,
                placement: 'top',
                container: 'body',
                template: '<div class="popover ' + cssClass + '" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                trigger: 'manual',
                allowList: allowList,
                boundary: 'window'
            }).on('focusout', function() {
                var _this = this;

                // wir hovern noch über dem Popup? z.B. Klick auf dem Popup
                if ($('.popover:hover').length) {
                    // dann adden wir ein Mouseleave Event, sodass sich das Popup schließt sobald die Maus nicht mehr über dem Popup hovert
                    $('.popover').mouseleave(function() {
                        // schließen
                        $(_this).popover('hide');
                        // aufräumen
                        $(this).off('mouseleave');
                    });
                    $('.popover').click(function(event) {
                        // wir haben auf den Link oder das Icon geklickt
                        if ($(event.target).hasClass('popover-link') || $(event.target).parents('.popover-link').length) {
                            // Popover entfernen
                            this.remove();
                            // aufräumen
                            $(this).off('click');
                        }
                    })
                }
                else {
                    // sonst können wir es direkt schließen
                    $(this).popover('hide');
                }
            }).popover('show');
        });
    }
}
window[showPopoverByUrl.name] = showPopoverByUrl;