/*------------------------------------------*/
/* ID Utils  **/
/*------------------------------------------*/


export function addID(ids: string, id: number): string {
    if (ids == '')
        ids = ';';

    ids = ids + String(id) + ';';

    return ids;
}

export function removeID(ids: string, id: number): string {
    return ids.replace(';' + String(id) + ';', ';');
}

export function hasID(ids: string, id: number): boolean {
    return ids.indexOf(';' + String(id) + ';') >= 0;
}