import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { getTutorialObjByCID } from "../Utils/TTutorialEditorShapeHandling";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";
import { TTutorialShapeDeleteAction } from "./TTutorialShapeDeleteAction";


export class TTutorialShapeCreateAction extends TCustomTutorialShapeAction {
    cid: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.cid = undefined;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeCreate;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.cid = obj.cid;

        this.refElement = getTutorialObjByCID(this.cid, this.svgEditor);
        this.refElement.id = obj.refElementID;

        this.svgEditor.registerObj(this.refElement);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        return obj;
    }

    setValue(cid: number): void {
        this.cid = cid;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.create();
        this.svgEditor.registerObj(this.refElement);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeDeleteAction(this.refElement, this.svgEditor);
        result.setValue(this.cid);
        this.svgEditor.unregisterObj(result.refElement);

        return result;
    }
}