﻿import { WebCompProcessingModeEventHandler } from '../../../core/communication';
import removeBootstrapStyles from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProcessingMode, ILoadingSpinner, ISupportsProcessingMode } from '../../interfaces/processingMode';

// LoadingSpinner INTF
export class TwcCustomButton extends TWebComponent implements ILoadingSpinner, ISupportsProcessingMode {
    private isClickLocked: boolean;
    Caption: string;
    Content: string;
    clickTimer: number;

    processingModeDiscriminator: 'I-SUPPORTS-PROCESSINGMODE' = 'I-SUPPORTS-PROCESSINGMODE';
    ProcessingMode: ComponentProcessingMode;

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcCustomButton';
        this.ProcessingMode = this.obj.dataset?.processingmode as unknown as ComponentProcessingMode ?? ComponentProcessingMode.TProcessingModeNone; // 0 ist Default
        this.Caption = document.getElementById(`${this.id}-caption`).innerHTML;
        this.Content = this.obj.innerHTML;

        // Click Handler
        this.isClickLocked = false;

        this.obj.addEventListener('click', () => this.handleClickEvent());
    }

    handleClickEvent(): void {
        if (this.isClickLocked) {
            console.debug('Fast multi click discarded!')
            return;
        }

        this.isClickLocked = true;

        // Timer für ClickUnlock setzen
        this.clickTimer = window.setTimeout(() => {
            this.isClickLocked = false;
        }, 500); // 500 ms ist der Standard in Windows für Doppelklicks, siehe https://docs.microsoft.com/de-de/windows/win32/controls/ttm-setdelaytime?redirectedfrom=MSDN

        WebCompProcessingModeEventHandler('OnClick', this.id);
    }

    showLoadingSpinnerPromise(): Promise<string> {
        return new Promise(function (resolve, reject) {
            $('#' + this.id).contents().fadeOut(10);
            let htmlContent = `<span id="${this.id}_spinner" style="display:none;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span id="${this.id}-caption">${this.Caption}</span></span>`;

            $('#' + this.id).html(htmlContent);
            $('#' + this.id + '_spinner').fadeIn(100, function showNext() {
                resolve('Loading Spinner shown');
            });
        }.bind(this));
    }

    hideLoadingSpinner(): void {
        // zurücksetzen
        let spinner = document.getElementById(`${this.id}_spinner`);
        if (assigned(spinner)) {
            spinner.style.display = 'none';
        }
        this.obj.innerHTML = this.Content;
        // hat sich mittlerweile die Caption geändert?
        let caption = document.getElementById(`${this.id}-caption`);
        if (assigned(caption)) {
            caption.innerHTML = this.Caption;
        }
    }

    setProcessingMode(value: ComponentProcessingMode) {
        // Property anpassen
        this.ProcessingMode = value;
        // data-Attribut anpassen
        this.obj.dataset.processingmode = value as unknown as string;
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Caption':
                this.Caption = value;
                let captionElement = document.getElementById(`${this.id}-caption`);
                if (assigned(captionElement)) {
                    captionElement.innerHTML = value;
                }
                break;
            case 'Hint':
                if (value)
                    this.obj.setAttribute('title', value);
                else
                    this.obj.removeAttribute('title');
                break;
            case 'ProcessingMode':
                this.setProcessingMode(value as unknown as ComponentProcessingMode);
                break;
            case 'Style':
                // alle alten styles löschen
                removeBootstrapStyles(this.obj, 'btn-');
                // ups: da wir nichht wissen, ob wir outline sind, löschen wir die auch direkt
                removeBootstrapStyles(this.obj, 'btn-outline-');
                // und den neuen style setzen, sofern vorhanden
                if (value.trim() != '') {
                    this.obj.classList.add(value.trim());
                }
                break;
            case 'Enabled':
                if (!boolFromStr(value)) {
                    AsType<HTMLButtonElement>(this.obj).disabled = true;
                } else {
                    // Attribut false setzen reicht nicht, das muss komplett gelöscht werden!
                    this.obj.removeAttribute('disabled');
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
            case 'CheckedMenuItem':
                this.obj.classList.toggle('checked-item', boolFromStr(value))
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Spinner.Show':
                this.showLoadingSpinnerPromise();
                break;
            case 'Spinner.Hide':
                this.hideLoadingSpinner();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}
/*------------------------------------------*/
export class TwcCustomURLButton extends TwcCustomButton {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcCustomURLButton';
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                if (!boolFromStr(value)) {
                    AsType<HTMLButtonElement>(this.obj).disabled = true;
                } else {
                    // Attribut false setzen reicht nicht, das muss komplett gelöscht werden!
                    this.obj.removeAttribute('disabled');
                }

                this.obj.classList.toggle('disabled', value === '0');
                this.obj.setAttribute('aria-disabled', String(value === '0'));
                break;
            default:
                super.writeProperties(key, value);
        }
    }
}
/*------------------------------------------*/
export class TwcIconButton extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcIconButton';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                if (!boolFromStr(value)) {
                    this.obj.setAttribute('disabled', 'disabled');
                }
                else {
                    // Attribut false setzen reicht nicht, das muss komplett gelöscht werden!
                    this.obj.removeAttribute('disabled');
                }

                this.obj.classList.toggle('disabled', value === '0');
                this.obj.setAttribute('aria-disabled', String(value === '0'));
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
        }
    }
}

export class TwcDropDownButton extends TwcCustomButton {
    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcDropDownButton';
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                if (!boolFromStr(value)) {
                    AsType<HTMLButtonElement>(this.obj).disabled = true;
                }
                else {
                    // Attribut false setzen reicht nicht, das muss komplett gelöscht werden!
                    this.obj.removeAttribute('disabled');
                }

                // Button liegt in einem Div..
                let buttonDiv = document.getElementById(`${this.id}-div`);
                if (!boolFromStr(value)) {
                    AsType<HTMLButtonElement>(buttonDiv).disabled = true;
                }
                else {
                    // Attribut false setzen reicht nicht, das muss komplett gelöscht werden!
                    buttonDiv.removeAttribute('disabled');
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                // Button liegt in einem Div..
                document.getElementById(`${this.id}-div`).classList.toggle('d-none', value === '0');
                break;
            default:
                super.writeProperties(key, value);
        }
    }
}
