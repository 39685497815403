import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcLink extends TWebComponent {
    linkObj: HTMLLinkElement;
    disabled: boolean;
    hasOnClick: boolean;
    URL: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcLink';
        this.linkObj = AsType<HTMLLinkElement>(this.obj);
        this.disabled = this.linkObj.hasAttribute('disabled');
        this.hasOnClick = boolFromStr(this.linkObj.dataset?.hasonclick ?? 'false');
        this.URL = this.linkObj.dataset?.url ?? '';
    }

    override initDomElement(): void {
        super.initDomElement();
        this.linkObj.addEventListener('click', e => this.handleOnClick(e));
        this.linkObj.href = this.URL;
    }

    handleOnClick(e: Event): void {
        // wir sind disabled oder haben keine URL, dann passiert auch nichts..
        if (this.disabled || (this.URL === '#' && !this.hasOnClick)) {
            e.preventDefault();
            return;
        }

        // wir haben also ein Click Event
        if (this.hasOnClick) {
            e.preventDefault();
            // Sonderfall: wenn wir gleichzeitg ne URL und ein OnClick haben, dann öffnen wir die URL im neuen Tab...
            if (this.URL !== '#') {
                window.open(this.URL, '_blank');
            }
            WebCompEventHandler('OnClick', this.id);

            return;
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            //			case "Caption":
            //				$(this.obj).text(value);
            //				break;
            case 'Enabled':
                this.disabled = !boolFromStr(value);
                if (this.disabled) {
                    this.linkObj.setAttribute('disabled', '');
                } else {
                    this.linkObj.removeAttribute('disabled');
                }
                this.linkObj.style.pointerEvents = this.disabled ? 'none' : 'auto';
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                this.linkObj.style.pointerEvents = !boolFromStr(value) ? 'none' : 'auto';
                break;
        }
    }
}