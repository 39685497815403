import imagesLoaded from 'imagesloaded';
import InfiniteScroll from 'infinite-scroll';
// https://infinite-scroll.com/extras.html#webpack-browserify
import Masonry from 'masonry-layout';
import { getBaseUrl } from '../../../core/endpoint';
import { assigned } from '../../../utils/helper';
import { getParentByClassname } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcMasonryInfinite extends TWebComponent {
	IsInitialized: boolean;
	masonry: Masonry;
	infScroll: any;

	override initComponent() {
		super.initComponent();
		this.classtype = 'TwcMasonryInfinite';
		this.IsInitialized = false;
	};

	override initDomElement() {
		super.initDomElement();
		new IntersectionObserver(entries => {
			entries.forEach(entry => {
				// sind wir sichtbar? Dann können wir laden! 
				if (entry.intersectionRatio > 0) {
					this.initMasonry();
				};
			});
		}).observe(this.obj);
	}

	initMasonry(): void {
		// wir dürfen uns nur einmal initialisieren
		if (this.IsInitialized) {
			return;
		}

		// make imagesLoaded available for InfiniteScroll
		InfiniteScroll.imagesLoaded = imagesLoaded;

		let container = document.querySelector('#' + this.obj.dataset.compid);

		// https://masonry.desandro.com/options.html
		this.masonry = new Masonry(container, {
			itemSelector: '.masonry-item', // select none at first
			columnWidth: '.masonry-grid__col-sizer',
			//gutter: '.masonry-grid__gutter-sizer', // UPS // gutter ist doppelt definiert, welches ist richtig?
			percentPosition: true,
			gutter: 24,
			stagger: 30, // Typdefinition muss aktualisiert werden, PR ist offen -> https://github.com/DefinitelyTyped/DefinitelyTyped/pull/48951
			// nicer reveal transition
			visibleStyle: { transform: 'translateY(0)', opacity: 1 },
			hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
		});

		let eleScroll: string | boolean;
		// wenn wir in der page-sidebar liegen, dann müssen wir unseren ScrollContainer anpassen,
		// da sonst das loadOnScroll nicht klappt
		if (assigned(getParentByClassname(this.obj, 'page-sidebar'))) {
			eleScroll = '.sidebar-section';
		} else {
			eleScroll = false;
		}

		// https://infinite-scroll.com/options.html
		this.infScroll = new InfiniteScroll(container, {
			path: getBaseUrl(this.obj.dataset.compid) + '&page={{#}}',
			append: '.masonry-item',
			status: '.masonry-scroll-page-load-status',
			checkLastPage: '.masonry-item',
			responseBody: 'text',
			outlayer: this.masonry,
			loadOnScroll: true,
			history: false,
			historyTitle: false,
			prefill: true,
			elementScroll: eleScroll
		});
		// this.infScroll.loadNextPage();	
		this.IsInitialized = true;

		let self = this;
		container.querySelectorAll('img').forEach(element => {
			element.addEventListener('load', () => {
				self.masonry.layout();
			})
		});

		this.infScroll.on('append', function (body, path, items, respons) {
			items.forEach(item => {
				item.querySelectorAll('img').forEach(element => {
					element.addEventListener('load', () => {
						self.masonry.layout();
					})
				});
			});
		});
	}

	writeProperties(key: string, value: string): void {
		// nothing
	}
}