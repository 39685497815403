import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { getTutorialActionByTypeID } from "../Utils/TTutorialEditorActionHandling";
import { TCustomTutorialEditorAction, TutorialActionType } from "./TTutorialEditorAction";

export class TTutorialGroupAction extends TCustomTutorialEditorAction<any> {
    actions: Array<TCustomTutorialEditorAction<any>>;

    // wir gehen hier von null aus für das refElement
    constructor(refElement: any, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.actions = [];
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatGroupAction;
    }

    override fromJSON(obj: any) {
        super.fromJSON(obj);

        obj.actions.forEach(action => {
            let actionObj = getTutorialActionByTypeID(action.actionType, this.svgEditor.objectById(action.refElementID), this.svgEditor);
            actionObj.fromJSON(action);
            this.actions.push(actionObj);
        });
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.actions = [];
        this.actions.forEach(action => {
            obj.actions.push(action.toJSON());
        });

        return obj;
    }

    setValue(...args: Array<TCustomTutorialEditorAction<any>>): void {
        this.actions = args;
    }

    performAction(): void {
        this.actions.forEach(action => {
            action.performAction();
        });
    }

    inverseAction(): TCustomTutorialEditorAction<any> {
        let result = new TTutorialGroupAction(this.refElement, this.svgEditor);
        let inverseActions: Array<TCustomTutorialEditorAction<any>> = [];

        [...this.actions].reverse().forEach(action => {
            inverseActions.push(action.inverseAction());
        });

        result.setValue(...inverseActions);
        return result;
    }
}