import { TWebComponent } from '../../base/class.web.comps';

export class TwcDateInterval extends TWebComponent {

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcDateInterval';
    }

    override initDomElement(): void {
        super.initDomElement();
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    writeProperties(key: string, value: string): void {

        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }
}