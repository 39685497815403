import { TWebComponent } from '../../base/class.web.comps';

export class TwsSmartPropertyList extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwsSmartPropertyList';
    }

    writeProperties(key: string, value: string): void {
        //
    }

};