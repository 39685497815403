import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShapeBPMN } from './TBaseShapeBPMN ';

export class TShapeBPMNDataStore extends TBaseShapeBPMN {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 980;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let w2 = this.boundingRect.width / 2;
        let wc = this.boundingRect.height / 15;

        let path = [
            'M', this.boundingRect.left, this.boundingRect.top + wc,
            'a', w2, ',', wc, 0, 1, ',', 0, this.boundingRect.width, 0,
            'a', w2, ',', wc, 0, 1, ',', 0, -this.boundingRect.width, 0,
            'v', this.boundingRect.height - (2 * wc),
            'a', w2, ',', wc, 0, 1, ',', 0, this.boundingRect.width, 0,
            'v', -(this.boundingRect.height - (2 * wc))
        ].join(' ');

        return path;
    }

    repaintIconBPMN() {
        // hat keine Icons
    }
}