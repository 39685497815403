import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcOverlayEmpty extends TWebComponent {
    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcOverlayEmpty';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}