import { WebCompEventHandlerAsync } from "../../../core/communication";
import { MessageType, MsgNotify } from "../../../core/utils/msgnotify";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";

export class TwcEmbed extends TWebComponent {
    lazyload: boolean;
    filename: string;
    objectType: string;
    objElement: HTMLObjectElement;
    isMobile: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcEmbed';

        this.lazyload = boolFromStr(this.obj.dataset.lazyload);
        this.filename = this.obj.dataset.filename;
        this.objectType = this.obj.dataset.objecttype;
        this.isMobile = boolFromStr(this.obj.dataset.ismobile);
    }

    override initDomElement() {
        super.initDomElement();

        this.objElement = document.createElement('object');
        this.objElement.id = `${this.id}-obj`;
        this.objElement.classList.add('object-embedded');
        this.objElement.addEventListener('error', (event) => this.handleLoadFailedEvent(event));

        this.objElement.addEventListener('load', event => {
            WebCompEventHandlerAsync('contentLoaded', this.id);
        });

        this.initData();
    }

    protected handleLoadFailedEvent(event) {
        MsgNotify('Das Element konnte nicht geladen werden!', MessageType.Danger);
    }

    initData() {
        if (this.isMobile)
            return;

        if (!this.filename)
            return;

        this.objElement.setAttribute('type', this.objectType);
        this.objElement.setAttribute('data', this.filename);

        // wenn Lazyload, dann laden wir den Inhalt erst, wenn wir sichtbar werden
        if (this.lazyload) {
            new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    // sind wir sichtbar?
                    if (entry.intersectionRatio > 0) {
                        this.obj.appendChild(this.objElement);
                    };
                });
            }).observe(this.obj);
        }
        else {
            this.obj.appendChild(this.objElement);
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }
}
