import { showPopoverForGroup, showPopoverForUser } from '../../../core/utils/popover';
import { assigned } from '../../../utils/helper';
import { TWebComponent } from '../../base/class.web.comps';

export class TwsPreviewFroala extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwsPreviewFroala';
        this.modifyHTML();
    }

    modifyHTML(): void {

        function modifyHyperlinks(parent: HTMLElement) {
            if (!assigned(parent)) {
                return;
            }
            parent.querySelectorAll('a').forEach(function (element: HTMLElement) {
                if (element.classList.contains('user-group-link')) {
                    if (element.dataset.key.indexOf('U') === 0) {
                        element.onclick = function () { showPopoverForUser(element, element.dataset.key); return false; };
                    }
                    else if (element.dataset.key.indexOf('G') === 0) {
                        element.onclick = function () { showPopoverForGroup(element, element.dataset.key); return false; };
                    }
                }
                else {
                    // jeder A-Tag bekommt ein target=_blank
                    element.setAttribute('target', '_blank');
                }
            });
        }

        modifyHyperlinks(this.obj);
        // ggf. liegt unser Content nicht unter this.obj, sondern wo anders (vgl. Wiki View)
        modifyHyperlinks(document.querySelector('[data-frviewid="' + this.id + '"]'));

    }


    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'html':
                this.obj.innerHTML = value;
                // und nochmal die links aktualisieren
                this.modifyHTML();
                break;
        }
    }
}