import { TWebComponent } from '../../base/class.web.comps';

export class TwcZoomDiv extends TWebComponent {
    ZoomDiv: JQuery<HTMLElement>;
    ZoomDivToolbar: JQuery<HTMLElement>;
    zoom: boolean;

    constructor(obj: HTMLElement) {
        super(obj);
        this.ZoomDiv = $('#' + this.id);
        this.ZoomDivToolbar = $('#' + this.id + '-toolbar');
        this.classtype = 'TwcZoomDiv';
        this.zoom = false;
    }

    zoomDiv(): void {
        if (this.zoom) {
            this.ZoomDiv.addClass('panel-fullscreen');
            this.ZoomDivToolbar.removeClass('d-none');
        } else {
            this.ZoomDiv.removeClass('panel-fullscreen');
            this.ZoomDivToolbar.addClass('d-none');
        }
        // Wir müssen, wenn im parent ein Element mit der css - Klasse "page-sidebar"
        // vorhanden ist, diese temporär switchen
        if (this.ZoomDiv.hasClass('panel-fullscreen')) {
            // marker setzen, damit wir uns das Element merken, welches wir später zurückwandeln
            this.ZoomDiv.closest('.page-sidebar').addClass('cs-fullscreenmarker').toggleClass('page-sidebar page-inner');
        } else {
            // marker entfernen
            this.ZoomDiv.closest('.cs-fullscreenmarker').removeClass('cs-fullscreenmarker').toggleClass('page-sidebar page-inner');
        }
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Zoom':
                this.zoom = (value == '1' ? true : false);
                this.zoomDiv();
                break;
        }
    }
}