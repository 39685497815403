import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodePositionAction extends TCustomNodeAction {
    dx: number;
    dy: number;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patShapePosition;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.dx = obj.dx;
        this.dy = obj.dy;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.dx = Math.round(this.dx);
        obj.dy = Math.round(this.dy);

        return obj;
    }

    setValue(dx: number, dy: number): void {
        this.dx = Math.round(dx);
        this.dy = Math.round(dy);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setNewDraftPosition(this.refElement.x + this.dx, this.refElement.y + this.dy, true);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodePositionAction(this.refElement, this.svgEditor);
        result.setValue(-this.dx, -this.dy);

        return result;
    }
}
