import { assigned } from '../../../../utils/helper';
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';
import { TBaseTutorialShape } from '../Shapes/TBaseTutorialShape';
import { TCustomTutorialShapeAction, TutorialActionType } from './TTutorialEditorAction';

export class TTutorialShapeTextContentAction extends TCustomTutorialShapeAction {
    oldContent: string;
    newContent: string;
    oldDisplayedText: string;
    newDisplayedText: string;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeTextContent;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldContent = obj.oldContent;
        this.newContent = obj.newContent;
        this.oldDisplayedText = obj.oldDisplayedText;
        this.newDisplayedText = obj.newDisplayedText;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldContent = this.oldContent;
        obj.newContent = this.newContent;

        return obj;
    }

    setValue(newContent: string, newDisplayedText: string): void {
        if (assigned(this.refElement)) {
            this.oldContent = this.refElement.getTextContent();
            this.oldDisplayedText = this.refElement.getDisplayedTextContent();
        }
        else {
            this.oldContent = '';
            this.oldDisplayedText = '';
        }

        this.newContent = newContent;
        this.newDisplayedText = newDisplayedText;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setTextContent(this.newContent);
        this.refElement.setDisplayedTextContent(this.newDisplayedText);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeTextContentAction(this.refElement, this.svgEditor);
        result.setValue(this.oldContent, this.oldDisplayedText);

        return result;
    }
}
