import { TWebComponent } from "../../base/class.web.comps";

export class TwcBackgroundTask extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcBackgroundTask';
    }

    override initDomElement() {
        super.initDomElement();
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            // do nothing
        }
    }
}