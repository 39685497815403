import bootstrap from 'bootstrap';
import UAParser from 'ua-parser-js';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent, TWebComponent } from '../../base/class.web.comps';

export class TwcFullPage extends TRenderWebComponent {

    isWorking: boolean;
    touchStartClientY: number;
    useNavigation: boolean;
    useScrollingByNumbers: boolean;

    documentWheelEvent: (e: WheelEvent) => void;
    documentMouseEvent: (e: MouseEvent) => void;
    documentKeyEvent: (e: KeyboardEvent) => void;
    documentTouchStartEvent: (e: TouchEvent) => void;
    documentTouchEndEvent: (e: TouchEvent) => void;
    documentTouchMoveEvent: (e: TouchEvent) => void;

    documentResizeEvent: () => void;
    private resizeObserver: ResizeObserver;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcFullPage';

        this.isWorking = false;

        this.touchStartClientY = 0;
        
        this.useNavigation = boolFromStr(this.obj.dataset.usenavigation);

        // bei f5 wollen wir nich irgendwo im Raum stehen sondern immer oben
        // alternativ
        // window.onbeforeunload = function () { window.scrollTo(0, 0); }
        history.scrollRestoration = 'manual';

        this.documentWheelEvent = e => this.handleWheelEvent(e);
        this.documentKeyEvent = e => this.handleKeyEvent(e);
        this.documentMouseEvent = e => this.handleMouseEvent(e);
        this.documentTouchStartEvent = e => this.handleTouchStartEvent(e);
        this.documentTouchEndEvent = e => this.handleTouchEndEvent(e);
        this.documentTouchMoveEvent = e => this.handleTouchMoveEvent(e);

        this.documentResizeEvent = () => this.handleResizeEvent;
        this.useScrollingByNumbers = this.obj.querySelector('.fullpage-sections').children.length <= 10;
    }

    override initDomElement() {
        super.initDomElement();
        if (this.obj.childElementCount == 0) {
            throw 'no pages found';
        }

        this.initNavigation();
        this.initPages();
        this.initEventListener();
    }

    initEventListener(): void {
        this.obj.addEventListener('wheel', this.documentWheelEvent, false);
        document.addEventListener('keydown', this.documentKeyEvent, false);
        document.addEventListener('mousedown', this.documentMouseEvent, false);
        document.addEventListener('touchstart', this.documentTouchStartEvent, false);
        document.addEventListener('touchmove', this.documentTouchMoveEvent, { passive: false });
        document.addEventListener('touchend', this.documentTouchEndEvent, false);

        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                this.invalidate();
            });
        });
        this.resizeObserver.observe(this.obj);
    }

    initPages(): void {
        let pages = this.obj.querySelector('.fullpage-sections').children;
        for (var i = 0; i < pages.length; i++) {
            pages[i].setAttribute('data-index', `${i}`);
        }
        // initial geben wir mal dem ersten Element eine .active Css Klasse!
        let firstElement = this.obj.querySelector('.fullpage-sections').children[0] as HTMLElement;
        this.setAsActive(firstElement);
    }

    initNavigation(): void {
        if (!this.useNavigation) {
            return;
        }

        let fullPageNavi = document.createElement('div');
        fullPageNavi.classList.add('fullpage-navi');
        if (['mobile', 'tablet'].includes(new UAParser().getDevice().type)) {
            if (['mobile'].includes(new UAParser().getDevice().type)) {
                fullPageNavi.classList.add('mobile');
            }
            fullPageNavi.classList.add('fullpage-navi-touch');
        }
        let naviContainer = document.createElement('div');
        naviContainer.classList.add('fullpage-navicontainer');
        let ul = document.createElement('ul');

        let pages = this.obj.querySelector('.fullpage-sections').children;
        for (var i = 0; i < pages.length; i++) {
            let span = document.createElement('span');
            span.classList.add('fa-solid', 'fa-circle');
            span.setAttribute('data-index', `${i}`);
            
            let li = document.createElement('li');
            li.setAttribute('data-index', `${i}`);
            li.setAttribute('data-bs-toggle', 'tooltip');
            li.setAttribute('data-bs-placement', 'left');
            li.setAttribute('title', (pages[i] as HTMLElement).dataset.title);
            li.addEventListener('click', e => {
                this.clickNavigation(e);
            });
            li.appendChild(span);
            ul.appendChild(li);
        }

        naviContainer.appendChild(ul);
        fullPageNavi.appendChild(naviContainer);
        this.obj.appendChild(fullPageNavi);

        this.initNavigationTooltip();
    }

    initNavigationTooltip(): void {
        if (!this.useNavigation) {
            return;
        }
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('.fullpage-navicontainer [data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            window.addEventListener('scroll', (e) => {
                let tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
                tooltip.hide();
            });
        });
    }

    clickNavigation(event: MouseEvent) {
        let index = (event.target as HTMLElement)?.dataset?.index;
        let element = this.obj.querySelector('.fullpage-sections').children[index] as HTMLElement;
        this.scrollToElement(element);
    }

    updateNavigation() {
        if (!this.useNavigation) {
            return;
        }
        let activeNavi = document.querySelector('.fullpage-navi .active');
        activeNavi?.classList.remove('active');
        let activePage = document.querySelector('.fullpage-section.active') as HTMLElement;
        let index = activePage?.dataset?.index;
        document.querySelector(`.fullpage-navi [data-index="${index}"]`)?.classList.add('active');
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        this.handleResizeEvent();
    }

    eventPossible(event: UIEvent): boolean {
        let result = !this.isWorking;
        event.preventDefault();
        this.isWorking = true;
        return result;
    }

    handleResizeEvent(): void {
        this.isWorking = true;
        let activePage = document.querySelector('.fullpage-section.active');
        this.scrollToElement(activePage);
    }

    handleTouchStartEvent(event: TouchEvent): void {
        if (this.isWorking) {
            return;
        }
        this.touchStartClientY = event.touches[0].clientY;
    }

    handleTouchMoveEvent(event: TouchEvent): void {
        event.preventDefault();
    }

    handleTouchEndEvent(event: TouchEvent): void {
        let touchEndClientY = event.changedTouches[0].clientY;
        
        const aToleranz = 5;
        // wir haben den finger nach OBEN bewegt und wollen RUNTER scrollen
        if (this.touchStartClientY > touchEndClientY) {
            if (this.touchStartClientY > touchEndClientY + aToleranz) {
                if (this.eventPossible(event)) {
                    this.scrollNext();
                }
            }
            // wir haben den finger nach UNTEN bewegt und wollen HOCH scrollen
        } else if (this.touchStartClientY < touchEndClientY) {
            if (this.touchStartClientY < touchEndClientY - aToleranz) {
                if (this.eventPossible(event)) {
                    this.scrollPrev();
                }
            }
        }
    }

    handleMouseEvent(event: MouseEvent): void {
        if (event.button == 1) {
            // stop middle click scrolling
            event.preventDefault();
        }
    }

    handleKeyEvent(event: KeyboardEvent): void {
        if (event.key == 'ArrowDown' || event.code == 'Space') {
            if (this.eventPossible(event)) {
                this.scrollNext();
            }
        } else if (event.key == 'ArrowUp') {
            if (this.eventPossible(event)) {
                this.scrollPrev();
            }
        } else if (event.key == 'PageUp' || event.key == 'Home') {
            if (this.eventPossible(event)) {
                this.scrollFirst();
            }
        } else if (event.key == 'PageDown' || event.key == 'End') {
            if (this.eventPossible(event)) {
                this.scrollLast();
            }
        } else if (this.useScrollingByNumbers && parseInt(event.key) >= 0 && parseInt(event.key) <= 9) {
            this.scrollToIndex(parseInt(event.key));
        }
    }

    handleWheelEvent(event: WheelEvent): void {
        if (event.deltaY > 0) {
            if (this.eventPossible(event)) {
                this.scrollNext();
            }
        } else if (event.deltaY < 0) {
            if (this.eventPossible(event)) {
                this.scrollPrev();
            }
        }
    }

    scrollNext(): void {
        let el = document.querySelector('.fullpage-section.active')?.nextElementSibling;
        if (assigned(el) && el.classList.contains('fullpage-section')) {
            this.scrollToElement(el);
        } else {
            this.isWorking = false;
        }
    }

    scrollPrev(): void {
        let el = document.querySelector('.fullpage-section.active')?.previousElementSibling;
        if (assigned(el) && el.classList.contains('fullpage-section')) {
            this.scrollToElement(el)
        } else {
            this.isWorking = false;
        }
    }

    scrollFirst(): void {
        let el = document.querySelector('.fullpage-section.active')?.parentNode.firstElementChild;
        if (assigned(el) && el.classList.contains('fullpage-section')) {
            this.scrollToElement(el)
        } else {
            this.isWorking = false;
        }
    }

    scrollLast(): void {
        let el = document.querySelector('.fullpage-section.active')?.parentNode.lastElementChild;
        if (assigned(el) && el.classList.contains('fullpage-section')) {
            this.scrollToElement(el)
        } else {
            this.isWorking = false;
        }
    }

    scrollToIndex(index): void {
        let el = this.obj.querySelector('.fullpage-sections').children[index];
        if (assigned(el) && el.classList.contains('fullpage-section')) {
            this.scrollToElement(el)
        } else {
            this.isWorking = false;
        }
    }

    scrollToElement(el): void {
        let timeoutAnimate = 0;
        let timeoutWorking = 500;

        // Sonderlösung für Firefox, damit das Scrollen klappt
        if (new UAParser().getBrowser().name == 'Firefox') {
            timeoutAnimate = 500;
            timeoutWorking = 0;
        }

        if (assigned(el)) {
            // ups JQuery, aber klappt so ohne viel Aufwand. 
            $('html, body').animate({
                scrollTop: el.offsetTop
            }, timeoutAnimate).promise().then(
                function () {
                    this.setAsActive(el);
                    this.updateNavigation();
                    setTimeout(function () {
                        this.isWorking = false;
                    }.bind(this), timeoutWorking);
                }.bind(this)
            );
        }
    }

    setAsActive(el): void {
        let active = document.querySelector('.fullpage-section.active');
        active?.classList.remove('active');
        el.classList.add('active');
    }

    writeProperties(key: string, value: string): void {
        throw new Error('Method not implemented.');
    }
}

export class TwcFullPageSection extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcFullPageSection';
    }

    override initDomElement() {
        super.initDomElement();
    }

    writeProperties(key: string, value: string): void {
        throw new Error('Method not implemented.');
    }
}
