import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent, TWebComponent } from "../../base/class.web.comps";

export class TwsListFilterPanel extends TRenderWebComponent {
    private visible: boolean;
    private enabled: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsFilterPanel';

        this.visible = boolFromStr(this.obj.dataset.visible, false);
        this.enabled = boolFromStr(this.obj.dataset.enabled, false);
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        this.obj.classList.toggle('d-flex', this.visible);
        this.obj.classList.toggle('d-none', !this.visible);

        if (this.enabled) {
            this.obj.querySelector('.close').removeAttribute('disabled');
            this.obj.querySelector('input').removeAttribute('disabled');
        } else {
            this.obj.querySelector('.close').setAttribute('disabled', '');
            this.obj.querySelector('input').setAttribute('disabled', '');
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.visible = boolFromStr(value);
                break;
            case 'ReadOnly':
            case 'Enabled':
                this.enabled = boolFromStr(value);
                break;
        }
    }
}

export class TwcListSearch extends TWebComponent {
    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcListSearch';
    }

    override setFocus(): void {
        super.setFocus();
        this.obj.parentElement.classList.toggle('focus', true);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}