import { TWebComponent } from '../../base/class.web.comps';

export class TwcFormGroup extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcFormGroup';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
            case 'Caption':
                document.getElementById(`${this.id}-label`).innerHTML = value;
                break;
        }
    }
}

export class TwcFormSectionGroup extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcFormSectionGroup';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
            case 'Caption':
                document.getElementById(`${this.id}-legend`).innerHTML = value;
                break;
        }
    }
}

export class TwcFormSectionSubGroup extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcFormSectionSubGroup';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }
}