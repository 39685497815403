import FroalaEditor from 'froala-editor';
// Sprachen müssen wir extra importieren
import 'froala-editor/js/languages/ar';
import 'froala-editor/js/languages/bs';
import 'froala-editor/js/languages/cs';
import 'froala-editor/js/languages/da';
import 'froala-editor/js/languages/de';
import 'froala-editor/js/languages/el';
import 'froala-editor/js/languages/en_ca';
import 'froala-editor/js/languages/en_gb';
import 'froala-editor/js/languages/es';
import 'froala-editor/js/languages/et';
import 'froala-editor/js/languages/fa';
import 'froala-editor/js/languages/fi';
import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/he';
import 'froala-editor/js/languages/hr';
import 'froala-editor/js/languages/hu';
import 'froala-editor/js/languages/id';
import 'froala-editor/js/languages/it';
import 'froala-editor/js/languages/ja';
import 'froala-editor/js/languages/ko';
import 'froala-editor/js/languages/ku';
import 'froala-editor/js/languages/me';
import 'froala-editor/js/languages/nb';
import 'froala-editor/js/languages/nl';
import 'froala-editor/js/languages/pl';
import 'froala-editor/js/languages/pt_br';
import 'froala-editor/js/languages/pt_pt';
import 'froala-editor/js/languages/ro';
import 'froala-editor/js/languages/ru';
import 'froala-editor/js/languages/sk';
import 'froala-editor/js/languages/sl';
import 'froala-editor/js/languages/sr';
import 'froala-editor/js/languages/sv';
import 'froala-editor/js/languages/th';
import 'froala-editor/js/languages/tr';
import 'froala-editor/js/languages/uk';
import 'froala-editor/js/languages/vi';
import 'froala-editor/js/languages/zh_cn';
import 'froala-editor/js/languages/zh_tw';
import 'froala-editor/js/plugins.pkgd.min';
import UAParser from 'ua-parser-js';
import { WebCompEventHandlerAsync } from '../../../core/communication';
import { isComponentActive } from '../../../core/utils/componentUtils';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { CSPopDown } from '../../common/consense.base.popdown';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwsEditorFroala extends TRenderWebComponent {
    hasServerChangeEvent: boolean;
    editor: any;
    testing: Boolean;
    popdownId: string;
    popdown: HTMLElement;
    popdownTrigger: string;
    lastRange: { startContainer: Node; startOffset: number; endContainer: Node; endOffset: number; };
    workOffDialog: any;
    lastHTML: string;
    contentChanged: boolean;
    language: string;
    fonts: Array<string>;
    minHeight: number;
    allowCustomFields: boolean;
    buttons: Array<string>;
    isReadOnly: boolean;
    needsOptimization: boolean;
    isInitialized: boolean;
    modalContainer: Element;
    private resizeObserver: ResizeObserver;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwsEditorFroala';
        this.testing = false;
        this.popdownId = this.id + '-popdown';
        this.popdown = document.getElementById(this.popdownId);
        this.popdownTrigger = '@';
        this.editor = null;
        this.lastHTML = '';
        this.contentChanged = false;
        this.lastRange = null;
        this.buttons = null;
        this.needsOptimization = false;
        this.isInitialized = false;

        this.language = this.obj.dataset.language;
        // Quickfix pt,zh:
        if (this.language === 'pt') {
            this.language = 'pt_br';
        } else if (this.language === 'zh') {
            this.language = 'zh_cn';
        }
        this.fonts = csJSONParse(this.obj.dataset?.fonts ?? '{}');
        this.minHeight = parseInt(this.obj.dataset?.minheight ?? '100');
        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent);
        this.allowCustomFields = boolFromStr(this.obj.dataset?.allowfields);
        this.isReadOnly = boolFromStr(this.obj.dataset?.isreadonly);

        // initial Laden, damit wir das später nicht immer machen müssen
        this.modalContainer = getModalDialogContainer(this.obj);
    }

    override initDomElement() {
        super.initDomElement();
        this.initFroalaEditor();
    }

    setFroalaHeight(val: number): void {
        if (!this.isInitialized) {
            return;
        }
        // das dürfen wir erst, wenn wir initialisiert sind... sonst passieren komische Dinge mit dem Editor
        if (assigned(this.editor)) {
            this.editor.opts.height = val;
            this.editor.opts.heightMin = val;
            this.editor.size.refresh();
        }
    }

    optimizeHeight(): void {
        if (!this.needsOptimization) {
            return;
        }
        // erstmal nur ohne Modale Dialoge
        if (!this.isInsideModal()) {
            let availableSpaceVertical = 0, height = 0;
            let editorRect = this.obj.getBoundingClientRect();


            let appFooter = document.querySelector('.app-footer');
            let appFooterHeight = 0;
            if (assigned(appFooter)) {
                let appFooterComputedStyle = window.getComputedStyle(appFooter);
                appFooterHeight = appFooter.getBoundingClientRect().height;
                appFooterHeight += parseFloat(appFooterComputedStyle.marginBottom);
                appFooterHeight += parseFloat(appFooterComputedStyle.marginTop);
                appFooterHeight += parseFloat(appFooterComputedStyle.paddingBottom);
                appFooterHeight += parseFloat(appFooterComputedStyle.paddingTop);
                appFooterHeight += 120; // experimentell noch etwas weg (appFooterHeight wird später abgezogen)
            }

            availableSpaceVertical = document.documentElement.clientHeight - editorRect.y - appFooterHeight;
            availableSpaceVertical = Math.floor(availableSpaceVertical); // hier keine halben pixel
            height = availableSpaceVertical - 15; // noch ein bisschen weg
            this.setFroalaHeight(height);
        }
        this.needsOptimization = false;
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        if (!isComponentActive(this)) {
            return;
        }
        this.optimizeHeight();
    }

    afterInitFroalaEditor(): void {
        if (!this.isInitialized) {
            return;
        }

        if (this.obj.classList.contains('cs-auto-height')) {
            this.needsOptimization = true;
            // Wenn wir das Browserfenster verändern, muss ggf. die Höhe der Tabelle angepasst werden
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    this.invalidate();
                });
            });
            this.resizeObserver.observe(this.obj);
        }
    }

    initFroalaEditor(): void {
        let editor = this;

        FroalaEditor.DefineIcon('insertCustomField', { NAME: 'scroll-old', SVG_KEY: 'scroll-old' });
        FroalaEditor.RegisterCommand('insertCustomField', {
            title: 'Manuelles Feld', // todo: localization: Custom Field
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                editor.editor.selection.save();
                WebCompEventHandlerAsync('OnInsertCustomField', editor.id, null);
            }
        });

        // hilfreiche Links
        // https://froala.com/wysiwyg-editor/docs/concepts/custom/icon/
        // https://froala.com/wysiwyg-editor/examples/custom-dropdown/

        // überschreiben von einzelnen Buttons
        FroalaEditor.DefineIcon('paragraphFormat', { NAME: 'Format', template: 'text' });
        FroalaEditor.DefineIcon('fullscreen', { NAME: 'expand-alt' });
        FroalaEditor.DefineIcon('fullscreenCompress', { NAME: 'compress-alt' });
        FroalaEditor.DefineIcon('hyperlinkIcon', { NAME: 'link' })

        FroalaEditor.RegisterCommand('insertHyperlinkDropdown', {
            title: 'Verknüpfung',
            type: 'dropdown',
            icon: 'hyperlinkIcon',
            html: function html() {
                // hilffunktion um dropdowns hinzuzufügen
                let insertDropdownActions = function (AAction: string, AIcon: string, AText: string): string {
                    let r = `<li role="presentation"> 
                                <a class="fr-command" tabIndex="-1" role="option" data-cmd="insertHyperlinkDropdown" data-param1="${AAction}" title="${AText}">
                                    <i class="dropdown-icon fa-light fa-${AIcon}"></i> ${editor.editor.language.translate(AText)}
                                </a>
                            </li>`;
                    return r;
                }
                // wir bauen nun unsere Liste im Dropdown
                let c = '<ul class="fr-dropdown-list" role="presentation">';
                // die v-Werte sind einfach nur Identifier, die wir im callback wiederfinden
                c += insertDropdownActions('v1', 'globe', 'Internet...');
                if (editor.allowCustomFields) {
                    c += insertDropdownActions('v2', 'scroll-old', 'Manuelles Feld...');
                }
                c += '</ul> ';

                return c;
            },
            // Save the dropdown action into undo stack.
            undo: true,
            // Focus inside the editor before callback.
            focus: true,
            // Refresh the button state after the callback.
            refreshAfterCallback: true,
            callback: function (cmd, val) {
                if (val == 'v1') {
                    // Das PopUp z.B. für die "Eingabe der URL" öffnet sich an der Stelle data-cmd='insertLink' (vgl. Froala Quellcode)
                    // da es diese Stelle aber nicht gibt, müssen wir hier nachhelfen und fügen uns ein Element hinzu
                    // oder falls es die schon gibt, dann brauchen wir das natürlich nur einmal zu machen
                    let hiddenSpan = editor.obj.querySelector("[data-cmd='insertLink']");
                    if (!assigned(hiddenSpan)) {
                        let button = editor.obj.querySelector("[data-cmd='insertHyperlinkDropdown']");
                        let newNode = document.createElement('span');
                        newNode.dataset['cmd'] = 'insertLink';
                        newNode.classList.add('fr-command');
                        newNode.classList.add('csfr-popuptrigger');
                        button.parentElement.insertBefore(newNode, button);
                    }
                    // und hier das Kommendo
                    editor.editor.commands.exec('insertLink');
                } else if (val == 'v2') {
                    // und hier das Kommendo
                    editor.editor.commands.exec('insertCustomField');
                }
            },
            // Callback on refresh.
            refresh: function ($btn) {
                // do nothing
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
                // do nothing
            }
        })

        this.buttons = [
            'paragraphFormat',
            'fontFamily',
            'fontSize',
            'textColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'superscript',
            'subscript',
            '|',
            'formatOL',
            'formatUL',
            '|',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'alignJustify',
            '|',
            'outdent',
            'indent',
            '|',
            'insertHyperlinkDropdown',
            'insertImage',
            'insertTable',
            'insertHR',
            '|',
            'undo',
            'redo',
            '|',
            'fullscreen'
        ];


        this.editor = new FroalaEditor(this.obj, {
            // docs: https://froala.com/wysiwyg-editor/docs/options/
            // unser wildcard key
            key: 'fIE3A-9C1B2F1B5B1B4td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3F3D4F2F2C3B3C2==',
            iconsTemplate: 'font_awesome_5l',
            attribution: false,
            autofocus: true,
            // Set the language code.
            language: this.language,
            // enter: FroalaEditor.ENTER_DIV,
            fontSizeUnit: 'pt',
            fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '36', '48', '72',],
            // fontPicker bietet uns erstmal noch zu viel Auswahl
            // fontFamily: this.fontsFromJSON(this.fonts),
            fontFamily: {
                // bei Fontfamilies mit spaces müssen die äußeren quotes doppelte sein
                'Arial': 'Arial',
                "'Arial Black'": 'Arial Black',
                'Calibri': 'Calibri',
                "'Comic Sans MS'": 'Comic Sans MS',
                'Courier New': 'Courier New',
                "'Fira Sans'": 'Fira Sans',
                'Georgia': 'Georgia',
                'Helvetica': 'Helvetica',
                "'Segoe UI'": 'Segoe UI',
                'Tahoma': 'Tahoma',
                "'Times New Roman'": 'Times New Roman',
            },
            fontFamilySelection: true,
            quickInsertEnabled: false,
            heightmin: this.minHeight,
            toolbarSticky: true,
            // stickyOffset wird über css gelöst
            shortcutsEnabled: ['undo', 'redo'],
            charCounterCount: false,
            /*
            toolbarButtons: >= 1200px
            toolbarButtonsMD >= 992px
            toolbarButtonsSM >= 768px
            toolbarButtonsXS <  768px
            */
            toolbarButtons: this.buttons,
            tooltips: false,
            listAdvancedTypes: false,
            imageInsertButtons: ['imageUpload'],
            imageEditButtons: ['imageAlign'],
            imageDefaultWidth: 0,
            tableEditButtons: ['tableRows', 'tableColumns', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', '|', 'tableRemove',],
            linkAlwaysBlank: true,
            linkList: [],
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            events: {
                initialized: () => {
                    // readonly bzw disabeld
                    if (this.isReadOnly) {
                        this.editor.edit.off();
                    }
                    // hyperlinks
                    let hyperlinks = this.obj.querySelectorAll('a');
                    hyperlinks.forEach(hyperlink => {
                        if (!(hyperlink.classList.contains('user-group-link') || hyperlink.classList.contains('fr-command'))) {
                            hyperlink.setAttribute('target', '_blank');
                            hyperlink.setAttribute('rel', 'noopener noreferrer');
                            if (this.isReadOnly) {
                                // wenn das ding disabled ist klappen die links auf normalen wege nicht mehr. 
                                // hier helfen wir nach und lösen das über die onclick funktion
                                hyperlink.addEventListener('click', () => {
                                    window.open(hyperlink.href);
                                });

                            }
                        }
                    });
                    this.isInitialized = true;
                    this.afterInitFroalaEditor();
                },
                contentChanged: () => {
                    this.contentChanged = true;
                    this.notifyComponentChanged();
                },
                // before, beforeCleanup, afterCleanup, after
                // return wird im Editor angezeigt
                'paste.afterCleanup': function (clipboard_html) {
                    // this is the editor instance.
                    // alle serifs rauslöschen, da WPTools sonst die Schriftart nicht erkennt
                    clipboard_html = clipboard_html.replaceAll(',serif;', ';');
                    clipboard_html = clipboard_html.replaceAll(',sans-serif;', ';');

                    return clipboard_html;
                },
                'paste.after': () => {
                    // für froala -> WPTools müssen die Styles in der Tabellenzelle angegeben werden
                    // wir ersetzen also die aus dem Wordimport kommenden <p> durch mit <br> getrennte Zeilen
                    let tableCells = this.obj.querySelectorAll('td');
                    tableCells.forEach(cell => {
                        let paragraphs = cell.querySelectorAll('p');
                        // nur wenn wir <p> ersetzen müssen
                        if (paragraphs.length > 0) {
                            cell.innerHTML = '';

                            paragraphs.forEach(par => {
                                if (par.innerHTML != '')
                                    if (cell.innerHTML === '')
                                        cell.innerHTML = par.innerHTML;
                                    else
                                        cell.innerHTML = [cell.innerHTML, par.innerHTML].join('<br>');

                                // style setzen
                                cell.style.textAlign = par.style.textAlign;
                                cell.style.fontFamily = par.style.fontFamily;
                                cell.style.fontSize = par.style.fontSize;
                            });
                        }
                    });
                },
                'table.inserted': function (table: HTMLTableElement) {
                    // we iterate through to table to apply the borderstyle
                    for (let i = 0, row: HTMLTableRowElement; row = table.rows[i]; i++) {
                        // iterate through rows
                        for (let j = 0, col: HTMLTableDataCellElement; col = row.cells[j]; j++) {
                            //iterate through columns
                            col.style.borderWidth = '1px';
                            col.style.borderStyle = 'solid';
                            col.style.borderColor = '#000';
                        }
                    }
                },
                'image.beforeUpload': function (files) {
                    let editor = this;
                    if (files.length) {
                        // Create a File Reader.
                        let reader = new FileReader();
                        // Set the reader to insert images when they are loaded.
                        reader.onload = function (e) {
                            let result = e.target.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }
                    editor.popups.hideAll();
                    // Stop default upload chain.
                    return false;
                },
                'keydown': (keydownEvent) => {
                    if (keydownEvent.key == this.popdownTrigger) {
                        this.openPopDown();
                    }
                    else if (keydownEvent.keyCode === 13 && (keydownEvent.ctrlKey || keydownEvent.metaKey)) {
                        // Strg + Enter
                        this.editor.commands.insertHR();
                        keydownEvent.preventDefault();
                        keydownEvent.stopPropagation();
                        return false;
                    }
                },
            },
        });
        this.contentChanged = true;
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.InsertCustomField':
                this.editor.selection.restore();
                this.editor.events.focus();
                this.editor.html.insert(params);
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    isInsideModal(): boolean {
        return assigned(this.modalContainer);
    }

    fontsFromJSON(fonts: Array<string>): unknown {
        let result = {};

        fonts.forEach((element: string) => {
            // quotes wegen der Leerzeichen in Fontnames
            result[`'${element}'`] = element;
        });

        return result;
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        let htmlcode: string;
        if (assigned(this.editor)) {
            htmlcode = this.editor.html.get();
        } else {
            htmlcode = this.lastHTML;
        }
        let re = new RegExp('\\n');

        // Wenn der Content bearbeitet wurde schicken wir das zum Server
        // sonst schicken wir das "letzte" was wir bekommen haben
        if (this.contentChanged) {
            if (htmlcode.trim() == '<br>') {
                htmlcode = '';
            } else if (htmlcode.length === 1 && htmlcode.search(re) == 0) {
                htmlcode = '';
            }
        } else {
            htmlcode = this.lastHTML;
        }

        properties.push([this.id, 'html', htmlcode]);
        this.contentChanged = false;
        this.lastHTML = htmlcode;

        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                if (value == '1') {
                    this.editor.edit.on();
                } else if (value == '0') {
                    this.editor.edit.off();
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', boolFromStr(value));
                break;
        }
    }

    isFireFox(): boolean {
        return new UAParser().getBrowser().name == 'Firefox';
    }

    openPopDown(): void {
        if (boolFromStr(this.popdown.dataset.searchprovider)) {
            if (this.saveRange()) { //aktuelle position merken
                this.workOffDialog = this.workOffPopDownFunc();
                this.workOffDialog.then((dataSet) => {
                    this.restoreRange();
                    this.clearLastInput();
                    // und einfügen
                    var newNode = this.createNewNodeInput(dataSet);
                    this.insertCsUnit(newNode);
                }).catch((data) => {
                    this.restoreRange()
                    if (data) {
                        this.clearLastInput();
                    } else {
                        this.jumpCaretToEnd();
                    }
                });
            } else {
                // hier darf der eigentlich nie rein
                console.error('Cursor Error');
            }
        }
    }

    clearLastInput(): void {
        let sel = window.getSelection();
        if (sel.rangeCount) {
            let range = sel.getRangeAt(0);
            let clone = range.cloneRange();
            try {
                // firefox verhält sich in position 0 anders. Hier helfen wir nach
                if (this.isFireFox() && range.commonAncestorContainer.nodeType == Node.ELEMENT_NODE) {
                    clone.setStart(range.startContainer, 0);
                    clone.setEnd(range.endContainer, 1);
                } else {
                    // Fix für das Nichtlöschen des @-Zeichens
                    // An dieser Stelle kommt es zu einem Mysteryverhalten mit Offsets und Length
                    // Teilweise wird noch ein ZeroWidthSpace berücksichtigt, teilweise nicht
                    if (range.startContainer == range.endContainer && range.startContainer.textContent.length == 1) {
                        clone.setStart(range.startContainer, 0);
                        clone.setEnd(range.endContainer, 1);
                    } else {
                        clone.setStart(range.startContainer, range.startOffset);
                        clone.setEnd(range.endContainer, range.startOffset + 1);
                    }
                }
                clone.deleteContents();
            } catch (e) {
                // statements
                console.log(e);
            }

        }
    }

    insertCsUnit(node: Node): void {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                range.collapse(false);
                range.insertNode(node);
                // hier setzen wir den cursor ans ende
                range.setStartAfter(node);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    }

    saveRange(): boolean {
        if (window.getSelection) {
            let sel = window.getSelection()
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                this.lastRange = {
                    "startContainer": range.startContainer,
                    "startOffset": range.startOffset,
                    "endContainer": range.endContainer,
                    "endOffset": range.endOffset
                };
                return true;
            }
        }
        return false;
    }

    restoreRange(): void {
        if (this.lastRange) {
            let sel = window.getSelection();
            sel.removeAllRanges();
            let range = document.createRange();
            range.setStart(this.lastRange.startContainer, this.lastRange.startOffset);
            range.setEnd(this.lastRange.endContainer, this.lastRange.endOffset);
            sel.addRange(range);
        }
    }

    createNewNodeInput(dataSet: any): Node {
        let newNode = document.createElement('a');

        // Gruppen und User via Visitenkarte
        if (dataSet.key.indexOf('U') === 0 || dataSet.key.indexOf('G') === 0) {
            newNode.setAttribute('class', 'user-group-link');
            newNode.setAttribute('data-key', dataSet.key);
            newNode.href = '#';
        } else if (dataSet.key.indexOf('#') === 0) {
            newNode.setAttribute('class', 'cs-rtf-link');
            newNode.setAttribute('data-key', dataSet.key);
            newNode.href = '#';
        } else {
            newNode.setAttribute('class', 'cs-oid-link');
            newNode.href = '/GoTo?OID=' + dataSet.key;
        }
        newNode.setAttribute('target', '_blank');
        newNode.setAttribute('rel', 'noopener noreferrer');

        let text = document.createTextNode(dataSet.captionraw);
        newNode.appendChild(text);
        return newNode;
    }

    workOffPopDownFunc(): Promise<any> {
        return new Promise((resolve, reject) => {
            let popdown = new CSPopDown(this.popdownId);
            let resolved = popdown.show();
            resolved.then((dataSet) => {
                resolve(dataSet);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    jumpCaretToEnd(): void {
        let sel = window.getSelection();
        if (sel.rangeCount) {
            let range = sel.getRangeAt(0);
            let newrange = document.createRange();
            newrange.selectNodeContents(range.commonAncestorContainer);
            newrange.collapse(false);
            sel.removeAllRanges();
            sel.addRange(newrange);
        }
    }
}