// Typeahead ist so nen Sonderfall wie man es lädt, siehe:
// https://github.com/twitter/typeahead.js/issues/1211
// https://github.com/twitter/typeahead.js/issues/1627
import $ from 'jquery';
import { getBaseUrl } from '../../../core/endpoint';
import { assigned } from '../../../utils/helper';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

require('typeahead.js/dist/typeahead.jquery.min.js');
const Bloodhound = require('typeahead.js/dist/bloodhound.min.js');

export class TwcObjectListSelector extends TWebComponent {
    elementId: string;
    element: JQuery<HTMLElement>;
    selectedKey: string;
    selectedValue: string;
    bloodhounds: any[];

    OnSelectEvent: (event: any) => void;
    scArray: string[];
    OnClickEvent: (event: any) => void;
    options: Twitter.Typeahead.Options;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcObjectListSelector';
        this.elementId = this.id + '-input';
        this.element = $('#' + this.elementId);
        this.selectedKey = this.obj.dataset.selectedkey;
        this.selectedValue = this.obj.dataset.selectedvalue;
        this.bloodhounds = [];
        this.options = {
            hint: false, // DAS MUSS FALSE SEIN! Hier wird das inputfeld bei TRUE gecloned und zerstört die Funktionen! 
            highlight: true,
            minLength: 2
        };
        if ($(this.obj).data('groupresults')) {
            this.initObjectListGroup();
        } else {
            this.initObjectList();
        }

        this.OnSelectEvent = null;
        this.OnClickEvent = null;
        
        this.bindObjectListEvents();
        this.emptyObjectListMessage();
    }

    getSuggestionTemplate(data: { typename: string; image: string; description: string; caption: string; }): string {

        let markup = '<div><div class="media"><span class="mediaimg" title="' + data.typename + '">' + data.image + '</span><div class="media-body">';
        if (data.description.trim() == '') {
            markup += '<h3 class="mx-2">' + data.caption + '</h3>';
        } else {
            markup += '<h4 class="mt-0 mb-0 mx-2">' + data.caption + '</h4>';
            markup += '<h5 class="text-muted mx-2">' + data.description + '</h5>';
        }
        markup += '</div></div></div>';
        return markup;
    }

    setErrorTemplate(errorMsg: string, errorDescription: string): void {
        let msg = '<strong>' + errorMsg + '</strong><br>' + errorDescription;
        $('#' + this.elementId + '_error-message .msg').html(msg);
    }

    initObjectList(): void {
        let settings = [];
        let set = {};
        let n = 0;

        this.addBloodhound(n, this.id);
        let headerTemplate = '';
        let suggestionTemplate = this.getSuggestionTemplate;
        set = {
            limit: 'Infinity',
            display: 'caption',
            source: this.bloodhounds[n],
            templates: {
                header: headerTemplate,
                suggestion: suggestionTemplate
            }
        }
        settings.push(set);
        n++;

        this.element.typeahead(this.options, settings);
        this.element.typeahead('val', this.selectedValue);
    }

    addBloodhound(index: number, id: string): void {
        let url = getBaseUrl(id) + '&params=%QUERY';
        const _this = this;
        this.bloodhounds[index] =
            new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('caption'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: url,
                    wildcard: '%QUERY',
                    transform: function (response) {
                        if (response.error.errorCode == 1) {
                            _this.setErrorTemplate(response.error.errorMsg, response.error.errorDescription);
                            return []; // wir geben nix zurück
                        } else {
                            return response.items;
                        }
                    }
                },
                identify: function (obj) {
                    return obj.caption;
                }
            });
    }

    initObjectListGroup(): void {
        this.scArray = new Array('scUsers', 'scKennzahlen', 'scFolders', 'scWiki', 'scOrgUnits', 'scGlossar', 'scFORMSTemplates', 'scContacts');
        let settings = [];
        let set = {};
        let n = 0;
        this.scArray.forEach(function (index: number) {
            this.addBloodhoundGroup(n, this.id);
            let headerTemplate = function (data) {
                return '<h2>' + data.suggestions[0].groupname + '</h2>';
            };
            let suggestionTemplate = this.getSuggestionTemplate;
            set = {
                limit: 'Infinity',
                display: 'caption',
                source: this.bloodhounds[n],
                templates: {
                    header: headerTemplate,
                    suggestion: suggestionTemplate
                }
            }
            settings.push(set);
            n++;
        }.bind(this));

        this.element.typeahead(this.options, settings);
        this.element.typeahead('val', this.selectedValue);
    }

    addBloodhoundGroup(index: number, id: string): void {
        let url = getBaseUrl(id) + '&params=%QUERY';
        const _this = this;
        this.bloodhounds[index] =
            new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('caption'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: url,
                    wildcard: '%QUERY',
                    transform: function (response) {
                        if (response.error.errorCode == 1) {
                            _this.setErrorTemplate(response.error.errorMsg, response.error.errorDescription);
                            return []; // wir geben nix zurück
                        } else {
                            if (typeof response.items[index] !== undefined) {
                                return response.items[index];
                            }
                        }
                    }
                },
                identify: function (obj) {
                    return obj.caption;
                }
            });
    }


    bindObjectListEvents(): void {
        this.element.bind('typeahead:select', function (ev: any, suggestion: { key: any; caption: any; captionraw: any}) {
            this.selectedKey = suggestion.key;
            this.selectedCaption = suggestion.caption;
            this.selectedCaptionRaw = suggestion.captionraw;
            this.notifyComponentChanged();

            // spezielle popdown Logik vorhanden?
            if (assigned(this.OnClickEvent)) { 
                this.OnClickEvent(this.selectedKey, this.selectedCaption, this.selectedCaptionRaw);
                this.resetInput();
            }
        }.bind(this));

        this.element.bind('typeahead:change', function (ev: any, suggestion: any) {

            // wenn wir vorher was ausgewählt haben und dann den eintrag löschen
            if (this.element.val() != this.selectedCaption) {
                this.resetInput();
            }
        }.bind(this));
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    emptyObjectListMessage(): void {
        const emptyMessage = '<div id="' + this.elementId + '_error-message" class="empty-message"><i class="fa-solid fa-search text-danger fa-2x"></i><br><span class="msg"></span></div>';
        const emptyMessageNode = $(emptyMessage);
        // hide empty message by default
        emptyMessageNode.hide();
        // get menu element and append hidden empty messsage element
        const menuNode = $('#' + this.elementId + '.tt-input').data('tt-typeahead').menu.$node;
        menuNode.append(emptyMessageNode);

        $('#' + this.elementId).on('typeahead:asyncreceive', function () {

            if ($(this).data('tt-typeahead').menu._allDatasetsEmpty()) {
                // hide dataset result containers
                menuNode.find('.tt-dataset').hide();
                // show empty message and menu
                emptyMessageNode.show();
                menuNode.show();
            } else {
                // show dataset result containers
                menuNode.find('.tt-dataset').show();
                // hide empty message
                emptyMessageNode.hide();
            }
        });
    }

    override setFocus(): void {
        this.element.focus();
    }

    resetInput(): void {
        this.selectedKey = '';
        this.selectedValue = '';
        this.clearInput();
    }

    clearInput(): void {
        this.element.typeahead('val', '');
    }

    getInput(): string {
        return this.element.typeahead('val');
    }

    override readProperties(): Array<ComponentProperty> {
        var properties = [];
        properties.push([this.id, 'selectedKey', this.selectedKey]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                if (value == '1') {
                    this.element.prop('disabled', false);
                } else if (value == '0') {
                    this.element.prop('disabled', true);
                }
                break;
            case 'Readonly':
                if (value == '1') {
                    this.element.prop('readonly', true);
                } else if (value == '0') {
                    this.element.prop('readonly', false);
                }
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;

        }
    }
}

export class TwcObjectListSelectorPopDown extends TwcObjectListSelector {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcObjectListSelectorPopDown';
    }

}