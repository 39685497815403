import { TWebComponent } from '../../base/class.web.comps';

export class TwcTimeline extends TWebComponent {

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcTimeline';
    }

    writeProperties(key: string, value: string): void {

    }
}


