import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeConnector extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 550;
        this.roundCorners = true;
        this.isResizePossible = false; // Default
    }

    getSvgElementType(): string {
        return 'rect';
    }

    override create() {
        super.create();

        this.isResizePossible = !this.editor.connectorsSizeFixed;
    }
}