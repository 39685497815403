import { getCurrentGlobalMousePosition } from '../../../app/consense.keyboard';
import { Point } from '../../../classes/geometry';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { getParentByClassname } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';

export enum ContextMenuEventNames {
    Show = 'TwcContextMenu.show',
    AfterShow = 'TwcContextMenu.afterShow',
    Hide = 'TwcContextMenu.hide',
    AfterHide = 'TwcContextMenu.afterHide',
}

export class TwcContextMenu extends TWebComponent {

    triggerId: string;
    dropdownId: string;
    dropdown: HTMLElement;
    active: boolean;
    private _mousePosition: Point | null;

    public get mousePosition(): Point {
        return this._mousePosition ?? getCurrentGlobalMousePosition();
    }

    documentClick: (e: any) => void;

    writeProperties(key: string, value: string): void {
        // nothing
    }

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcContextMenu';

        this.triggerId = this.obj.dataset.triggerid;
        this.dropdownId = this.obj.dataset.dropdownid;
        this.dropdown = document.getElementById(this.dropdownId);

        this.active = false;
        this._mousePosition = null;

        this.initEventlistener();
        this.initDropdownStyles();

        // um den eventlistener später zu removen müssen wir mit "named"-function arbeiten
        this.documentClick = e => this.handleDocumentClick(e);
    }

    initDropdownStyles(): void {
        this.dropdown.style.position = 'relative';
    }

    initEventlistener(): void {
        document.addEventListener('contextmenu', event => this.saveMousePosition(event));
    }

    saveMousePosition(event: MouseEvent): void {
        this._mousePosition = new Point(event.clientX, event.clientY);
    }

    positionInParent(left: number, top: number): { left: number, top: number } {
        let parentRect = null;
        let diffX, diffY = 0;
        // erstmal checken ob wir im modalen dialog sind
        let modalContainer = getModalDialogContainer(this.obj);
        if (assigned(modalContainer)) {
            parentRect = modalContainer.querySelector('.modal-body').getBoundingClientRect();
            diffX = parentRect.x;
            diffY = parentRect.y;
        } else {
            // wir haben bei lookuppages andere Bezugselemente. Erstmal schauen, wo wir uns befinden
            let isInSidebar = assigned(getParentByClassname(this.obj, 'page-sidebar'));
            if (isInSidebar) {
                parentRect = document.querySelector('.page-sidebar').getBoundingClientRect();
            } else {
                parentRect = document.querySelector('.page-inner').getBoundingClientRect();
            }
            if (assigned(parentRect)) {
                diffX = parentRect.x;
                diffY = parentRect.y;
            }

        }
        return { left: left - diffX + 5, top: top - diffY - 5 };
    }

    showContextMenu(mousePosition: Point): void {
        this.triggerEvent(ContextMenuEventNames.Show, mousePosition);

        // werte holen
        let pos = this.positionInParent(mousePosition.x, mousePosition.y);

        this.dropdown.style.left = `${pos.left}px`;
        this.dropdown.style.top = `${pos.top}px`;
        this.dropdown.classList.add('show');

        this.active = true;
        this.triggerEvent(ContextMenuEventNames.AfterShow, mousePosition);
    }

    hideContextMenu(mousePosition: Point): void {
        this.triggerEvent(ContextMenuEventNames.Hide, mousePosition);
        this.dropdown.classList.remove('show');

        this.active = false;
        this.triggerEvent(ContextMenuEventNames.AfterHide, mousePosition);
    }

    openContextMenu(): void {
        if (this.active) {
            this.hideContextMenu(this.mousePosition);
        }
        this.showContextMenu(this.mousePosition);
        // eventlistener für close setzen
        document.addEventListener('mouseup', this.documentClick, false);
    }

    handleDocumentClick(event: MouseEvent): void {
        // wohin wurde geklickt? Wir holen uns das target
        let eventTarget = (event.target as Element);
        // und nun die Abfragen, ob wir ein Link-Text oder ein Link sind, aber nicht ein submenü öffner!
        let isHrefCaption = eventTarget?.classList?.contains('dropdown-hrefcaption') ?? false;
        let isHref = isHrefCaption || ((eventTarget?.nodeName == 'A' && !eventTarget?.classList?.contains('dropdown-toggle')) ?? false);
        // wenn wir z.b. auf einen seperator geklickt haben, dann soll es nicht schließen
        let isClickInside = this.dropdown?.contains(eventTarget) ?? false;

        // wenn wir auf ein Item im Menü geklickt haben dann müssen wir nur schließen, wenn es ein Link ist
        // Ausserhalb geklickt? Dann zu!
        // rechte maustaste lassen wir auch zu. 
        if (isHref || !isClickInside || event.button == 2) {
            if (this.active) {
                this.hideContextMenu(new Point(event.clientX, event.clientY));
            }
            // eventlistener für close wieder löschen, sonst wird dieser jedes mal getrigger, auch wenn nicht komponente nicht sichtbar
            document.removeEventListener('mouseup', this.documentClick, false);
        }
    }

    //Events
    triggerEvent(targetEvent: ContextMenuEventNames, mousePosition: Point): void {
        this.obj.dispatchEvent(new CustomEvent(targetEvent, { detail: { clientX: mousePosition.x, clientY: mousePosition.y, } }));
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.Show':
                this.openContextMenu();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}
