import { WebCompEventHandler } from '../../../core/communication';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcTextArea extends TWebComponent {
    isMultiline: boolean;
    hasServerChangeEvent: boolean;
    hasServerClickEvent: boolean;
    hasServerKeypressEvent: boolean;
    hasServerEnterEvent: boolean;
    hasServerExitEvent: boolean;
    lineWrapping: boolean;


    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTextArea';
        this.isMultiline = boolFromStr(this.obj.dataset.multiline, true);

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent ?? 'false');
        this.hasServerClickEvent = boolFromStr(this.obj.dataset?.hasServerClickEvent ?? 'false');
        this.hasServerKeypressEvent = boolFromStr(this.obj.dataset?.hasServerKeypressEvent ?? 'false');
        this.hasServerEnterEvent = boolFromStr(this.obj.dataset?.hasServerEnterEvent ?? 'false');
        this.hasServerExitEvent = boolFromStr(this.obj.dataset?.hasServerExitEvent ?? 'false');

        this.lineWrapping = boolFromStr(this.obj.dataset.linewrapping, false);

        this.updateComponent();
    }

    override initDomElement(): void {
        super.initDomElement();

        this.obj.addEventListener('keydown', e => this.handleKeydownEvent(e), false);
        // events mit serverbenachrichtigung
        this.obj.addEventListener('keyup', e => this.handleKeyupEvent(e), false);
        this.obj.addEventListener('click', e => this.handleClickEvent(e), false);
        this.obj.addEventListener('input', e => this.handleInputEvent(e), false);
        this.obj.addEventListener('focus', e => this.handleFocusEvent(e), false);
        this.obj.addEventListener('blur', e => this.handleBlurEvent(e), false);

    }

    handleKeyupEvent(event): void {
        this.notifyComponentChanged();
        if (this.hasServerChangeEvent) {
            // hier senden wir ein OnChange!
            WebCompEventHandler('OnChange', this.id);
        }
    }

    handleClickEvent(event): void {
        if (this.hasServerClickEvent) {
            WebCompEventHandler('OnClick', this.id);
        }
    }

    handleInputEvent(event): void {
        this.notifyComponentChanged();
        if (this.hasServerKeypressEvent) {
            // hier senden wir ein OnKeyPress!
            WebCompEventHandler('OnKeyPress', this.id);
        }
    }

    handleFocusEvent(event): void {
        if (this.hasServerEnterEvent) {
            WebCompEventHandler('OnEnter', this.id);
        }
    }

    handleBlurEvent(event): void {
        if (this.hasServerExitEvent) {
            WebCompEventHandler('OnExit', this.id);
        }
    }

    handleKeydownEvent(event): void {
        this.notifyComponentChanged();

        if (this.isMultiline) {
            return;
        }

        if (event.key == 'Enter') {
            event.preventDefault();
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        let text = (this.obj as HTMLTextAreaElement).value;

        if (this.lineWrapping) {
            text = text.replace(/[\r\n]/g, '\r\n');
        }
        properties.push([this.id, 'Text', text]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Text':
                $(this.obj).val(value);
                break;
            case 'MaxLength':
                if (parseInt(value) >= 0) {
                    $(this.obj).attr('maxlength', value);
                } else {
                    $(this.obj).removeAttr('maxlength');
                }
                break;
            case 'MultiLine':
                this.isMultiline = boolFromStr(value, true);
                break;
            case 'Enabled':
                if (value == '1') {
                    $(this.obj).prop('disabled', false);
                } else if (value == '0') {
                    $(this.obj).prop('disabled', true);
                }
                break;
            case 'Readonly':
                if (value == '1') {
                    $(this.obj).prop('readonly', true);
                } else if (value == '0') {
                    $(this.obj).prop('readonly', false);
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
            case 'ShowLinesCount':
                $(this.obj).attr('rows', value);
                break;
            case 'Resizable':
                this.obj.setAttribute('resizable', value);
            case 'LineWrapping':
                this.lineWrapping = boolFromStr(value, false);
                break;
        }

        this.updateComponent();
    }

    updateComponent() {
        this.obj.style.whiteSpace = (this.isMultiline ? 'pre-line' : 'pre');
    }
}