'use strict';

import {PointElement} from 'chart.js';

export class CustomElementPoint extends PointElement {

    draw(ctx) {
        super.draw(ctx);
    }
}

CustomElementPoint.id = 'customElementPoint';
CustomElementPoint.defaults = PointElement.defaults;
