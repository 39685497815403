import { sendComponentRequestGetJson } from "../../../core/communication";
import { getBaseUrl } from "../../../core/endpoint";
import { formatDateTime } from "../../../core/utils/locale";
import { assigned } from "../../../utils/helper";
import { AsType } from "../../../utils/html";
import { FileSelectorCommand, FileSelectorRequests, FileSelectorResponse, TwcCustomFileSelector, WebFilePreviewMode } from "./class.web.comp.fileselector.base";

export class TwcDropZoneFileSelector extends TwcCustomFileSelector {
    dropzone: HTMLElement;
    noDataText: string;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcDropZoneFileSelector';

        this.dropzone = $(this.obj).find('.dropzone')[0];
        this.noDataText = this.obj.dataset.textNoData;
    }

    override initDomElement(): void {
        super.initDomElement();

        $(this.dropzone).children('.upload-button').click(e => this.triggerFileSelect());
        $(this.dropzone).children('.upload-hidden').change(e => this.handleFileSelect(e));

        this.dropzone.addEventListener('dragenter', event => this.handleDragEnter(event), false);
        // event für das reinfahren
        this.dropzone.addEventListener('dragover', event => this.handleDragOver(event), false);
        // event für das rausfahren
        this.dropzone.addEventListener('dragleave', event => this.handleDragLeave(event), false);
        // dieses event ist für den upload da
        this.dropzone.addEventListener('drop', event => this.handleDrop(event), false);
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        // die Dropzone verstecken bei ReadOnly
        if (this.readOnly) {
            $(this.dropzone).removeClass('d-block');
            $(this.dropzone).addClass('d-none');
        }
        else {
            $(this.dropzone).removeClass('d-none');
            $(this.dropzone).addClass('d-block');
        }
    }

    triggerFileSelect(): void {
        AsType<HTMLElement>(this.dropzone.querySelector('.upload-hidden'))?.click();

    }

    handleFileSelect(evt: JQuery.ChangeEvent<HTMLElement, null, HTMLElement, HTMLElement>) {
        evt.stopPropagation();
        evt.preventDefault();

        let localFiles = AsType<HTMLInputElement>(this.dropzone.querySelector('.upload-hidden'))?.files ?? [];

        for (let index = 0; index < localFiles.length; index++) {
            this.uploadFile(localFiles[index]);
        }

        // danach müssen wir das input Feld resetten, falls man die selbe Datei nochmals auswählen will.
        AsType<HTMLInputElement>(this.dropzone.querySelector('.upload-hidden')).value = '';
    }

    handleDragEnter(evt: DragEvent) {
        if (this.readOnly) {
            evt.dataTransfer.dropEffect = 'none';
        }
        else {
            evt.dataTransfer.dropEffect = 'copy';
        }
    }

    handleDragOver(evt: DragEvent) {
        evt.stopPropagation();
        evt.preventDefault();

        // cursor wird angepasst		
        if (this.readOnly) {
            evt.dataTransfer.dropEffect = 'none';
        }
        else {
            evt.dataTransfer.dropEffect = 'copy';
            this.dropzone.classList.add('dropzone-highlight');
        }
    }

    handleDragLeave(evt: DragEvent) {
        evt.stopPropagation();
        evt.preventDefault();
        this.dropzone.classList.remove('dropzone-highlight');
    }

    handleDrop(evt: DragEvent) {
        evt.stopPropagation();
        evt.preventDefault();

        this.dropzone.classList.remove('dropzone-highlight');

        if (this.readOnly) {
            evt.dataTransfer.dropEffect = 'none';
        }
        else {
            evt.dataTransfer.dropEffect = 'copy';
        }

        if (this.readOnly) {
            return;
        }

        let localFiles = evt.dataTransfer.files; // FileList object.

        for (let index = 0; index < localFiles.length; index++) {
            this.uploadFile(localFiles[index]);
        }
    }
}

export class TwcFileZoneFileSelector extends TwcDropZoneFileSelector {
    filePreviewZone: HTMLElement;
    filePreviewMode: WebFilePreviewMode;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcFileZoneFileSelector';

        this.filePreviewZone = document.getElementById(`${this.id}-filezone`);
        this.filePreviewMode = (<WebFilePreviewMode>parseInt(this.filePreviewZone.dataset?.previewMode)) ?? WebFilePreviewMode.wfpCompact
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        this.filePreviewZone.classList.toggle('d-none', this.filePreviewMode === WebFilePreviewMode.wfpNone);

        if (this.filePreviewMode === WebFilePreviewMode.wfpCompact) {
            this.compactPreview();
        }
        else if (this.filePreviewMode === WebFilePreviewMode.wfpExtended) {
            this.extendedPreview();
        }
    }

    protected compactPreview() {
        // alle listitems erstellen, die noch fehlen
        for (let i = 0; i < this.files.length; i++) {
            if ($("#" + this.files[i].id).length === 0) {
                let listitem = document.createElement('div');
                $(listitem).attr("id", this.files[i].id);
                $(listitem).appendTo($(this.dropzone).siblings('.files'));
                $(listitem).addClass('list-group list-group-flush list-group-divider compact-preview');
            }
        }

        // und die li´s die es nicht mehr gibt rausschmeißen aus dem dom
        let listItems = $(this.dropzone).siblings('.files').find('div');
        for (let i = 0; i < listItems.length; i++) {
            let item = $(listItems[i]);
            let id = item.attr('id');

            // in der Fileliste nach dem Element suchen
            let found = this.files.find(element => element.id == id) !== undefined;

            // wenn nicht gefunden löschen
            if (!found) {
                $(item).remove();
            }
        }

        // Jetzt weißen wir die Daten zu und bauen HTML ;o
        for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            let listitem = $('#' + file.id);

            listitem.attr('attachment-type', file.fileType);

            let content = '';

            content = content + '<div class="list-group-item compact-preview file-thumbnail">';
            content = content + '<div class="list-group-item-figure">';
            content = content + this.displayImgByDataType(file.fileType);
            content = content + '</div>';
            content = content + '<div class="list-group-item-body">';
            content = content + '<div class="media align-items-center">';

            content = content + '<div class="media-body row">';
            content = content + '<div id="' + file.id + '-link" class="col cursor-pointer">' + file.fileName + '</div>';
            content = content + '<div class="col">' + this.bytesToSize(file.fileSize) + '</div>';
            content = content + '<div class="col"><time datetime="' + file.fileDateTime + '">' + formatDateTime(file.fileDateTime) + '</time></div>';
            content = content + '<div class="col">' + file.fileType + '</div>';
            content = content + '</div>';
            if (!this.readOnly) {
                content = content + '<div id="' + file.id + '-delete" class="cursor-pointer">';
                content = content + '<i class="fa-light fa-trash"></i>';
                content = content + '</div>';
            }
            content = content + '</div>';
            content = content + '</div>';
            content = content + '</div>';

            $(listitem).html(content);

            $('#' + file.id + '-link').click(function () {
                let cmd = new FileSelectorCommand();
                cmd.command = FileSelectorRequests.downloadFile;
                cmd.data = file.id;
                this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd))
            }.bind(this));

            if (!this.readOnly) {
                $('#' + file.id + '-delete').click(function () {
                    let cmd = new FileSelectorCommand();
                    cmd.command = FileSelectorRequests.removeFile;
                    cmd.data = file.id;
                    this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd))
                    this.updateRemoteFiles();
                }.bind(this));
            }
        }

        if (this.files.length == 0) {
            let listitem = document.createElement('div');
            $(listitem).attr('id', this.id + '-nofiles');
            $(listitem).appendTo($(this.dropzone).siblings('.files'));
            $(listitem).addClass('list-group list-group-flush list-group-divider');

            let content = this.noDataText;
            $(listitem).html(content)
        }
    }

    protected extendedPreview() {
        // alle listitems erstellen, die noch fehlen
        for (let i = 0; i < this.files.length; i++) {
            if (!assigned(document.getElementById(this.files[i].id))) {
                let listitem = document.createElement('div');
                listitem.id = this.files[i].id;
                listitem.classList.add('extended-preview');
                this.filePreviewZone.appendChild(listitem);
            }
        }

        // und die li´s die es nicht mehr gibt rausschmeißen aus dem dom
        this.filePreviewZone.querySelectorAll('div').forEach((divElement: HTMLDivElement) => {
            let id = divElement.id;

            // in der Fileliste nach dem Element suchen
            let found = this.files.find(element => element.id == id) !== undefined;

            // wenn nicht gefunden löschen
            if (!found) {
                divElement.parentNode.removeChild(divElement);
            }
        });

        // Jetzt weißen wir die Daten zu und bauen HTML ;o
        for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            let listitem = document.getElementById(file.id);

            listitem.setAttribute('attachment-type', file.fileType);

            let content = `
                <div class="thumbnail-preview">
                    <img src="${getBaseUrl(this.id)}&previewId=${file.id}" alt="Thumbnail for ${file.fileName}" data-file-link="${file.id}" />                    
                    <div id="${file.id}-delete" class="cursor-pointer ${this.readOnly ? 'd-none' : ''}">
                        <i class="fa-thin fa-trash fa-lg fw-normal thumbnail-delete"></i>
                    </div> 
                </div>

                <div class="thumbnail-caption" data-filetype="${file.fileType}" data-file-link="${file.id}">
                    <p class="thumbnail-filename mb-1">${file.fileName}</p>
                    <p class="thumbnail-filesize mb-0">${this.bytesToSize(file.fileSize)}</p>     
                </div>                           
                `;

            listitem.innerHTML = content;

            document.querySelectorAll(`[data-file-link="${file.id}"]`).forEach(linkElement => {
                linkElement.classList.add('cursor-pointer');

                linkElement.addEventListener('click', () => {
                    let cmd = new FileSelectorCommand();
                    cmd.command = FileSelectorRequests.downloadFile;
                    cmd.data = file.id;
                    this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd) as FileSelectorResponse)
                });
            });

            if (!this.readOnly) {
                let deleteElement = document.getElementById(`${file.id}-delete`);
                deleteElement.classList.add('cursor-pointer');
                deleteElement.addEventListener('click', () => {
                    let cmd = new FileSelectorCommand();
                    cmd.command = FileSelectorRequests.removeFile;
                    cmd.data = file.id;
                    this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd) as FileSelectorResponse)
                    this.updateRemoteFiles();
                });
            }
        }
    }
}