import { AsType } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcImageEx extends TWebComponent {
    imageObj: HTMLImageElement;

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcImageEx';

        this.imageObj = AsType<HTMLImageElement>(obj);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Source':
                this.imageObj.src = value;
                break;
            // UPS // Bilder haben kein disabled
            // case 'Enabled':
            //     this.imageObj.disabled = value == '0';
            //     break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Source', this.imageObj.src]);
        return properties;        
    }
}