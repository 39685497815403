﻿import Color from 'color';
import cloneDeep from 'lodash.clonedeep';
import { addVector2D, degToRad, multVector2D, Point, Rectangle, Vector2D, vector2DBetweenPoints } from '../../../../classes/geometry';
import { WebCompEventHandlerAsync } from '../../../../core/communication';
import { assigned } from '../../../../utils/helper';
import { svgRoundPolyLine } from '../../../../utils/svg';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { areEqualBaseGraphObjects, HorizontalAlignment, TBaseGraphObject, VerticalAlignment } from '../TBaseGraphObject';
import { FontStyles, TTextBase } from '../Text/TTextBase';
import { recalcExtraPoints } from '../Utils/TProcessEditorExtraPoints';
import { getIDSuffix, SVGNS } from '../Utils/TProcessEditorIDUtils';
import { ResizeDirection } from '../Utils/TProcessEditorResizeUtils';

export enum AnchorHandle { ahCenter, ahTop, ahRight, ahBottom, ahLeft }

export enum EdgeStyle { lsDefault, lsConditionalFlow, lsDefaultFlow, lsMessageFlow, lsDataAssociation }

export type EdgeIdentifier = 'dstPt' | 'srcPt' | number;

export function parseEdgeIdentifier(value: string): EdgeIdentifier {
    const edgeDst: EdgeIdentifier = 'dstPt';
    const edgeSrc: EdgeIdentifier = 'srcPt';

    if (value === edgeDst || value === edgeSrc) {
        return value
    } else {
        return parseInt(value);
    }
}

export class TEdgeBase implements TBaseGraphObject {
    private readonly HoverZoneOffset = 10;
    private visible: boolean; // Setter nutzen

    id: string;
    guid: string;
    fromNode: string;
    toNode: string;
    fromAnchor: AnchorHandle;
    toAnchor: AnchorHandle;

    protected realExtraPointsArr: Array<Point>;
    protected draftExtraPointsArr: Array<Point>;
    svgClickZone: SVGPathElement;

    public get extraPoints(): Array<Point> { return cloneDeep(assigned(this.draftExtraPointsArr) ? this.draftExtraPointsArr : this.realExtraPointsArr) }
    public get realExtraPoints(): Array<Point> { return cloneDeep(this.realExtraPointsArr) }

    arrowStyle: number;

    color: Color;
    fontColor: Color;
    fontStyles: Array<FontStyles>;
    captionElement: TTextBase;
    svgElement: SVGPathElement;
    arrowElement: SVGPathElement;
    arrowDecoration: SVGPathElement;
    editor: TwsProcessEditorCustom;
    selected: boolean;
    protected caption: string;
    captionHorzAlignment: HorizontalAlignment;
    captionVertAlignment: VerticalAlignment;
    strokeWidth: number;
    get renderedStrokeWidth(): number { return /*this.isHovered ? this.strokeWidth + this.HoverZoneOffset :*/ this.selected ? this.strokeWidth + 1 : this.strokeWidth };
    boundingRect: Rectangle;
    wordWrap: boolean;
    inlineEdit: boolean;
    srcShape: TBaseShape;
    dstShape: TBaseShape;
    itemData: string;
    private isHovered: boolean;

    get isDraft(): boolean { return assigned(this.draftExtraPointsArr) };

    constructor() {
        this.id = '';
        this.guid = '';

        this.editor = null;

        this.boundingRect = new Rectangle(0, 0, 0, 0);

        this.fromNode = '';
        this.toNode = '';
        this.fromAnchor = 0;
        this.toAnchor = 0;

        this.caption = '';

        this.captionElement = null;
        this.svgElement = null;
        this.svgClickZone = null;
        this.arrowElement = null;
        this.arrowDecoration = null;
        this.srcShape = null;
        this.dstShape = null;

        this.visible = true;

        this.captionHorzAlignment = HorizontalAlignment.taCenter;
        this.captionVertAlignment = VerticalAlignment.taVerticalCenter;
        this.wordWrap = false;
        this.inlineEdit = false;

        this.realExtraPointsArr = [];
        this.draftExtraPointsArr = null;

        this.itemData = undefined;

        this.selected = false;

        this.isHovered = false;

        this.strokeWidth = 2;
        this.arrowStyle = 0;
        this.color = Color('#000');
        // fontColor lässt sich über die Suite nicht ändern
        this.fontColor = Color('#000');
        this.fontStyles = [];
    }

    getSVGPath(edgeStyle: EdgeStyle) {
        if (assigned(this.srcShape) && assigned(this.dstShape)) {
            let srcPoint: Point = this.srcShape.getAnchorPoint(this.fromAnchor);
            let dstPoint: Point = this.dstShape.getAnchorPoint(this.toAnchor);

            // Bei Condition: Pfad an Start
            if (edgeStyle === EdgeStyle.lsConditionalFlow) {
                // ----- vorne -----
                let nextPoint: Point = this.extraPoints.length > 0 ? this.extraPoints[0] : dstPoint;
                let vector = vector2DBetweenPoints(srcPoint, nextPoint);

                // normalize
                let vLength = Math.sqrt(vector.x1 * vector.x1 + vector.x2 * vector.x2);
                let otherVector = multVector2D(vector, 1 / vLength * 17);

                // final position
                otherVector = addVector2D(srcPoint.asVector(), otherVector);
                srcPoint = new Point(otherVector.x1, otherVector.x2);
            }


            // Bei Message: Pfad an Start und Ziel verkürzen
            if (edgeStyle === EdgeStyle.lsMessageFlow) {
                // ----- vorne -----
                let nextPoint: Point = this.extraPoints.length > 0 ? this.extraPoints[0] : dstPoint;
                let vector = vector2DBetweenPoints(srcPoint, nextPoint);

                // normalize
                let vLength = Math.sqrt(vector.x1 * vector.x1 + vector.x2 * vector.x2);
                let otherVector = multVector2D(vector, 1 / vLength * 17 * 0.4);

                // final position
                otherVector = addVector2D(srcPoint.asVector(), otherVector);
                srcPoint = new Point(otherVector.x1, otherVector.x2);

                // ----- hinten -----
                let lastPoint: Point = this.extraPoints.length > 0 ? this.extraPoints[this.extraPoints.length - 1] : srcPoint;

                vector = vector2DBetweenPoints(lastPoint, dstPoint);
                otherVector = multVector2D(vector, -1);
                // normalize
                vLength = Math.sqrt(otherVector.x1 * otherVector.x1 + otherVector.x2 * otherVector.x2);
                otherVector = multVector2D(otherVector, 1 / vLength * 6);

                // final position
                otherVector = addVector2D(addVector2D(lastPoint.asVector(), vector), otherVector);
                dstPoint = new Point(otherVector.x1, otherVector.x2);
            }

            let points: Array<Point>;
            points = [];
            points.push(srcPoint);
            this.extraPoints.forEach(pt => {
                points.push(pt);
            });
            points.push(dstPoint);

            let path = svgRoundPolyLine(points, this.editor.gridSize.width / 2);

            return path;
        }
        else
            return '';
    }

    setVisible(value: boolean): void {
        if (this.visible !== value) {
            this.visible = value;

            this.invalidate();
        }
    }

    getArrowPath(edgeStyle: EdgeStyle): string {
        const h = 6;
        const w = edgeStyle === EdgeStyle.lsDataAssociation ? 6 : 3; // bei Data Association für die Öffnung den Pfeil auch weiter

        let dstPoint = this.dstShape.getAnchorPoint(this.toAnchor);
        let lastPoint = this.extraPoints.length > 0 ? this.extraPoints[this.extraPoints.length - 1] : this.srcShape.getAnchorPoint(this.fromAnchor);

        let vector = vector2DBetweenPoints(dstPoint, lastPoint);

        // normalisieren
        let vLength = Math.sqrt(vector.x1 * vector.x1 + vector.x2 * vector.x2);

        if (vLength > 0) {
            vector.x1 = vector.x1 / vLength;
            vector.x2 = vector.x2 / vLength;
        }

        let perpendicular: Vector2D = { x1: -vector.x2, x2: vector.x1 };

        let ph1 = addVector2D(addVector2D(dstPoint.asVector(), multVector2D(perpendicular, w)), multVector2D(vector, h));
        let ph2 = addVector2D(addVector2D(dstPoint.asVector(), multVector2D(perpendicular, -w)), multVector2D(vector, h));

        // bei data Associaten den Pfeil offen lassen
        if (edgeStyle === EdgeStyle.lsDataAssociation) {
            return ['M', dstPoint.x, dstPoint.y, ph1.x1, ph1.x2, dstPoint.x, dstPoint.y, ph2.x1, ph2.x2, dstPoint.x, dstPoint.y, 'Z'].join(' ');
        }
        else {
            return ['M', dstPoint.x, dstPoint.y, ph1.x1, ph1.x2, ph2.x1, ph2.x2, dstPoint.x, dstPoint.y, 'Z'].join(' ');
        }
    }

    getArrowDecoration(edgeStyle: EdgeStyle): string {
        const GRID_SIZE = 17;

        // Diamant am Start
        if (edgeStyle === EdgeStyle.lsConditionalFlow) {
            let srcPoint = this.srcShape.getAnchorPoint(this.fromAnchor);
            let nextPoint = this.extraPoints.length > 0 ? this.extraPoints[0] : this.dstShape.getAnchorPoint(this.toAnchor);

            let vector = vector2DBetweenPoints(srcPoint, nextPoint);

            // normalisieren und um ein Kästchen verschieben (Annahme: 17 pro Kästchen...)
            let vLength = Math.sqrt(vector.x1 * vector.x1 + vector.x2 * vector.x2);
            let vNormalizedGrid = multVector2D(vector, 1 / vLength * GRID_SIZE * 0.5);

            // 45° Transformation
            const angle = degToRad(20);
            let decorationleft: Vector2D = addVector2D(srcPoint.asVector(),
                {
                    x1: vNormalizedGrid.x1 * Math.cos(angle) - vNormalizedGrid.x2 * Math.sin(angle),
                    x2: vNormalizedGrid.x1 * Math.sin(angle) + vNormalizedGrid.x2 * Math.cos(angle)
                });

            // 45° Transformation
            let decorationRight: Vector2D = addVector2D(srcPoint.asVector(),
                {
                    x1: vNormalizedGrid.x1 * Math.cos(-angle) - vNormalizedGrid.x2 * Math.sin(-angle),
                    x2: vNormalizedGrid.x1 * Math.sin(-angle) + vNormalizedGrid.x2 * Math.cos(-angle)
                });

            let decorationTop: Vector2D = addVector2D(srcPoint.asVector(), multVector2D(vNormalizedGrid, 2))

            return ['M', srcPoint.x, srcPoint.y, decorationleft.x1, decorationleft.x2, decorationTop.x1, decorationTop.x2, decorationRight.x1, decorationRight.x2, srcPoint.x, srcPoint.y, 'Z'].join(' ');
        }
        // Querstrich im 45° Winkel "in der Nähe" des Anfangs
        else if (edgeStyle === EdgeStyle.lsDefaultFlow) {
            let srcPoint = this.srcShape.getAnchorPoint(this.fromAnchor);
            let nextPoint = this.extraPoints.length > 0 ? this.extraPoints[0] : this.dstShape.getAnchorPoint(this.toAnchor);

            let vector = vector2DBetweenPoints(srcPoint, nextPoint);
            // normalisieren und um ein Kästchen verschieben (Annahme: 17 pro Kästchen...)
            let vLength = Math.sqrt(vector.x1 * vector.x1 + vector.x2 * vector.x2);
            let vNormalized = multVector2D(vector, 1 / vLength);

            // Kreuzungspunkt
            let otherVector = multVector2D(vNormalized, GRID_SIZE * 0.5);
            otherVector = addVector2D(srcPoint.asVector(), otherVector);
            let intersectionPoint = new Point(otherVector.x1, otherVector.x2);

            // 45° Transformation
            let decoration: Vector2D = {
                x1: vNormalized.x1 * Math.cos(45) - vNormalized.x2 * Math.sin(45),
                x2: vNormalized.x1 * Math.sin(45) + vNormalized.x2 * Math.cos(45)
            };

            let decorationSrcPoint = addVector2D(intersectionPoint.asVector(), multVector2D(decoration, GRID_SIZE * 0.5));
            let decorationDstPoint = addVector2D(intersectionPoint.asVector(), multVector2D(decoration, -1 * GRID_SIZE * 0.5));

            return ['M', decorationSrcPoint.x1, decorationSrcPoint.x2, decorationDstPoint.x1, decorationDstPoint.x2, 'Z'].join(' ');
        }
        // kleiner Kreis am Start
        else if (edgeStyle === EdgeStyle.lsMessageFlow) {
            let srcPoint = this.srcShape.getAnchorPoint(this.fromAnchor);
            let nextPoint = this.extraPoints.length > 0 ? this.extraPoints[0] : this.dstShape.getAnchorPoint(this.toAnchor);

            let vector = vector2DBetweenPoints(srcPoint, nextPoint);
            // normalisieren und um ein Kästchen verschieben (Annahme: 17 pro Kästchen...)
            let vLength = Math.sqrt(vector.x1 * vector.x1 + vector.x2 * vector.x2);
            let circlePoint = addVector2D(srcPoint.asVector(), multVector2D(vector, 1 / vLength * GRID_SIZE * 0.2));
            let radius = GRID_SIZE * 0.2;

            return ['M', circlePoint.x1 - radius + ',', circlePoint.x2, 'a', radius + ',', radius, '0', '1,0', radius * 2 + ',0', 'a', radius + ',', radius, '0', '1,0', -radius * 2 + ',0'].join(' ');
        }

        return '';
    }

    create() {
        this.id = this.fromNode + '#' + this.toNode;
        this.srcShape = this.editor.objectById(this.fromNode) as TBaseShape;
        this.dstShape = this.editor.objectById(this.toNode) as TBaseShape;

        this.svgClickZone = document.createElementNS(SVGNS, 'path');
        this.svgElement = document.createElementNS(SVGNS, 'path');
        this.arrowElement = document.createElementNS(SVGNS, 'path');
        this.arrowDecoration = document.createElementNS(SVGNS, 'path');
        this.editor.editorSVG.appendChild(this.svgClickZone);
        this.editor.editorSVG.appendChild(this.svgElement);
        this.editor.editorSVG.appendChild(this.arrowElement);
        this.editor.editorSVG.appendChild(this.arrowDecoration);

        this.svgClickZone.setAttribute('id', getIDSuffix(this.id, 'clickzone'));
        this.svgClickZone.setAttribute('fill', 'none');
        this.svgClickZone.setAttribute('stroke-width', String(this.strokeWidth + this.HoverZoneOffset)); // die clickzone machen wir 2 Pixel größer in jede Richtung
        this.svgClickZone.setAttribute('stroke', '#333333');
        this.svgClickZone.setAttribute('stroke-opacity', '0'); // Die Zone machen wir via Transparenz unsichtbar
        this.svgClickZone.classList.add('cursor-pointer');
        // this.svgElement.setAttribute('pointer-events', 'none');
        if (this.getSVGPath(EdgeStyle.lsDefault) != '')
            this.svgClickZone.setAttribute('d', this.getSVGPath(EdgeStyle.lsDefault));

        this.svgElement.setAttribute('id', this.id);
        this.svgElement.setAttribute('fill', 'none');
        this.svgElement.setAttribute('stroke-width', String(this.renderedStrokeWidth));
        this.svgElement.setAttribute('stroke', this.color.hex());
        this.svgElement.classList.add('cursor-pointer');
        // this.svgElement.setAttribute('pointer-events', 'none');
        if (this.getSVGPath(EdgeStyle.lsDefault) != '')
            this.svgElement.setAttribute('d', this.getSVGPath(EdgeStyle.lsDefault));

        this.arrowElement.setAttribute('id', getIDSuffix(this.id, 'arrow'));
        this.arrowElement.setAttribute('fill', this.color.hex());
        this.arrowElement.setAttribute('stroke', this.color.hex());
        this.arrowElement.setAttribute('stroke-width', String(this.strokeWidth));
        this.arrowElement.setAttribute('d', this.getArrowPath(EdgeStyle.lsDefault));

        this.arrowDecoration.setAttribute('id', getIDSuffix(this.id, 'arrowDecoration'));
        this.arrowDecoration.setAttribute('fill', this.color.hex());
        this.arrowDecoration.setAttribute('stroke', this.color.hex());
        this.arrowDecoration.setAttribute('stroke-width', String(this.strokeWidth));
        this.arrowDecoration.setAttribute('d', '');


        // Wir setzen die Nodes auf dem SVGElement um per Selector die Edges zu bekommen
        this.svgElement.setAttribute('fromNode', this.fromNode);
        this.svgElement.setAttribute('toNode', this.toNode);

        this.captionElement = new TTextBase(this);
        this.captionElement.createCaption();

        this.initEvents();
    }

    delete() {
        if (document.getElementById(this.id) != null) {
            document.getElementById(this.editor.svgID).removeChild(document.getElementById(this.id));
            document.getElementById(this.editor.svgID).removeChild(document.getElementById(getIDSuffix(this.id, 'text')));
            document.getElementById(this.editor.svgID).removeChild(this.arrowElement);
            document.getElementById(this.editor.svgID).removeChild(this.arrowDecoration);
            document.getElementById(this.editor.svgID).removeChild(this.svgClickZone);

            // Und die ResizeHandler entfernen
            if (assigned(document.getElementById(getIDSuffix(this.id, 'srcPt'))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, 'srcPt')));
            // Wir bekommen vom Server bei der EdgeDelete Action leere Extrapunkte gesetzt,
            // deshalb entfernen wir alle möglichen Extrapunkte (maximal mögliche Anzahl: 4)
            for (let i = 0; i < 4; i++) {
                if (assigned(document.getElementById(getIDSuffix(this.id, String(i))))) {
                    this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, String(i))));
                }
            }
            if (assigned(document.getElementById(getIDSuffix(this.id, 'dstPt'))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, 'dstPt')));
        }
    }

    invalidate() {
        // Styles resetten!
        this.arrowElement.setAttribute('fill', this.color.hex());
        this.arrowElement.setAttribute('stroke', this.color.hex());
        this.arrowElement.setAttribute('stroke-width', String(this.renderedStrokeWidth));
        this.arrowDecoration.setAttribute('fill', this.color.hex());
        this.arrowDecoration.setAttribute('stroke', this.color.hex());
        this.arrowDecoration.setAttribute('stroke-width', String(this.renderedStrokeWidth));
        this.svgElement.setAttribute('stroke-width', String(this.renderedStrokeWidth));
        this.svgElement.removeAttribute('stroke-dasharray');

        let edgeStyle = this.styleByCode(this.itemData);

        this.svgClickZone.setAttribute('d', this.getSVGPath(edgeStyle));
        this.svgElement.setAttribute('d', this.getSVGPath(edgeStyle));
        this.arrowElement.setAttribute('d', this.getArrowPath(edgeStyle));
        this.arrowDecoration.setAttribute('d', this.getArrowDecoration(edgeStyle));

        switch (this.styleByCode(this.itemData)) {
            case EdgeStyle.lsDefault:
                // nothing
                break;
            case EdgeStyle.lsConditionalFlow:
                // hat einen nicht ausgefüllten Diamanten am Ursprung                
                this.arrowDecoration.setAttribute('fill', 'none');
                break;
            case EdgeStyle.lsDefaultFlow:
                // hat einen strikethrough nah am Ursprung
                break;
            case EdgeStyle.lsMessageFlow:
                // hat am Ursprung einen nicht ausgefüllten Kreis
                this.svgElement.setAttribute('stroke-dasharray', '20,10');
                this.arrowElement.setAttribute('fill', 'none');
                this.arrowDecoration.setAttribute('fill', 'none');
                break;
            case EdgeStyle.lsDataAssociation:
                this.svgElement.setAttribute('stroke-dasharray', '4');
                // Pfeilspitze ist "offener" Pfeil
                this.arrowElement.setAttribute('fill', 'none');
                break;
            default:
                // nothing
                break;
        }

        this.arrowDecoration.classList.toggle('d-none', !this.visible);
        this.arrowElement.classList.toggle('d-none', !this.visible);
        this.svgElement.classList.toggle('d-none', !this.visible);

        this.calculateCaptionPos();
        this.captionElement.repaintCaption();
        this.captionElement.svgElement.classList.toggle('d-none', !this.visible);
        this.repaintResizeHandlers();
    }

    repaintResizeHandlers() {
        const SIZE = 8;
        const OFFSET = SIZE / 2;

        function getHandler(owner: TEdgeBase, suffix: string): SVGRectElement {
            let id = getIDSuffix(owner.id, suffix);

            let result: SVGRectElement = document.getElementById(id) as any; // upps - type

            // wenn wir keine haben, erstellen wir sie neu
            if (!assigned(result)) {
                result = document.createElementNS(SVGNS, 'rect');
                owner.editor.editorSVG.insertBefore(result, owner.arrowElement.nextSibling);
                result.setAttribute('id', id);
                result.dataset.ownerId = owner.id;
            }

            return result;
        }

        function deleteHandler(owner: TEdgeBase, suffix: string): void {
            let id = getIDSuffix(owner.id, suffix);

            let handler = document.getElementById(id);
            if (assigned(handler))
                owner.editor.editorSVG.removeChild(handler);
        }

        function buildCursorStyleClassName(direction: string) {
            return getIDSuffix('edgeMove', direction);
        }

        function setAttributes(element: SVGRectElement, x: number, y: number, w: number, h: number, color: Color, cursorStyle: string): void {
            element.setAttribute('x', String(x - OFFSET));
            element.setAttribute('y', String(y - OFFSET));
            element.setAttribute('width', String(w));
            element.setAttribute('height', String(h));
            element.setAttribute('fill', color.hex());
            element.setAttribute('stroke', '#000');

            // resize im sinne von verschieben
            element.classList.add('edgeMove');
            // wir setzen den cursorStyle nur, wenn wir noch keinen haben
            if (!element.classList.contains(buildCursorStyleClassName('all-scroll'))
                && !element.classList.contains(buildCursorStyleClassName(ResizeDirection.vertical))
                && !element.classList.contains(buildCursorStyleClassName(ResizeDirection.horizontal))) {
                element.classList.add(cursorStyle);
            }
        }

        if (this.selected) {
            let srcHandler: SVGRectElement, extraHandler: SVGRectElement, dstHandler: SVGRectElement;
            let x: number, y: number;
            let cursorStyle: string;

            srcHandler = getHandler(this, 'srcPt');
            dstHandler = getHandler(this, 'dstPt');
            setAttributes(srcHandler, this.srcShape.getAnchorPoint(this.fromAnchor).x, this.srcShape.getAnchorPoint(this.fromAnchor).y, SIZE, SIZE, Color('#ff0000'), buildCursorStyleClassName('all-scroll'));
            setAttributes(dstHandler, this.dstShape.getAnchorPoint(this.toAnchor).x, this.dstShape.getAnchorPoint(this.toAnchor).y, SIZE, SIZE, Color('#ff0000'), buildCursorStyleClassName('all-scroll'));

            srcHandler.classList.toggle('d-none', !this.visible);
            dstHandler.classList.toggle('d-none', !this.visible);

            // resizehandler nur bei einer selektierten edge anzeigen
            if (this.editor.getSelectedEdges().length === 1) {
                this.extraPoints.forEach((extraPoint, index) => {
                    // Den letzten brauchen wir nicht
                    if (index === this.extraPoints.length - 1) {
                        return;
                    }
                    extraHandler = getHandler(this, String(index));

                    // x-coords
                    if (extraPoint.x != this.extraPoints[index + 1].x) {
                        x = extraPoint.x - Math.floor((extraPoint.x - this.extraPoints[index + 1].x) / 2);
                    }
                    else {
                        x = extraPoint.x;
                        cursorStyle = ResizeDirection.horizontal;
                    }

                    // y-coords
                    if (extraPoint.y != this.extraPoints[index + 1].y) {
                        y = extraPoint.y - Math.floor((extraPoint.y - this.extraPoints[index + 1].y) / 2);
                    }
                    else {
                        y = extraPoint.y;
                        cursorStyle = ResizeDirection.vertical;
                    }

                    setAttributes(extraHandler, x, y, SIZE, SIZE, Color('#ffff00'), buildCursorStyleClassName(cursorStyle));
                    extraHandler.classList.toggle('d-none', !this.visible);
                });
            }
        }
        else {
            deleteHandler(this, 'srcPt');
            this.extraPoints.forEach((extraPoint, index) => {
                deleteHandler(this, String(index));
            });
            deleteHandler(this, 'dstPt');
        }
    }

    styleByCode(code: string): EdgeStyle {
        switch (code?.trim() ?? '') {
            case 'A':
                return EdgeStyle.lsDefault;
            case 'B':
                return EdgeStyle.lsConditionalFlow;
            case 'C':
                return EdgeStyle.lsDefaultFlow;
            case 'D':
                return EdgeStyle.lsMessageFlow;
            case 'E':
                return EdgeStyle.lsDataAssociation;
            default:
                return EdgeStyle.lsDefault;
        }
    }

    setExtraPoints() {
        let src = this.editor.objectById(this.fromNode) as TBaseShape;
        let dst = this.editor.objectById(this.toNode) as TBaseShape;
        if (assigned(src) && assigned(dst)) {
            let srcPoint = src.getAnchorPoint(this.fromAnchor);
            let dstPoint = dst.getAnchorPoint(this.toAnchor);

            this.svgElement.setAttribute('d',
                `M ${srcPoint.x},${srcPoint.y} ${this.extraPoints.map(point => `L ${point.x},${point.y}`).join(' ')} L ${dstPoint.x},${dstPoint.y}`);
            this.svgClickZone.setAttribute('d',
                `M ${srcPoint.x},${srcPoint.y} ${this.extraPoints.map(point => `L ${point.x},${point.y}`).join(' ')} L ${dstPoint.x},${dstPoint.y}`);
            this.invalidate();
        }
        else
            return '';
    }
    correctMousePos(pt: Point): Point {
        if (assigned(this.srcShape) && assigned(this.dstShape)) {
            let srcPoint: Point = this.srcShape.getAnchorPoint(this.fromAnchor);
            let dstPoint: Point = this.dstShape.getAnchorPoint(this.toAnchor);

            // Durch die Clickzone können wir leicht daneben klicken, hier finden wir raus auf welchem Teilstueck geklickt wurde
            let newSrcPoint: Point = srcPoint;
            if (this.extraPoints.length > 0) {
                for (let i = 0; i < this.extraPoints.length; i++) {
                    let extraPoint = this.extraPoints[i];

                    // vertikal
                    if (newSrcPoint.x === extraPoint.x) {
                        // liegen wir direkt daneben ?
                        if (pt.x > (extraPoint.x - (this.editor.gridSize.width / 2)) && pt.x < (extraPoint.x + (this.editor.gridSize.width / 2))) {
                            pt.x = extraPoint.x;
                            return pt;
                        }
                        // horizontal 
                    } else if (newSrcPoint.y === extraPoint.y) {
                        if (pt.y > (extraPoint.y - (this.editor.gridSize.height / 2)) && pt.y < (extraPoint.y + (this.editor.gridSize.height / 2))) {
                            pt.y = extraPoint.y;
                            return pt;
                        }
                    }
                    newSrcPoint = extraPoint;
                }
            }
            // Noch den endpunkt checken, falls es kein extraPoint war
            if (newSrcPoint.x === dstPoint.x) {
                if (pt.x > (dstPoint.x - (this.editor.gridSize.width / 2)) && pt.x < (dstPoint.x + (this.editor.gridSize.width / 2))) {
                    pt.x = dstPoint.x;
                    return pt;
                }
            } else if (newSrcPoint.y === dstPoint.y) {
                if (pt.y > (dstPoint.y - (this.editor.gridSize.height / 2)) && pt.y < (dstPoint.y + (this.editor.gridSize.height / 2))) {
                    pt.y = dstPoint.y;
                    return pt;
                }
            }
        }
        return pt;
    }

    initEvents() {
        let handleMouseOver = () => {
            this.isHovered = true;
            this.svgElement.classList.add('edgeHovered');
            this.arrowElement.classList.add('edgeHovered');
            this.arrowDecoration.classList.add('edgeHovered');
            this.invalidate();
        };
        let handleMouseOut = () => {
            this.isHovered = false;
            this.svgElement.classList.remove('edgeHovered');
            this.arrowElement.classList.remove('edgeHovered');
            this.arrowDecoration.classList.remove('edgeHovered');
            this.invalidate();
        };

        let handleMouseDown = (event: MouseEvent) => {
            if (!this.selected) {
                if (!event.shiftKey) {
                    this.editor.clearSelectedElements();
                }
                this.selected = true;
            }

            if (event.shiftKey) {
                // bei bisherigen Edges die Handler entfernen
                this.editor.getSelectedEdges().forEach(edge => {
                    edge.extraPoints.forEach((extraPoint, index) => {
                        let handler = document.getElementById(getIDSuffix(edge.id, String(index)));
                        if (assigned(handler))
                            this.editor.editorSVG.removeChild(handler);
                    });
                });
            }

            // Und noch die selektierten Elemente übergeben
            this.editor.notifySelectedElementsChanged();
        };

        let handledblClick = (event: MouseEvent) => {
            if (!this.editor.elementEditLocked) {
                this.editor.elementEditLocked = true;

                this.editor.clearSelectedElements();
                this.selected = true;
                this.editor.invalidate();

                this.editor.notifySelectedElementsChanged();
                WebCompEventHandlerAsync('OnEdgeDoubleClick', this.editor.id, () => { this.editor.elementEditLocked = false });
            }
        };

        let handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();

            this.selected = true;
            this.editor.invalidate();

            let pt = this.editor.getLocalCoordinate(event);
            // Wir koennen auch Edges haben, die nicht auf einer Gridlinie sind
            pt = this.correctMousePos(pt);


            // Und noch die selektierten Elemente übergeben
            this.editor.notifySelectedElementsChanged();
            WebCompEventHandlerAsync('OnContextMenu', this.editor.id, null, `${Math.round(pt.x)};${Math.round(pt.y)}`);
        };

        this.svgClickZone.addEventListener('mouseover', handleMouseOver)
        this.svgElement.addEventListener('mouseover', handleMouseOver)

        this.svgClickZone.addEventListener('mouseout', handleMouseOut);
        this.svgElement.addEventListener('mouseout', handleMouseOut);

        this.svgClickZone.addEventListener('mousedown', handleMouseDown);
        this.svgElement.addEventListener('mousedown', handleMouseDown);

        this.svgClickZone.addEventListener('dblclick', handledblClick);
        this.svgElement.addEventListener('dblclick', handledblClick);

        this.svgClickZone.addEventListener('contextmenu', handleContextMenu);
        this.svgElement.addEventListener('contextmenu', handleContextMenu);
    }

    setCaption(caption: string) {
        this.caption = caption;
        this.captionElement.innerDiv.innerText = caption;
    }

    getCaption(): string {
        return this.caption;
    }

    recalcExtraPoints(): Point[] {
        return recalcExtraPoints(this.editor, this.srcShape, this.fromAnchor, this.dstShape, this.toAnchor);
    }

    private checkStartDraft(): void {
        if (!assigned(this.draftExtraPointsArr)) {
            this.draftExtraPointsArr = [];
        }
    }

    setDraftExtraPoints(value: Array<Point>, applyImmediately?: boolean): void {
        this.checkStartDraft();

        this.draftExtraPointsArr = cloneDeep(value);

        if (applyImmediately) {
            this.applyDraftExtraPoints();
        }
    }

    applyDraftExtraPoints(): void {
        if (this.isDraft) {
            this.realExtraPointsArr = this.draftExtraPointsArr;
            this.resetDraftExtraPoints();
        }

        this.setExtraPoints();
    }

    resetDraftExtraPoints(): void {
        this.draftExtraPointsArr = null;
    }

    calculateCaptionPos() {
        if (assigned(this.srcShape) && assigned(this.dstShape)) {
            let aSrcPoint: Point;
            let aDstPoint: Point;

            // Wo kommt es hin?
            if (this.extraPoints.length >= 2) {
                aSrcPoint = this.extraPoints[Math.floor(this.extraPoints.length / 2) - 1];
                aDstPoint = this.extraPoints[Math.floor(this.extraPoints.length / 2)];
            }
            if (this.extraPoints.length === 0) {
                aSrcPoint = this.srcShape.getAnchorPoint(this.fromAnchor);
                aDstPoint = this.dstShape.getAnchorPoint(this.toAnchor);
            }
            if (this.extraPoints.length === 1) {
                aSrcPoint = this.srcShape.getAnchorPoint(this.fromAnchor);
                aDstPoint = this.extraPoints[0];
            }

            // Auf Ursprung setzen
            this.captionHorzAlignment = HorizontalAlignment.taCenter;
            this.captionVertAlignment = VerticalAlignment.taVerticalCenter;

            // FAutoTextAlign aus dem SmartDrawEditor ist im Portal erstmal immer true
            if (aSrcPoint.x === aDstPoint.x) {
                this.captionHorzAlignment = HorizontalAlignment.taLeftJustify;
            }
            else {
                this.captionVertAlignment = VerticalAlignment.taAlignOuterBottom;
            }

            let x = aSrcPoint.x < aDstPoint.x ? aSrcPoint.x : aDstPoint.x;
            let y = aSrcPoint.y < aDstPoint.y ? aSrcPoint.y : aDstPoint.y;
            let w = Math.abs(aDstPoint.x - aSrcPoint.x);
            let h = Math.abs(aDstPoint.y - aSrcPoint.y);

            this.boundingRect = new Rectangle(x, y, x + w, y + h);

            // Offsets
            switch (this.captionHorzAlignment) {
                case HorizontalAlignment.taCenter:
                    break;
                case HorizontalAlignment.taLeftJustify:
                    this.boundingRect.left += 4;
                    break;
                // Diesen Fall kann es mit AutoAlign nicht geben
                // case HorizontalAlignment.taRightJustify:
                //     this.bBox.x -= 4;
                //     break;
                default:
                    throw new Error(`Invalid horizontal alignment [${this.captionHorzAlignment}].`);
            }

            switch (this.captionVertAlignment) {
                case VerticalAlignment.taVerticalCenter:
                    break;
                // Diesen Fall kann es mit AutoAlign nicht geben
                // case VerticalAlignment.taAlignOuterTop:
                //     this.bBox.y -= 4;
                //     break;
                case VerticalAlignment.taAlignOuterBottom:
                    this.boundingRect.top += 4;
                    break;
                default:
                    throw new Error(`Invalid vertical alignment [${this.captionVertAlignment}].`);
            }
        }
    }

    setBackgroundColor(backgroundColor: Color) {
        this.color = backgroundColor;

        this.svgElement.setAttribute('stroke', this.color.hex());

        this.arrowElement.setAttribute('fill', this.color.hex());
        this.arrowElement.setAttribute('stroke', this.color.hex());

        this.arrowDecoration.setAttribute('fill', this.color.hex());
        this.arrowDecoration.setAttribute('stroke', this.color.hex());
    }

    isConnectedWithShape(shape: TBaseShape): boolean {
        return areEqualBaseGraphObjects(shape, this.srcShape) || areEqualBaseGraphObjects(shape, this.dstShape);
    }
}