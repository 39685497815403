import { Popover } from 'bootstrap';
import Color from 'color';
import { getCurrentGlobalMousePosition } from '../../../../app/consense.keyboard';
import { Bounds, Point, Rectangle, Triangle } from '../../../../classes/geometry';
import { sendComponentRequestGetJsonNoError, WebCompEventHandlerAsync } from '../../../../core/communication';
import { PopoverPlacement } from '../../../../utils/bootstrap';
import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCluster } from '../class.web.comp.process.editor.cluster';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { AnchorHandle } from '../Edges/TEdgeBase';
import { HorizontalAlignment, TBaseGraphObject, VerticalAlignment } from '../TBaseGraphObject';
import { FontStyles, TTextBase } from '../Text/TTextBase';
import { getActionByTypeID } from '../Utils/TProcessEditorActionHandling';
import { getIDSuffix, SVGNS } from '../Utils/TProcessEditorIDUtils';
import { ResizeDirection } from '../Utils/TProcessEditorResizeUtils';

// WICHTIG: Die Anchorsize muss gerade sein. Hintergrund ist, dass wir die Anchor Boxen auf Halbe Pixel verschieben müssen, damit die Stroke Width von 1 (0.5 in beide Richtungen) 
// ohne Anti Aliasing klappt. Mit Anti Aliasing ist die Farbe undeutlich und der Rahmen auch blurry und dicker. 
// Dadurch, dass wir die Boxen um einen halben Pixel verschieben wird stattdessen die Größe aufgerundet, sodass wir effektiv bei einer ungeraden Anzahl Pixel in der Darstellung enden.
// Die ungerade Anzahl Pixel ist erforderlich, damit wir ordentlich zentrieren können (einer in der Mitte und dann Gerade Zahl / 2 in jede Richtung).
// Siehe auch: https://stackoverflow.com/a/23376793/6244709
const ANCHORSIZE = 12;

// übernommen aus CSDiagramClasses
export enum ShapeNotificationIcon {
    snCP, snCCP, snQS, snDSGVO, snDeprecated1, snDeprecated2, snDeprecated3,
    snDeprecated4, snSOXControlGap, snSOXControl, snSOXRisk, snSpezifikation, snSAP, snRisk,
    snWarning, snError
};

export abstract class TBaseShape implements TBaseGraphObject {
    private pendingAnimationFrames: Array<number>;
    private updatingLockCount: number;

    id: string;
    cid: number;
    guid: string;

    svgElement: any;

    roundCorners: boolean;

    protected realBounds: Rectangle;
    protected draftBounds: Rectangle;

    public get boundingRect(): Rectangle { return Rectangle.fromBounds(assigned(this.draftBounds) ? this.draftBounds : this.realBounds) }
    public get realBoundingRect(): Rectangle { return Rectangle.fromBounds(this.realBounds) };

    public get x(): number { return this.realBounds.left }
    public get y(): number { return this.realBounds.top };
    public get w(): number { return this.realBounds.width };
    public get h(): number { return this.realBounds.height };

    protected ownerPos: number;
    protected caption: string;
    protected hasMoreInformation: boolean;

    captionHorzAlignment: HorizontalAlignment;
    captionVertAlignment: VerticalAlignment;
    wordWrap: boolean;

    gradient: number;

    fontColor: Color;
    color: Color;
    strokeColor: Color;

    strokeWidth: number;
    gradientBegin: Color;
    gradientEnd: Color;
    selected: boolean;
    isResizePossible: boolean;
    isMoveable: boolean;
    hasAnchors: boolean;
    alignment: number;
    transparent: boolean;
    shadow: boolean;
    background: boolean;
    canInPlaceEdit: boolean;
    inlineEdit: boolean;
    colorFixed: boolean;
    captionElement: TTextBase;
    editor: TwsProcessEditorCustom;
    fontStyles: Array<FontStyles>;

    icons: Array<ShapeNotificationIcon>;
    // Icons müssen auf dem Shape zum Verschieben bekannt sein
    iconQS: SVGSVGElement;
    iconCP: SVGImageElement;
    iconDSGVO: SVGSVGElement;
    iconSpezifikation: SVGImageElement;
    iconSOXControlGap: SVGImageElement;
    iconSOXControl: SVGImageElement;
    iconSOXRisk: SVGImageElement;
    iconSAP: SVGImageElement;
    iconRisk: SVGImageElement;

    translationTexts: Map<ShapeNotificationIcon, string>;
    get isDraft(): boolean { return assigned(this.draftBounds) };

    constructor(editor: TwsProcessEditorCustom) {
        this.pendingAnimationFrames = [];
        this.updatingLockCount = 0;

        this.id = '';
        this.cid = undefined;
        this.guid = '';

        this.editor = editor;

        // hier merken wir uns das SVG Element
        this.svgElement = null;

        // Property für BPMN Activity, ShapeConnector(start,end) und Organisation
        this.roundCorners = false;

        // Position und Größe
        this.realBounds = new Rectangle(0, 0, 0, 0);
        this.draftBounds = null;

        // Position in der Z-Order des Prozesses
        this.ownerPos = undefined;

        this.caption = '';
        this.captionElement = null;

        this.captionHorzAlignment = HorizontalAlignment.taCenter;
        this.captionVertAlignment = VerticalAlignment.taVerticalCenter;
        this.wordWrap = true;

        this.icons = [];
        this.iconQS = null;
        this.iconCP = null;
        this.iconDSGVO = null;
        this.iconSpezifikation = null;
        this.iconSOXControlGap = null;
        this.iconSOXControl = null;
        this.iconSOXRisk = null;
        this.iconSAP = null;
        this.iconRisk = null;

        // Properties des Shapes
        this.gradient = undefined;
        this.fontColor = Color('#000');
        this.setColor(Color('#006edc'));
        this.strokeWidth = 1;
        this.gradientBegin = Color('#fff');
        this.gradientEnd = Color('#5490cc');
        this.fontStyles = [];

        this.selected = false;
        this.isMoveable = true;
        this.isResizePossible = true;
        // Delphi: TShapeOptions:
        // TShapeOption = (shoNoMove, shoNoResize, shoNoSelect, shoNoLink, shoNoLinkDuplicates,
        // shoNoContextHandles, shoNoTextEditing, shoNoCursorSelection);
        this.hasAnchors = true;
        this.alignment = 0;

        this.transparent = false;
        this.shadow = false;
        this.colorFixed = false;
        this.background = false;
        this.canInPlaceEdit = true;
        this.inlineEdit = true;

        // Das brrauchen wir teilweise relativ früh, also erstellen wir es direkt!
        // TODO: Ganzes SHape mal ordentlich initilaisieren
        this.svgElement = document.createElementNS(SVGNS, this.getSvgElementType());
    }

    beginUpdate(): void {
        this.updatingLockCount++;
    }

    endUpdate(): void {
        this.updatingLockCount--;

        // Fertig? Dann neu Darstellen
        if (!this.isUpdating()) {
            this.invalidate();
        }
    }

    isUpdating(): boolean {
        return this.updatingLockCount !== 0;
    }

    // TODO: Enum Path, Rect, Ellipse, Circle (auch nur ne Ellipse?!), ggf. mehr
    abstract getSvgElementType(): string;

    create() {
        this.editor.editorSVG.appendChild(this.svgElement);

        // set Attributes
        this.svgElement.setAttribute('id', this.id);
        this.svgElement.setAttribute('fill', this.color.hex());
        this.svgElement.setAttribute('stroke', this.strokeColor);
        this.svgElement.setAttribute('stroke-width', this.strokeWidth);

        // set Classes
        this.setClasses();

        // set Position / Size:
        switch (this.getSvgElementType()) {
            case 'path':
                this.svgElement.setAttribute('d', this.getSVGPath());
                break;
            case 'rect':
                this.svgElement.setAttribute('x', String(this.x));
                this.svgElement.setAttribute('y', String(this.y));
                if (this.roundCorners) {
                    this.svgElement.setAttribute('rx', String(this.h / 2));
                    this.svgElement.setAttribute('ry', String(this.h / 2));
                }
                this.svgElement.setAttribute('width', String(this.w));
                this.svgElement.setAttribute('height', String(this.h));
                break;
            case 'circle':
                this.svgElement.setAttribute('cx', String(this.x + this.w / 2));
                this.svgElement.setAttribute('cy', String(this.y + this.w / 2));
                this.svgElement.setAttribute('r', String(this.w / 2));
                break;
            case 'ellipse':
                this.svgElement.setAttribute('cx', String(this.x + this.w / 2));
                this.svgElement.setAttribute('cy', String(this.y + this.h / 2));
                this.svgElement.setAttribute('rx', String(this.w / 2));
                this.svgElement.setAttribute('ry', String(this.h / 2));
                break;
            default:
                throw 'unknown svg type';
        }

        this.caption = '';
        this.ownerPos = null;
        this.hasMoreInformation = false;

        this.captionElement = new TTextBase(this);
        this.captionElement.createCaption();

        this.initEvents();
    }

    getSVGPath(): string {
        throw new Error('Method not implemented.');
    }

    delete() {
        if (assigned(document.getElementById(this.id))) {
            this.editor.editorSVG.removeChild(document.getElementById(this.id));
            this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, 'text')));

            // Und die ResizeHandler entfernen
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.north))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.north)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.northEast))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.northEast)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.east))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.east)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.southEast))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.southEast)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.south))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.south)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.southWest))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.southWest)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.west))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.west)));
            if (assigned(document.getElementById(getIDSuffix(this.id, ResizeDirection.northWest))))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, ResizeDirection.northWest)));

            // und die anchor entfernen
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop])))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop])));
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight])))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight])));
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom])))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom])));
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft])))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft])));

            // ggf anchor arrows
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop] + '-arrow')))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop] + '-arrow')));
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight] + '-arrow')))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight] + '-arrow')));
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom] + '-arrow')))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom] + '-arrow')));
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft] + '-arrow')))
                this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft] + '-arrow')));

            // popovers
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop] + '-popover')))
                $(`#${getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop] + '-popover')}`).popover('hide');
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight] + '-popover')))
                $(`#${getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight] + '-popover')}`).popover('hide');
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom] + '-popover')))
                $(`#${getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom] + '-popover')}`).popover('hide');
            if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft] + '-popover')))
                $(`#${getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft] + '-popover')}`).popover('hide');

            // und die icons entfernen
            if (assigned(this.iconQS)) {
                this.editor.editorSVG.removeChild(this.iconQS);
                this.iconQS = null;
            }
            if (assigned(this.iconCP)) {
                this.editor.editorSVG.removeChild(this.iconCP);
                this.iconCP = null;
            }
            if (assigned(this.iconDSGVO)) {
                this.editor.editorSVG.removeChild(this.iconDSGVO);
                this.iconDSGVO = null;
            }
            if (assigned(this.iconSpezifikation)) {
                this.editor.editorSVG.removeChild(this.iconSpezifikation);
                this.iconSpezifikation = null;
            }
            if (assigned(this.iconSOXControlGap)) {
                this.editor.editorSVG.removeChild(this.iconSOXControlGap);
                this.iconSOXControlGap = null;
            }
            if (assigned(this.iconSOXControl)) {
                this.editor.editorSVG.removeChild(this.iconSOXControl);
                this.iconSOXControl = null;
            }
            if (assigned(this.iconSOXRisk)) {
                this.editor.editorSVG.removeChild(this.iconSOXRisk);
                this.iconSOXRisk = null;
            }
            if (assigned(this.iconRisk)) {
                this.editor.editorSVG.removeChild(this.iconRisk);
                this.iconRisk = null;
            }
            if (assigned(this.iconSAP)) {
                this.editor.editorSVG.removeChild(this.iconSAP);
                this.iconSAP = null;
            }

            this.editor.stopArrowMode(true);
        }
    }

    invalidate(): void {
        if (this.isUpdating()) {
            return;
        }

        // clear old requests
        this.pendingAnimationFrames.forEach(animationHandle => {
            window.cancelAnimationFrame(animationHandle)
        });

        // new request
        this.pendingAnimationFrames.push(window.requestAnimationFrame(() => {
            this.repaint();
        }));

    }

    protected repaint(): void {
        // Wir sind noch in der Initialisierung
        if (this.w + this.h === 0) {
            return;
        }

        switch (this.getSvgElementType()) {
            case 'path':
                this.svgElement.setAttribute('d', this.getSVGPath());
                break;
            case 'rect':
                this.svgElement.setAttribute('x', String(this.boundingRect.left));
                this.svgElement.setAttribute('y', String(this.boundingRect.top));
                if (this.roundCorners) {
                    this.svgElement.setAttribute('rx', String(this.boundingRect.height / 2)); // das ist kein copy paster fehler sondern hier wollen wir tatsächlich zweimal height, sonst haben wir ein Oval
                    this.svgElement.setAttribute('ry', String(this.boundingRect.height / 2));
                }
                this.svgElement.setAttribute('width', String(this.boundingRect.width));
                this.svgElement.setAttribute('height', String(this.boundingRect.height));
                break;
            case 'circle':
                this.svgElement.setAttribute('cx', String(this.boundingRect.left + this.boundingRect.width / 2));
                this.svgElement.setAttribute('cy', String(this.boundingRect.top + this.boundingRect.height / 2));
                this.svgElement.setAttribute('r', String(this.boundingRect.width / 2));
                break;
            case 'ellipse':
                this.svgElement.setAttribute('cx', String(this.boundingRect.left + this.boundingRect.width / 2));
                this.svgElement.setAttribute('cy', String(this.boundingRect.top + this.boundingRect.height / 2));
                this.svgElement.setAttribute('rx', String(this.boundingRect.width / 2));
                this.svgElement.setAttribute('ry', String(this.boundingRect.height / 2));
                break;
            default:
                throw 'unknown svg type';
        }

        // Verschieben oder Resize?
        if (assigned(this.draftBounds)) {
            let dx = this.draftBounds.left - this.realBounds.left;
            let dy = this.draftBounds.top - this.realBounds.top;
            let dw = (this.draftBounds.right - this.draftBounds.left) - (this.realBounds.right - this.realBounds.left);
            let dh = (this.draftBounds.top - this.draftBounds.bottom) - (this.realBounds.top - this.realBounds.bottom);

            this.repaintDynamic(dx, dy, dw, dh);
        }
        // Standard
        else {
            this.repaintDynamic(0, 0, 0, 0);
        }
    }

    private checkStartDraft(): void {
        if (!assigned(this.draftBounds)) {
            this.draftBounds = Rectangle.fromBounds(this.realBounds);
        }
    }

    setNewDraftPosition(x: number, y: number, applyImmediately?: boolean): void {
        this.checkStartDraft();

        let dx = x - this.draftBounds.left;
        let dy = y - this.draftBounds.top;

        this.draftBounds.left = x;
        this.draftBounds.top = y;
        this.draftBounds.right += dx;
        this.draftBounds.bottom += dy;

        if (applyImmediately) {
            this.applyDraftBounds();
        }
    }

    setNewDraftSize(width: number, height: number, applyImmediately?: boolean): void {
        this.checkStartDraft();

        this.draftBounds.right = this.draftBounds.left + width;
        this.draftBounds.bottom = this.draftBounds.top + height;

        if (applyImmediately) {
            this.applyDraftBounds();
        }
    }

    applyDraftBounds(): void {
        if (this.isDraft) {
            this.realBounds = Rectangle.fromBounds(this.draftBounds);
            this.resetDraftBounds();
        }
    }

    resetDraftBounds(): void {
        this.draftBounds = null;
    }

    protected repaintDynamic(dx: number, dy: number, dw: number, dh: number): void {
        this.repaintResizeHandlers();

        // Anchorsize ist eine Gerade Zahl. Damit wir kein hässliches Anti Aliasing bei einer Stroke Width von 1 bekommen müssen wir auf halbe Pixel schieben.
        this.repaintAnchorHandles(AnchorHandle.ahTop, -ANCHORSIZE / 2 + 0.5, -ANCHORSIZE * 2 + 0.5);
        this.repaintAnchorHandles(AnchorHandle.ahRight, ANCHORSIZE - 0.5, -ANCHORSIZE / 2 + 0.5);
        this.repaintAnchorHandles(AnchorHandle.ahBottom, -ANCHORSIZE / 2 + 0.5, ANCHORSIZE - 0.5);
        this.repaintAnchorHandles(AnchorHandle.ahLeft, -ANCHORSIZE * 2 + 0.5, -ANCHORSIZE / 2 + 0.5);

        this.repaintIcons();

        // Caption neu berechnen
        this.captionElement.repaintCaption();

        // Hier werden die Edges neu berechnet
        if (!assigned(this.draftBounds)) {
            let edgeElementList = document.querySelectorAll('[fromNode="' + this.id + '"], [toNode="' + this.id + '"]');

            edgeElementList.forEach(element => {
                this.editor.objectById(element.id).invalidate();
            });
        }
    }

    /**
     * 
     * @returns If the shape has resizeAnchors
     * 
     * @remarks Shapes in clustermode resize to their cells size and can not be resized manually
     */
    useResize(): boolean {
        return this.isResizePossible && !(this.editor instanceof TwsProcessEditorCluster);
    }

    repaintResizeHandlers() {
        const SIZE = 5;
        const OFFSET = SIZE / 2;
        const CLASS_PREFIX = 'resize';

        function getHandler(owner: TBaseShape, direction: ResizeDirection): SVGRectElement {
            let id = getIDSuffix(owner.id, direction);

            let result: SVGRectElement = document.getElementById(id) as any; // upps - type

            // wenn wir keine haben, erstellen wir sie neu
            if (!assigned(result)) {
                result = document.createElementNS(SVGNS, 'rect');
                owner.editor.editorSVG.appendChild(result);
                result.setAttribute('id', id);
                result.dataset.ownerId = owner.id;
            }

            return result;
        }

        function buildResizeClassName(direction: ResizeDirection): string {
            return `${CLASS_PREFIX}-${direction}`;
        }

        function deleteHandler(owner: TBaseShape, direction: ResizeDirection): void {
            let id = getIDSuffix(owner.id, direction);

            let handler = document.getElementById(id);

            if (assigned(handler))
                owner.editor.editorSVG.removeChild(handler);
        }

        function setAttributes(element: SVGRectElement, x: number, y: number, w: number, h: number, cl: string): void {
            element.setAttribute('x', String(x));
            element.setAttribute('y', String(y));
            element.setAttribute('width', String(w));
            element.setAttribute('height', String(h));
            element.setAttribute('stroke', '#2a66c8');
            element.setAttribute('fill', '#fff');
            element.classList.add('resizeHandle');
            element.classList.add(cl);
        }

        if (this.selected && this.useResize()) {
            let n: SVGRectElement, ne: SVGRectElement, e: SVGRectElement, se: SVGRectElement,
                s: SVGRectElement, sw: SVGRectElement, w: SVGRectElement, nw: SVGRectElement;

            n = getHandler(this, ResizeDirection.north);
            ne = getHandler(this, ResizeDirection.northEast);
            e = getHandler(this, ResizeDirection.east);
            se = getHandler(this, ResizeDirection.southEast);
            s = getHandler(this, ResizeDirection.south);
            sw = getHandler(this, ResizeDirection.southWest);
            w = getHandler(this, ResizeDirection.west);
            nw = getHandler(this, ResizeDirection.northWest);

            setAttributes(n, this.boundingRect.left + this.boundingRect.width / 2 - OFFSET, this.boundingRect.top - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.north));
            setAttributes(ne, this.boundingRect.right - OFFSET, this.boundingRect.top - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.northEast));
            setAttributes(e, this.boundingRect.right - OFFSET, this.boundingRect.top + this.boundingRect.height / 2 - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.east));
            setAttributes(se, this.boundingRect.right - OFFSET, this.boundingRect.bottom - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.southEast));
            setAttributes(s, this.boundingRect.left + this.boundingRect.width / 2 - OFFSET, this.boundingRect.bottom - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.south));
            setAttributes(sw, this.boundingRect.left - OFFSET, this.boundingRect.bottom - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.southWest));
            setAttributes(w, this.boundingRect.left - OFFSET, this.boundingRect.top + this.boundingRect.height / 2 - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.west));
            setAttributes(nw, this.boundingRect.left - OFFSET, this.boundingRect.top - OFFSET, SIZE, SIZE, buildResizeClassName(ResizeDirection.northWest));
        }
        else {
            deleteHandler(this, ResizeDirection.north);
            deleteHandler(this, ResizeDirection.northEast);
            deleteHandler(this, ResizeDirection.east);
            deleteHandler(this, ResizeDirection.southEast);
            deleteHandler(this, ResizeDirection.south);
            deleteHandler(this, ResizeDirection.southWest);
            deleteHandler(this, ResizeDirection.west);
            deleteHandler(this, ResizeDirection.northWest);
        }
    }

    repaintAnchorHandles(anchorHandle: AnchorHandle, offsetX: number, offsetY: number) {
        let element = document.getElementById(getIDSuffix(this.id, AnchorHandle[anchorHandle]));
        let arrowImage = document.getElementById(getIDSuffix(this.id, AnchorHandle[anchorHandle] + '-arrow'));
        let point: Point;

        if (assigned(element)) {
            point = this.getAnchorPoint(anchorHandle);
            element.setAttribute('x', String(point.x + offsetX));
            element.setAttribute('y', String(point.y + offsetY));
        }

        if (assigned(arrowImage)) {
            point = this.getAnchorPoint(anchorHandle);
            arrowImage.setAttribute('x', String(point.x + offsetX));
            arrowImage.setAttribute('y', String(point.y + offsetY));
        }
    }

    repaintIcons() {
        /* 
          Diese Funktion deckt erstmal nur das Verhalten der alten Icons ab.
          Kunden sollen in Zukunft die Möglichkeit bekommen, eigene SVGs in den
          Assets abzulegen und diese dann zu benutzen.
          Dazu soll es eine Box geben, in der die Icons nach einer sortierbaren
          Nummerierung abgelegt werden -> cp,dsgvo,bsp1,bsp2
        */
        const SOXICONWIDTH = 14,
            SOXICONHEIGHT = 14,
            SOXICONSPACER = 3;

        function setAttributes(parent: TBaseShape, element: SVGGraphicsElement, iconType: string, x: number, y: number, w: number, h: number) {
            element.setAttribute('id', getIDSuffix(parent.id, iconType));
            if (element instanceof SVGImageElement)
                element.setAttribute('href', '/assets/images/shapes/processIcons/' + iconType + '.svg');
            element.setAttribute('x', String(x));
            element.setAttribute('y', String(y));
            element.setAttribute('width', String(w));
            element.setAttribute('height', String(h));
            element.setAttribute('pointer-events', 'none');
        }

        function deleteIcon(parent: TBaseShape, icon: SVGGraphicsElement) {
            if (assigned(icon)) {
                parent.editor.editorSVG.removeChild(icon);
            }
        }

        let xPos = this.boundingRect.right;

        // QS
        if (this.icons.includes(ShapeNotificationIcon.snQS)) {
            xPos -= 24;
            if (!assigned(this.iconQS)) {
                this.iconQS = document.createElementNS(SVGNS, 'svg');
                let g = document.createElementNS(SVGNS, 'g');
                let rect = document.createElementNS(SVGNS, 'rect');
                let text = document.createElementNS(SVGNS, 'text');

                setAttributes(this, rect, ShapeNotificationIcon[ShapeNotificationIcon.snQS] + '-rect', 0, 0, 24, 12);
                rect.setAttribute('fill', '#F00')

                setAttributes(this, text, ShapeNotificationIcon[ShapeNotificationIcon.snQS] + '-text', 5, 10, 24, 12);
                let translationText = this.translationTexts[ShapeNotificationIcon.snQS];
                if (translationText != '')
                    text.textContent = translationText
                else
                    text.textContent = 'QS';
                text.setAttribute('font-size', '11px')
                text.setAttribute('fill', '#FFF')
                text.setAttribute('font-family', 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif');
                text.classList.add('noselect');

                g.appendChild(rect);
                g.appendChild(text);
                this.iconQS.appendChild(g);
                this.editor.editorSVG.insertBefore(this.iconQS, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconQS, ShapeNotificationIcon[ShapeNotificationIcon.snQS], xPos - 1, this.boundingRect.bottom - 12, 24, 12);
        }
        else if (assigned(this.iconQS)) {
            deleteIcon(this, this.iconQS);
            this.iconQS = null;
        }
        // ControlPoint || CriticalControlPoint
        if (this.icons.includes(ShapeNotificationIcon.snCCP) || this.icons.includes(ShapeNotificationIcon.snCP)) {
            xPos -= 24;
            if (!assigned(this.iconCP)) {
                this.iconCP = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconCP, this.captionElement.svgElement.nextSibling);
            }
            if (this.icons.includes(ShapeNotificationIcon.snCP)) {
                setAttributes(this, this.iconCP, ShapeNotificationIcon[ShapeNotificationIcon.snCP], xPos - 1, this.boundingRect.bottom - 12, 24, 12);
            }
            else if (this.icons.includes(ShapeNotificationIcon.snCCP)) {
                setAttributes(this, this.iconCP, ShapeNotificationIcon[ShapeNotificationIcon.snCCP], xPos - 1, this.boundingRect.bottom - 12, 24, 12);
            }

        }
        else if (assigned(this.iconCP)) {
            deleteIcon(this, this.iconCP);
            this.iconCP = null;
        }
        //DSGVO
        if (this.icons.includes(ShapeNotificationIcon.snDSGVO)) {
            xPos -= 48;
            if (!assigned(this.iconDSGVO)) {
                this.iconDSGVO = document.createElementNS(SVGNS, 'svg');
                let g = document.createElementNS(SVGNS, 'g');
                let rect = document.createElementNS(SVGNS, 'rect');
                let text = document.createElementNS(SVGNS, 'text');

                setAttributes(this, rect, ShapeNotificationIcon[ShapeNotificationIcon.snDSGVO] + '-rect', 0, 0, 48, 12);
                rect.setAttribute('fill', '#FFF')

                setAttributes(this, text, ShapeNotificationIcon[ShapeNotificationIcon.snDSGVO] + '-text', 5, 10, 48, 12);
                let translationText = this.translationTexts[ShapeNotificationIcon.snDSGVO];
                if (translationText != '')
                    text.textContent = translationText
                else
                    text.textContent = 'DSGVO';
                text.setAttribute('font-size', '11px')
                text.setAttribute('fill', '#F00')
                text.setAttribute('font-family', 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif');
                text.classList.add('noselect');

                g.appendChild(rect);
                g.appendChild(text);
                this.iconDSGVO.appendChild(g);
                this.editor.editorSVG.insertBefore(this.iconDSGVO, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconDSGVO, ShapeNotificationIcon[ShapeNotificationIcon.snDSGVO], xPos - 1, this.boundingRect.bottom - 12, 48, 12);
        }
        else if (assigned(this.iconDSGVO)) {
            deleteIcon(this, this.iconDSGVO);
            this.iconDSGVO = null;
        }
        // Spezifikation
        if (this.icons.includes(ShapeNotificationIcon.snSpezifikation)) {
            if (!assigned(this.iconSpezifikation)) {
                this.iconSpezifikation = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconSpezifikation, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconSpezifikation, ShapeNotificationIcon[ShapeNotificationIcon.snSpezifikation], this.boundingRect.left + 4, this.boundingRect.top + this.boundingRect.height / 2 - 8, 16, 16);
        }
        else if (assigned(this.iconSpezifikation)) {
            deleteIcon(this, this.iconSpezifikation);
            this.iconSpezifikation = null;
        }

        // SOX
        xPos = this.boundingRect.right + SOXICONSPACER;
        let yPos = this.boundingRect.top - SOXICONHEIGHT - SOXICONSPACER;

        if (this.icons.includes(ShapeNotificationIcon.snSOXControlGap)) {
            xPos = xPos - SOXICONWIDTH - SOXICONSPACER;
            if (!assigned(this.iconSOXControlGap)) {
                this.iconSOXControlGap = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconSOXControlGap, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconSOXControlGap, ShapeNotificationIcon[ShapeNotificationIcon.snSOXControlGap], xPos, yPos, 18, 18);
        }
        else if (assigned(this.iconSOXControlGap)) {
            deleteIcon(this, this.iconSOXControlGap);
            this.iconSOXControlGap = null;
        }
        if (this.icons.includes(ShapeNotificationIcon.snSOXControl)) {
            xPos = xPos - SOXICONWIDTH - SOXICONSPACER;
            if (!assigned(this.iconSOXControl)) {
                this.iconSOXControl = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconSOXControl, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconSOXControl, ShapeNotificationIcon[ShapeNotificationIcon.snSOXControl], xPos, yPos, 18, 18);
        }
        else if (assigned(this.iconSOXControl)) {
            deleteIcon(this, this.iconSOXControl);
            this.iconSOXControl = null;
        }
        if (this.icons.includes(ShapeNotificationIcon.snSOXRisk)) {
            xPos = xPos - SOXICONWIDTH - SOXICONSPACER;
            if (!assigned(this.iconSOXRisk)) {
                this.iconSOXRisk = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconSOXRisk, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconSOXRisk, ShapeNotificationIcon[ShapeNotificationIcon.snSOXRisk], xPos, yPos, 18, 18);
        }
        else if (assigned(this.iconSOXRisk)) {
            deleteIcon(this, this.iconSOXRisk);
            this.iconSOXRisk = null;
        }
        // sap
        if (this.icons.includes(ShapeNotificationIcon.snSAP)) {
            if (!assigned(this.iconSAP)) {
                this.iconSAP = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconSAP, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconSAP, ShapeNotificationIcon[ShapeNotificationIcon.snSAP], this.boundingRect.left - 4, this.boundingRect.top - 4, 16, 16);
        }
        else if (assigned(this.iconSAP)) {
            deleteIcon(this, this.iconSAP);
            this.iconSAP = null;
        }
        // risk
        if (this.icons.includes(ShapeNotificationIcon.snRisk)) {
            if (!assigned(this.iconRisk)) {
                this.iconRisk = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconRisk, this.captionElement.svgElement.nextSibling);
            }
            setAttributes(this, this.iconRisk, ShapeNotificationIcon[ShapeNotificationIcon.snRisk], xPos - 12, this.boundingRect.top - 8, 16, 16);
        }
        else if (assigned(this.iconRisk)) {
            deleteIcon(this, this.iconRisk);
            this.iconRisk = null;
        }
    }

    setCaption(caption: string): void {
        this.caption = caption;

        this.updateDisplayCaption();
    }

    setHasMoreInformation(value: boolean): void {
        this.hasMoreInformation = value;

        this.updateDisplayCaption();
    }

    getCaption(): string {
        return this.caption;
    }

    setOwnerPos(pos: number) {
        this.ownerPos = pos;
        this.updateDisplayCaption();
    }

    getOwnerPos(): number {
        return this.ownerPos;
    }

    // UPS // wird u. U. mehrfach aufgerufen -> Invalidate Logik für die Zukunft
    updateDisplayCaption() {
        let finalCaption = this.caption;

        if (this.hasMoreInformation) {
            finalCaption += this.editor.suffixStyle;
        }

        if (this.editor.disableAutoOrder) {
            finalCaption = this.ownerPos + 1 + ': ' + finalCaption;
        }

        this.captionElement.innerDiv.innerText = finalCaption;
    }

    setClasses() {
        this.svgElement.classList.toggle('draggable', this.isMoveable);
        this.svgElement.classList.toggle('shapeSelected', this.selected);
    }

    setColor(color: Color) {
        this.color = color;
        this.strokeColor = this.color.darken(0.2);

        // Font Color berechnen
        // Bei Gradient erst bei dunkleren Farben wegen des helleren Farbverlaufs!!!
        let brightnessTreshhold = this.editor.colorMode === 1 ? 85 : 140;
        let rgb = [color.red(), color.green(), color.blue()];
        let yiq = (rgb[0] * 300 + rgb[1] * 590 + rgb[2] * 110) / 1000;

        this.fontColor = (yiq < brightnessTreshhold) ? new Color('#fff') : new Color('#000');
    }

    buildGradientID(): string {
        return `gradient-${this.gradientBegin.hex()}-${this.gradientEnd.hex()}`;
    }

    setBackgroundColor(backgroundColor: Color, gradient: number) {
        this.setColor(backgroundColor);
        this.gradient = gradient;

        if (this.gradient > 0 && this.editor.colorMode === 1) {
            this.gradientBegin = Color('#fff');
            this.gradientEnd = backgroundColor;

            // wir gucken ob es den Gradienten schon gibt
            // ansonsten erstellen wir ihn neu
            if (!document.getElementById(this.buildGradientID())) {
                let gradient = document.createElementNS(SVGNS, 'linearGradient');
                gradient.setAttribute('id', this.buildGradientID());
                gradient.setAttribute('x1', '0%');
                gradient.setAttribute('x2', '0%');
                gradient.setAttribute('y1', '0%');
                gradient.setAttribute('y2', '100%');

                // Die Offsets und Colors
                let gradBegin = document.createElementNS(SVGNS, 'stop');
                gradBegin.setAttribute('offset', '0%');
                gradBegin.setAttribute('stop-color', this.gradientBegin.hex());
                let gradEnd = document.createElementNS(SVGNS, 'stop');
                gradEnd.setAttribute('offset', '100%');
                gradEnd.setAttribute('stop-color', this.gradientEnd.hex());

                gradient.appendChild(gradBegin);
                gradient.appendChild(gradEnd);

                this.editor.svgDefs.appendChild(gradient);
            }

            this.svgElement.setAttribute('fill', `url(#${this.buildGradientID()})`);
        }
        else {
            this.svgElement.setAttribute('fill', this.color.hex());
        }

        // set border
        this.svgElement.setAttribute('stroke', this.strokeColor.hex());
    }

    /**
     * Returns boolean if element is a fixed and not interactable part of the process
     * @returns
     */
    isFixedElement(): boolean {
        return false;
    }

    initEvents() {
        // todo: move und click müssen eventuell beide über das mouseup event gesteuert werden 
        // - dabei sollte dann ein delta wert eingeführt werden, ab dem die beiden events unterschieden werden

        this.svgElement.addEventListener('mousedown', event => {
            if (!this.editor.isArrowMode) {
                // Wenn wir eigentlich garkein inteagierbares Element sind, tun wir so als ob das auf dem Editor passiert wäre
                if (this.isFixedElement()) {
                    this.editor.handleMouseDownEvent(event);
                    return;
                }

                if (!this.selected) {
                    if (!(event.shiftKey || event.ctrlKey)) {
                        this.editor.clearSelectedElements();
                    }
                    this.selected = true;
                }
                // selected and modifier key
                else if (event.shiftKey || event.ctrlKey) {
                    this.selected = false;
                }

                // Und noch die selektierten Elemente übergeben
                this.editor.notifySelectedElementsChanged();
            }
        });

        this.svgElement.addEventListener('mouseup', event => {
            if (this.editor.isArrowMode) {
                // Sektor bestimmen; dazu brauchen wir erstmal unsere Mitte und den Klickpunkt
                let click = this.editor.getLocalCoordinate(event);
                let center = new Point(this.x + this.w / 2, this.y + this.h / 2);

                // oben
                let triangle = new Triangle(new Point(this.x, this.y), new Point(this.x + this.w, this.y), center);
                if (triangle.checkPointIsInside(click)) {
                    this.editor.setTargetShape(this, AnchorHandle.ahTop);
                    this.removeAnchors();
                    return;
                }

                // links
                triangle = new Triangle(new Point(this.x, this.y), new Point(this.x, this.y + this.h), center);
                if (triangle.checkPointIsInside(click)) {
                    this.editor.setTargetShape(this, AnchorHandle.ahLeft);
                    this.removeAnchors();
                    return;
                }

                // unten
                triangle = new Triangle(new Point(this.x, this.y + this.h), new Point(this.x + this.w, this.y + this.h), center);
                if (triangle.checkPointIsInside(click)) {
                    this.editor.setTargetShape(this, AnchorHandle.ahBottom);
                    this.removeAnchors();
                    return;
                }

                // rechts
                triangle = new Triangle(new Point(this.x + this.w, this.y), new Point(this.x + this.w, this.y + this.h), center);
                if (triangle.checkPointIsInside(click)) {
                    this.editor.setTargetShape(this, AnchorHandle.ahRight);
                    this.removeAnchors();
                    return;
                }

                console.warn('Could not determine shape sector!');
            }
        })

        this.svgElement.addEventListener('dblclick', () => {
            if (!this.editor.elementEditLocked) {
                this.editor.elementEditLocked = true;

                this.editor.clearSelectedElements();
                this.selected = true;
                this.editor.invalidate();

                this.editor.notifySelectedElementsChanged();
                WebCompEventHandlerAsync('OnShapeDoubleClick', this.editor.id);
            }
        });

        this.svgElement.addEventListener('contextmenu', (event: MouseEvent) => {
            event.preventDefault();

            let pt = this.editor.getLocalCoordinatesFromMouseEvent(event);

            // snap to grid     
            pt.x = this.editor.snapToUsedGridX(pt.x);
            pt.y = this.editor.snapToUsedGridX(pt.y);

            WebCompEventHandlerAsync('OnContextMenu', this.editor.id, null, `${Math.round(pt.x)};${Math.round(pt.y)}`);
        });

        let anchorSpawnTimer: number = null;
        let popOverActive = new Map<AnchorHandle, boolean>();

        this.svgElement.addEventListener('mouseenter', () => {

            if (assigned(this.editor.draftEdge)) {
                // shapes can't connect to themselves
                // shapes can't connect to already connected shapes
                this.editor.draftEdge.classList.toggle('shapeValid', this.hasAnchors && (this !== this.editor.arrowSourceShape) && !(assigned(this.editor.objectById(this.editor.arrowSourceShape.id + '#' + this.id))));
                this.editor.draftEdge.classList.toggle('shapeInvalid', this === this.editor.arrowSourceShape || assigned(this.editor.objectById(this.editor.arrowSourceShape.id + '#' + this.id)));
            }

            if (this.hasAnchors) {
                window.clearTimeout(anchorSpawnTimer);
                anchorSpawnTimer = window.setTimeout(() => {

                    function createSourceShapeAnchorHandle(owner: TBaseShape, pos: AnchorHandle, offsetX: number, offsetY: number) {

                        let popOver: Popover = null;

                        // Wenn wir den Anchor schon haben, fügen wir keinen neuen hinzu
                        // zB beim sehr schnellen verschoben eines shapes und damit verbundenem mouseleave
                        if (!document.getElementById(getIDSuffix(owner.id, AnchorHandle[pos]))) {
                            let anchor = document.createElementNS(SVGNS, 'rect');
                            let anchorPoint = owner.getAnchorPoint(pos);
                            let anchorArrow = document.createElementNS(SVGNS, 'image');
                            owner.editor.editorSVG.appendChild(anchor);
                            owner.editor.editorSVG.insertBefore(anchorArrow, anchor.nextSibling);

                            anchor.setAttribute('id', getIDSuffix(owner.id, AnchorHandle[pos]));
                            anchor.setAttribute('x', String(anchorPoint.x + offsetX));
                            anchor.setAttribute('y', String(anchorPoint.y + offsetY));
                            anchor.setAttribute('width', String(ANCHORSIZE));
                            anchor.setAttribute('height', String(ANCHORSIZE));
                            anchor.setAttribute('stroke', '#000');
                            anchor.setAttribute('stroke-width', '1');
                            anchor.setAttribute('fill', '#fff');

                            anchor.classList.add('cs-processeditor-quickMenu');

                            anchorArrow.setAttribute('id', getIDSuffix(owner.id, AnchorHandle[pos] + '-arrow'));
                            anchorArrow.setAttribute('href', 'assets/images/shapes/processIcons/' + AnchorHandle[pos] + '.svg');
                            anchorArrow.setAttribute('x', String(anchorPoint.x + offsetX));
                            anchorArrow.setAttribute('y', String(anchorPoint.y + offsetY));
                            anchorArrow.setAttribute('width', String(ANCHORSIZE));
                            anchorArrow.setAttribute('height', String(ANCHORSIZE));
                            anchorArrow.setAttribute('pointer-events', 'none');

                            anchor.addEventListener('mousedown', (event) => {
                                // Wenn wir schon im Arrowmode sind, brechen wir ab
                                if (owner.editor.isArrowMode) {
                                    owner.editor.stopArrowMode(true);
                                    return;
                                }

                                owner.editor.startArrowMode(owner, pos, null, owner.editor.getLocalCoordinate(event));
                            });

                            anchor.addEventListener('mouseenter', () => {
                                showPopover(anchor, anchorPoint);

                                if (assigned(owner.editor.draftEdge)) {
                                    // shapes can't connect to themselves
                                    owner.editor.draftEdge.classList.toggle('anchorInvalid', !((owner.editor.arrowSourceShape !== owner) && !assigned(owner.editor.objectById(owner.editor.arrowSourceShape.id + '#' + owner.id))));
                                };
                            });

                            anchor.addEventListener('mouseleave', () => {
                                if (assigned(owner.editor.draftEdge)) {
                                    owner.editor.draftEdge.classList.toggle('anchorInvalid', false);
                                };
                            });
                        }

                        function showPopover(anchorElement: SVGElement, anchorPoint: Point) {
                            let editorPos = owner.editor.editorSVG.getBoundingClientRect();

                            let popOffsetX = 0;
                            let popOffsetY = 0;

                            // Pop Richtung und Offsets bestimmen
                            let popPlacement: PopoverPlacement = 'auto';

                            switch (pos) {
                                case AnchorHandle.ahTop:
                                    popPlacement = 'top';
                                    popOffsetY = offsetY;
                                    break;
                                case AnchorHandle.ahRight:
                                    popOffsetX = offsetX + ANCHORSIZE;
                                    popPlacement = 'right';
                                    break;
                                case AnchorHandle.ahBottom:
                                    popPlacement = 'bottom';
                                    popOffsetY = offsetY + ANCHORSIZE;
                                    break;
                                case AnchorHandle.ahLeft:
                                    popPlacement = 'left';
                                    popOffsetX = offsetX;
                                    break;
                            }

                            let popOverId = getIDSuffix(anchorElement.id, 'popover')
                            anchorElement.dataset.popOverId = popOverId;

                            let popOverElement = document.getElementById(popOverId)
                            if (!assigned(popOverElement)) {
                                popOverElement = document.createElement('span');

                                popOverElement.id = popOverId;
                                popOverElement.classList.add('popover-anchor');
                                popOverElement.style.position = 'absolute';

                                let contentId = getIDSuffix(popOverId, 'content');
                                let content = document.createElement('div');
                                content.id = contentId

                                let hasAddedShapes = false;
                                let addShape = (cid: number) => {
                                    hasAddedShapes = true;

                                    // create button
                                    let buttonId = getIDSuffix(contentId, `-${cid}`);
                                    let buttonDiv = document.createElement('div');
                                    buttonDiv.id = buttonId
                                    buttonDiv.dataset.cid = String(cid);
                                    buttonDiv.dataset.shapeId = owner.id;
                                    buttonDiv.dataset.shapeDirection = String(pos);

                                    // image
                                    let image = document.createElement('img');
                                    image.src = `/assets/images/shapes/${cid}.svg`;
                                    image.width = 30;

                                    buttonDiv.append(image);
                                    buttonDiv.classList.add('cursor-pointer');

                                    // events
                                    buttonDiv.addEventListener('click', (event: MouseEvent) => {
                                        if (assigned(popOver)) {

                                            let response = sendComponentRequestGetJsonNoError(owner.editor.id, {
                                                Action: 'QuickShape',
                                                SourceShape: owner.id,
                                                CID: cid,
                                                Anchor: pos,
                                            }) as any;

                                            let action = getActionByTypeID(response.actionType, null, owner.editor);
                                            action.fromJSON(response);
                                            action.performAction();

                                            owner.editor.actionList.addAction(action);
                                            owner.editor.checkEditorSize();

                                            popOverActive.set(pos, false)
                                            popOver.hide();

                                            WebCompEventHandlerAsync('OnClientQuickShape', owner.editor.id, function () { });
                                        }
                                    });

                                    // ins content packen
                                    content.append(buttonDiv);
                                }

                                owner.editor.allowedQuickShapeActions.forEach(cid => {
                                    addShape(cid);
                                })

                                if (!hasAddedShapes) {
                                    return;
                                }

                                document.body.append(popOverElement);
                                document.body.append(content);

                                popOver = new Popover(popOverElement, {
                                    content: content,
                                    html: true,
                                    placement: popPlacement,
                                    trigger: 'manual',
                                });
                            }
                            else {
                                popOver = Popover.getInstance(popOverElement);
                            }

                            // Positionen müssen wir wegen scrollen immer neu berechnen
                            // Da das PopOver kein Teil des SVGs ist müssen wir für das PopOver den Zoom manuell mit einkalkulieren
                            // Aber nur auf die Werte, welche Koordination innerhalb des Editors darstellen.                                                        
                            popOverElement.style.left = `${window.pageXOffset + editorPos.left + owner.editor.zoomFactor * (anchorPoint.x + popOffsetX)}px`;
                            popOverElement.style.top = `${window.pageYOffset + editorPos.top + owner.editor.zoomFactor * (anchorPoint.y + popOffsetY)}px`;

                            // andere popovers schließen...
                            for (const handle in AnchorHandle) {
                                // enums in TS sind ein Array aus Number und String Werten (der Namen). Wir brauchen hier die NumberWerte!
                                let num = Number(handle);
                                if (isNaN(num) || num === pos) {
                                    continue;
                                }

                                // try close
                                $(`#${getIDSuffix(getIDSuffix(owner.id, AnchorHandle[handle]), 'popover')}`).popover('hide');
                                popOverActive.set(num, false);
                            }

                            popOver.show();
                            popOverActive.set(pos, true);
                        }
                    }

                    function createDestinationShapeAnchorHandle(owner: TBaseShape, pos: AnchorHandle, offsetX: number, offsetY: number) {
                        if (!document.getElementById(getIDSuffix(owner.id, AnchorHandle[pos]))) {
                            let anchor = document.createElementNS(SVGNS, 'rect');
                            let anchorPoint = owner.getAnchorPoint(pos);
                            owner.editor.editorSVG.appendChild(anchor);
                            anchor.setAttribute('id', getIDSuffix(owner.id, AnchorHandle[pos]));
                            anchor.setAttribute('x', String(anchorPoint.x + offsetX));
                            anchor.setAttribute('y', String(anchorPoint.y + offsetY));
                            anchor.setAttribute('width', String(ANCHORSIZE));
                            anchor.setAttribute('height', String(ANCHORSIZE));
                            anchor.setAttribute('stroke', '#000');
                            anchor.setAttribute('fill', '#fff');

                            anchor.addEventListener('mouseenter', () => {
                                if (assigned(owner.editor.draftEdge)) {
                                    // shapes can't connect to themselves
                                    // shapes can't connect to already connected shapes
                                    owner.editor.draftEdge.classList.toggle('anchorValid', (owner.editor.arrowSourceShape !== owner) && !assigned(owner.editor.objectById(owner.editor.arrowSourceShape.id + '#' + owner.id)));
                                    owner.editor.draftEdge.classList.toggle('anchorInvalid', !((owner.editor.arrowSourceShape !== owner) && !assigned(owner.editor.objectById(owner.editor.arrowSourceShape.id + '#' + owner.id))));
                                };
                            });

                            anchor.addEventListener('mouseleave', () => {
                                if (assigned(owner.editor.draftEdge)) {
                                    owner.editor.draftEdge.classList.toggle('anchorValid', false);
                                    owner.editor.draftEdge.classList.toggle('anchorInvalid', false);
                                };
                            });

                            anchor.addEventListener('mousedown', () => {
                                owner.editor.setTargetShape(owner, pos);
                                owner.removeAnchors();
                            });
                        }
                    }

                    if (!this.editor.isArrowMode) {
                        // Anchorsize ist eine Gerade Zahl. Damit wir kein hässliches Anti Aliasing bei einer Stroke Width von 1 bekommen müssen wir auf halbe Pixel schieben.
                        createSourceShapeAnchorHandle(this, AnchorHandle.ahTop, -ANCHORSIZE / 2 + 0.5, -ANCHORSIZE * 2 + 0.5);
                        createSourceShapeAnchorHandle(this, AnchorHandle.ahRight, ANCHORSIZE - 0.5, -ANCHORSIZE / 2 + 0.5);
                        createSourceShapeAnchorHandle(this, AnchorHandle.ahBottom, -ANCHORSIZE / 2 + 0.5, ANCHORSIZE - 0.5);
                        createSourceShapeAnchorHandle(this, AnchorHandle.ahLeft, -ANCHORSIZE * 2 + 0.5, -ANCHORSIZE / 2 + 0.5);
                    }
                    else {
                        createDestinationShapeAnchorHandle(this, AnchorHandle.ahTop, -ANCHORSIZE / 2, -ANCHORSIZE / 2);
                        createDestinationShapeAnchorHandle(this, AnchorHandle.ahRight, -ANCHORSIZE / 2, -ANCHORSIZE / 2);
                        createDestinationShapeAnchorHandle(this, AnchorHandle.ahBottom, -ANCHORSIZE / 2, -ANCHORSIZE / 2);
                        createDestinationShapeAnchorHandle(this, AnchorHandle.ahLeft, -ANCHORSIZE / 2, -ANCHORSIZE / 2);
                    }
                }, 100);
            }
        });

        this.svgElement.addEventListener('mouseleave', (event: MouseEvent) => {
            if (assigned(this.editor.draftEdge)) {
                this.editor.draftEdge.classList.toggle('shapeValid', false);
                this.editor.draftEdge.classList.toggle('shapeInvalid', false);
            }

            let anchorBounds = new Bounds(this.x - ANCHORSIZE * 2.5, this.y - ANCHORSIZE * 2.5, this.x + this.w + ANCHORSIZE * 2.5, this.y + this.h + ANCHORSIZE * 2.5);

            popOverActive.clear();

            let anchorDespawnInterval = window.setInterval(() => {
                let isDespawnAllowed = true;

                popOverActive.forEach((value, key) => {
                    // soll es hier ein popover geben?
                    if (value) {
                        // falls ja holen wir uns über den anchor die ID
                        let anchor = document.getElementById(getIDSuffix(this.id, AnchorHandle[key]));
                        let popOverId = anchor?.dataset?.popOverId;
                        // und versuchen es zu finden
                        if (assigned(popOverId)) {
                            let contentElement = document.getElementById(getIDSuffix(popOverId, 'content'));
                            if (assigned(contentElement)) {
                                // wenn wir eins finden können wir die position prüfen
                                let popOverPosition = Bounds.fromDOMRect(contentElement.getBoundingClientRect());
                                // 10px Padding + "Spielraum", welcher mit dem Zoom skaliert
                                popOverPosition.expand(12 + 10 * this.editor.zoomFactor);
                                if (popOverPosition.checkPointIsInside(getCurrentGlobalMousePosition())) {
                                    isDespawnAllowed = false;
                                    return;
                                }
                            }
                        }
                    }
                });

                if (isDespawnAllowed && !anchorBounds.checkPointIsInside(this.editor.getLocalCoordinate(getCurrentGlobalMousePosition()))) {
                    window.clearTimeout(anchorSpawnTimer);
                    window.clearInterval(anchorDespawnInterval);
                    this.removeAnchors();
                }
            }, 100);
        });
    }

    removeAnchors(): void {
        let removeAnchor = (anchor: HTMLElement) => {
            let popOverId = anchor?.dataset?.popOverId;
            if (assigned(popOverId)) {
                $(`#${popOverId}`).popover('hide');
            }

            this.editor.editorSVG.removeChild(anchor);
        }

        let anchor = document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop]));
        if (assigned(anchor)) {
            removeAnchor(anchor);
        }
        anchor = document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight]));
        if (assigned(anchor)) {
            removeAnchor(anchor);
        }
        anchor = document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom]));
        if (assigned(anchor)) {
            removeAnchor(anchor);
        }
        anchor = document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft]));
        if (assigned(anchor)) {
            removeAnchor(anchor);
        }

        // arrows
        if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop] + '-arrow')))
            this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahTop] + '-arrow')));
        if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight] + '-arrow')))
            this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahRight] + '-arrow')));
        if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom] + '-arrow')))
            this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahBottom] + '-arrow')));
        if (document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft] + '-arrow')))
            this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, AnchorHandle[AnchorHandle.ahLeft] + '-arrow')));
    }

    getAnchorPoint(sourceHandle: AnchorHandle): Point {
        switch (sourceHandle) {
            case AnchorHandle.ahCenter:
                return new Point(this.boundingRect.left + Math.floor(this.boundingRect.width / 2), this.boundingRect.top + Math.floor(this.boundingRect.height / 2));
            case AnchorHandle.ahTop:
                return new Point(this.boundingRect.left + Math.floor(this.boundingRect.width / 2), this.boundingRect.top);
            case AnchorHandle.ahRight:
                return new Point(this.boundingRect.right, this.boundingRect.top + Math.floor(this.boundingRect.height / 2));
            case AnchorHandle.ahBottom:
                return new Point(this.boundingRect.left + Math.floor(this.boundingRect.width / 2), this.boundingRect.bottom);
            case AnchorHandle.ahLeft:
                return new Point(this.boundingRect.left, this.boundingRect.top + Math.floor(this.boundingRect.height / 2));
            default:
                throw 'no valid AnchorHandle';
        }
    }

    center(): Point {
        return new Point(this.x + (this.w / 2), this.y + (this.h / 2));
    }
}
