import { Bounds, Point } from "../../../../classes/geometry";
import { assigned } from "../../../../utils/helper";
import { TTutorialGroupAction } from "../Actions/TTutorialGroupAction";
import { TTutorialShapePositionAction } from "../Actions/TTutorialShapePositionAction";
import { TTutorialShapeSizeAction } from "../Actions/TTutorialShapeSizeAction";
import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TBaseTutorialObject } from "../TBaseTutorialObject";


export function initMoveEvents(editor: TwsTutorialEditor) {

    editor.svg.addEventListener('mousedown', event => handleMouseDown(event));

    let selectedElements: Array<TBaseTutorialObject>;

    let transform: SVGTransform = null;
    let hasElement: boolean = false;
    let translate: SVGTransform = null;

    let offset: { x: number, y: number };
    let snappedOffset: { x: number, y: number };

    let lastDX = 0;
    let lastDY = 0;

    let animationIds: Array<number> = [];

    function getMousePosition(event) {
        let CTM = editor.svg.getScreenCTM();
        if (event.touches) {
            event = event.touches[0];
        }
        return {
            x: (event.clientX - CTM.e) / CTM.a,
            y: (event.clientY - CTM.f) / CTM.d
        };
    }

    function handleMouseDown(event) {
        // wenn wir nicht draggable sind brechen wir hier schon ab
        if (!event.target.classList.contains('draggable')) {
            return;
        }

        // eventlistener intialisieren
        document.documentElement.addEventListener('mousemove', handleMouseMove);
        document.documentElement.addEventListener('mouseup', handleMouseUp);

        // gespeicherte Move Koordinaten leeren
        lastDX = 0;
        lastDY = 0;

        selectedElements = editor.objectList.filter(x => x instanceof TBaseTutorialShape && x.selected === true);
        hasElement = true;
        offset = getMousePosition(event);

        selectedElements.forEach(selectedElement => {
            let shape = selectedElement as TBaseTutorialShape;

            if (!assigned(shape)) {
                return;
            }

            if (!shape.isMoveable) {
                return;
            }

            // Make sure the first transform on the element is a translate transform
            let transforms = selectedElement.svgElement.transform.baseVal;

            if (transforms.numberOfItems === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
                // Create a transform that translates by (0, 0)
                translate = editor.svg.createSVGTransform();
                translate.setTranslate(0, 0);
                selectedElement.svgElement.transform.baseVal.insertItemBefore(translate, 0);
            }

            // Get initial translation
            transform = transforms.getItem(0);

            offset.x -= transform.matrix.e;
            offset.y -= transform.matrix.f;
        });

        snappedOffset = {
            x: offset.x,
            y: offset.y
        };
    }

    function handleMouseMove(event: MouseEvent) {
        // wenn unser editmode beendet wurde abbrechen und aufräumen
        // if (!editor.isElementEditMode) {
        //     resetMovePos();
        //     return;
        // }

        if (hasElement) {
            event.preventDefault();

            let coord = getMousePosition(event);
            let dx = coord.x - snappedOffset.x;
            let dy = coord.y - snappedOffset.y;

            // wenn wir nichts bewegen müssen können wir uns die rechnerei sparen
            if (dx === lastDX && dy === lastDY) {
                return;
            }

            // alte Berechnungen stoppen
            animationIds.forEach(id => {
                window.cancelAnimationFrame(id);
            });

            animationIds.push(window.requestAnimationFrame(timestamp => {
                // Shapes bewegen
                selectedElements.forEach(element => {
                    let shape = element as TBaseTutorialShape;

                    if (!shape.isMoveable) {
                        return;
                    }

                    let targetPos = new Point(shape.x + dx, shape.y + dy);
                    let finalPos = new Bounds(targetPos.x, targetPos.y, targetPos.x + shape.w, targetPos.y + shape.h);

                    let dxTranslate = finalPos.left - shape.x
                    let dyTranslate = finalPos.top - shape.y;

                    // Vergrößern des Editors beim Rand
                    if ((shape.x + dxTranslate) < 0)
                        dxTranslate = -shape.x;

                    if ((shape.y + dyTranslate) < 0)
                        dyTranslate = -shape.y;


                    shape.setNewDraftPosition(finalPos.left, finalPos.top);
                    shape.setNewDraftSize(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top)
                });

                // Editorgröße anpassen; -> macht implizit schon ein invalidate!
                editor.checkEditorSize();
            }));

            lastDX = dx;
            lastDY = dy;
        }
    }

    function handleMouseUp() {
        // if (!editor.isElementEditMode) {
        //     resetMovePos();
        //     return;
        // }

        hasElement = false;

        if (assigned(selectedElements) && selectedElements.length > 0) {
            let actionGroup = new TTutorialGroupAction(null, editor);

            selectedElements.forEach(selectedElement => {
                let selectedShape = selectedElement as TBaseTutorialShape;

                let dx = selectedShape.boundingRect.left - selectedShape.realBoundingRect.left;
                let dy = selectedShape.boundingRect.top - selectedShape.realBoundingRect.top;

                if (dx !== 0 || dy !== 0) {
                    // Hier muss das Translate als dx,dy umgerechnet und zurückgesetzt werden
                    let posAction = new TTutorialShapePositionAction(selectedShape, editor);
                    posAction.setValue(dx, dy);

                    let resizeAction = new TTutorialShapeSizeAction(selectedShape, editor);
                    resizeAction.setValue(selectedShape.w, selectedShape.h);

                    actionGroup.actions.push(posAction);
                    actionGroup.actions.push(resizeAction);

                    selectedShape.resetDraftBounds(); // das eigentliche Anwenden passiert unten via Perform auf der Actionliste
                }
            });

            if (actionGroup.actions.length > 0) {
                editor.actionList.addClientAction(actionGroup);
                editor.actionList.actions[editor.actionList.listPointer].performAction();
            }

            selectedElements.length = 0;
        }

        // und die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleMouseUp, false);
        // editor.isElementEditMode = false;
    }

    function resetMovePos() {
        // alte Bounds wiederherstellen
        if (assigned(selectedElements) && selectedElements.length > 0) {
            selectedElements.forEach(selectedElement => {
                if (selectedElement instanceof TBaseTutorialShape) {
                    selectedElement.setNewDraftPosition(selectedElement.x, selectedElement.y);
                    selectedElement.setNewDraftSize(selectedElement.w, selectedElement.h, true);
                }
            });
        }

        hasElement = false;
        selectedElements.length = 0;

        // Editorgröße anpassen; -> macht implizit schon ein invalidate!
        editor.checkEditorSize();

        // eventlistener aufräumen
        document.documentElement.removeEventListener('mousemove', handleMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleMouseUp, false);
    }
}