import loader, { Monaco } from '@monaco-editor/loader';
import { editor, IRange, languages } from 'monaco-editor';
import { b64DecodeUnicode, boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';
import { sendComponentRequestGetJson, WebCompEventHandlerAsync } from '../../../core/communication';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';

var globalProvider;

type RequestResult = {
    success: boolean,
    errorMsg: string,
    data: string
}

export class TwcMonacoEditor extends TWebComponent {
    container: HTMLElement;
    editorContainer: HTMLElement;
    editor: editor.IStandaloneCodeEditor;

    csAutoCompletion: string;
    csSignatures: string;
    csCanProvideCompletionData: boolean;

    zoomButton: HTMLElement;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcMonacoEditor';
        this.container = document.getElementById(`${this.id}`);
        this.editorContainer = document.getElementById(`${this.id}-editor`);

        this.csAutoCompletion = this.editorContainer.dataset?.autocompletion ?? '[]';
        this.csSignatures = this.editorContainer.dataset?.signatureprovider ?? '[]';

        this.csCanProvideCompletionData = boolFromStr(this.editorContainer.dataset?.canprovidecompletiondata ?? 'false')

        this.zoomButton = document.getElementById(`${this.id}-zoom`);
        this.zoomButton.addEventListener('click', () => this.handleZoomEvent());
    }

    override initDomElement() {
        super.initDomElement();

        this.initEditor();
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Source', this.editor.getValue()]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Source':
                this.editor.setValue(value);
                break;
        }

    }

    handleZoomEvent(): void {
        if (this.container.classList.contains('fullscreen')) {
            this.container.classList.remove('fullscreen');
        } else {
            this.container.classList.add('fullscreen');
        }
    }

    initEditor(): void {
        // Set config -> Default geht sonst über das Internet. https://github.com/suren-atoyan/monaco-loader#usage
        loader.config({
            paths: {
                vs: '/assets/libs/monaco-editor/',
            }
        });

        loader.init().then(monaco => {
            this.editor = monaco.editor.create(this.editorContainer, {
                value: b64DecodeUnicode(this.editorContainer.dataset.sourcecode),
                language: this.editorContainer.dataset.scriptlanguage,
                automaticLayout: true,
                minimap: {
                    enabled: boolFromStr(this.editorContainer.dataset?.showminimap ?? 'false')
                }
            });

            // Unregister previous completion items - ups this wont work with more than 1 monaco editor on one page
            if (globalProvider) { globalProvider.dispose() };

            // Register new completion-items
            globalProvider = monaco.languages.registerCompletionItemProvider('pascal', {
                provideCompletionItems: (model, position, context, token) => {
                    const word = model.getWordUntilPosition(position);
                    const range: IRange = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endColumn: word.endColumn
                    };

                    // New variant with live code-completion
                    if ((this.csCanProvideCompletionData) && (this.editor)){                        
                        let res = sendComponentRequestGetJson(this.id, {
                            action: 'onGetCompletionItems',
                            source: this.editor.getValue(),
                            word: word,
                            range: range
                        }) as RequestResult;
                        if (res.success) {                            
                            let completionItems: Array<languages.CompletionItem> = JSON.parse(res.data);
                            return {
                                suggestions: completionItems
                            }
                        } else {
                            if (res.errorMsg) {
                                MsgNotify(res.errorMsg, MessageType.Warning);
                                return { suggestions: null };
                            }
                        }
                    }
                    // Classic variant with static information
                    else {
                        let completionItems: Array<languages.CompletionItem> = JSON.parse(this.csAutoCompletion);
                        return {
                            suggestions: completionItems
                        }
                    }
                }
            });

            monaco.languages.registerSignatureHelpProvider('pascal', {
                signatureHelpTriggerCharacters: ['(', ','],
                provideSignatureHelp: (model, position, token, context) => {
                    let csSignatures: Array<languages.SignatureInformation> = JSON.parse(this.csSignatures);
                    return {
                        value: {
                            signatures: csSignatures,
                            activeSignature: 0,
                            activeParameter: 0
                        },
                        dispose: () => { }
                    };
                }
            });


        });
    }

}
