﻿import { ActionType } from '../Actions/TCustomAction';
import { TCustomActionGroup } from '../Actions/TCustomActionGroup';
import { TEdgeBackgroundColorAction } from '../Actions/TEdgeBackgroundColorAction';
import { TEdgeCaptionAction } from '../Actions/TEdgeCaptionAction';
import { TEdgeCreateAction } from '../Actions/TEdgeCreateAction';
import { TEdgeDeleteAction } from '../Actions/TEdgeDeleteAction';
import { TEdgeExtraPointsAction } from '../Actions/TEdgeExtraPointsAction';
import { TEdgeItemDataAction } from '../Actions/TEdgeItemDataAction';
import { TNodeBackgroundColorAction } from '../Actions/TNodeBackgroundColorAction';
import { TNodeBPMNItemDataAction } from '../Actions/TNodeBPMNItemDataAction';
import { TNodeCaptionAction } from '../Actions/TNodeCaptionAction';
import { TNodeCreateAction } from '../Actions/TNodeCreateAction';
import { TNodeDeleteAction } from '../Actions/TNodeDeleteAction';
import { TNodeIconAction } from '../Actions/TNodeIconAction';
import { TNodeOwnerPosAction } from '../Actions/TNodeOwnerPosAction';
import { TNodePositionAction } from '../Actions/TNodePositionAction';
import { TNodeSizeAction } from '../Actions/TNodeSizeAction';
import { TStreamAction } from '../Actions/TStreamAction';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';

export function getActionByTypeID(actionTypeID: ActionType, refElement: any, editor: TwsProcessEditorCustom) {
    switch (actionTypeID) {
        case ActionType.patShapeCreate:
            return new TNodeCreateAction(refElement, editor);
        case ActionType.patShapeDelete:
            return new TNodeDeleteAction(refElement, editor);
        case ActionType.patShapeCaption:
            return new TNodeCaptionAction(refElement, editor);
        case ActionType.patShapePosition:
            return new TNodePositionAction(refElement, editor);
        case ActionType.patShapeSize:
            return new TNodeSizeAction(refElement, editor);
        case ActionType.patGroupAction:
            return new TCustomActionGroup(refElement, editor);
        case ActionType.patShapeBackgroundColor:
            return new TNodeBackgroundColorAction(refElement, editor);
        case ActionType.patEdgeCreate:
            return new TEdgeCreateAction(refElement, editor);
        case ActionType.patEdgeDelete:
            return new TEdgeDeleteAction(refElement, editor);
        case ActionType.patEdgeCaption:
            return new TEdgeCaptionAction(refElement, editor);
        case ActionType.patEdgeExtraPoints:
            return new TEdgeExtraPointsAction(refElement, editor);
        case ActionType.patStream:
            return new TStreamAction(refElement, editor);
        case ActionType.patEdgeBackgroundColor:
            return new TEdgeBackgroundColorAction(refElement, editor);
        case ActionType.patShapeOwnerPos:
            return new TNodeOwnerPosAction(refElement, editor);
        case ActionType.patShapeIcons:
            return new TNodeIconAction(refElement, editor);
        case ActionType.patShapeBPMNItemData:
            return new TNodeBPMNItemDataAction(refElement, editor);
        case ActionType.patEdgeItemData:
            return new TEdgeItemDataAction(refElement, editor);
        default:
            throw `Invalid ActionType [${actionTypeID}]!`;
    }
}