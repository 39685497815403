import * as atlas from 'azure-maps-control';
import * as atlasRest from 'azure-maps-rest';
import { csJSONParse, truncateText } from '../../../utils/strings';
import { TWebComponent } from "../../base/class.web.comps";
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

const APIKEY = 'gjjHTgkDgxrD3xQQuWF99kec8ZKL78TcACQjV-3Oiys';

/**
 * Type for MapElement.
 */
declare namespace csMap {
    export type MapElement = {
        /**
         * Name of the element
         */
        name?: string,
        /**
         * shortName of the element
         */
        short?: string,
        /**
         * Key of the element
         */
        key?: string,
        /**
         * Address of the element
         */
        address?: string,
        /**
         * Hint of the element
         */
        hint?: string,
        /**
        * Icon of the element
        */
        icon?: string,
        /**
         * Link of the element
         */
        link?: string,
    }
}

export class TwsMap extends TWebComponent {
    map: atlas.Map;
    minSearchInputLength: number;
    keyStrokeDelay: number;
    dataSource: atlas.source.DataSource;
    symbolLayer: atlas.layer.SymbolLayer;
    clusterLayer: atlas.layer.SymbolLayer;
    isBusy: boolean;
    results: any[];
    searchURL: atlasRest.SearchURL;
    searchOptions: atlasRest.SearchAddressOptions;
    mapElements: Array<csMap.MapElement>;
    popup: atlas.Popup;
    popupTemplate: string;
    bBoxOffset: number;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwsMap';
        try {
            this.mapElements = csJSONParse(this.obj.dataset?.mapelements);
        } catch (e) {
            console.debug(e);
            this.mapElements = [];
        }
        this.dataSource = null;
        this.symbolLayer = null;
        this.clusterLayer = null;
        this.searchURL = null;
        this.popup = null;
        this.popupTemplate = null;
        this.bBoxOffset = 2;

        this.searchOptions = {
            limit: 1,
            zoom: 14,
            view: 'Auto',
            // https://docs.microsoft.com/en-us/azure/azure-maps/supported-languages
            language: navigator.language,
        };

        this.isBusy = false;
        this.results = [];
    }

    override initDomElement(): void {
        super.initDomElement();

        this.map = new atlas.Map(this.id, {
            center: new atlas.data.Position(0, 0),
            zoom: 14,
            view: 'Auto',
            showFeedbackLink: false,
            showLogo: false,
            language: navigator.language,

            //Add authentication details for connecting to Azure Maps.
            authOptions: {
                authType: atlas.AuthenticationType.subscriptionKey,
                subscriptionKey: APIKEY,
            }
        });

        //Define an HTML template for a custom popup content laypout.
        this.popupTemplate = '<div class="customInfobox"><div class="name">{name}</div>{description}</div>';

        // Use MapControlCredential to share authentication between a map control and the service module.
        let pipeline = atlasRest.MapsURL.newPipeline(new atlasRest.MapControlCredential(this.map));

        // Construct the SearchURL object
        this.searchURL = new atlasRest.SearchURL(pipeline);

        //Wait until the map resources are ready.
        this.map.events.add('ready', () => {

            //Pass multiple controls into the map using an array.
            this.map.controls.add([
                new atlas.control.ZoomControl(),
                new atlas.control.CompassControl(),
                new atlas.control.PitchControl(),
                // new atlas.control.StyleControl()
            ], {
                position: atlas.ControlPosition.TopRight,
            });

            // Create a data source and add it to the map
            this.dataSource = new atlas.source.DataSource(null, { cluster: true, clusterRadius: 45, clusterMaxZoom: 15 });
            this.map.sources.add(this.dataSource);

            //Create an array of custom icon promises to load into the map. 
            let iconPromises = [
                // svg code from: https://fontawesome.com/v6.0/icons?q=industry&s=solid%2Cbrands
                this.map.imageSprite.add('fa-industry', '/assets/images/mapIcons/fa-industry.svg'),
                // svg code from: https://fontawesome.com/v6.0/icons?q=building&s=solid%2Cbrands
                this.map.imageSprite.add('fa-building', '/assets/images/mapIcons/fa-building.svg'),

                //Create an icon from one of the built-in templates and use it with a symbol layer.
                this.map.imageSprite.createFromTemplate('marker-csBlue', 'marker', '#06c', '#fff'),
            ];

            //Load all the custom image icons into the map resources.
            Promise.all(iconPromises).then(() => {

                //Add a layer for rendering the results.
                this.symbolLayer = new atlas.layer.SymbolLayer(this.dataSource, null, {
                    filter: ['!', ['has', 'point_count']], //Filter out clustered points from this layer.
                    iconOptions: {
                        image: 'marker-csBlue', // ['get', 'csIcon'],
                        size: 1.2,
                        anchor: 'bottom',
                        allowOverlap: true
                    },
                    textOptions: {
                        // doc for expressions: https://docs.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk
                        textField: ['format', ['image', ['get', 'csIcon']]],
                        color: '#fff',
                        allowOverlap: true,
                        offset: [0, -1.8]
                    }
                });

                this.clusterLayer = new atlas.layer.SymbolLayer(this.dataSource, null, {
                    filter: ['has', 'point_count'], //Only rendered data points which have a point_count property, which clusters do.
                    iconOptions: {
                        image: 'marker-csBlue',
                        size: 1.2,
                        anchor: 'bottom'
                    },
                    textOptions: {
                        textField: '{point_count_abbreviated}',
                        offset: [0, -1.6],
                        color: '#fff',
                    }
                })

                this.map.layers.add([this.symbolLayer, this.clusterLayer]);

                //Create a popup but leave it closed so we can update it and display it later.
                this.popup = new atlas.Popup({
                    pixelOffset: [0, -40]
                });

                //Add a mouseover event to the symbol layer.
                this.map.events.add('mouseover', this.symbolLayer, (e: atlas.MapMouseEvent) => {
                    //When the mouse is over the layer, change the cursor to be a pointer.
                    this.map.getCanvasContainer().style.cursor = 'pointer';

                    //Make sure the event occured on a point feature.
                    if (e.shapes && e.shapes.length > 0) {
                        let content, coordinate;
                        let description = '';
                        let header = '';

                        // mehere Adressen an einen Punkt?
                        if (e.shapes.length > 1) {
                            let ids = new Set;
                            // dann merken wir uns die ClusterIDs (sollte immer nur eine sein...)
                            for (let i = 0; i < e.shapes.length; i++) {
                                let shape = e.shapes[i] as atlas.Shape;
                                if (!ids.has(shape.getId())) {
                                    ids.add(shape.getId());
                                }
                            }

                            // dann alle Shapes auf der Map holen und mit unserer ID(s) abgleichen
                            let shapes = this.dataSource.getShapes();
                            for (let i = 0; i < shapes.length; i++) {
                                let shape = shapes[i];
                                // passt die ID?
                                if (ids.has(shape.getId())) {
                                    // dann fügen wir einen Link hinzu
                                    let properties = shape.getProperties();
                                    if (description !== '') {
                                        description += '<br>';
                                    }

                                    description += '<span title="' + properties.csName + '">' + truncateText(properties.csName, 25, true) + ': <a href="' + properties.csLink + '" target="_blank" rel="noopener noreferrer">' + properties.csKuerzel + '</a>';
                                }
                            }
                        } else {
                            let properties = (e.shapes[0] as atlas.Shape).getProperties();
                            header = properties.csName;
                            description += '<a href="' + properties.csLink + '" target="_blank" rel="noopener noreferrer">' + properties.csKuerzel + '</a>';
                        }

                        content = this.popupTemplate.replace(/{name}/g, header).replace(/{description}/g, description);
                        coordinate = (e.shapes[0] as atlas.Shape).getCoordinates();

                        if (content && coordinate) {
                            //Populate the popupTemplate with data from the clicked point feature.
                            this.popup.setOptions({
                                //Update the content of the popup.
                                content: content,

                                //Update the position of the popup with the symbols coordinate.
                                position: coordinate,
                                pixelOffset: [0, -40]
                            });

                            // Open the popup.
                            this.popup.open(this.map);
                        }
                    }
                });

                //When the mouse leaves the item on the layer, change the cursor back to the default which is grab.
                this.map.events.add('mouseout', this.symbolLayer, () => {
                    this.map.getCanvasContainer().style.cursor = 'grab';
                });

                // add events on the symbollayer
                this.map.events.add('dblclick', this.symbolLayer, (e: atlas.MapMouseEvent) => {
                    e.preventDefault();

                    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0] instanceof atlas.Shape) {
                        let properties = e.shapes[0].getProperties();

                        // refer to csOID
                        window.open(properties.csLink, '_blank', 'noopener');
                    }
                });

                this.map.events.add('click', this.clusterLayer, (e: atlas.MapMouseEvent) => {
                    if (e && e.shapes && e.shapes.length > 0 && (e.shapes[0] as any).properties.cluster) {
                        //Get the clustered point from the event.
                        let cluster = e.shapes[0] as atlas.data.Feature<atlas.data.Geometry, any>;

                        //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
                        this.dataSource.getClusterExpansionZoom(cluster.properties.cluster_id).then((zoom: number) => {

                            //Update the map camera to be centered over the cluster. 
                            this.map.setCamera({
                                center: cluster.geometry.coordinates,
                                zoom: zoom,
                                type: 'ease',
                                duration: 200
                            });
                        });

                        this.popup.close();
                    }
                });

                //Add a mouseover event to the cluster layer.
                this.map.events.add('mouseover', this.clusterLayer, (e: atlas.MapMouseEvent) => {
                    //When the mouse is over the layer, change the cursor to be a pointer.
                    this.map.getCanvasContainer().style.cursor = 'pointer';

                    //Make sure the event occured on a cluster feature.
                    if (e.shapes && e.shapes.length > 0 && (e.shapes[0] as any).properties.cluster) {
                        let content, coordinate;
                        let description = '';
                        let cluster = e.shapes[0] as atlas.data.Feature<atlas.data.Geometry, any>;

                        this.dataSource.getClusterLeaves(cluster.properties.cluster_id, Infinity, 0).then((points: Array<atlas.Shape>) => {
                            let ids = new Set;
                            // getClusterLeaves kann nicht unter verschiedenen Standorten mit gleichen Adressen unterscheiden (z.B. mehrere Standorte an einer PLZ)
                            // daher merken wir uns die IDs (ID 123 kann mehrfach vorkommen, es wir aber nur das letzte Shape genommen)
                            points.forEach((point: atlas.Shape) => {
                                ids.add(point.getId());
                            });

                            // nun alle shapes durchlaufen und schauen, ob es relevant ist
                            description = '';
                            let shapes = this.dataSource.getShapes();
                            for (let i = 0; i < shapes.length; i++) {
                                let shape = shapes[i];
                                // passt die ID?
                                if (ids.has(shape.getId())) {
                                    // dann fügen wir einen Link hinzu
                                    let properties = shape.getProperties();
                                    if (description !== '') {
                                        description += '<br>';
                                    }
                                    description += '<a href="' + properties.csLink + '" target="_blank" rel="noopener noreferrer">' + properties.csKuerzel + '</a>';
                                }
                            }

                            content = this.popupTemplate.replace(/{name}/g, 'Standorte:').replace(/{description}/g, description);
                            coordinate = cluster.geometry.coordinates;

                            if (content && coordinate) {
                                //Populate the popupTemplate with data from the clicked point feature.
                                this.popup.setOptions({
                                    //Update the content of the popup.
                                    content: content,
                                    //Update the position of the popup with the symbols coordinate.
                                    position: coordinate,
                                    pixelOffset: [0, -40]
                                });

                                // Open the popup.
                                this.popup.open(this.map);
                            }
                        });
                    }
                });

                //When the mouse leaves the item on the layer, change the cursor back to the default which is grab.
                this.map.events.add('mouseout', this.clusterLayer, () => {
                    this.map.getCanvasContainer().style.cursor = 'grab';
                });

                this.startSearch();
            });
        });
    }

    startSearch() {
        if (!this.isBusy) {
            this.isBusy = true;
            this.dataSource.clear();
            this.results = [];
            this.parallelGeocode();
        }
        else {
            console.debug('search is busy');
        }
    }

    endSearch() {
        // close open popups
        this.popup.close();

        let bBox: number[];

        if (this.results.length > 0) {
            this.dataSource.setShapes(this.results);
            // increase/decrease the boundingBox by 2° lon/lat
            // order: [west, south, east, north]
            bBox = atlas.data.BoundingBox.fromData(this.results).map((x, i) => {
                if (i === 0 || i === 1) {
                    return x - this.bBoxOffset;
                } else {
                    return x + this.bBoxOffset;
                }
            });
        }
        else {
            bBox = [5.8663153, 47.2701114, 15.0419319, 55.099161];
        }

        // Set the camera to the bounds of the results.
        this.map.setCamera({
            bounds: bBox,
            padding: 40,
            center: atlas.data.BoundingBox.getCenter(bBox),
        });

        this.isBusy = false;
    }

    /**
     * This method will iterate through all the locations and make multiple parallel requests.
     * The browser will limit the number of concurrent requests to the same domain.
     */
    async parallelGeocode() {
        let requests = [];

        //Create the request promises.
        for (let i = 0; i < this.mapElements.length; i++) {
            if (this.mapElements[i].address != '') {
                requests.push(this.searchURL.searchAddress(atlasRest.Aborter.timeout(10000), this.mapElements[i].address, this.searchOptions));
            }
        }

        //Process the promises in parallel.
        let responses = await Promise.all(requests);

        //Extract the GeoJSON feature results.
        responses.forEach((r, i) => {
            let fc = r.geojson.getFeatures();

            if (fc.features.length > 0) {
                // add consense properties
                fc.features[0].properties.csName = this.mapElements[i].name;
                fc.features[0].properties.csKuerzel = this.mapElements[i].short;
                fc.features[0].properties.csOID = this.mapElements[i].key;
                fc.features[0].properties.csIcon = this.mapElements[i].icon;
                fc.features[0].properties.csLink = this.mapElements[i].link;
                this.results.push(fc.features[0]);
            }
        });

        //Done.
        this.endSearch();
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];

        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.RefreshPinPoint':
                this.mapElements = csJSONParse(params);
                this.startSearch();
                break;
            case 'Action.Invalidate':
                this.map.resize();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}