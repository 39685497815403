import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeConnectorEnd extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 570;
        this.roundCorners = true;
        this.isResizePossible = false;
        this.inlineEdit = false;
    }

    getSvgElementType(): string {
        return 'rect';
    }
}