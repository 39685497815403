import { WebCompEventHandler, WebCompEventHandlerAsync } from '../../../core/communication';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcTimer extends TWebComponent {
    timer: number;
    useAsync: boolean;
    interval: number;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTimer';

        this.timer = null;
        this.interval = parseInt(this.obj.dataset.interval ?? '100');
        this.useAsync = boolFromStr(this.obj.dataset.isAsync, true);
    }

    override initDomElement() {
        super.initDomElement();

        if (boolFromStr(this.obj.dataset.isActive)) {
            this.startTimer();
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'isAsync':
                this.useAsync = boolFromStr(value);
                break;
            case 'interval':
                this.interval = parseInt(value);
                break;
        }
    }

    startTimer(): void {
        if (this.isActive) {
            this.stopTimer();
        }

        // Intervalle <= 0 sund nicht erlaubt
        if (this.interval <= 0) {
            return;
        }

        this.timer = window.setInterval(() => this.execTimer(), this.interval);
    }

    stopTimer(): void {
        window.clearInterval(this.timer);
        this.timer = null;
    }

    isActive(): boolean {
        return assigned(this.timer);
    }

    execTimer(): void {
        const EXEC_EVENT = 'OnExecute';

        if (this.useAsync) {
            WebCompEventHandlerAsync(EXEC_EVENT, this.id);
        }
        else {
            WebCompEventHandler(EXEC_EVENT, this.id);
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.StartTimer':
                this.startTimer();
                break;
            case 'Action.StopTimer':
                this.stopTimer();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}