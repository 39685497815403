import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';
import { TNodeCreateAction } from './TNodeCreateAction';

export class TNodeDeleteAction extends TCustomNodeAction {
    cid: number;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.cid = undefined;
    }

    typeID(): ActionType {
        return ActionType.patShapeDelete;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.cid = obj.cid;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        return obj;
    }

    setValue(cid: number): void {
        this.cid = cid;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.delete();

        this.svgEditor.unregisterObj(this.refElement);
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeCreateAction(this.refElement, this.svgEditor);
        result.setValue(this.cid);
        this.svgEditor.registerObj(result.refElement);

        return result;
    }
}