import Color from 'color';
import { Bounds, Rectangle } from '../../../classes/geometry';
import { TTutorialTextBase, TutorialFontStyles } from './Text/TTutorialTextBase';
import { TwsTutorialEditor } from './class.web.comp.tutorialeditor';

export enum TutorialHorizontalAlignment { taLeftJustify, taRightJustify, taCenter };
export enum TutorialVerticalAlignment { taAlignOuterTop, taAlignTop, taAlignOuterBottom, taAlignBottom, taVerticalCenter };
export enum TutorialFontSizeFactor { fsf100, fsf150, fsf200, fsf250, fsf300, fsf350, fsf400 }

export interface TBaseTutorialObject {
    create(): void;
    delete(): void;
    invalidate(): void;
    setTextContent(textContent: string): void;
    setDisplayedTextContent(displayedTextContent: string): void;
    getTextContent(): string;
    getDisplayedTextContent(): string;
    setImage(): void;

    id: string;
    guid: string;

    // Owner
    editor: TwsTutorialEditor;

    // Element im DOM
    svgElement: any;

    // Bounding Box
    boundingRect: Rectangle;

    // ElementProperties
    selected: boolean;
    wordWrap: boolean;
    inlineEdit: boolean;
    rotation: number;

    // TextContentProperties
    fontColor: Color;
    fontStyles: Array<TutorialFontStyles>;
    fontSize: TutorialFontSizeFactor;

    textContentElement: TTutorialTextBase;
    textContentHorzAlignment: TutorialHorizontalAlignment;
    textContentVertAlignment: TutorialVerticalAlignment;
    margin: Bounds;
    lineHeight: number;
}

// da interface können wir für sowas leider keine basisimplementierung machen :( 
export function areEqualTutorialObjects(obj1: TBaseTutorialObject, obj2: TBaseTutorialObject): boolean {
    return obj1.id === obj2.id;
}