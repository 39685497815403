import { acquireRequestLock, releaseRequestLock, WebCompEventHandlerAsync } from "../../../core/communication";
import { AsType } from "../../../utils/html";
import { TwsProcessViewerSVG } from "./class.web.comp.process.viewer.svg";

export class TWebComponentProcessDiffViewerSVG extends TwsProcessViewerSVG {

    constructor(obj: HTMLElement) {
        super(obj);

        this.classtype = 'TWebComponentProcessDiffViewerSVG';
    }

    override getClassType(): string {
        return 'TWebComponentProcessDiffViewerSVG';
    }

    override initClickHandler(): void {
        var containerElement = $('#' + this.id + '_processview_container');
        var parent = this;

        containerElement.on('click', function (event) {
            // Ist der Request fertig?
            if (acquireRequestLock()) {
                // wir gucken, ob wir auf den viewer oder den container geklickt haben
                if (event.target.id == this.id || event.target.id == parent.id + '_processviewer' || event.target.id == '0' ) {
                    parent.setActiveShape(0);
                } else {
                    // Für den DiffView müssen wir den String trimmen
                    var id = event.target.id.substring(0, event.target.id.indexOf('_'));
                    parent.setActiveShape(parseInt(id));
                }
                if (!parent.isLastActiveShape()) {
                    // Markierung wechseln
                    $('#' + parent.id + '_processviewer > #process_viewer').children().removeClass('active-shape');
                    if (parent.ActiveShape != 0) {
                        var id = id + '_Diff';
                        $('#' + id).addClass('active-shape');
                    }

                    WebCompEventHandlerAsync('OnClick', parent.id, function () { });
                }

                releaseRequestLock();
            }
        });

        var zoomSlider = $('#' + this.id + '_zoomer_slider');
        zoomSlider.on('input', function () {
            parent.changeZoom(parseInt(AsType<HTMLInputElement>(this).value));
        })
    }
}