import isEmpty from 'lodash.isempty';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { VerticalAlignment } from '../TBaseGraphObject';
import { TBaseShapeBPMN } from './TBaseShapeBPMN ';

export class TShapeBPMNGateway extends TBaseShapeBPMN {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 970;

        this.captionVertAlignment = VerticalAlignment.taAlignOuterBottom;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let w2 = this.boundingRect.width / 2;
        let h2 = this.boundingRect.height / 2;

        let path = [
            // Kante oben
            'M', this.boundingRect.left + w2, this.boundingRect.top,
            'l', w2, h2,
            'l', -w2, h2,
            'l', -w2, -h2,
            'Z'
        ].join(' ');

        return path;
    }

    iconByCode(code: string): string {
        switch (code.trim()) {
            case 'A':
                return 'bpmn-gateway-exclusive';
            case 'B':
                return 'bpmn-gateway-exclusive-2';
            case 'C':
                return 'bpmn-gateway-eventbased';
            case 'D':
                return 'bpmn-gateway-parallel';
            case 'E':
                return 'bpmn-gateway-inclusive';
            case 'F':
                return 'bpmn-gateway-complex';
            case 'G':
                return 'bpmn-gateway-exclusive-eventbased';
            case 'H':
                return 'bpmn-gateway-parallel-eventbased';
            default:
                return '';
        }
    }

    repaintIconBPMN() {
        if (isEmpty(this.itemData)) {
            return;
        }

        // Caption anzeigen?
        this.captionElement.svgElement.classList.toggle('d-none', !(this.itemData.substr(2, 1) === 'X'));

        if (!isEmpty(this.iconByCode(this.itemData.substr(0, 2)))) {
            this.setAttributesBPMN(this.iconByCode(this.itemData.substr(0, 2)),
                this.boundingRect.left + this.boundingRect.width / 4, this.boundingRect.top + this.boundingRect.height / 4, this.boundingRect.width / 2,
                this.boundingRect.height / 2);
        }
    }
}