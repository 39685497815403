﻿import { v4 as uuidv4, validate } from 'uuid';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TEdgeBase } from '../Edges/TEdgeBase';
import { TBaseShape } from '../Shapes/TBaseShape';

export enum ActionType {
    patShapeCreate, patShapeDelete, patShapeCaption, patShapePosition,
    patShapeSize, patGroupAction, patShapeBackgroundColor, patEdgeCreate,
    patEdgeDelete, patEdgeCaption, patEdgeExtraPoints, patStream, patEdgeBackgroundColor,
    patShapeOwnerPos, patShapeIcons, patShapeBPMNItemData, patEdgeItemData
};

export abstract class TCustomAction<T> {
    actionGuid: string;
    refElement: T;
    svgEditor: TwsProcessEditorCustom;

    constructor(refElement: T, editor: TwsProcessEditorCustom) {
        this.actionGuid = uuidv4();
        this.refElement = refElement;
        this.svgEditor = editor;
    }

    fromJSON(obj: any): void {
        if (!validate(obj.actionGuid))
            throw `Guid for action is not valid [${obj.actionGuid}].`;

        this.actionGuid = obj.actionGuid;
    }

    toJSON(): any {
        return { actionType: this.typeID(), actionGuid: this.actionGuid };
    }

    abstract typeID(): ActionType;

    abstract performAction(): void;

    abstract setValue(...args: any[]): void;

    abstract inverseAction(): TCustomAction<T>;
}

export abstract class TCustomNodeAction extends TCustomAction<TBaseShape>{ }
export abstract class TCustomEdgeAction extends TCustomAction<TEdgeBase>{ }