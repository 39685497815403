import Color from 'color';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeNote extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 400;

        this.gradientBegin = Color('#ffffff');
        this.gradientEnd = Color('#ffffca');
        this.strokeColor = Color('#646446');
        this.colorFixed = true;
        this.hasAnchors = false;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let wc = this.boundingRect.width / 8;
        if (wc > this.boundingRect.height / 4) {
            wc = this.boundingRect.height / 8;
        }

        let path = [
            'M', this.boundingRect.left + 2 * wc, this.boundingRect.top,
            'h', this.boundingRect.width - 2 * wc,
            'v', this.boundingRect.height,
            'h', -this.boundingRect.width,
            'v', -(this.boundingRect.height - wc),
            'Z'
        ].join(' ');

        return path;
    }
}
