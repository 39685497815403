import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { ShapeNotificationIcon, TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodeIconAction extends TCustomNodeAction {
    oldIcons: Array<ShapeNotificationIcon>;
    newIcons: Array<ShapeNotificationIcon>;
    translationTexts: Map<ShapeNotificationIcon, string>;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patShapeIcons;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldIcons = obj.oldIcons;
        this.newIcons = obj.newIcons;
        this.translationTexts = obj.translationTexts;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldIcons = this.newIcons;
        obj.newIcons = this.oldIcons;
        obj.translationTexts = this.translationTexts;

        return obj;
    }

    setValue(newIcons: Array<ShapeNotificationIcon>, translationTexts: Map<ShapeNotificationIcon, string>): void {
        this.newIcons = newIcons;
        this.translationTexts = translationTexts;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.icons = this.newIcons;
        this.refElement.translationTexts = this.translationTexts;
        this.refElement.repaintIcons();
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeIconAction(this.refElement, this.svgEditor);
        result.setValue(this.oldIcons, this.translationTexts);

        return result;
    }
}