import { TWebComponent } from '../../base/class.web.comps';

export class TwcJumbotron extends TWebComponent {


    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcJumbotron';
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
        }
    }
}


export class TwcJumbotronCS extends TwcJumbotron {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcJumbotronCs';
    }
}