import Shepherd from 'shepherd.js';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcTourController extends TWebComponent {
    tour: Shepherd.Tour;

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcTourController';
        this.tour = new Shepherd.Tour({
            defaultStepOptions: {
                cancelIcon: {
                    enabled: true
                },
                classes: 'class-1 class-2',
                scrollTo: { behavior: 'smooth', block: 'center' }
            },
            useModalOverlay: true
        });

        this.initModalBehavior();
    }

    initModalBehavior(): void {
        var tour = this.tour;

        // Tour kann per Default mit Esc beendet werden, das klappt aber nicht, falls die Tour auf einem Modal ist.
        // Deswegen schließen wir die Tour beim Hide des Modals direkt mit, da diese sonst vorhanden bleibt
        tour.on('active', function (e) {
            // gibt es überhaupt Steps?
            if (tour.steps[0] != null) {
                // dann holen wir uns das nächstgelegene Modal, der Step Komponente (entweder liegt es auf nem Modal oder nicht, daher reicht [0])

                let modal: JQuery<HTMLElement>;

                
                let element = tour.steps[0].options.attachTo.element;
                // Des Weiteren kann das in der neusten Version auch eine lazy Load function sein siehe https://github.com/shipshapecode/shepherd/pull/1930
                if (typeof element === 'function') {
                    element = element();
                }
                // Typescript ist smart -> durch das if fliegt function aus dem Typ von element raus und ist wieder HTMLElement | string, so wie vorher                
                // das ist entweder HTML oder String; JQuery kann beides aber für TS muss es den Typen leider fix wissen
                if (element instanceof HTMLElement) {
                    modal = $(element).closest('[data-type="TwcModal"]');
                }
                else {
                    modal = $(element).closest('[data-type="TwcModal"]');
                }

                // haben wir ein Modal?
                if (modal.length > 0) {
                    // im Hide des Modals auch die Tour schließen
                    modal.on('hide.bs.modal', function (e) {
                        if (tour.isActive()) {
                            tour.hide();
                        }
                    });
                }
            }
        })
    }

    writeProperties(key: string, value: string): void {
        //
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.RunScript':
                // this.enjoyhint_instance.run();
                break;
            case 'Action.ResumeScript':
                // this.enjoyhint_instance.resume();
                break;
        }
    }
}