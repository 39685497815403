'use strict';

import { DoughnutController } from 'chart.js';
import { merge } from 'chart.js/helpers';
import { CustomElementArc } from './element.custom.arc';

export class CustomControllerDoughnut extends DoughnutController {

	draw() {
		super.draw();
	}
}

CustomControllerDoughnut.id = 'customControllerDoughnut';
CustomControllerDoughnut.defaults = merge(DoughnutController.defaults, { dataElementType: CustomElementArc.id });
