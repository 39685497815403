import { registerOnShowModal } from "../../core/interaction";
import { b64DecodeUnicode, b64EncodeUnicode } from "../../utils/strings";

/* dialog */
export class TcsDialog {
    memoId: string;
    modal: JQuery<HTMLLIElement>;
    modalid: string;
    modaltype: string;
    modalHtml: string;
    modalCaption: string;
    dataSet: {
        resolved: boolean,
        value: string,
        class: string,
        oidbase64: string,
        href: string,
        key: string,
        caption: string,
        captionraw: string
    };

    constructor(memoId: string) {
        this.memoId = memoId;
        this.modal = null;
        this.modalid = 'dummyModal' + Math.floor(Math.random() * 10000).toString();
        this.modaltype = 'oid';
        /*modal content*/
        this.modalHtml = '';
        this.modalCaption = '';
        this.dataSet = {
            resolved: false,
            value: '',
            class: '',
            oidbase64: '',
            href: '',
            key: '',
            caption: '',
            captionraw: ''
        };
    }

    createModalDialog(): void {
        this.modal = $('<div class="modal modal-alert fade cs-modal-js" id="' + this.modalid + '" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true"></div>');
        var modalDialog = $('<div class="modal-dialog modal-dialog-centered" role="document"></div>');
        this.modal.append(modalDialog);
        var modalContent = $('<div class="modal-content"></div>');
        modalDialog.append(modalContent);
        var modalHeader = $('<div class="modal-header cs-themecolor-primary cs-themecolor-primary-font"><h5 class="modal-title"></h5></div>');
        modalContent.append(modalHeader);
        var modalBody = $('<div class="modal-body"></div>');
        modalContent.append(modalBody);
        var modalFooter = $('<div class="modal-footer"></div>');
        modalContent.append(modalFooter);
        $('body').append(this.modal);
    }

    initContent(): void {
        this.modalCaption = 'Objekte hinzufügen';
        this.modalHtml = 'Hier können Sie später Objekte verlinken';
    }

    setContent(): void {
        $('#' + this.modalid + ' .modal-body').html('<form class="form" onsubmit="return false;"><fieldset class="form-group">' + this.modalHtml + '</fieldset></form>');
        $('#' + this.modalid + ' .modal-title').html(this.modalCaption);
        $('#' + this.modalid + ' .modal-footer').html('<button type="button" class="btn btn-primary" data-bs-dismiss="modal" data-uitype="1">OK</button><button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" data-uitype="2">Abbrechen</button>');
    }

    showModal(): void {
        registerOnShowModal($('#' + this.modalid));
        $('#' + this.modalid).modal('show');
    }

    afterShowModal(): void {

    }

    onCloseModal(): void {
        // collect data
        this.dataSet.value = '';
        this.dataSet.class = this.modaltype;
        this.dataSet.oidbase64 = '';
        this.dataSet.href = '';
        this.dataSet.key = '';
    }

    show(): Promise<any> {
        return new Promise(function (resolve, reject) {
            this.createModalDialog();
            this.initContent();
            this.setContent();
            this.showModal();
            $('#' + this.modalid).on('shown.bs.modal', function () {
                this.afterShowModal();
            }.bind(this));

            // click event für ok und abbrechen
            $('#' + this.modalid + ' .modal-footer button').on('click', function (event) {

                var clickedButton = $(event.target);

                // nur bei ok klick
                if (clickedButton.data('uitype') === 1) {
                    this.onCloseModal();
                }

                // Hier handlen wir das finale Schließen des Modals
                $('#' + this.modalid).on('hidden.bs.modal', function () {
                    // nur bei ok klick
                    if (clickedButton.data('uitype') === 1) {
                        if (this.dataSet.resolved) {
                            resolve(this.dataSet);
                        }
                    }
                    reject('Abbruch');
                    $('#' + this.modalid).remove();
                    $('#' + this.modalid).modal('dispose');
                }.bind(this));


            }.bind(this));
        }.bind(this));
    }

    dummyId(): string {
        return this.modalid + '_' + this.modaltype;
    }

    setAsReloved(): void {
        this.dataSet.resolved = true;
        this.dataSet.class = this.modaltype;
    }
}

export class HyperlinkTcsDialog extends TcsDialog {
    database64: string;

    constructor(memoId: string) {
        super(memoId);
        this.modaltype = 'hyperlink';
        this.database64 = '';
    }

    override initContent(): void {

        function escapeQuotes(str) {
            var re = new RegExp(/"/, 'g');
            return str.replace(re, '&quot;');
        }

        let url = '', beschriftung = '', checked = '', disabled = ' disabled';
        let words;
        if (this.database64.trim() != '') {
            const stringFrom64 = b64DecodeUnicode(this.database64);
            if (stringFrom64.indexOf('\u0002') > -1) { //  #2
                words = stringFrom64.split('\u0002'); // wir holden die URL und die Caption //  #2
            } else {
                words = stringFrom64.split('|'); // wir holden die URL und die Caption    
            }
            url = words[0];
            beschriftung = words[1];
            if (beschriftung.trim() != '') {
                checked = ' checked="checked"';
                disabled = '';
            }

        }

        this.modalCaption = 'Internetverknüpfung einfügen';
        this.modalHtml = '';
        this.modalHtml += '<div class="form-group">';
        this.modalHtml += '<label for="' + this.dummyId() + '_url">Internetadresse</label>';
        this.modalHtml += '<input type="text" class="form-control" id="' + this.dummyId() + '_url" placeholder="https://www." value="' + escapeQuotes(url) + '">';
        this.modalHtml += '</div>';

        this.modalHtml += '<div class="form-group">';
        this.modalHtml += '<label class="switcher-control">'
        this.modalHtml += '<input type="checkbox" class="switcher-input" id="' + this.dummyId() + '_check_beschriftung"' + checked + '><span class="switcher-indicator"></span>';
        this.modalHtml += '</label>'
        this.modalHtml += '<label class="ms-1" for="' + this.dummyId() + '_check_beschriftung">Zusätzliche Beschriftung anzeigen</label>';
        this.modalHtml += '<input type="text" class="form-control disabled" id="' + this.dummyId() + '_beschriftung"' + disabled + ' value="' + escapeQuotes(beschriftung) + '">';
        this.modalHtml += '</div>';
    }

    override afterShowModal(): void {
        var checkBoxSelector = $('#' + this.dummyId() + '_check_beschriftung');
        var inputSelector = $('#' + this.dummyId() + '_beschriftung');

        checkBoxSelector.change(function () {
            if (checkBoxSelector.is(':checked')) {
                inputSelector.removeClass('disabled');
                inputSelector.prop('disabled', false);
            } else {
                inputSelector.addClass('disabled');
                inputSelector.prop('disabled', true);
            }
        }.bind(this));
    }

    override onCloseModal(): void {
        var url = String($('#' + this.dummyId() + '_url').val());
        var ckeckBoxCheked = $('#' + this.dummyId() + '_check_beschriftung').is(':checked');
        var beschriftung = String((ckeckBoxCheked ? $('#' + this.dummyId() + '_beschriftung').val() : ''));

        if (url.trim() != '') {
            //  URL schema, oder http:// als default
            url = /^[A-Za-z][A-Za-z0-9+-.]*\:[\/\/]?/.test(url) ?
                url : 'http://' + url;

            var stringTo64 = url + '\u0002' + beschriftung;
            this.dataSet.oidbase64 = b64EncodeUnicode(stringTo64);

            this.dataSet.href = url;
            this.dataSet.caption = beschriftung;

            if (ckeckBoxCheked && beschriftung.trim() != '') {
                this.dataSet.caption = 'Internet "' + beschriftung + '"';
            } else {
                this.dataSet.caption = 'Internet "' + url + '"';;
            }
            this.dataSet.captionraw = this.dataSet.caption;
            this.setAsReloved();
        }
    }
}

export class TcsDialogSelect extends TcsDialog {
    autocompId: string;
    // UPS // Das wird nirgendwo benutzt und verursacht Fehler im Select2 Aufruf unten weil es das so nicht gibt!
    // dataToSelect: {};

    // elternklasse für Dialoge mit select
    constructor(memoId: string) {
        super(memoId);
        this.modaltype = 'oid';
        this.autocompId = 'autocomp_' + this.dummyId();
        // this.dataToSelect = {};

    }

    override initContent(): void {
        this.modalHtml = '';
        this.modalHtml += '<div class="form-group">';
        this.modalHtml += '<label for="' + this.autocompId + '_url">Suche</label>';
        this.modalHtml += '<select id="' + this.autocompId + '" name="state">';
        this.modalHtml += '</select>';
        this.modalHtml += '</div>';

        this.modalCaption = 'Geben Sie einen Suchbegriff ein';
    }

    /**
     * @deprecated Not implemented
     */
    getDataToSelect(): void {

    }

    override afterShowModal(): void {
        var formatRepo = function formatRepo(repo) {
            if (repo.loading) return 'Suche';
            if (repo.id == -1) return '';
            var markup = '<div class="media">' +
                '' + repo.avatar + '' +
                '<div class="media-body">' + '<h6 class="mt-1 mx-2">' + repo.text + '</h6>';
            if (repo.info && repo.info.trim() != '') {
                markup += '<div class="small text-muted mx-2">' + repo.info + '</div>';
            }
            markup += '</div></div>';
            return markup;
        };

        this.getDataToSelect();

        $('#' + this.autocompId).select2({
            width: '100%',
            dropdownParent: $('#' + this.modalid),
            // data: this.dataToSelect,
            minimumInputLength: 1,
            templateResult: formatRepo,
            escapeMarkup: function escapeMarkup(markup) {
                return markup;
            }
        });
        var newOption = new Option(this.modalCaption, '-1', true, true);
        $('#' + this.autocompId).append(newOption).trigger('change');
    }

    override onCloseModal(): void {
        this.collectDataFromSelect();
    }


    /**
     * @deprecated Not implemented
     */
    collectDataFromSelect(): void {
        throw new Error('Method not implemented.');
    }
}
window[TcsDialogSelect.name] = TcsDialogSelect; // Alter Prozesseditor :(