﻿import flatpickr from 'flatpickr';
// Dynamisches Laden geht leider nicht, da die Sprachen zur Compiletime vorhanden sein müssen.
//#region flatpickr localization
import 'flatpickr/dist/l10n/ar';
import 'flatpickr/dist/l10n/at';
import 'flatpickr/dist/l10n/az';
import 'flatpickr/dist/l10n/be';
import 'flatpickr/dist/l10n/bg';
import 'flatpickr/dist/l10n/bn';
import 'flatpickr/dist/l10n/bs';
import 'flatpickr/dist/l10n/cat';
import 'flatpickr/dist/l10n/cs';
import 'flatpickr/dist/l10n/cy';
import 'flatpickr/dist/l10n/da';
import 'flatpickr/dist/l10n/de';
import 'flatpickr/dist/l10n/default';
import 'flatpickr/dist/l10n/eo';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/l10n/et';
import 'flatpickr/dist/l10n/fa';
import 'flatpickr/dist/l10n/fi';
import 'flatpickr/dist/l10n/fo';
import 'flatpickr/dist/l10n/fr';
import 'flatpickr/dist/l10n/ga';
import 'flatpickr/dist/l10n/gr';
import 'flatpickr/dist/l10n/he';
import 'flatpickr/dist/l10n/hi';
import 'flatpickr/dist/l10n/hr';
import 'flatpickr/dist/l10n/hu';
import 'flatpickr/dist/l10n/id';
import 'flatpickr/dist/l10n/index';
import 'flatpickr/dist/l10n/is';
import 'flatpickr/dist/l10n/it';
import 'flatpickr/dist/l10n/ja';
import 'flatpickr/dist/l10n/ka';
import 'flatpickr/dist/l10n/km';
import 'flatpickr/dist/l10n/ko';
import 'flatpickr/dist/l10n/kz';
import 'flatpickr/dist/l10n/lt';
import 'flatpickr/dist/l10n/lv';
import 'flatpickr/dist/l10n/mk';
import 'flatpickr/dist/l10n/mn';
import 'flatpickr/dist/l10n/ms';
import 'flatpickr/dist/l10n/my';
import 'flatpickr/dist/l10n/nl';
import 'flatpickr/dist/l10n/no';
import 'flatpickr/dist/l10n/pa';
import 'flatpickr/dist/l10n/pl';
import 'flatpickr/dist/l10n/pt';
import 'flatpickr/dist/l10n/ro';
import 'flatpickr/dist/l10n/ru';
import 'flatpickr/dist/l10n/si';
import 'flatpickr/dist/l10n/sk';
import 'flatpickr/dist/l10n/sl';
import 'flatpickr/dist/l10n/sq';
import 'flatpickr/dist/l10n/sr';
import 'flatpickr/dist/l10n/sr-cyr';
import 'flatpickr/dist/l10n/sv';
import 'flatpickr/dist/l10n/th';
import 'flatpickr/dist/l10n/tr';
import 'flatpickr/dist/l10n/uk';
import 'flatpickr/dist/l10n/uz';
import 'flatpickr/dist/l10n/uz_latn';
import 'flatpickr/dist/l10n/vn';
import 'flatpickr/dist/l10n/zh';
import 'flatpickr/dist/l10n/zh-tw';
import { Instance } from 'flatpickr/dist/types/instance';
import { key as LocaleKey } from 'flatpickr/dist/types/locale';
import { WebCompEventHandler } from '../../../core/communication';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';



//#endregion

export class TwcEditDate extends TWebComponent {
    calendar: Instance; // Instance ist aus flatpickr
    mobileDisabled: boolean;
    language: LocaleKey;
    dateUnified: string;
    todayStr: string;
    tomorrowStr: string;
    yesterdayStr: string;
    clearStr: string;
    private autoCorrect: boolean;
    private defaultDate: Date;

    hasServerChangeEvent: boolean;
    hasServerExitEvent: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcEditDate';

        // Wir holen uns die übergebenen Daten
        this.mobileDisabled = boolFromStr(this.obj.dataset.mobileDisabled);
        this.language = this.obj.dataset.language as LocaleKey;
        this.dateUnified = this.obj.dataset.unified;
        this.todayStr = this.obj.dataset.today;
        this.tomorrowStr = this.obj.dataset.tomorrow;
        this.yesterdayStr = this.obj.dataset.yesterday;
        this.clearStr = this.obj.dataset.clear;

        this.autoCorrect = boolFromStr(this.obj.dataset.autocorrect, false);

        this.defaultDate = null;
        let tmpDate = this.obj.dataset.dateDefault;
        if (tmpDate && tmpDate.length > 0) {
            this.defaultDate = this.stringToDate(tmpDate);
        }

        // Events
        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent);
        this.hasServerExitEvent = boolFromStr(this.obj.dataset?.hasServerExitEvent);
    }

    stringToDate(s: string): Date {
        // Die einzelnen Werte aus dem String holen
        let year = parseInt(s.substring(0, 4));
        let month = parseInt(s.substring(4, 6));
        let day = parseInt(s.substring(6));

        return new Date(year, month - 1, day);
    }

    override initDomElement(): void {
        super.initDomElement();

        // Wir haben noch kein Datum
        let date: Date = null;

        // Wurde uns ein Datum übergeben?
        if (this.dateUnified.length > 0) {
            // JS Date erstellen -> damit können wir dann arbeiten
            date = this.stringToDate(this.dateUnified);
        }

        const defaultDate = this.defaultDate;
        const correctDate = this.autoCorrect;

        let incDate = (date: Date, days: number): Date => {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
        }

        const dateStrings = [
            {
                // Heute
                Date: new Date(),
                Text: this.todayStr
            },
            {
                // Morgen
                Date: incDate(new Date(), 1),
                Text: this.tomorrowStr
            },
            {
                // Gestern
                Date: incDate(new Date(), -1),
                Text: this.yesterdayStr
            }
        ];

        this.calendar = flatpickr(this.obj, {
            // Die Mobile-Komponente ist etwas nativer und kann nicht so viel -> wir können auch einfach die Standard-Desktop-Variante nutzen
            // https://flatpickr.js.org/mobile-support/
            disableMobile: this.mobileDisabled,
            defaultDate: date,
            locale: this.language,

            // Init-Funktion der Komponente
            onReady: function (dateObj, dateStr, instance) {

                // ----- Hier fügen wir die Custom-Buttons "Heute" und "Löschen" ein -----

                // Wir holen uns den Calender-Container
                let $cal = $(instance.calendarContainer);

                // Button sollen in einer Reihe sein
                let row = $('<div class="row p-2"></div>');
                $cal.append(row);

                // Wir fügen einen "Heute"-Button hinzu
                let todayContainer = $('<div class="col"></div>');
                row.append(todayContainer);
                let today = $('<button type="button" class="btn btn-secondary btn-sm btn-block">' + this.todayStr + '</button>');
                todayContainer.append(today);
                today.on('click', e => {
                    instance.setDate(Date.now(), true);
                    instance.close();
                });

                // Wir fügen einen "Löschen"-Button hinzu
                let clearContainer = $('<div class="col"></div>');
                row.append(clearContainer);
                let clear = $('<button type="button" class="btn btn-secondary btn-sm btn-block">' + this.clearStr + '</button>');
                clearContainer.append(clear);
                clear.on('click', e => {
                    if (correctDate && defaultDate != null) {
                        instance.setDate(defaultDate, true);
                    } else {
                        instance.clear();
                    }
                    instance.close();
                });
            }.bind(this),
            // Nach Ändern des Datums (Auswahldialog geht zu)
            onChange: () => this.handleEditObjChanged(),
        });


        // Bei der normalen Desktop-Variante wollen wir noch Custom-Texte anzeigen können
        // Die native Mobile-Komponente kann das nicht!
        if (!this.calendar.isMobile) {
            // Set refreshed den Kalender
            this.calendar.set('formatDate', (date, format) => {
                // Wir schauen, ob es für das Datum einen Custom-Text gibt
                for (const key in dateStrings)
                    if (flatpickr.compareDates(date, dateStrings[key].Date, true) === 0)
                        // Und geben den Custom-Text aus
                        return dateStrings[key].Text;

                // Sonst geben wir das Date so raus, wie es im Browser lokal eingestellt ist
                return date.toLocaleDateString();
            });
        }

        // readonly wird sonst immer gesetzt, wir brauchen es aber nicht und nutzen nur disabled
        this.obj.removeAttribute('readonly');
    }

    handleEditObjChanged(): void {
        this.notifyComponentChanged();

        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
        if (this.hasServerExitEvent) {
            WebCompEventHandler('OnExit', this.id);
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    // Wir brauchen immer zwei Zeichen
    dateParser(i: number): string {
        return (i < 10) ? "0" + i : "" + i;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        let date = this.calendar.selectedDates[0];

        // Wir brauchen das Date wieder im unified Format
        let dateUnified = null;
        if (assigned(date)) {
            dateUnified = date.getFullYear() + this.dateParser(date.getMonth() + 1) + this.dateParser(date.getDate());
        }
        properties.push([this.id, 'Date', date != null ? dateUnified : '']);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Enabled':
                if (value == '0') {
                    //Disabled
                    this.obj.setAttribute('disabled', 'true');
                    if (this.calendar.isMobile) {
                        this.calendar.mobileInput.disabled = true;
                    }
                }
                else if (value == '1') {
                    // Enabled
                    this.obj.removeAttribute('disabled');
                    if (this.calendar.isMobile) {
                        this.calendar.mobileInput.disabled = false;
                    }
                }
                break;
            case 'Date':
                // wenn wir kein Datum bekommen
                if (value == '') {
                    // dann schauen wir, ob wir ggf. das DefaultDate setzen
                    if (this.autoCorrect && this.defaultDate != null) {
                        this.calendar.setDate(this.defaultDate, true);
                    } else {
                        // sonst die Auswahl löschen
                        this.calendar.clear();
                    }
                } else {
                    // sonst setzen wir das neue Datum
                    this.calendar.setDate(this.stringToDate(value), true);
                }
                break;
        }
    }
}