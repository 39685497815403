import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeSizeAction extends TCustomTutorialShapeAction {
    oldWidth: number;
    oldHeight: number;
    newWidth: number;
    newHeight: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldWidth = this.refElement.w;
        this.oldHeight = this.refElement.h;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeSize;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldWidth = obj.oldWidth;
        this.oldHeight = obj.oldHeight;
        this.newWidth = obj.newWidth;
        this.newHeight = obj.newHeight;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldWidth = Math.round(this.oldWidth);
        obj.oldHeight = Math.round(this.oldHeight);
        obj.newWidth = Math.round(this.newWidth);
        obj.newHeight = Math.round(this.newHeight);

        return obj;
    }

    setValue(newWidth: number, newHeight: number): void {
        this.newWidth = Math.round(newWidth);
        this.newHeight = Math.round(newHeight);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setNewDraftSize(this.newWidth, this.newHeight, true);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeSizeAction(this.refElement, this.svgEditor);
        result.setValue(this.oldWidth, this.oldHeight);

        return result;
    }
}