import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeOwnerPosAction extends TCustomTutorialShapeAction {
    oldOwnerPos: number;
    newOwnerPos: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeOwnerPos;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldOwnerPos = obj.oldOwnerPos;
        this.newOwnerPos = obj.newOwnerPos;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldOwnerPos = this.oldOwnerPos;
        obj.newOwnerPos = this.newOwnerPos;

        return obj;
    }

    setValue(newOwnerPos: number): void {
        this.newOwnerPos = newOwnerPos;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setOwnerPos(this.newOwnerPos);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeOwnerPosAction(this.refElement, this.svgEditor);
        result.setValue(this.oldOwnerPos);

        return result;
    }
}
