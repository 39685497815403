import { AudioConfig, CancellationReason, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { WebCompEventHandlerAsync, sendComponentRequestGetJson } from "../../../core/communication";
import { MessageType, MsgNotify } from "../../../core/utils/msgnotify";
import { assigned } from "../../../utils/helper";
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from "../../base/class.web.comps";
import { getComponentForElement } from "../../base/controlling";

// Komponenten, die als Ausgabe für SpeechToText-Verarbeitung dienen können (clientseitig)
export interface ISTTOutputComp {
    // finaler Text aus der SpeechToText-Verarbeitung 
    setRecognizedText: (text: string) => void;
    // Zwischenergebnisse aus der SpeechToText-Verarbeitung
    setRecognizingText: (text: string) => void;
}

// pro Endpoint lassen wir nur eine Aufnahme zu, da der Text sonst nicht in die zugewiesene Komponente geschrieben wird
var endpointIsRecording: boolean = false;

// Button, bei dem SpeechToText übers Mikro gestartet und wieder gestoppt werden kann
export class TwsSpeechToText extends TRenderWebComponent {
    isRecording: boolean;
    currRecognizer: SpeechRecognizer;
    outputComp: any;
    visible: boolean;
    enabled: boolean;
    recordingStartTime: number;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsSpeechToText';
        this.isRecording = false;
        this.currRecognizer = null;

        this.visible = boolFromStr(this.obj.dataset.visible);
        this.enabled = boolFromStr(this.obj.dataset.enabled);
    }

    getOutputComp(): ISTTOutputComp {
        if (assigned(this.outputComp)) {
            return this.outputComp as ISTTOutputComp;
        }

        this.outputComp = getComponentForElement(document.getElementById(this.obj.dataset.outputid));

        return this.outputComp as ISTTOutputComp;
    }

    override initDomElement() {
        super.initDomElement();

        this.obj.addEventListener('click', () => this.onToggleSpeechToText());
    }

    onToggleSpeechToText(): void {
        if (this.isRecording) {
            this.endRecording();
        } else {
            this.doSTT();
        }
    }

    async endRecording() {
        this.currRecognizer.stopContinuousRecognitionAsync();
        this.isRecording = false;
        endpointIsRecording = false;

        // Oberfläche updaten
        this.invalidate();

        if (assigned(this.obj.dataset.endmsg)) {
            MsgNotify(this.obj.dataset.endmsg, MessageType.Success);
        }

        let diff = Date.now() - this.recordingStartTime;
        WebCompEventHandlerAsync('timeTracked', this.id, null, String(diff));
    }

    async startRecording() {
        await this.currRecognizer.startContinuousRecognitionAsync();
        this.isRecording = true;
        endpointIsRecording = true;

        // Oberfläche updaten
        this.invalidate();
        if (assigned(this.obj.dataset.endmsg)) {
            MsgNotify(this.obj.dataset.endmsg, MessageType.Success);
        }

        // Startzeit merken
        this.recordingStartTime = Date.now();
    }

    getAudioConfig(): AudioConfig {
        return AudioConfig.fromDefaultMicrophoneInput();
    }

    async doSTT() {
        if (endpointIsRecording) {
            MsgNotify(this.obj.dataset.multirecordmsg, MessageType.Danger);
            return;
        }


        // Wir holen uns einen kurzlebigen Token vom Server
        var request = {};
        request['request'] = 'getToken';
        let token = sendComponentRequestGetJson(this.id, request)['token'];
        let tokenObj = { authToken: token, region: 'germanywestcentral' };
        let speechConfig = SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);

        // Zu erkennende Sprache ist die Oberflächensprache des Users
        speechConfig.speechRecognitionLanguage = this.obj.dataset.language;

        const audioConfig = this.getAudioConfig();
        this.currRecognizer = new SpeechRecognizer(speechConfig, audioConfig);

        // Events für kontinuierliche Erkennung
        this.currRecognizer.recognizing = (s, e) => {
            try {
                const res = e.result;
                if (assigned(res.text)) {
                    this.getOutputComp().setRecognizingText(res.text);
                }
            } catch (error) {
                console.error(error);
            }
        };

        this.currRecognizer.recognized = (reco, e) => {
            try {
                const res = e.result;
                if (assigned(res.text)) {
                    this.getOutputComp().setRecognizedText(res.text);
                }
            } catch (error) {
                console.error(error);
            }
        };

        this.currRecognizer.canceled = (s, e) => {
            MsgNotify(`RECORDING CANCELED: Reason=${e.errorDetails}`, MessageType.Danger);

            if (e.reason == CancellationReason.Error) {
                console.error(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.error(`"CANCELED: ErrorDetails=${e.errorDetails}`);
            }

            this.endRecording();
        };

        // this.currRecognizer.sessionStopped = (s, e) => {
        //     console.log("\n    Session stopped event.");
        // };

        this.startRecording();
    }

    render(): void {
        this.obj.classList.toggle('recording-end', this.isRecording);
        this.obj.classList.toggle('recording-start', !this.isRecording);
        this.obj.classList.toggle('disabled', !this.enabled);
        this.obj.classList.toggle('d-none', !this.visible);
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        this.render();
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                this.enabled = boolFromStr(value);
                break;
            case 'Visible':
                this.visible = boolFromStr(value);
                break;
        }
    }
}

export class TwsFileSpeechToText extends TwsSpeechToText {
    input: HTMLInputElement;
    icon: HTMLElement;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsFileSpeechToText';

        this.icon = this.obj.querySelector(`#${this.obj.id}_icon`);
    }

    override initDomElement() {
        super.initDomElement();

        this.input = document.createElement('input');
        this.input.classList.add('d-none');
        this.input.type = 'file';
        this.input.addEventListener('change', (event) => this.handleFileSelect(event));
        // laut doku werden nur die beiden Dateiendungen unterstützt
        this.input.accept = 'wav,pcm';
        this.obj.appendChild(this.input);
    }

    handleFileSelect(e: Event): void {
        let file = this.input.files[0]
        if (!file) {
            return;
        }

        if (this.isRecording) {
            this.endRecording();
        } else {
            this.doSTT();
        }
    }

    override getAudioConfig(): AudioConfig {
        return AudioConfig.fromWavFileInput(this.input.files[0]);
    }

    override onToggleSpeechToText() {
        if (this.isRecording) {
            this.endRecording();
        } else {
            this.input.click();
        }
    }

    override render(): void {
        this.icon.classList.toggle('upload-end', this.isRecording);
        this.icon.classList.toggle('upload-start', !this.isRecording);
        this.obj.classList.toggle('disabled', !this.enabled);
        this.obj.classList.toggle('d-none', !this.visible);
        this.obj.classList.toggle('fa-spin', !this.isRecording);
        this.obj.classList.toggle('fa-spin', this.isRecording);
    }

    override  async endRecording() {
        super.endRecording();
        this.input.value = '';
    }

}
