import cloneDeep from 'lodash.clonedeep';
import { Point } from '../../../../classes/geometry';
import { assigned } from '../../../../utils/helper';
import { TCustomActionGroup } from '../Actions/TCustomActionGroup';
import { TEdgeExtraPointsAction } from '../Actions/TEdgeExtraPointsAction';
import { TNodePositionAction } from '../Actions/TNodePositionAction';
import { TNodeSizeAction } from '../Actions/TNodeSizeAction';
import { ProcessMode, TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { AnchorHandle, EdgeIdentifier, parseEdgeIdentifier, TEdgeBase } from '../Edges/TEdgeBase';
import { TBaseShape } from '../Shapes/TBaseShape';
import { getIDSuffix } from './TProcessEditorIDUtils';

export enum ResizeDirection {
    north = 'n',
    northEast = 'ne',
    east = 'e',
    southEast = 'se',
    south = 's',
    southWest = 'sw',
    west = 'w',
    northWest = 'nw',
    horizontal = 'ew',
    vertical = 'ns',
    all = 'all',
}

export function initResizeEvents(editor: TwsProcessEditorCustom): void {

    initShapeResizeEvents(editor);
    initEdgeMoveEvents(editor);
}

function initShapeResizeEvents(editor: TwsProcessEditorCustom): void {
    let resizingShape: TBaseShape = null;
    let resizeDirection: ResizeDirection = null;
    let selectedElements: Array<TBaseShape>;

    function buildResizeClassName(direction: ResizeDirection): string {
        const CLASS_PREFIX = 'resize';
        return `${CLASS_PREFIX}-${direction}`;
    }

    editor.editorSVG.addEventListener('mousedown', event => {
        // die eventListener nur Hinzufügen, wenn wir resizen wollen
        if (!(event.target instanceof SVGElement && event.target.classList.contains('resizeHandle'))) {
            return;
        }

        for (const directionName in ResizeDirection) {
            let direction = ResizeDirection[directionName];
            let className = buildResizeClassName(ResizeDirection[directionName]);

            if (event.target.classList.contains(className)) {
                resizeDirection = direction;
                break;
            }
        }

        selectedElements = editor.objectList.filter(x => x instanceof TBaseShape && x.selected === true) as Array<TBaseShape>;
        selectedElements.forEach(element => {
            (element as TBaseShape).resetDraftBounds();
        });

        let ownerId = event.target.dataset.ownerId;
        resizingShape = editor.objectById(ownerId) as TBaseShape;

        // eventlistener intialisieren
        document.documentElement.addEventListener('mousemove', handleShapeResizeMouseMove);
        document.documentElement.addEventListener('mouseup', handleShapeResizeMouseUp);
        editor.isElementEditMode = true;
    });

    function handleShapeResizeMouseMove(event: MouseEvent): void {

        function getResizeAnchorPosition(shape: TBaseShape, anchorDirection: ResizeDirection): Point {
            let x = shape.x;
            let y = shape.y;

            // Bei den Ankern rechts müssen wir die breite berücksichtigen
            if ([ResizeDirection.northEast, ResizeDirection.east, ResizeDirection.southEast].includes(anchorDirection)) {
                x += shape.w;
            };

            // Und unten die Höhe
            if ([ResizeDirection.southWest, ResizeDirection.south, ResizeDirection.southEast].includes(anchorDirection)) {
                y += shape.h;
            }

            return new Point(x, y);
        }

        if (!editor.isElementEditMode) {
            stopShapeResize();
            return;
        }

        if (assigned(resizingShape)) {
            let cursorPosition = editor.getLocalCoordinate(event);
            let anchorPosition = getResizeAnchorPosition(resizingShape, resizeDirection);

            // Resize Änderung
            let dx = cursorPosition.x - anchorPosition.x;
            let dy = cursorPosition.y - anchorPosition.y;
            console.debug(`dx: ${dx}, dy: ${dy}`);

            selectedElements.forEach(element => {
                let shape = element as TBaseShape;

                if (assigned(shape)) {
                    if (!shape.isResizePossible) {
                        return;
                    }

                    // ACHTUNG: dx und dy sind Offsets auf Basis der alten Resizeankerpositionen. Daher müssen wir beim Rechnen (Snapping) immer auf den Originalzustand
                    // beziehen. Die unveränderten Werte müssen aber aus dem Draft Zustand kommen, damit wir uns beim Diagonalen Resize in zwei Richtungen
                    // nicht wieder die zuvor veränderte Richtung mit dem original überschreiben

                    // North
                    if ([ResizeDirection.north, ResizeDirection.northEast, ResizeDirection.northWest].includes(resizeDirection)) {
                        // dy auf position addieren zum verschieben
                        let y = editor.snapToRealGridY(shape.y + dy);
                        // höhe um dy reduzieren um untere Kante beizubehalten
                        let h = editor.snapToRealGridY(shape.h - dy);

                        // Boundings prüfen und anwenden
                        if (h >= editor.gridSize.height) {
                            shape.setNewDraftPosition(shape.boundingRect.left, y);
                            shape.setNewDraftSize(shape.boundingRect.width, h);
                        }
                    }
                    // South
                    else if ([ResizeDirection.south, ResizeDirection.southEast, ResizeDirection.southWest].includes(resizeDirection)) {
                        // höhe um dy erweitern
                        let h = editor.snapToRealGridY(shape.h + dy);
                        // Boundings prüfen und anwenden
                        if (h >= editor.gridSize.height) {
                            shape.setNewDraftSize(shape.boundingRect.width, h);
                        }
                    }

                    // West
                    if ([ResizeDirection.west, ResizeDirection.northWest, ResizeDirection.southWest].includes(resizeDirection)) {
                        // dx auf Position addieren zum verschieben
                        let x = editor.snapToRealGridX(shape.x + dx);
                        // breite um dx reduzieren um die rechte Kante beizubehalten
                        let w = editor.snapToRealGridX(shape.w - dx);

                        // Boundings prüfen und anwenden
                        if (w >= editor.gridSize.width) {
                            shape.setNewDraftPosition(x, shape.boundingRect.top);
                            shape.setNewDraftSize(w, shape.boundingRect.height);
                        }
                    }
                    // East
                    else if ([ResizeDirection.east, ResizeDirection.northEast, ResizeDirection.southEast].includes(resizeDirection)) {
                        // breite um dx erweitern
                        let w = editor.snapToRealGridX(shape.w + dx);

                        // Boundings prüfen und anwenden
                        if (w >= editor.gridSize.width) {
                            shape.setNewDraftSize(w, shape.boundingRect.height);
                        }
                    }

                    // Kanten separat neu berechnen; wenn wir das hier machen können wir uns alle auf einmal ausm DOM holen statt per Shape und so doppelte Berechnungen vermeiden
                    let queryString = selectedElements.map(shape => `[fromNode="${shape.id}"], [toNode="${shape.id}"]`).join(', ');
                    if (queryString !== '') {
                        let edgeElementList = editor.editorSVG.querySelectorAll(queryString);
                        edgeElementList.forEach(element => {
                            let aEdge = editor.objectById(element.id) as TEdgeBase;
                            aEdge.setDraftExtraPoints(aEdge.recalcExtraPoints());
                        });
                    }

                    // Editorgröße anpassen -> macht implizit schon ein invalidate!
                    editor.checkEditorSize();
                }
            });
        }
    }

    function handleShapeResizeMouseUp(): void {
        if (!editor.isElementEditMode) {
            stopShapeResize();
            return;
        }

        let actionGroup = new TCustomActionGroup(null, editor);

        let handledEdges = new Array<string>();

        selectedElements.forEach(element => {
            let selectedShape = element as TBaseShape;
            if (assigned(selectedShape)) {
                // und speichern uns das target wohin wir bewegen wollen, je nach Gridmodus
                // im Cluster Grid brauchen wir nämlich das Zentrum
                let target = editor.processMode === ProcessMode.VirtualGrid ? new Point(selectedShape.x + selectedShape.w / 2, selectedShape.y + selectedShape.h / 2) : new Point(selectedShape.boundingRect.left, selectedShape.boundingRect.top);

                // wir vergleichen unsere bbox mit der orgbbox und erstellen daraus jeweils eine pos und eine size action
                // für das virtuelle Grid müssen wir jedoch zuerst die Größe aktualisieren, um es danach neu ausrichten zu können
                let sizeAction = new TNodeSizeAction(selectedShape, editor);
                sizeAction.setValue(selectedShape.boundingRect.width, selectedShape.boundingRect.height);
                actionGroup.actions.push(sizeAction);

                // nun die Position bestimmen
                let newPosition = editor.snapShapeToUsedGrid(selectedShape, target);
                // und als Action speichern
                let positionAction = new TNodePositionAction(selectedShape, editor);
                positionAction.setValue(newPosition.left - selectedShape.x, newPosition.top - selectedShape.y);
                actionGroup.actions.push(positionAction);

                // extraPoints für verschobene Edges aktualisieren                 
                let edges = editor.objectList.filter(x => (x instanceof TEdgeBase
                    && (x.id.split('#')[0] === selectedShape.id || x.id.split('#')[1] === selectedShape.id) && handledEdges.indexOf(x.id) < 0));
                edges.forEach(element => {
                    let edge = element as TEdgeBase;
                    let extraPtAction = new TEdgeExtraPointsAction(edge, editor);
                    let newExtraPoints = edge.recalcExtraPoints();
                    extraPtAction.setValue(newExtraPoints);

                    actionGroup.actions.push(extraPtAction);

                    // merken -> wenn wir zwei Elemente verschieben, und die Kante zu beiden gehört, müssen wir das später dann nicht nochmal machen.
                    handledEdges.push(edge.id);
                });

                selectedShape.resetDraftBounds(); // das eigentliche Anwenden passiert unten via Perform auf der Actionliste
            }
        });

        if (actionGroup.actions.length > 0) {
            editor.actionList.addClientAction(actionGroup);
            editor.actionList.actions[editor.actionList.listPointer].performAction();
        }

        // und nun die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleShapeResizeMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleShapeResizeMouseUp, false);
        editor.isElementEditMode = false;
    }

    function stopShapeResize() {
        // alte Bounds wiederherstellen
        if (assigned(selectedElements) && selectedElements.length > 0) {
            selectedElements.forEach(selectedElement => {
                if (selectedElement instanceof TBaseShape) {
                    selectedElement.setNewDraftSize(selectedElement.w, selectedElement.h);
                    selectedElement.setNewDraftPosition(selectedElement.x, selectedElement.y, true);
                }
            });
        }

        // Kanten separat neu berechnen; wenn wir das hier machen können wir uns alle auf einmal ausm DOM holen statt per Shape und so doppelte Berechnungen vermeiden
        let queryString = selectedElements.map(shape => `[fromNode="${shape.id}"], [toNode="${shape.id}"]`).join(', ');
        if (queryString !== '') {
            let edgeElementList = editor.editorSVG.querySelectorAll(queryString);
            edgeElementList.forEach(element => {
                let aEdge = editor.objectById(element.id) as TEdgeBase;
                aEdge.setDraftExtraPoints(aEdge.recalcExtraPoints(), true);
            });
        }

        // Editorgröße anpassen; -> macht implizit schon ein invalidate!
        editor.checkEditorSize();

        // eventlistener aufräumen
        document.documentElement.removeEventListener('mousemove', handleShapeResizeMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleShapeResizeMouseUp, false);
    }
}

function initEdgeMoveEvents(editor: TwsProcessEditorCustom): void {
    let resizeDirection: ResizeDirection = null;
    let edgeIndex: number = null;
    let originalExtraPoints: Array<Point>;
    let movingEdge: TEdgeBase = null;

    editor.editorSVG.addEventListener('mousedown', event => {
        // die eventListener nur Hinzufügen, wenn wir resizen wollen
        if (!(event.target instanceof SVGElement && event.target.classList.contains('edgeMove'))) {
            return;
        }

        const edgeDst: EdgeIdentifier = 'dstPt';
        const edgeSrc: EdgeIdentifier = 'srcPt';

        if (event.target.classList.contains(getIDSuffix('edgeMove', ResizeDirection.vertical))) {
            resizeDirection = ResizeDirection.vertical;
        }
        else if (event.target.classList.contains(getIDSuffix('edgeMove', ResizeDirection.horizontal))) {
            resizeDirection = ResizeDirection.horizontal;
        }
        else if (event.target.classList.contains(getIDSuffix('edgeMove', ResizeDirection.all))) {
            resizeDirection = ResizeDirection.all;
        }

        let identifierStr = parseEdgeIdentifier(event.target.id.split('-')[1]);
        let ownerId = event.target.dataset.ownerId;
        movingEdge = editor.objectById(ownerId) as TEdgeBase;

        if (identifierStr === edgeDst) {
            handleEdgeChangeShape(movingEdge.srcShape, movingEdge.fromAnchor, true, movingEdge);
        }
        else if (identifierStr === edgeSrc) {
            handleEdgeChangeShape(movingEdge.dstShape, movingEdge.toAnchor, false, movingEdge);
        }
        else {
            edgeIndex = identifierStr;
            originalExtraPoints = cloneDeep(movingEdge.extraPoints);

            // eventlistener intialisieren
            document.documentElement.addEventListener('mousemove', handleEdgeMoveMouseMove);
            document.documentElement.addEventListener('mouseup', handleEdgeMoveMouseUp);
        }

        editor.isElementEditMode = true;
    });

    function handleEdgeChangeShape(fixedShape: TBaseShape, fixedAnchor: AnchorHandle, fixedShapeIsSrc: boolean, oldEdge: TEdgeBase) {

        // alte Kannte unsichtbar machen
        oldEdge.setVisible(false);

        editor.startArrowMode(fixedShape, fixedAnchor, {
            isReverseMode: !fixedShapeIsSrc,
            replaceExistingEdges: true,
            replacingEdge: oldEdge,
            afterArrowModeCallback: (wasSuccessfull: boolean): void => {
                oldEdge.setVisible(true);
            }
        });
    }

    function handleEdgeMoveMouseMove(event: MouseEvent): void {
        if (!editor.isElementEditMode) {
            stopEdgeResize();
            return;
        }

        if (assigned(movingEdge)) {
            let cursorPosition = editor.getLocalCoordinate(event);

            let dx = cursorPosition.x;
            let dy = cursorPosition.y;
            let movingExtraPoints = cloneDeep(originalExtraPoints);

            if (assigned(movingEdge)) {
                if (resizeDirection === ResizeDirection.vertical) {
                    // y coords anpassen
                    movingExtraPoints[edgeIndex].y = editor.snapToRealGridY(dy);
                    movingExtraPoints[edgeIndex + 1].y = editor.snapToRealGridY(dy);
                }
                else if (resizeDirection === ResizeDirection.horizontal) {
                    // x coords anpassen
                    movingExtraPoints[edgeIndex].x = editor.snapToRealGridX(dx);
                    movingExtraPoints[edgeIndex + 1].x = editor.snapToRealGridX(dx);
                }

                movingEdge.setDraftExtraPoints(movingExtraPoints);
                movingEdge.invalidate();
            }
        }
    }


    function handleEdgeMoveMouseUp(): void {
        if (!editor.isElementEditMode) {
            stopEdgeResize();
            return;
        }

        if (assigned(movingEdge)) {
            let actionGroup = new TCustomActionGroup(null, editor);

            let newExtraPoints = movingEdge.extraPoints;

            // für die action den alten wert wiederherstellen und anwenden
            movingEdge.setDraftExtraPoints(originalExtraPoints);
            let extraPtAction = new TEdgeExtraPointsAction(movingEdge, editor);
            extraPtAction.setValue(newExtraPoints);
            actionGroup.actions.push(extraPtAction);

            if (actionGroup.actions.length > 0) {
                editor.actionList.addClientAction(actionGroup);
                editor.actionList.actions[editor.actionList.listPointer].performAction();
            }

            movingEdge = null;
        }

        // und die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleEdgeMoveMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleEdgeMoveMouseUp, false);
        editor.isElementEditMode = false;
    }

    function stopEdgeResize() {
        // alte Bounds wiederherstellen     
        movingEdge.setDraftExtraPoints(originalExtraPoints, true);

        movingEdge = null;

        // Editorgröße anpassen; -> macht implizit schon ein invalidate!
        editor.checkEditorSize();

        // und die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleEdgeMoveMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleEdgeMoveMouseUp, false);
    }
}