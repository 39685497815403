import { Tab } from 'bootstrap';
import { ISignal, SignalDispatcher } from 'strongly-typed-events';
import { assigned } from '../../../utils/helper';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';
import { ISupportsActive } from '../../interfaces/supportsActive.intf';

export class TwcContainerItem extends TWebComponent implements ISupportsActive {

    public supportsActiveDiscriminator: 'I-Supports-Active' = 'I-Supports-Active';
    protected owner: TwcContainer;
    private _onShow = new SignalDispatcher();
    protected tabElement: HTMLElement;
    protected get tab(): Tab {
        return new Tab(this.tabElement);
    }

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcContainerItem';
        this.tabElement = document.getElementById(`${this.id}-tab`);

        this.owner = findComponent(this.tabElement.dataset.tabpanel) as TwcContainer;
        if (!assigned(this.owner)) {
            throw `Container item ${this.id} has no owner`;
        }
    }

    isActive(): boolean {
        return this.owner.getActiveTabId() === this.id;
    }

    public show(): void {
        this.tab.show();

        this._onShow.dispatchAsync();
    }

    onShow(): ISignal {
        return this._onShow.asEvent();
    }

    writeProperties(key: string, value: string): void {
        // no properties        
    }
}

export class TwcContainer extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcContainer';
    }

    setActiveTab(componentOrId: TwcContainerItem | string): void {
        if (componentOrId instanceof TwcContainerItem) {
            let containerItem = componentOrId;
            containerItem.show();
        }
        else {
            let component = findComponent(componentOrId);
            if (!assigned(component)) {
                throw `Cannot find component for ${componentOrId}.`;
            }
            if (!(component instanceof TwcContainerItem)) {
                throw `Component ${componentOrId} is not a valid container item.`;
            }

            this.setActiveTab(component);
        }
    }

    getActiveTabId(): string {
        let activeTab = this.obj.getElementsByClassName('active');
        if (activeTab.length != 0) {
            return activeTab[0].id.split('-')[0]; //'-tab' absplitten
        }
        return '-1';
    }

    override readProperties(): Array<ComponentProperty> {
        var properties = [];
        properties.push([this.id, 'ActiveTabId', this.getActiveTabId()]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'ActiveTabId':
                this.setActiveTab(value);
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
        }
    }
}