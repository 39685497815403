import { sendAjaxRequestGetText, WebCompEventHandlerAsync } from '../../../core/communication';
import { getBaseUrl } from '../../../core/endpoint';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { getIDSuffix, SVGNS } from '../../../utils/svg';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwsTutorialViewer extends TWebComponent {
    svg: SVGSVGElement;
    width: number;
    height: number;
    isReadOnly: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsTutorialViewer';
        this.width = 1600;
        this.height = 900;
        this.isReadOnly = boolFromStr(this.obj.dataset?.isreadonly) ?? false;
    }

    override initDomElement(): void {
        super.initDomElement();

        this.svg = document.createElementNS(SVGNS, 'svg');
        this.svg.setAttribute('id', getIDSuffix(this.id, 'svg'));
        this.svg.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);
        this.svg.setAttribute('preserveAspectRatio', 'xMinYMin');
        this.svg.classList.add('csTutorialSVGView');

        // Hier holen wir uns die Informationen über das SVG vom Server
        this.updatePage();

        this.obj.appendChild(this.svg);

        this.initClickHandler();
    }

    updatePage(): void {
        if (assigned(this.svg)) {
            // Inhalt leeren, damit neu angefragt
            this.svg.innerHTML = '';
            this.svg.innerHTML = sendAjaxRequestGetText(getBaseUrl(this.id) + '&data=getSVG') as string;

            // In dem SVG holen wir uns alle Images und Prefixen unsere URL
            let images = this.svg.querySelectorAll('image');
            images.forEach(image => {
                if (!(image.getAttribute('href') === 'assets/images/shapes/tutorial/Image_Placeholder.svg'))
                    // Vorher steht in der href nur die ShapeID
                    image.setAttribute('href', getBaseUrl(this.id) + '&data=getImage' + '&shapeID=' + image.getAttribute('href') + '&t=' + new Date().getTime());
            });
        }
    }

    initClickHandler(): void {
        if (!this.isReadOnly)
            this.svg.addEventListener('click', () => {
                WebCompEventHandlerAsync('OnClick', this.id, null);
            });
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.RefreshPage':
                this.updatePage();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}