import { assigned } from "../../../utils/helper";
import { boolFromStr } from "../../../utils/strings";
import { TRenderWebComponent } from "../../base/class.web.comps";
import { getComponentForElement } from "../../base/controlling";
import { TwcCustomFileTransfer } from "./class.web.comp.filetransfer.base";

export class TwcFileTransferAttachments extends TRenderWebComponent {
    uploadInput: HTMLInputElement;
    fileTransferID: string;
    buttonFileAdd: HTMLElement;
    buttonFileAddEmpty: HTMLElement;
    fileTransfer: TwcCustomFileTransfer;
    container: HTMLElement;
    overlayadd: HTMLElement;
    overlayforbidden: HTMLElement;
    readOnly: boolean;
    overlayVisible: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcFileTransferAttachments';
        this.uploadInput = this.obj.querySelector(`#${this.id}-input`);

        // wenn der Upload nicht da ist, dann haben wir den auch nicht mitgesendet und sind fertig
        if (!assigned(this.uploadInput)) {
            return;
        }

        this.fileTransferID = this.obj.dataset.filetransferid;
        this.buttonFileAdd = this.obj.querySelector(`#${this.obj.dataset.addid}`);
        this.buttonFileAddEmpty = this.obj.querySelector(`#${this.obj.dataset.addemptyid}`);

        this.readOnly = boolFromStr(this.obj.dataset.readonly);
        this.overlayVisible = false;
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {

    }

    override initDomElement(): void {
        super.initDomElement();

        // wenn der Upload nicht da ist, dann haben wir den auch nicht mitgesendet und sind fertig
        if (!assigned(this.uploadInput)) {
            return;
        }

        this.uploadInput.addEventListener('change', (event) => this.handleFileSelect(event));
        // die beiden Click-Events müssen wir wegen iOS via JS machen
        this.buttonFileAdd.addEventListener('click', () => this.handleFileAddClicked());
        this.buttonFileAddEmpty.addEventListener('click', () => this.handleFileAddClicked());

        this.obj.addEventListener('dragenter', event => this.handleDragEnter(event), false);
        this.obj.addEventListener('dragover', event => this.handleDragOver(event), false);
        this.obj.addEventListener('dragleave', event => this.handleDragLeave(event), false);
        this.obj.addEventListener('drop', event => this.handleDrop(event), false);
    }

    getContainer(): HTMLElement {
        if (!assigned(this.container)) {
            this.container = this.obj.querySelector('.attachments-container');
        }

        return this.container;
    }

    getAddOverlay(): HTMLElement {
        if (!assigned(this.overlayadd)) {
            this.overlayadd = this.obj.querySelector('.cs-attchments-overlay-add');
        }

        return this.overlayadd;
    }

    getForbiddenOverlay(): HTMLElement {
        if (!assigned(this.overlayforbidden)) {
            this.overlayforbidden = this.obj.querySelector('.cs-attchments-overlay-forbidden');
        }

        return this.overlayforbidden;
    }

    toggleOverlays(useAdd: boolean | null, makeVisible: boolean): void {
        if (!makeVisible) {
            this.getAddOverlay().classList.add('d-none');
            this.getForbiddenOverlay().classList.add('d-none');

            this.getContainer().classList.remove('d-none');
            this.overlayVisible = false;

        } else {
            let height = this.getContainer().getBoundingClientRect().height;
            if (useAdd) {
                this.getForbiddenOverlay().classList.add('d-none');
                this.getAddOverlay().classList.remove('d-none');
                this.getAddOverlay().style.minHeight = height + 'px';
            } else {
                this.getAddOverlay().classList.add('d-none');
                this.getForbiddenOverlay().classList.remove('d-none');
                this.getForbiddenOverlay().style.minHeight = height + 'px';
            }

            this.getContainer().classList.add('d-none');
            this.overlayVisible = true;
        }
    }

    handleFileSelect(e: Event) {
        let file = this.uploadInput.files[0]
        if (!file) {
            this.uploadInput.value = '';
            return;
        }

        this.doUpload(file);
    }

    handleFileAddClicked() {
        this.uploadInput.click();
    }

    doUpload(file: File) {
        if (!assigned(this.fileTransfer)) {
            this.fileTransfer = getComponentForElement(this.obj.querySelector(`#${this.fileTransferID}`)) as TwcCustomFileTransfer;

            this.fileTransfer.onError.subscribe(() => this.onFileTransferError());
            this.fileTransfer.onSuccess.subscribe(() => this.onFileTransferSuccess());
        }

        this.fileTransfer.uploadFile(file);
    }

    onFileTransferError() {
        this.uploadInput.value = '';
    }

    onFileTransferSuccess() {
        this.uploadInput.value = '';
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
            case 'FileTypes':
                this.uploadInput.accept = value;
                break;
            case 'Readonly':
                this.readOnly = boolFromStr(value);
                break;
            case 'Enabled':
                // disabled/readonly verhalten sich gleich
                this.readOnly = !boolFromStr(value);
        }
    }

    handleDragEnter(e: DragEvent) {
        if (this.readOnly) {
            e.dataTransfer.dropEffect = 'none';
        }
        else {
            e.dataTransfer.dropEffect = 'copy';
        }
    }

    handleDragOver(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();

        // wenn das overlay eh schon sichtbar ist -> raus
        if (this.overlayVisible) {
            return;
        }

        let allowed = true;
        // über den Button lassen wir auch nur eine Datei zu - Code klappt aber auch mit mehreren Dateien
        if (e.dataTransfer.items.length > 1) {
            allowed = false;
        }

        // wir wollen nur Dateien zulassen
        if (allowed) {
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                if (e.dataTransfer.items[i].kind !== 'file') {
                    allowed = false;
                    break;
                }
            }
        }

        // falls nicht erlaubt hier raus
        if (this.readOnly || !allowed) {
            e.dataTransfer.dropEffect = 'none';
        }
        else {
            e.dataTransfer.dropEffect = 'copy';
            this.toggleOverlays(true, true);
        }
    }

    handleDragLeave(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();

        // solange wir innerhalb unserer Komponente draggen, soll das DragLeave nichts machen
        if ((e.currentTarget as HTMLElement).contains(e.relatedTarget as HTMLElement)) {
            return;
        }

        this.toggleOverlays(null, false);
    }

    handleDrop(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();

        let allowed = true;
        let files = e.dataTransfer.files;
        let allowedFileTypes = this.uploadInput.accept.length > 0 ? this.uploadInput.accept.split(',').map(function (word) { return word.toLowerCase(); }) : [];
        // erlauben wir nur bestimmte Dateien?
        if (allowedFileTypes.length > 0 && allowedFileTypes.indexOf('*') < 0) {
            for (let i = 0; i < files.length; i++) {
                let ext = files[i].name.substring(files[i].name.lastIndexOf("."));
                // haben wir eine Datei gedragged, die nicht erlaubt ist? --> kann man leider nicht früher prüfen
                if (allowedFileTypes.indexOf(ext.toLowerCase()) < 0) {
                    // dann zeigen wir das "nicht erlaubt" Overlay an
                    this.toggleOverlays(false, true);

                    // und nach drei Sekunden wieder ausblenden, so bekommt der User Feedback über nicht erlaubte Dateien
                    setTimeout(() => {
                        this.toggleOverlays(null, false);
                    }, 3000);
                    allowed = false;
                }
            }
        }

        // wenn Upload nicht erlaubt, gehen wir nun raus!
        if (this.readOnly || !allowed) {
            e.dataTransfer.dropEffect = 'none';
            return;
        }
        else {
            e.dataTransfer.dropEffect = 'copy';
        }

        for (let i = 0; i < files.length; i++) {
            this.doUpload(files[i]);
        }

        this.toggleOverlays(null, false);
    }
}