﻿import { TWebComponent } from '../../base/class.web.comps';

export class TwcToolbarSeparator extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcToolbarSeparator';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.parentElement.classList.toggle('d-none', value === '0');
                break;
        }
    }
}