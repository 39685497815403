import Color from 'color';
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';
import { TBaseTutorialShape } from '../Shapes/TBaseTutorialShape';
import { TCustomTutorialShapeAction, TutorialActionType } from './TTutorialEditorAction';

export class TTutorialShapeFontColorAction extends TCustomTutorialShapeAction {
    oldFontColor: Color;
    newFontColor: Color;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeFontColor;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldFontColor = Color(obj.oldFontColor);
        this.newFontColor = Color(obj.newFontColor);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldFontColor = this.oldFontColor.hex();
        obj.newFontColor = this.newFontColor.hex();

        return obj;
    }

    setValue(newFontColor: Color): void {
        this.newFontColor = newFontColor;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setFontColor(this.newFontColor);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeFontColorAction(this.refElement, this.svgEditor);
        result.setValue(this.oldFontColor);

        return result;
    }
}
