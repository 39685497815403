import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodeSizeAction extends TCustomNodeAction {
    oldWidth: number;
    oldHeight: number;
    newWidth: number;
    newHeight: number;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.oldWidth = this.refElement.w;
        this.oldHeight = this.refElement.h;
    }

    typeID(): ActionType {
        return ActionType.patShapeSize;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldWidth = obj.oldWidth;
        this.oldHeight = obj.oldHeight;
        this.newWidth = obj.newWidth;
        this.newHeight = obj.newHeight;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldWidth = Math.round(this.oldWidth);
        obj.oldHeight = Math.round(this.oldHeight);
        obj.newWidth = Math.round(this.newWidth);
        obj.newHeight = Math.round(this.newHeight);

        return obj;
    }

    setValue(newWidth: number, newHeight: number): void {
        this.newWidth = Math.round(newWidth);
        this.newHeight = Math.round(newHeight);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setNewDraftSize(this.newWidth, this.newHeight, true);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeSizeAction(this.refElement, this.svgEditor);
        result.setValue(this.oldWidth, this.oldHeight);

        return result;
    }
}