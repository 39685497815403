import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeOrganisation extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 850;
        this.roundCorners = true;
    }

    getSvgElementType(): string {
        return 'rect';
    }

    override repaint() {
        super.repaint();
         // rx und ry sind für die abgerundeten Ecken, siehe https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/rx
        this.svgElement.setAttribute('rx', '10');
        this.svgElement.setAttribute('ry', '10');
    }
}