import { WebCompEventHandler, WebCompEventHandlerAsync } from "../../../core/communication";
import { assigned } from "../../../utils/helper";
import { boolFromStr } from "../../../utils/strings";
import { TRenderWebComponent } from "../../base/class.web.comps";
import { findComponent } from "../../base/controlling";
import { ComponentProperty } from "../../interfaces/class.web.comps.intf";

export class TwcListBox extends TRenderWebComponent {
    container: HTMLElement;
    hasOnChange: boolean;
    hasOnRightClick: boolean;
    itemIndex: number;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcListBox';

        this.container = document.getElementById(this.id + '-container');
        this.itemIndex = parseInt(this.obj.dataset.itemindex);

        this.hasOnChange = boolFromStr(this.obj.dataset.hasonchange);
        this.hasOnRightClick = boolFromStr(this.obj.dataset.hasonrightclick);
    }

    override initDomElement(): void {
        super.initDomElement();
        // events must always be active!
        this.initEvents(null);
    }

    initEvents(element: Element | null): void {
        this.initDragAndDrop(element);
        this.initClick(element);
        this.initRightClick(element);
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        this.container.childNodes.forEach(element => {
            if (element instanceof HTMLElement) {
                element.classList.remove('active');
            }
        });

        if (this.itemIndex >= 0) {
            this.container.children[this.itemIndex].classList.add('active');
        }
    }

    setItemIndex(index: number | HTMLElement) {
        let oldIndex = this.itemIndex;

        if (index instanceof HTMLElement) {
            this.itemIndex = Array.from(this.container.children).indexOf(index)
        } else {
            this.itemIndex = index;
        }

        if (oldIndex !== this.itemIndex) {
            this.notifyComponentChanged();
            if (this.hasOnChange && !this.isUpdating()) {
                WebCompEventHandlerAsync('OnSelectionChanged', this.id);
            }
            // wir wollen sicherstellenn, dass der aktuelle ItemIndex selektiert ist
            this.invalidate();
        }
    }

    initClick(element: Element | null): void {
        let self = this;

        if (assigned(element) && element instanceof HTMLElement) {
            element.addEventListener('click', function () {
                self.setItemIndex(this);
            });
        } else {
            this.obj.querySelectorAll('.cs-listboxitem[data-parentid="' + this.id + '"]').forEach(function (item) {
                item.addEventListener('click', function () {
                    self.setItemIndex(this);
                });
            });
        }
    }

    initRightClick(element: Element | null): void {
        let self = this;

        function RightClick(event: MouseEvent) {
            event.preventDefault();

            self.setItemIndex(this);

            if (self.hasOnRightClick) {
                WebCompEventHandlerAsync('OnRightClick', self.id);
            }
        };

        if (assigned(element) && element instanceof HTMLElement) {
            element.addEventListener('contextmenu', RightClick);
        } else {
            this.obj.querySelectorAll('.cs-listboxitem[data-parentid="' + this.id + '"]').forEach(function (item) {
                item.addEventListener('contextmenu', RightClick);
            });
        }
    }

    initDragAndDrop(el: Element | null): void {
        let self = this;

        function DragElement(element: Element) {
            if (element instanceof HTMLElement) {
                element.addEventListener('dragstart', (event: DragEvent) => {
                    let dragElement = event.currentTarget as HTMLElement
                    self.setItemIndex(dragElement);
                    event.dataTransfer.setData('itemid', dragElement.dataset.itemid);
                    event.dataTransfer.setData('listboxid', dragElement.dataset.parentid);
                    event.dataTransfer.setData('listboxdrop', 'allowed');
                    // dragElement.classList.add('activeDragElement');
                });

                element.addEventListener('dragenter', (event: DragEvent) => {
                    event.preventDefault();
                });

                element.addEventListener('dragover', (event: DragEvent) => {
                    event.preventDefault();
                });

                element.addEventListener('drop', (event: DragEvent) => {
                    // default stoppen, falls vorhanden
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }

                    // aktuell nur Drop aus einer Listbox erlaubt
                    if (event.dataTransfer.getData('listboxdrop') !== 'allowed') {
                        return;
                    }

                    let dropElement = event.currentTarget as HTMLElement;
                    let itemID = event.dataTransfer.getData('itemid');
                    let listBoxID = event.dataTransfer.getData('listboxid');

                    let dragElement: HTMLElement;
                    if (listBoxID !== self.id) {
                        dragElement = document.querySelector('[data-itemid="' + itemID + '"][data-parentid="' + listBoxID + '"]');
                    } else {
                        dragElement = self.obj.querySelector('[data-itemid="' + itemID + '"][data-parentid="' + listBoxID + '"]');
                    }
                
                    if (!assigned(dragElement)) {
                        return;
                    }

                    let oldIndex = Array.from(dragElement.parentNode.children).indexOf(dragElement);
                    let newIndex = Array.from(dropElement.parentNode.children).indexOf(dropElement);
                    let s = '';

                    // dropElement innerhalb der eigenen Liste
                    if (dragElement.parentElement == self.container) {
                        // alte Pos | neue Pos merken und senden
                        s = oldIndex + '|' + newIndex;

                        self.notifyComponentChanged();
                        WebCompEventHandler('dragged', self.id, s);
                    } else {
                        // drop aus einer anderen Liste
                        let oldListBox = findComponent(listBoxID);
                        // alte TwcListBoxID | alte Pos | neue Pos merken und senden
                        s = oldListBox.id + '|' + oldIndex + '|' + newIndex;

                        self.notifyComponentChanged();
                        oldListBox.notifyComponentChanged();
                        WebCompEventHandler('newElementDragged', self.id, s);
                    }
                });
            }
        }

        if (assigned(el) && el instanceof HTMLElement) {
            DragElement(el);
        } else {
            this.obj.querySelectorAll('.cs-listboxitem[data-parentid="' + this.id + '"][draggable="true"]').forEach(element => DragElement(element));

            // nur wenn drag erlaubt ist
            if (this.container.hasAttribute('draggable')) {
                // müssen wir das leider auf dem Container nochmal machen,
                // da die Logik etwas anders ist

                this.container.addEventListener('dragenter', (event: DragEvent) => {
                    event.preventDefault();
                });

                this.container.addEventListener('dragover', (event: DragEvent) => {
                    event.preventDefault();
                });

                this.container.addEventListener('drop', (event: DragEvent) => {
                    // default stoppen, falls vorhanden
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }

                    if (event.dataTransfer.getData('listboxdrop') !== 'allowed') {
                        return;
                    }

                    let dropElement = event.currentTarget as HTMLElement;
                    let itemID = event.dataTransfer.getData('itemid');
                    let listBoxID = event.dataTransfer.getData('listboxid');

                    let dragElement: HTMLElement;
                    if (listBoxID !== self.id) {
                        dragElement = document.querySelector('[data-itemid="' + itemID + '"][data-parentid="' + listBoxID + '"]');
                    } else {
                        dragElement = this.obj.querySelector('[data-itemid="' + itemID + '"][data-parentid="' + listBoxID + '"]');
                    }
                    if (!assigned(dragElement)) {
                        return;
                    }

                    let oldIndex = Array.from(dragElement.parentNode.children).indexOf(dragElement);
                    let newIndex = self.container.childElementCount - 1;
                    let s = '';


                    // dropElement innerhalb der eigenen Liste
                    if (dragElement.parentElement == self.container) {
                        // alte Pos | neue Pos merken und senden
                        s = oldIndex + '|' + newIndex;

                        self.notifyComponentChanged();
                        WebCompEventHandler('dragged', self.id, s);
                    } else {
                        // drop aus einer anderen Liste
                        let oldListBox = findComponent(dragElement.dataset.parentid);
                        // neues Element hinten dran
                        newIndex++;
                        // alte TwcListBoxID | alte Pos | neue Pos merken und senden
                        s = oldListBox.id + '|' + oldIndex + '|' + newIndex;

                        self.notifyComponentChanged();
                        oldListBox.notifyComponentChanged();
                        WebCompEventHandler('newElementDragged', self.id, s);
                    }
                });
            }
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ItemIndex', this.itemIndex]);
        return properties;
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.Add':
                // neues Element einfach adden
                this.container.insertAdjacentHTML('beforeend', params);
                // und das DragnDrop anwenden
                this.initEvents(this.container.lastElementChild);
                break;
            case 'Action.Delete':
                // Element am Index löschen
                let index = parseInt(params);
                if (index >= 0 && index <= this.container.childElementCount) {
                    let elem = this.container.children[index];
                    elem.remove();
                }
                break;
            case 'Action.Exchange':
                // Element an Pos old nach new setzen
                let arr = params.split('|');
                let oldIndex = parseInt(arr[0]);
                let newIndex = parseInt(arr[1]);

                if (newIndex < oldIndex) {
                    $(this.container).children().eq(newIndex).before($(this.container).children().eq(oldIndex))
                } else {
                    $(this.container).children().eq(newIndex).after($(this.container).children().eq(oldIndex));
                }
                break;
            case 'Action.Clear':
                while (this.container.lastElementChild) {
                    this.container.removeChild(this.container.lastElementChild);
                }
                break;
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                document.getElementById(this.id).classList.toggle('d-none', value == '0');
                break;
            case 'Enabled':
                // Alle Elemente disablen
                this.container.childNodes.forEach(element => {
                    if (element instanceof HTMLElement) {
                        element.classList.toggle('disabled', value == '0');
                    }
                });
                break;
            case 'ItemIndex':
                this.setItemIndex(parseInt(value));
        }
    }
}

export class TwcListBoxSearch extends TwcListBox {
    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcListBoxSearch';
    }
}