import { findComponent } from '../comps/base/controlling';
import { ComponentProperty } from '../comps/interfaces/class.web.comps.intf';
import { actProcessingModeSpinnerEmbedded, actProcessingModeSpinnerOverlay, actProcessingSpinnerFullOverlay, ComponentProcessingMode, tryGetAsProcessingMode } from '../comps/interfaces/processingMode';
import { isDev } from '../utils/devUtils';
import { replaceHtml, setHtml } from '../utils/domUtils';
import { assigned } from '../utils/helper';
import { getStringSizeKB } from '../utils/strings';
import { canUseDispatcher, notifyGlobalDisconnect } from './dispatcher';
import { CSEndpointGuid, getBaseUrl } from './endpoint';
import { AjaxError } from './interaction';
import { ClearNotification, MessageType, MsgNotifyEx } from './utils/msgnotify';


function sendData(obj: unknown, callback: (result: unknown) => unknown, isAsync: boolean): void {
    // Wir holen wenn möglich einen Request-Lock, damit der Server nicht überlastet wird
    if (!acquireRequestLock()) {
        pushRequestQueue(obj, callback, isAsync);
        console.debug(obj);
        console.debug(callback);
        console.debug(isAsync);
        return;
    }

    let jsonStr = '[]';
    let url = new URL(window.location.origin);
    url.pathname = window.location.pathname;
    url.searchParams.append('handler', 'dispatcher');
    url.searchParams.append('endpointguid', CSEndpointGuid);

    if (isDev()) {
        var tStart = Date.now();
    }

    if (canUseDispatcher()) {
        $.ajax({
            async: isAsync,
            method: 'POST',
            dataType: 'json',
            url: url.href,
            crossDomain: true,
            data: {
                data: JSON.stringify(obj)
            },
            success: (result) => {
                if (isDev()) {
                    var tNetwork = Date.now();
                }

                releaseRequestLock();
                callback(result);

                if (isDev()) {
                    var tFinish = Date.now();
                    let debugOutput = [
                        { action: 'Data sent', value: getStringSizeKB(JSON.stringify(obj)) + 'KB', timeElapsed: '0ms' },
                        { action: 'Data received', value: getStringSizeKB(jsonStr) + 'KB', timeElapsed: tNetwork - tStart + 'ms' },
                        { action: 'Data applied', timeElapsed: tFinish - tNetwork + 'ms' },
                        { action: 'Total', value: getStringSizeKB(JSON.stringify(obj) + jsonStr) + 'KB', timeElapsed: tFinish - tStart + 'ms' },
                    ];
                    console.groupCollapsed(['Dispatcher event "sendData"']);
                    console.table(debugOutput);
                    console.groupCollapsed(['Dispatcher event "sendData" (Data sent)']);
                    console.log(JSON.stringify(obj));
                    console.groupEnd();
                    console.groupCollapsed(['Dispatcher event "sendData" (Data received)']);
                    console.log(jsonStr);
                    console.groupEnd();
                    console.groupEnd();
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
                // Ansonsten gab es eine Antwort vom Server -> Error an UI
                else {
                    AjaxError(xhr);
                }

                // Der Request ist fertig, Lock aufgeben
                releaseRequestLock();
            }
        });
    }
}

/*------------------------------------------*/
// Wir fordern die Komponente neu an und tauschen diese im Web komplett aus 
// Achtung: War vorher "setHTML" aber wir bekommen hier ggf. ja auch noch mal die ID zurück
// 2019.04.01 ABa: Wieder auf setHTML gesetzt für Frames, da dort keine ID kommt und wir die Funktion hier nur dort nutzen
export function sendComponentRequestGetPlainAsync(id: string, callback?: () => unknown): void {
    let url = getBaseUrl(id);

    if (canUseDispatcher()) {
        $.ajax({
            async: true,
            method: 'POST',
            url: url,
            crossDomain: true,
            data: {},
            success: function (result) {
                setHtml(id, result);
                if (assigned(callback)) {
                    callback();
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
                // Ansonsten gab es eine Antwort vom Server -> Error an UI
                else {
                    AjaxError(xhr);
                }
            }
        });
    }
}
window[sendComponentRequestGetPlainAsync.name] = sendComponentRequestGetPlainAsync;

/*------------------------------------------*/
export function sendComponentRequestGetJson(id: string, obj: unknown): unknown {
    let url = getBaseUrl(id);
    let resultObj = {};

    if (canUseDispatcher()) {
        $.ajax({
            async: false,
            method: 'POST',
            dataType: 'json',
            url: url,
            crossDomain: true,
            data: {
                data: JSON.stringify(obj)
            },
            success: function (result) {
                resultObj = result;
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
                // Ansonsten gab es eine Antwort vom Server -> Error an UI
                else {
                    AjaxError(xhr);
                }
            }
        });
    }

    return resultObj;
}
window[sendComponentRequestGetJson.name] = sendComponentRequestGetJson; // alter Prozesseditor;

export async function sendComponentRequestGetJsonAsync(id: string, obj: any): Promise<unknown> {
    if (!canUseDispatcher()) {
        return null;
    }

    let response = await fetch(getBaseUrl(id), {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        body: new URLSearchParams({ data: JSON.stringify(obj) }),
    });

    if (!response.ok) {
        notifyGlobalDisconnect();
    }

    if (assigned(response.body)) {
        return await response.json();
    }

    return null;
}

export function sendComponentRequestGetJsonNoError(id: string, obj: unknown, isAsync = false): unknown {
    let url = getBaseUrl(id);;
    let resultObj = {};

    if (canUseDispatcher()) {
        $.ajax({
            async: isAsync,
            method: 'POST',
            dataType: 'json',
            url: url,
            crossDomain: true,
            data: {
                data: JSON.stringify(obj)
            },
            success: function (result) {
                resultObj = result;
            },
            error: function (xhr, ajaxOptions, thrownError) {
                resultObj = undefined;

                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
            }
        });
    }

    return resultObj;
}

/*------------------------------------------*/
// Wir tauschen das Container-Element (mit der ID) komplett aus durch den Inhalt des URL-Requests
export function sendAjaxRequestUrlContent(id: string, url: string): void {
    if (canUseDispatcher()) {
        $.ajax({
            async: true,
            method: 'POST',
            url: url,
            crossDomain: true,
            data: {},
            success: function (result) {
                replaceHtml(id, result);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
                // Ansonsten gab es eine Antwort vom Server -> Error an UI
                else {
                    AjaxError(xhr);
                }
            }
        });
    }
}

/*------------------------------------------*/
// Derzeit nur vom process.viewer.svg verwendet!
export function sendAjaxRequestGetText(url: string): unknown {
    let resultObj = {};

    if (canUseDispatcher()) {
        $.ajax({
            async: false,
            method: 'POST',
            dataType: 'text',
            url: url,
            crossDomain: true,
            success: function (result) {
                resultObj = result;
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
                // Ansonsten gab es eine Antwort vom Server -> Error an UI
                else {
                    AjaxError(xhr);
                }
            },
            // Wenn unser GetText Request nach 8 Sekunden nicht beantwortet ist, blockieren wir nicht länger
            timeout: 8000 // sets timeout to 8 seconds
        });
    }

    return resultObj;
}

export function WebCompEventHandler(action: string, id: string, param: string = null): void {
    param = param ?? '';

    let dataArr = getFormProperties()
    dataArr = dataArr.concat([
        [id, '#Event#', action, param]
    ]);
    let json = { fields: dataArr };

    sendData(json, (result: Array<ComponentProperty>) => setFormProperties(result), false);
}
// Für Delphi freigeben
window[WebCompEventHandler.name] = WebCompEventHandler;

export function WebCompEventHandlerAsync(action: string, id: string, callback?: () => unknown, param?: string): void {
    callback = callback ?? function () { };
    param = param ?? '';

    let dataArr = getFormProperties()
    dataArr = dataArr.concat([
        [id, '#Event#', action, param]
    ]);

    let json = { fields: dataArr };

    sendData(json, (result: Array<ComponentProperty>) => {
        setFormProperties(result);

        return callback();
    }, true);
}
// Für delphi freigeben
window[WebCompEventHandlerAsync.name] = WebCompEventHandlerAsync;

export function WebCompProcessingModeEventHandler(action: string, id: string): void {
    let component = findComponent(id);

    if (assigned(component)) {
        let processingObj = tryGetAsProcessingMode(component);

        if (assigned(processingObj) && processingObj.ProcessingMode != ComponentProcessingMode.TProcessingModeNone) {
            if (processingObj.ProcessingMode == ComponentProcessingMode.TProcessingModeSpinnerEmbedded) {
                actProcessingModeSpinnerEmbedded(action, id);
            } else if (processingObj.ProcessingMode == ComponentProcessingMode.TProcessingModeSpinnerFullOverlay) {
                actProcessingSpinnerFullOverlay(action, id);
            } else if (processingObj.ProcessingMode == ComponentProcessingMode.TProcessingModeSpinnerOverlay) {
                actProcessingModeSpinnerOverlay(action, id);
            }
        }
        else {
            WebCompEventHandler(action, id);
        }
    }
}

// Für Delphi freigeben
window[WebCompProcessingModeEventHandler.name] = WebCompProcessingModeEventHandler;

/*------------------------------------------*/
/* RequestLock **/
/*------------------------------------------*/

// Requests für den Baum müssen geblockt werden, damit diese sequentiell ablaufen.
export let RequestIsLocked = false; // UPS // Eigentlich sollten die FUnktion acquire und release genutzt werden, aber die nutzt der Prozessviewer direkt
let requestNotification = null;

const queueInterval = window.setInterval(() => {
    if (requestQueue.length > 0 && !assigned(requestNotification)) {
        if ((Date.now() - requestQueue[0].waitStart) > REQUEST_MAX_WAITTIME) {
            requestNotification = MsgNotifyEx('Die Verbindung zum Server ist langsam. Einige Anfragen werden noch verarbeitet...', MessageType.Warning, false);
        }
    }
}, 1000);

interface RequestWaitData {
    obj?: unknown,
    callback: (result: unknown) => unknown,
    isAsync: boolean,
    waitStart: number
};
const REQUEST_MAX_WAITTIME = 3000; // milliseconds
const requestQueue: Array<RequestWaitData> = [];

export function releaseRequestLock(): void {
    RequestIsLocked = false;

    processRequestQueue();
}

export function acquireRequestLock(): boolean {
    if (RequestIsLocked) {
        // TODO: Hier könnten wir dann einen Wait-Spinner anzeigen
        return false;
    } else {
        RequestIsLocked = true;
        return true;
    }

}
export function pushRequestQueue(obj, callback, isAsync) {
    let requestData = { obj: obj, callback: callback, isAsync: isAsync, waitStart: Date.now() };
    requestQueue.push(requestData);
    console.warn('Cannot lock dispatcher. Request pushed in queue!')
}

export function processRequestQueue() {
    console.info('Try processing request queue.')
    let data = requestQueue.shift();

    if (assigned(data)) {
        console.debug(`Request waited for ${Date.now() - data.waitStart}ms.`);
        let callback = (result: unknown): unknown => {
            let returnValue = data.callback(result);

            if (requestQueue.length === 0 && assigned(requestNotification)) {
                document.body.classList.remove('waiting');
                ClearNotification(requestNotification);
                requestNotification = null; // die Variable danach auch resetten
            }

            return returnValue;
        }

        sendData(data.obj, callback, data.isAsync);
    }
}


/*------------------------------------------*/
// JSON String sieht z. B. so aus [["WC1797","Enabled","1"],["WC1797","Readonly","0"],["WC1797","Visible","1"]]
function setFormProperties(json_arr: Array<ComponentProperty>): void {
    let changedComponents = new Map();
    // wir gehen als erstes alle Komponenten durch und setzen dort die Properties
    json_arr.forEach(dataset => {
        let component = findComponent(dataset[0]);

        if (!assigned(component)) {
            // Einige unserer Basis Pageelemente scheinen übertragen zu werden als WC, dabei sind sie nur HTML.
            return;
        }

        // eine Action beginnt immer mit "#". Die wollen wir hier erstmal nicht, da zuerst die Properties gesetzt werden
        if (dataset[1].charAt(0) != '#') {
            component.writeChangedProperties(dataset[1], dataset[2]);
        }


        if (!changedComponents.has(dataset[0])) {
            changedComponents.set(dataset[0], component);
        }
    });

    json_arr.forEach(dataset => {
        let component = findComponent(dataset[0]);

        if (!assigned(component)) {
            // Einige unserer Basis Pageelemente scheinen übertragen zu werden als WC, dabei sind sie nur HTML.
            return;
        }

       // eine Action beginnt immer mit "#". Hier suchen wir genau nach einer Action und führen sie aus
        if (dataset[1].charAt(0) == '#') {
            console.log(dataset)
            component.execAction(dataset[1].slice(1), dataset[2]);
        }
    });
}


// TODO: Korrekten return type
function getFormProperties() {
    let propertiesArr = [];

    document.querySelectorAll('[data-type]').forEach(element => {
        if (element.getAttribute('type') !== 'submit') {
            let component = findComponent(element.id);
            if (assigned(component)) {
                if (!component.supportsTransferDirty() || component.isTransferDirty()) {
                    let properties = component.getChangedProperties();
                    if (assigned(properties) && properties.length > 0) {
                        propertiesArr = propertiesArr.concat(properties);
                    }
                    component.resetTransferDirty();
                }
            }
        }
    });

    return propertiesArr;
}


// UPS // ALTER PROZESSEDITOR SPEZIALLOCKE
function WebCompNotifyAsync(id, name, value) {
    let obj = {
        fields: [[id, name, value]]
    }

    let url = new URL(window.location.origin);
    url.pathname = window.location.pathname;
    url.searchParams.append('handler', 'dispatcher');
    url.searchParams.append('endpointguid', CSEndpointGuid);

    if (canUseDispatcher()) {
        $.ajax({
            async: true,
            method: 'POST',
            dataType: 'json',
            url: url.href,
            crossDomain: true,
            data: {
                data: JSON.stringify(obj)
            },
            error: function (xhr, ajaxOptions, thrownError) {
                // 0 heißt nicht abgeschlossen -> zusätzlich Dispatcher informieren
                if (xhr.status == 0) {
                    notifyGlobalDisconnect();
                }
                // Ansonsten gab es eine Antwort vom Server -> Error an UI
                else {
                    AjaxError(xhr);
                }
            }
        });
    }
}

window[WebCompEventHandlerAsync.name] = WebCompEventHandlerAsync;
