'use strict';

import {PointElement} from 'chart.js';

export class CustomElementLine extends PointElement {

	draw(ctx) {
		super.draw(ctx);
	}
}

CustomElementLine.id = 'customElementLine';
CustomElementLine.defaults = PointElement.defaults;
