﻿import Color from 'color';
import isEmpty from 'lodash.isempty';
import { getCurrentGlobalMousePosition } from '../../../app/consense.keyboard';
import { Bounds, Direction, directionPointToPoint, distancePointPoint, Point, SizeDefinition } from '../../../classes/geometry';
import { sendComponentRequestGetJsonNoError, WebCompEventHandlerAsync } from '../../../core/communication';
import { getBaseUrl } from '../../../core/endpoint';
import { EnumToValues } from '../../../utils/arrays';
import { isInViewport } from '../../../utils/domUtils';
import { assigned } from '../../../utils/helper';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';
import { TCustomAction } from './Actions/TCustomAction';
import { TCustomActionGroup } from './Actions/TCustomActionGroup';
import { TCustomActionList } from './Actions/TCustomActionList';
import { TEdgeBackgroundColorAction } from './Actions/TEdgeBackgroundColorAction';
import { TEdgeCaptionAction } from './Actions/TEdgeCaptionAction';
import { TEdgeCreateAction } from './Actions/TEdgeCreateAction';
import { TEdgeDeleteAction } from './Actions/TEdgeDeleteAction';
import { TEdgeExtraPointsAction } from './Actions/TEdgeExtraPointsAction';
import { TNodePositionAction } from './Actions/TNodePositionAction';
import { TNodeSizeAction } from './Actions/TNodeSizeAction';
import { AnchorHandle, TEdgeBase } from './Edges/TEdgeBase';
import { TBaseShape } from './Shapes/TBaseShape';
import { TBaseShapeBPMN } from './Shapes/TBaseShapeBPMN ';
import { TShapeBPMNActivity } from './Shapes/TShapeBPMNActivity';
import { TShapeBPMNEvent } from './Shapes/TShapeBPMNEvent';
import { TShapeKeyIndicator } from './Shapes/TShapeKeyIndicator';
import { TShapeLane } from './Shapes/TShapeLane';
import { TBaseGraphObject } from './TBaseGraphObject';
import { getActionByTypeID } from './Utils/TProcessEditorActionHandling';
import { recalcExtraPoints } from './Utils/TProcessEditorExtraPoints';
import { getIDSuffix, SVGNS } from './Utils/TProcessEditorIDUtils';
import { initMoveEvents } from './Utils/TProcessEditorMoveUtils';
import { initResizeEvents } from './Utils/TProcessEditorResizeUtils';

type EventHandler<T extends Event> = (event: T) => void;
type AfterArrowModeCallback = (wasSuccessfull: boolean) => void;

/**
 * Entspricht dem TProcessEditModes in Delphi!
 * @remarks BPMN existiert nur bei Enterprise
 */
export enum ProcessMode {
    Normal,
    SwimLaneVertical,
    SwimLaneHorizontal,
    BPMN,
    VirtualGrid
}

export const ProcessModesSwimLanes = [ProcessMode.SwimLaneVertical, ProcessMode.SwimLaneHorizontal, ProcessMode.BPMN];
export const ProcessModesWithGrid = EnumToValues<ProcessMode>(ProcessMode).filter(item => ProcessModesSwimLanes.indexOf(item) < 0);

// WICHTIG: Wenn der Typ erweitert wird bitte auch die Funktion "getDefaultArrowModeConfiguration" anpassen!!!

/**
 * Configuration type for arrow mode.
 */
export type ArrowModeConfiguration = {
    /**
     * If true, the start shape is interpreted as destination.
     */
    isReverseMode?: boolean,
    /**
     * If true, existing edges between target nodes will be replaced. 
     */
    replaceExistingEdges?: boolean,
    /**
     * If set, this edge will be replaced with the new edge.
     */
    replacingEdge?: TEdgeBase,
    /**
     * Callback which is called after Arrow mode is left.
     */
    afterArrowModeCallback?: AfterArrowModeCallback,
}

export abstract class TwsProcessEditorCustom extends TRenderWebComponent {
    private isInitialized: boolean;
    private useGrid: boolean;

    // UPS: Hier sollte man idealerweise vllt auch mal einige private oder protected etc machen....
    processMode: ProcessMode;

    actionList: TCustomActionList;
    editorElement: HTMLElement;
    objectList: Array<TBaseGraphObject>;
    editorSVG: SVGSVGElement;
    background: SVGRectElement;
    grid: SVGRectElement;
    height: number;
    width: number;
    svgID: string;
    zoomSlider: HTMLElement;
    zoomFactor: number;
    svgDefs: SVGDefsElement;
    isArrowMode: boolean;
    isElementEditMode: boolean;
    edgeColor: Color;
    backgroundImage: SVGImageElement;
    connectorsSizeFixed: boolean;
    sizeHorizontalSpace: number;
    sizeVerticalSpace: number;
    sizeMarginLeft: number;
    sizeMarginTop: number;
    colorMode: number;
    allowedQuickShapeActions: Array<number>;
    elementEditLocked: boolean;
    suffixStyle: string;
    disableAutoOrder: boolean;

    arrowSourceShape: TBaseShape;
    arrowSourceHandle: AnchorHandle;
    draftEdge: SVGPathElement;
    arrowModeMoveEventHandler: EventHandler<MouseEvent>
    arrowModeBackgroundClickEventHandler: EventHandler<MouseEvent>;
    arrowModeConfig: ArrowModeConfiguration;
    fullscreen: boolean;
    /**
     * The SizeDefinitions of the used underlaying grid.
     * @remarks This is identical to the grid size used in ConSense Suite.
    */
    gridSize: SizeDefinition;

    imageSize: SizeDefinition;
    imgUrl: string;
    backgroundColor: Color;
    elementCaptionEditLock: boolean;

    //#region Component Init
    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcProcessEditorCustom';

        // DO FIRST
        this.isInitialized = false;

        // base initialization
        this.zoomSlider = document.getElementById(getIDSuffix(this.id, 'zoom'));

        this.objectList = [];
        this.actionList = new TCustomActionList();
        this.actionList.registerChangeEvent(action => this.handleActionListChanged(action));

        // svg
        this.svgID = getIDSuffix(this.id, 'editor');
        this.editorElement = document.getElementById(getIDSuffix(this.id, 'svg'));
        this.editorSVG = null;
        this.svgDefs = null;
        this.background = null;
        this.backgroundImage = null;
        this.grid = null;

        // editor constraints
        this.width = 50;
        this.height = 50;
        this.zoomFactor = parseInt(this.editorElement.dataset.zoom) / 100;
        this.fullscreen = false;

        // process / editor options
        this.isArrowMode = false;
        this.isElementEditMode = false;

        this.processMode = ProcessMode[ProcessMode[this.editorElement.dataset.processMode as unknown as ProcessMode ?? ProcessMode.BPMN]];

        this.elementEditLocked = false;
        this.elementCaptionEditLock = false;

        this.connectorsSizeFixed = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.connectorsSizeFixed ?? false;
        this.sizeHorizontalSpace = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.sizeHorizontalSpace ?? 2;
        this.sizeVerticalSpace = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.sizeVerticalSpace ?? 2;
        this.sizeMarginLeft = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.sizeMarginLeft ?? 2;
        this.sizeMarginTop = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.sizeMarginTop ?? 2;

        this.colorMode = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.colorMode ?? 1;
        this.edgeColor = Color(this.editorElement.dataset.edgeColor);
        this.suffixStyle = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.suffixStyle ?? '';
        this.disableAutoOrder = csJSONParse(this.editorElement.dataset?.processTypeOptions ?? '{}')?.disableAutoOrder ?? false;
        this.backgroundColor = Color(this.editorElement.dataset.backgroundColor);
        this.editorElement.style.backgroundColor = this.backgroundColor.hex();

        this.useGrid = boolFromStr(this.obj.dataset.useGrid, true) || this.processMode === ProcessMode.VirtualGrid; // Das ist im wesentlichen nur für die Testingseite relevant wenn man von "normal" zu "Raster" wechselt und zuvor das grid deaktiviert hat
        this.gridSize = { width: 17, height: 17 };

        this.allowedQuickShapeActions = [];
        (this.obj.dataset?.allowedQuickShapes ?? '').split(';').forEach(s => {
            let value = parseInt(s);
            if (!isNaN(value)) {
                this.allowedQuickShapeActions.push(value);
            }
        });

        this.imgUrl = undefined;
        let imgInformation = csJSONParse(this.editorElement.dataset?.backgroundImage ?? '{}');
        this.setBackgroundImage({ width: imgInformation.width ?? 0, height: imgInformation.height ?? 0 });
    }

    override initDomElement() {
        super.initDomElement();

        // initialize process
        this.initEditor();
        this.parseFromJSON(this.editorElement.dataset.json);

        // remember initialization
        this.isInitialized = true;

        // ArrowMode Events als Referenz merken
        this.arrowModeMoveEventHandler = (event: MouseEvent) => this.handleArrowModeMouseMove(event);
        this.arrowModeBackgroundClickEventHandler = (event: MouseEvent) => this.handleArrowModeClick(event);

        // Größe speichern und auf Veränderungen reagieren
        let observer = new ResizeObserver(() => {
            this.checkEditorSize();
        });
        observer.observe(this.obj);
    }
    //#endregion

    //#region SVG Init
    parseFromJSON(jsonsrc: string): void {
        let src = csJSONParse(jsonsrc);

        if (isEmpty(src)) {
            return;
        }

        let action = getActionByTypeID(src.actionType, this.objectById(src.refElementID), this);
        action.fromJSON(src);
        action.performAction();

        // die "create" Action brauchen wir auch für den initialen Zustand
        this.actionList.addClientAction(action);

        // Prozess ggf. auf sein Grid migrieren
        if (this.hasGrid()) {
            let gridMigrateAction = this.ensureGrid();

            if (assigned(gridMigrateAction)) {
                this.actionList.addClientAction(gridMigrateAction);

                // BESONDERHEIT: Da wir zu diesem Zeitpunkt in der Regel noch in der Initialisierung sind greift das Change Event auf der ActionList noch nicht und wir müssen die Action manuell an den Server pushen!
                let executeServerActionFunc = () => this.forceServerAction(gridMigrateAction);
                // da das einen Request auslöst dürfenn wir das erst machen, wenn wir registered sind.
                if (!this.isRegistered()) {
                    this.onRegister().one(executeServerActionFunc);
                }
                else {
                    executeServerActionFunc();
                }
            }
        }
    }

    initEditor(): void {
        this.initSVGElements();
        this.initEvents();
        this.initDragNDropEvents();
    }

    initDragNDropEvents(): void {
        /* brauchen wir aktuell noch nicht
        this.editorSVG.addEventListener('dragenter', (event) => {
            console.log("dragenter");
        });
        this.editorSVG.addEventListener('dragleave', (event) => {
            console.log("dragleave");
        });
        */
        this.editorSVG.addEventListener('dragover', (event) => {
            // den müssen wir auch anbinden, damit das dropevent funktioniert
            event.preventDefault();
        });

        this.editorSVG.addEventListener('drop', (event) => {
            event.preventDefault();
            let shapeid = event.dataTransfer.getData('text');
            if (shapeid != '') {
                let pt = this.getLocalCoordinatesFromMouseEvent(event);
                pt.x = this.snapToUsedGridX(pt.x);
                pt.y = this.snapToUsedGridX(pt.y);
                WebCompEventHandlerAsync('OnDrop', this.id, null, `${shapeid};${Math.round(pt.x)};${Math.round(pt.y)}`);
            }
        });

    }

    initSVGElements(): void {
        this.editorSVG = document.createElementNS(SVGNS, 'svg');
        this.editorSVG.setAttribute('id', this.svgID);
        this.editorElement.appendChild(this.editorSVG);
        this.editorSVG.classList.add('svgEditor');

        this.svgDefs = document.createElementNS(SVGNS, 'defs');
        this.editorSVG.appendChild(this.svgDefs);

        this.background = document.createElementNS(SVGNS, 'rect');
        this.background.setAttribute('id', getIDSuffix(this.svgID, 'bg'));
        this.editorSVG.appendChild(this.background);

        this.backgroundImage = document.createElementNS(SVGNS, 'image');
        this.backgroundImage.setAttribute('id', getIDSuffix(this.id, 'bgImage'));
        this.editorSVG.appendChild(this.backgroundImage);

        this.grid = document.createElementNS(SVGNS, 'rect');
        this.grid.setAttribute('id', getIDSuffix(this.svgID, 'grid'));
        this.editorSVG.appendChild(this.grid);
    }

    initEvents(): void {
        this.background.addEventListener('mousedown', event => this.handleMouseDownEvent(event));

        // Im Rastermodus machen wir das Kontextmenü auf den Zellen und nicht auf dem Hintergrund
        if (this.processMode !== ProcessMode.VirtualGrid) {
            this.background.addEventListener('contextmenu', event => {
                event.preventDefault();

                let pt = this.getLocalCoordinatesFromMouseEvent(event);

                // snap to grid            
                pt.x = this.snapToUsedGridX(pt.x);
                pt.y = this.snapToUsedGridX(pt.y);

                WebCompEventHandlerAsync('OnContextMenu', this.id, null, `${Math.round(pt.x)};${Math.round(pt.y)}`);
            });
        }

        this.editorSVG.addEventListener('mousedown', event => {
            this.invalidate();
        });

        this.editorSVG.addEventListener('wheel', event => {
            if (event.ctrlKey) {
                event.preventDefault();
                let newZoom = Math.round(this.zoomFactor * 100 + event.deltaY * -0.1);

                if (newZoom < 10 || newZoom > 400) {
                    // Außerhalb des gültigen Bereichs
                }
                else {
                    this.zoomEditor(newZoom);
                    WebCompEventHandlerAsync('OnZoomChange', this.id, null, String(newZoom));
                }
            }
        });

        // block defaults of keydowns
        window.addEventListener('keydown', event => {
            if (isInViewport(this.editorSVG)) {
                let connectorAction = event.ctrlKey && (event.key === '1' || event.key === '2' || event.key === '3' || event.key === '4');
                let moveAction = event.key === 'ArrowLeft' || event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown';
                let tabAction = event.key === 'Tab';
                let cancelAction = event.key === 'Escape';

                if (cancelAction) {
                    event.preventDefault();
                }
                else if (!this.isArrowMode && !this.elementEditLocked && this.isSingleShapeSelected() && (connectorAction || moveAction || tabAction)) {
                    event.preventDefault();
                }
            }
        });

        window.addEventListener('keyup', event => {

            function checkConnection(srcShape: TBaseShape, direction: Direction, srcAnchor: AnchorHandle, dstAnchor: AnchorHandle, radius: number = Number.MAX_VALUE) {
                if (!srcShape.hasAnchors) {
                    return;
                }

                let editor = srcShape.editor;
                let closestShape = editor.findClosestShape(srcShape.center(), direction, editor.gridSize.width * radius);
                let connectingEdge = editor.objectList.find(obj => obj instanceof TEdgeBase && obj.srcShape === srcShape && obj.dstShape === closestShape);

                if (assigned(closestShape) && closestShape !== srcShape) {
                    if (!assigned(connectingEdge) && closestShape.hasAnchors) {
                        // neue Verbindung zwischen den beiden Shapes
                        let actionGroup = new TCustomActionGroup(null, editor);

                        let actCreate = new TEdgeCreateAction(null, editor);
                        actCreate.setValue(srcShape.id, srcAnchor, closestShape.id, dstAnchor);

                        let actColor = new TEdgeBackgroundColorAction(actCreate.refElement, editor);
                        actColor.setValue(editor.edgeColor);

                        let newExtraPts = recalcExtraPoints(editor, srcShape, srcAnchor, closestShape, dstAnchor);
                        let actExtraPt = new TEdgeExtraPointsAction(actCreate.refElement, editor);
                        actExtraPt.setValue(newExtraPts);

                        let actCaption = new TEdgeCaptionAction(actCreate.refElement, editor);
                        actCaption.setValue('');

                        actionGroup.actions.push(actCreate);
                        actionGroup.actions.push(actColor);
                        actionGroup.actions.push(actExtraPt);
                        actionGroup.actions.push(actCaption);

                        if (actionGroup.actions.length > 0) {
                            editor.actionList.addClientAction(actionGroup);
                            editor.actionList.actions[editor.actionList.listPointer].performAction();
                        }
                    }
                }
            }
            if (isInViewport(this.editorSVG)) {
                let selectedShapes = this.getSelectedShapes();
                let selectedEdges = this.getSelectedEdges();
                let isSingleShapeSelected = this.isSingleShapeSelected();
                let isSingleEdgeSelected = this.isSingleEdgeSelected();

                if (event.key === 'Escape') {
                    if (this.isArrowMode) {
                        this.stopArrowMode(true);
                    }
                    else if (this.isElementEditMode) {
                        this.stopElementEditMode();
                    }
                    else {
                        this.clearSelectedElements();
                        this.notifySelectedElementsChanged();
                    }
                }
                // während des arrowmodes und der bearbeitung im modal sind die funktionen blockiert
                else if (!this.isArrowMode && !this.elementEditLocked) {
                    // Wenn wir Elemente selektiert haben, nutzen wir die Pfeiltasten zum Verschieben
                    // Die Action senden wir erst beim Keyup zum Server, um bei längerem Halten der Taste zu viele Serveranfragen zu vermeiden
                    if (!this.elementCaptionEditLock && (selectedShapes.length > 0 && (event.key === 'ArrowLeft' || event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown'))) {
                        event.preventDefault();

                        if (event.shiftKey && event.ctrlKey) {
                            // ctrl + shift + [cursortaste] => größer / kleiner werden (rechts/unten verändern sich)
                            this.inflateShape(event.key);
                        }
                        else if (event.shiftKey && event.altKey) {
                            // shift+alt + [cursortaste] => connector zwischen aktivem shape und nächstem in die richtung
                            if (isSingleShapeSelected) {
                                switch (event.key) {
                                    // up&down = 10, left&right = 20
                                    case 'ArrowUp':
                                        checkConnection(selectedShapes[0], Direction.Up, AnchorHandle.ahTop, AnchorHandle.ahBottom);
                                        break;
                                    case 'ArrowDown':
                                        checkConnection(selectedShapes[0], Direction.Down, AnchorHandle.ahBottom, AnchorHandle.ahTop);
                                        break;
                                    case 'ArrowLeft':
                                        checkConnection(selectedShapes[0], Direction.Left, AnchorHandle.ahLeft, AnchorHandle.ahRight);
                                        break;
                                    case 'ArrowRight':
                                        checkConnection(selectedShapes[0], Direction.Right, AnchorHandle.ahRight, AnchorHandle.ahLeft);
                                        break;
                                }
                            }
                        }
                        else if (event.shiftKey) {
                            // shift + [cursortaste] => zu beiden seiten größer werden (oben/unten || rechts/links verändern sich)
                            this.inflateShape(event.key, true);
                        }
                        else if (event.ctrlKey) {
                            // ctrl + [cursortaste] => bewegen
                            this.moveShape(event.key);
                        }
                        else {
                            // select closest shape
                            if (isSingleShapeSelected) {
                                let closestShape: TBaseShape;

                                switch (event.key) {
                                    case 'ArrowUp':
                                        closestShape = this.findClosestShape(selectedShapes[0].center(), Direction.Up);
                                        break;
                                    case 'ArrowDown':
                                        closestShape = this.findClosestShape(selectedShapes[0].center(), Direction.Down);
                                        break;
                                    case 'ArrowLeft':
                                        closestShape = this.findClosestShape(selectedShapes[0].center(), Direction.Left);
                                        break;
                                    case 'ArrowRight':
                                        closestShape = this.findClosestShape(selectedShapes[0].center(), Direction.Right);
                                        break;
                                }

                                if (assigned(closestShape)) {
                                    this.clearSelectedElements();
                                    closestShape.selected = true;
                                    this.notifySelectedElementsChanged();
                                }
                            }
                        }
                    }

                    // Tab zum durchlaufen der OwnerPos
                    if (isSingleShapeSelected && event.key === 'Tab') {
                        event.preventDefault();
                        let newShape: TBaseShape;

                        if (event.shiftKey)
                            newShape = this.findPreviousShape(selectedShapes[0]);
                        else
                            newShape = this.findNextShape(selectedShapes[0]);

                        if (assigned(newShape)) {
                            this.clearSelectedElements();
                            newShape.selected = true;
                            this.notifySelectedElementsChanged();
                        }
                    }

                    // Inline Textedit
                    if ((isSingleShapeSelected || isSingleEdgeSelected) && event.key === 'F2') {
                        event.preventDefault();

                        let graphObj: TBaseGraphObject;

                        if (isSingleShapeSelected)
                            graphObj = selectedShapes[0];
                        else if (isSingleEdgeSelected)
                            graphObj = selectedEdges[0];

                        let graphObjText = graphObj.captionElement.innerDiv;

                        if (graphObj.inlineEdit) {
                            this.elementCaptionEditLock = true;
                            graphObjText.setAttribute('contenteditable', String(true));

                            // extras TBaseShape
                            if (graphObj instanceof TBaseShape) {
                                graphObjText.classList.add('cs-edit-shape-caption');

                                // zur Bearbeitung entfernen wir die Punkte der Extrainformationen
                                if (graphObjText.innerHTML.endsWith(this.suffixStyle)) {
                                    graphObjText.innerHTML = graphObjText.innerHTML.slice(0, -this.suffixStyle.length);
                                }
                                // wenn AutoOrder deaktiviert ist, entfernen wir das zOrder Präfix
                                if (this.disableAutoOrder) {
                                    let textPrefix = graphObj.getOwnerPos() + 1 + ': ';
                                    graphObjText.innerHTML = graphObjText.innerHTML.slice(textPrefix.length);
                                }
                            }

                            // extras TEdgeBase
                            if (graphObj instanceof TEdgeBase) {
                                graphObjText.classList.add('cs-edit-edge-caption');
                            }

                            const selection = window.getSelection();
                            const range = document.createRange();
                            selection.removeAllRanges();
                            range.selectNodeContents(graphObjText);
                            range.collapse(false);
                            selection.addRange(range);
                            graphObjText.focus();
                        }
                    }

                    // Start Connector von ShapeAnchor
                    if (isSingleShapeSelected && (event.key === '1' || event.key === '2' || event.key === '3' || event.key === '4') && event.ctrlKey) {
                        event.preventDefault();
                        let mousePos = this.getLocalCoordinate(getCurrentGlobalMousePosition());

                        switch (event.key) {
                            // oben
                            case '1':
                                this.startArrowMode(selectedShapes[0], AnchorHandle.ahTop, null, mousePos);
                                break;
                            // rechts
                            case '2':
                                this.startArrowMode(selectedShapes[0], AnchorHandle.ahRight, null, mousePos);
                                break;
                            // unten
                            case '3':
                                this.startArrowMode(selectedShapes[0], AnchorHandle.ahBottom, null, mousePos);
                                break;
                            // links
                            case '4':
                                this.startArrowMode(selectedShapes[0], AnchorHandle.ahLeft, null, mousePos);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        });

        initMoveEvents(this);
        initResizeEvents(this);
    }
    //#endregion

    //#region Events
    getLocalCoordinatesFromMouseEvent(evt: { clientX: number; clientY: number; }) {
        let pt = this.editorSVG.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;
        return pt.matrixTransform(this.editorSVG.getScreenCTM().inverse());
    }

    handleMouseDownEvent(event: MouseEvent): void {
        if (!event.shiftKey) {
            this.clearSelectedElements();
        }

        let editor = this;

        let pointStart = this.getLocalCoordinatesFromMouseEvent(event);
        let selectionRect = document.createElementNS(SVGNS, 'rect');
        selectionRect.setAttribute('class', 'selectionRect');
        updateSelectionRect(selectionRect, pointStart, pointStart);
        editor.editorSVG.appendChild(selectionRect);

        document.documentElement.addEventListener('mousemove', trackMouseMove, false);
        document.documentElement.addEventListener('mouseup', stopTrackingMove, false);

        function trackMouseMove(evt: { clientX: number; clientY: number; }) {
            let pointEnd = editor.getLocalCoordinatesFromMouseEvent(evt);
            updateSelectionRect(selectionRect, pointStart, pointEnd);
        }

        function stopTrackingMove() {
            document.documentElement.removeEventListener('mousemove', trackMouseMove, false);
            document.documentElement.removeEventListener('mouseup', stopTrackingMove, false);
            editor.editorSVG.removeChild(selectionRect);
            updateSelection(selectionRect);
        }

        function updateSelectionRect(rect: SVGRectElement, ptStart: DOMPoint, ptEnd: DOMPoint) {
            let xs = [ptStart.x, ptEnd.x].sort(sortByNumber),
                ys = [ptStart.y, ptEnd.y].sort(sortByNumber);
            rect.setAttribute('x', String(xs[0]));
            rect.setAttribute('y', String(ys[0]));
            rect.setAttribute('width', String(xs[1] - xs[0]));
            rect.setAttribute('height', String(ys[1] - ys[0]));
        }

        function sortByNumber(a: number, b: number) {
            return a - b
        }

        function updateSelection(selection: SVGRectElement) {

            let x = parseInt(selection.getAttribute('x')),
                y = parseInt(selection.getAttribute('y')),
                w = parseInt(selection.getAttribute('width')),
                h = parseInt(selection.getAttribute('height'));

            function overlap(obj: TBaseShape): boolean {
                // Shape und multiSelect sind nebeneinander  
                if (x >= obj.x + obj.w || obj.x >= x + w) {
                    return false;
                }

                // Shape und multiSelect sind übereinander
                if (y >= obj.y + obj.h || obj.y >= y + h) {
                    return false;
                }

                return true;
            }

            editor.objectList.forEach(obj => {
                if (obj instanceof TBaseShape)
                    if (overlap(obj) && !obj.isFixedElement()) {
                        obj.selected = true;
                    }
            });

            editor.notifySelectedElementsChanged();
        }
    }

    //#endregion

    //#region Component Properties
    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];

        properties.push([this.id, 'ActionListPointer', this.actionList.listPointer]);
        properties.push([this.id, 'ActionListLength', this.actionList.actions.length]);
        properties.push([this.id, 'Fullscreen', this.fullscreen]);
        return properties;
    }

    writeProperties(key: string, value: any): void {
        switch (key) {
            case 'ServerActions':
                let src = csJSONParse(value);

                src.forEach(element => {
                    let action = getActionByTypeID(element.actionType, this.objectById(element.refElementID), this);
                    action.fromJSON(element);
                    this.actionList.registerServerAction(action);
                    this.notifyComponentChanged();
                    action.performAction();
                });
                this.invalidate();
                break;
            case 'UseGrid':
                this.useGrid = boolFromStr(value);
                // this.invalidateGrid();
                break;
            case 'Fullscreen':
                this.fullscreen = boolFromStr(value);
                this.toggleFullscreen();
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
            case 'ProcessMode':
                this.processMode = ProcessMode[ProcessMode[this.editorElement.dataset.processMode as unknown as ProcessMode ?? ProcessMode.BPMN]];
                // this.invalidateGrid(); // danach müssen wir ggf das Grid neu zeichen
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.Redo':
                this.beginUpdate();
                try {
                    let redoAction = this.actionList.getRedoAction();
                    redoAction.performAction();
                    this.loadServerAction(this.actionList.getCurrentStateActionGuid());
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.Undo':
                this.beginUpdate();
                try {
                    let undoAction = this.actionList.getUndoAction();
                    undoAction.performAction();
                    this.loadServerAction(this.actionList.getCurrentStateActionGuid());
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'ActionList.Clear':
                this.actionList.reset();
                break;
            case 'Action.SetBackgroundImage':
                this.beginUpdate();
                try {
                    let jsonParsed = csJSONParse(params);
                    this.setBackgroundImage({ width: jsonParsed.width, height: jsonParsed.height });
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.DeleteBackgroundImage':
                this.beginUpdate();
                try {
                    this.deleteBackgroundImage();
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SelectAllShapes':
                this.beginUpdate();
                try {
                    this.objectList.forEach(obj => {
                        if (obj instanceof TBaseShape) {
                            obj.selected = true;
                        }
                    });
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SelectShapes':
                this.beginUpdate();
                try {
                    this.objectList.forEach(obj => {
                        if (obj instanceof TBaseShape) {
                            if (params.includes(';' + obj.id + ';')) {
                                obj.selected = true;
                            } else {
                                obj.selected = false;
                            }
                        }
                    });
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.ClearSelection':
                this.beginUpdate();
                try {
                    this.clearSelectedElements();
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.RecalcEdgeExtraPoints':
                this.beginUpdate();
                try {
                    let actionGroup = new TCustomActionGroup(null, this);

                    this.objectList.forEach(obj => {
                        if (obj instanceof TEdgeBase) {
                            if (params.includes(';' + obj.id + ';')) {

                                // recalc ExtraPoints
                                let edgeAction = new TEdgeExtraPointsAction(obj, this);
                                let newPoints = obj.recalcExtraPoints();

                                edgeAction.setValue(newPoints);
                                actionGroup.actions.push(edgeAction);
                            }
                        }
                    });

                    actionGroup.performAction();
                    this.actionList.addClientAction(actionGroup);
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.UnlockElementEdit':
                this.elementEditLocked = false;
                break;
            // Ausrichten
            case 'Action.AutoLayout':
                this.beginUpdate();
                try {
                    this.doAutoLayout();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.AlignHLeft':
                this.beginUpdate();
                try {
                    this.doAlignHLeft();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.AlignHRight':
                this.beginUpdate();
                try {
                    this.doAlignHRight();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.AlignHCenter':
                this.beginUpdate();
                try {
                    this.doAlignHCenter();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.AlignVTop':
                this.beginUpdate();
                try {
                    this.doAlignVTop();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.AlignVBottom':
                this.beginUpdate();
                try {
                    this.doAlignVBottom();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.AlignVMiddle':
                this.beginUpdate();
                try {
                    this.doAlignVMiddle();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.LeftTetris':
                this.beginUpdate();
                try {
                    this.doLeftTetris();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.LeftGroup':
                this.beginUpdate();
                try {
                    this.doLeftGroup();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.TopGroup':
                this.beginUpdate();
                try {
                    this.doTopGroup();
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.ToggleFullscreen':
                this.beginUpdate();
                try {
                    this.toggleFullscreen();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.CheckEditorSize':
                this.beginUpdate();
                try {
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SetZoom':
                this.beginUpdate();
                try {
                    this.zoomEditor(parseInt(params));
                }
                finally {
                    this.endUpdate();
                }
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
    //#endregion

    //#region Action Handling
    private handleActionListChanged(action: TCustomAction<any>): void {
        this.notifyComponentChanged();

        if (this.isInitialized) {
            this.forceServerAction(action);
        }

        // Wenn es Aktionen gab müssen wir auch immer das UI updaten
        this.invalidate();
    }

    private forceServerAction(action: TCustomAction<any>): void {
        sendComponentRequestGetJsonNoError(this.id, { Action: 'ServerAction', ActionData: action }, true);
        WebCompEventHandlerAsync('OnClientActionAdded', this.id, function () { });
    }

    private loadServerAction(action: TCustomAction<any>): void;
    private loadServerAction(guid: string): void;
    private loadServerAction(param: string | TCustomAction<any>): void {
        if (typeof param === 'string') {
            this.notifyComponentChanged();
            sendComponentRequestGetJsonNoError(this.id, { Action: 'LoadAction', ActionGuid: param }, true);
        }
        else {
            return this.loadServerAction(param.actionGuid);
        }
    }
    //#endregion

    //#region Data API
    registerObj(obj: TBaseGraphObject): void {
        if (this.objectList.indexOf(obj) < 0) {
            this.objectList.push(obj);
        }
    }

    unregisterObj(obj: TBaseGraphObject): void {
        let i = this.objectList.indexOf(obj);
        if (i < 0) {
            return;
        }

        this.objectList.splice(i, 1);
    }

    objectById(id: string): TBaseGraphObject {
        return this.objectList.find(obj => obj.id === id);
    }

    getSelectedEdges(): Array<TEdgeBase> {
        return this.objectList.filter(obj => obj.selected).filter(obj => obj instanceof TEdgeBase) as Array<TEdgeBase>;
    }

    getSelectedEdgeIDs(): string {
        return `;${this.getSelectedEdges()
            // IDs sammeln
            .map(edge => edge.id)
            // Zu String joinen
            .join(';')
            };`;
    }

    getSelectedShapes(): Array<TBaseShape> {
        // Selektierte Filtern
        return this.objectList.filter(obj => obj.selected).filter(obj => obj instanceof TBaseShape) as Array<TBaseShape>;
    }

    getSelectedShapeIDs(): string {
        return `;${this.getSelectedShapes()
            // IDs sammeln
            .map(shape => shape.id)
            // Zu String joinen
            .join(';')
            };`;
    }

    clearSelectedElements(): void {
        this.objectList.forEach(obj => {
            obj.selected = false;
        });

        this.invalidate();
    }

    isSingleShapeSelected(): boolean {
        return this.getSelectedShapes().length === 1 && this.getSelectedEdges().length === 0;
    }

    isSingleEdgeSelected(): boolean {
        return this.getSelectedShapes().length === 0 && this.getSelectedEdges().length === 1;
    }

    notifySelectedElementsChanged(): void {
        WebCompEventHandlerAsync('OnSelectedShapesChanged', this.id, null, this.getSelectedShapeIDs());
        WebCompEventHandlerAsync('OnSelectedEdgesChanged', this.id, null, this.getSelectedEdgeIDs());
        this.invalidate();
    }
    //#endregion

    //#region Grid Utils
    /**
     * Returns a boolean if any kind of grid is being used.
     * @returns true, if any kind of grid is being used.
     */
    protected hasGrid(): boolean {
        return this.useGrid;
    }

    /**
     * Ensures that all shapes are aligned to the grids. This effects the standard grid and the cluster grids.
     * @returns If the process has been modified, the actionlist of modification is returned. If the return value is null, the process has not been modified.
     */
    ensureGrid(): TCustomActionGroup | null {
        if (!this.hasGrid()) {
            return;
        }

        // grid refresh
        this.checkEditorSize();

        let nodeActionGroup = new TCustomActionGroup(null, this);
        let edgeActionGroup = new TCustomActionGroup(null, this);

        let modifiedShapes: Array<TBaseShape> = [];

        let shapes = this.objectList.filter(obj => obj instanceof TBaseShape) as Array<TBaseShape>;

        shapes.forEach(shape => {
            let currentPosition = shape.boundingRect;
            let targetPosition = this.snapShapeToUsedGrid(shape);
            if (!currentPosition.equals(targetPosition)) {
                let moveAction = new TNodePositionAction(shape, this);
                let resizeAction = new TNodeSizeAction(shape, this);
                moveAction.setValue(targetPosition.left - currentPosition.left, targetPosition.top - currentPosition.top);
                resizeAction.setValue(targetPosition.right - targetPosition.left, targetPosition.bottom - targetPosition.top);

                nodeActionGroup.actions.push(moveAction);
                nodeActionGroup.actions.push(resizeAction);

                modifiedShapes.push(shape);
            }
        });

        if (modifiedShapes.length > 0) {
            nodeActionGroup.performAction();

            modifiedShapes.forEach(shape => {
                // UPS - wir holen uns hier ggf. EdgeActions doppelt
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            edgeActionGroup.performAction();

            let result = new TCustomActionGroup(null, this);
            result.actions.push(nodeActionGroup);
            result.actions.push(edgeActionGroup);

            return result;
        }

        return null;
    }

    /**
     * @returns Either the gridSize or, if virtual grid is used, the virtualGridSize for clusters.
     */
    abstract getUsedGrid(): SizeDefinition;

    /**
     * @returns Either the grid padding of individual cells or, if virtual grid is used, the padding between clusters.
     */
    abstract getGridPadding(): SizeDefinition;

    /**
     * Snaps a given value on the x-axis to the underlaying grid.
     * @param x Value to snap to grid.
     * @remarks This matches the grid used in ConSense Suite.
     */
    snapToRealGridX(x: number): number {
        if (this.hasGrid()) {
            return Math.round(x / this.gridSize.width) * this.gridSize.width;
        }

        return x;
    }

    /**
     * Snaps a given value on the y-axis to the underlaying grid.
     * @param y Value to snap to grid.
     * @remarks This matches the grid used in ConSense Suite.
     */
    snapToRealGridY(y: number): number {
        if (this.hasGrid()) {
            return Math.round(y / this.gridSize.height) * this.gridSize.height;
        }

        return y;
    }

    /**
     * Snaps a given value on the x-axis to the used grid
     * @param x Value to snap to grid
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    abstract snapToUsedGridX(x: number): number;
    /**
     * Snaps a given value on the y-axis to the used grid
     * @param x Value to snap to grid
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    abstract snapToUsedGridY(y: number): number;

    /**
     * Snaps a shape to the used grid. Optionally can handle a new target position.
     * @param shape Shape to snap to the grid.
     * @param newPos Target position. If not set, the current shape position will be used instead.
     * @remarks If virtual grid is enabled, this will snap to a grid cluster.
     */
    abstract snapShapeToUsedGrid(shape: TBaseShape, newPos?: Point): Bounds;

    //#endregion

    //#region Rendering
    /**
     * Renders the Process Editor
     * @param timestamp 
     */
    override doRender(timestamp: DOMHighResTimeStamp): void {
        // Durch den Core wird sonst ggf. versucht hier was zu zeichnen, ohne das der Editor sichtbar initialisiert wurde, was natürlich nicht geht....
        if (!this.isInitialized) {
            return;
        }

        // TODO: All Den ArrowMode sollten wir hier möglichst auch integriern noch

        // editorSize
        // Höhe und Breite steuern den Zoom über das Verhältnis zu Höhe und Breite der viewBox
        // Falls die Editorkomponente an sich aber mehr Platz darstellen kann im Browserfenster, so nehmen wir den größeren Wert
        // Damit sich das Grid auch bei Zoom korrekt vergrößert müssen wir durch diesen dividieren
        let realWidth = Math.max(this.width, this.editorElement.clientWidth / this.zoomFactor);
        let realHeight = Math.max(this.height, this.editorElement.clientHeight / this.zoomFactor);

        this.editorSVG.setAttribute('width', String(realWidth * this.zoomFactor));
        this.editorSVG.setAttribute('height', String(realHeight * this.zoomFactor));
        this.editorSVG.setAttribute('viewBox', '0 0 ' + String(realWidth) + ' ' + String(realHeight));

        // background
        this.background.setAttribute('width', '100%');
        this.background.setAttribute('height', '100%');
        this.background.setAttribute('opacity', '0');

        // image
        this.backgroundImage.classList.toggle('d-none', !this.hasImage());
        this.backgroundImage.setAttribute('pointer-events', 'none');
        this.backgroundImage.setAttribute('width', String(this.imageSize.width));
        this.backgroundImage.setAttribute('height', String(this.imageSize.height));


        // #time zur URL hinzugefügt, da sonst der RequestHandler nicht aufgerufen wird
        // Aber nur wenn sich der Wert auch ändert, sonst spammen wir den Server zu.
        if (assigned(this.imgUrl) && this.backgroundImage.getAttribute('href') !== this.imgUrl) {
            this.backgroundImage.setAttribute('href', this.imgUrl);
        }

        // Grid
        this.renderGrids();

        // zOrder - todo: prüfen ob notwendig
        this.updateZOrder();

        // selected
        this.objectList.forEach(obj => {
            obj.invalidate();

            if (obj instanceof TBaseShape) {
                obj.svgElement.classList.toggle('shapeSelected', obj.selected);
            }
            else if (obj instanceof TEdgeBase) {
                obj.svgElement.classList.toggle('edgeSelected', obj.selected);
            }
            obj.repaintResizeHandlers();
        });
    }

    renderGrids() {
        // do nothing
    }

    getLocalCoordinate(value: MouseEvent | Point): Point {
        let domPoint = this.editorSVG.createSVGPoint();
        domPoint.x = value.x
        domPoint.y = value.y
        let transformedDomPoint = domPoint.matrixTransform(this.editorSVG.getScreenCTM().inverse());

        return new Point(transformedDomPoint.x, transformedDomPoint.y);
    }

    hasImage(): boolean {
        return this.imageSize.width > 0 && this.imageSize.height > 0;
    }

    setBackgroundImage(size: SizeDefinition) {
        this.imageSize = size;

        if (this.hasImage()) {
            this.imgUrl = getBaseUrl(this.id) + '&data=' + JSON.stringify({ Action: 'GetBackgroundImage' }) + '&t=' + new Date().getTime();
            this.notifyComponentChanged();
        }
    }


    deleteBackgroundImage() {
        this.imageSize.width = 0;
        this.imageSize.height = 0;

        this.notifyComponentChanged();
    }

    zoomEditor(value: number): void {
        // Höhe und Breite steuern den Zoom über das Verhältnis
        // zu Höhe und Breite der viewBox
        this.zoomFactor = value / 100;

        // im Anschluss die größe Aktualisieren
        this.checkEditorSize();
    }

    toggleFullscreen(): void {
        document.getElementById(this.id).classList.toggle('panel-fullscreen', this.fullscreen);
        // toolbar.classList.toggle('d-none', !value);
        // wir markieren auch am page-inner, dass wir nun fullscreen sind, da sonst manche Komponenten, die ausserhalb des ProzessEditorDivs liegen nicht mitbekommen.
        document.getElementsByClassName('page-inner')[0].classList.toggle('has-panel-fullscreen', this.fullscreen);

        this.checkEditorSize();
    }

    checkEditorSize(): void {
        let editorPaddingX = 4 * this.gridSize.width;
        let editorPaddingY = 4 * this.gridSize.height;
        let maxWidth = 0;
        let maxHeight = 0;

        this.objectList.forEach(obj => {
            maxWidth = Math.max(obj.boundingRect.right, maxWidth);
            maxHeight = Math.max(obj.boundingRect.bottom, maxHeight);
        });

        if (this.hasImage()) {
            let backgroundImageWidth = parseInt(this.backgroundImage.getAttribute('width'));
            let backgroundImageHeight = parseInt(this.backgroundImage.getAttribute('height'));

            if (backgroundImageWidth > 0 && backgroundImageHeight > 0) {
                maxWidth = Math.max(backgroundImageWidth, maxWidth);
                maxHeight = Math.max(backgroundImageHeight, maxHeight);
            }
        }

        this.width = maxWidth + editorPaddingX;
        this.height = maxHeight + editorPaddingY;

        // wir müssen die UI auf die neue Size update
        this.invalidate();
    }

    updateZOrder() {
        function updateIcon(shape: TBaseShape, icon: SVGGraphicsElement) {
            if (assigned(icon)) {
                shape.editor.editorSVG.insertBefore(icon, shape.captionElement.svgElement);
            }
        }

        this.objectList.forEach(obj => {
            if (obj instanceof TBaseShape) {
                // z-order
                if (obj.getOwnerPos() === 0) {
                    if (obj.svgElement != this.grid.nextSibling) {
                        // das element nach dem grid einfügen
                        this.editorSVG.insertBefore(obj.svgElement, this.grid.nextSibling);
                        // TODO: DCy bekommen wir die Swimlane Sonderlogik hier irgendwie raus in den Standard?
                        // wenn wir eine swimlane haben, kommt das grid vor der caption
                        // im ProcessMode.Normal haben wir als TShapeLane eine Zuständigkeit
                        if (obj instanceof TShapeLane && !(this.processMode === ProcessMode.Normal)) {
                            this.editorSVG.insertBefore(obj.grid, obj.svgElement.nextSibling);
                            this.editorSVG.insertBefore(obj.captionElement.svgElement, obj.grid.nextSibling);
                        }
                        else {
                            this.editorSVG.insertBefore(obj.captionElement.svgElement, obj.svgElement.nextSibling);
                        }
                    }
                }
                else {
                    let nextSibling = this.objectList.find(x => (x instanceof TBaseShape) && (x as TBaseShape).getOwnerPos() === obj.getOwnerPos() - 1).captionElement.svgElement.nextSibling;

                    if (obj.svgElement != nextSibling) {
                        this.editorSVG.insertBefore(obj.svgElement, nextSibling);
                        // TODO: DCy bekommen wir die Swimlane Sonderlogik hier irgendwie raus in den Standard?
                        // wenn wir eine swimlane haben, kommt das grid vor der caption
                        // im ProcessMode.Normal haben wir als TShapeLane eine Zuständigkeit
                        if (obj instanceof TShapeLane && !(this.processMode === ProcessMode.Normal)) {
                            this.editorSVG.insertBefore(obj.grid, obj.svgElement.nextSibling);
                            this.editorSVG.insertBefore(obj.captionElement.svgElement, obj.grid.nextSibling);
                        }
                        else if (obj instanceof TShapeKeyIndicator) {
                            this.editorSVG.insertBefore(obj.indicator, obj.svgElement.nextSibling);
                            this.editorSVG.insertBefore(obj.captionElement.svgElement, obj.indicator.nextSibling);
                        }
                        else {
                            this.editorSVG.insertBefore(obj.captionElement.svgElement, obj.svgElement.nextSibling);
                        }
                    }
                }
                updateIcon(obj, obj.iconQS);
                updateIcon(obj, obj.iconCP);
                updateIcon(obj, obj.iconDSGVO);
                updateIcon(obj, obj.iconSpezifikation);
                updateIcon(obj, obj.iconRisk);
                updateIcon(obj, obj.iconSAP);
                updateIcon(obj, obj.iconSOXControlGap);
                updateIcon(obj, obj.iconSOXControl);
                updateIcon(obj, obj.iconSOXRisk);
                if (obj instanceof TBaseShapeBPMN) {
                    updateIcon(obj, obj.iconBPMN);
                }
                // falls ein doppelter rand vorhanden ist
                if (obj instanceof TShapeBPMNActivity || obj instanceof TShapeBPMNEvent) {
                    if (assigned(obj.doubleBorder)) {
                        this.editorSVG.insertBefore(obj.doubleBorder, obj.captionElement.svgElement.nextSibling);
                    }
                }
            }
        });
    }
    //#endregion

    //#region Arrow Mode
    private static getDefaultArrowModeConfiguration(): ArrowModeConfiguration {
        return {
            isReverseMode: false,
            replaceExistingEdges: false,
            replacingEdge: null,
            afterArrowModeCallback: null,
        }
    }

    /**
     * Active arrow mode in editor.
     * @param shape Source shape for the arrow.
     * @param anchorHandle Source handle of the shape.
     * @param isReverseMode Optionally, pass true if the shape is the destination shape instead.
     * @param replaceExistingEdges Optionally, pass true if existing edges shall be replaces. Default behaviour will prevent duplicated edges instead.
     * @param callback Optionally, pass a callback to be called, when arrow mode is stopped.
     */
    startArrowMode(shape: TBaseShape, anchorHandle: AnchorHandle, configuration?: ArrowModeConfiguration, currentMousePosition?: Point) {
        this.isArrowMode = true;

        let defaultConfig = TwsProcessEditorCustom.getDefaultArrowModeConfiguration();
        this.arrowModeConfig = {
            isReverseMode: configuration?.isReverseMode ?? defaultConfig.isReverseMode,
            replaceExistingEdges: configuration?.replaceExistingEdges ?? defaultConfig.replaceExistingEdges,
            replacingEdge: configuration?.replacingEdge ?? defaultConfig.replacingEdge,
            afterArrowModeCallback: configuration?.afterArrowModeCallback ?? defaultConfig.afterArrowModeCallback
        };

        this.arrowSourceShape = shape;
        this.arrowSourceHandle = anchorHandle;

        this.draftEdge = document.createElementNS(SVGNS, 'path');
        this.draftEdge.setAttribute('class', 'newEdge');
        this.draftEdge.setAttribute('stroke-width', '3');
        this.draftEdge.setAttribute('stroke', '#808080');
        this.draftEdge.setAttribute('stroke-dasharray', '2, 4');
        this.draftEdge.setAttribute('pointer-events', 'none');
        this.draftEdge.classList.add('draftEdge');
        this.editorSVG.appendChild(this.draftEdge);

        this.updateDraftEdge(shape.getAnchorPoint(anchorHandle), currentMousePosition ?? shape.getAnchorPoint(anchorHandle));

        this.editorSVG.addEventListener('mousemove', this.arrowModeMoveEventHandler, false);
        this.background.addEventListener('mouseup', this.arrowModeBackgroundClickEventHandler, false);
    }

    setTargetShape(shape: TBaseShape, anchorHandle: AnchorHandle) {
        if (!this.isArrowMode) {
            return;
        }

        // Pfeil auf sich selber ist verboten
        // Pfeil auf Shapes ohne angezeigte Anchor ist auch verboten
        if (shape === this.arrowSourceShape || !shape.hasAnchors) {
            this.stopArrowMode(true);
            return;
        }

        let actionGroup = new TCustomActionGroup(null, this);
        let newEdgeCaption = ''; // standardmäßig haben die keinen Text

        let realSourceShape = this.arrowModeConfig.isReverseMode ? shape : this.arrowSourceShape;
        let realTargetShape = this.arrowModeConfig.isReverseMode ? this.arrowSourceShape : shape;

        let realSourceHandle = this.arrowModeConfig.isReverseMode ? anchorHandle : this.arrowSourceHandle;
        let realTargetHandle = this.arrowModeConfig.isReverseMode ? this.arrowSourceHandle : anchorHandle;


        // Gibt es die Verbindung schon?
        if (assigned(this.objectById(realSourceShape.id + '#' + realTargetShape.id))) {
            // Wollen wir eine bestehe Edge ersetzen (z. B. an andere Anker platzieren)
            if (this.arrowModeConfig.replaceExistingEdges) {
                let oldEdge = this.objectById(realSourceShape.id + '#' + realTargetShape.id) as TEdgeBase;
                oldEdge.selected = false; // die alte Kante ist nicht mehr sichtbar, also auch deselektieren

                newEdgeCaption = oldEdge.getCaption(); // Wir wollen die Caption beibehalten
                let actDeleteOld = new TEdgeDeleteAction(oldEdge, this);
                actDeleteOld.setValue(oldEdge.fromNode, oldEdge.fromAnchor, oldEdge.toNode, oldEdge.toAnchor);
                actionGroup.actions.push(actDeleteOld);
            }
            // Ansonsten: Abbruch Abbruch
            else {
                this.stopArrowMode(true);
                return;
            }
        }
        // Wir erzeugen durch verschieben eine neue Verbindung
        else if (assigned(this.arrowModeConfig.replacingEdge)) {
            let oldEdge = this.arrowModeConfig.replacingEdge;
            oldEdge.selected = false; // die alte Kante ist nicht mehr sichtbar, also auch deselektieren

            newEdgeCaption = oldEdge.getCaption(); // Wir wollen die Caption beibehalten
            let actDeleteOld = new TEdgeDeleteAction(oldEdge, this);
            actDeleteOld.setValue(oldEdge.fromNode, oldEdge.fromAnchor, oldEdge.toNode, oldEdge.toAnchor);
            actionGroup.actions.push(actDeleteOld);
        }

        let actCreate = new TEdgeCreateAction(null, this);
        actCreate.setValue(realSourceShape.id, realSourceHandle, realTargetShape.id, realTargetHandle);

        let actColor = new TEdgeBackgroundColorAction(actCreate.refElement, this);
        actColor.setValue(this.edgeColor);

        let newExtraPts = recalcExtraPoints(this, realSourceShape, realSourceHandle, realTargetShape, realTargetHandle);
        let actExtraPt = new TEdgeExtraPointsAction(actCreate.refElement, this);
        actExtraPt.setValue(newExtraPts);

        let actCaption = new TEdgeCaptionAction(actCreate.refElement, this);
        actCaption.setValue(newEdgeCaption);

        actionGroup.actions.push(actCreate);
        actionGroup.actions.push(actColor);
        actionGroup.actions.push(actExtraPt);
        actionGroup.actions.push(actCaption);

        if (actionGroup.actions.length > 0) {
            this.actionList.addClientAction(actionGroup);
            this.actionList.actions[this.actionList.listPointer].performAction();
        }

        // Wir selektieren die neue Linie direkt, falls der User damit weiter Sachen machen will
        this.objectById(realSourceShape.id + '#' + realTargetShape.id).selected = true;

        this.stopArrowMode(false);
    }

    updateDraftEdge(start: Point, end: Point) {
        if (!assigned(this.draftEdge)) {
            return;
        }

        this.draftEdge.setAttribute('d', `M ${start.x} ${start.y} ${end.x} ${end.y}`);
    }

    handleArrowModeMouseMove(event: MouseEvent) {
        let endPoint = this.getLocalCoordinate(event);

        this.updateDraftEdge(this.arrowSourceShape.getAnchorPoint(this.arrowSourceHandle), endPoint)
    }

    handleArrowModeClick(event: MouseEvent) {
        this.stopArrowMode(true);
    }

    stopArrowMode(isAborting: boolean) {
        // wenn nicht im Arrow Mode sind brauchen wir nichts machen
        if (!this.isArrowMode) {
            return;
        }

        this.isArrowMode = false;

        this.editorSVG.removeEventListener('mousemove', this.arrowModeMoveEventHandler, false);
        this.background.removeEventListener('mouseup', this.arrowModeBackgroundClickEventHandler, false);

        this.arrowSourceHandle = null;
        this.arrowSourceShape = null;

        this.editorSVG.removeChild(this.draftEdge);
        this.draftEdge = null;

        if (assigned(this.arrowModeConfig.afterArrowModeCallback)) {
            this.arrowModeConfig.afterArrowModeCallback(!isAborting);
        }
    }
    //#endregion

    //#region ElementEdit Mode
    startElementEditMode() {
        this.isElementEditMode = true;
    }

    stopElementEditMode() {
        if (!this.isElementEditMode) {
            return;
        }

        this.isElementEditMode = false;
    }
    //#endregion

    //#region Auto Alignments, Grouping and more...
    doAutoLayout() {
        this.beginUpdate();
        try {
            let shapeAsBounds = shape => new Bounds(shape.x, shape.y, shape.x + shape.w, shape.y + shape.h);;

            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            this.getSelectedShapes().forEach(shape => {
                let oldBounds = shapeAsBounds(shape);

                let newX = this.snapToUsedGridX(shape.x);
                let newY = this.snapToUsedGridY(shape.y);

                let newW = shape.w;
                let newH = shape.h;

                // bei Clustergrid setzen wir die Größe auf das Cluster
                if (shape.isResizePossible) {
                    // TODO: DBe Cluster Resize
                    // if (this.processMode === ProcessMode.VirtualGrid) {
                    //     newW = this.virtualGridSize.x * this.gridSize.x;
                    //     newH = this.virtualGridSize.y * this.gridSize.y;
                    // }
                    // ansonsten die Größe auch snappen lassen
                    // else {
                    newW = this.snapToUsedGridX(newX + shape.w) - newX;
                    newH = this.snapToUsedGridY(newY + shape.h) - newY
                    // }
                }

                let targetBounds = new Bounds(newX, newY, newX + newW, newY + newH);
                if (!targetBounds.equals(oldBounds)) {
                    let moveAction = new TNodePositionAction(shape, this);
                    moveAction.setValue(newX - shape.x, newY - shape.y);

                    let resizeAction = new TNodeSizeAction(shape, this);
                    resizeAction.setValue(newW, newH);

                    nodeActionGroup.actions.push(moveAction);
                    nodeActionGroup.actions.push(resizeAction);

                    changedShapes.push(shape);
                }
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doAlignHLeft(): void {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let targetLeft = Number.MAX_VALUE;
            selectedShapes.forEach(shape => {
                targetLeft = Math.min(targetLeft, shape.x);
            });

            selectedShapes.forEach(shape => {
                if (shape.x === targetLeft) {
                    return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                moveAction.setValue(targetLeft - shape.x, 0);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        }
        finally {
            this.endUpdate();
        }
    }

    doAlignHRight(): void {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let targetRight = -Number.MAX_VALUE;
            selectedShapes.forEach(shape => {
                targetRight = Math.max(targetRight, shape.x + shape.w);
            });

            selectedShapes.forEach(shape => {
                if (shape.x + shape.w === targetRight) {
                    return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                moveAction.setValue(targetRight - shape.w - shape.x, 0);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doAlignHCenter(): void {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let targetLeft = Number.MAX_VALUE;
            let targetRight = -Number.MAX_VALUE;
            selectedShapes.forEach(shape => {
                targetLeft = Math.min(targetLeft, shape.x);
                targetRight = Math.max(targetRight, shape.x + shape.w);
            });

            let center = Math.round((targetRight + targetLeft) / 2);

            selectedShapes.forEach(shape => {
                let shapeLeft = center - Math.round(shape.w / 2);
                if (shape.x === shapeLeft) {
                    return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                moveAction.setValue(shapeLeft - shape.x, 0);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }
            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doAlignVTop(): void {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let targetTop = Number.MAX_VALUE;
            selectedShapes.forEach(shape => {
                targetTop = Math.min(targetTop, shape.y);
            });

            selectedShapes.forEach(shape => {
                if (shape.y === targetTop) {
                    return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                moveAction.setValue(0, targetTop - shape.y);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doAlignVBottom(): void {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let targetBottom = -Number.MAX_VALUE;
            selectedShapes.forEach(shape => {
                targetBottom = Math.max(targetBottom, shape.y + shape.h);
            });

            selectedShapes.forEach(shape => {
                if (shape.y + shape.h === targetBottom) {
                    return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                moveAction.setValue(0, targetBottom - shape.h - shape.y);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doAlignVMiddle(): void {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let targetTop = Number.MAX_VALUE;
            let targetBottom = -Number.MAX_VALUE;
            selectedShapes.forEach(shape => {
                targetTop = Math.min(targetTop, shape.y);
                targetBottom = Math.max(targetBottom, shape.y + shape.h);
            });

            let center = Math.round((targetBottom + targetTop) / 2);

            selectedShapes.forEach(shape => {
                let shapeTop = center - Math.round(shape.h / 2);
                if (shape.y === shapeTop) {
                    return;
                }

                let moveAction = new TNodePositionAction(shape, this);
                moveAction.setValue(0, shapeTop - shape.y);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape)
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doLeftTetris(): void {
        this.beginUpdate();
        try {
            let wasChanged = false;
            let shapes = this.objectList.filter(obj => obj instanceof TBaseShape) as Array<TBaseShape>;
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);
            let actionGroup = new TCustomActionGroup(null, this);

            let nearCollision = (shape1: TBaseShape, shape2: TBaseShape): boolean => {
                if ((shape1.y >= shape2.y + shape2.h) || (shape1.y + shape1.h <= shape2.y)) {
                    return false;
                }

                if (shape1.x > shape2.x + shape2.w + this.sizeHorizontalSpace * this.gridSize.width || shape1.x < shape2.x) {
                    return false;
                }

                return true;
            }

            let hasCollision = (shape: TBaseShape): boolean => {
                for (let i = 0; i < shapes.length; i++) {
                    if (shapes[i] === shape) {
                        continue;
                    }

                    if (nearCollision(shape, shapes[i])) {
                        return true;
                    }
                }

                return false;
            };

            do {
                wasChanged = false;

                shapes.forEach(shape => {
                    // sind wir links?
                    if (shape.x <= 1 * this.gridSize.width) {
                        return;
                    }

                    // kollision?
                    if (!hasCollision(shape)) {
                        let moveAction = new TNodePositionAction(shape, this);
                        moveAction.setValue(-this.gridSize.width, 0);
                        moveAction.performAction();
                        wasChanged = true;

                        nodeActionGroup.actions.push(moveAction);
                    }
                });
            } while (wasChanged);

            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                shapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            shapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doLeftGroup(): void {
        this.beginUpdate();
        try {
            let shapes = this.objectList.filter(obj => obj instanceof TBaseShape) as Array<TBaseShape>;

            let getMiddleX = (shape: TBaseShape): number => shape.x + Math.floor(shape.w / 2);

            let getMostLeft = (arr: Array<TBaseShape>): number => {
                let result = Number.MAX_VALUE;

                arr.forEach(shape => {
                    result = Math.min(result, shape.x);
                });

                return result;
            }

            let getMostRight = (arr: Array<TBaseShape>): number => {
                let result = -Number.MAX_VALUE;

                arr.forEach(shape => {
                    result = Math.max(result, shape.x + shape.w);
                });

                return result;
            }

            let nodeActionGroup = new TCustomActionGroup(null, this);
            let edgeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let actionGroup = new TCustomActionGroup(null, this);

            // nach links rücken
            for (let i = 0; i < shapes.length; i++) {
                let vNode1 = shapes[i];

                let other: Array<TBaseShape> = [];
                let righter: Array<TBaseShape> = [];

                righter.push(vNode1);

                let sameRibbon = (shape1: TBaseShape, shape2: TBaseShape, ribbonLeft: number, ribbonRight: number): boolean => {
                    if (
                        // | NODE1 |
                        // | NODE2 |
                        (ribbonLeft >= shape2.x && ribbonLeft < shape2.x + shape2.w)
                        // | NODE1 |
                        // | NODE2 |
                        || (ribbonLeft <= shape2.x && ribbonRight > shape2.x)) {
                        return true;
                    }

                    return false;
                };

                let checkRibbon = (shape: TBaseShape): void => {
                    // Die Grenzen des Bandes sind zunächst mal die der vorgegebenen Node
                    let ribbonLeft = shape.x;
                    let ribbonRight = shape.x + shape.w;

                    let done = false
                    let rememberedNodes: Array<TBaseShape> = [];
                    // Den Algrorithmus machen wir jetzt solange bis wir alle haben, die in diesem Cluster liegen.
                    do {
                        done = true;

                        for (let k = 0; k < shapes.length; k++) {
                            if (shapes[k] === shape) {
                                continue;
                            }

                            if (rememberedNodes.includes(shapes[k])) {
                                continue;
                            }

                            if (sameRibbon(shape, shapes[k], ribbonLeft, ribbonRight)) {
                                // merken, dass wir damit gearbeitet haben, sonst läuft der Algorithmus endlos
                                rememberedNodes.push(shapes[k]);

                                if (ribbonLeft > shapes[k].x) {
                                    ribbonLeft = shapes[k].x;
                                }

                                if (ribbonRight < shapes[k].x + shapes[k].w) {
                                    ribbonRight = shapes[k].x + shapes[k].w;
                                }
                                done = false;
                                break;
                            }
                        }
                    } while (!done);

                    // Jetzt noch alle Selektierten aus der anderen in die rechte Gruppe verschieben,
                    // dann haben wirs geschafft!
                    for (let k = other.length - 1; k >= 0; k--) {
                        // Die schleife läuft rückwärts, so kann ich am Ende löschen,
                        // ohne mit der reihenfolge durcheinanderzukommen, oder einen
                        // Fehler zu verursachen.
                        // Ist es selektiert?
                        // DBe: Das "ich" bin nicht ich sondern ist die Person, die den Algorithmus in Delphi geschrieben hat 🙃

                        if (rememberedNodes.includes(other[k])) {
                            righter.push(other[k]);
                            other.splice(k, 1);
                        }
                    }
                };

                // sortieren
                for (let j = 0; j < shapes.length; j++) {
                    if (i === j) {
                        continue;
                    }

                    let vNode2 = shapes[j];

                    if (getMiddleX(vNode2) >= getMiddleX(vNode1)) {
                        righter.push(vNode2);
                    }
                    else {
                        other.push(vNode2);
                    }
                }

                // Bänder und Überlappungen überprüfen.
                checkRibbon(vNode1);

                if (other.length > 0) {
                    let x = getMostLeft(righter) - getMostRight(other) - this.sizeHorizontalSpace * this.gridSize.width;

                    if (getMostLeft(righter) - x > 0) {
                        righter.forEach(shape => {
                            let moveAction = new TNodePositionAction(shape, this);
                            moveAction.setValue(-x, 0);

                            moveAction.performAction();
                            nodeActionGroup.actions.push(moveAction);
                        });
                    }
                }
                else {
                    let x = getMostLeft(righter) - this.sizeHorizontalSpace * this.gridSize.width;
                    righter.forEach(shape => {
                        let moveAction = new TNodePositionAction(shape, this);
                        moveAction.setValue(-x, 0);

                        moveAction.performAction();
                        nodeActionGroup.actions.push(moveAction);
                    });
                }
            }

            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                shapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            shapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    doTopGroup() {
        this.beginUpdate();
        try {
            let shapes = this.objectList.filter(obj => obj instanceof TBaseShape) as Array<TBaseShape>;

            let getMiddleY = (shape: TBaseShape): number => shape.y + Math.floor(shape.h / 2);

            let getMostTop = (arr: Array<TBaseShape>): number => {
                let result = Number.MAX_VALUE;

                arr.forEach(shape => {
                    result = Math.min(result, shape.y);
                });

                return result;
            }

            let getMostBottom = (arr: Array<TBaseShape>): number => {
                let result = -Number.MAX_VALUE;

                arr.forEach(shape => {
                    result = Math.max(result, shape.y + shape.h);
                });

                return result;
            }

            let nodeActionGroup = new TCustomActionGroup(null, this);
            let edgeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this);
            let actionGroup = new TCustomActionGroup(null, this);

            // nach links rücken
            for (let i = 0; i < shapes.length; i++) {
                let vNode1 = shapes[i];

                let other: Array<TBaseShape> = [];
                let lower: Array<TBaseShape> = [];

                lower.push(vNode1);

                let sameRibbon = (shape1: TBaseShape, shape2: TBaseShape, ribbonTop: number, ribbonBottom: number): boolean => {
                    if (
                        // | NODE1 |
                        // | NODE2 |
                        (ribbonTop >= shape2.y && ribbonTop < shape2.y + shape2.h)
                        // | NODE1 |
                        // | NODE2 |
                        || (ribbonTop <= shape2.y && ribbonBottom > shape2.y)) {
                        return true;
                    }

                    return false;
                };

                let checkRibbon = (shape: TBaseShape): void => {
                    // Die Grenzen des Bandes sind zunächst mal die der vorgegebenen Node
                    let ribbonTop = shape.y;
                    let ribbonBottom = shape.y + shape.h;

                    let done = false
                    let rememberedNodes: Array<TBaseShape> = [];
                    // Den Algrorithmus machen wir jetzt solange bis wir alle haben, die in diesem Cluster liegen.
                    do {
                        done = true;

                        for (let k = 0; k < shapes.length; k++) {
                            if (shapes[k] === shape) {
                                continue;
                            }

                            if (rememberedNodes.includes(shapes[k])) {
                                continue;
                            }

                            if (sameRibbon(shape, shapes[k], ribbonTop, ribbonBottom)) {
                                // merken, dass wir damit gearbeitet haben, sonst läuft der Algorithmus endlos
                                rememberedNodes.push(shapes[k]);

                                if (ribbonTop > shapes[k].y) {
                                    ribbonTop = shapes[k].y;
                                }

                                if (ribbonBottom < shapes[k].y + shapes[k].h) {
                                    ribbonBottom = shapes[k].y + shapes[k].h;
                                }
                                done = false;
                                break;
                            }
                        }
                    } while (!done);

                    // Jetzt noch alle Selektierten aus der anderen in die rechte Gruppe verschieben,
                    // dann haben wirs geschafft!
                    for (let k = other.length - 1; k >= 0; k--) {
                        // Die schleife läuft rückwärts, so kann ich am Ende löschen,
                        // ohne mit der reihenfolge durcheinanderzukommen, oder einen
                        // Fehler zu verursachen.
                        // Ist es selektiert?
                        // DBe: Das "ich" bin nicht ich sondern ist die Person, die den Algorithmus in Delphi geschrieben hat 🙃

                        if (rememberedNodes.includes(other[k])) {
                            lower.push(other[k]);
                            other.splice(k, 1);
                        }
                    }
                };

                // sortieren
                for (let j = 0; j < shapes.length; j++) {
                    if (i === j) {
                        continue;
                    }

                    let vNode2 = shapes[j];

                    if (getMiddleY(vNode2) >= getMiddleY(vNode1)) {
                        lower.push(vNode2);
                    }
                    else {
                        other.push(vNode2);
                    }
                }

                // Bänder und Überlappungen überprüfen.
                checkRibbon(vNode1);

                if (other.length > 0) {
                    let y = getMostTop(lower) - getMostBottom(other) - this.sizeVerticalSpace * this.gridSize.width;

                    if (getMostTop(lower) - y > 0) {
                        lower.forEach(shape => {
                            let moveAction = new TNodePositionAction(shape, this);
                            moveAction.setValue(0, -y);

                            moveAction.performAction();
                            nodeActionGroup.actions.push(moveAction);
                        });
                    }
                }
                else {
                    let y = getMostTop(lower) - this.sizeVerticalSpace * this.gridSize.width;
                    lower.forEach(shape => {
                        let moveAction = new TNodePositionAction(shape, this);
                        moveAction.setValue(0, -y);

                        moveAction.performAction();
                        nodeActionGroup.actions.push(moveAction);
                    });
                }
            }

            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                shapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            shapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    // UPS // Warum ist das keine Funktion auf der Edge selber?!
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);
        } finally {
            this.endUpdate();
        }
    }

    findNextShape(srcShape: TBaseShape): TBaseShape {
        if (this.objectList.filter(obj => obj instanceof TBaseShape && srcShape.getOwnerPos() + 1 === obj.getOwnerPos()).length === 1)
            return this.objectList.filter(obj => obj instanceof TBaseShape && srcShape.getOwnerPos() + 1 === obj.getOwnerPos())[0] as TBaseShape;
    }

    findPreviousShape(srcShape: TBaseShape): TBaseShape {
        if (this.objectList.filter(obj => obj instanceof TBaseShape && srcShape.getOwnerPos() - 1 === obj.getOwnerPos()).length === 1)
            return this.objectList.filter(obj => obj instanceof TBaseShape && srcShape.getOwnerPos() - 1 === obj.getOwnerPos())[0] as TBaseShape;
    }

    findClosestShape(srcPoint: Point, direction: Direction, maxRadius: number = Number.MAX_VALUE): TBaseShape {
        let result: TBaseShape;
        let bestDelta = Number.MAX_VALUE;
        let shapes = this.objectList.filter(obj => obj instanceof TBaseShape) as Array<TBaseShape>;

        shapes.forEach((obj, i) => {
            if (obj instanceof TBaseShape) {
                if (!srcPoint.equals(shapes[i].center()) && shapes[i].hasAnchors) {
                    if (directionPointToPoint(srcPoint, shapes[i].center()) === direction) {
                        let aDelta = distancePointPoint(srcPoint, shapes[i].center())
                        if (aDelta < bestDelta && aDelta < maxRadius) {
                            result = shapes[i];
                            bestDelta = aDelta;
                        }
                    }
                }
            }
        });

        return result;
    }

    moveShape(direction: string) {
        this.beginUpdate();
        try {
            let changedShapes: Array<TBaseShape> = [];
            let actionGroup = new TCustomActionGroup(null, this);
            let nodeActionGroup = new TCustomActionGroup(null, this);
            let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
            let edgeActionGroup = new TCustomActionGroup(null, this);

            let selectedShapes = this.getSelectedShapes();
            let dXY = 0;
            let minPos = Number.MAX_VALUE;

            if (direction === 'ArrowLeft')
                dXY = -this.gridSize.width;
            else if (direction === 'ArrowUp')
                dXY = -this.gridSize.height;
            else if (direction === 'ArrowRight')
                dXY = this.gridSize.width;
            else if (direction === 'ArrowDown')
                dXY = this.gridSize.height;

            selectedShapes.forEach(shape => {
                if (direction === 'ArrowUp' || direction === 'ArrowDown')
                    minPos = Math.min(shape.y + dXY, minPos);
                else if (direction === 'ArrowLeft' || direction === 'ArrowRight')
                    minPos = Math.min(shape.x + dXY, minPos);
            });

            // links oder oben angekommen
            if (minPos < 0)
                return;

            selectedShapes.forEach(shape => {
                let moveAction = new TNodePositionAction(shape, this);
                if (direction === 'ArrowUp' || direction === 'ArrowDown')
                    moveAction.setValue(0, dXY);
                else if (direction === 'ArrowLeft' || direction === 'ArrowRight')
                    moveAction.setValue(dXY, 0);

                nodeActionGroup.actions.push(moveAction);
                changedShapes.push(shape);
            });

            // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
            if (changedShapes.length === 0) {
                return;
            }

            // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
            nodeActionGroup.performAction();
            actionGroup.actions.push(nodeActionGroup);

            // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
            if (this.processMode === ProcessMode.VirtualGrid) {
                changedShapes.forEach(shape => {
                    let finalPos = this.snapShapeToUsedGrid(shape);
                    let moveAction = new TNodePositionAction(shape, this);
                    let resizeAction = new TNodeSizeAction(shape, this);
                    moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                    resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                    snapActionGroup.actions.push(moveAction);
                    snapActionGroup.actions.push(resizeAction);
                });

                // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
                snapActionGroup.performAction();
                actionGroup.actions.push(snapActionGroup);
            }

            changedShapes.forEach(shape => {
                this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                    let edge = obj as TEdgeBase;

                    let edgeAction = new TEdgeExtraPointsAction(edge, this);
                    let newPoints = edge.recalcExtraPoints();

                    edgeAction.setValue(newPoints);
                    edgeActionGroup.actions.push(edgeAction);
                });
            });

            // jetzt die neuen Kanten anwenden
            edgeActionGroup.performAction();
            actionGroup.actions.push(edgeActionGroup);

            this.actionList.addClientAction(actionGroup);

            this.checkEditorSize();
        }
        finally {
            this.endUpdate();
        }
    }

    inflateShape(direction: string, centered = false) {
        let changedShapes: Array<TBaseShape> = [];
        let actionGroup = new TCustomActionGroup(null, this);
        let nodeActionGroup = new TCustomActionGroup(null, this);
        let snapActionGroup = new TCustomActionGroup(null, this); // nur bei ClusterGrid relevant
        let edgeActionGroup = new TCustomActionGroup(null, this);

        let selectedShapes = this.getSelectedShapes();
        let dXY = 0;

        if (direction === 'ArrowLeft')
            dXY = -this.gridSize.width;
        else if (direction === 'ArrowUp')
            dXY = -this.gridSize.height;
        else if (direction === 'ArrowRight')
            dXY = this.gridSize.width;
        else if (direction === 'ArrowDown')
            dXY = this.gridSize.height;

        let minSize = true;
        selectedShapes.forEach(shape => {
            if (centered) {
                if (((direction === 'ArrowLeft' || direction === 'ArrowRight') && (shape.x - dXY <= 0 || shape.w + 2 * dXY <= 0))
                    || ((direction === 'ArrowUp' || direction === 'ArrowDown') && (shape.y + dXY <= 0 || shape.h + 2 * -dXY <= 0))) {
                    minSize = false;
                }
            }
            else if (((direction === 'ArrowLeft' || direction === 'ArrowRight') && (shape.w + dXY <= 0))
                || ((direction === 'ArrowUp' || direction === 'ArrowDown') && (shape.h + dXY <= 0))) {
                minSize = false;
            }
        });

        if (!minSize)
            return;

        selectedShapes.forEach(shape => {
            let moveAction = new TNodePositionAction(shape, this);
            let sizeAction = new TNodeSizeAction(shape, this);
            if (direction === 'ArrowUp' || direction === 'ArrowDown') {
                if (centered) {
                    moveAction.setValue(0, dXY);
                    sizeAction.setValue(shape.w, shape.h + 2 * -dXY);
                }
                else {
                    sizeAction.setValue(shape.w, shape.h + dXY);
                }
            }
            else if (direction === 'ArrowLeft' || direction === 'ArrowRight') {
                if (centered) {
                    moveAction.setValue(-dXY, 0);
                    sizeAction.setValue(shape.w + 2 * dXY, shape.h);
                }
                else {
                    sizeAction.setValue(shape.w + dXY, shape.h);
                }
            }
            if (centered) {
                nodeActionGroup.actions.push(moveAction);
            }
            nodeActionGroup.actions.push(sizeAction);
            changedShapes.push(shape);
        });

        // gabs Änderungen? -> wenn nein können wir uns die Kanten sparen
        if (changedShapes.length === 0) {
            return;
        }

        // die müssen wir ausführen, bevor wir den Rest neu berechnen, damit die Ausgangsdaten korrekt sind
        nodeActionGroup.performAction();
        actionGroup.actions.push(nodeActionGroup);

        // Bei Cluster müssen wir ggf. auch noch die Shapes in ihrer Zelle neu zenrieren
        if (this.processMode === ProcessMode.VirtualGrid) {
            changedShapes.forEach(shape => {
                let finalPos = this.snapShapeToUsedGrid(shape);
                let moveAction = new TNodePositionAction(shape, this);
                let resizeAction = new TNodeSizeAction(shape, this);
                moveAction.setValue(finalPos.left - shape.x, finalPos.top - shape.y);
                resizeAction.setValue(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top);

                snapActionGroup.actions.push(moveAction);
                snapActionGroup.actions.push(resizeAction);
            });

            // die müssen wir ausführen, bevor wir die Kanten neu berechnen, damit die Ausgangsdaten korrekt sind
            snapActionGroup.performAction();
            actionGroup.actions.push(snapActionGroup);
        }

        changedShapes.forEach(shape => {
            this.objectList.filter(obj => obj instanceof TEdgeBase && obj.isConnectedWithShape(shape)).forEach(obj => {
                let edge = obj as TEdgeBase;

                let edgeAction = new TEdgeExtraPointsAction(edge, this);
                let newPoints = edge.recalcExtraPoints();

                edgeAction.setValue(newPoints);
                edgeActionGroup.actions.push(edgeAction);
            });
        });

        // jetzt die neuen Kanten anwenden
        edgeActionGroup.performAction();
        actionGroup.actions.push(edgeActionGroup);

        this.actionList.addClientAction(actionGroup);

        this.checkEditorSize();
    }
    //#endregion
}

export function processEditorAddItemDragStart(ev: DragEvent): void {
    let shapeid = (ev.target as HTMLElement)?.dataset?.shapeid;
    if (assigned(shapeid)) {
        ev.dataTransfer.setData('text', shapeid);
    }
}

// Die müssen wir nach außen freigeben
window[processEditorAddItemDragStart.name] = processEditorAddItemDragStart;