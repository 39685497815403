import { TWebComponent } from '../../base/class.web.comps';

export class TwcCard extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcCard';
    }

    handleNavTargetsVisible(value: string): void {
        // wir schauen, ob es eine NavBar gibt und prüfen jedes NavItem
        document.querySelectorAll(`.cs-nav-container [data-targetid=${this.id}`).forEach(item => {
            // Card gehört zu einem NavItem -> erstmal unsichtbar
            this.obj.classList.add('d-none');

            // ist unser NavItem das aktive? Dann zeigen wir die Card an
            if (item.classList.contains('active') && value == '1') {
                this.obj.classList.remove('d-none');
            }
        });
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.parentElement.classList.toggle('d-none', value == '0');
                this.handleNavTargetsVisible(value);
                break;
            case 'Title':
                var element = document.getElementById(`${this.id}-title`);
                if (element != null) {
                    element.innerHTML = value;
                }
                break;
            case 'HeaderTitle':
                var element = document.getElementById(`${this.id}-headertitle`);
                if (element != null) {
                    element.innerHTML = value;
                }
                break;
        }
    }
}

export class TwcCardSection extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcCardSection';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                document.getElementById(this.id).classList.toggle('d-none', value == '0');
        }
    }
}

export class TwcCardPanel extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcCardPanel';
    }

    handleNavTargetsVisible(value: string): void {
        // wir schauen, ob es eine NavBar gibt und prüfen jedes NavItem
        document.querySelectorAll(`.cs-nav-container [data-targetid=${this.id}`).forEach(item => {
            // Card gehört zu einem NavItem -> erstmal unsichtbar
            this.obj.classList.add('d-none');

            // ist unser NavItem das aktive? Dann zeigen wir die Card an
            if (item.classList.contains('active') && value == '1') {
                this.obj.classList.remove('d-none');
            }
        });
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                document.getElementById(this.id).classList.toggle('d-none', value == '0');
                this.handleNavTargetsVisible(value);
                break;
            case 'Title':
                var element = document.getElementById(`${this.id}-title`);
                if (element != null) {
                    element.innerHTML = value;
                }
                break;
            case 'HeaderTitle':
                var element = document.getElementById(`${this.id}-headertitle`);
                if (element != null) {
                    element.innerHTML = value;
                }
                break;
        }
    }
}

export class TwcCardPanelItem extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcCardPanelItem';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                document.getElementById(this.id).classList.toggle('d-none', value == '0');
        }
    }
}
export class TwcCardExtended extends TwcCard {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcCardExtended';
    }
}