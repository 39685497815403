﻿import bootstrap, { Popover } from 'bootstrap';
import { UAParser } from 'ua-parser-js';
import { WebCompEventHandlerAsync } from '../../../core/communication';
import { String2HTMLEntity } from '../../../core/interaction';
import { isComponentActive } from '../../../core/utils/componentUtils';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import * as PopOver from '../../../core/utils/popover';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { addMultipleEventListeners } from '../../../utils/domUtils';
import { assigned } from '../../../utils/helper';
import { b64EncodeUnicode, boolFromStr, removeControlCodes } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { HyperlinkTcsDialog } from '../../common/consense.base.dialog';
import { CSPopDown } from '../../common/consense.base.popdown';
import { ISTTOutputComp } from '../../comps/SpeechToText/class.web.comp.speechtotext';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcTextMemo extends TRenderWebComponent implements ISTTOutputComp {
    static readonly memoListCssClasses = ['cs-memo-style-enumeration-item', 'cs-memo-style-enumeration-number', 'cs-memo-style-enumeration-yes', 'cs-memo-style-enumeration-no', 'cs-memo-style-enumeration-empty'];
    textAreaId: string;
    textArea: JQuery<HTMLElement>;
    textAreaElement: HTMLElement;
    buttonToolbar?: JQuery<HTMLElement>;
    buttonToolbarElement?: HTMLElement;
    previousElementSibling: Node & ParentNode;
    workOffDialog: Promise<any>;
    copyPasteId: string;
    maxLength: number;
    popdownId: string;
    readonly popdownTrigger: string = '@';
    lastCaretContainer: { startContainer: Node; startOffset: number; endContainer: Node; endOffset: number; };
    lastRange: { startContainer: Node; startOffset: number; endContainer: Node; endOffset: number; };
    popdown: HTMLElement;
    hasServerChangeEvent: boolean;
    showLinesCount: number;
    isInteractive: boolean;
    needsOptimization: boolean;
    modalContainer: Element;
    private resizeObserver: ResizeObserver;
    static readonly DefaultLineCount = 6;
    keyPressTimeout?: number = 0;
    checkboxClickFunction: (e: MouseEvent) => void;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTextMemo';
        this.textAreaId = this.id + '-content';
        this.textArea = $('#' + this.textAreaId);
        this.textAreaElement = document.getElementById(this.textAreaId);
        this.buttonToolbar = $('#' + this.id + '-buttontoolbar');
        this.buttonToolbarElement = document.getElementById(`${this.id}-buttontoolbar`);
        this.lastRange = null;
        this.previousElementSibling = null;
        this.lastCaretContainer = null;
        this.workOffDialog = null;
        this.copyPasteId = 'copyfrommemo';
        this.popdownId = this.id + '-popdown';
        this.needsOptimization = false;
        this.popdown = document.getElementById(this.popdownId);
        this.showLinesCount = parseInt(this.textAreaElement.dataset?.showlinescount ?? String(TwcTextMemo.DefaultLineCount));
        this.maxLength = parseInt(this.textAreaElement.dataset?.maxlength);
        this.isInteractive = boolFromStr(this.obj.dataset?.isInteractive ?? 'false');
        this.checkboxClickFunction = ((e) => this.onClickCheckbox(e)).bind(this);

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent ?? 'false');

        // initial Laden, damit wir das später nicht immer machen müssen
        this.modalContainer = getModalDialogContainer(this.obj);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initEventListener();
        this.initUnitClick('#' + this.textAreaId + ' :button');


        if (this.textAreaElement.classList.contains('cs-auto-height')) {
            this.needsOptimization = true;

            // Wenn wir das Browserfenster verändern, muss ggf. die Höhe der Tabelle angepasst werden
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    this.invalidate();
                });
            });

            this.resizeObserver.observe(this.obj);

        } else if (this.showLinesCount != TwcTextMemo.DefaultLineCount) {
            // Default-Höhe muss nicht berechnet werden, erst wenn wir eine andere Höhe haben, dann berechnen wir neu
            let textAreaStyles = window.getComputedStyle(this.textArea.get(0));
            // wir holen uns die Zeilenhöhe + 16px Margin-Bottom, berechnen die Gesamthöhe
            let computedHeight = (parseFloat(textAreaStyles.lineHeight) + 16) * this.showLinesCount;
            this.textArea.css('height', computedHeight);
            this.textArea.css('max-height', computedHeight);
        }

        // wir schalten erst im Construktor die bearbeitung frei           
        this.setContenteditable(!(this.isDisabled() || this.isReadOnly()));
        this.initMemo();

    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    isReadOnly(): boolean {
        return this.textAreaElement.hasAttribute('readonly');
    }

    isDisabled(): boolean {
        return this.textAreaElement.hasAttribute('disabled');
    }

    isFireFox(): boolean {
        return new UAParser().getBrowser().name == 'Firefox';
    }

    isInsideModal(): boolean {
        return assigned(this.modalContainer);
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        if (!isComponentActive(this)) {
            return;
        }
        this.optimizeHeight();
    }

    optimizeHeight(): void {

        if (!this.needsOptimization) {
            return;
        }
        // erstmal nur ohne Modale Dialoge
        if (!this.isInsideModal()) {
            let availableSpaceVertical = 0, height = 0;
            let memoRect = this.obj.getBoundingClientRect();

            let appFooter = document.querySelector('.app-footer');
            let appFooterHeight = 0;
            if (assigned(appFooter)) {
                let appFooterComputedStyle = window.getComputedStyle(appFooter);
                appFooterHeight = appFooter.getBoundingClientRect().height;
                appFooterHeight += parseFloat(appFooterComputedStyle.marginBottom);
                appFooterHeight += parseFloat(appFooterComputedStyle.marginTop);
                appFooterHeight += parseFloat(appFooterComputedStyle.paddingBottom);
                appFooterHeight += parseFloat(appFooterComputedStyle.paddingTop);
                appFooterHeight += 120; // experimentell noch etwas weg (appFooterHeight wird später abgezogen)
            }

            availableSpaceVertical = document.documentElement.clientHeight - memoRect.y - appFooterHeight;
            availableSpaceVertical = Math.floor(availableSpaceVertical); // hier keine halben pixel

            // schauen ob wir mehr Platz benötigen als wir haben.
            let textAreaHeight = this.textAreaElement.getBoundingClientRect().height;
            let totalMemoHeight = textAreaHeight + (this.buttonToolbarElement?.getBoundingClientRect()?.height ?? 0);

            if (availableSpaceVertical > totalMemoHeight) {
                height = availableSpaceVertical;
            } else {
                height = totalMemoHeight - 15; // noch ein bisschen weg
            }
            this.textAreaElement.style.setProperty('max-height', height + 'px');
            this.textAreaElement.style.setProperty('height', height + 'px');

        }

        this.needsOptimization = false;
    }

    override setFocus(): void {
        // der cursor kann im memo sein, aber aktiv ist ein anderes element! das fragen wir hier ab
        let isMemoActive = this.isInRootNode(document.activeElement);
        if (!isMemoActive) {
            this.textArea.trigger('focus');
        }
    }

    autoFocus(): void {
        if (!this.isCaretInMemo()) {
            if (this.lastCaretContainer == null) {
                this.textArea.trigger('focus');
                this.initMemo();
                let linesCount = this.getSelectedLinesCount();
                if (linesCount == 1) {
                    if (window.getSelection) {
                        let sel = window.getSelection();
                        if (sel.rangeCount) {
                            let range = sel.getRangeAt(0);
                            if (this.isRootNode(range.commonAncestorContainer)) {
                                let rootnode = range.commonAncestorContainer as unknown as ParentNode;
                                // haben wir schon children
                                if (rootnode.children.length) {
                                    // UPS // Direkt casten geht nicht, da die Typen sich irgendwie unterscheiden
                                    range.setStart(rootnode.firstElementChild as unknown as Node, 0);
                                    range.setEnd(rootnode.firstElementChild as unknown as Node, 0);
                                    range.collapse(true);
                                    sel.removeAllRanges();
                                    sel.addRange(range);
                                }
                            }
                        }
                    }
                }

            } else {
                // Wir kommen von aussen und holen uns die letzte Position
                this.restoreLastCaret();
                this.initMemo();
            }

        }

    }

    openPopDown(insertTrigger: boolean): void {
        // wir öffnen nur wenn ein Searchprovider assigned ist
        try {
            if (boolFromStr(this.popdown.dataset.searchprovider)) {
                this.autoFocus();
                if (insertTrigger) {
                    this.insertTextAtCurrentPosition(this.popdownTrigger);
                }
                let clearLastInput = !insertTrigger;
                if (this.saveRange()) { //aktuelle position merken
                    this.saveSibling();
                    this.workOffDialog = this.workOffPopDownFunc();
                    this.workOffDialog.then(function (dataSet) {
                        this.restoreRange();
                        if (clearLastInput) {
                            this.clearLastInput();
                        }
                        this.initMemo();
                        // und einfügen
                        dataSet.class = 'oid';
                        dataSet.oidbase64 = b64EncodeUnicode(dataSet.key);
                        dataSet.href = dataSet.key;

                        let newNode = this.createNewNodeInput(dataSet);
                        this.insertCsUnit(newNode);
                        this.notifyComponentChanged();
                    }.bind(this))
                        .catch(function (data) {
                            console.error(data);
                            this.restoreRange()
                            if (data) {
                                if (clearLastInput) {
                                    this.clearLastInput();
                                }
                            } else {
                                this.jumpCaretToEnd();
                            }
                            this.initMemo();
                        }.bind(this));
                } else {
                    // hier darf der eigentlich nie rein
                    console.error('Cursor Error');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    jumpCaretToEnd(): void {
        let sel = window.getSelection();
        if (sel.rangeCount) {
            let range = sel.getRangeAt(0);
            let newrange = document.createRange();
            newrange.selectNodeContents(range.commonAncestorContainer);
            newrange.collapse(false);
            sel.removeAllRanges();
            sel.addRange(newrange);
            this.textArea.trigger('focus');
        }
    }

    jumpCaretToEndByNode(el: HTMLElement, pos: number = -1): void {
        let sel = window.getSelection();
        if (sel.rangeCount) {
            let newrange = document.createRange();
            newrange.selectNodeContents(el);
            if (pos != -1) {
                try {
                    let nodeList = el.childNodes;
                    if (assigned(nodeList)) {
                        if (nodeList.length === 1) {
                            if (nodeList[0].nodeType == Node.TEXT_NODE) {
                                newrange.setStart(nodeList[0], pos);
                            }
                        } else {
                            // wir haben mehrere nodes, jetzt müssen wir die Stelle finden
                            let charCount = 0;
                            let newpos = pos;
                            for (let i = 0; i < nodeList.length; i++) {
                                if (nodeList[i].nodeType == Node.ELEMENT_NODE) {
                                    // wir sind ein HTML Element, 
                                } else if (nodeList[i].nodeType == Node.TEXT_NODE) {
                                    charCount += nodeList[i].textContent.length;
                                }

                                if (charCount > pos) {
                                    newpos = nodeList[i].textContent.length - (charCount - pos);
                                    // wir haben unsere node gefunden, wo wir hin müssen
                                    newrange.setStart(el.childNodes[i], newpos);
                                    break;
                                } else if (charCount == pos) {
                                    // wir haben unsere node gefunden, wo wir hin müssen
                                    newpos = pos;
                                    if (pos >= el.childNodes[i].textContent.length) {
                                        newpos = nodeList[i].textContent.length;
                                    }
                                    newrange.setStart(el.childNodes[i], newpos);
                                    break;
                                }
                            }
                        }
                    }
                } catch (e) {
                    // statements
                    console.log(e);
                }
            }
            newrange.collapse(true);

            sel.removeAllRanges();
            sel.addRange(newrange);
            this.textArea.trigger('focus');
        }
    }

    clearLastInput(): void {
        let sel = window.getSelection();
        if (sel.rangeCount) {
            let range = sel.getRangeAt(0);
            let clone = range.cloneRange();
            try {
                // firefox verhält sich in position 0 anders. Hier helfen wir nach
                if (this.isFireFox() && range.commonAncestorContainer.nodeType == Node.ELEMENT_NODE) {
                    // wenn jemand STRG+Enter drückt, haben wir einen anderen Offset
                    if (range.startOffset > 0) {
                        clone.setStart(range.startContainer, range.startOffset);
                        clone.setEnd(range.endContainer, range.startOffset + 1);
                    } else {
                        clone.setStart(range.startContainer, 0);
                        clone.setEnd(range.endContainer, 1);
                    }
                } else {
                    clone.setStart(range.startContainer, range.startOffset);
                    clone.setEnd(range.endContainer, range.startOffset + 1);
                }
                clone.deleteContents();
            } catch (e) {
                // statements
                console.log(e);
            }
        }
    }

    workOffPopDownFunc(): Promise<any> {
        return new Promise(function (resolve, reject) {
            let popdown = new CSPopDown(this.popdownId);
            let resolved = popdown.show();
            resolved.then(function (dataSet) {
                resolve(dataSet);
            }.bind(this))
                .catch(function (error) {
                    console.error(error);
                    reject(error);
                }.bind(this));
        }.bind(this));
    }

    initUnitClick(selector: string | Node): void {
        // eventListener für die Klicks
        let jObj: JQuery<Node>

        // Typescript mag es leider nicht jQuery zu nutzen wenn es den Typen nicht fest kennt, auch wenn beide funktionieren, daher das if
        if (selector instanceof Node) {
            jObj = $(selector);
        } else {
            jObj = $(selector);
        }

        if (jObj.length > 0) {
            jObj.off('click');
            jObj.on('click', function (event) {
                this.execUnitClick(event);
            }.bind(this));
        }
    }
    /**************************************************************************/
    /*  klicks -> wir unterscheiden zwischen readonly und normal */
    execUnitClick(event: Event): void {
        if (this.isReadOnly()) {
            this.execUnitClickReadonly(event);
        } else {
            this.execUnitClickNotReadonly(event)
        }
    }

    execUnitClickReadonly(event: Event): void {
        var target = event.target as HTMLInputElement;
        var targetObj = $(target);
        // die css Klasse sagt uns wer wir sind
        switch (targetObj.data('class')) {
            case 'cs-memo-data-hyperlink':
                window.open(targetObj.data('href'));
                break;
            case 'cs-memo-data-filelink':
            case 'cs-memo-data-folderlink':
            case 'cs-memo-data-cslink':
            case 'cs-memo-data-saplink':
                MsgNotify('Sie haben auf ' + target.value + ' geklickt!', MessageType.Success);
                break;
            default:
                // hier sind wir ein CSObject     
                var csObjectKey = targetObj.data('key');
                if (csObjectKey.indexOf('U') === 0) {
                    PopOver.showPopoverForUser(target, csObjectKey);
                } else if (csObjectKey.indexOf('G') === 0) {
                    PopOver.showPopoverForGroup(target, csObjectKey);
                } else {
                    window.open('/GoTo?oid=' + csObjectKey);
                }
        }

    }

    execUnitClickNotReadonly(event: Event): void {
        // hier kommt später die Bearbeitung
        var target = event.target as HTMLInputElement;
        var targetObj = $(target);
        // die css Klasse sagt uns wer wir sind
        switch (targetObj.data('class')) {
            case 'cs-memo-data-hyperlink':
                this.showEditPopoverHyperlink(target);
                break;
            case 'cs-memo-data-filelink':
            case 'cs-memo-data-folderlink':
            case 'cs-memo-data-cslink':
            case 'cs-memo-data-saplink':
                MsgNotify('Bearbeitung von ' + target.value + ' derzeit nicht möglich!', MessageType.Warning);
                break;
            default:
                // hier sind wir ein CSObject     
                var csObjectKey = targetObj.data('key');
                if (csObjectKey.indexOf('U') === 0) {
                    PopOver.showPopoverForUser(target, csObjectKey);
                } else if (csObjectKey.indexOf('G') === 0) {
                    PopOver.showPopoverForGroup(target, csObjectKey);
                } else {
                    window.open('/GoTo?oid=' + csObjectKey);
                }
        }
    }

    closeAllPopOvers(): void {
        let alllinks = this.obj.querySelectorAll('.btn-link');
        for (let i = 0; i < alllinks.length; i++) {
            let popOverToClose = Popover.getInstance(alllinks[i]);
            if (assigned(popOverToClose)) {
                popOverToClose.hide();
            }
        }
    }

    showEditPopoverHyperlink(target: HTMLInputElement): void {
        let textChange = this.textAreaElement.dataset?.textchange ?? 'Ändern';
        let textGoto = this.textAreaElement.dataset?.textgoto ?? 'Gehe zu';

        let html = '<a href="' + target.dataset.href + '" target="_blank"><i class="fa-regular fa-external-link"></i> ' + textGoto + '...</a>';
        html += ' <a href="#" class="memoLinkEdit ps-2" onclick="return false;"><i class="fa-regular fa-edit"></i> ' + textChange + '...</a>';

        let myDefaultAllowList = bootstrap.Tooltip.Default.allowList
        myDefaultAllowList['a'].push('onclick');
        let popOver = new Popover(target, {
            content: html,
            html: true,
            placement: 'top',
            container: 'body',
            trigger: 'focus',
            allowList: myDefaultAllowList
        })

        let memoLinkEditClick = (event) => {
            event.stopPropagation()
            event.preventDefault();
            this.showEditHyperlink(event);
        }
        // jetzt die listener drauf
        target.addEventListener('hidden.bs.popover', () => {
            popOver.dispose();
        }, { once: true });
        target.addEventListener('shown.bs.popover', () => {
            let popoverId = target.getAttribute('aria-describedby');
            let memoLinkEdit = document.getElementById(popoverId).querySelector('.memoLinkEdit');
            memoLinkEdit.addEventListener('click', (event: MouseEvent) => memoLinkEditClick(event));
        }, { once: true });
        popOver.show();
    }

    showEditHyperlink(event: Event): void {
        let target = <HTMLElement>event.target;
        let popOverbody = target.parentElement;
        let node = <HTMLInputElement>document.querySelector('[aria-describedby="' + popOverbody.parentElement.id + '"]');
        this.hasCsUnit('hyperlink', node.dataset.oidbase64, node);
    }


    /**************************************************************************/
    /*  events */
    initEventListener(): void {

        // not allowed with readonly and/or disabled
        this.obj.addEventListener('drop', (e: Event) => this.execOnInteractionEvent(e));
        this.obj.addEventListener('paste', (e: ClipboardEvent) => this.execOnInteractionEvent(e));
        this.obj.addEventListener('cut', (e: ClipboardEvent) => this.execOnInteractionEvent(e));
        this.obj.addEventListener('input', (e: InputEvent) => this.execOnInteractionEvent(e));
        this.obj.addEventListener('keydown', (e: KeyboardEvent) => this.execOnInteractionEvent(e));
        this.obj.addEventListener('keyup', (e: KeyboardEvent) => this.execOnInteractionEvent(e));
        this.obj.addEventListener('keypress', (e: KeyboardEvent) => this.execOnInteractionEvent(e));
        this.textArea.on('blur', {}, this.execOnInteractionEvent.bind(this));

        // allowed with and without readonly
        this.obj.addEventListener('copy', (e: ClipboardEvent) => this.execOnCopyEvent(e));

        // allowed when not disabled
        $('#' + this.id).on('selectstart', {}, this.execOnSelectStartEvent.bind(this));

        // NotifyChange registrieren
        addMultipleEventListeners(this.obj, 'input cut paste', () => {
            this.notifyComponentChanged();

            if (this.hasServerChangeEvent) {
                // falls wir gerade senden wollen, brechen wir das ab, damit nicht zu viele Anfragen an den Server gesendet werden, die veraltet sind...
                if (assigned(this.keyPressTimeout)) {
                    window.clearTimeout(this.keyPressTimeout);
                    this.keyPressTimeout = null;
                }

                this.keyPressTimeout = window.setTimeout(() => {
                    WebCompEventHandlerAsync('OnChange', this.id);
                }, 200);
            }
        });

        this.textArea.on('blur click', {}, this.initMemo.bind(this));

        if (!(this.isReadOnly() || this.isDisabled()) && this.isInteractive) {
            this.setCheckboxEventListeners();
        }
    }

    execOnInteractionEvent(event: Event) {

        // Bei Readonly wollen wir nicht alles
        if (this.isReadOnly() || this.isDisabled()) {
            event.preventDefault();
            return;
        }

        if (event.type === 'cut') {
            this.execOnCutEvent(event as ClipboardEvent);
        } else if (event.type === 'drop') {
            this.execOnDropEvent(event);
        } else if (event.type === 'paste') {
            this.execOnPasteEvent(event as ClipboardEvent);
        } else if (event.type === 'input') {
            this.execOnInputEvent(event as InputEvent);
        } else if (event.type === 'keydown') {
            this.execOnKeyEvent(event as KeyboardEvent);
            this.execOnKeyDownEvent(event as KeyboardEvent);
        } else if (event.type === 'keyup') {
            this.execOnKeyUpEvent(event as KeyboardEvent);
        } else if (event.type === 'keypress') {
            this.execOnKeyPressEvent(event as KeyboardEvent);
        } else if (event.type === 'blur') {
            this.updateLines();
            this.saveLastCaret();
        }

    }

    execOnSelectStartEvent(e): boolean {
        // Bei Disabled wollen wir nicht dass markiert wird
        if (this.isDisabled()) {
            e.returnValue = false;
            return false;
        }
    }

    execOnKeyPressEvent(e: KeyboardEvent): void {
        if (this.maxLength >= 0 && this.textArea.text().length >= this.maxLength) {
            e.preventDefault();
        }
    }

    execOnInputEvent(event: InputEvent) {
        if ((event.target as Node).childNodes.length > 0) {
            for (let i = 0; i < (event.target as Node).childNodes.length; i++) {
                let node = (event.target as Node).childNodes[i];

                if (node instanceof Text) {
                    // wenn ein textnode gefunden wird, ändern wir das zum htmlnode
                    // nodeValue enthält den Text 
                    let htmlnode = this.createNewNodeDiv('cs-memo-style-text', node.nodeValue);
                    let textnode = (event.target as Node).childNodes[i];
                    let parentDiv = textnode.parentNode;
                    parentDiv.replaceChild(htmlnode, textnode);

                    // Alternative
                    // textnode.parentNode.insertBefore(htmlnode, textnode);
                    // textnode.parentNode.removeChild(textnode);

                    // jetzt müssen wir noch den Cursor neu setzen, sonst kann man nicht weiterarbeiten
                    let range = document.createRange();
                    let sel = window.getSelection();
                    range.setStart(htmlnode, 1);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                } else if (node instanceof Element) {
                    if (node.classList.length == 0 && node.tagName == 'DIV') {
                        node.classList.add('cs-memo-style-text');
                    }
                    // wenn man zwei oder mehr zeilen markiert und entfernen klickt, fügt FF da BRs ein
                    // das wollen wir aber nicht
                    if (node.tagName == 'BR') {
                        if (node.parentNode) {
                            node.parentNode.removeChild(node);
                        }
                    }
                }
            }
        }
        this.execEventAction('update', event);
    }

    execOnKeyUpEvent(event: KeyboardEvent): void {
        if (event.key == 'Tab') {
            // wenn wir mit Tab reinkommen, müssen wir den Focus setzen
            this.setFocus();
        }

        this.execEventAction('update', event); // allg update stuff 
    }

    execOnKeyEvent(event: KeyboardEvent): void {
        this.execEventAction('update', event); // allg update stuff 
    }

    execOnKeyDownEvent(event: KeyboardEvent): void {
        // Wir verhindern, dass das Event an andere Komponenten weitergereicht wird
        // Zur Risikominderung machen wir das erstmal nur für KeyDown, weil wir hier
        // ein explizites Problem im Prozess-Editor hatten (IMS-8398)
        event.stopPropagation();

        if (event.ctrlKey) {
            if ((event.shiftKey && event.keyCode == 90) || event.keyCode == 89) {
                this.docExec('redo', ''); // Strg + shift + z OR Strg + y
            } else if (event.keyCode == 90) {
                this.docExec('undo', ''); // Strg + z 
            }
        } else {
            if (event.keyCode == 27) {
                this.textArea.blur(); // ESC
            }
        }

        if (event.key == this.popdownTrigger) {
            this.openPopDown(false);
        }

        if (event.key == 'Enter') {
            let node = window.getSelection().focusNode as HTMLElement;
            if ((node.textContent == '') && (node.innerHTML == '<br>')) {
                if (TwcTextMemo.memoListCssClasses.includes(node.className)) {
                    node.className = 'cs-memo-style-text';
                    event.preventDefault();
                }
            }
        }
    }

    execOnDropEvent(event: Event): void {
        event.stopPropagation()
        event.preventDefault();
        // MsgNotify('Drop is not allowed!', mtWarning);
    }
    /**************************************************************************/

    setClipboardId(event: ClipboardEvent): void {
        event.clipboardData.setData('text/csformat', this.copyPasteId);
    }

    isClipboardId(event: ClipboardEvent): boolean {
        return (event.clipboardData.getData('text/csformat') == this.copyPasteId);
    }

    execOnCopyEvent(event: ClipboardEvent): void {

        if (this.isDisabled()) {
            return;
        }

        event.stopPropagation()
        event.preventDefault();
        let linesCount = this.getSelectedLinesCount();
        if (linesCount > 1) {
            this.copySelectedNodes(event);
        } else {
            this.copySelection(event);
        }
        this.setClipboardId(event);
    }

    execOnCutEvent(event: ClipboardEvent): void {
        event.stopPropagation()
        event.preventDefault();
        let linesCount = this.getSelectedLinesCount();
        if (linesCount > 1) {
            this.copySelectedNodes(event);
            this.deleteSelectedNodes();
        } else {
            this.copySelection(event);
            this.deleteSelection();
        }
        this.setClipboardId(event);
    }

    execOnPasteEvent(event: ClipboardEvent): void {
        // wir stoppen die standard befehle
        event.stopPropagation();
        event.preventDefault();
        // jetzt holen wir uns den Text
        var clipboard = event.clipboardData;
        var text = clipboard.getData('text/plain');
        if (text.trim() == '') {
            // raus wenn nix im clipboard
            return;
        }

        if (this.maxLength >= 0) {
            // ACHTUNG: totalTextLength ist unter Umständen größer als der reale Text nachher, da hier oft noch unsichtbare Steuerzeichen drin sind, die im Text u. Um. nachher so aber nicht mehr auftauchen
            let totalTextLength = this.textArea.text().length + text.length;
            if (totalTextLength > this.maxLength) {
                MsgNotify(`Es sind maximal ${this.maxLength} Zeichen erlaubt!`, MessageType.Danger);
                return;
            }
        }

        // wir speichern wo wir sind! 
        // das ist sehr wichtig, damit wir beim insert wieder wissen wo wir hinpasten wollen! 
        this.saveSibling();
        this.saveRange();

        if (this.isClipboardId(event)) {
            this.deleteSelectedNodes();
            var html = clipboard.getData('text/html');
            var re1 = new RegExp(/[\s\S]*startfragment-->/, 'gmi');
            html = html.replace(re1, '');
            var re2 = new RegExp(/<!--[\s\S]*/, 'gmi');
            html = html.replace(re2, '');
            var newNode = this.createNodeFromHtml(html);
            this.insertNodeAfterCurrentNode(newNode);
        } else {
            // hier will jemand etwas html reinpasten aus einer datei oder so
            let textArray = text.split(/\r\n|\r|\n/g);
            // zählen ob wir mehrere zeilen haben
            let linesCount = this.getSelectedLinesCount();
            // paste und markierung: jeweils eine Zeile 
            if (linesCount === 1) {
                // Sonderfall eine Zeile
                // HIER KEIN deleteSelectedNodes
                if (textArray.length == 1) {
                    // funzt
                    let textToPaste = textArray[0];
                    this.insertTextAtCurrentPosition(textToPaste);

                    // und den Cursor setzen
                    window.getSelection().collapseToEnd();
                } else {
                    let caretPos = this.getCaretPositionEx();
                    let parentNode = this.getFocusParentNodeOfSelection() as HTMLElement;
                    let innerHTML = parentNode.innerHTML;
                    let rightText = innerHTML.substring(caretPos);
                    let lastNode = parentNode;
                    // nun geht es los mit dem pasten
                    let textToPaste = textArray[0];
                    this.insertTextAtCurrentPosition(textToPaste);
                    for (let i = textArray.length - 1; i >= 1; i--) {
                        textToPaste = textArray[i];
                        // an letzter Stelle packen wir den Text rein
                        if (i === textArray.length - 1) {
                            textToPaste += rightText;
                        }
                        let innerHTML = (textToPaste != '' ? textToPaste : '<br>');
                        let currentNode = this.createNewNodeDiv('cs-memo-style-text', innerHTML);
                        this.insertNodeAfterCurrentNode(currentNode);
                        // an letzter Stelle merken wir uns die letze Node, um den cursor zu setzen
                        if (i === textArray.length - 1) {
                            lastNode = currentNode;
                        }
                    }
                    // jetzt noch aufräumen und den ersten text updaten
                    if (rightText.trim() != '') {
                        parentNode = this.getFocusParentNodeOfSelection() as HTMLElement;

                        innerHTML = parentNode.innerHTML;
                        if (innerHTML.lastIndexOf(rightText) == innerHTML.length - rightText.length) {
                            parentNode.innerHTML = innerHTML.substring(0, innerHTML.length - rightText.length);
                        }
                    }
                    this.jumpCaretToEndByNode(lastNode, lastNode.innerHTML.length - rightText.length);
                }
            } else {
                let anchorNode = this.getAnchorParentNodeOfSelection() as HTMLElement;
                let focusNode = this.getFocusParentNodeOfSelection() as HTMLElement;
                this.deleteSelectedNodes();
                let leftText = anchorNode.textContent;
                let leftHTML = anchorNode.innerHTML;
                let rightHTML = focusNode.innerHTML;
                let finalposition = 0;
                let currentNode = anchorNode;
                if (textArray.length === 1) {
                    if (currentNode.isConnected) {
                        currentNode.innerHTML = leftHTML + textArray[0] + rightHTML;
                        finalposition = leftText.length + textArray[0].length;
                    } else {
                        currentNode = this.createNewNodeDiv('cs-memo-style-text', textArray[0]);
                        this.insertNodeNow(currentNode);
                        finalposition = textArray[0].length;
                    }
                    focusNode.remove();
                    this.jumpCaretToEndByNode(currentNode, finalposition);
                } else {
                    let lastNode = focusNode;
                    let textToPaste = textArray[0];

                    if (currentNode.isConnected) {
                        currentNode.innerHTML = leftHTML + textToPaste;
                        finalposition = leftText.length + textArray[0].length;
                    } else {
                        currentNode = this.createNewNodeDiv('cs-memo-style-text', textArray[0]);
                        this.insertNodeNow(currentNode);
                        finalposition = textArray[0].length;
                    }
                    this.jumpCaretToEndByNode(currentNode, finalposition);

                    for (let i = textArray.length - 1; i >= 1; i--) {
                        textToPaste = textArray[i];
                        // an letzter Stelle packen wir den Text rein
                        if (i === textArray.length - 1) {
                            textToPaste += rightHTML;
                        }
                        let innerHTML = (textToPaste != '' ? textToPaste : '<br>');
                        let currentNode = this.createNewNodeDiv('cs-memo-style-text', innerHTML);
                        this.insertNodeAfterCurrentNode(currentNode);
                        // an letzter Stelle merken wir uns die letze Node, um den cursor zu setzen
                        if (i === textArray.length - 1) {
                            lastNode = currentNode;
                        }
                    }
                    focusNode.remove();
                    // und den Cursor setzen
                    this.jumpCaretToEndByNode(lastNode, lastNode.innerHTML.length - rightHTML.length);
                }
            }
            this.initUnitClick('#' + this.textAreaId + ' :button');
        }
    }

    docExec(sCmd: string, sValue: string): boolean {
        return document.execCommand(sCmd, false, sValue);
    }

    isRootNode(node: Node): boolean {
        return this.textArea.attr('id') == $(node).attr('id');
    }

    // UPS // Node ist hier nicht ausreichend, JQuery erwartet Element
    isInRootNode(element: Element): boolean {
        return $.contains(this.textArea[0], element) || this.isRootNode(element);
    }

    clearSavedRanges(): void {
        this.lastRange = null;
        this.previousElementSibling = null;
        this.lastCaretContainer = null;
    }

    initMemo(): void {
        let htmlContent = this.textArea.html().trim();
        if (htmlContent == '') {
            // wir setzen das gespeicherte zurück
            this.clearSavedRanges();
            if (this.textArea.is(':focus')) {
                this.docExec('formatBlock', '<div>');
                var node = this.getFocusNodeOfSelection();
                this.setCssClass(node, 'cs-memo-style-text');
            } else {
                this.textArea.html('<div class="cs-memo-style-text"><br></div>');
            }
        } else if (htmlContent == '<div class="cs-memo-style-text"></div>' || htmlContent == '<br>') {
            // FIREFOX legt beim löschen mit backspace ein <br> automatisch an
            this.textArea.html('');
            this.initMemo(); //rekursiv
        }
    }

    saveSibling(): void {
        if (window.getSelection) {
            var sel = window.getSelection()
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                var currentNode = range.startContainer;
                // wenn wir ein Text node sind, wollen wir das Div drumherum 
                // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType                
                if (currentNode.nodeType == Node.TEXT_NODE) {
                    currentNode = currentNode.parentNode;
                }

                this.previousElementSibling = currentNode.previousSibling as unknown as Node & ParentNode;
                // sonderfall, wir sind ganz oben:
                if (this.previousElementSibling == null) {
                    this.previousElementSibling = currentNode.parentNode;
                }
            }
        }
    }

    saveLastCaret(): boolean {
        if (window.getSelection) {
            var sel = window.getSelection()
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                    this.lastCaretContainer = {
                        "startContainer": range.startContainer,
                        "startOffset": range.startOffset,
                        "endContainer": range.endContainer,
                        "endOffset": range.endOffset
                    };
                    return true;
                }
            }
        }
        return false;
    }

    restoreLastCaret(): void {
        if (this.lastCaretContainer) {
            var sel = window.getSelection();
            sel.removeAllRanges();
            var range = document.createRange();

            try {
                // firefox verhält sich in position 0 anders. Hier helfen wir nach
                if (this.isFireFox()) {
                    range.setStart(this.lastCaretContainer.startContainer, 0);
                    range.setEnd(this.lastCaretContainer.endContainer, 0);
                } else {
                    range.setStart(this.lastCaretContainer.startContainer, this.lastCaretContainer.startOffset);
                    range.setEnd(this.lastCaretContainer.endContainer, this.lastCaretContainer.endOffset);
                }
                sel.addRange(range);
            } catch (e) {
                // statements
                console.log(e);
            }
            this.textArea.trigger('focus');
        }
    }

    saveRange(): boolean {
        if (window.getSelection) {
            var sel = window.getSelection()
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                    this.lastRange = {
                        "startContainer": range.startContainer,
                        "startOffset": range.startOffset,
                        "endContainer": range.endContainer,
                        "endOffset": range.endOffset
                    };
                    return true;
                }
            }
        }
        return false;
    }

    restoreRange(): void {
        if (this.lastRange) {
            var sel = window.getSelection();
            sel.removeAllRanges();
            var range = document.createRange();
            try {
                range.setStart(this.lastRange.startContainer, this.lastRange.startOffset);
                range.setEnd(this.lastRange.endContainer, this.lastRange.endOffset);
                sel.addRange(range);
            } catch (e) {
                // statements
                console.log(e);
            }
            this.textArea.trigger('focus');
        }
    }

    onClickCheckbox(e: MouseEvent): void {
        let el = e.target as HTMLElement;
        if (el.classList.contains('cs-memo-style-enumeration-empty')) {
            el.classList.remove('cs-memo-style-enumeration-empty');
            el.classList.add('cs-memo-style-enumeration-yes')
        } else if (el.classList.contains('cs-memo-style-enumeration-yes')) {
            el.classList.remove('cs-memo-style-enumeration-yes');
            el.classList.add('cs-memo-style-enumeration-no')
        } else if (el.classList.contains('cs-memo-style-enumeration-no')) {
            el.classList.remove('cs-memo-style-enumeration-no');
            el.classList.add('cs-memo-style-enumeration-empty')
        }

        this.notifyComponentChanged();
    }

    setCheckboxEventListenersForStyle(style: string): void {
        let elements = this.obj.getElementsByClassName(style);
        for (let i = elements.length - 1; i >= 0; i--) {
            elements[i].removeEventListener('click', this.checkboxClickFunction, false);
            elements[i].addEventListener('click', this.checkboxClickFunction, false);
        }
    }

    setCheckboxEventListeners(): void {
        this.setCheckboxEventListenersForStyle('cs-memo-style-enumeration-yes');
        this.setCheckboxEventListenersForStyle('cs-memo-style-enumeration-no');
        this.setCheckboxEventListenersForStyle('cs-memo-style-enumeration-empty');
    }

    formatBlockDiv(cssClass: string): void {
        const defaultCssClass = 'cs-memo-style-text';
        let linesCount = this.getSelectedLinesCount();
        if (linesCount <= 1) {
            this.setFocus();
            // Beim einzeilig nur die class austauschen.
            let node = this.getFocusParentNodeOfSelection() as HTMLElement;
            if (this.isRootNode(node)) {
                if (!assigned(node.firstChild)) {
                    // sollten wir noch keine Elemente im Rootnode haben, müssen wir updaten!
                    this.initMemo();
                    this.updateLines();
                }
                if (assigned(node.firstChild) && node.firstChild.nodeType == Node.ELEMENT_NODE) {
                    node = node.firstChild as HTMLElement;
                } else {
                    // hier ist ein Fehler passiert und wir springen vorsichtshalber raus! 
                    console.error('formatBlockDiv: cannot change divBlock, node is broken');
                    return;
                }
            }
            if (this.isInRootNode(node)) {
                // wenn wir z.b. bold sind und nochmal auf bold klicken, dann werden wir wieder ein normaler text
                if (node.classList.contains(cssClass)) {
                    cssClass = defaultCssClass;
                }
                this.clearCssClass(node);
                this.setCssClass(node, cssClass);
            }
        } else {
            // erstmal alle popups schließen 
            this.closeAllPopOvers();
            let nodeList = this.getSelectedParHtmlNodes(true);

            // wenn wir z.b. bold sind und nochmal auf bold klicken, dann werden wir wieder ein normaler text
            // dh...wir müssen erstmal schauen, ob wir unterschiedliche classen haben
            let usedClassesArray = [];
            let needToChangeToDefaultClass = false;
            for (let i = nodeList.length - 1; i >= 0; i--) {
                let nodeClasses = nodeList[i].classList.value.split(' '); // hier darf es eigentlich nur einen geben
                for (let j = 0; j < nodeClasses.length; j++) {
                    if (!usedClassesArray.includes(nodeClasses[j])) {
                        usedClassesArray.push(nodeClasses[j]);
                        needToChangeToDefaultClass = (nodeClasses[j] == cssClass);
                    }
                }
                // wenn wir mehr als zwei haben, können wir uns den rest sparen
                if (usedClassesArray.length > 1) {
                    needToChangeToDefaultClass = false;
                    break;
                }
            }

            if (usedClassesArray.length == 1 && needToChangeToDefaultClass) {
                cssClass = defaultCssClass;
            }

            // sonst nodes neu
            this.deleteSelectedNodes();
            for (let i = nodeList.length - 1; i >= 0; i--) {
                if (nodeList[i].innerHTML.trim() != '') {
                    this.insertHtmlAtNode('<div class="' + cssClass + '">' + nodeList[i].innerHTML + '</div>');
                } else {
                    this.insertHtmlAtNode('<div class="' + cssClass + '"><br></div>');
                }
            }
            this.clearSelection();
            this.updateLines();
            // wenn wir buttons haben, dann nochmal events initialisieren
            this.initUnitClick('#' + this.textAreaId + ' :button');
        }

        if (this.isInteractive) {
            this.setCheckboxEventListeners();
        }

        this.clearSavedRanges();

        this.notifyComponentChanged();
    }

    //https://stackoverflow.com/questions/16736680/get-caret-index-in-contenteditable-div-including-tags
    getCaretPositionEx(): number {
        let caretPosition = 0;
        let sel = window.getSelection();
        if (sel.rangeCount > 0) {
            let range = sel.getRangeAt(0);
            let preCaretRange = range.cloneRange();
            let tmp = document.createElement('div');
            let node = this.getFocusParentNodeOfSelection() as HTMLElement;
            preCaretRange.selectNodeContents(node);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            tmp.appendChild(preCaretRange.cloneContents());
            caretPosition = tmp.innerHTML.length;
        }
        return caretPosition;
    }

    getSelectedLinesCount(): number {
        // die lines-Anzahl wird per \n ermittelt
        let selectedtext = this.getSelectedParPlainContent();
        return selectedtext.split(/\r\n|\r|\n/g).length;
    }

    getSelectedParHtmlNodes(getFullNodesWithText: Boolean): Array<any> {
        // wir kriegen die documentFragments (nodes) zurück
        let sel, range;
        let return_documentFragment = new Array();
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.rangeCount) {
                range = sel.getRangeAt(0);
                if (getFullNodesWithText) {
                    // in Firefox bewirkt STRG+A das der RootContainer ausgewählt wird. 
                    // Das wollen wir nicht! Chrome macht es richtig
                    let startOffset = 0;
                    let startNode = range.startContainer;
                    if (this.isRootNode(startNode)) {
                        startNode = startNode.firstChild;
                    }
                    range.setStart(startNode, startOffset);
                    // in Firefox bewirkt STRG+A das der RootContainer ausgewählt wird. 
                    // Das wollen wir nicht! Chrome macht es richtig
                    let endOffset = 0;
                    let endNode = range.endContainer;
                    if (this.isRootNode(endNode)) {
                        endNode = endNode.lastChild;
                    }
                    // hier wird ein dummy tag eingefügt und wieder gelöscht, um den Endpoint der range zu ermitteln 
                    if (this.isRootNode(endNode.parentElement)) {
                        let guid = 'dummy' + Math.floor(Math.random() * 10000).toString();
                        $(endNode).append('<span id="' + guid + '"></span>');
                        let lastchild = $('#' + guid)[0];
                        range.setEnd(lastchild, endOffset);
                        $(lastchild).remove();
                    } else {
                        endOffset = endNode.textContent.length;
                        range.setEnd(endNode, endOffset);
                    }
                }
                let documentFragment = range.cloneContents();
                return_documentFragment = documentFragment.childNodes;
            }
        }
        return return_documentFragment;
    }

    getSelectedParPlainContent(): string {
        var sel, range, text = '';
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.rangeCount) {
                range = sel.getRangeAt(0);
                var rangeCopy = range.cloneRange();

                // in Firefox bewirkt STRG+A das der RootContainer ausgewählt wird. 
                // Das wollen wir nicht! Chrome macht es richtig
                var startOffset = 0;
                var startNode = range.startContainer;
                if (this.isRootNode(startNode)) {
                    startNode = startNode.firstChild;
                }
                rangeCopy.setStart(startNode, startOffset);
                // in Firefox bewirkt STRG+A das der RootContainer ausgewählt wird. 
                // Das wollen wir nicht! Chrome macht es richtig
                var endOffset = 0;
                var endNode = range.endContainer;
                if (this.isRootNode(endNode)) {
                    endNode = endNode.lastChild;
                }

                // hier wird ein dummy tag eingefügt und wieder gelöscht, um den Endpoint der range zu ermitteln 
                if (this.isRootNode(endNode.parentElement)) {
                    var guid = 'dummy' + Math.floor(Math.random() * 10000).toString();
                    $(endNode.parentElement).append('<span id="' + guid + '"></span>');
                    var lastchild = $('#' + guid)[0];
                    rangeCopy.setEnd(lastchild, endOffset);
                    $(lastchild).remove();
                } else if (this.isInRootNode(endNode.parentElement)) {
                    endOffset = endNode.textContent.length;
                    rangeCopy.setEnd(endNode, endOffset);
                }

                if (this.isFireFox() && (startNode == endNode)) {
                    text = startNode.textContent;
                } else {
                    // hier der Sonderfall -> Doppelklick erzeugt einen Fehler, wenn man eine weitere Zeile hat.
                    if (range.startContainer.parentNode.nextSibling == range.endContainer) {
                        text = range.startContainer.textContent;
                    } else {
                        text = window.getSelection().toString();
                    }
                }
                // sonderfall. Wenn jemand <br> einträgt sind uns die Zeilen egal
                if (startNode.nodeType == Node.TEXT_NODE && endNode.nodeType == Node.TEXT_NODE) {
                    if (startNode.parentNode == endNode.parentNode) {
                        // wir geben hier einen leeren Text zurück.
                        // alternativ könnten wir auch \n replacen, aber das brauchen wir nicht
                        // text = text.replaceAll('\n', '');
                        text = '';
                    }
                }
            }
        }
        return text;
    }

    removeParentNode(node: ChildNode) {
        // wenn wir root sind darf das nicht!
        if ($(node) && !this.isRootNode(node)) {
            if (node.parentElement && !this.isRootNode(node.parentElement)) {
                node.parentElement.remove();
            } else {
                node.remove();
            }
        }
    }

    createNodeFromHtml(html: string): DocumentFragment {
        let range = document.createRange();
        let documentFragment = range.createContextualFragment(html);
        return documentFragment;
    }

    insertHtmlAtNode(html: string): void {
        if (window.getSelection && window.getSelection().getRangeAt) {
            let sel = window.getSelection();
            let range = sel.getRangeAt(0);
            if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                let documentFragment = range.createContextualFragment(html);
                range.insertNode(documentFragment);
            }
        }
    }

    insertNodeAtCursor(node: Node): void {
        if (window.getSelection && window.getSelection().getRangeAt) {
            let range = window.getSelection().getRangeAt(0);
            if (!this.isRootNode(range.commonAncestorContainer)) {
                range.insertNode(node);
            }
        }
    }

    insertNodeAfterCurrentNode(node: Node): void {
        if (window.getSelection && window.getSelection().getRangeAt) {
            let currentNode = this.getFocusParentNodeOfSelection();
            if (!this.isRootNode(currentNode) && this.isInRootNode(currentNode as Element)) {
                currentNode.parentNode.insertBefore(node, currentNode.nextSibling);
            } else {
                // wenn wir Firefox sind, müssem wir hier anders einfügen
                if (this.isFireFox() && this.isRootNode(currentNode)) {
                    let sel = window.getSelection();
                    if (sel.rangeCount) {
                        let range = sel.getRangeAt(0);
                        range.deleteContents();
                        range.insertNode(node);
                    }
                } else {
                    this.insertNodeNow(node);
                }
            }
        }
    }

    insertTextAtCurrentPosition(text: string): void {
        if (window.getSelection && window.getSelection().getRangeAt) {
            let currentNode = this.getFocusParentNodeOfSelection();

            let sel = window.getSelection();
            let range = null;
            if (sel.rangeCount) {
                range = sel.getRangeAt(0);
            }

            if (this.isRootNode(currentNode) && currentNode.childNodes.length >= 1) {
                currentNode = currentNode.childNodes[0];
                range.setStart(currentNode, 0);
                range.setEnd(currentNode, 0);
            }

            if (!this.isRootNode(currentNode) && this.isInRootNode(currentNode as Element)) {
                // bei doppelklick müssen wir den Sonderfall abdecken
                if (range.startContainer.parentNode.nextSibling == range.endContainer) {
                    range.setEnd(range.startContainer, 0);
                    range.startContainer.parentElement.textContent = '';
                    range.insertNode(document.createTextNode(text));
                } else {
                    range.deleteContents();
                    range.insertNode(document.createTextNode(text));
                }
            } else {
                let node = this.createNewNodeDiv('cs-memo-style-text', text);
                if (this.isFireFox() && this.isRootNode(currentNode)) {
                    if (sel.rangeCount) {
                        let range = sel.getRangeAt(0);
                        range.deleteContents();
                        range.insertNode(node);
                    }
                } else {
                    this.insertNodeNow(node);
                }
            }
        }
    }

    insertNodeNow(node: Node): void {
        // UPS // wilde Casterei
        var focusNode = this.getFocusNodeOfSelection() as unknown as ParentNode;
        if (this.isRootNode(focusNode as unknown as Node)) {
            //wir sind ganz oben und an erster Stelle
            if (focusNode.childElementCount == 1) {
                let currentNode = (focusNode as unknown as Node).childNodes[0];
                if (this.isInRootNode(currentNode as Element)) {
                    currentNode.parentNode.insertBefore(node, currentNode);
                }
            } else {
                let currentNode = this.lastRange.startContainer;
                // wenn wir TEXT sind, müssen wir das Parent Div nehmen
                if (currentNode.nodeType == Node.TEXT_NODE) {
                    currentNode = currentNode.parentNode;
                }
                // falls es die Parent node nicht mehr gibt...
                if (!this.isRootNode(currentNode) && currentNode.isConnected && this.isInRootNode(currentNode as Element)) {
                    currentNode.parentNode.insertBefore(node, currentNode.nextSibling);
                } else {
                    currentNode = this.previousElementSibling;
                    if (currentNode != null && currentNode.isConnected && this.isInRootNode(currentNode as Element)) {
                        if (this.isRootNode(currentNode)) {
                            currentNode.insertBefore(node, currentNode.firstChild);
                        } else {
                            currentNode.parentNode.insertBefore(node, currentNode.nextSibling);
                        }
                    } else {
                        //... versuchen wir die Nachbarn -> z.B. bei listen
                        currentNode = focusNode.lastElementChild;
                        if (this.isInRootNode(currentNode as Element)) {
                            currentNode.parentNode.insertBefore(node, currentNode.nextSibling);
                        }
                    }
                }
            }
        }
        // todo nach einfügen dahinter springen
    }

    /**************************************************************************/
    copySelection(event: ClipboardEvent): void {
        if (window.getSelection) {
            let sel = window.getSelection()
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                    let nodeStart = range.startContainer;
                    let clipboard = event.clipboardData;
                    let textToCopy = String2HTMLEntity(sel.toString());
                    clipboard.setData('text', sel.toString());
                    clipboard.setData('text/html', '<div class="cs-memo-style-text">' + textToCopy + '</div>');
                    if (nodeStart.parentElement.innerHTML.trim() == sel.toString()) {
                        let cssClass = nodeStart.parentElement.className;
                        clipboard.setData('text/html', '<div class="' + cssClass + '">' + textToCopy + '</div>');
                    }
                }
            }
        }
    }

    copySelectedNodes(event: ClipboardEvent): void {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                    // wir holen alle nodes in der Range
                    let nodeList = this.getSelectedParHtmlNodes(false);
                    let clipboardText = '',
                        clipboardHTML = '';

                    for (let i = 0; i < nodeList.length; i++) {
                        // hier nur html nodes, keine text-nodes
                        // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
                        if (nodeList[i].nodeType == Node.ELEMENT_NODE) {
                            clipboardText += nodeList[i].innerText + '\r\n';
                            clipboardHTML += nodeList[i].outerHTML;
                        }
                    }
                    let clipboard = event.clipboardData;
                    clipboard.setData('text', clipboardText);
                    clipboard.setData('text/html', clipboardHTML);
                }
            }
        }
    }

    /**************************************************************************/

    deleteSelection(): void {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                    let nodeStart = range.startContainer;
                    range.deleteContents();
                    // wenn der zu löschende Text das ganze node ist, löschen wir auch das node
                    if (nodeStart.parentElement.innerHTML.trim() == '') {
                        this.removeParentNode(nodeStart as ChildNode);
                    } else if (this.isInRootNode(nodeStart as Element) && !this.isRootNode(nodeStart)) {
                        // wir verschachteln, damit es leserlicher wird
                        // erst wenn wir ein HTML Element sind, gibt es innerHTml.
                        if (nodeStart.nodeType == Node.ELEMENT_NODE && (nodeStart as Element).innerHTML.trim() == '') {
                            this.removeParentNode(nodeStart as ChildNode);
                        }
                    }
                }
            }
        }
    }

    deleteSelectedNodes(): void {
        if (window.getSelection) {
            var sel = window.getSelection();
            if (sel.rangeCount) {
                var range = sel.getRangeAt(0);
                if (this.isInRootNode(range.commonAncestorContainer as Element)) {
                    var nodeStart = range.startContainer;
                    var nodeEnd = range.endContainer;
                    range.deleteContents();

                    if (nodeStart.nodeType == Node.TEXT_NODE && nodeStart.parentElement.innerHTML.trim() == '') {
                        this.removeParentNode(nodeStart as ChildNode);
                    } else if (nodeStart.nodeType == Node.ELEMENT_NODE && (nodeStart as Element).innerHTML.trim() == '') {
                        this.removeParentNode(nodeStart as ChildNode);
                    }
                    if (nodeEnd.nodeType == Node.TEXT_NODE && nodeEnd.parentElement.innerHTML.trim() == '') {
                        this.removeParentNode(nodeEnd as ChildNode);
                    } else if (nodeEnd.nodeType == Node.ELEMENT_NODE && (nodeEnd as Element).innerHTML.trim() == '') {
                        this.removeParentNode(nodeEnd as ChildNode);
                    }
                }
            }
        }
    }

    /**************************************************************************/

    clearSelection(): void {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                window.getSelection().removeAllRanges();
            }
        }
    }

    isCaretInMemo(): boolean {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.rangeCount) {
                let range = sel.getRangeAt(0);
                // bei Strg + A reagiert der FireFox anders
                if (this.isFireFox() && this.isRootNode(range.commonAncestorContainer)) {
                    return true;
                }
                // das gilt für firefox und chrome 
                if (!this.isRootNode(range.commonAncestorContainer) && this.isInRootNode(range.commonAncestorContainer as Element)) {
                    return true;
                }
            }
        }
        return false;
    }

    getSelectedText(): string {
        let text = '';
        if (window.getSelection) {
            text = window.getSelection().toString();
        }
        return text;
    }

    setCssClass(node: Node, newClass: string): void {
        if ($(node) && node.nodeType == Node.ELEMENT_NODE) {
            if (newClass.trim() != '') {
                $(node).addClass(newClass);
            }
        }
    }

    clearCssClass(node: Node): void {
        if ($(node) && node.nodeType == Node.ELEMENT_NODE) {
            $(node).removeClass().filter('[class=""]').removeAttr('class');
        }
    }

    updateLines(): void {
        if (this.textArea.children().length > 0) {
            let textObjects = this.textArea.children();
            for (let i = 0; i < textObjects.length; i++) {
                if ($(textObjects[i]).children().length > 0) {
                    $(textObjects[i]).children().each(function (index, value) {
                        let textObject = $(value);
                        if (textObject.is('span') || textObject.is('b') || textObject.is('font')) {
                            textObject.contents().unwrap();
                        } else {
                            textObject.removeAttr('style');
                        }
                    });
                }
            }
        }
    }

    getAnchorParentNodeOfSelection(): Node {
        let node = null;
        try {
            let anchorNode = window.getSelection().anchorNode;
            if (anchorNode != null && anchorNode.parentNode != null) {
                // @todo rekursiv!
                if (this.isRootNode(anchorNode)) {
                    node = anchorNode;
                } else if (this.isRootNode(anchorNode.parentNode)) {
                    node = anchorNode;
                } else {
                    let node1 = anchorNode.parentNode;
                    if (this.isRootNode(node1.parentNode)) {
                        node = node1;
                    } else {
                        node = node1.parentNode;
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
        return node;
    }

    getFocusNodeOfSelection(): Node {
        let node = null;
        try {
            node = window.getSelection().focusNode;
        } catch (err) {
            console.log(err);
        }
        return node;
    }

    getFocusParentNodeOfSelection(): Node {
        let node = null;
        try {
            let selection = window.getSelection();
            let focusnode = selection.focusNode;
            if (focusnode != null && focusnode.parentNode != null) {

                // @todo rekursiv!
                if (this.isRootNode(focusnode)) {
                    node = focusnode;
                } else if (this.isRootNode(focusnode.parentNode)) {
                    node = focusnode;
                } else {
                    let node1 = focusnode.parentNode;
                    if (this.isRootNode(node1.parentNode)) {
                        node = node1;
                    } else {
                        node = node1.parentNode;
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
        return node;
    }

    deleteTextWithNodes(): void {
        var linesCount = this.getSelectedLinesCount();
        if (linesCount > 1) {
            this.deleteSelectedNodes();
        } else {
            this.deleteSelection();
        }

        this.notifyComponentChanged();
    }

    improveSelectedText(action: string): void {
        let aText = this.getSelectedText();

        if (aText === '') {
            let html = this.textArea.text();
            aText = removeControlCodes(html);
        }

        this.notifyComponentChanged();

        WebCompEventHandlerAsync('OnRequestAI', this.id, null, action + ';' + aText);
    }

    override execAction(action: string, params: string) {
        switch (action) {
            case 'insertcsunit':
                // hier kann es nur noch hyperlink geben
                this.hasCsUnit('hyperlink', '', null);
                break;
            case 'openPopDown':
                this.openPopDown(true);
                break;
            case 'trash':
                this.clearSavedRanges(); // erstmal alles resetten
                this.saveSibling(); // dann die nachbarn speichern
                this.deleteTextWithNodes();
                break;
            case 'aiSetText':
                if (this.getSelectedText() !== '')
                    this.insertTextAtCurrentPosition(params)
                else {
                    this.textArea.text(params);
                }

                this.notifyComponentChanged();
                break;
            case 'aiAddText':
                if (this.getSelectedText() !== '')
                    // this.insertTextAtCurrentPosition(params)
                    // replace after marked text
                    // erstmal so wie bei nicht markiertem text handeln (wider erwartetem verhalten)
                    this.textArea.text(this.textArea.text() + params);
                else
                    this.textArea.text(this.textArea.text() + params);

                this.notifyComponentChanged();
                break;
            case 'aiPrompt':
                this.improveSelectedText(action);
                break;
            case 'aiPromptID':
                this.improveSelectedText(action);
                break;
            case 'aiImprove':
                this.improveSelectedText(action);
                break;
            case 'aiShorten':
                this.improveSelectedText(action);
                break;
            case 'aiElaborate':
                this.improveSelectedText(action);
                break;
            case 'aiContinue':
                this.improveSelectedText(action);
                break;
            case 'aiAnswer':
                this.improveSelectedText(action);
                break;
            case 'aiBulletToText':
                this.improveSelectedText(action);
                break;
            case 'aiTextToBullet':
                this.improveSelectedText(action);
                break;
            case 'check_yes':
                this.formatBlockDiv('cs-memo-style-enumeration-yes');
                break;
            case 'check_no':
                this.formatBlockDiv('cs-memo-style-enumeration-no');
                break;
            case 'check':
                this.formatBlockDiv('cs-memo-style-enumeration-empty');
                break;
            case 'text':
                this.formatBlockDiv('cs-memo-style-text');
                break;
            case 'caption':
                this.formatBlockDiv('cs-memo-style-caption');
                break;
            case 'headline':
                this.formatBlockDiv('cs-memo-style-headline');
                break;
            case 'headline2':
                this.formatBlockDiv('cs-memo-style-headline2');
                break;
            case 'headline3':
                this.formatBlockDiv('cs-memo-style-headline3');
                break;
            case 'insertorderedlist':
                this.formatBlockDiv('cs-memo-style-enumeration-number');
                break;
            case 'insertunorderedlist':
                this.formatBlockDiv('cs-memo-style-enumeration-item');
                break;
            case 'undo':
                // this.docExec('undo', '');
                // MsgNotify('undo ist noch ein Experiment', mtInformation);
                break;
            case 'redo':
                // this.docExec('redo', '');
                // MsgNotify('redo ist noch ein Experiment', mtInformation);
                break;
            /* case 'paragraph':
                 if (this.textArea.hasClass('cs-memo-par')) {
                     this.textArea.removeClass('cs-memo-par');
                 } else {
                     this.textArea.addClass('cs-memo-par');
                 }

                 break;*/
            /* case 'debugnow': // debug helper for testpage
                if (this.textAreaElement.classList.contains('cs-memo-debug')) {
                    let debugLogArea = document.getElementById('cs-memo-debug-log');
                    //  debugLogArea.innerText = String('debug');
                    // debugLogArea.innerText = String(this.getSelectedLinesCount());
                }
                break;
                */
            default:
                super.execAction(action, params);
                break;
        }
    }

    execEventAction(action: string, event: Event): void {
        switch (action) {
            case 'update':
                // wenn mit backspace und entfernen alles gelöscht wurde
                let currentNode = this.getFocusNodeOfSelection();
                if (this.isRootNode(currentNode) || this.textArea.html().trim() == '') {
                    this.initMemo();
                }

                if (event instanceof KeyboardEvent) {
                    if (event.key == 'Backspace' || event.key == 'Delete') {
                        let currentHTMLElement = currentNode as HTMLElement;
                        let prevSilbingElement = null;
                        let newClassName = '';

                        // sonderfall: wir sind zb. eine Überschrift-Zeile sind löschen diese Zeile, dann soll die unterliegende Zeile
                        // die alte Klasse behalten, aber nur wenn die Überschrift-Zeile leer war
                        // wir dürfen das nur bei keydown machen und es müssen auch mehrere Elemente vorhanden sein
                        if (event.type == 'keydown' && this.textAreaElement.childElementCount > 1) {
                            if (event.key == 'Backspace') {
                                let sel = window.getSelection();
                                if (sel.type == 'Caret') {
                                    if (currentHTMLElement.nodeType == Node.TEXT_NODE) {
                                        currentHTMLElement = currentHTMLElement.parentElement;
                                    }
                                    if (assigned(currentHTMLElement.previousElementSibling)) {
                                        prevSilbingElement = currentHTMLElement.previousElementSibling;
                                        if (prevSilbingElement.innerHTML == '' || prevSilbingElement.innerHTML == '<br>') {
                                            if (currentHTMLElement.className != prevSilbingElement.className) {
                                                newClassName = currentHTMLElement.className;
                                            }
                                        }
                                    }
                                }
                            } else if (event.key == 'Delete') {
                                let sel = window.getSelection();
                                if (sel.type == 'Caret') {
                                    if (currentHTMLElement.innerHTML == '' || currentHTMLElement.innerHTML == '<br>') {
                                        if (assigned(currentHTMLElement.nextElementSibling)) {
                                            if (currentHTMLElement.className != currentHTMLElement.nextElementSibling.className) {
                                                newClassName = currentHTMLElement.nextElementSibling.className;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // jetzt erstmal update lines 
                        this.updateLines();
                        // wenn in newClassName was drinsteht, müssen wir es setzen, denn der Sonderfall ist eingetroffen
                        if (newClassName != '' && event.type == 'keydown') {
                            if (event.key == 'Delete') {
                                let newNode = this.getFocusNodeOfSelection();
                                this.clearCssClass(newNode);
                                this.setCssClass(newNode, newClassName);
                            } else if (event.key == 'Backspace' && assigned(prevSilbingElement)) {
                                this.clearCssClass(prevSilbingElement);
                                this.setCssClass(prevSilbingElement, newClassName);
                            }
                        }

                    }
                }

                if (this.isInteractive) {
                    // ups, hier werden immer neue Eventlistener gesetzt, aber nicht gelöscht, falls vorhanden
                    this.setCheckboxEventListeners();
                }

                break;
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        // bevor wir was zum Server senden, machen wir noch ein update
        this.updateLines();
        let html = this.textArea.html();
        if (html == '<div class="cs-memo-style-text"><br></div>') {
            html = '';
        }
        properties.push([this.id, 'Text', removeControlCodes(html)]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Text':
                // wir setzten den text nur neu, wenn er sich geändert hat
                if (value != this.textArea.html()) {
                    this.textArea.html(value);
                    this.initUnitClick('#' + this.textAreaId + ' :button');
                    this.execEventAction('update', null);
                    // wenn wir hier neuladen, müssen wir den Zustand anpassen
                    // this.clearSavedRanges(); 
                    // this.textArea.trigger('focus');            
                }
                break;
            case 'EnabledReadOnly':
                if (value == '00') {
                    this.setEnabledReadOnly(false, false);
                } else if (value == '01') {
                    this.setEnabledReadOnly(false, true);
                } else if (value == '10') {
                    this.setEnabledReadOnly(true, false);
                } else if (value == '11') {
                    this.setEnabledReadOnly(true, true);
                }
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
            case 'MaxLength':
                this.maxLength = parseInt(value);
                break;
        }
    }

    setEnabledReadOnly(enabled: boolean, readOnly: boolean): void {
        // Soll-Verhalten:
        // Disabled -> Man darf weder editieren noch auf Hyperlinks drücken
        // Readonly -> Man darf nicht editieren aber noch auf Hyperlinks drücken

        let disabled = !enabled;
        // disabled
        this.textAreaElement.classList.toggle('disabled', disabled);
        if (disabled) {
            this.textAreaElement.setAttribute('disabled', 'disabled');
        } else {
            this.textAreaElement.removeAttribute('disabled');
        }

        this.buttonToolbar?.find('.btn')?.toggleClass('disabled', disabled);
        // readonly        
        this.buttonToolbar?.find('.btn')?.toggleClass('disabled', disabled);
        this.textAreaElement.classList.toggle('readOnly', readOnly);
        this.textAreaElement.toggleAttribute('readonly', readOnly);
        this.buttonToolbarElement?.classList?.toggle('d-none', readOnly);
        // contenteditable
        this.setContenteditable(!(disabled || readOnly));
    }

    setContenteditable(setter: boolean): void {
        this.textAreaElement.toggleAttribute('contenteditable', setter);
    }

    insertCsUnit(node: Node): void {
        var linesCount = this.getSelectedLinesCount();
        if (window.getSelection) {
            var sel = window.getSelection()
            if (sel.rangeCount) {
                var range = sel.getRangeAt(0);

                try {
                    if (linesCount > 1) {
                        this.deleteSelectedNodes();
                        this.insertTextAtCurrentPosition(($(node)[0] as Element).outerHTML);
                    } else {
                        range.deleteContents();
                        // wenn wir hier nur ein <br> drin haben, dann wollen wir das nicht
                        if ((range.commonAncestorContainer as Element).innerHTML == '<br>') {
                            $(range.commonAncestorContainer).html('');
                        }
                        this.insertNodeAtCursor(node);
                    }
                    // hier setzen wir den cursor ans ende
                    range.setStartAfter(node);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                    // im eventlistener bekannt machen
                    this.initUnitClick(node);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }

    createNewNodeDiv(className: string, innerHTML: string): HTMLDivElement {
        let newNode = document.createElement('div');
        newNode.className = className;
        newNode.innerHTML = innerHTML;
        return newNode;
    }

    createNewNodeInput(dataSet: { caption: string; class: string; oidbase64: string; href: string; key: string; captionraw: string }): HTMLInputElement {
        var newNode = document.createElement('input');
        newNode.type = 'button';
        newNode.value = dataSet.captionraw;
        newNode.className = 'btn btn-link';
        newNode.dataset.class = 'cs-memo-data-' + dataSet.class;
        newNode.dataset.oidbase64 = dataSet.oidbase64;
        newNode.dataset.href = dataSet.href;
        newNode.dataset.key = dataSet.key;
        return newNode;
    }

    hasCsUnit(type: string, database64: string, node: HTMLInputElement): boolean {
        this.autoFocus();
        if (this.saveRange()) { //aktuelle position merken
            this.saveSibling();
            this.workOffDialog = this.workOffDialogFuncHyperlink(type, database64);
            this.workOffDialog.then(function (dataSet) {

                if (node == null) {
                    // wir legen eine neue an
                    this.restoreRange();
                    this.initMemo();
                    var newNode = this.createNewNodeInput(dataSet);
                    this.insertCsUnit(newNode);
                } else {
                    node.value = dataSet.caption;
                    node.dataset.captionraw = dataSet.caption;
                    node.dataset.oidbase64 = dataSet.oidbase64;
                    node.dataset.href = dataSet.href;
                    node.dataset.key = dataSet.key;
                }
                this.notifyComponentChanged();
                return true;
            }.bind(this)).catch(function (error) {
                this.restoreRange();
            }.bind(this));
        } else {
            // hier darf der eigentlich nie rein
            console.error('getCsUnit Insert Error');
        }

        return false;
    }

    workOffDialogFuncHyperlink(type: string, database64: string): Promise<any> {
        return new Promise(function (resolve, reject) {
            try {
                var memoDialog = new HyperlinkTcsDialog(this.id);
                memoDialog.database64 = database64;
            } catch (err) {
                MsgNotify('Type-Klasse not found: ' + type, MessageType.Danger);
                console.error(err);
            }

            if (!(memoDialog instanceof Object)) {
                reject('Type-Klasse not found');
            }
            var resolved = memoDialog.show();
            resolved.then(function (dataSet) {
                resolve(dataSet);
            })
                .catch(function (error) {
                    console.error(error);
                    reject(error);
                });

        }.bind(this));
    }

    setRecognizedText(text: string): void {
        this.setFocus();
        this.insertTextAtCurrentPosition(text + ' ');
        // Wir sind fertig, also setzen wir den Cursor ans Ende und packen ein Leerzeichen dahinter
        window.getSelection().collapseToEnd();
    }


    setRecognizingText(text: string): void {
        this.setFocus();
        this.insertTextAtCurrentPosition(text);
    }
}