
export class SyncImageLoader {
    public static instance = new SyncImageLoader();

    private Sources = [];
    private Current = null;

    private loadNext() {
        if (!this.Current && this.Sources.length > 0) {
            this.Current = this.Sources.shift();

            this.Current.elem.addEventListener('load', () => {
                this.Current = null;
                this.loadNext();
            });
            this.Current.elem.addEventListener('error', () => {
                this.Current = null;
                this.loadNext();
            });

            this.Current.elem.setAttribute('src', this.Current.source);
        }
    };

    addImage(image: HTMLImageElement, imageSource: string) {
        this.Sources.push({
            elem: image,
            source: imageSource
        });
        this.loadNext();
    }

    addSource(imageId: string, imageSource: string) {
        var image = document.getElementById(imageId) as HTMLImageElement;
        if (image) {
            this.addImage(image, imageSource);
        } else {
            throw 'Id: ' + imageId + ' is not an HTMLImageElement';
        }
    }
}