'use strict';

import { ArcElement, PieController } from 'chart.js';
import { merge } from 'chart.js/helpers';

export class CustomControllerPie extends PieController {

    draw() {
        super.draw();
    }
}

CustomControllerPie.id = 'customControllerPie';
CustomControllerPie.defaults = merge(PieController.defaults, { dataElementType: ArcElement.id });
