import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeBorderOpacityAction extends TCustomTutorialShapeAction {
    oldOpacity: number;
    newOpacity: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldOpacity = this.refElement.borderOpacity;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeBorderOpacity;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldOpacity = obj.oldOpacity;
        this.newOpacity = obj.newOpacity;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldOpacity = this.oldOpacity;
        obj.newOpacity = this.newOpacity;

        return obj;
    }

    setValue(newOpacity: number): void {
        this.newOpacity = newOpacity;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setBorderOpacity(this.newOpacity);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeBorderOpacityAction(this.refElement, this.svgEditor);
        result.setValue(this.oldOpacity);

        return result;
    }
}