import { TEdgeCaptionAction } from '../Actions/TEdgeCaptionAction';
import { TNodeCaptionAction } from '../Actions/TNodeCaptionAction';
import { HorizontalAlignment, TBaseGraphObject, VerticalAlignment } from '../TBaseGraphObject';
import { getIDSuffix, SVGNS } from '../Utils/TProcessEditorIDUtils';

export enum FontStyles { fsBold, fsItalic, fsUnderline, fsStrikeOut };

export class TTextBase {
    id: string;
    parentElement: TBaseGraphObject;
    outerDiv: HTMLElement;
    midDiv: HTMLElement;
    innerDiv: HTMLElement;
    svgElement: SVGForeignObjectElement;

    constructor(parentElement: TBaseGraphObject) {
        this.id = '';
        this.svgElement = null;
        this.outerDiv;
        this.midDiv;
        this.innerDiv;

        // die Position kennt der ShapeText durch sein ParentElement (Shape / Edge)
        this.parentElement = parentElement;
    }

    createCaption() {
        this.id = getIDSuffix(this.parentElement.id, 'text');

        this.svgElement = document.createElementNS(SVGNS, 'foreignObject');
        this.svgElement.setAttribute('id', this.id);
        this.svgElement.setAttribute('pointer-events', 'none');
        this.svgElement.setAttribute('width', '100%');
        this.svgElement.setAttribute('height', '100%');
        this.svgElement.classList.add('noselect');

        this.parentElement.editor.editorSVG.appendChild(this.svgElement);

        this.outerDiv = document.createElement('div');
        this.outerDiv.setAttribute('id', getIDSuffix(this.parentElement.id, 'textdiv'));
        this.outerDiv.classList.add('divAussen');

        this.midDiv = document.createElement('div');
        this.midDiv.classList.add('divMitte');

        this.innerDiv = document.createElement('div');
        this.innerDiv.classList.add('divInnen');
        this.innerDiv.style.color = this.parentElement.fontColor.hex();
        this.innerDiv.innerText = this.parentElement.getCaption();

        this.svgElement.appendChild(this.outerDiv);
        this.outerDiv.appendChild(this.midDiv);
        this.midDiv.appendChild(this.innerDiv);

        this.innerDiv.addEventListener('focusout', event => {
            let captionAction: TNodeCaptionAction | TEdgeCaptionAction;

            if (this.innerDiv.classList.contains('cs-edit-shape-caption')) {
                captionAction = new TNodeCaptionAction(this.parentElement as any, this.parentElement.editor);
                captionAction.setValue(this.parentElement.captionElement.innerDiv.innerText, (this.parentElement as any).hasMoreInformation);
            }
            else if (this.innerDiv.classList.contains('cs-edit-edge-caption')) {
                captionAction = new TEdgeCaptionAction(this.parentElement as any, this.parentElement.editor);
                captionAction.setValue(this.parentElement.captionElement.innerDiv.innerText);
            }

            this.parentElement.editor.actionList.addClientAction(captionAction);
            captionAction.performAction();

            this.innerDiv.removeAttribute('contenteditable');
            this.innerDiv.classList.remove('cs-edit-shape-caption', 'cs-edit-edge-caption');
            this.parentElement.editor.elementCaptionEditLock = false;
        });

        this.innerDiv.addEventListener('keydown', event => {
            // Wir erlauben keine Umbrüche in Texten
            if (event.key === 'Enter') {
                event.preventDefault();
                this.innerDiv.blur();
            }
        });

        this.repaintCaption();
    }

    deleteCaption() {
        if (document.getElementById(this.id) != null) {
            document.getElementById(this.parentElement.editor.svgID).removeChild(document.getElementById(this.id));
        }
    }

    repaintCaption() {
        const FONT_PADDING = 2;
        let fontSize: (element: HTMLElement) => number = (element: HTMLElement) => parseFloat(window.getComputedStyle(element, null).getPropertyValue('font-size'));

        let baseTop = this.parentElement.boundingRect.top;
        switch (this.parentElement.captionVertAlignment) {
            case VerticalAlignment.taAlignTop:
                baseTop += fontSize(this.innerDiv);
            // kein Break, taAlignOuterTop muss mit addiert werden!
            case VerticalAlignment.taAlignOuterTop:
                baseTop += FONT_PADDING;
                break;
            case VerticalAlignment.taAlignBottom:
                baseTop += - fontSize(this.innerDiv)
            // kein Break, taAlignOuterBottom muss mit addiert werden!
            case VerticalAlignment.taAlignOuterBottom:
                baseTop += this.parentElement.boundingRect.bottom - this.parentElement.boundingRect.top - FONT_PADDING;
                break;
            case VerticalAlignment.taVerticalCenter:
                baseTop += ((this.parentElement.boundingRect.bottom - this.parentElement.boundingRect.top) / 2);
                break;
        };

        this.outerDiv.style.width = (this.parentElement.boundingRect.right - this.parentElement.boundingRect.left) + 'px';
        this.outerDiv.style.height = '1px';
        this.outerDiv.style.paddingTop = `${baseTop}px`;
        this.outerDiv.style.marginLeft = this.parentElement.boundingRect.left + 'px';

        this.innerDiv.style.color = this.parentElement.fontColor.hex();
        this.setFontStyles();

        // alignment und vertalignment
        this.outerDiv.classList.toggle('alignCenter', this.parentElement.captionHorzAlignment === HorizontalAlignment.taCenter);
        this.outerDiv.classList.toggle('alignLeft', this.parentElement.captionHorzAlignment === HorizontalAlignment.taLeftJustify);
        this.outerDiv.classList.toggle('alignRight', this.parentElement.captionHorzAlignment === HorizontalAlignment.taRightJustify);

        this.outerDiv.classList.toggle('vAlignCenter', this.parentElement.captionVertAlignment === VerticalAlignment.taVerticalCenter);
        this.outerDiv.classList.toggle('vAlignTop', [VerticalAlignment.taAlignTop, VerticalAlignment.taAlignOuterTop].includes(this.parentElement.captionVertAlignment));
        this.outerDiv.classList.toggle('vAlignBottom', [VerticalAlignment.taAlignBottom, VerticalAlignment.taAlignOuterBottom].includes(this.parentElement.captionVertAlignment))

        this.innerDiv.classList.toggle('noWordWrap', !this.parentElement.wordWrap);
    }

    setFontStyles() {
        if (FontStyles.fsBold in this.parentElement.fontStyles)
            this.innerDiv.style.fontWeight = 'bold';
        if (FontStyles.fsItalic in this.parentElement.fontStyles)
            this.innerDiv.style.fontStyle = 'italic';
        if (FontStyles.fsUnderline in this.parentElement.fontStyles)
            this.innerDiv.style.textDecoration = 'underline';
        if (FontStyles.fsStrikeOut in this.parentElement.fontStyles)
            this.innerDiv.style.textDecoration = 'line-through';
    }
}