import { assigned } from "../../utils/helper";
import { MessageType, MsgNotify } from "./msgnotify";

export function copyToClipboardEx(text: string, successMsg: string): void {
    if (text.trim() == '') {
        return;
    }
    // wir speichern erstmal den Text in die zwischenablage
    if (copyToClipboard(text)) {
        // und nun geben wir eine meldung aus
        if (successMsg.trim() !== '') {
            MsgNotify(successMsg, MessageType.Success);
        }
    }
}


function copyToClipboard(text: string): boolean {
    let result = false;

    if (text.trim() == '') {
        return result;
    }

    /*Wir merken uns den aktuellen Fokus*/
    const focusedElement = document.activeElement as HTMLElement;

    /*Neue temporäre TextArea anlegen und mit dem Text füllen*/
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '-100';
    textArea.style.left = '-100';
    textArea.value = text;
    document.body.appendChild(textArea);

    /*Inhalt der TextArea auswählen*/
    textArea.select();
    textArea.setSelectionRange(0, 99999); /*For mobile devices*/

    /*KOPIEREN!*/
    result = document.execCommand('copy');
    /*Temporäre TextArea aufräumen*/
    document.body.removeChild(textArea);

    if (assigned(focusedElement)) {
        focusedElement.focus();
    }

    return result;
}

function getTextByComponent(element: Element): string {
    if (!assigned(element)) {
        return '[COMPONENT UNDEFINED]';
    }

    if (element instanceof HTMLInputElement) {
        let compType = $(element).attr('type');
        if (compType === 'text') {
            return element.value;
        }
    }

    return $(element).text();
}

function copyCompToClipboard(id: string): boolean {
    let text = getTextByComponent(document.getElementById(id));
    return copyToClipboard(text);
}

/* --- Copy-Funktionen die eine Bestätigungs-Message ausgeben --- */
function showCopyResult(result: boolean): void {
    let type = MessageType.Danger;
    let msg = 'Kopieren fehlgeschlagen!';

    if (result) {
        type = MessageType.Success;
        msg = 'Kopiert!';
    }

    MsgNotify(msg, type);
}

export function copyToClipboardMsg(text: string): void {
    /* Kopiert den Text in die Zwischenablage und gibt zusätzlich eine Bestätigungs-Notification aus! */
    showCopyResult(copyToClipboard(text));
}

export function copyCompToClipboardMsg(id: string): void {
    /* Kopiert den Text der Komponente mit der übergebenen ID in die Zwischenablage und gibt zusätzlich eine Bestätigungs-Notification aus! */
    showCopyResult(copyCompToClipboard(id));
}