import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TNodeCaptionAction extends TCustomNodeAction {
    oldCaption: string;
    newCaption: string;
    newHasMoreInformation: boolean;
    oldHasMoreInformation: boolean;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patShapeCaption;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldCaption = obj.oldCaption;
        this.newCaption = obj.newCaption;
        this.newHasMoreInformation = obj.newHasMoreInformation;
        this.oldHasMoreInformation = obj.oldHasMoreInformation;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldCaption = this.oldCaption;
        obj.newCaption = this.newCaption;
        obj.newHasMoreInformation = this.newHasMoreInformation;
        obj.oldHasMoreInformation = this.oldHasMoreInformation;

        return obj;
    }

    setValue(newCaption: string, hasMoreInformation: boolean): void {
        if (assigned(this.refElement)) {
            this.oldCaption = this.refElement.getCaption();
            this.oldHasMoreInformation = (this.refElement as any).hasMoreInformation;
        }
        else {
            this.oldCaption = '';
            this.oldHasMoreInformation = false;
        }

        this.newCaption = newCaption;
        this.newHasMoreInformation = hasMoreInformation;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;

        this.refElement.setHasMoreInformation(this.newHasMoreInformation);
        this.refElement.setCaption(this.newCaption);
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeCaptionAction(this.refElement, this.svgEditor);
        result.setValue(this.oldCaption, this.oldHasMoreInformation);

        return result;
    }
}
