import { TWebComponent } from '../../base/class.web.comps';

export class TwcFormSection extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcFormSection';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
        }
    }
}

export class TwcFormSectionItem extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcFormSectionItem';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
            case 'Caption':
                document.getElementById(`${this.id}-label`).innerHTML = value;
                break;
        }
    }
}



