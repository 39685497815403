import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TWebFormCombobox extends TWebComponent {
    UseUndefined: boolean;
    combobox: HTMLSelectElement;
    hasChangeEvent: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TWebFormCombobox';

        this.combobox = AsType<HTMLSelectElement>(this.obj);
        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
        this.UseUndefined = boolFromStr(this.obj.dataset.useundefined);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.combobox.addEventListener('input', (event: InputEvent) => this.handleComboboxChanged(event))
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleComboboxChanged(event?: InputEvent): void {
        this.notifyComponentChanged();

        if (this.hasChangeEvent) {
            WebCompEventHandler('OnChange', this.id)
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Value', $(this.obj).val()]);
        // 		properties.push([$(this.obj).attr('id'), "Enabled", ($(this.obj).is('[readonly]') ? '0' : '1')]);
        return properties;
    }

    writeProperties(key: string, value: string): void {

        function appendOption(AObj: JQuery<HTMLElement>, AValue: number, AText: string) {
            if (AText.trim() != '') {
                AObj.append($('<option>', {
                    value: AValue,
                    html: AText,
                }))
            }
        }

        switch (key) {
            case 'Value':
                $(this.obj).val(value);
                break;
            case 'Items':
                let splitted = value.split('\n');

                // Wir merken uns den alten selectedIndex (Value) und setzen ihn nach den options neu,
                // da der Index nicht übertragen wird, falls er sich nicht ändert 				
                let selectedIndex = this.combobox.selectedIndex;

                $(this.obj).find('option').remove();
                for (var i = 0, len = splitted.length; i < len; i++) {
                    if (i == 0 && this.UseUndefined) {
                        appendOption($(this.obj), -1, splitted[i]);
                    } else {
                        appendOption($(this.obj), i, splitted[i]);
                    }
                }

                this.combobox.selectedIndex = selectedIndex;
                break;
            case 'Enabled':
                this.combobox.disabled = value == '0';
                break;
            case 'Readonly': // UPS // Comboboxen haben gar kein ReadOnly!!!
                if (value == '1') {
                    $(this.obj).prop('readonly', true);
                } else if (value == '0') {
                    $(this.obj).prop('readonly', false);
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
            case 'UseUndefined':
                this.UseUndefined = value == '1';
        }
    }
}