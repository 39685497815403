import { boolFromStr } from "../../../utils/strings";
import { TRenderWebComponent } from "../../base/class.web.comps";

export class TwcText extends TRenderWebComponent {
    textContentHtml: string;  // Die Komponente erlaubt in Delphi auch HTML Input, also merken wir uns das auch als HTML
    visible: boolean;

    override initComponent(): void {
        super.initComponent();

        this.textContentHtml = this.obj.innerHTML;
        this.visible = !this.obj.classList.contains('d-none');
    }

    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);

        this.obj.innerHTML = this.textContentHtml;
        this.obj.classList.toggle('d-none', !this.visible);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Text': {
                this.textContentHtml = value;
                break;
            }
            case 'Visible': {
                this.visible = boolFromStr(value);
                break;
            }
        }
    }
}