
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';
import { TBaseTutorialShape, TTutorialShapeArrow, TTutorialShapeEllipse, TTutorialShapeRect, TTutorialShapeRectImage, TTutorialShapeRoundRect, TTutorialShapeText } from '../Shapes/TBaseTutorialShape';

// case unterscheidung cid
export function getTutorialObjByCID(cid: number, editor: TwsTutorialEditor): TBaseTutorialShape {
    switch (cid) {
        case 100:
            // Text
            return new TTutorialShapeText(editor);
        case 200:
            // RoundRect
            return new TTutorialShapeRoundRect(editor);
        case 300:
            // Ellipse
            return new TTutorialShapeEllipse(editor);
        case 400:
            // Arrow
            return new TTutorialShapeArrow(editor);
        case 500:
            // Image
            return new TTutorialShapeRectImage(editor);
        case 600:
            // Rect
            return new TTutorialShapeRect(editor);
        default:
            throw 'No valid shape cid';
    }
}