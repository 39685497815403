'use strict';

import { ArcElement, PolarAreaController } from 'chart.js';
import { merge } from 'chart.js/helpers';

export class CustomControllerPolarArea extends PolarAreaController {

	draw() {
		super.draw();
	}
}

CustomControllerPolarArea.id = 'customControllerPolarArea';
CustomControllerPolarArea.defaults = merge(PolarAreaController.defaults, { dataElementType: ArcElement.id });
