import List from 'list.js';
import { assigned } from '../../../utils/helper';
import { AsType } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcListView extends TWebComponent {
    searchComponent: HTMLInputElement;
    paginationCount: number;
    listDivId: string;
    mainList: List;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcListView';
        this.listDivId = `${this.id}-DIV`;
        this.mainList = null;
        let searchComponent = document.getElementById(this.obj.dataset.searchcomponentid);
        if (assigned(searchComponent)) {
            this.searchComponent = AsType<HTMLInputElement>(searchComponent);
        }
        this.paginationCount = parseInt(this.obj.dataset.paginationcount);
    }

    override initDomElement(): void {
        super.initDomElement();
        this.initSearch();
    }

    initSearch(): void {
        // wenn wir keine Elemente haben, müssen wir keine Liste initialisieren
        if (!assigned(this.obj.querySelector('.list-group-item')) && !assigned(this.obj.querySelector('.dropdown-item'))) {
            return;
        }

        let options: List.ListOptions;
        if (!Number.isNaN(this.paginationCount)) {
            options = {
                listClass: 'cs-search-list',
                valueNames: ['dropdown-hrefcaption', 'cs-listview-caption', 'cs-listview-props'],
                page: this.paginationCount,
                pagination: {
                    item: '<li><a href onclick="javascript:window.scrollTo(0,0); return false;" class="page"></a></li>',
                    outerWindow: 2
                }
            }
        } else {
            options = {
                listClass: 'cs-search-list',
                valueNames: ['dropdown-hrefcaption', 'cs-listview-caption', 'cs-listview-props'],
                fuzzySearch: {
                    threshold: 0.5,
                    distance: 1000,
                    multiSearch: false,
                }
            }
        }

        this.mainList = new List(this.listDivId, options);

        if (assigned(this.searchComponent)) {
            this.searchComponent.addEventListener('keyup', () => {
                this.triggerSearch();
            })
            // Initial einmal suchen (falls wir schon was in der Suchleiste haben)
            this.triggerSearch();
        }
    }

    triggerSearch(): void {
        if (assigned(this.searchComponent) && assigned(this.mainList)) {
            let searchString = this.searchComponent.value;
            // hier wurde bewusst auf fuzzySearch umgestellt, weil es einen bug in search.js gibt IMS-23886
            this.mainList.fuzzySearch(searchString);
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }

    getItemsCount(): number {
        return Object.keys(this.mainList.items).length;
    }

    getVisibleItemsCount(): number {
        return Object.keys(this.mainList.visibleItems).length;
    }

    override execAction(action: string, params: string): void {
        try {
            switch (action) {
                case 'Action.Add':
                    this.obj.insertAdjacentHTML('beforeend', params);
                    if (this.getVisibleItemsCount() == this.getItemsCount()) {
                        this.mainList.reIndex();
                    } else {
                        console.error('ListView Add Item Error');
                        // wenn wir irgendwas gesucht haben und dann hinzufügen wollen, 
                        // kommt die Komponente durcheinander und löscht alle alten Einträge.
                        // Deswegen geht das hier noch nicht. Nutzen wir momentan auch erst nur in der Testseite
                    }
                    // und refreshen das Listen Objekt
                    this.mainList.update();
                    break;
                case 'Action.Delete':
                    let index = parseInt(params);
                    if (index >= 0 && index < this.getItemsCount()) {
                        let elem = this.mainList.items[index]['elm'] as HTMLElement;
                        // wir löschen den Listeneintrag 
                        elem.remove();
                        // die haben noch einen BUG (Stand 2023-01)
                        // https://listjs.com/docs/item-api/ -> hide() auf dem Item -> "Hides the item (removes the element from the list, 
                        // and then when its shown it's appended again. The element will thereby change position in the list.
                        // A bug, but a good solution is yet to be found.)"
                        // -> So klappt es! 
                        this.mainList.items.splice(index, 1);
                        // und refreshen das Listen Objekt
                        this.mainList.update();
                    }
                    break;
                case 'Action.Clear':
                    this.mainList.clear();
                    this.mainList.update();
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    }

}
