import { copyCompToClipboardMsg, copyToClipboardMsg } from '../../../core/utils/clipboard';
import { csJSONParse } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcClipboard extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcClipboard';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'CopyData':
                var CopyData = csJSONParse(value);
                if (CopyData.hasOwnProperty('CopyText')) {
                    copyToClipboardMsg(CopyData.CopyText);
                } else if (CopyData.hasOwnProperty('CopyID')) {
                    copyCompToClipboardMsg(CopyData.CopyID);
                }
                break;
        }
    }
}