import Color from 'color';
import { Point, Rectangle } from '../../../classes/geometry';
import { WebCompEventHandler } from '../../../core/communication';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

enum RiskMatrixYLayout { Bottom2Top, Top2Bottom };
enum RiskMatrixXLayout { Left2Right, Right2Left };
enum RiskMatrixXAxis { Likelihood, Impact };

export class TwsRiskMatrix extends TWebComponent {
    private hasChangeEvent: boolean;
    private selected: { row: number; col: number; };

    private layout: {
        rows: number;
        cols: number;
        xAxis: RiskMatrixXAxis;
        xOrder: RiskMatrixXLayout;        yOrder: RiskMatrixYLayout;
        div: Rectangle;
        svg: Rectangle;
        captions: {
            x: number;
            y: number;
            x2: number;
            y2: number;
        };
        field: {
            w: number,
            h: number
        };
    };

    protected onChange: () => void;
    private resizeObserver: ResizeObserver;
    private readOnly: boolean;
    private enabled: any;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwsRiskMatrix';
        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);

        this.selected = {
            row: -1,
            col: -1
        };

        this.layout = {
            rows: 0,
            cols: 0,
            xAxis: RiskMatrixXAxis.Likelihood,
            xOrder: RiskMatrixXLayout.Left2Right,
            yOrder: RiskMatrixYLayout.Bottom2Top,
            div: new Rectangle(0, 0, 0, 0),
            svg: new Rectangle(0, 0, 0, 0),
            captions: { x: 0, y: 0, x2: 0, y2: 0 },
            field: { w: 0, h: 0 }
        };

        this.setEnabled(this.obj.dataset.enabled === 'true');
        this.setReadOnly(this.obj.dataset.readonly === 'true');
        this.selected.row = parseInt(this.obj.dataset.sy);
        this.selected.col = parseInt(this.obj.dataset.sx);
    }

    override initDomElement(): void {
        super.initDomElement();

        // Austausch Voodoo
        // Ein Reload entfernt sonst den ResizeObserver (siehe weiter unten)
        let wrapper = $('<div></div>');
        let tmp = $(this.obj);
        let matrix = $(this.obj);
        tmp.replaceWith(wrapper);
        wrapper.append(matrix);

        $(matrix).find('[data-bs-toggle="tooltip"]').tooltip();

        this.onChange = function () {
            this.notifyComponentChanged();
            if (this.hasChangeEvent) {
                WebCompEventHandler('OnChange', this.id);
             }
        };

        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    this.resizeSVG();
                }
            }
        });

        this.resizeObserver.observe(wrapper[0]);
    }

    private updateCaptionsSize(axis: string) {
        let elem = document.getElementById(`${this.id}-${axis}-caption`);
        this.layout.captions[axis] = this.getElementHeight(elem);

        let field = `${axis}2`;
        elem = document.getElementById(`${this.id}-${axis}-captions`);
        this.layout.captions[field] = 0;

        let self = this;
        $(elem).find('> div').each(function (i, elem) {
            if (self.getElementHeight(elem) > self.layout.captions[field]) {
                self.layout.captions[field] = self.getElementHeight(elem);
            }
        });
    }

    private calculateBounds(obj: HTMLElement) {
        this.updateCaptionsSize('x');
        this.updateCaptionsSize('y');

        let maxHeight = parseInt(obj.style["max-height"], 10);
        let availableWidth = $(obj).width();

        // DIV Bounds (das alles umfassende Element)
        let div = new Rectangle(0, 0, 0, 0);
        if (maxHeight < availableWidth || availableWidth == 0) {
            div.bottom = maxHeight;
            div.right = maxHeight;
        } else {
            div.bottom = availableWidth;
            div.right = availableWidth;
        }

        // SVG Bounds (die Matrix, ohne Captions)
        let svg = new Rectangle(0, 0, 0, 0);
        var ox = this.layout.captions.x + this.layout.captions.x2;
        var oy = this.layout.captions.y + this.layout.captions.y2;

        if (ox > oy) {
            svg.bottom = div.height - ox;
            svg.left = ox;
        } else {
            svg.bottom = div.height - oy;
            svg.left = oy;
        }
        svg.right = svg.left + svg.height;

        // Matrix Felder (quadratisch)
        let field = { h: 0, w: 0 };
        let divisor = this.layout.rows > this.layout.cols ? this.layout.rows : this.layout.cols;
        field.w = field.h = svg.height / divisor;

        // SVG Breite und Höhe aktualisieren
        svg.bottom = this.layout.rows * field.h;
        svg.right = svg.left + this.layout.cols * field.w;

        // DIV aktualisieren
        div.right = svg.right + ox;
        div.bottom = svg.bottom + oy;

        this.layout.div = div;
        this.layout.svg = svg;
        this.layout.field = field;
    }

    // gibt den linken oberen Punkt der Zielposition zurück
    private getFieldTransform(row: number, col: number): Point {
        var point = new Point(0, 0);

        if (this.layout.yOrder == RiskMatrixYLayout.Bottom2Top) {
            row = (this.layout.rows - row - 1);
        }

        if (this.layout.xOrder == RiskMatrixXLayout.Right2Left) {
            col = (this.layout.cols - col - 1);
        }

        point.x = col * this.layout.field.w;
        point.y = row * this.layout.field.h;

        return point;
    }

    private setXCaptionTransform(caption: HTMLElement) {
        caption.style.width = `${this.layout.svg.width}px`;
        caption.style['text-align'] = 'center';

        this.setElementTranslation(caption, new Point(this.layout.svg.left, this.layout.svg.bottom + this.layout.captions.y + this.layout.captions.y2 - $(caption).height()));
    }

    private setXCaptionsTransform(group: HTMLElement) {
        var self = this;
        var p = {
            x: this.layout.svg.left,
            y: this.layout.svg.bottom + this.layout.captions.y2
        };

        $(group).find('> div').each(function (i, elem) {
            if (self.layout.xOrder == RiskMatrixXLayout.Right2Left) {
                i = (self.layout.cols - i - 1);
            }

            elem.style.width = `${self.layout.field.w}px`;
            elem.style['text-align'] = 'center';
            self.setElementTranslation(elem, new Point(p.x + self.layout.field.w * i, p.y - $(elem).height()));
        });
    }

    private setYCaptionTransform(caption: HTMLElement) {
        caption.style.width = `${this.layout.svg.height}px`;
        caption.style['text-align'] = 'center';

        this.setElementTransformation(caption, new Point(
            this.layout.svg.left - this.layout.captions.x - this.layout.captions.x2 + $(caption).height(),
            this.layout.svg.top));
    }

    private setYCaptionsTransform(group: HTMLElement) {
        var self = this;
        var p = new Point(
            this.layout.svg.left - this.layout.captions.x2,
            this.layout.svg.top);

        $(group).find('> div').each(function (i, elem) {
            if (self.layout.yOrder == RiskMatrixYLayout.Bottom2Top) {
                i = (self.layout.rows - i - 1);
            }

            elem.style.width = `${self.layout.field.h}px`;
            elem.style['text-align'] = 'center';
            self.setElementTransformation(elem, new Point(
                p.x + $(elem).height(),
                p.y + i * self.layout.field.h)
            );
        });
    }

    private resizeSVG() {
        var self = this;
        var obj = this.obj;
        var div = $(obj).find('> div')[0];
        var matrix = $(div).find('> .riskmatrix')[0];

        this.layout.rows = matrix.children.length;
        this.layout.cols = $(matrix).find('> .cs-row')[0].children.length;

        this.calculateBounds(obj);

        var selectedItem;
        // update matrix field transformations        
        $(matrix).find('> .cs-row').each(function (i, row) {
            $(row).find('> .cs-col').each(function (j, rect) {
                if (i == self.selected.row && j == self.selected.col) {
                    selectedItem = rect;
                }
                self.setElementSize(rect, self.layout.field.w - 2, self.layout.field.h - 2);
                if (self.layout.xAxis == RiskMatrixXAxis.Likelihood) {
                    self.setElementTranslation(rect, self.getFieldTransform(i, j));
                } else {
                    self.setElementTranslation(rect, self.getFieldTransform(j, i));
                }
            });
        });

        // update transformation of captions
        if (this.layout.xAxis == RiskMatrixXAxis.Likelihood) {
            this.setXCaptionTransform(document.getElementById(`${this.id}-x-caption`));
            this.setXCaptionsTransform(document.getElementById(`${this.id}-x-captions`));
            this.setYCaptionTransform(document.getElementById(`${this.id}-y-caption`));
            this.setYCaptionsTransform(document.getElementById(`${this.id}-y-captions`));
        } else {
            this.setXCaptionTransform(document.getElementById(`${this.id}-y-caption`));
            this.setXCaptionsTransform(document.getElementById(`${this.id}-y-captions`));
            this.setYCaptionTransform(document.getElementById(`${this.id}-x-caption`));
            this.setYCaptionsTransform(document.getElementById(`${this.id}-x-captions`));
        }

        // resize div container
        div.style.height = `${this.layout.div.height}px`;
        div.style.width = `${this.layout.div.width}px`;
        this.setElementBounds(matrix, this.layout.svg);

        var icon = document.getElementById(`${this.id}-select-icon`);
        this.setElementSize(icon, this.layout.field.w - 2, this.layout.field.h - 2);
        $(icon).find('g')[0].setAttribute('stroke-width', String(this.layout.field.w / 15));

        if (selectedItem) {
            this.setSelectedIcon(selectedItem, this.selected.row, this.selected.col);
        } else {
            icon.style.display = 'none';
        }
    }

    private setSelectedIcon(elem: HTMLElement, row: number, col: number) {
        if (row != this.selected.row || col != this.selected.col || elem.children.length == 0) {
            var icon = document.getElementById(`${this.id}-select-icon`);
            icon.style.display = 'block';

            if (Color(elem.style.backgroundColor).isDark())
                icon.getElementsByTagName('g')[0].setAttribute('stroke', 'white')
            else
                icon.getElementsByTagName('g')[0].setAttribute('stroke', 'black');

            this.selected.row = row;
            this.selected.col = col;
            $(elem).append(icon);
        }
    }

    onClick(elem: HTMLElement, row: number, col: number) {
        if (this.readOnly || !this.enabled) {
            return;
        }

        if (row != this.selected.row || this.selected.col != col) {
            this.setSelectedIcon(elem, row, col);
            this.onChange();
        }
    }

    private setEnabled(enabled: boolean) {
        if (enabled != this.enabled) {
            this.enabled = enabled;
            if (enabled) {
                this.obj.style.opacity = '1';
            } else {
                this.obj.style.opacity = '0.4';
            }
        }
    }

    private setReadOnly(readOnly: boolean) {
        if (readOnly != this.readOnly) {
            this.readOnly = readOnly;
        }
    }

    private setVisible(visible: boolean) {
        this.obj.classList.toggle('d-none', !visible);
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Select':
                var res = value.split(":");
                this.selected = {
                    row: parseInt(res[0]),
                    col: parseInt(res[1])
                };
                break;
            case 'Bottom2Top':
                this.layout.yOrder = RiskMatrixYLayout.Bottom2Top;
                this.resizeSVG();
                break;
            case 'Top2Bottom':
                this.layout.yOrder = RiskMatrixYLayout.Top2Bottom;
                this.resizeSVG();
                break;
            case 'Left2Right':
                this.layout.xOrder = RiskMatrixXLayout.Left2Right;
                this.resizeSVG();
                break;
            case 'Right2Left':
                this.layout.xOrder = RiskMatrixXLayout.Right2Left;
                this.resizeSVG();
                break;
            case 'Likelihood':
                this.layout.xAxis = RiskMatrixXAxis.Likelihood;
                this.resizeSVG();
                break;
            case 'Impact':
                this.layout.xAxis = RiskMatrixXAxis.Impact;
                this.resizeSVG();
                break;
            case 'Visible':
                this.setVisible(value === '1');
                break;
            case 'Enabled':
                this.setEnabled(value === '1');
                break;
            case 'ReadOnly':
                this.setReadOnly(value === '1');
                break;
        }
    }

    override readProperties(): Array<ComponentProperty> {
        var properties = [];
        properties.push([this.id, 'selected', `${this.selected.col}:${this.selected.row}`]);
        return properties;
    }

    private setElementSize(elem: HTMLElement, w: number, h: number) {
        elem.style.width = `${w}px`;
        elem.style.height = `${h}px`;
    }

    private setElementBounds(elem: HTMLElement, bounds: Rectangle) {
        elem.style.left = `${bounds.left}px`;
        elem.style.top = `${bounds.top}px`
        elem.style.width = `${bounds.width}px`
        elem.style.height = `${bounds.height}px`
    }

    private setElementTranslation(elem: HTMLElement, point: Point) {
        elem.style.transform = `translate(${point.x}px, ${point.y}px)`;
    }

    private setElementTransformation(elem: HTMLElement, point: Point) {
        // es wird um die linke obere Ecke rotiert
        elem.style['transform-origin'] = `0% 0%`;
        // dadurch verschiebt sich das Element um seine Breite nach oben
        // diese Höhe muss auf den Zielpunkt addiert werden 
        // außerdem muss das Element vor dem Punkt platzier werden -> es muss um seine Höhe nach links verschoben werden
        elem.style.transform = `translate(${point.x - $(elem).height()}px, ${point.y + $(elem).innerWidth()}px) rotate(-90deg)`;
    }

    private getElementHeight(elem: HTMLElement) {
        return $(elem).height();
    }

    override supportsTransferDirty(): boolean {
        return true;
    }
}