import { WebCompEventHandlerAsync } from "../../../core/communication";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";

export class TwcPopup extends TWebComponent {
    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcPopup';
    }

    override initDomElement() {
        super.initDomElement();

        // Popup an den Body, damit es nicht abgeschnitten wird...
        document.body.appendChild(this.obj);

        // ggf. soll das Popup nicht schließbar sein
        if (boolFromStr(this.obj.dataset.canclose)) {
            document.getElementById(this.id + '-btnclose').addEventListener('click', e => this.handleCloseClick(e), false);
        }
    }

    handleCloseClick(e: MouseEvent) {
        this.obj.classList.add('d-none');
        // ggf. wollen wir etwas machen, wenn das Popup weggeklickt wird
        if (boolFromStr(this.obj.dataset.hasonclose)) {
            WebCompEventHandlerAsync('OnCloseClick', this.id);
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}