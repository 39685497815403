﻿import { WebCompEventHandlerAsync } from '../../../core/communication';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { assigned } from '../../../utils/helper';
import { getUniqueId } from '../../../utils/html';
import { b64DecodeUnicode, boolFromStr } from '../../../utils/strings';
import { findComponent } from '../../base/controlling';
import { TwcPopContainer } from '../PopContainer/class.web.comp.popcontainer';
import { TWebTree } from './class.web.comp.tree';

export class TwcTreeTable extends TWebTree {

    tableElement: HTMLTableElement;
    noEntryDiv: HTMLElement;

    HasOnMenuClick: boolean;
    menuComponentId: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTreeTable';
        // in einem Baum gibt es eine Spalte, die ein Icon zulässzt. 
        // diese kennzeichnen wir mit MainColumnIndex (momentan kann man diese noch nicht setzen und sie ist hart verdrahtet)
        this.MainColumnIndex = 0;

        this.tableElement = this.obj as HTMLTableElement;
        this.noEntryDiv = document.getElementById(`${this.id}-noEntryDiv`);

        this.HasOnMenuClick = boolFromStr(this.obj.dataset.hasonmenuclick);
        this.menuComponentId = this.obj.dataset.menucomponentid;
    }

    override initDomElement() {
        super.initDomElement();

        // bei resize des Fensters, optimieren wir unsere Position
        window.addEventListener('resize', () => {
            if (assigned(document.getElementById(this.id))) {
                this.optimizePosition()
            }
        });
    }

    override internalOnRightClick(event: MouseEvent): void {
        // bei TH gehen wir raus
        if ((event.target as Element).nodeName == 'TH') {
            return;
        }
        let parentElement = (event.target as Element).parentElement;
        // leider kann man z.b. genau mit der rechten maustaste auch das icon treffen und dann geht das menü nicht auf.
        // hier suchen wir nach dem richtigen parent. Abbruch auch wenn wir ausserhalb unserer Komponente landen sollten
        while (!(parentElement instanceof HTMLTableRowElement) && this.obj.contains(parentElement)) {
            parentElement = parentElement.parentElement;
        }
        // wir wollen nur im tbody die klicks. TH darf nicht geklickt werden, unser Parent muss also TR sein
        if (parentElement.nodeName == 'TR') {
            super.internalOnRightClick(event);
        }
    }

    override getTreeExtensions(IsMobile: boolean): string[] {
        let result = super.getTreeExtensions(IsMobile);
        // hier adden wir noch die table extention

        const TABLE_EXTENSION = 'table';

        if (!result.includes(TABLE_EXTENSION)) {
            result.push(TABLE_EXTENSION);
        }

        return result;
    }

    override refreshItem(itemJSON): void {
        super.refreshItem(itemJSON);
        // Hier müssen wir auch die einzelnen Spalten aktualisieren im Tabellenmodus!       
        if (itemJSON.columns) {
            // Wir holen den Node aus dem Baum
            let tree = $.ui.fancytree.getTree(this.obj);
            let node = tree.getNodeByKey(itemJSON.key);
            // Wir laden unsere Column-Data in die zusätzlichen Spalten
            let cells = node.tr.cells;
            for (let key of Object.keys(itemJSON.columns[0])) {
                if (Number(key) !== this.MainColumnIndex) {
                    cells[Number(key)].innerHTML = b64DecodeUnicode(itemJSON.columns[0][key]);
                    if (cells[Number(key)].innerText.trim() != '') {
                        cells[Number(key)].title = cells[Number(key)].innerText.replace('"', '\'');
                    }
                } else {
                    if (this.HasOnMenuClick) {
                        let fancyNode = cells[Number(key)].querySelector('.fancytree-node');
                        // wir fügen den Button nur einmal hinzu.  
                        if (fancyNode.querySelectorAll('.cs-tablebutton').length == 0) {
                            fancyNode.parentNode.insertBefore(this.getMenuButtonElement(), fancyNode.nextSibling);
                        }
                    }
                }
            }
        }
    }

    getMenuButtonElement(): HTMLElement {
        let btn = document.createElement('button');
        btn.classList.add('btn', 'btn-transparent', 'btn-icon', 'd-inline-block', 'float-end', 'cs-tablebutton');
        btn.innerHTML = '<i class="fa-regular fa-ellipsis-v"></i>';
        let btnId = getUniqueId('1', 'tablebutton');
        btn.id = btnId;
        let _this = this;
        btn.onclick = () => this.internalOnMenuClick(btnId);;
        return btn;
    }

    internalOnMenuClick(btnId: string): void {
        let menuComponent = findComponent(this.menuComponentId);
        if (menuComponent instanceof TwcPopContainer) {
            menuComponent.triggerId = btnId;
            // @Lkl TOdo bei Schlagworten nochmal checken, ob wir WebCompEventHandlerAsync ausserhalb auslösen,
            // oder nur wenn wir auch eine Komponente gesetzt haben 
            // und dann hier eine Warnung ausgeben, dass kein Popcontainer da ist
        }
        WebCompEventHandlerAsync('OnMenuClick', this.id);
    }

    override nodeRender(event: JQueryEventObject, data: Fancytree.EventData): void {
        let node = data.node;
        if (node.data && node.data.columns) {
            // Wir laden unsere Column-Data in die zusätzlichen Spalten
            let cells = node.tr.cells;
            let treeObj = data.options.tree_obj;

            for (let key of Object.keys(node.data.columns[0])) {
                if (Number(key) !== treeObj.MainColumnIndex) {
                    // Wir erlauben hier explizit HTML
                    // daher Base64 Encoding, weil wir sonst in eiun Anführungszeichen Dilemma laufen können
                    cells[Number(key)].innerHTML = b64DecodeUnicode(node.data.columns[0][key]);
                    if (cells[Number(key)].innerText.trim() != '') {
                        cells[Number(key)].title = cells[Number(key)].innerText.replace('"', '\'');
                    }
                } else {
                    if (treeObj.HasOnMenuClick) {
                        let fancyNode = cells[Number(key)].querySelector('.fancytree-node');
                        // wir fügen den Button nur einmal hinzu.  
                        if (fancyNode.querySelectorAll('.cs-tablebutton').length == 0) {
                            fancyNode.parentNode.insertBefore(treeObj.getMenuButtonElement(), fancyNode.nextSibling);
                        }
                    }
                }
            }
        }
    }

    override afterRender(event: JQueryEventObject, data: Fancytree.EventData): void {
        super.afterRender(event, data);
        // wenn in der Tabelle Zeilen existieren, zeigen wir diese; sonst wird ein Hinweis sichtbar     
        let tree_obj = data.options.tree_obj;
        tree_obj.noEntryDiv?.classList.toggle('d-none', tree_obj.tableElement?.tBodies[0]?.rows?.length > 0);
        tree_obj.tableElement?.classList.toggle('d-none', tree_obj.tableElement?.tBodies[0]?.rows?.length == 0);
        tree_obj.optimizePosition();
    }

    override markTree(key: string): void {
        if (key !== undefined) {
            this.openTreepath(key);
        }
    }

    optimizePosition(): void {
        let availableSpaceVertical = 0, availableSpaceHorizontal = 0;
        let modalContainer = getModalDialogContainer(this.obj);
        if (assigned(modalContainer)) {
            let modalBody = modalContainer.querySelector('.modal-body');
            if (modalBody.getBoundingClientRect().width == 0) {
                const myModalEl = document.getElementById(modalContainer.parentElement.id);
                myModalEl.addEventListener('csrendercomplete.modal', e => {
                    let modalBody = modalContainer.querySelector('.modal-body');
                    let parentRectModalBody = modalBody.getBoundingClientRect();
                    let tableRect = this.treeContainer.getBoundingClientRect();
                    let computedStyleModal = window.getComputedStyle(modalBody);
                    availableSpaceVertical = Math.floor(parseFloat(computedStyleModal.height) - (tableRect.y - parentRectModalBody.y));

                    // jetzt noch den vertikalen Platz berechnen. Ist eine vertikale Scrollbar da, so haben wir weniger Platz. Das müssen wir erstmal herausfinden.
                    availableSpaceHorizontal = parseFloat(computedStyleModal.width) - parseFloat(computedStyleModal.paddingRight) - parseFloat(computedStyleModal.paddingLeft);

                    if (modalBody.scrollWidth > availableSpaceHorizontal) {
                        // wir haben eine vertikale scrollbar... die muss dann noch abgezogen werden
                        // scrollbar ist 11px hoch
                        availableSpaceVertical -= 11;
                    }
                    this.treeContainer.style.setProperty('max-height', availableSpaceVertical + 'px', 'important');
                });
            }
        }
    }
    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'AllowExpand':
                this.obj.classList.toggle('no-expand', value == '0');
                break;
            case 'AllowCollapse':
                this.obj.classList.toggle('no-collapse', value == '0');
                break;
            default:
                super.writeProperties(key, value);
                break;
        }
    }
}

// Delphi greift da direkt drauf zu 🤮
window[TwcTreeTable.name] = TwcTreeTable;