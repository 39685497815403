import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

const PICKER_VALUE_ATTR_NAME = 'csLastValue';

export class TwcRadioPicker extends TWebComponent {
    pickerContainer: HTMLElement;
    pickerObj: HTMLInputElement;
    isOptional: boolean;
    groupId: string;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcRadioPicker';

        this.pickerContainer = document.getElementById(`${this.id}-visual-picker`);
        this.pickerObj = AsType<HTMLInputElement>(this.obj);
        this.groupId = this.pickerObj.name;

        this.isOptional = boolFromStr(this.obj.dataset?.isOptional, false);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.setLastValue(this.pickerObj.checked);
        this.pickerObj.addEventListener('click', (event: MouseEvent) => this.handlePickerClick(event))
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    getLastValue(): boolean {
        return boolFromStr(this.pickerObj.getAttribute(PICKER_VALUE_ATTR_NAME));
    }

    setLastValue(value: boolean | string) {
        if (typeof value === 'boolean') {
            this.setLastValue(String(value));
        } else {
            this.pickerObj.setAttribute(PICKER_VALUE_ATTR_NAME, value)
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Selected', ($(this.obj).is(':checked') ? '1' : '0')]);
        return properties;
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Selected':
                this.pickerObj.checked = boolFromStr(value);
                break;
            case 'Enabled':
                this.pickerContainer.classList.toggle('disabled', !boolFromStr(value));
                break;
            case 'Visible':
                this.obj.parentElement.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }

    handlePickerClick(event: MouseEvent): void {
        // bei disabled nichts machen
        if (this.pickerObj.disabled) {
            return;
        }

        if (this.isOptional) {
            this.pickerObj.checked = !this.getLastValue();
        }

        // da wir ein anderes Element in der Gruppe automatisch deselktieren müssen die anderen Elemente der Gruppe korrigiert werden
        let otherPickers = document.querySelectorAll(`[data-type="${this.classtype}"][name="${this.groupId}"]`);
        otherPickers.forEach(picker => {
            let comp = findComponent(picker.id) as TwcRadioPicker;

            comp.setLastValue(comp.pickerObj.checked);
            comp.notifyComponentChanged(); // durch das automatische deselektieren müssen wir die auch dirty setzen
        });
        
        this.setLastValue(this.pickerObj.checked)
        this.notifyComponentChanged();
    }
}