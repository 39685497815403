/**
 * Checks if an element is contained in an array.
 * @param needle Element to search for
 * @param haystack The array which will be searched
 * @deprecated Use function inArray<T> for type-safety.
 */
export function inArrayUNSAFE(needle: unknown, haystack: Array<unknown>): boolean {
    let needleStr = String(needle);

    for (let i = 0; i < haystack.length; i++) {
        if (String(haystack[i]) === needleStr) {
            return true;
        }
    }

    return false;
}

/**
 * Converts an Enum into an Array of its values.
 * @param enumValue 
 */
export function EnumToValues<T>(enumValue: object): Array<T> {
    // Typescript Enums mappen Name zu Wert und Wert zu Namen!
    // das heißt aus E {a, b} wird {a = 0, b = 1, 0 = 'a', 1 = 'b'}
    return Object.keys(enumValue)
        .filter(key => typeof enumValue[key as any] === 'number')
        .map(key => enumValue[key as any]);
}

/**
 * Checks if an element is contained in an array.
 * @param needle Element to search for
 * @param haystack The array which will be searched
 */
export function inArray<T>(needle: T, haystack: Array<T>): boolean {
    for (let i = 0; i < haystack.length; i++) {
        if (haystack[i] === needle) {
            return true;
        }
    }

    return false;
}

/**
 * Removes an element from an array. 
 * IMPORTANT: The array given as input also is modified already!
 * @param array The array from which the item should be removed.
 * @param value Either the element to remove or its index.
 * @returns Modified array. 
 */
export function removeItem<T>(array: Array<T>, value: T | number): Array<T> {
    if (typeof value === 'number') {
        if (value > -1) {
            array.splice(value, 1);
        }

        return array;
    }
    return removeItem<T>(array, array.indexOf(value));
}