import { SignalDispatcher } from 'strongly-typed-events';
import { Upload } from 'tus-js-client';
import { WebCompEventHandler } from '../../../core/communication';
import { getBaseUrl } from "../../../core/endpoint";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";

// const tus = require('tus-js-client/dist/tus.min.js');

export class TwcCustomFileTransfer extends TWebComponent {
    private hasOnFileUploaded: boolean;
    private chunksize: number;
    public onError = new SignalDispatcher();
    public onSuccess = new SignalDispatcher();

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcCustomFileTransfer';

        this.hasOnFileUploaded = boolFromStr(this.obj.dataset.hasonfileuploaded);
        this.chunksize = parseInt(this.obj.dataset.chunksize);
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    uploadFile(file: File) {
        let upload = new Upload(file, {
            endpoint: getBaseUrl(this.id),
            chunkSize: this.chunksize > 0 ? this.chunksize : Infinity,
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: (error) => {
                this.onError.dispatchAsync();
                console.error('File upload failed: ' + error);
                upload = null;
            },
            onSuccess: () => {
                this.onSuccess.dispatchAsync();

                this.notifyComponentChanged();
                // Alles hat geklappt jetzt Events auslösen				
                if (this.hasOnFileUploaded) {
                    WebCompEventHandler('OnFileUploaded', this.id);
                }
                upload = null;
            }
        });

        upload.findPreviousUploads().then(function (previousUploads) {

            // Found previous uploads so we select the first one. 
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0])
            }

            // Start the upload
            upload.start()
        });
    }

    override writeProperties(key: string, value: string) {
        // nothing to do 
    }
}