import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcRating extends TWebComponent {

    override initComponent(){
        super.initComponent();

        this.classtype = 'TwcRating';
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value))
                break;
        }
    }
}