import { WebCompEventHandler, WebCompEventHandlerAsync } from '../../../core/communication';
import { replaceHtml } from '../../../utils/domUtils';
import { assigned } from '../../../utils/helper';
import { getParentBySelectors } from '../../../utils/html';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcTable extends TWebComponent {
    tableElement: HTMLTableElement;

    HasOnRightClick: boolean;
    HasOnChange: boolean;
    HasCanSelect: boolean;
    HasOnDblClick: boolean;
    selectedRowId: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTable';
        this.tableElement = this.obj as HTMLTableElement;

        this.HasOnRightClick = boolFromStr(this.obj.dataset.hasonrightclick);
        this.HasOnChange = boolFromStr(this.obj.dataset.hasonchange);
        this.HasCanSelect = boolFromStr(this.obj.dataset.hascanselect);
        this.HasOnDblClick = boolFromStr(this.obj.dataset.hasondblclick);
        this.selectedRowId = this.obj.dataset.selectedrowid;
    }

    override initDomElement() {
        super.initDomElement();
        this.initCanSelect();

        this.obj.addEventListener('contextmenu', event => this.internalOnRightClick(event));
        this.obj.addEventListener('dblclick', event => this.onDblClick(event));
    }

    internalOnRightClick(event: MouseEvent) {
        if (this.HasOnRightClick) {
            let targetElement = (event.target as Element);
            // wir wollen nur im tbody die klicks. TH darf nicht geklickt werden
            if (targetElement?.nodeName == 'TD' && targetElement?.parentElement?.nodeName == 'TR') {
                event.preventDefault();
                this.updateSelectedRow(targetElement.parentElement as HTMLTableRowElement);
                WebCompEventHandlerAsync('OnRightClick', this.id);
            }
        }
    }

    onDblClick(event: MouseEvent): void {
        if (this.HasOnDblClick) {
            let eventTarget = (event.target as Element);
            // bei THEAD > TR > TH > ELEMENT gehen wir raus
            if (assigned(getParentBySelectors(eventTarget, 'thead'))) {
                return;
            }
            let trElement = null;
            if (eventTarget.nodeName == 'TR') {
                trElement = eventTarget;
            } else {
                trElement = getParentBySelectors(eventTarget, 'TR');
            }
            // wir wollen nur im tbody die klicks. TH darf nicht geklickt werden, unser Parent muss also TR sein
            if (assigned(trElement)) {
                event.preventDefault();
                this.updateSelectedRow(trElement as HTMLTableRowElement);
                WebCompEventHandlerAsync('OnDblClick', this.id);
            }
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleSelectionChanged() {
        if (this.HasOnChange) {
            WebCompEventHandler('OnSelectionChanged', this.id);
        }
    }

    setSelectEventsToRow(row: HTMLTableRowElement) {
        // damit blur und focus auf einer Table funktioniert, muss man tabindex setzten
        row.setAttribute('tabindex', '0');
        row.addEventListener('blur', event => {
            this.tableElement.classList.remove('selectedRow-focused');
        });
        row.addEventListener('focus', event => {
            this.tableElement.classList.add('selectedRow-focused');
        });
        row.addEventListener('click', e => {
            // wir speichern die aktuelle row die ausgewählt ist mal zwischen
            let currentSelectedRowID = this.getSelectedRowId();
            // jetzt löschen und neu setzen
            this.updateSelectedRow(row);
            // ist die alte id ungleich der neuen, dann change event 
            if (currentSelectedRowID !== row.dataset.id) {
                this.handleSelectionChanged();
            }
        });
    }

    initCanSelect() {
        if (this.HasCanSelect) {
            let rows = this.tableElement.tBodies[0]?.rows;
            for (let i = 0; i < rows.length; i++) {
                this.setSelectEventsToRow(rows[i]);
            }
            // nun setzen wir noch das row
            this.setSelectedRowById();
        }
    }

    updateSelectedRow(row: HTMLTableRowElement): void {
        this.setSelectedRow(row);
        this.notifyComponentChanged();
    }

    setSelectedRowById(): void {
        if (this.selectedRowId.trim() != '') {
            let rowToSelect = this.tableElement.querySelector(`[data-id="${this.selectedRowId}"]`);
            this.updateSelectedRow(rowToSelect as HTMLTableRowElement);
        }
    }

    setSelectedRow(row: HTMLTableRowElement): void {
        this.clearSelectedRow();
        row?.classList.add('selectedRow');
    }

    clearSelectedRow(): void {
        let selectedRow = this.getSelectedRow();
        if (assigned(selectedRow)) {
            selectedRow?.classList.remove('selectedRow');
        }
    }

    getSelectedRow(): HTMLTableRowElement {
        return this.tableElement.querySelector('.selectedRow');
    }

    getSelectedRowId(): string {
        let selectedRowId = '';
        let row = this.getSelectedRow();
        if (assigned(row)) {
            selectedRowId = row.dataset.id;
        }
        return selectedRowId;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'SelectedRow', this.getSelectedRowId()]);
        return properties;
    }

    override execAction(action: string, params: string) {
        try {
            var ActionSet = csJSONParse(params);
            switch (action) {
                case 'Action.RowChange':
                    var obj = csJSONParse(ActionSet.ActionValue);
                    if ($('#' + this.id + ' tr:nth-child(' + (obj.index + 1) + ')').length) {
                        let i;
                        for (i = 0; i < obj.cells.length; i++) {
                            $('#' + this.id + ' tr:nth-child(' + (obj.index + 1) + ') td:nth-child(' + (i + 1) + ')').html(obj.cells[i]);
                        }
                    };
                    break;
                case 'Action.RowAdd':
                    $('#' + this.id + ' > tbody:last-child').append(ActionSet.ActionValue);
                    if (this.HasCanSelect) {
                        let lastRowIndex = this.tableElement.tBodies[0]?.rows.length - 1;
                        let lastRow = this.tableElement.tBodies[0]?.rows[lastRowIndex];
                        this.setSelectEventsToRow(lastRow);
                    }
                    break;
                case 'Action.RowDelete':
                    if ($('#' + this.id + ' tr').length > parseInt(ActionSet.ActionValue)) {
                        $('#' + this.id + ' tr').eq(parseInt(ActionSet.ActionValue) + 1).addClass('d-none').removeClass('selectedRow');
                    };
                    break;
                case 'Action.Reload':
                    replaceHtml(this.id, ActionSet.ActionValue);
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'SelectedRow':
                this.selectedRowId = value;
                this.setSelectedRowById();
                break;
        }
    }
}