import { AsType } from "../../../utils/html";
import { boolFromStr } from "../../../utils/strings";
import { TwcCustomFileSelector } from "./class.web.comp.fileselector.base";


export class TwsTemplateSelect extends TwcCustomFileSelector {
    hiddenUpload: HTMLInputElement;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwsTemplateSelect';

        this.hiddenUpload = AsType<HTMLInputElement>(document.getElementById(this.id + '-hidden-upload'));
    }

    override initDomElement(): void {
        super.initDomElement();

        this.obj.querySelector('.btnselect').addEventListener('click', event => this.triggerFileSelect(), false);
        this.hiddenUpload.addEventListener('change', event => this.handleFileSelect(event), false);
    }

    triggerFileSelect() {
        this.hiddenUpload.click();
    }

    handleFileSelect(evt: Event) {
        if (this.readOnly) {
            return;
        }

        evt.stopPropagation();
        evt.preventDefault();

        let localFiles = this.hiddenUpload.files;

        for (let index = 0; index < localFiles.length; index++) {
            this.uploadFile(localFiles[index]);
        }

        // Aufräumen
        this.hiddenUpload.value = '';
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'fileInfo':
                document.getElementById(`${this.id}-fileinfo`).innerHTML = value;
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Readonly':
                this.readOnly = boolFromStr(value);
                break;
            default:
                super.writeProperties(key, value);
                break;
        }
    }
}