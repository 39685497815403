import { TWebComponent } from '../../base/class.web.comps';

export class TwcTitle extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcTitle';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Title':
                $(this.obj).find('.page-title').html(value);
                break;
            case 'Caption':
                $(this.obj).find('.page-caption').html(value);
                break;
            case 'Category':
                $(this.obj).find('.page-category').html(value);
                break;
        }
    }
}