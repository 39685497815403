import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeRotationAction extends TCustomTutorialShapeAction {
    oldRotation: number;
    newRotation: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldRotation = this.refElement.rotation;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeRotation;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldRotation = obj.oldRotation;
        this.newRotation = obj.newRotation;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldRotation = Math.round(this.oldRotation);
        obj.newRotation = Math.round(this.newRotation);

        return obj;
    }

    setValue(newRotation: number): void {
        this.newRotation = Math.round(newRotation);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setRotation(this.newRotation);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeRotationAction(this.refElement, this.svgEditor);
        result.setValue(this.oldRotation);

        return result;
    }
}