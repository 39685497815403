import { Point } from '../../../../classes/geometry';
import { assigned } from '../../../../utils/helper';
import { TCustomActionGroup } from '../Actions/TCustomActionGroup';
import { TEdgeExtraPointsAction } from '../Actions/TEdgeExtraPointsAction';
import { TNodePositionAction } from '../Actions/TNodePositionAction';
import { TNodeSizeAction } from '../Actions/TNodeSizeAction';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TEdgeBase } from '../Edges/TEdgeBase';
import { TBaseShape } from '../Shapes/TBaseShape';
import { TBaseGraphObject } from '../TBaseGraphObject';

export function initMoveEvents(editor: TwsProcessEditorCustom) {

    editor.editorSVG.addEventListener('mousedown', event => handleMouseDown(event));

    let selectedElements: Array<TBaseGraphObject>;

    let transform: SVGTransform = null;
    let hasElement: boolean = false;
    let translate: SVGTransform = null;

    let offset: { x: number, y: number };
    let snappedOffset: { x: number, y: number };

    let lastDX = 0;
    let lastDY = 0;

    let animationIds: Array<number> = [];

    function getMousePosition(event) {
        let CTM = editor.editorSVG.getScreenCTM();
        if (event.touches) {
            event = event.touches[0];
        }
        return {
            x: (event.clientX - CTM.e) / CTM.a,
            y: (event.clientY - CTM.f) / CTM.d
        };
    }

    function handleMouseDown(event) {
        // wenn wir nicht draggable sind brechen wir hier schon ab
        if (!event.target.classList.contains('draggable')) {
            return;
        }

        // eventlistener intialisieren
        document.documentElement.addEventListener('mousemove', handleMouseMove);
        document.documentElement.addEventListener('mouseup', handleMouseUp);
        editor.isElementEditMode = true;

        // gepsiehcerte Move Koordinaten leeren
        lastDX = 0;
        lastDY = 0;

        selectedElements = editor.objectList.filter(x => x instanceof TBaseShape && x.selected === true);
        hasElement = true;
        offset = getMousePosition(event);

        selectedElements.forEach(selectedElement => {
            let shape = selectedElement as TBaseShape;

            if (!assigned(shape)) {
                return;
            }

            if (!shape.isMoveable) {
                return;
            }

            // Make sure the first transform on the element is a translate transform
            let transforms = selectedElement.svgElement.transform.baseVal;

            if (transforms.numberOfItems === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
                // Create a transform that translates by (0, 0)
                translate = editor.editorSVG.createSVGTransform();
                translate.setTranslate(0, 0);
                selectedElement.svgElement.transform.baseVal.insertItemBefore(translate, 0);
            }

            // Get initial translation
            transform = transforms.getItem(0);

            offset.x -= transform.matrix.e;
            offset.y -= transform.matrix.f;
        });

        snappedOffset = {
            x: editor.snapToUsedGridX(offset.x),
            y: editor.snapToUsedGridY(offset.y)
        };
    }

    function handleMouseMove(event: MouseEvent) {
        // wenn unser editmode beendet wurde abbrechen und aufräumen
        if (!editor.isElementEditMode) {
            resetMovePos();
            return;
        }

        if (hasElement) {
            event.preventDefault();

            let coord = getMousePosition(event);
            let dx = editor.snapToUsedGridX(coord.x) - snappedOffset.x;
            let dy = editor.snapToUsedGridY(coord.y) - snappedOffset.y;

            // wenn wir nichts bewegen müssen können wir uns die rechnerei sparen
            if (dx === lastDX && dy === lastDY) {
                return;
            }

            // alte Berechnungen stoppen
            animationIds.forEach(id => {
                window.cancelAnimationFrame(id);
            });

            animationIds.push(window.requestAnimationFrame(timestamp => {
                // Shapes bewegen
                selectedElements.forEach(element => {
                    let shape = element as TBaseShape;

                    if (!shape.isMoveable) {
                        return;
                    }

                    let targetPos = new Point(shape.x + dx, shape.y + dy);
                    let finalPos = editor.snapShapeToUsedGrid(shape, targetPos);

                    let dxTranslate = finalPos.left - shape.x
                    let dyTranslate = finalPos.top - shape.y;

                    // Vergrößern des Editors beim Rand
                    if ((shape.x + dxTranslate) < 0)
                        dxTranslate = -shape.x;

                    if ((shape.y + dyTranslate) < 0)
                        dyTranslate = -shape.y;


                    shape.setNewDraftPosition(finalPos.left, finalPos.top);
                    shape.setNewDraftSize(finalPos.right - finalPos.left, finalPos.bottom - finalPos.top)
                });

                // Kanten separat neu berechnen; wenn wir das hier machen können wir uns alle auf einmal ausm DOM holen statt per Shape und so doppelte Berechnungen vermeiden
                let queryString = selectedElements.map(shape => `[fromNode="${shape.id}"], [toNode="${shape.id}"]`).join(', ');
                if (queryString !== '') {
                    let edgeElementList = editor.editorSVG.querySelectorAll(queryString);
                    edgeElementList.forEach(element => {
                        let aEdge = editor.objectById(element.id) as TEdgeBase;
                        aEdge.setDraftExtraPoints(aEdge.recalcExtraPoints());
                    });
                }

                // Editorgröße anpassen; -> macht implizit schon ein invalidate!
                editor.checkEditorSize();
            }));

            lastDX = dx;
            lastDY = dy;
        }
    }

    function handleMouseUp() {
        if (!editor.isElementEditMode) {
            resetMovePos();
            return;
        }

        hasElement = false;

        if (assigned(selectedElements) && selectedElements.length > 0) {
            let actionGroup = new TCustomActionGroup(null, editor);

            let handledEdges = new Array<string>();

            selectedElements.forEach(selectedElement => {
                let selectedShape = selectedElement as TBaseShape;

                let dx = selectedShape.boundingRect.left - selectedShape.realBoundingRect.left;
                let dy = selectedShape.boundingRect.top - selectedShape.realBoundingRect.top;

                if (dx !== 0 || dy !== 0) {
                    // Hier muss das Translate als dx,dy umgerechnet und zurückgesetzt werden
                    let posAction = new TNodePositionAction(selectedShape, editor);
                    posAction.setValue(dx, dy);

                    let resizeAction = new TNodeSizeAction(selectedShape, editor);
                    resizeAction.setValue(selectedShape.w, selectedShape.h);

                    // extraPoints für verschobene Edges aktualisieren
                    let edges = editor.objectList.filter(x => (x instanceof TEdgeBase
                        && (x.id.split('#')[0] === selectedShape.id || x.id.split('#')[1] === selectedShape.id) && handledEdges.indexOf(x.id) < 0));
                    edges.forEach(edge => {
                        let aEdge = edge as TEdgeBase;
                        let extraPtAction = new TEdgeExtraPointsAction(aEdge, editor);
                        let newExtraPoints = aEdge.recalcExtraPoints();
                        extraPtAction.setValue(newExtraPoints);
                        actionGroup.actions.push(extraPtAction);

                        // merken -> wenn wir zwei Elemente verschieben, und die Kante zu beiden gehört, müssen wir das später dann nicht nochmal machen.
                        handledEdges.push(aEdge.id);
                    });

                    actionGroup.actions.push(posAction);
                    actionGroup.actions.push(resizeAction);

                    selectedShape.resetDraftBounds(); // das eigentliche Anwenden passiert unten via Perform auf der Actionliste
                }
            });

            if (actionGroup.actions.length > 0) {
                editor.actionList.addClientAction(actionGroup);
                editor.actionList.actions[editor.actionList.listPointer].performAction();
            }

            selectedElements.length = 0;
        }

        // und die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleMouseUp, false);
        editor.isElementEditMode = false;
    }

    function resetMovePos() {
        // alte Bounds wiederherstellen
        if (assigned(selectedElements) && selectedElements.length > 0) {
            selectedElements.forEach(selectedElement => {
                if (selectedElement instanceof TBaseShape) {
                    selectedElement.setNewDraftPosition(selectedElement.x, selectedElement.y);
                    selectedElement.setNewDraftSize(selectedElement.w, selectedElement.h, true);
                }
            });
        }

        // Kanten separat neu berechnen; wenn wir das hier machen können wir uns alle auf einmal ausm DOM holen statt per Shape und so doppelte Berechnungen vermeiden
        let queryString = selectedElements.map(shape => `[fromNode="${shape.id}"], [toNode="${shape.id}"]`).join(', ');
        if (queryString !== '') {
            let edgeElementList = editor.editorSVG.querySelectorAll(queryString);
            edgeElementList.forEach(element => {
                let aEdge = editor.objectById(element.id) as TEdgeBase;
                aEdge.setDraftExtraPoints(aEdge.recalcExtraPoints(), true);
            });
        }

        hasElement = false;
        selectedElements.length = 0;

        // Editorgröße anpassen; -> macht implizit schon ein invalidate!
        editor.checkEditorSize();

        // eventlistener aufräumen
        document.documentElement.removeEventListener('mousemove', handleMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleMouseUp, false);
    }
}