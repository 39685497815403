﻿import { v4 as uuidv4 } from 'uuid';
import { sendComponentRequestGetJson, WebCompEventHandler } from '../../../core/communication';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';

enum FileDataState {
    unknown = 0,
    uploading = 1,
    uploaded = 2,
    deleted = 3,
    error = 4,
}

export enum FileSelectorRequests {
    unknown = 0,
    requestFileMeta = 1,
    uploadFile = 2,
    downloadFile = 3,
    removeFile = 4,
}

export enum WebFilePreviewMode {
    wfpNone = 0,
    wfpCompact = 1,
    wfpExtended = 2
}

export class FileSelectorCommand {
    command: FileSelectorRequests;
    data: any;

    constructor() {
        this.command = FileSelectorRequests.unknown;
        this.data = undefined;
    }
}

enum FSRErrorType {
    Warning = 0,
    Error = 1,
}

export class FileSelectorResponse {
    errorCode: number;
    errorType: FSRErrorType;
    errorMessage: string;
    requestedCommand: FileSelectorRequests;
    data: any;

    constructor() {
        this.errorCode = 0;
        this.errorType = FSRErrorType.Error;
        this.errorMessage = '';
        this.requestedCommand = FileSelectorRequests.unknown;
        this.data = undefined;
    }
}

class FileData {
    id: any;
    fileName: any;
    fileDateTime: any;
    fileType: any;
    fileSize: any;
    fileData: any;
    state: FileDataState;
    stateMessage: string;

    constructor() {
        this.id = undefined;
        this.fileName = undefined;
        this.fileDateTime = undefined;
        this.fileType = undefined;
        this.fileSize = undefined;
        this.fileData = undefined;
        this.state = FileDataState.unknown;
        this.stateMessage = '';
    }

    initGUID() {
        this.id = uuidv4();
    }
}

const MAX_ALLOWED_BYTES = 30 * 1024 * 1024; // 30 MB Maximal erlaubt

export class TwcCustomFileSelector extends TRenderWebComponent {
    files: Array<FileData>;
    htmlNode: HTMLElement;
    FileExtensions: string[];
    maxBytes: number;
    readOnly: boolean;
    hasServerChangeEvent: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcFileZoneFileSelector';

        this.files = [];
        this.htmlNode = this.obj;

        // array mit allen dateiendungen 
        this.FileExtensions = this.htmlNode.dataset.extensions.split('|').map(el => el.toLowerCase());
        this.setMaxedBytes(parseInt(this.htmlNode.dataset.maxfilesize));
        this.readOnly = boolFromStr(this.htmlNode.dataset.readonly);
        this.hasServerChangeEvent = boolFromStr(this.obj.dataset.hasServerChangeEvent);
    }

    override initDomElement(): void {
        super.initDomElement();

        // Anzeigedaten vom Server Laden
        this.updateRemoteFiles();
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    // Implemented in derived classes if necessary
    protected override doRender(timestamp: number): void {
        super.doRender(timestamp);
    }

    doSomethingChanged(): void {
        this.notifyComponentChanged();

        // Alles hat geklappt jetzt Events auslösen				
        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    setMaxedBytes(bytes: number): void {
        if (isNaN(bytes) || bytes <= 0 || bytes > MAX_ALLOWED_BYTES) {
            this.maxBytes = MAX_ALLOWED_BYTES;
        }
        else {
            this.maxBytes = bytes;
        }
    }

    handleRequestResult(response: FileSelectorResponse): void {
        switch (response.requestedCommand) {
            case FileSelectorRequests.requestFileMeta:
                this.files = response.data;
                this.invalidate();
                break;
            case FileSelectorRequests.uploadFile:
                this.doSomethingChanged();

                // Und Anzeige neu laden
                this.updateRemoteFiles();
                break;
            case FileSelectorRequests.downloadFile:
                let file = new FileData();
                file = response.data;
                // download über pseudoelement triggern
                var element = document.createElement('a');
                element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + file.fileData);
                element.setAttribute('download', file.fileName);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                break;
            case FileSelectorRequests.removeFile:
                this.doSomethingChanged();
                break;
        }

        if (response.errorCode != 0) {
            if (response.errorType == FSRErrorType.Warning)
                MsgNotify(response.errorMessage, MessageType.Warning);
            else
                MsgNotify(response.errorMessage, MessageType.Danger);
        }
    }

    updateRemoteFiles(): void {
        let cmd = new FileSelectorCommand();
        cmd.command = FileSelectorRequests.requestFileMeta;
        this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd) as FileSelectorResponse);
    }

    uploadFile(f: File): void {
        let regexFilename = /(?:\.([^.]+))?$/; //https://stackoverflow.com/a/680982

        let reader = new FileReader();
        let data = new FileData();
        data.initGUID();
        data.fileName = f.name;
        data.fileDateTime = f.lastModified;
        data.fileSize = f.size;

        let fileExt = regexFilename.exec(f.name)[1];
        if (assigned(fileExt)) {
            fileExt = '.' + fileExt; // Den Punkt müssen wir manuell prependen
        }
        else {
            fileExt = f.type; // Fallback wenn das RegEx failed -> entspricht einem von JS "geratenen" MIME Type.
        }
        data.fileType = fileExt;

        // is supported extension?
        if (this.FileExtensions.length > 0 && !(this.FileExtensions[0] == '.*')) {
            let extensionIsLegal = false;
            let ext = regexFilename.exec(data.fileName).map(el => el.toLowerCase());
            for (let i = 0; i < this.FileExtensions.length; i++) {
                if (ext.indexOf(this.FileExtensions[i]) > -1) {
                    extensionIsLegal = true;
                    break;
                }
            }

            if (!extensionIsLegal) {
                MsgNotify('Die Nutzung der Datei ' + data.fileName + ' ist nicht erlaubt. Anlagen mit dieser Dateiendung sind aus Sicherheitsgr&uuml;nden systemweit gesperrt.', MessageType.Danger);
                this.invalidate();
                return;
            }
        }

        // is file size ok?
        if (this.maxBytes > 0 && this.maxBytes < data.fileSize) {
            MsgNotify('Die Datei ' + data.fileName + ' (' + this.bytesToSize(data.fileSize) + ') kann nicht hinzugef&uuml;gt werden. Anlagen d&uuml;rfen maximal ' + this.bytesToSize(this.maxBytes) + ' gro&szlig; sein.', MessageType.Danger);
            this.invalidate();
            return;
        }

        reader.onload = function (fileData) {
            data.fileData = fileData.target.result;

            data.state = FileDataState.uploading;
            data.stateMessage = 'Uploading...';
            this.files.push(data);
            this.invalidate();

            let cmd = new FileSelectorCommand();
            cmd.command = FileSelectorRequests.uploadFile;
            cmd.data = data;
            this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd));
        }.bind(this);

        reader.readAsDataURL(f);
    }

    bytesToSize(bytes: number): string {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0)
            return 'n/a'

        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        if (i === 0)
            return `${bytes} ${sizes[i]}`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'FileExtensions':
                this.FileExtensions = value.split("|").map(el => el.toLowerCase());
                break;
            case 'MaxSize':
                this.setMaxedBytes(parseInt(value));
                break;
            case 'Readonly':
                this.readOnly = value == '1';
                break;
        }
    }

    displayImgByDataType(type: any): string {
        let return_value = '';

        switch (type) {
            default: return_value = '<i class="fa fa-copy"></i>';
                break;
        }

        return return_value;
    }
}