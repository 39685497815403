﻿import jquery from 'jquery';
import select2 from 'select2/dist/js/select2.full';
import { sendComponentRequestGetJson, WebCompEventHandler } from '../../../core/communication';
import { getBaseUrl } from '../../../core/endpoint';
import { registerOnShowModal, String2HTMLEntity } from '../../../core/interaction';
import { inArrayUNSAFE } from '../../../utils/arrays';
import { getModalDialogContainer } from '../../../utils/bootstrap';
import { setHtml } from '../../../utils/domUtils';
import { assigned } from '../../../utils/helper';
import { AsType, getUniqueId } from '../../../utils/html';
import { boolFromStr, csJSONParse } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


// Hook up select2 to jQuery
// SONDERFALL: Select2 muss an jquery gebunden werden. Dafür müssen wir das via Modul laden. Damit wir aber nicht die originale 
// $ Funktion überschreiben, in welche Bootstrap sich integriert (dann geht der Modal innerhalb dieser Komponente nämlich nicht mehr)
// müssen wir das hier mit anderen Namen einladen. Damit das FUnktioniert dürfen wir für das Select2 auch nicht die $ Objekte nutzen!
select2(jquery);

export class TwsOrgRelEditor extends TWebComponent {
    editButton: JQuery<HTMLElement>;
    inputGroup: JQuery<HTMLElement>;
    dropdownButton: JQuery<HTMLElement>;
    orgRelEditorCSObject: any;
    hasChangeEvent: boolean;
    dirtyEvent: Event;
    dataIsAnonymized: boolean;

    override initComponent(): void {
        super.initComponent();

        this.editButton = $('#' + this.id + '-orgRelEditBtn');
        this.inputGroup = $('#' + this.id + '-inputGroup');
        this.dropdownButton = $('#' + this.id + '-orgRelEditBtnDropDown');

        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
        this.dataIsAnonymized = boolFromStr(this.obj.dataset.dataIsAnonymized);

        this.dirtyEvent = new Event('isdirty');
    }

    override initDomElement(): void {
        super.initDomElement();

        document.getElementById(this.id).addEventListener('isdirty', (event: InputEvent) => this.handleIsDirty(event))

        this.initOrgRelEditor();
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    handleIsDirty(event?: InputEvent): void {
        this.notifyComponentChanged();

        if (this.hasChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    initOrgRelEditor() {
        this.orgRelEditorCSObject = $('#' + this.id).orgRelEditorCSObject();
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([$(this.obj).attr('id'), 'btn', $(this.obj).val()]);
        properties.push([$(this.obj).attr('id'), 'IsAlle', $('#' + $(this.obj).data('id') + '-OrgRelAllBtn').hasClass('active')]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                // Hier disablen wir nicht nur den Button, sondern zeigen die komplette Komponente nicht an
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                    $('#' + this.id + '-BtnGroupOneAll').removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                    $('#' + this.id + '-BtnGroupOneAll').addClass('d-none');
                }
                break;
            case 'Text':
                setHtml(this.id + '-orgRelEditor', value);
                break;
            case 'State':
                if (value == '0') {
                    //Disabled
                    this.inputGroup.removeClass('orgRelReadonly');

                    this.editButton.removeClass('orgRelPointer');
                    this.editButton.prop('hidden', false);
                    this.editButton.prop('disabled', true);

                    this.dropdownButton.removeClass('orgRelPointer');
                    this.dropdownButton.prop('hidden', false);
                    this.dropdownButton.prop('disabled', true);

                    $('#' + this.id + '-OrgRelOneBtn').prop('disabled', true);
                    $('#' + this.id + '-OrgRelAllBtn').prop('disabled', true);
                } else if (value == '1') {
                    //ReadOnly
                    this.inputGroup.addClass('orgRelReadonly');

                    this.editButton.removeClass('orgRelPointer');
                    this.editButton.prop('hidden', true);

                    this.dropdownButton.removeClass('orgRelPointer');
                    this.dropdownButton.prop('hidden', true);

                    $('#' + this.id + '-OrgRelOneBtn').prop('disabled', true);
                    $('#' + this.id + '-OrgRelAllBtn').prop('disabled', true);
                } else if (value == '2') {
                    //Enabled -> nur wenn nicht schon anonymisiert
                    if (!this.dataIsAnonymized) {
                        this.inputGroup.removeClass('orgRelReadonly');

                        this.editButton.addClass('orgRelPointer');
                        this.editButton.prop('hidden', false);
                        this.editButton.prop('disabled', false);

                        this.dropdownButton.addClass('orgRelPointer');
                        this.dropdownButton.prop('hidden', false);
                        this.dropdownButton.prop('disabled', false);

                        $('#' + this.id + '-OrgRelOneBtn').prop('disabled', false);
                        $('#' + this.id + '-OrgRelAllBtn').prop('disabled', false);
                    }
                }
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.ParagraphEdited':
                let paramsJSON = csJSONParse(params);

                // Das ist der Paragraph, der editiert wurde
                var editorGroups = jquery('#' + paramsJSON['paragraph']);

                // Daten einfügen
                editorGroups.empty();
                // Wir haben maximal einen Paragraphen
                var selectedList = paramsJSON['groups']['paragraphs'][0];
                // Bei keiner Auswahl ist gar kein Paragraph vorhanden, daher checken wir darauf
                if (selectedList)
                    for (var j = 0; j < selectedList.length; j++) {
                        var newOption = new Option(selectedList[j].caption, selectedList[j].key, false, true);
                        editorGroups.append(newOption);
                    }
                break;
            case 'Action.Show':
                this.editButton.trigger('click');
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}

// SIEHE websrc/javascript/@types/orgRelEditor.d.ts

/***********************************************/
$.fn.extend({
    orgRelEditorCSObject: function () {
        // Properties des OrgRel-Editors holen
        var orgRelData = this.children('.org-rel-editor-data');
        var id = this.data('id');

        let title = this.data('title');
        let subtitle = this.data('subtitle');
        var editorType = this.data('editortype');
        var displayText = orgRelData.children('.org-rel-editor-display-text').html();
        var dict = {};
        orgRelData.children('.org-rel-editor-texts').children('.org-rel-editor-text').each(function (index) {
            dict[$(this).attr('name')] = $(this).html();
        });
        var properties = {};
        orgRelData.children('.org-rel-editor-properties').children('.org-rel-editor-property').each(function (index) {
            properties[$(this).attr('name')] = $(this).attr('value');
        });

        // Sind wir im Matrixmodus?
        var matrixModus = properties['MatrixModus'] === '1';

        // ------ KOMPONENTE HOLEN ------
        var orgRelEditorComp = $('#' + id + '-orgRelEditor');

        // Button
        var btnEdit = $('#' + id + '-orgRelEditBtn');

        var btnGroupEinerAlle = $('#' + id + '-BtnGroupOneAll');

        function dispatchDirty() {
            let comp = <TwsOrgRelEditor>findComponent(id);
            document.getElementById(id).dispatchEvent(comp.dirtyEvent);
        }

        // Gibt es die Einer-Alle-Buttons?
        if (btnGroupEinerAlle.length) {
            // Hilfsfunktionen
            let setBtnEinerAlleActive = (button) => {
                button.addClass('active');
                button.addClass('btn-primary')
                button.removeClass('btn-secondary');
            }

            let setBtnEinerAlleInActive = (button) => {
                button.removeClass('active');
                button.removeClass('btn-primary');
                button.addClass('btn-secondary');
            }

            // Buttons holen
            var btnEiner = $('#' + id + '-OrgRelOneBtn');
            var btnAlle = $('#' + id + '-OrgRelAllBtn');

            btnEiner.on({
                'click': function () {
                    setBtnEinerAlleActive(btnEiner);
                    setBtnEinerAlleInActive(btnAlle);
                    btnGroupEinerAlle.trigger('change');

                    dispatchDirty();
                }
            });

            btnAlle.on({
                'click': function () {
                    setBtnEinerAlleActive(btnAlle);
                    setBtnEinerAlleInActive(btnEiner);
                    btnGroupEinerAlle.trigger('change');

                    dispatchDirty();
                }
            });
        }

        // Modaler Dialog
        let modalId = getUniqueId(id, '-orgrel');

        var modal = $('<div id="' + modalId + '" class="modal OrgRelEditor" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true"></div>');
        $(document.body).append(modal);
        var modalDialog = $('<div class="modal-dialog modal-dialog-centered"></div>');
        modal.append(modalDialog);
        var modalContent = $('<div class="modal-content"></div>');
        modalDialog.append(modalContent);
        var modalHeader = $('<div class="modal-header cs-themecolor-primary cs-themecolor-primary-font"></div>');
        modalHeader.append($('<div><h6 class="modal-subtitle">' + subtitle + '</h6><h5 class="modal-title">' + title + '</h5></div>'));
        modalHeader.append($('<span><button type="button" class="close cs-themecolor-primary-font" data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button></span>'));
        modalContent.append(modalHeader);
        var alertNote = $('<div class="alert alert-info rounded-0 mb-0" role="alert">' + dict['allGroupsNote'] + '</div>');
        alertNote.hide();
        modalContent.append(alertNote);
        var modalBody = $('<div class="modal-body"></div>');
        modalContent.append(modalBody);
        var modalBodyFormSection = $('<div class="form"></div>');
        modalBody.append(modalBodyFormSection);
        var modalFooter = $('<div class="modal-footer"></div>');
        modalContent.append(modalFooter);

        registerOnShowModal(modal);

        var bottomPanel = $('<div class="container"></div>');
        modalFooter.append(bottomPanel);
        var bottomPanelRow = $('<div class="row"></div>');
        bottomPanel.append(bottomPanelRow);

        // Checkbox für "Komplette Organisation"
        if (properties['AllowSelectAll'] === '1') {
            var checkBoxPanel = $('<div class="col"></div>');
            bottomPanelRow.append(checkBoxPanel);
            var checkBoxContainer = $('<div class="form-check float-start mt-1"></div>');
            checkBoxPanel.append(checkBoxContainer);
            var checkBox = $('<input type="checkbox" class="form-check-input" id="checkBoxAll-' + id + '">');
            checkBoxContainer.append(checkBox);
            var checkBoxLabel = $('<label class="form-check-label" for="checkBoxAll-' + id + '">' + dict['allGroups'] + '</label>');
            checkBoxContainer.append(checkBoxLabel);
        }

        var btnPanel = $('<div class="col text-end"></div>');
        bottomPanelRow.append(btnPanel);

        // OK und Abbrechen
        var btnSubmit = $('<button type="submit" class="btn btn-primary org-rel-apply me-2">' + dict['btnOK'] + '</button>');

        btnPanel.append(btnSubmit);
        btnPanel.append($('<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">' + dict['btnCancel'] + '</button>'));

        // Eigene Such-Funktion für Gruppen und User
        function matchCustom(params, data) {

            // Bei leer zeigen wir erstmal alles
            if ($.trim(params.term) === '') {
                return data;
            }

            // Wir splitten bei Leerzeichen
            var terms = params.term.split(' ');

            for (var i = 0; i < terms.length; i++) {
                var term = terms[i];

                // Leerzeichen überspringen
                if (term.trim() === '')
                    continue;

                // Wir holen unsere Search-Texts für dieses Element
                var searchTexts = $(data.element).data('search');
                // Gibt es Search-Texts?
                if (typeof searchTexts !== 'undefined') {
                    // Dann überprüfen wir alle Texte
                    if ($(data.element).data('search').join(' ').toUpperCase().indexOf(term.toUpperCase()) < 0) {
                        // Wenn nichts matcht, ist hier vorbei
                        return null;
                    }
                } else {
                    // Ansonsten schauen wir einfach auf den Text
                    if (data.text.toUpperCase().indexOf(term.toUpperCase()) < 0) {
                        // Wenn nichts matcht, ist hier vorbei
                        return null;
                    }
                }
            }
            // Ok, wir haben einen Match
            return data;
        }
        /******************************************************************************************/
        function restoreUser(multiple: boolean, showLabel: boolean = false) {

            var formSectionfieldset = $('<fieldset class="form-group"></fieldset>');
            modalBodyFormSection.append(formSectionfieldset);

            // Label einsetzen
            if (showLabel) {
                formSectionfieldset.append('<legend class="cs-legend">' + (multiple ? dict['users'] : dict['user']) + '</legend>');
            }

            // container vorbereiten
            let container = $('<div class="orgrel-users"></div>');
            formSectionfieldset.append(container);
            let innercontainer = $('<div class="orgrelEditor-users"></div>');
            container.append(innercontainer);
            let publisher = $('<div class="publisher"></div>');
            innercontainer.append(publisher);

            let editroId = (multiple ? 'editor-users-' : 'editor-user-') + id;

            let editor = jquery('<select id="' + editroId + '" class="form-control orgrel-editor" data-bs-toggle="select2"' + (multiple ? ' multiple' : '') + '></select>');
            publisher.append(editor);

            // selected vom Server holen
            var request = {};
            request['request'] = 'getSelectedUsers';
            let response = sendComponentRequestGetJson(id, request) as any; // UPS // Typ
            if (!multiple) {
                var newOption = new Option(response.caption, response.key, false, true);
                editor.append(newOption);
            } else {
                for (var i = 0; i < response.items.length; i++) {
                    var newOption = new Option(response.items[i].caption, response.items[i].key, false, true);
                    editor.append(newOption);
                }
            }
            var searchproviderid_user = $('#' + id + '-orgrelsearchprovider-user').data('listselectorid');

            let hintForTypingInput = multiple ? dict['hintForTypingInputUsers'] : dict['hintForTypingInputUser'];
            // und select zwei drauf!
            initSelect2(editor, searchproviderid_user, hintForTypingInput);
        }
        /******************************************************************************************/
        function restoreGroups(showLabel: boolean) {

            // selected vom Server holen
            var request = {};
            request['request'] = 'getSelectedGroups';
            var response = sendComponentRequestGetJson(id, request);

            var hasVarAndFixedOrgUnit = $('#' + id).data('has-variable-org-units') & ($('#' + id).data('has-fixed-org-units'));

            if (hasVarAndFixedOrgUnit) {
                var formSectionFixed = $('<fieldset id="' + id + '-orgrel-section-fixed" class="form-group"></fieldset>')
                modalBodyFormSection.append(formSectionFixed);
                formSectionFixed.append('<legend class="cs-legend">' + dict['magicOrgUnitFix'] + '</legend>');

                var fixedHTML = response['fixed'];
                formSectionFixed.append(fixedHTML);

                modalBodyFormSection.append('<br><br>');
            }

            var formSectionfieldset = $('<fieldset class="form-group"></fieldset>');
            modalBodyFormSection.append(formSectionfieldset);

            // Label anzeigen?
            if (hasVarAndFixedOrgUnit)
                formSectionfieldset.append('<legend class="cs-legend">' + dict['magicOrgUnitVar'] + '</legend>')
            else if (showLabel)
                formSectionfieldset.append('<legend class="cs-legend">' + dict['groups'] + '</legend>');

            // Wir bauen einen Container für die Paragraphen //class="pb-2"
            var paraContainer = $('<div id="para-container-' + id + '" style="max-height: 400px; overflow-y: auto;"></div>');
            formSectionfieldset.append(paraContainer);

            function UpdateRemoveParaButtons() {
                // Wenn wir nur einen Paragraphen haben, wollen wir keine Remove-Buttons, sonst schon
                $('.editor-groups-' + id).length > 1 ? $('.editor-groups-btnremove-' + id).show() : $('.editor-groups-btnremove-' + id).hide();
            }

            function CreateNewParagraph(i, selectedList) {

                var id_i = id + '-' + i;

                function RemoveParagraphButton() {
                    if (matrixModus) {
                        return '<button id="btn-remove-para-' + id_i + '" data-id="editor-groups-container-' + id_i + '" class="btn btn-md btn-secondary editor-groups-btnremove-' + id + '"><i class="fa-light fa-trash"></i></button>';
                    } else {
                        return '';
                    }
                }
                paraContainer.append('<div class="orgrel-usersgroups mb-2" id="editor-groups-container-' + id_i + '">' +
                    '<div class="publisher">' +
                    '<select id="editor-groups-' + id_i + '" class="form-control tag-editor editor-groups-' + id + '" data-bs-toggle="select2" multiple></select>' +
                    '<div class="publisher-actions d-flex">' +
                    '<button id="btn-search-para-' + id_i + '" data-id="editor-groups-container-' + id_i + '" class="btn btn-md btn-secondary editor-groups-btn-search-' + id + '"><i class="fa-regular fa-sitemap"></i></button>' +
                    RemoveParagraphButton() +
                    '</div>' +
                    '</div>' +
                    '</div>');

                $('#btn-remove-para-' + id_i).click(function (event, param1) {
                    $('#' + $(this).data('id')).remove();
                    UpdateRemoveParaButtons();
                });

                $('#btn-search-para-' + id_i).click(function (event, param1) {
                    // Der Such-Button wurde geklickt: Wir schicken ein paar Infos an den Server
                    var request = {};

                    // Von welchem Paragraphen kam das?
                    request['paragraph'] = 'editor-groups-' + id_i;

                    // Und welche Gruppen sind hier bereits ausgewählt?
                    var selectedIDs = jquery('#editor-groups-' + id_i).select2('data');
                    var groupIDs = [];
                    for (var i = 0; i < selectedIDs.length; i++) {
                        groupIDs.push(selectedIDs[i].id);
                    }
                    request['groups'] = groupIDs;

                    // Jetzt per Event an den Server, damit dieser weitermachen kann
                    WebCompEventHandler('Action.ButtonSearchClicked', id, JSON.stringify(request));
                });

                var editorGroups = jquery('#editor-groups-' + id_i);
                // Daten einfügen
                editorGroups.empty();
                for (var j = 0; j < selectedList.length; j++) {
                    var newOption = new Option(selectedList[j].caption, selectedList[j].key, false, true);
                    editorGroups.append(newOption);
                }
                var searchproviderid_group = $('#' + id + '-orgrelsearchprovider-group').data('listselectorid');
                initSelect2(editorGroups, searchproviderid_group, dict['hintForTypingInputGroups']);
            }

            var paragraphs = response['paragraphs'];
            // Wir merken uns, ob "Komplette Organisation" angehakt ist
            var allGroups = false;

            // Wenn wir nichts haben, wollen wir trotzdem einen leeren Editor anzeigen
            if (paragraphs.length === 0) {
                CreateNewParagraph(0, []);
            } else {
                // Komplette Organisation?
                // UPS // DEPRECATED 
                if (inArrayUNSAFE(0, paragraphs[0])) {
                    allGroups = true;
                    CreateNewParagraph(0, []);
                } else {
                    // Sonst für jeden Para einen Editor
                    for (var i = 0; i < paragraphs.length; i++) {
                        CreateNewParagraph(i, paragraphs[i]);
                    }
                }
            }

            // Add Button
            if (matrixModus) {
                var addButtonFooter = $('<div class="text-center mt-3"></div>');
                modalBodyFormSection.append(addButtonFooter);
                var addButton = $('<button class="card-footer-item justify-content-start btn btn-link text-decoration-none" data-id="' + id + '" data-next="' + $('.editor-groups-' + id).length + '"><span><i class="fa fa-plus-circle me-1"></i>&nbsp;' + dict['addParagraph'] + '</span></button></footer>');
                addButtonFooter.append(addButton);
                addButton.click(function () {
                    // Nur neuen Paragraphen, falls wir nicht schon "Komplette Orga" ausgewählt haben
                    $(this).data('next', $(this).data('next') + 1);
                    CreateNewParagraph($(this).data('next'), []);

                    // Nach unten scrollen
                    paraContainer.scrollTop(paraContainer.prop("scrollHeight"));

                    UpdateRemoveParaButtons();
                });
            }

            // Komplette Organisation?
            if (properties['AllowSelectAll'] === '1') {

                // Beim Change müssen die Komponenten disabled/enabled werden
                checkBox.change(function () {
                    $('#editor-users-' + id).prop('disabled', AsType<HTMLInputElement>(this).checked);
                    $('.editor-groups-' + id).prop('disabled', AsType<HTMLInputElement>(this).checked);
                    if (matrixModus) {
                        addButton.prop('disabled', AsType<HTMLInputElement>(this).checked);
                        $('.editor-groups-btnremove-' + id).prop('disabled', AsType<HTMLInputElement>(this).checked);
                    }
                    $('.editor-groups-btn-search-' + id).prop('disabled', AsType<HTMLInputElement>(this).checked);

                    if (AsType<HTMLInputElement>(this).checked) {
                        alertNote.show();
                    } else {
                        alertNote.hide();
                    }

                });
                checkBox.prop('checked', allGroups);
                checkBox.trigger("change");
            }

            UpdateRemoveParaButtons();
        }
        /******************************************************************************************/
        function initSelect2(container, searchproviderid, hintForTypingInput) {
            // Editor als Select2 initialisieren
            container.select2({
                tags: false,
                templateResult: formatResult,
                matcher: matchCustom,
                dropdownParent: modal,
                minimumInputLength: 2,
                ajax: {
                    url: getBaseUrl(searchproviderid),
                    dataType: 'json',
                    processResults: transformResult,
                    delay: 250
                },
                // UPS // Es darf nur ein Language Attribut geben
                // language: 'de', 
                language: {
                    inputTooShort: function () {
                        return hintForTypingInput;
                    }
                }
            });

            // ergänzen das Select2 JS
            container.on('select2:selecting', function (e) {
                // wir prüfen das ausgewählte Element
                var data = e.params.args.data;
                // wenn die ID -1 ist, dann wurde "keine Ergebnisse" ausgewählt
                // das wollen wir dann hier abbrechen
                if (data.id == -1) {
                    e.preventDefault();
                    return;
                }
            });

            container.on('select2:open', function () {
                // der Editor wurde angeklickt, das inline CSS von Select2 Outputfenster muss ergäntzt werden
                let select2Container = document.querySelector('.select2-container--open:not(.select2)') as HTMLElement;
                if (assigned(select2Container)) {
                    let select2ResultContainer = select2Container.querySelector('.select2-results__options') as HTMLElement;
                    if (assigned(select2ResultContainer)) {
                        // das child soll im sichbaren Bereich bleiben; 40px Abstand zum unten Rand
                        // Hinweis: Zeilenumbrüche (~25px) werden von den 40px abgezogen
                        select2ResultContainer.style.maxHeight = 'calc(100vh - 40px - ' + select2Container.style.top + ')';
                    }
                }
            });

            container.next().find('.select2-selection').on('mousedown', function (e) {
                // im Mousedown wollen wir hier raus, falls auf Entfernen geklickt wurde, 
                // da das Entfernen sonst mit Scroll nicht klappt (x-Button hat nochmal eigene Events)
                if (e.target.classList.contains('select2-selection__choice__remove') || document.elementFromPoint(e.pageX, e.pageY).classList.contains('select2-selection__choice__remove')) {
                    e.preventDefault();
                }
            });
        }

        /******************************************************************************************/

        function formatResult(data) {
            if (!data.id) {
                return 'Suche';
            }

            // Hier zeigen wir an, dass es keine/zu viele Suchergebnisse gab
            if (data.id == -1) {
                return $('<div class="text-center"><i class="fa-solid fa-search text-danger fa-2x"></i><br><strong>'
                    + String2HTMLEntity(data.display) + '</strong><br>' + data.description + '</div>');
            }

            var markup = '<div><div class="media"><span class="mediaimg" title="' + data.typename + '">' + data.image + '</span><div class="media-body">';
            if (data.description.trim() == '') {
                markup += '<h3 class="mx-2" title="' + String2HTMLEntity(data.text) + '">' + data.display + '</h3>';
            } else {
                markup += '<h4 class="mt-0 mb-0 mx-2" title="' + String2HTMLEntity(data.text) + '">' + data.display + '</h4>';
                markup += '<h5 class="text-muted my-0 mx-2">' + data.description + '</h5>';
            }
            markup += '</div></div></div>';
            return $(markup);

        }

        function transformResult(data): { results: Array<unknown> } {
            var response = data;
            if (response.error.errorCode == 1) {
                let results = [];
                let itemTemp = {
                    id: -1,
                    display: response.error.errorMsg,
                    description: response.error.errorDescription,
                };

                results.push(itemTemp);

                return {
                    results: results
                };
            } else {
                let results = [];
                response.items[0].forEach(function (item) {
                    let itemTemp = {
                        id: item.key,
                        // unescapter text
                        text: item.captionraw,
                        // escapter text
                        display: item.caption,
                        description: item.description,
                        image: item.image,
                        typename: item.typename,
                        groupname: item.groupname,
                    };
                    results.push(itemTemp)
                });
                return {
                    results: results
                };
            }
        }
        /******************************************************************************************/
        function storeUser(): string {
            var editorUsers = jquery('#editor-user-' + id);
            var selectedUser = editorUsers.select2('data');
            var request = {};
            request['userOID'] = selectedUser[0].id;
            return String((sendComponentRequestGetJson(id, request) as any).displayText);
        }

        function storeUsers(): string {
            var editorUsers = jquery('#editor-users-' + id);
            var selectedUsers = editorUsers.select2('data');

            var userIDs = [];

            // Nur wenn nicht "Komplette Organisation" angehakt ist, setzen wir alle User rein
            if (!$('#checkBoxAll-' + id).is(':checked'))
                for (var i = 0; i < selectedUsers.length; i++) {
                    userIDs.push(selectedUsers[i].id);
                }

            var requestIDs = {};
            requestIDs['userOIDs'] = userIDs;

            return String((sendComponentRequestGetJson(id, requestIDs) as any).displayText);
        }

        function storeGroups(): string {
            var paragraphs = [];

            // Komplette Organisation?
            if ($('#checkBoxAll-' + id).is(':checked')) {
                paragraphs.push([0]);
            } else {
                $('.editor-groups-' + id).each(function (index) {
                    var selectedIDs = jquery(this).select2('data');
                    var groupIDs = [];
                    for (var i = 0; i < selectedIDs.length; i++) {
                        groupIDs.push(selectedIDs[i].id);
                    }
                    paragraphs.push(groupIDs);
                });
            }
            var request = {};
            request['paragraphs'] = paragraphs;
            return String((sendComponentRequestGetJson(id, request) as any).displayText);
        }

        function addMyUnits(mode: string): string {
            var request = {};
            request['request'] = mode;
            return String((sendComponentRequestGetJson(id, request) as any).displayText);
        }

        // Button im Dropdown
        let areaDropDown = $('#' + id + '-orgRelDropDownArea');
        let btnDropDown = areaDropDown.find('button').first();

        let dropDownMenu = $('<ul class="dropdown-menu dropdown-menu-right dropdown-menu-orgrel"></ul>');
        dropDownMenu.insertAfter(btnDropDown);

        let modalContainer = getModalDialogContainer(orgRelEditorComp[0]);
        if (assigned(modalContainer)) {
            // wenn wir in einem Modal sind, dann darf das Dropdown nicht an den Button gehangen werden,
            // sondern muss außerhalb des Modals liegen
            btnDropDown[0].addEventListener('click', function (e) {
                e.stopPropagation();
                let dropdown = dropDownMenu[0];

                let openDropdown = true;
                // die Klasse fügen wir hinzu, damit wir show/hide selbst steuern können,
                // da das normale show hier schon gesetzt ist
                if (dropdown.classList.contains('dropdown-menu-orgrel-show')) {
                    openDropdown = false;
                }

                // alle anderen schließen
                document.querySelectorAll('.dropdown-menu-orgrel').forEach(function (element: Element) {
                    if (element instanceof HTMLElement) {
                        element.classList.remove('show');
                        element.classList.remove('dropdown-menu-orgrel-show');
                    }
                })

                // wenn das akutelle schon auf war und auf den Button geklickt wird
                // schließen wir das jetzt
                if (!openDropdown) {
                    return;
                }

                // dropdown ggf. verschieben
                dropDownMenu.closest('.modal').append(dropDownMenu);
                // das aktuelle sichtbar machen
                dropdown.classList.add('show');
                dropdown.classList.add('dropdown-menu-orgrel-show');

                let arrow = dropdown.querySelector('.dropdown-arrow') as HTMLElement;
                // rect vom Button holen
                let rect = btnDropDown[0].getBoundingClientRect();
                let left = rect.left - dropdown.offsetWidth - arrow.offsetWidth / 2;

                // und neue Positionen setzen (muss leider so unschön gemacht werden, da die originalen Werte alle !important haben!)
                dropdown.style.cssText = dropdown.style.cssText + 'left:' + left + 'px !important;' + 'right: unset !important;' + 'top:' + rect.bottom + 'px !important;';
            });

            areaDropDown[0].addEventListener('hide.bs.dropdown', event => {
                // ClickEvent kommt nur, wenn wir außerhalb des Buttons/Dropdowns klicken
                if ('clickEvent' in event) {
                    dropDownMenu[0].classList.remove('dropdown-menu-orgrel-show');
                }
            });
        }

        // erstmal die Struktur: Menu -> Submenu | LöschenButton
        dropDownMenu.append('<li class="dropdown-arrow"></li>');
        let dropDownSubMenu = $('<li  class="dropdown-submenu"></li>');
        dropDownMenu.append(dropDownSubMenu);
        dropDownMenu.append('<li class="dropdown-divider"></li>');
        let btnDropDown_delete = $('<button data-drpdwn="deleteEntry" class="dropdown-item" type="button"><i class="fa-light fa-trash-alt pe-2"></i>' + dict['delete'] + '</button>');
        dropDownMenu.append(btnDropDown_delete);
        // und nun das Submenü Erstellen
        let btnDropDownSubmenuChoose = $('<a class="dropdown-item dropdown-toggle dropdown-chevron">' + dict['choose'] + '</a>');
        dropDownSubMenu.append(btnDropDownSubmenuChoose);
        let btnDropDownSubmenuWrapper = $('<div class="dropdown-submenu-wrapper"><ul class="dropdown-menu dropdown-menu-right cs-dropdown-container"></ul></div>');
        dropDownSubMenu.append(btnDropDownSubmenuWrapper);
        let dropDownSubmenuListe = $('<ul class="dropdown-list"></ul>');
        btnDropDownSubmenuWrapper.find('ul').append($('<li>').append(dropDownSubmenuListe));
        // wir legen die button an
        let btnDropDown_AddMe = $('<button data-drpdwn="addMe" class="dropdown-item" type="button">' + dict['mySelf'] + '</button>');
        let btnDropDown_AddMitgliedschaften = $('<button data-drpdwn="addMemberships" class="dropdown-item" type="button">' + dict['myMemberships'] + '</button>');
        let btnDropDown_AddStandorte = $('<button data-drpdwn="addSites" class="dropdown-item" type="button">' + dict['mySites'] + '</button>');
        let btnDropDown_AddKompletteOrganisation = $('<button data-drpdwn="addAllGroups" class="dropdown-item" type="button">' + dict['allGroups'] + '</button>');

        // und sehen, welche wir nun brauchen
        if (editorType == 'user' || editorType == 'users') {
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddMe));
        } else if (editorType == 'groups') {
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddMitgliedschaften));
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddStandorte));
        } else if (editorType == 'usersgroups') {
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddMe));
            dropDownSubmenuListe.append('<li class="dropdown-divider"></li>');
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddMitgliedschaften));
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddStandorte));
        }
        // manchmal können wir auch die komplette Organisation hinzufügen
        if (properties['AllowSelectAll'] === '1') {
            dropDownSubmenuListe.append('<li class="dropdown-divider"></li>');
            dropDownSubmenuListe.append($('<li>').append(btnDropDown_AddKompletteOrganisation));
        }

        // und nun die Klickevents auf die Buttons
        var btnDropDownClick = function (e) {
            let response = addMyUnits($(this).data('drpdwn'));
            // Anzeigetext einfügen
            orgRelEditorComp.html(response);

            dispatchDirty();
        }
        // und nun die Klickevents auf die Buttons
        btnDropDown_AddMe[0].addEventListener('click', btnDropDownClick);
        btnDropDown_AddMitgliedschaften[0].addEventListener('click', btnDropDownClick);
        btnDropDown_AddStandorte[0].addEventListener('click', btnDropDownClick);
        btnDropDown_delete[0].addEventListener('click', btnDropDownClick);
        if (properties['AllowSelectAll'] === '1') {
            btnDropDown_AddKompletteOrganisation[0].addEventListener('click', btnDropDownClick);
        }

        // unser Submenü müssen wir auch noch ansteuern
        btnDropDownSubmenuChoose[0].addEventListener('mouseover', e => {
            let submenuWrapper = btnDropDownSubmenuChoose.next('div');
            let subMenu = $(submenuWrapper).find('.dropdown-menu').first();
            subMenu.addClass('show');
            var menuItemPos = btnDropDownSubmenuChoose.position();
            $(submenuWrapper).css({ top: menuItemPos.top - 8 }); // wir haben noch ein wenig padding
        });
        btnDropDownSubmenuChoose[0].addEventListener('click', e => {
            e.stopPropagation();
        });
        dropDownMenu.children(':not(.dropdown-submenu)').each(function (index) {
            $(this)[0].addEventListener('mouseover', e => {
                dropDownMenu.find('.dropdown-submenu .show').removeClass('show');
            });
        });
        areaDropDown.on('hide.bs.dropdown', event => {
            dropDownMenu.find('.dropdown-submenu .show').removeClass('show');
        });

        /******************************************************************************************/
        // focus ins erste Element
        $(`#${modalId}`).on('shown.bs.modal', function (e) {
            let selectID = modalBodyFormSection.find('select').first().attr('ID');
            let editor = jquery(`#${selectID}`);
            // wir machen den focus nur, wenn das Element auch leer ist
            // .val() gibt number, string oder ein object zurück
            let valueString = '';
            if ((typeof editor.val() === 'object')) {
                valueString = Object(editor.val()).join('');
            } else if (!Array.isArray(editor.val())) {
                valueString = String(editor.val());
            }
            if (valueString.length === 0) {
                editor.select2('open');
            }
        })
        // BEARBEITEN
        btnEdit.on('click', function () {
            // Leeren
            modalBodyFormSection.empty();
            // Restore
            if (editorType == 'user') {
                restoreUser(false);
            } else if (editorType == 'users') {
                restoreUser(true);
            } else if (editorType == 'groups') {
                restoreGroups(false);
            } else if (editorType == 'usersgroups') {
                restoreUser(true, true);
                modalBodyFormSection.append('<br><br>');
                restoreGroups(true);
            }
            // Dialog anzeigen
            $(`#${modalId}`).modal('show');
        });
        /******************************************************************************************/
        // ANWENDEN
        btnSubmit.on('click', function () {
            let response = '';

            // Store
            if (editorType == 'user') {
                response = storeUser();
            } else if (editorType == 'users') {
                response = storeUsers();
            } else if (editorType == 'groups') {
                response = storeGroups();
            } else if (editorType == 'usersgroups') {
                storeUsers();
                response = storeGroups();
            }

            // Anzeigetext einfügen
            orgRelEditorComp.html(response);

            dispatchDirty();

            btnSubmit.trigger('change');
            // Dialog schließen
            modal.modal('hide');
        });
    }
});
