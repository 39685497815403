import { Point } from '../../../../classes/geometry';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { AnchorHandle } from '../Edges/TEdgeBase';
import { TBaseShape } from './TBaseShape';

export class TShapeInformation extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 710;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let wc = this.boundingRect.width / 10;

        let path = [
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'h', this.boundingRect.width - wc,
            'l', -wc, this.boundingRect.height,
            'h', -(this.boundingRect.width - wc),
            'Z'
        ].join(' ');

        return path;
    }




    override getAnchorPoint(sourceHandle: AnchorHandle): Point {
        let wc = this.boundingRect.width / 10;

        // kein inherited
        switch (sourceHandle) {
            case AnchorHandle.ahCenter:
                return new Point(this.boundingRect.left + Math.floor(this.boundingRect.width / 2), this.boundingRect.top + Math.floor(this.boundingRect.height / 2));
            case AnchorHandle.ahTop:
                return new Point(this.boundingRect.left + Math.floor(this.boundingRect.width / 2), this.boundingRect.top);
            // anker rechts nach links
            case AnchorHandle.ahRight:
                return new Point(this.boundingRect.right - Math.floor(wc / 2), this.boundingRect.top + Math.floor(this.boundingRect.height / 2));
            case AnchorHandle.ahBottom:
                return new Point(this.boundingRect.left + Math.floor(this.boundingRect.width / 2), this.boundingRect.bottom);
            // anker links nach rechts
            case AnchorHandle.ahLeft:
                return new Point(this.boundingRect.left + Math.floor(wc / 2), this.boundingRect.top + Math.floor(this.boundingRect.height / 2));
            default:
                throw 'no valid AnchorHandle';
        }
    }
}