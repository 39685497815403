import { getAngleByPoints, Point } from "../../../../classes/geometry";
import { assigned } from "../../../../utils/helper";
import { TTutorialGroupAction } from "../Actions/TTutorialGroupAction";
import { TTutorialShapeRotationAction } from "../Actions/TTutorialShapeRotationAction";
import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TBaseTutorialObject } from "../TBaseTutorialObject";

export function initRotateEvents(editor: TwsTutorialEditor) {

    editor.svg.addEventListener('mousedown', event => handleMouseDown(event));

    let selectedElements: Array<TBaseTutorialObject>;

    let hasElement: boolean = false;

    let lastMousePosX = 0;
    let lastMousePosY = 0;

    let oldAngle = 0;

    let animationIds: Array<number> = [];

    function getMousePosition(event) {
        let CTM = editor.svg.getScreenCTM();
        if (event.touches) {
            event = event.touches[0];
        }
        return {
            x: (event.clientX - CTM.e) / CTM.a,
            y: (event.clientY - CTM.f) / CTM.d
        };
    }

    function handleMouseDown(event) {
        // wenn wir nicht rotatable sind brechen wir hier schon ab
        if (!event.target.classList.contains('rotateHandler')) {
            return;
        }

        // eventlistener intialisieren
        document.documentElement.addEventListener('mousemove', handleMouseMove);
        document.documentElement.addEventListener('mouseup', handleMouseUp);

        // gespeicherte Move Koordinaten leeren
        lastMousePosX = 0;
        lastMousePosY = 0;

        selectedElements = editor.objectList.filter(x => x instanceof TBaseTutorialShape && x.selected === true);
        hasElement = true;
    }

    function handleMouseMove(event: MouseEvent) {

        if (hasElement) {
            event.preventDefault();

            let coord = getMousePosition(event);
            let mousePosX = coord.x;
            let mousePosY = coord.y;

            // wenn wir nichts bewegen müssen können wir uns die rechnerei sparen
            if (mousePosX === lastMousePosX && mousePosY === lastMousePosY) {
                return;
            }

            // alte Berechnungen stoppen
            animationIds.forEach(id => {
                window.cancelAnimationFrame(id);
            });

            animationIds.push(window.requestAnimationFrame(timestamp => {
                // Shapes bewegen
                selectedElements.forEach(element => {
                    let shape = element as TBaseTutorialShape;

                    if (!shape.isRotatable) {
                        return;
                    }

                    let angle = getAngleByPoints(shape.center(), new Point(shape.boundingRect.right, shape.boundingRect.top), new Point(mousePosX, mousePosY));

                    // 180° addieren, wenn wir über der geraden durch Punkt A und B liegen
                    let dx = shape.boundingRect.right - shape.center().x;
                    let dy = shape.boundingRect.top - shape.center().y;
                    let mx = mousePosX - shape.center().x;
                    let my = mousePosY - shape.center().y;
                    let cross = dx * my - dy * mx;
                    let below = (cross > 0)
                    if (dx != 0)
                        if (dy / dx < 0)
                            below = !below;

                    if (below)
                        angle = 360 - angle;

                    shape.setRotation(Math.round(angle), true);
                });

                // Editorgröße anpassen; -> macht implizit schon ein invalidate!
                editor.checkEditorSize();
            }));

            lastMousePosX = mousePosX;
            lastMousePosY = mousePosY;
        }
    }

    function handleMouseUp() {

        hasElement = false;

        if (assigned(selectedElements) && selectedElements.length > 0) {
            let actionGroup = new TTutorialGroupAction(null, editor);

            selectedElements.forEach(selectedElement => {
                let selectedShape = selectedElement as TBaseTutorialShape;

                let angle = selectedShape.rotation;

                // Hier muss das Translate als dx,dy umgerechnet und zurückgesetzt werden
                let rotAction = new TTutorialShapeRotationAction(selectedShape, editor);
                rotAction.setValue(angle);

                actionGroup.actions.push(rotAction);
            });

            if (actionGroup.actions.length > 0) {
                editor.actionList.addClientAction(actionGroup);
                editor.actionList.actions[editor.actionList.listPointer].performAction();
            }

            selectedElements.length = 0;
        }

        // und die eventlistener entfernen
        document.documentElement.removeEventListener('mousemove', handleMouseMove, false);
        document.documentElement.removeEventListener('mouseup', handleMouseUp, false);
    }
}