import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { getObjByCID } from '../Utils/TProcessEditorShapeHandling';
import { ActionType, TCustomNodeAction } from "./TCustomAction";
import { TNodeDeleteAction } from './TNodeDeleteAction';

export class TNodeCreateAction extends TCustomNodeAction {
    cid: number;

    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.cid = undefined;
    }

    typeID(): ActionType {
        return ActionType.patShapeCreate;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.cid = obj.cid;

        this.refElement = getObjByCID(this.cid, this.svgEditor);
        this.refElement.id = obj.refElementID;

        this.svgEditor.registerObj(this.refElement);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        return obj;
    }

    setValue(cid: number): void {
        this.cid = cid;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.create();
        this.svgEditor.registerObj(this.refElement);
    }

    inverseAction(): TCustomNodeAction {
        let result = new TNodeDeleteAction(this.refElement, this.svgEditor);
        result.setValue(this.cid);
        this.svgEditor.unregisterObj(result.refElement);

        return result;
    }
}