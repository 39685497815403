import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TWebTextEdit extends TRenderWebComponent {
    editObj: HTMLInputElement;
    hasServerChangeEvent: boolean;
    hasServerKeyPressEvent: boolean;
    keyPressDelayMilliSeconds: number;
    keyPressTimeout: number;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TWebTextEdit';
        this.editObj = AsType<HTMLInputElement>(this.obj);

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent);
        this.hasServerKeyPressEvent = boolFromStr(this.obj.dataset?.hasServerKeypressEvent);
        this.keyPressTimeout = null;
        this.keyPressDelayMilliSeconds = 0;
        if (this.obj.dataset?.keypressdelaymilliseconds) {
            this.keyPressDelayMilliSeconds = Number(this.obj.dataset?.keypressdelaymilliseconds);
        }
    }

    override initDomElement() {
        super.initDomElement();

        this.initEventlistener();
    }

    protected initEventlistener(): void {
        this.editObj.addEventListener('change', event => this.handleEditObjChanged(event));
        // https://stackoverflow.com/questions/4843472/javascript-listener-keypress-doesnt-detect-backspace
        // wir verwenden das input event statt keydown https://stackoverflow.com/questions/10911047/keydown-event-new-value
        this.editObj.addEventListener('input', event => this.handleEditInputEvent(event as KeyboardEvent));
        this.editObj.addEventListener('paste', event => this.handlePasteEvent(event));
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    protected validate(): void { }

    protected isValidInput(): Boolean {
        return true;
    }

    protected handleEditInputEvent(event: KeyboardEvent): void {
        this.validate();
        this.handleEditObjTextChanged(event);
    }

    protected handlePasteEvent(event: ClipboardEvent): void { }

    protected handleEditObjChanged(event: Event): void {
        this.notifyComponentChanged();

        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    protected handleEditObjTextChanged(event: KeyboardEvent): void {
        if (this.isValidInput()) {
            this.doKeypressEventAction();
        }
    }

    protected doKeypressEventAction(): void {
        this.notifyComponentChanged();

        if (this.hasServerKeyPressEvent) {
            // falls wir gerade senden wollen, brechen wir das ab, damit nicht zu viele Anfragen an den Server gesendet werden, die veraltet sind...
            if (this.keyPressTimeout) {
                clearTimeout(this.keyPressTimeout);
                this.keyPressTimeout = null;
            }
            this.keyPressTimeout = window.setTimeout(() => { WebCompEventHandler('OnKeyPress', this.id); }, this.keyPressDelayMilliSeconds);
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Text', $(this.obj).val()]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Text':
                this.editObj.value = value;
                this.validate();
                break;
            case 'Enabled':
                this.editObj.disabled = value == '0';
                break;
            case 'Readonly':
                if (value == '1') {
                    $(this.obj).prop('readonly', true);
                } else if (value == '0') {
                    $(this.obj).prop('readonly', false);
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
            case 'MaxLength':
                if (parseInt(value) >= 0) {
                    $(this.obj).attr('maxlength', value);
                }
                else {
                    $(this.obj).removeAttr('maxlength');
                }
                break;
            case 'KeypressDelay':
                this.keyPressDelayMilliSeconds = Number(value);
                break;
        }
    }
}

export class TwcButtonEdit extends TWebTextEdit {
    inputGroup: HTMLElement;
    btnPanel: HTMLElement;
    readOnly: boolean;
    onlyButtonAllowed: boolean;
    enabled: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcButtonEdit';
        this.inputGroup = document.getElementById(`${this.id}-inputGroup`);

        this.readOnly = boolFromStr(this.inputGroup.dataset.readOnly, false);
        this.onlyButtonAllowed = boolFromStr(this.inputGroup.dataset.buttonOnly, false);
        this.enabled = boolFromStr(this.inputGroup.dataset.enabled, true);

        this.btnPanel = document.getElementById(`${this.id}-btnPanel`)
    }

    override setFocus(): void {
        super.setFocus();
        this.obj.parentElement.classList.toggle('focus', true);
    }

    override writeProperties(key: string, value: string): void {
        super.writeProperties(key, value);
        switch (key) {
            case 'Enabled':
                this.enabled = boolFromStr(value);
                break;
            case 'Readonly':
                this.readOnly = boolFromStr(value);
            case 'OnlyButtonAllowed':
                this.onlyButtonAllowed = boolFromStr(value);
                break;
        }
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        super.doRender(timestamp);

        // Input        
        this.inputGroup.classList.toggle('disabled', !this.enabled || this.readOnly || this.onlyButtonAllowed); // optisch deaktivieren
        this.editObj.disabled = !this.enabled || this.readOnly || this.onlyButtonAllowed; // "echte" Texteingabe deaktivieren

        // Buttons
        this.btnPanel.classList.toggle('d-none', this.readOnly); // ausblenden bei readOnly
        Array.from(this.btnPanel.children).forEach(element => { // Button Elemente disablen
            if (element instanceof HTMLInputElement) {
                element.disabled = !this.enabled;
            }
        });
    }
}

export class TwcButtonEditEx extends TwcButtonEdit {

    minInputForOnchangeEvent: number;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcButtonEditEx';

        this.minInputForOnchangeEvent = Number(this.inputGroup.dataset?.minimalinput);

        if (isNaN(this.minInputForOnchangeEvent)) {
            throw `${this.classtype} Error: minInput is not a number`;
        }
    }

    override initDomElement() {
        super.initDomElement();
    }

    override isValidInput(): Boolean {
        let valid = false;
        // mindestens x Zeichen
        if (this.editObj.value.trim() != '') {
            valid = (this.editObj.value.length >= this.minInputForOnchangeEvent);
        } else {
            // leer darf es sein
            valid = true;
        }
        return valid;
    }
}

export class TwcEditNumber extends TWebTextEdit {
    private validInput: RegExp;
    private minValue: number;
    private maxValue: number;
    private autoCorrect: boolean;
    private defaultvalue: number;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcEditNumber';

        this.validInput = /-$|[0-9]$/;
        this.minValue = 0;
        this.maxValue = 0;
        this.autoCorrect = true;

        if (this.editObj.min && this.editObj.max) {
            this.minValue = parseFloat(this.editObj.min);
            this.maxValue = parseFloat(this.editObj.max);
        }

        this.autoCorrect = boolFromStr(this.editObj.dataset.correct, true);
        this.defaultvalue = parseInt(this.editObj.dataset.defaultvalue);

        this.validate();
    }

    protected override validate(): void {
        let valid = false;

        if (this.editObj.value !== '') {
            valid = true;

            if (this.minValue != this.maxValue) {
                let val = parseFloat(this.editObj.value);
                valid = valid && this.minValue <= val && this.maxValue >= val;
            }
        }

        valid = valid && this.editObj.value.indexOf('.') == -1;
        this.editObj.classList.toggle('text-danger', !valid);
    }

    protected override handleEditObjTextChanged(event: KeyboardEvent): void {
        if (this.validInput.test(event.key)) {
            super.handleEditObjTextChanged(event);
        }
    }

    protected override handleEditObjChanged(event: Event): void {
        if (this.autoCorrect && this.editObj.value == '') {
            this.editObj.value = this.defaultvalue.toString();
        }

        if (this.minValue != this.maxValue) {
            let val = parseInt(this.editObj.value);
            let valid = this.minValue <= val && this.maxValue >= val;
            if (!valid && this.autoCorrect) {
                if (this.minValue > val) {
                    this.editObj.value = this.minValue.toString();
                } else if (this.maxValue < val) {
                    this.editObj.value = this.maxValue.toString();
                }
            }
        }

        this.validate();
        super.handleEditObjChanged(event);
    }

    override writeProperties(key: string, value: string): void {
        super.writeProperties(key, value);

        switch (key) {
            case 'MinValue':
                this.editObj.min = value;
                this.minValue = parseFloat(value);
            case 'MaxValue':
                this.editObj.max = value;
                this.maxValue = parseFloat(value);
        }
    }
}

export class TwcEditDouble extends TWebTextEdit {
    private validInput: RegExp;
    private minValue: number;
    private maxValue: number;
    private precision: number;
    private autoCorrect: boolean;
    private defaultvalue: number;
    private allowEmpty: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcEditDouble';

        this.minValue = 0;
        this.maxValue = 0;
        this.precision = -1;
        this.autoCorrect = true;
        this.allowEmpty = false;

        if (this.editObj.min && this.editObj.max) {
            this.minValue = parseFloat(this.editObj.min);
            this.maxValue = parseFloat(this.editObj.max);
        }

        if (this.editObj.dataset.precision) {
            this.precision = parseInt(this.editObj.dataset.precision);
        }

        this.autoCorrect = boolFromStr(this.editObj.dataset.autocorrect, true);
        this.allowEmpty = boolFromStr(this.editObj.dataset.allowempty, false);

        let decimal = '.';
        if (this.editObj.dataset.decimal) {
            decimal = this.editObj.dataset.decimal;
        }

        this.defaultvalue = parseFloat(this.editObj.dataset.defaultvalue);

        this.validInput = new RegExp("-$|[0-9]$|\\" + decimal + '$');

        this.editObj.value = this.round(this.editObj.value);
        this.validate();
    }

    private calcPrecision(): number {
        let value = parseFloat(this.editObj.value);
        let num = value.toString();
        num = num.replace(/0+$/g, '');
        let pos = num.indexOf('.');
        if (pos >= 0) {
            return num.length - pos - 1;
        } else {
            return pos;
        }
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Text':
                this.editObj.value = this.round(value);
                this.validate();
                break;
            default:
                super.writeProperties(key, value);
                break;
        }
    }

    protected roundValue() {
        let p = this.calcPrecision();
        if (this.precision > -1 && p > 0 && p > this.precision) {
            let pos = this.editObj.value.indexOf('.');
            let intPart = parseFloat(this.editObj.value.substring(0, pos));
            let decimalPart = parseFloat(this.editObj.value.substring(pos));
            decimalPart = Math.round(decimalPart * Math.pow(10, this.precision)) / Math.pow(10, this.precision);
            intPart += decimalPart;
            this.editObj.value = intPart.toFixed(this.precision);
        }
    }

    protected override validate(): void {
        let valid = false;

        if (this.editObj.value !== '') {
            valid = true;

            if (valid && this.minValue != this.maxValue) {
                let val = parseFloat(this.editObj.value);
                valid = this.minValue <= val && this.maxValue >= val;
            }
        }

        this.editObj.classList.toggle('text-danger', !valid);
    }

    protected override handlePasteEvent(event: ClipboardEvent): void {
        super.handlePasteEvent(event);

        // Wert ggf. anpassen, validate geschieht später im Change
        this.editObj.value = this.round(this.editObj.value);
    }

    protected override handleEditObjTextChanged(event: KeyboardEvent): void {
        if (this.validInput.test(event.key)) {
            super.handleEditObjTextChanged(event);
        }
    }

    protected round(value: string): string {
        let val = value

        // wenn leer nicht erlaubt ist, ersetzen wir leer durch Standardwert
        if ((value == '') && !this.allowEmpty) {
            val = String(this.defaultvalue);
        }

        // nur wenn autoCorrect und maximale Nachkommastellen gesetzt
        if (this.autoCorrect && this.precision >= 0) {
            // runden, bzw. Nullen auffüllen
            val = parseFloat(val).toFixed(this.precision);
        }
        return val;
    }

    protected override handleEditObjChanged(event: Event): void {
        // ganz am Ende ggf. runden und Nullen auffüllen
        this.editObj.value = this.round(this.editObj.value);

        // Grenzen ok? sonst Grenzen setzen
        if (this.minValue != this.maxValue) {
            let val = parseFloat(this.editObj.value);
            let valid = this.minValue <= val && this.maxValue >= val;
            if (!valid && this.autoCorrect) {
                if (this.minValue > val) {
                    this.editObj.value = this.minValue.toString();
                } else if (this.maxValue < val) {
                    this.editObj.value = this.maxValue.toString();
                }
            }
        };

        // Text am Ende anpassen
        this.validate();

        super.handleEditObjChanged(event);
    }
}