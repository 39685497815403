import Color from 'color';
import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';
import { TBaseTutorialShape } from '../Shapes/TBaseTutorialShape';
import { TCustomTutorialShapeAction, TutorialActionType } from './TTutorialEditorAction';

export class TTutorialShapeBorderColorAction extends TCustomTutorialShapeAction {
    oldBorderColor: Color;
    newBorderColor: Color;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeBorderColor;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldBorderColor = Color(obj.oldBorderColor);
        this.newBorderColor = Color(obj.newBorderColor);
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldBorderColor = this.oldBorderColor.hex();
        obj.newBorderColor = this.newBorderColor.hex();

        return obj;
    }

    setValue(newBorderColor: Color): void {
        this.newBorderColor = newBorderColor;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setBorderColor(this.newBorderColor);
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeBorderColorAction(this.refElement, this.svgEditor);
        result.setValue(this.oldBorderColor);

        return result;
    }
}
