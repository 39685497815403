import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeDecision extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 200;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let w2 = this.boundingRect.width / 2;
        let h2 = this.boundingRect.height / 2;

        let path = [
            // Kante oben
            'M', this.boundingRect.left + w2, this.boundingRect.top,
            'l', w2, h2,
            'l', -w2, h2,
            'l', -w2, -h2,
            'Z'
        ].join(' ');

        return path;
    }
}