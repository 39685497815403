import $ from 'jquery';
import select2 from 'select2/dist/js/select2.full';
import { sendComponentRequestGetJson, WebCompEventHandler } from '../../../core/communication';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


// //Hook up select2 to jQuery
select2($);

enum SuggestionRequests {
    unknown = 0,
    requestAllTemplates = 1,
}

class SuggestionCommand {
    command: SuggestionRequests;

    constructor() {
        this.command = SuggestionRequests.unknown;
    }
}

export class TwcSuggestions extends TWebComponent {
    text: string;
    select2: JQuery<HTMLElement>;
    hasChangeEvent: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcSuggestions';
        this.text = '';
        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initializeSelect2();

        // Wir setzen die initiale SelectedID
        let selectedID = $('#' + this.id).data('selectedid');
        $('#' + this.id).val(selectedID);

        // Wir setzen den initialen Status
        this.setReadOnly(boolFromStr(this.obj.dataset.readonly));
        this.setEnabled(boolFromStr(this.obj.dataset.enabled));
        this.setVisible(boolFromStr(this.obj.dataset.visible));
    }

    initializeSelect2(): void {
        $("#" + this.id).select2({
            width: '100%',
            placeholder: this.obj.dataset.placeholder,
            allowClear: true
        });
        this.select2 = $('#select2-' + this.id + '-container').parent().parent().parent(); // Das select2 fügt ne relativ komplexe dom struktur

        var self = this;

        $("#" + this.id).on({
            // neues Element
            'select2:select': function () {
                self.notifyComponentChanged();
                if (self.hasChangeEvent) {
                    WebCompEventHandler('OnChange', self.id);
                }
            },
            //Element weg
            'select2:unselect': function () {
                self.notifyComponentChanged();
                if (self.hasChangeEvent) {
                    WebCompEventHandler('OnChange', self.id);
                }
            }
        });
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ID', $('#' + this.id).val() ? $('#' + this.id).val() : -1]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                if (value == '1') {
                    this.setVisible(true);
                } else if (value == '0') {
                    this.setVisible(false);
                }
                break;
            case 'State':
                if (value == '0') {
                    //Disabled
                    this.setEnabled(false);
                } else if (value == '1') {
                    //ReadOnly
                    this.setReadOnly(true);
                } else if (value == '2') {
                    //Enabled
                    this.setEnabled(true);
                }
        }
    }

    setReadOnly(readOnly: boolean): void {
        $('#' + this.id).prop('readonly', readOnly);
    }

    setEnabled(enabled: boolean): void {
        $('#' + this.id).prop('disabled', !enabled);
    }

    setVisible(visible: boolean): void {
        this.select2.toggleClass('d-none', !visible);
    }
}

export class TwcTemplatedSuggestions extends TwcSuggestions {
    templates: Array<any>;

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcTemplatedSuggestions';

        this.templates = [];

        let cmd = new SuggestionCommand();
        cmd.command = SuggestionRequests.requestAllTemplates;
        this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd));
    }

    override initializeSelect2(): void {

        $('#' + this.id).select2({
            width: '100%',
            templateResult: function (data) {
                var result = $('<div>' + this.getTemplateFromID(data.id) + '</div>');
                return result;
            }.bind(this),
            placeholder: $('#' + this.id).data('placeholder'),
            allowClear: true
        });

        // Wir merken uns die oberste Komponente
        this.select2 = $('#select2-' + this.id + '-container').parent().parent().parent(); // Das select2 fügt ne relativ komplexe dom struktur   
    }

    handleRequestResult(response: any) {
        switch (response.requestedCommand) {
            case SuggestionRequests.requestAllTemplates:
                this.templates = response.data;
                this.initializeSelect2();
                break;
        }

        if (response.errorCode != 0)
            MsgNotify('Error occured: ' + response.errorMessage + ' [' + response.errorCode + ']', MessageType.Danger);
    }

    getTemplateFromID(id: any) {
        if (id === undefined)
            return '';

        return this.templates.find(x => x.id == id).template;
    }
}

