import Cropme from 'cropme';
import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';
import { getComponentForElement } from '../../base/controlling';
import { TwcModal } from '../Modal/class.web.comp.modal';
import { TwcFileUpload } from '../Upload/class.web.comp.upload';

export class TwcImageCropper extends TWebComponent {
    imageCropper: any;
    selectedImageFilename: string;
    croppedImageShape: string;
    uploadInput: HTMLInputElement;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcImageCropper';
        this.croppedImageShape = this.obj.dataset?.croppedimageshape ?? 'square';
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initImageCropper();

        // DIV in dem der Uploader liegt
        let uploaderDIV = document.getElementById(`${this.id}-uploader`);
        // hidden upload Button, den wir steuern
        this.uploadInput = uploaderDIV.querySelector<HTMLInputElement>('.cs-upload');
        // Uploader Komponente holen
        let uploaderComp = getComponentForElement(uploaderDIV.querySelector('[data-type="TwcFileUpload"]')) as TwcFileUpload;
        // und beim onFileUploaded den Inhalt leeren, da sonst ein erneuter Upload in Kombination mit dem Cropper Probleme macht
        uploaderComp.onFileUploaded().subscribe(() => this.uploadInput.value = '');
    }

    initImageCropper(): void {
        this.imageCropper = {
            cropMe: null,

            options: {
                container: { width: "90%", height: 400 },
                viewport: {
                    width: 300, height: 300,
                    type: this.croppedImageShape,
                    border: { width: 2, enable: true, color: '#fff' }
                },
                zoom: { enable: true, mouseWheel: true, slider: true },
                rotation: { slider: true, enable: true },
                transformOrigin: 'viewport',
            },
            imgHolder: null,
            imgContainer: null
        }
    }

    // Originalbild mit Verschiebbarem Bildausschnitt anzeigen
    showImageToCrop(AContainerCompID: string): void {
        if (this.selectedImageFilename == '')
            return;

        this.imageCropper.imgHolder = new Image();

        this.imageCropper.imgHolder.src = this.selectedImageFilename;

        if (this.imageCropper.cropMe)
            this.imageCropper.cropMe.destroy();

        this.imageCropper.imgContainer = document.getElementById(AContainerCompID);
        this.imageCropper.imgContainer.classList.remove('d-none');
        this.imageCropper.cropMe = new Cropme(this.imageCropper.imgContainer, this.imageCropper.options);
        this.imageCropper.cropMe.bind({ url: this.imageCropper.imgHolder.src });
    }

    // Originalbild zurechtschneiden und anzeigen
    cropImage(AContainerCompID: string): void {
        this.imageCropper.cropMe.crop().then(res => {
            let croppedImage = AsType<HTMLImageElement>(document.getElementById(AContainerCompID));
            croppedImage.src = res;
            WebCompEventHandler('AfterImageCropped', this.id);
        });
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.ShowImage':
                this.showImageToCrop(params);
                break;
            case 'Action.CropImage':
                this.cropImage(params);
                break;
            case 'Action.UploadImage':
                this.uploadInput.click();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'selectedImage':
                this.selectedImageFilename = value;
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
            case 'Enabled':
                if (value) {
                    this.obj.setAttribute('disabled', '');
                } else {
                    this.obj.removeAttribute('disabled');
                }
        }
    }
}

export class TwcImageCropperDialog extends TwcModal {

    override initDomElement() {
        this.obj.addEventListener('shown.bs.modal', () => {
            WebCompEventHandler('OnShown', this.id);
        });

        super.initDomElement();
    }
}