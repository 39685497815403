﻿import { TWebComponent } from '../../base/class.web.comps';

export class TwcTagFilterView extends TWebComponent {
    constructor(obj) {
        super(obj);
    }

    writeProperties(key: string, value: string): void {
        //
    }
};