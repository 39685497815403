import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TWebComponentWorkflowDesigner extends TWebComponent {
    svg: string;
    ActiveShape: number;
    LastActiveShape: number;
    zoom: number;
    svgWidth: number;
    svgHeight: number;

    constructor(obj: HTMLElement) {
        super(obj);
        this.svg = '';
        this.ActiveShape = -1;
        this.LastActiveShape = -1;
        this.zoom = 100;
        this.svgWidth = 0;
        this.svgHeight = 0;
        this.initClickHandler();
    }

    getClassType(): string {
        return 'TWebComponentWorkflowDesigner';
    }

    initClickHandler(): void {
        var containerElement = $('#' + this.id + '_workflowdesigner_container');
        var parent = this;

        containerElement.on('click', function (event) {
            // wir gucken, ob wir auf den viewer oder den container geklickt haben
            if (event.target.id == this.id || event.target.id == parent.id + '_workflowdesigner' || (event.target.nodeName == 'svg' && event.target.id == 'workflowdesigner')) {
                parent.setActiveShape(-1);
            } else {
                parent.setActiveShape(parseInt(event.target.id));
            }
            if (!parent.isLastActiveShape()) {
                // Markierung wechseln
                $('#' + parent.id + '_workflowdesigner > #workflowdesigner').children().removeClass('active-shape');
                if (parent.ActiveShape != -1) {
                    $('#' + event.target.id).addClass('active-shape');
                }

                WebCompEventHandler('OnClick', parent.id);
            }
        });

        containerElement.on('dblclick', function (event) {
            if (event.target.id == this.id || event.target.id == parent.id + '_workflowdesigner' || (event.target.nodeName == 'svg' && event.target.id == 'workflowdesigner')) {
                parent.setActiveShape(-1);
            } else {
                // UPS // sind das wirklich numbers in der ID?
                parent.setActiveShape(parseInt(event.target.id));
            }

            // Markierung wechseln
            $('#' + parent.id + '_workflowdesigner > #workflowdesigner').children().removeClass('active-shape');
            if (parent.ActiveShape != -1) {
                $('#' + event.target.id).addClass('active-shape');
            }

            WebCompEventHandler('OnDblClick', parent.id);
        });

        var zoomSlider = $('#' + this.id + '_zoomer_slider');
        zoomSlider.on('input', function () {
            parent.changeZoom(parseInt(AsType<HTMLInputElement>(this).value), false);
        })
    }

    changeZoom(val: number, setSlider: boolean): number {
        this.zoom = val;
        var zoomFactor = 1;

        if (val > 0) {
            zoomFactor = val / 100
        }

        // Wurde das von aussen gesetzt?
        if (setSlider) {
            var slider = $('#' + this.id + '_zoomer_slider');
            slider.val(this.zoom);
        }

        var svgWorkflow = $('#' + this.id + '_workflowdesigner > #workflowdesigner');
        // Abfangen, wenn SVG 0 ist
        if (this.svgHeight == 0) {
            this.svgWidth = parseInt(svgWorkflow.attr('width'));
            this.svgHeight = parseInt(svgWorkflow.attr('height'));
        }
        svgWorkflow.attr('width', this.svgWidth * zoomFactor);
        svgWorkflow.attr('height', this.svgHeight * zoomFactor);

        return this.zoom;
    }


    setActiveShape(value: number): void {
        this.LastActiveShape = this.ActiveShape;
        this.ActiveShape = value;
    }

    getActiveShape(): number {
        return this.ActiveShape;
    }

    isLastActiveShape(): boolean {
        return this.LastActiveShape == this.ActiveShape;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ActiveShape', this.getActiveShape()]);
        properties.push([this.id, 'Zoom', this.zoom]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
            case 'Zoom':
                this.changeZoom(parseInt(value), true);
                break;
        }
    }

    refreshWorkflow() {
        this.setActiveShape(this.ActiveShape);

        var svgWorkflow = $('#' + this.id + '_workflowdesigner > #workflowdesigner');
        this.svgWidth = parseInt(svgWorkflow.attr('width'));
        this.svgHeight = parseInt(svgWorkflow.attr('height'));

        // Markierung wechseln
        $('#' + this.id + '_workflowdesigner > #workflowdesigner').children().removeClass('active-shape');
        if (this.ActiveShape != -1) {
            $('#' + this.ActiveShape).addClass('active-shape');
        }

        this.changeZoom(this.zoom, true);
    }

}