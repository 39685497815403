import Color from 'color';
import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { getIDSuffix } from '../Utils/TProcessEditorIDUtils';
import { TBaseShape } from './TBaseShape';

export class TShapeKeyIndicator extends TBaseShape {
    indicator: SVGPathElement;
    backgroundColor: Color;

    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 950;
        this.roundCorners = true;
        this.indicator = null;
        this.hasAnchors = false;
        this.backgroundColor = Color('#f0f0f0');
        this.strokeColor = Color('#c0c0c0');
        this.inlineEdit = false;
    }

    getSvgElementType(): string {
        return 'rect';
    }

    // dies ist der IndicatorPath
    override getSVGPath() {
        let wc = 5.5;

        let path = [
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'a', wc, wc, 0, 0, 1, wc, wc,
            'v', this.boundingRect.height - 2 * wc,
            'a', wc, wc, 0, 0, 1, -wc, wc,
            'a', wc, wc, 0, 0, 1, -wc, -wc,
            'v', -(this.boundingRect.height - 2 * wc),
            'a', wc, wc, 0, 0, 1, wc, -wc
        ].join(' ');

        return path;
    }

    override create() {
        super.create();

        this.svgElement.setAttribute('rx', '10');
        this.svgElement.setAttribute('ry', '10');
        this.svgElement.setAttribute('fill', this.backgroundColor);
        this.strokeColor = this.backgroundColor.darken(0.2);
        this.svgElement.setAttribute('stroke', this.strokeColor);

        this.indicator = document.createElementNS("http://www.w3.org/2000/svg", 'path');
        this.indicator.setAttribute('id', getIDSuffix(this.id, 'indicator'));
        this.indicator.setAttribute('fill', this.color.hex());
        this.indicator.setAttribute('stroke', this.strokeColor.hex());
        this.indicator.setAttribute('d', this.getSVGPath());
        this.indicator.setAttribute('pointer-events', 'none');

        this.editor.editorSVG.appendChild(this.svgElement);
        this.editor.editorSVG.appendChild(this.indicator);
        this.editor.editorSVG.appendChild(this.captionElement.svgElement);
    }

    override delete() {
        super.delete();

        if (assigned(document.getElementById(getIDSuffix(this.id, 'indicator')))) {
            this.editor.editorSVG.removeChild(document.getElementById(getIDSuffix(this.id, 'indicator')));
        }
    }

    override repaint() {
        super.repaint();

        this.svgElement.setAttribute('rx', '10');
        this.svgElement.setAttribute('ry', '10');
        // und jetzt noch den indicator
        this.indicator.setAttribute('d', this.getSVGPath());
    }

    override setBackgroundColor(backgroundColor: Color, gradient: number) {
        super.setBackgroundColor(backgroundColor, gradient);

        this.svgElement.setAttribute('fill', this.backgroundColor);
        this.indicator.setAttribute('fill', this.color.hex());

        // set border
        this.strokeColor = Color('#c0c0c0');
        this.svgElement.setAttribute('stroke', this.strokeColor.hex());
    }
}