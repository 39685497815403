import isEmpty from 'lodash.isempty';
import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { VerticalAlignment } from '../TBaseGraphObject';
import { getIDSuffix, SVGNS } from '../Utils/TProcessEditorIDUtils';
import { BPMNBorderStyles, TBaseShapeBPMN } from './TBaseShapeBPMN ';

export class TShapeBPMNEvent extends TBaseShapeBPMN {
    doubleBorder: SVGEllipseElement;

    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 960;
        this.doubleBorder = null;

        this.captionVertAlignment = VerticalAlignment.taAlignOuterBottom;
    }

    getSvgElementType(): string {
        return 'ellipse';
    }

    iconByCode(code: string): string {
        switch (code.trim()) {
            case 'A':
                return 'bpmn-event-type-blank';
            case 'B':
                return 'bpmn-event-type-message';
            case 'C':
                return 'bpmn-event-type-message-out';
            case 'D':
                return 'bpmn-event-type-escalation';
            case 'E':
                return 'bpmn-event-type-escalation-out';
            case 'F':
                return 'bpmn-event-type-timer';
            case 'G':
                return 'bpmn-event-type-signal';
            case 'H':
                return 'bpmn-event-type-signal-out';
            case 'I':
                return 'bpmn-event-type-error';
            case 'J':
                return 'bpmn-event-type-error-out';
            case 'K':
                return 'bpmn-event-type-multiple';
            case 'L':
                return 'bpmn-event-type-multiple-out';
            case 'M':
                return 'bpmn-event-type-condition';
            case 'N':
                return 'bpmn-event-type-link';
            case 'O':
                return 'bpmn-event-type-link-out';
            case 'P':
                return 'bpmn-event-type-compensation';
            case 'Q':
                return 'bpmn-event-type-compensation-out';
            case 'R':
                return 'bpmn-event-type-parallel';
            case 'S':
                return 'bpmn-event-type-terminate';
            case 'T':
                return 'bpmn-event-type-cancel';
            case 'V':
                return 'bpmn-event-type-cancel-out';
            default:
                return '';
        }
    }

    borderStyleByCode(code: string): BPMNBorderStyles {
        switch (code.trim()) {
            case 'A':
                return BPMNBorderStyles.lmNormal;
            case 'B':
                return BPMNBorderStyles.lmDouble;
            case 'C':
                return BPMNBorderStyles.lmBold;
            case 'D':
                return BPMNBorderStyles.lmDash;
            case 'E':
                return BPMNBorderStyles.lmDoubleDash;
            default:
                return undefined;
        }
    }

    repaintIconBPMN() {
        if (isEmpty(this.itemData)) {
            return;
        }

        // Caption anzeigen?
        this.captionElement.svgElement.classList.toggle('d-none', !(this.itemData.substr(4, 1) === 'X'));

        if (!isEmpty(this.iconByCode(this.itemData.substr(2, 2)))) {
            this.setAttributesBPMN(this.iconByCode(this.itemData.substr(2, 2)),
                this.boundingRect.left + this.boundingRect.width / 10, this.boundingRect.top + this.boundingRect.height / 10, this.boundingRect.width - this.boundingRect.width / 5, this.boundingRect.height - this.boundingRect.height / 5);
        }

        // borderStyle
        const innerCircleDistance = 3;

        switch (this.borderStyleByCode(this.itemData.substr(0, 2))) {
            case BPMNBorderStyles.lmNormal:
                if (assigned(this.doubleBorder)) {
                    this.editor.editorSVG.removeChild(this.doubleBorder);
                    this.doubleBorder = null;
                }
                this.svgElement.removeAttribute('stroke-dasharray');
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);

                break;
            case BPMNBorderStyles.lmDouble:
                if (!assigned(this.doubleBorder)) {
                    this.doubleBorder = document.createElementNS(SVGNS, 'ellipse');
                    this.editor.editorSVG.insertBefore(this.doubleBorder, this.iconBPMN);
                }
                this.svgElement.removeAttribute('stroke-dasharray');
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);

                this.doubleBorder.setAttribute('id', getIDSuffix(this.id, 'dblBorder'));
                this.doubleBorder.setAttribute('cx', String(this.boundingRect.left + this.boundingRect.width / 2));
                this.doubleBorder.setAttribute('cy', String(this.boundingRect.top + this.boundingRect.height / 2));
                this.doubleBorder.setAttribute('rx', String((this.boundingRect.width - innerCircleDistance) / 2));
                this.doubleBorder.setAttribute('ry', String((this.boundingRect.height - innerCircleDistance) / 2));
                this.doubleBorder.setAttribute('stroke', this.strokeColor.hex());
                this.doubleBorder.setAttribute('fill', 'none');
                break;
            case BPMNBorderStyles.lmBold:
                if (assigned(this.doubleBorder)) {
                    this.editor.editorSVG.removeChild(this.doubleBorder);
                    this.doubleBorder = null;
                }
                this.svgElement.removeAttribute('stroke-dasharray');

                this.svgElement.setAttribute('stroke-width', '3');
                break;
            case BPMNBorderStyles.lmDash:
                if (assigned(this.doubleBorder)) {
                    this.editor.editorSVG.removeChild(this.doubleBorder);
                    this.doubleBorder = null;
                }
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);

                this.svgElement.setAttribute('stroke-dasharray', '20,10');
                break;
            case BPMNBorderStyles.lmDoubleDash:
                if (!assigned(this.doubleBorder)) {
                    this.doubleBorder = document.createElementNS(SVGNS, 'ellipse');
                    this.editor.editorSVG.appendChild(this.doubleBorder);
                }
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);

                this.svgElement.setAttribute('stroke-dasharray', '4');

                this.doubleBorder.setAttribute('id', getIDSuffix(this.id, 'dblBorder'));
                this.doubleBorder.setAttribute('cx', String(this.boundingRect.left + this.boundingRect.width / 2));
                this.doubleBorder.setAttribute('cy', String(this.boundingRect.top + this.boundingRect.height / 2));
                this.doubleBorder.setAttribute('rx', String((this.boundingRect.width - innerCircleDistance) / 2));
                this.doubleBorder.setAttribute('ry', String((this.boundingRect.height - innerCircleDistance) / 2));
                this.doubleBorder.setAttribute('stroke', this.strokeColor.hex());
                this.doubleBorder.setAttribute('fill', 'none');
                this.doubleBorder.setAttribute('stroke-dasharray', '4');
                break;
            default:
                // nothing
                break;
        }
    }

    override delete() {
        super.delete();

        // falls ein doppelter rand vorhanden ist
        if (assigned(this.doubleBorder)) {
            this.editor.editorSVG.removeChild(this.doubleBorder);
            this.doubleBorder = null;
        }
    }
}
