import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeCondition extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 900;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let wc = this.boundingRect.height / 4;
        if (wc > (this.boundingRect.width / 2)) {
            wc = this.boundingRect.width / 4;
        }

        let path = [
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'h', this.boundingRect.width - (2 * wc),
            'l', wc, this.boundingRect.height / 2,
            'l', -wc, this.boundingRect.height / 2,
            'h', -(this.boundingRect.width - 2 * wc),
            'l', -wc, -(this.boundingRect.height / 2),
            'Z'
        ].join(' ');

        return path;
    }
}