import Color from 'color';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeWarning extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 500;

        this.colorFixed = true;
        this.gradientBegin = Color('#fff');
        this.gradientEnd = Color('#ff0505');
        this.color = Color('#ff0505');
        this.fontColor = Color('#fff');
        this.strokeColor = Color('#fff')
        this.strokeWidth = 4;
        this.hasAnchors = false;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let wc = Math.floor(this.boundingRect.height / 4);
        if (wc > Math.floor(this.boundingRect.width / 4)) {
            wc = Math.floor(this.boundingRect.width / 4);
        }

        let path = [
            // Kante oben
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'h', this.boundingRect.width - (2 * wc),
            'l', wc, wc,
            'v', this.boundingRect.height - (2 * wc),
            'l', -wc, wc,
            'h', -this.boundingRect.width + (2 * wc),
            'l', -wc, -wc,
            'v', -this.boundingRect.height + (2 * wc),
            'Z'
        ].join(' ');

        return path;
    }

    override setBackgroundColor(backgroundColor: Color, gradient: number) {
        super.setBackgroundColor(backgroundColor, gradient);

        // Rand bei Warning ist immer Weiß
        this.strokeColor = Color('#fff');
        this.svgElement.setAttribute('stroke', this.strokeColor.hex());
    }
}