import { cntrlIsPressed, onGlobalKeyEvent } from '../../../app/consense.keyboard';
import { sendAjaxRequestUrlContent, WebCompEventHandler } from '../../../core/communication';
import { canUseDispatcher, isUnloading, lockDispatcher, onDirty, onDisconnect, sendComponentBeacon, sendComponentRequestDispatcher } from '../../../core/dispatcher';
import { CSEndpointGuid } from '../../../core/endpoint';
import { copyToClipboardEx } from '../../../core/utils/clipboard';
import { ClearNotification, MessageType, MsgNotify, MsgNotifyEx } from '../../../core/utils/msgnotify';
import { ConsoleMessageType, CsConsole } from '../../../utils/console';
import { addHtml, replaceHtml } from '../../../utils/domUtils';
import { assigned } from '../../../utils/helper';
import { boolFromStr, csJSONParse, sameText } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { IWebComponent } from '../../interfaces/class.web.comps.intf';
import { TwcOverlay } from '../Overlay/class.web.comp.overlay';

export class TwcDispatcher extends TWebComponent {
    maxFailCount: number;
    keepAliveInterval: number;
    keepAliveTimer: number;
    resurrectionTimer: number;
    keepAliveFailCounter: number;
    errorToast: any;
    hasUnloaded: boolean;
    overlay: TwcOverlay;
    useGoodbye: boolean;
    isUnloadWarningAllowed: boolean;
    useUnloadWarning: boolean;
    useSmartRefresh: boolean;
    isGlobalDirty: boolean;


    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcDispatcher';
        this.maxFailCount = 1;

        this.keepAliveInterval = parseInt(this.obj.dataset.keepaliveinterval);
        this.keepAliveTimer = undefined;
        this.resurrectionTimer = undefined;
        this.keepAliveFailCounter = 0;
        this.errorToast = undefined;
        this.hasUnloaded = false;
        this.overlay = undefined;
        this.useGoodbye = true;

        this.isGlobalDirty = false;

        this.isUnloadWarningAllowed = true; // allow: prinzipiell sind wir in nem zustand wo das sinn macht
        this.useUnloadWarning = boolFromStr(this.obj.dataset.useunloadwarning); // use: server sagt wir sollen es auch nutzen
        this.useSmartRefresh = boolFromStr(this.obj.dataset.usesmartrefresh, true);

        onDisconnect().subscribe(() => this.handleDisconnnect())
        onDirty().subscribe((sender: IWebComponent) => this.handleDirty(sender))
        onGlobalKeyEvent().subscribe((event: KeyboardEvent) => this.handleKeyboardEvent(event));

        // Init
        this.initKeepAlive();
    }

    override initDomElement() {
        super.initDomElement();

        // Goodbye registration; da nicht alle Browser das im selben Event richtig machen müssen wir beide registrieeren
        window.addEventListener('beforeunload', event => this.handleBeforeUnload(event), false);
        window.addEventListener('unload', () => this.handleUnload(), false);
    }

    handleKeyboardEvent(event: KeyboardEvent): void {
        switch (event.key) {
            case 'F5': // F5 -> hier interessiert uns nicht was damit gemacht wurde, in jedem Fall wird neu geladen
                if (!cntrlIsPressed && canUseDispatcher(false) && CSEndpointGuid !== undefined && this.useSmartRefresh) {
                    // normales F5 stoppen
                    event.preventDefault();

                    if (isUnloading()) {
                        // Bei Unload machen wir keinen Refresh mehr, z. B. beim Verlassen der Edit Seite würden wir diese wieder öffnen dann.
                        console.error('Refresh cannot be executed due to pending requests.');
                        return;
                    }

                    // GoodBye im Dispatcher disablen da der Endpunkt erhalten bleiben soll
                    // Unload Warning zeigen wird auch nicht, da die Daten durch die endpoint guid erhalten bleiben sollten                    
                    this.disableGoodbye();
                    this.disallowUnloadWarning();


                    // Und F5 auf den Endpunkt
                    let url = new URL(window.location.href);
                    url.searchParams.set('endpointguid', CSEndpointGuid)

                    // Wir schreiben die GUID zusätzlich in die URL (ohne neues History Event). Hintergrund ist, dass wenn man mehrfach F5 drückt der Event Handler
                    // ggf. schon nicht mehr existiert aufgrund des Unload. In dem Fall soll aber dennoch der Endpunkt möglichst beibehalten werden.
                    history.replaceState(null, document.title, url.href);

                    // Der Server mag es nicht wenn der Doppelpunkt aus den OIDs automatisch encoded wird.
                    window.location.href = decodeURIComponent(url.href);
                }
                break;
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'KeepAliveInterval':
                this.keepAliveInterval = parseInt(value);
                this.initKeepAlive();
                break;
            case 'UseUnloadWarning':
                this.useUnloadWarning = value.toLowerCase() == 'true'; // bollean-string zu boolean
                break;
            case 'UseSmartRefresh':
                this.useSmartRefresh = value.toLowerCase() == 'true'; // bollean-string zu boolean
                break;
            case 'IsDirty':
                this.isGlobalDirty = boolFromStr(value);
                break;
        }
    }

    getDispatcherOverlay(): TwcOverlay {
        if (!assigned(this.overlay)) {
            let componentDiv = document.createElement('div');
            componentDiv.id = `${this.id}-overlay`;
            componentDiv.classList.add('cs-overlay');

            let contentDiv = document.createElement('div');
            contentDiv.id = `${componentDiv.id}-content`;
            contentDiv.classList.add('cs-overlay-content');

            componentDiv.appendChild(contentDiv);
            this.obj.appendChild(componentDiv);
            this.overlay = new TwcOverlay(componentDiv);
            this.overlay.changeOverlayContent(['<i class="fa-solid fa-spinner fa-pulse"></i>'], true);
        }

        return this.overlay;
    }

    //#region Heartbeat und KeepAlive
    isKeepAliveActive(): boolean {
        return assigned(this.keepAliveTimer);
    }

    stopKeepAlive(): void {
        window.clearInterval(this.keepAliveTimer);
        CsConsole('KeepAlive stopped.', ConsoleMessageType.Warning);
    }

    initKeepAlive(): void {
        if (this.isKeepAliveActive()) {
            this.stopKeepAlive();
        }

        if (this.keepAliveInterval > 0) {
            this.keepAliveTimer = window.setInterval(() => {
                this.doKeepAlive();
            }, this.keepAliveInterval);
            CsConsole('KeepAlive started.', ConsoleMessageType.Information);
        }
    }

    isResurrection(): boolean {
        return assigned(this.resurrectionTimer);
    }

    stopResurrection(): void {
        window.clearInterval(this.resurrectionTimer);
        CsConsole('Resurrection timer stopped.', ConsoleMessageType.Success);
    }

    tryResurrection(): void {
        if (this.isResurrection()) {
            this.stopResurrection();
        }

        this.resurrectionTimer = window.setInterval(() => {
            this.doResurrection();
        }, 5000) // alle 5 Sekunden -> den können wir ruhig oft laufen lassen, da er nur bei Disconnect läuft verursacht er keinen permamenten Traffic am Server
        CsConsole('Resurrection timer started.', ConsoleMessageType.Information);
    }

    disableWebsite(): void {
        lockDispatcher(true);

        this.getDispatcherOverlay().show();
    }

    enableWebsite(): void {
        lockDispatcher(false);

        this.getDispatcherOverlay().hide();
    }

    handleDisconnnect(): void {
        this.keepAliveFailCounter++;

        if (this.keepAliveFailCounter >= this.maxFailCount && this.isKeepAliveActive()) {
            this.handleDisconnect();
        }
    }

    handleDisconnect(): void {
        this.stopKeepAlive();
        this.tryResurrection();
        this.disableWebsite();
        this.disallowUnloadWarning();

        this.errorToast = MsgNotifyEx('Die Verbindung zum Server wurde unterbrochen! Wir versuchen die Verbindung wiederherzustellen...', MessageType.Danger, 0);
    }

    handleSessionDead(): void {
        // Session im Regelbetrieb verloren?                
        this.stopKeepAlive();
        this.tryResurrection();
        this.disableWebsite();
        this.disallowUnloadWarning();

        this.errorToast = MsgNotifyEx('Dieser Endpunkt ist nicht mehr gültig. Bitte aktualisieren Sie die Webseite.', MessageType.Danger, 0);
    }

    doKeepAlive(): void {
        let response = sendComponentRequestDispatcher(this.id, { action: 'heartbeat', message: 'ping' }) as { message: string, additionalCommand?: string, data?: string };

        if (!assigned(response)) {
            this.handleDisconnnect();
        } else {
            // alles gut
            if (response.message == 'pong') {
                this.keepAliveFailCounter = 0;

                if (assigned(response.additionalCommand) && response.additionalCommand !== '') {
                    this.hanndleAdditionalCommand(response.additionalCommand, response.data);
                }
            }
            // kill command
            else if (response.message == 'kill') {
                this.handleSessionDead();
            }
            else {
                this.handleDisconnnect();
            }
        }
    }

    hanndleAdditionalCommand(command: string, data?: string): void {
        if (sameText(command, 'serverSideEventRequested')) {
            if (assigned(data)) {
                try {
                    let arr = csJSONParse(data) as Array<string>;
                    arr.forEach(command => WebCompEventHandler('ServerSideEvent', this.id, command));
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }

    doResurrection(): void {
        let response = sendComponentRequestDispatcher(this.id, { action: 'heartbeat', message: 'ping' }) as { message: string };

        if (!assigned(response)) {
            // Wir haben weiterhin keine Verbindung
        } else {
            // alles gut
            if (response.message == 'pong') {
                this.keepAliveFailCounter = 0;
                this.stopResurrection();
                this.initKeepAlive();
                this.enableWebsite();
                ClearNotification(this.errorToast);
                MsgNotifyEx('Die Verbindung zum Server wurde wiederhergestellt!', MessageType.Success, 10000);
            }
            // kill command
            else if (response.message == 'kill') {
                this.disableWebsite();
                ClearNotification(this.errorToast);
                this.errorToast = MsgNotifyEx('Der Server ist wieder erreichbar. Bitte aktualisieren Sie die Webseite.', MessageType.Warning, 0);
            } else {
                this.stopResurrection();
                this.disableWebsite();
                ClearNotification(this.errorToast);
                this.errorToast = MsgNotifyEx('Der Server ist wieder erreichbar. Bitte aktualisieren Sie die Webseite.', MessageType.Warning, 0);
            }
        }
    }
    //#endregion

    //#region Unload und Goodybe
    handleBeforeUnload(event: BeforeUnloadEvent): void {
        if (this.useUnloadWarning && this.isUnloadWarningAllowed) {
            event.preventDefault();
            // Es ist notwendig eine Meldung zu setzen, damit die Browser den Bestätigungsdialog anzeigen. Der Custom Text wird jedoch nur noch vom IE unterstützt.
            // https://stackoverflow.com/a/45088935 und https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
            event.returnValue = 'Möchten Sie die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren.';
        }

        this.useSmartRefresh = false;
    }

    handleUnload(): void {
        if (!this.hasUnloaded && this.useGoodbye) {
            this.stopKeepAlive(); // ungünstiges keepAlive nach goodbye unterbinden
            sendComponentBeacon(this.id, { action: 'heartbeat', message: 'goodbye' });
            this.hasUnloaded = true;
        }
    }

    enableGoodbye(): void {
        this.useGoodbye = true;
    }

    disableGoodbye(): void {
        this.useGoodbye = false;
    }

    allowUnloadWarning(): void {
        this.isUnloadWarningAllowed = true;
    }

    disallowUnloadWarning(): void {
        this.isUnloadWarningAllowed = false;
    }
    //#endregion

    //#region Dirty
    handleDirty(component: IWebComponent) {
        console.debug(`${component.id} changed!`)
        component.obj.dispatchEvent(new CustomEvent('cscomponentchanged', {}));

        if (!this.isGlobalDirty) {
            console.debug('Dirty state changed... Messaging server!')
            WebCompEventHandler('OnClientIsDirty', this.id);
        }

        this.isGlobalDirty = true;
    }
    //#endregion

    override execAction(action: string, params: string): void {
        try {
            switch (action) {
                case 'Action.PopupEndpoint':
                    var locationsearch = location.search;

                    // Hier ist im Server der Endpunkt mittlerweile ausgetausch, daher dürfen wir kein Goodbye senden und müssen dean Heartbeat stoppen!                    
                    this.useGoodbye = false;
                    this.stopKeepAlive();

                    this.isUnloadWarningAllowed = false; // serverseitiges Redirect ist erzwungen und daher ohne UnloadWarning

                    // Hier muss noch gecheckt werden, ob 1. schon params da sind und 2. ob die EndpointGUID schon gesetzt ist
                    if (locationsearch.indexOf('?') == 0) {
                        var urlParams = new URLSearchParams(window.location.search);
                        var endpointguid = urlParams.get('endpointguid');
                        if (endpointguid) {
                            var replacestr = 'endpointguid=' + endpointguid

                            locationsearch = locationsearch.replace('?' + replacestr + '&', '?');
                            locationsearch = locationsearch.replace('?' + replacestr, '');
                            locationsearch = locationsearch.replace('&' + replacestr, '');
                        }

                        locationsearch += '&endpointguid=' + params;
                    } else {
                        locationsearch = '?endpointguid=' + params;
                    }

                    var newURL = location.pathname + locationsearch;
                    location.href = newURL;
                    break;
                case 'Action.ExecJavascript':
                    eval(params);
                    break;
                case 'Action.CopyToClipboard': {
                    let ActionSet = csJSONParse(params);
                    copyToClipboardEx(ActionSet.Text, ActionSet.SuccessMsg);
                    break;
                }
                case 'Action.MsgNotify': {
                    let ActionSet = csJSONParse(params);
                    MsgNotify(ActionSet.ActionContent, ActionSet.ActionValue);
                    break;
                }
                case 'Action.PushURL':
                    window.history.pushState({
                        page: params
                    }, '', params);
                    break;
                case 'Action.RedirectURL':
                    this.isUnloadWarningAllowed = false; // serverseitiges Redirect ist erzwungen und daher ohne UnloadWarning
                    this.useSmartRefresh = false; // selbiges gilt für SmartRefresh
                    window.location.href = params;
                    break;
                case 'Action.OpenNewTab': {
                    let ActionSet = csJSONParse(params);
                    window.open(ActionSet.URL, ActionSet.Name, ActionSet.Specs);
                    break;
                }
                case 'Action.ReloadPage':
                    this.isUnloadWarningAllowed = false; // serverseitiges Reload ist erzwungen und daher ohne UnloadWarning
                    this.useSmartRefresh = false; // selbiges gilt für SmartRefresh
                    location.reload();
                    break;
                case 'Action.ReloadPart': {
                    let ActionSet = csJSONParse(params);
                    replaceHtml(ActionSet.ActionValue, ActionSet.ActionContent);
                    break;
                }
                case 'Action.ComponentAdd': {
                    let ActionSet = csJSONParse(params);
                    addHtml(ActionSet.ActionTarget, ActionSet.ActionContent);
                    break;
                }
                case 'Action.ReloadPartURL': {
                    let ActionSet = csJSONParse(params);
                    sendAjaxRequestUrlContent(ActionSet.ActionValue, ActionSet.ActionURL);
                    break;
                }
                case 'Action.SetScrollPosition': {
                    let arr = params.split(':');
                    let elem = findComponent(arr[0]);
                    if (assigned(elem)) {
                        elem.obj.scrollTo(parseInt(arr[1]), parseInt(arr[2]));
                    }
                    break;
                }
                default:
                    super.execAction(action, params);
                    break;
            }
        }
        catch (e) {
            // statements
            console.log(e);
        }
    }
}

// Warum?
$(function () {

    // Wenn ein Parameter "endpointguid" in der URL vorhanden ist, dann entfernen wir diesen
    var locationsearch = location.search;

    // Hier muss noch gecheckt werden, ob 1. schon params da sind und 2. ob die EndpointGUID schon gesetzt ist
    if (locationsearch.indexOf('?') == 0) {
        var urlParams = new URLSearchParams(window.location.search);

        var endpointguid = urlParams.get('endpointguid');
        var replacestr = 'endpointguid=' + endpointguid

        locationsearch = locationsearch.replace('?' + replacestr + '&', '?');
        locationsearch = locationsearch.replace('?' + replacestr, '');
        locationsearch = locationsearch.replace('&' + replacestr, '');

        window.history.replaceState('obj', 'Title', location.pathname + locationsearch);
    }
});