import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


export class TwcCustomCanvas extends TWebComponent {
    canvas: HTMLCanvasElement;
    context: any;

    constructor(obj) {
        super(obj);

        if (this.obj instanceof HTMLCanvasElement) {
            this.canvas = this.obj;
        }
        else {
            throw `Element is not of type HTMLCanvasElement [${this.id}]`;
        }

        this.context = this.canvas.getContext('2d');

        this.initCanvas();
        this.draw();
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Width', this.canvas.width]);
        properties.push([this.id, 'Height', this.canvas.height]);

        return properties;
    }

    writeProperties(key: string, value: string): void {
        let redrawRequired = false;
        switch (key) {
            case 'Width':
                this.canvas.width = parseInt(value);
                redrawRequired = true;
                break;
            case 'Height':
                this.canvas.height = parseInt(value);
                redrawRequired = true;
                break;
        }

        // Bei Größenänderungen brauchen wir ein Redraw, da sonst nur gestrecht wird
        if (redrawRequired)
            this.draw();
    }

    // draw
    initCanvas(): void {
        // implement in derived classes
    }

    draw(): void {
        // implement in derived classes
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
}