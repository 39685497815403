﻿import { WebCompEventHandler } from '../../../core/communication';
import { registerOnShowModal } from '../../../core/interaction';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { getActiveComponent, unregisterComponent } from '../../base/controlling';
import { IWebComponent } from '../../interfaces/class.web.comps.intf';

export class TwcModal extends TRenderWebComponent {

    private activeComponent: IWebComponent;
    private Title: string;
    private SubTitle: string;
    hasHideEvent: boolean;
    hasShownEvent: boolean;

    override initComponent() {
        super.initComponent();

        this.classtype = 'TwcModal';
        this.activeComponent = null;
        
        this.Title = this.obj.dataset?.title ?? '';
        this.SubTitle = this.obj.dataset?.subtitle ?? '';
        this.hasHideEvent = boolFromStr(this.obj.dataset?.hasHideEvent);
        this.hasShownEvent = boolFromStr(this.obj.dataset?.hasShownEvent);
    }

    override initDomElement(): void {
        super.initDomElement();

        registerOnShowModal($(this.obj));

        this.obj.addEventListener('hide.bs.modal', () => {
            if (this.hasHideEvent) {
                WebCompEventHandler('OnHide', this.id);
            }
        });

        this.obj.addEventListener('hidden.bs.modal', () => {
            // Pseudoklassen wie :visible geht ohne jQuery nicht -> Da die Modals eh komplett mit Jquery intern noch arbeiten ok
            if ($('.modal:visible').length > 0) {
                window.setTimeout(() => {
                    document.body.classList.add('modal-open');
                }, 0);
            }
        });

        this.obj.addEventListener('shown.bs.modal', () => {
            if (this.hasShownEvent) {
                WebCompEventHandler('OnShown', this.id);
            }
        });

        // initial sichtbar?
        if (boolFromStr(this.obj.dataset.visible)) {
            this.show();
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Modal.Show':
                this.show();
                break;
            case 'Modal.Hide':
                this.hide();
                break;
            case 'Modal.Remove':
                unregisterComponent(this.id);
                $('#' + this.id).remove();

                this.afterHide();
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    protected beforeShow(): void {
        // Wir merken uns die fokussierte Komponente
        this.activeComponent = getActiveComponent(true);
    }

    protected afterHide(): void {
        // und wenn wir zu machen fokussieren wir die alte Komponente wieder
        if (assigned(this.activeComponent)) {
            this.activeComponent.setFocus();
        }

        if (!(document.querySelector('.modal')?.classList?.contains('show'))) {
            // Scroll "aktivieren"
            document.documentElement.classList.remove('modal-noscroll');
        }
    }

    show(): void {
        this.beforeShow();

        $('#' + this.id).modal('show');
    }

    hide(): void {
        $('#' + this.id).modal('hide');

        this.afterHide();
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Title':
                this.Title = value;
                break;
            case 'SubTitle':
                this.SubTitle = value;
                break;
        }
    }

    protected override doRender(timestamp: number): void {
        this.obj.querySelector('.modal-title').innerHTML = this.Title;
        this.obj.querySelector('.modal-subtitle').innerHTML = this.SubTitle;
        // ok wir sind soweit mit dem render-Vorgang fertig. nun erzählen wir davon... 
        this.obj.dispatchEvent(new CustomEvent('csrendercomplete.modal', {}));
    }
}