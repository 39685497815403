import { TWebComponent } from '../../base/class.web.comps';

export class TWebComponentHtmlArea extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TWebComponentHtmlArea';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Content':
                $(this.obj).html('');
                $(this.obj).html(value);
                break;
            case 'Visible':
                if (value == '1') {
                    $(this.obj).removeClass('d-none');
                } else if (value == '0') {
                    $(this.obj).addClass('d-none');
                }
                break;
        }
    }
}