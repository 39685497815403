// Bootstrap hat das nicht als Typen definiert, daher geben wir dem hier einen Alias
export type PopoverPlacement = 'auto' | 'top' | 'bottom' | 'left' | 'right' | (() => void);

/*------------------------------------------*/
/* Bootstrap Utils */
import { getParentByClassname } from "./html";

/*------------------------------------------*/
const bootstrapMainStyles = ['primary', 'secondary', 'warning', 'success', 'danger', 'info', 'light', 'dark', 'link'];

export default function removeBootstrapStyles(obj: Element, prefix: string): void {
    // bsp: removeBootstrapStyles(this.obj, 'btn-');  
    // löscht alle styles: btn-primary , btn-secondary usw
    bootstrapMainStyles.forEach(element => {
        obj.classList.remove(prefix + element);
    });
}
/*------------------------------------------*/
/* sind wir im Modalen Dialog? **/
/*------------------------------------------*/
export function getModalDialogContainer(el: Element): Element {
    return getParentByClassname(el, 'modal-dialog');
}