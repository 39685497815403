import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { ActionType, TCustomNodeAction } from './TCustomAction';

export class TStreamAction extends TCustomNodeAction {
    setValue(...args: any[]): void {
        throw new Error('Method not implemented.');
    }
    constructor(refElement: TBaseShape, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patStream;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);
    }

    override toJSON(): any {
        return super.toJSON();
    }

    performAction(): void {
    }

    inverseAction(): TCustomNodeAction {
        return new TStreamAction(this.refElement, this.svgEditor);
    }
}
