import List from 'list.js';
import Masonry from 'masonry-layout';
import { getBaseUrl } from '../../../core/endpoint';
import { assigned } from '../../../utils/helper';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcMasonry extends TWebComponent {
    searchComponent: HTMLInputElement;
    deferredLoading: boolean;
    IsInitialized: boolean;
    container: HTMLElement;
    masonry: Masonry;

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcMasonry';
        this.IsInitialized = false;

        this.container = document.querySelector('#' + this.obj.dataset.compid);

        this.deferredLoading = boolFromStr(this.container.dataset.deferredloading);

        let searchComponent = document.getElementById(this.container.dataset.searchcomponentid);
        if (assigned(searchComponent)) {
            this.searchComponent = AsType<HTMLInputElement>(searchComponent);
            this.initSearch();
        }

        this.initMasonry();
    }

    initMasonry(): void {
        // wir dürfen uns nur einmal initialisieren
        if (this.IsInitialized) {
            return;
        }

        // https://masonry.desandro.com/options.html
        this.masonry = new Masonry(this.container, {
            itemSelector: '.masonry-item',
            columnWidth: '.masonry-grid__col-sizer',
            percentPosition: true,
            gutter: 24,
            stagger: 30,
            visibleStyle: { transform: 'translateY(0)', opacity: 1 },
            hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
        });
        this.IsInitialized = true;

        this.container.querySelectorAll('img').forEach(element => {
            element.addEventListener('load', () => {
                this.masonry.layout();
            })
        });
    }


    initSearch(): void {
        let options: List.ListOptions;
        options = {
            // list.js braucht ein "template" wie ein Item aussieht, wenn die Liste leer ist; übergeben hier aber nur ein dummy weil nicht relevant
            item: '<div></div>',
            listClass: 'cs-search-list',
            valueNames: ['dropdown-hrefcaption', 'cs-listview-caption', 'cs-listview-props'],
        }

        let aList = new List(this.container.id + '-DIV', options);

        if (assigned(this.searchComponent)) {
            this.searchComponent.addEventListener('keyup', () => {
                let searchString = this.searchComponent.value;
                aList.search(searchString);
                this.masonry.layout();
            })

            // Initial einmal suchen (falls wir schon was in der Suchleiste haben)
            let searchString = this.searchComponent.value;
            aList.search(searchString);
        }
    }

    writeProperties(key: string, value: string): void {
        // nothing
    }

    LoadNextData(): void {
        function getData(page: string, aBaseURL: string) {
            let $data;
            $.ajax({
                url: aBaseURL,
                data: {
                    page: page
                },
                async: false,
                dataType: 'json'
            }).done(function (data) {
                $data = data;
            });
            return $data;
        }

        let BaseURL = getBaseUrl(this.container.id);
        let aData = getData('nextData', BaseURL);
        if (parseInt(aData.loaded) >= parseInt(aData.count)) {
            this.container.classList.remove('hasMoreItems');
        }
        let newElements = [];

        for (let index in aData.data) {
            let obj = document.createElement('article');
            obj.innerHTML = aData.data[index].html;
            obj.className = 'masonry-item';
            this.obj.querySelector('.cs-masonry-list').appendChild(obj);
            newElements.push(obj);
        }

        this.masonry.appended(newElements);
        this.masonry.layout();
    }
}