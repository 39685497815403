import { Point } from '../../../../classes/geometry';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TEdgeBase } from '../Edges/TEdgeBase';
import { ActionType, TCustomEdgeAction } from "./TCustomAction";

/* DEAKTIVERT, da wir die canvg Points nutzen. Mit dem alten Editor fliegen die aber eh raus, daher lass ich den mal drin.
export type Point = { x: number, y: number }; // UPS // Irgendwo zentral ablegen später?!
*/

export class TEdgeExtraPointsAction extends TCustomEdgeAction {
    oldExtraPoints: Array<Point>;
    newExtraPoints: Array<Point>;


    constructor(refElement: TEdgeBase, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.oldExtraPoints = this.refElement.realExtraPoints;
    }

    typeID(): ActionType {
        return ActionType.patEdgeExtraPoints;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        // wir brauchen echte Objektinstanzen
        this.oldExtraPoints = obj.OldExtraPoints.map(p => new Point(p.x, p.y));
        this.newExtraPoints = obj.NewExtraPoints.map(p => new Point(p.x, p.y));
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        // vor der Übergabe sicherstellen, dass wir Integer übergeben
        this.oldExtraPoints.forEach(point => {
            point.x = Math.round(point.x);
            point.y = Math.round(point.y);
        });
        this.newExtraPoints.forEach(point => {
            point.x = Math.round(point.x);
            point.y = Math.round(point.y);
        });

        obj.OldExtraPoints = this.oldExtraPoints;
        obj.NewExtraPoints = this.newExtraPoints;

        return obj;
    }

    setValue(newExtraPoints: Array<Point>): void {
        this.newExtraPoints = newExtraPoints;
    }

    performAction(): void {
        this.refElement.setDraftExtraPoints(this.newExtraPoints, true);
        this.refElement.setExtraPoints();
    }

    inverseAction(): TCustomEdgeAction {
        let result = new TEdgeExtraPointsAction(this.refElement, this.svgEditor);
        result.setValue(this.oldExtraPoints);

        return result;
    }
}