import { TWebComponent } from '../../base/class.web.comps';
import { boolFromStr } from '../../../utils/strings';
import { assigned } from '../../../utils/helper';

export class TwcMenuItem extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcMenuItem';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                this.obj.querySelector('.menu-text').innerHTML = value;
                break;
            case 'URL':
                this.obj.querySelector('.menu-link').setAttribute('href', value);
                break;
            case 'ExtraText':
                let extraTextElem = this.obj.querySelector('.menu-extratext');
                if (assigned(extraTextElem)) {
                    extraTextElem.innerHTML = value;
                    extraTextElem.classList.toggle('d-none', !(value.length > 0));
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}