import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TEdgeBase } from '../Edges/TEdgeBase';
import { ActionType, TCustomEdgeAction } from './TCustomAction';

export class TEdgeCaptionAction extends TCustomEdgeAction {
    oldCaption: string;
    newCaption: string;

    constructor(refElement: TEdgeBase, editor: TwsProcessEditorCustom) {
        super(refElement, editor);
    }

    typeID(): ActionType {
        return ActionType.patEdgeCaption;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldCaption = obj.oldCaption;
        this.newCaption = obj.newCaption;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldCaption = this.oldCaption;
        obj.newCaption = this.newCaption;

        return obj;
    }

    setValue(newCaption: string): void {
        this.oldCaption = this.refElement.getCaption();
        this.newCaption = newCaption;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setCaption(this.newCaption);
    }

    inverseAction(): TCustomEdgeAction {
        let result = new TEdgeCaptionAction(this.refElement, this.svgEditor);
        result.setValue(this.oldCaption);

        return result;
    }
}