import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcCollapseWrapper extends TWebComponent {
    CollapseDiv: JQuery<HTMLElement>;
    CollapseWrapperBtn: JQuery<HTMLElement>;
    PageSidebar: JQuery<HTMLElement>;
    collapsed: boolean;
    btnicon: any; // UPS // Was für ein Typ ist das / Was steht dort drin?

    constructor(obj: HTMLElement) {
        super(obj);
        this.CollapseDiv = $('#' + this.id);
        this.CollapseWrapperBtn = $('#' + this.id + ' button i').first();
        this.PageSidebar = $('#' + this.id).closest('.page-sidebar');
        this.classtype = 'TwcCollapseWrapper';
        this.collapsed = false;
        this.btnicon = this.CollapseDiv.data('btnicon');
    }

    collapseDiv(): void {
        this.PageSidebar.siblings().toggleClass('d-none', this.collapsed);
        this.PageSidebar.toggleClass('sidebar-collapsed', this.collapsed);
        this.CollapseWrapperBtn.toggleClass('fa-bars', this.collapsed).toggleClass(this.btnicon, !this.collapsed);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Collapsed':
                this.collapsed = boolFromStr(value);
                this.collapseDiv();
                break;
        }
    }
}

export class TwcCollapsePreview extends TwcCollapseWrapper {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcCollapsePreview';
    }

    override collapseDiv(): void {
        this.CollapseDiv.siblings().toggleClass('d-none', this.collapsed);
        this.CollapseWrapperBtn.toggleClass('fa-bars', this.collapsed).toggleClass(this.btnicon, !this.collapsed);
    }
}