import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from '../Shapes/TBaseShape';
import { TShapeActivity } from '../Shapes/TShapeActivity';
import { TShapeBPMNActivity } from '../Shapes/TShapeBPMNActivity';
import { TShapeBPMNData } from '../Shapes/TShapeBPMNData';
import { TShapeBPMNDataStore } from '../Shapes/TShapeBPMNDataStore';
import { TShapeBPMNEvent } from '../Shapes/TShapeBPMNEvent';
import { TShapeBPMNGateway } from '../Shapes/TShapeBPMNGateway';
import { TShapeCondition } from '../Shapes/TShapeCondition';
import { TShapeConditionLink } from '../Shapes/TShapeConditionLink';
import { TShapeConnector } from '../Shapes/TShapeConnector';
import { TShapeConnectorEnd } from '../Shapes/TShapeConnectorEnd';
import { TShapeConnectorStart } from '../Shapes/TShapeConnectorStart';
import { TShapeDatabase } from '../Shapes/TShapeDatabase';
import { TShapeDecision } from '../Shapes/TShapeDecision';
import { TShapeDocument } from '../Shapes/TShapeDocument';
import { TShapeHyperlink } from '../Shapes/TShapeHyperlink';
import { TShapeInformation } from '../Shapes/TShapeInformation';
import { TShapeKeyIndicator } from '../Shapes/TShapeKeyIndicator';
import { TShapeKeyIndicatorNew } from '../Shapes/TShapeKeyIndicatorNew';
import { TShapeLane } from '../Shapes/TShapeLane';
import { TShapeMainLink } from '../Shapes/TShapeMainLink';
import { TShapeNote } from '../Shapes/TShapeNote';
import { TShapeOrganisation } from '../Shapes/TShapeOrganisation';
import { TShapeProcessLink } from '../Shapes/TShapeProcessLink';
import { TShapeSpot } from '../Shapes/TShapeSpot';
import { TShapeSynch } from '../Shapes/TShapeSynch';
import { TShapeWarning } from '../Shapes/TShapeWarning';

// case unterscheidung cid
export function getObjByCID(cid: number, editor: TwsProcessEditorCustom): TBaseShape {
    switch (cid) {
        // case 0:
        //     return new TBaseShape;
        case 100:
            // Aktivität
            return new TShapeActivity(editor);
        case 200:
            // Entscheidung
            return new TShapeDecision(editor);
        case 300:
            // Synchronisation
            return new TShapeSynch(editor);
        case 400:
            // Erläuterung
            return new TShapeNote(editor);
        case 450:
            // Hyperlink
            return new TShapeHyperlink(editor);
        case 500:
            // Warnung
            return new TShapeWarning(editor);
        case 550:
            // Konnektor
            return new TShapeConnector(editor);
        case 560:
            // Konnektor (Start)
            return new TShapeConnectorStart(editor);
        case 570:
            // Konnektor (Ende)
            return new TShapeConnectorEnd(editor);
        case 600:
            // Prozess
            return new TShapeProcessLink(editor);
        case 650:
            // Verknüpfungen
            return new TShapeSpot(editor);
        case 660:
            // Hauptprozess
            return new TShapeMainLink(editor);
        case 700:
            // Dokumente
            return new TShapeDocument(editor);
        case 710:
            // Information
            return new TShapeInformation(editor);
        case 750:
            // Datenbank
            return new TShapeDatabase(editor);
        case 800:
            // Zuständigkeit
            return new TShapeLane(editor);
        case 850:
            // Organisation
            return new TShapeOrganisation(editor);
        case 900:
            // Ereignis
            return new TShapeCondition(editor);
        case 910:
            // Ereignisverknüpfungen
            return new TShapeConditionLink(editor);
        case 950:
            // Kennzahl (alt)
            return new TShapeKeyIndicator(editor);
        case 951:
            // Kennzahl
            return new TShapeKeyIndicatorNew(editor);
        case 960:
            // BPMN-Ereignis
            return new TShapeBPMNEvent(editor);
        case 965:
            // BPMN-Aktivität
            return new TShapeBPMNActivity(editor);
        case 970:
            // BPMN-Zugang
            return new TShapeBPMNGateway(editor);
        case 975:
            // BPMN-Data Object
            return new TShapeBPMNData(editor);
        case 980:
            // BPMN-Datenstore
            return new TShapeBPMNDataStore(editor);
        default:
            throw 'No valid shape cid';
    }
}