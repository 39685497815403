import { DataSet } from "vis-data";
import { DataGroupCollectionType, DataItemCollectionType, Timeline, TimelineOptions, TimelineTimeAxisScaleType } from "vis-timeline";
import { WebCompEventHandler } from "../../../core/communication";
import { b64DecodeUnicode, boolFromStr } from "../../../utils/strings";
import { TWebComponent } from '../../base/class.web.comps';

//https://visjs.github.io/vis-timeline/examples/timeline/styling/weekStyling.html

export class TwcTimegrid extends TWebComponent {
    timegridContainer: HTMLElement;
    timegrid: Timeline;
    timegridOptions: TimelineOptions;
    timegridData: Array<Object>;
    timegridItems: Array<Object>;

    datestart: string;
    dateend: string;

    locale: string;
    timeAxisScale: TimelineTimeAxisScaleType;
    timeAxisStep: number;

    hasOnClick: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTimegrid';

        this.timegridContainer = document.getElementById(`${this.id}-timegridcontainer`) as HTMLElement;

        // Configuration for the Timeline
        this.timegrid = null;
        this.timegridOptions = {};
        this.timegridData = [];
        this.timegridItems = [];

        this.datestart = this.obj.dataset.datestart;
        this.dateend = this.obj.dataset.dateend;

        this.hasOnClick = boolFromStr(this.obj.dataset.hasonclick);
        this.locale = 'de'; // gibt schon ein item hierfür, damit wir das richtige aus delphi bekommen // IMS-26965

        // erstmal als default, kann man dann noch übergeben +
        // bei Änderungen, auch initTimegridOptions anpassen
        this.timeAxisScale = (this.obj.dataset.unit ?? 'week') as TimelineTimeAxisScaleType;
        this.timeAxisStep = parseInt(this.obj.dataset.unitwidth) ?? 1;
    }

    override initDomElement() {
        super.initDomElement();

        // erst die Daten aufbereiten
        this.initTimegridItems(); // groups 
        this.initTimegridData(); //  datenpunkte
        // nun die Optionen 
        this.initTimegridOptions();
        // und jetzt starten wir das ding
        this.initTimegrid();

        // onclick
        this.timegridContainer.addEventListener('click', e => {
            let props = this.timegrid.getEventProperties(e);

            if (!this.hasOnClick) {
                return;
            }

            if (props.item != null) {
                WebCompEventHandler('OnClick', this.id, `${props.item}`);
            }
        });
    }

    initTimegridItems(): void {
        /*
        https://visjs.github.io/vis-timeline/docs/timeline/#items
        kommt so an 
        [{ "content": "Wir gruppieren was", "id": "gr0", "value": 1, className: 'group' }];
        */
        let items = this.obj.dataset?.timegriditems;
        items = String.raw`${b64DecodeUnicode(items)}`;
        if (items != '') {
            try {
                this.timegridItems = JSON.parse(items);
            } catch (err) {
                console.error('Fehler beim Laden der Items');
                console.error(err);
            }
        }
    }

    initTimegridData(): void {
        /*
       kommt so an 
       [ { start: "2022-12-22", end: "2022-12-22", group: "g1", className: "danger", content: "Argentina", id: "1", title: "Argentina" },];
       */
        let data = this.obj.dataset?.timegriddata;
        data = String.raw`${b64DecodeUnicode(data)}`;
        if (data != '') {
            try {
                this.timegridData = JSON.parse(data);
            } catch (err) {
                console.error('Fehler beim Laden der Data');
                console.error(err);
            }
        }
    }

    initTimegridOptions(): void {
        this.timegridOptions = {
            timeAxis: {
                scale: this.timeAxisScale,
                step: this.timeAxisStep
            },
            format: {
                minorLabels: { day: 'D', week: 'w', month: 'MMM', year: 'YYYY' }
            }
        };
        this.updateOptions();
    }

    updateOptions(): void {
        this.timegridOptions.orientation = 'both'; // gibt es ein item für, damit wir die leiste fixieren // IMS-26966
        this.timegridOptions.locale = this.locale;

        if (this.datestart != '0') {
            this.timegridOptions.start = new Date(this.datestart);
        }
        if (this.dateend != '0') {
            this.timegridOptions.end = new Date(this.dateend);
        }

        this.timegridOptions.timeAxis.step = this.timeAxisStep;
    }

    refreshOptions(): void {
        this.updateOptions();
        this.timegrid.setOptions(this.timegridOptions);
    }

    getTimegridData(): DataItemCollectionType {
        let data = new DataSet(this.timegridData);
        return data as unknown as DataItemCollectionType;
    }

    getTimegridItems(): DataGroupCollectionType {
        let groups = new DataSet(this.timegridItems);
        return groups as unknown as DataGroupCollectionType;
    }

    initTimegrid(): void {
        // Create a Timeline
        this.timegrid = new Timeline(this.timegridContainer, this.getTimegridData(), this.getTimegridItems(), this.timegridOptions);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'TimeUnitWidth':
                this.timeAxisStep = parseInt(value);
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.ChangeView':
                this.handleChangeView(params);
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }

    handleChangeView(type: string): void {
        switch (type) {
            case 'fit':
                this.timegrid.fit();
                break;
            case 'zoomIn':
                this.timegrid.zoomIn(0.2);
                break;
            case 'zoomOut':
                this.timegrid.zoomOut(0.2);
                break;
            case 'day':
                this.timegridOptions.timeAxis.scale = 'day';
                this.refreshOptions();
                break;
            case 'week':
                this.timegridOptions.timeAxis.scale = 'week';
                this.refreshOptions();
                break;
            case 'month':
                this.timegridOptions.timeAxis.scale = 'month';
                this.refreshOptions();
                break;
            case 'year':
                this.timegridOptions.timeAxis.scale = 'year';
                this.refreshOptions();
                break;
        }
    }
}