import $ from 'jquery';
import select2 from 'select2/dist/js/select2.full';
import { sendComponentRequestGetJson, WebCompEventHandler } from '../../../core/communication';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


// //Hook up select2 to jQuery
select2($);

enum CSObjectRequests {
    unknown = 0,
    requestAllTemplates = 1
}

class CSObjectCommand {
    command: CSObjectRequests;

    constructor() {
        this.command = CSObjectRequests.unknown;
    }
}

export class TwsCSObject extends TWebComponent {
    text: string;
    templates: Array<{ oid: string, template: string }>;
    select2: JQuery<HTMLElement>;
    hasChangeEvent: boolean;
    isInitialized: boolean;
    selectedOid: string;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsCSObject';
        this.text = '';
        this.isInitialized = false;
        this.templates = [];
        // Wir setzen die initiale SelectedID
        this.selectedOid = this.obj.dataset.selectedoid;

        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
    }

    override initDomElement(): void {
        super.initDomElement();

        let cmd = new CSObjectCommand();
        cmd.command = CSObjectRequests.requestAllTemplates;
        // handleRequestResult ruft InitializeSelect2 auf
        this.handleRequestResult(sendComponentRequestGetJson(this.id, cmd));

        // Wir setzen den initialen Status
        this.setEnabledReadOnly(boolFromStr(this.obj.dataset.enabled), boolFromStr(this.obj.dataset.readonly));
        this.setVisible(boolFromStr(this.obj.dataset.visible));
    }

    initializeSelect2(): void {
        if (!this.isInitialized) {
            this.isInitialized = true;

            $('#' + this.id).select2({
                width: '100%',
                templateResult: function (data, container) {
                    var result = $('<div>' + this.getTemplateFromOID(data.id) + '</div>');
                    return result;
                }.bind(this),
                placeholder: this.obj.dataset.placeholder,
                allowClear: true
            });

            // Wir müssen nach dem Initialisieren den Wert setzen
            // wichtig: vor den Eventlistener muss das gemacht werden
            $('#' + this.id).val(this.selectedOid).trigger('change');

            // Wir merken uns die oberste Komponente
            this.select2 = $('#select2-' + this.id + '-container').parent().parent().parent(); // Das select2 fügt ne relativ komplexe dom struktur 

            var self = this;

            $('#' + this.id).on({
                // neues Element
                'select2:select': function () {
                    self.selectedOid = String($('#' + this.id).val());
                    self.notifyComponentChanged();
                    if (self.hasChangeEvent) {
                        WebCompEventHandler('OnChange', self.id);
                    }
                },
                //Element weg
                'select2:unselect': function () {
                    self.selectedOid = String($('#' + this.id).val());
                    self.notifyComponentChanged();
                    if (self.hasChangeEvent) {
                        WebCompEventHandler('OnChange', self.id);
                    }
                }
            });
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'OID', $('#' + this.id).val() ? $('#' + this.id).val() : '']);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.setVisible(boolFromStr(value));
                break;
            case 'EnabledReadOnly':
                if (value == '00') {
                    this.setEnabledReadOnly(false, false);
                } else if (value == '01') {
                    this.setEnabledReadOnly(false, true);
                } else if (value == '10') {
                    this.setEnabledReadOnly(true, false);
                } else if (value == '11') {
                    this.setEnabledReadOnly(true, true);
                }
                break;
            case 'OID':
                let selfComponent = $('#' + this.id);

                if (selfComponent.val() != value) {
                    selfComponent.val(value);
                    // trigger('change') löst ein onchange aus
                    // das wollen wir verhindern und löschen es temporär
                    let tempOnchange = selfComponent[0].onchange;
                    selfComponent[0].onchange = null;
                    selfComponent.trigger('change');
                    // nun setzen wir onchange auf den Ursprunswert
                    selfComponent[0].onchange = tempOnchange;
                }
                break;
        }
    }

    setEnabledReadOnly(enabled: boolean, readOnly: boolean): void {
        $('#' + this.id).prop('disabled', !enabled || readOnly);
    }

    setVisible(visible: boolean): void {
        if (this.isInitialized) {
            this.select2.toggleClass('d-none', !visible);
        }
    }

    handleRequestResult(response: any) {
        switch (response.requestedCommand) {
            case CSObjectRequests.requestAllTemplates:
                this.templates = response.data;
                this.initializeSelect2();
                break;
        }

        if (response.errorCode != 0) {
            MsgNotify('Error occured: ' + response.errorMessage + ' [' + response.errorCode + ']', MessageType.Danger);
        }
    }

    getTemplateFromOID(oid: string): string {
        if (oid === undefined) {
            return '';
        }

        return this.templates.find(x => x.oid == oid).template;
    }
}

