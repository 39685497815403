import { getIDSuffix, SVGNS } from "../../../../utils/svg";
import { TTutorialShapeTextContentAction } from "../Actions/TTutorialShapeTextContentAction";
import { TBaseTutorialObject, TutorialFontSizeFactor, TutorialHorizontalAlignment, TutorialVerticalAlignment } from "../TBaseTutorialObject";


export enum TutorialFontStyles { fsBold, fsItalic, fsUnderline, fsStrikeOut };

export class TTutorialTextBase {
    id: string;
    parentElement: TBaseTutorialObject;
    outerDiv: HTMLElement;
    midDiv: HTMLElement;
    innerDiv: HTMLElement;
    svgElement: SVGForeignObjectElement;

    constructor(parentElement: TBaseTutorialObject) {
        this.id = '';
        this.svgElement = null;
        this.outerDiv;
        this.midDiv;
        this.innerDiv;

        // die Position kennt der ShapeText durch sein ParentElement (Shape / Edge)
        this.parentElement = parentElement;
    }

    createTextContent() {
        this.id = getIDSuffix(this.parentElement.id, 'text');

        this.svgElement = document.createElementNS(SVGNS, 'foreignObject');
        this.svgElement.setAttribute('id', this.id);
        this.svgElement.setAttribute('pointer-events', 'none');
        this.svgElement.setAttribute('width', '100%');
        this.svgElement.setAttribute('height', '100%');
        this.svgElement.classList.add('noselect');

        this.parentElement.editor.svg.appendChild(this.svgElement);

        this.outerDiv = document.createElement('div');
        this.outerDiv.setAttribute('id', getIDSuffix(this.parentElement.id, 'textdiv'));
        this.outerDiv.classList.add('divAussen');

        this.midDiv = document.createElement('div');
        this.midDiv.classList.add('divMitte');

        this.innerDiv = document.createElement('div');
        this.innerDiv.classList.add('divInnen');
        this.innerDiv.style.color = this.parentElement.fontColor.hex();
        this.innerDiv.innerText = this.parentElement.getTextContent();

        this.svgElement.appendChild(this.outerDiv);
        this.outerDiv.appendChild(this.midDiv);
        this.midDiv.appendChild(this.innerDiv);

        this.innerDiv.addEventListener('focusout', event => {
            let textContentAction: TTutorialShapeTextContentAction;

            if (this.innerDiv.classList.contains('cs-edit-shape-textcontent')) {
                textContentAction = new TTutorialShapeTextContentAction(this.parentElement as any, this.parentElement.editor);
                textContentAction.setValue(this.parentElement.getTextContent(), this.parentElement.getDisplayedTextContent());
            }

            this.parentElement.editor.actionList.addClientAction(textContentAction);
            textContentAction.performAction();

            this.innerDiv.removeAttribute('contenteditable');
            this.innerDiv.classList.remove('cs-edit-shape-textcontent');
        });

        this.innerDiv.addEventListener('keydown', event => {
            // Wir erlauben keine Umbrüche in Texten
            if (event.key === 'Enter') {
                event.preventDefault();
                this.innerDiv.blur();
            }
        });

        this.repaintTextContent();
    }

    deleteTextContent() {
        if (document.getElementById(this.id) != null) {
            document.getElementById(this.parentElement.editor.svg.id).removeChild(document.getElementById(this.id));
        }
    }

    repaintTextContent() {
        const FONT_PADDING = 2;
        let fontSize: (element: HTMLElement) => number = (element: HTMLElement) => parseFloat(window.getComputedStyle(element, null).getPropertyValue('font-size'));

        let baseTop = this.parentElement.boundingRect.top;
        switch (this.parentElement.textContentVertAlignment) {
            case TutorialVerticalAlignment.taAlignTop:
                baseTop += FONT_PADDING;
                break;
            case TutorialVerticalAlignment.taAlignOuterTop:
                baseTop += FONT_PADDING;
                break;
            case TutorialVerticalAlignment.taAlignBottom:
                baseTop += this.parentElement.boundingRect.bottom - this.parentElement.boundingRect.top - FONT_PADDING;
                break;
            case TutorialVerticalAlignment.taAlignOuterBottom:
                baseTop += this.parentElement.boundingRect.bottom - this.parentElement.boundingRect.top - FONT_PADDING;
                break;
            case TutorialVerticalAlignment.taVerticalCenter:
                baseTop += ((this.parentElement.boundingRect.bottom - this.parentElement.boundingRect.top) / 2);
                break;
        };

        let fontSizeRem: string;

        switch (this.parentElement.fontSize) {
            case TutorialFontSizeFactor.fsf100:
                fontSizeRem = '1rem';
                break;
            case TutorialFontSizeFactor.fsf150:
                fontSizeRem = '1.5rem';
                break;
            case TutorialFontSizeFactor.fsf200:
                fontSizeRem = '2rem';
                break;
            case TutorialFontSizeFactor.fsf250:
                fontSizeRem = '2.5rem';
                break;
            case TutorialFontSizeFactor.fsf300:
                fontSizeRem = '3rem';
                break;
            case TutorialFontSizeFactor.fsf350:
                fontSizeRem = '3.5rem';
                break;
            case TutorialFontSizeFactor.fsf400:
                fontSizeRem = '4rem';
                break;
            default:
                fontSizeRem = '1.5rem';
                break;
        }


        // temp fuer die action
        this.innerDiv.style.fontSize = fontSizeRem;

        this.outerDiv.style.width = (this.parentElement.boundingRect.right - this.parentElement.boundingRect.left) + 'px';
        this.outerDiv.style.height = '1px';
        this.outerDiv.style.paddingTop = `${baseTop}px`;
        this.outerDiv.style.marginLeft = this.parentElement.boundingRect.left + 'px';

        this.innerDiv.style.color = this.parentElement.fontColor.hex();

        this.setFontStyles();

        // alignment und vertalignment
        this.outerDiv.classList.toggle('alignCenter', this.parentElement.textContentHorzAlignment === TutorialHorizontalAlignment.taCenter);
        this.outerDiv.classList.toggle('alignLeft', this.parentElement.textContentHorzAlignment === TutorialHorizontalAlignment.taLeftJustify);
        this.outerDiv.classList.toggle('alignRight', this.parentElement.textContentHorzAlignment === TutorialHorizontalAlignment.taRightJustify);

        this.midDiv.classList.toggle('textAlignLeft', this.parentElement.textContentHorzAlignment === TutorialHorizontalAlignment.taLeftJustify);
        this.midDiv.classList.toggle('textAlignCenter', this.parentElement.textContentHorzAlignment === TutorialHorizontalAlignment.taCenter);
        this.midDiv.classList.toggle('textAlignRight', this.parentElement.textContentHorzAlignment === TutorialHorizontalAlignment.taRightJustify);

        this.outerDiv.classList.toggle('vAlignCenter', this.parentElement.textContentVertAlignment === TutorialVerticalAlignment.taVerticalCenter);
        this.outerDiv.classList.toggle('vAlignTop', this.parentElement.textContentVertAlignment === TutorialVerticalAlignment.taAlignTop);
        this.outerDiv.classList.toggle('vAlignTopOut', this.parentElement.textContentVertAlignment === TutorialVerticalAlignment.taAlignOuterTop);
        this.outerDiv.classList.toggle('vAlignBottom', this.parentElement.textContentVertAlignment === TutorialVerticalAlignment.taAlignBottom);
        this.outerDiv.classList.toggle('vAlignBottomOut', this.parentElement.textContentVertAlignment === TutorialVerticalAlignment.taAlignOuterBottom);

        this.innerDiv.classList.toggle('noWordWrap', !this.parentElement.wordWrap);

        // margins aus der config
        this.innerDiv.style.marginLeft = this.parentElement.margin.left + 'px';
        this.innerDiv.style.marginTop = this.parentElement.margin.top + 'px';
        this.innerDiv.style.marginRight = this.parentElement.margin.right + 'px';
        this.innerDiv.style.marginBottom = this.parentElement.margin.bottom + 'px';

        // lineheight aus der config
        this.innerDiv.style.lineHeight = String(this.parentElement.lineHeight / 10);
    }

    setFontStyles() {
        if (TutorialFontStyles.fsBold in this.parentElement.fontStyles)
            this.innerDiv.style.fontWeight = 'bold';
        if (TutorialFontStyles.fsItalic in this.parentElement.fontStyles)
            this.innerDiv.style.fontStyle = 'italic';
        if (TutorialFontStyles.fsUnderline in this.parentElement.fontStyles)
            this.innerDiv.style.textDecoration = 'underline';
        if (TutorialFontStyles.fsStrikeOut in this.parentElement.fontStyles)
            this.innerDiv.style.textDecoration = 'line-through';
    }
}