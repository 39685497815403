import { WebCompEventHandler } from '../../../core/communication';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from "../../base/class.web.comps";
import { ComponentProperty } from "../../interfaces/class.web.comps.intf";

export class TwcComboMenu extends TWebComponent {
    hasChangeEvent: boolean;
    items: HTMLCollection;
    itemIndex: number;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcComboMenu';
        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
        this.items = AsType<HTMLDivElement>(this.obj).children;
        this.itemIndex = Number(this.obj.dataset?.itemIndex ?? -1);
    }

    override initDomElement(): void {
        super.initDomElement();

        for (let i = 0; i < this.items.length; i++) {
            this.items[i].addEventListener('click', (event: MouseEvent) => this.handleButtonClick(event));
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    writeProperties(key: string, value: string): void {
        switch(key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Enabled':
                this.obj.toggleAttribute('disabled', value == '0');
                break;
            case 'ItemIndex':
                // Highlighting des vorher ausgewählten Buttons entfernen
                this.items[this.itemIndex].classList.remove('btn-primary');

                this.itemIndex = Number.parseInt(value);

                // neuen ausgewählten Buttons highlighten
                this.items[this.itemIndex].classList.add('btn-primary');
                break;
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'ItemIndex', this.itemIndex])
        return properties;
    }

    handleButtonClick(event: MouseEvent): void {
        for (let i = 0; i < this.items.length; i++) {    
            this.items[i].classList.remove('btn-primary');
            if (this.items[i] == event.srcElement) {
                this.itemIndex = i;
                this.items[i].classList.add('btn-primary');
            }
        }
        
        this.notifyComponentChanged();

        if (this.hasChangeEvent) {
            WebCompEventHandler('OnChange', this.id);   
        }
    }
}