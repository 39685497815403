import { ParticleAnimation } from '../../../classes/particleanimation';
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TRenderWebComponent } from '../../base/class.web.comps';

export class TwcOverlay extends TRenderWebComponent {
    private _visible: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcOverlay';

        this._visible = boolFromStr(this.obj.dataset.visible, false);
    }

    override doRender(timestamp: DOMHighResTimeStamp): void {
        this.obj.classList.toggle('active', this._visible);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this._visible = boolFromStr(value);
                break;
        }
    }

    show(): void {
        this._visible = true;
        this.invalidate();
    }

    hide(): void {
        this._visible = false;
        this.invalidate();
    }

    toggle(): void {
        this._visible = !this._visible;
        this.invalidate();
    }

    // Lines ist ein Array von String das in einzelne Paragraphen gewandelt wird
    changeOverlayContent(lines: string[], htmlMode: boolean = false): void {
        let contentDiv = document.getElementById(`${this.id}-content`);

        // wir löschen den alten Content
        contentDiv.innerHTML = '';

        // und fügen den neuen Paragraphenweise hinzu
        lines.forEach(text => {
            let paragraph = document.createElement('p');

            if (htmlMode) {
                paragraph.innerHTML = text;
            }
            else {
                let node = document.createTextNode(text);
                paragraph.appendChild(node);
            }

            contentDiv.appendChild(paragraph);
        });
    }
}

export class TwcOverlayAnimation extends TwcOverlay {
    private animation: ParticleAnimation;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcOverlayAnimation';

        if (assigned(document.getElementById(`${this.id}-content`)))
            this.animation = new ParticleAnimation(`${this.id}-canvas`);
    }
}