import Color from 'color';
import { Rectangle } from '../../../classes/geometry';
import { TwsProcessEditorCustom } from './class.web.comp.process.editor.custom';
import { FontStyles, TTextBase } from './Text/TTextBase';

export enum HorizontalAlignment { taLeftJustify, taRightJustify, taCenter };
export enum VerticalAlignment { taAlignOuterTop, taAlignTop, taAlignOuterBottom, taAlignBottom, taVerticalCenter };

export interface TBaseGraphObject {
    create(): void;
    delete(): void;
    invalidate(): void;
    repaintResizeHandlers(): void;
    setCaption(caption: string): void;
    getCaption(): string;

    id: string;
    guid: string;

    // Owner
    editor: TwsProcessEditorCustom;

    // Element im DOM
    svgElement: any;

    // Bounding Box
    boundingRect: Rectangle;

    // CaptionProperties
    fontColor: Color;
    fontStyles: Array<FontStyles>;

    captionElement: TTextBase;
    captionHorzAlignment: HorizontalAlignment;
    captionVertAlignment: VerticalAlignment;

    // ElementProperties
    selected: boolean;
    wordWrap: boolean;
    inlineEdit: boolean;
}

// da interface können wir für sowas leider keine basisimplementierung machen :( 
export function areEqualBaseGraphObjects(obj1: TBaseGraphObject, obj2: TBaseGraphObject): boolean {
    return obj1.id === obj2.id;
}