import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeBorderWidthAction extends TCustomTutorialShapeAction {
    oldWidth: number;
    newWidth: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldWidth = this.refElement.rotation;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeBorderWidth;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldWidth = obj.oldWidth;
        this.newWidth = obj.newWidth;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldWidth = this.oldWidth;
        obj.newWidth = this.newWidth;

        return obj;
    }

    setValue(newRotation: number): void {
        this.newWidth = Math.round(newRotation);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setBorderWidth(this.newWidth);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeBorderWidthAction(this.refElement, this.svgEditor);
        result.setValue(this.oldWidth);

        return result;
    }
}