import { TwsTutorialEditor } from "../class.web.comp.tutorialeditor";
import { TBaseTutorialShape } from "../Shapes/TBaseTutorialShape";
import { TCustomTutorialShapeAction, TutorialActionType } from "./TTutorialEditorAction";


export class TTutorialShapeFontSizeAction extends TCustomTutorialShapeAction {
    oldFontSize: number;
    newFontSize: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);

        this.oldFontSize = this.refElement.fontSize;
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapeRotation;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.oldFontSize = obj.oldFontSize;
        this.newFontSize = obj.newFontSize;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        obj.oldFontSize = this.oldFontSize;
        obj.newFontSize = this.newFontSize;

        return obj;
    }

    setValue(newFontSize: number): void {
        this.newFontSize = Math.round(newFontSize);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.setFontSize(this.newFontSize);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeFontSizeAction(this.refElement, this.svgEditor);
        result.setValue(this.oldFontSize);

        return result;
    }
}