import { TwsTutorialEditor } from '../class.web.comp.tutorialeditor';
import { TBaseTutorialShape } from '../Shapes/TBaseTutorialShape';
import { TCustomTutorialShapeAction, TutorialActionType } from './TTutorialEditorAction';

export class TTutorialShapeImageAction extends TCustomTutorialShapeAction {
    // dx: number;
    // dy: number;

    constructor(refElement: TBaseTutorialShape, editor: TwsTutorialEditor) {
        super(refElement, editor);
    }

    typeID(): TutorialActionType {
        return TutorialActionType.tatShapePosition;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        // this.dx = obj.dx;
        // this.dy = obj.dy;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;
        // obj.dx = Math.round(this.dx);
        // obj.dy = Math.round(this.dy);

        return obj;
    }

    setValue(dx: number, dy: number): void {
        // this.dx = Math.round(dx);
        // this.dy = Math.round(dy);
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        // this.refElement.setNewDraftPosition(this.refElement.x + this.dx, this.refElement.y + this.dy, true);
        this.refElement.invalidate();
    }

    inverseAction(): TCustomTutorialShapeAction {
        let result = new TTutorialShapeImageAction(this.refElement, this.svgEditor);
        // result.setValue(-this.dx, -this.dy);

        return result;
    }
}
