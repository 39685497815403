import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeActivity extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 100;
    }

    getSvgElementType(): string {
        return 'rect';
    }
}