import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcProgressbar extends TWebComponent {

    isEnabled: boolean;
    captionSeparate: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcProgressbar';
        this.isEnabled = false;
        this.captionSeparate = boolFromStr(this.obj.dataset.captionSeparate);
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'Value.Caption':
                if (this.captionSeparate)
                    document.getElementById(`${this.id}-caption-separate`).textContent = value
                else
                    document.getElementById(`${this.id}-caption`).textContent = value;
                break;
            case 'Value.AsPercent':
                document.getElementById(this.id + '-bar').style.width = value + '%';
                break;
            case 'Value.CaptionSeparate':
                this.captionSeparate = boolFromStr(value);
                document.getElementById(`${this.id}-caption-separate`).classList.toggle('d-none', !boolFromStr(value));
                document.getElementById(`${this.id}-caption`).classList.toggle('d-none', boolFromStr(value));
                break;
        }
    }
}