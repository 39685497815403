import $ from 'jquery';
import { ISignal, SignalDispatcher } from 'ste-signals';
import { sendComponentRequestGetPlainAsync, WebCompEventHandler } from '../../../core/communication';
import { assigned, onReady } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ISupportsActive, supportsActive } from '../../interfaces/supportsActive.intf';


export class TWebFrame extends TWebComponent implements ISupportsActive {

    public supportsActiveDiscriminator: 'I-Supports-Active' = 'I-Supports-Active';
    private _onShow = new SignalDispatcher();

    private isStale: boolean;
    isLateLoading: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TWebFrame';
        this.isLateLoading = boolFromStr(this.obj.dataset.islateloading, true);

        let currentParentComponent = this.getComponentsPath().head;
        while (assigned(currentParentComponent)) {
            if (supportsActive(currentParentComponent.value)) {
                let supportsActiveComponent = currentParentComponent.value as unknown as ISupportsActive;

                supportsActiveComponent.onShow().subscribe(() => this.triggerShowFrame())
            }

            currentParentComponent = currentParentComponent.next;
        }
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initLoader(); // ruft intern this.resetOnShowCmd();

        // Wenn wir das Frame direkt anzeigen wollen, dann Request via Client triggern, andernfalls wäre dort nur der LoadingSpinner...
        if (!this.isLateLoading || this.isActive()) {
            onReady(() => this.triggerShowFrame());
        }
    }

    private onShowCallback(): void {
        WebCompEventHandler('OnShown', this.id);

        this._onShow.dispatchAsync();
    }

    private triggerShowFrame(): void {
        if (this.isStale) {
            sendComponentRequestGetPlainAsync(this.id, this.onShowCallback.bind(this));
        }

        // wir feuern einmal den Ajax Request und danach ist alles wieder gut 🥳
        this.isStale = false;
    }

    public onShow(): ISignal {
        return this._onShow.asEvent();
    }

    public isActive(): boolean {
        // entweder wissen das die Parent Components
        let currentParentComponent = this.getComponentsPath().head;
        while (assigned(currentParentComponent)) {
            if (supportsActive(currentParentComponent.value) && currentParentComponent.value !== this) {
                let supportsActiveComponent = currentParentComponent.value as unknown as ISupportsActive;

                return supportsActiveComponent.isActive();
            }

            currentParentComponent = currentParentComponent.next;
        }

        // oder wir müssen uns als Aktiv identifizieren
        return true;
    }

    writeProperties(key: string, value: string): void {
        //
    }

    initLoader(): void {
        $('#' + this.id).html('<div class="text-center"><div class="spinner-border text-primary"></div></div>');

        // wenn wir den Loader initialisieren, brauchen wir danach auch den Request für die neuen Daten
        this.isStale = true;
    }

    override execAction(action: string, params: string): void {
        try {
            switch (action) {
                case 'Action.ReloadFrame':
                    this.initLoader();
                    if (this.isActive()) {
                        this.triggerShowFrame();
                    }

                    break;
                default:
                    super.execAction(action, params);
                    break;
            }
        } catch (e) {
            // statements
            console.log(e);
        }
    }
}