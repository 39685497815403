import { Point } from "../../classes/geometry";
import { assigned } from "../../utils/helper";
import { findComponent, registerComponent } from "../base/controlling";
import { TwcObjectListSelector, TwcObjectListSelectorPopDown } from "../comps/CSObject/class.web.comp.csobjectlist";

export class CSPopDown {
    isActive: boolean;
    popup: JQuery<HTMLElement>;
    popupId: string;
    listSelector: TwcObjectListSelector;
    sobjectlistid: string;
    placeholdertext: string;
    dataSet: { key: string; caption: string; captionraw: string };
    events: any;

    mouseOutsideClickEvent: (e: any) => void;

    constructor(popdownId: string) {
        this.isActive = false;
        this.popup = null;
        this.popupId = popdownId;
        this.listSelector = null;
        this.sobjectlistid = String($('#' + this.popupId).data('sobjectlistid'));
        this.placeholdertext = String($('#' + this.sobjectlistid).data('placeholder'));
        this.dataSet = {
            'key': '',
            'caption': '',
            'captionraw': ''
        };

        new CSPopDownEvents(this);
        // um den eventlistener später zu removen müssen wir mit "named"-function arbeiten
        this.mouseOutsideClickEvent = e => this.handleMouseOutsideClick(e);
    }

    show(): Promise<any> {
        return new Promise(function (resolve, reject) {
            // erst initialisieren
            this.initPopDown();
            this.initObjectfinder();

            window.setTimeout(function () {
                this.openPopDown();
            }.bind(this), 100);

            this.on(CSPopDown.constEventClose() + '.cspopdown', function (data) {
                reject(data);
            }.bind(this));

            this.on(CSPopDown.constEventSelect() + '.cspopdown', function (data) {
                resolve(this.dataSet);
            }.bind(this));

            this.on(CSPopDown.constEventHidden() + '.cspopdown', function (data) {
                this.unregisterCloseEvents();
            }.bind(this));

        }.bind(this));
    }

    handleMouseOutsideClick(event: MouseEvent): void {
       this.close(true);
    }

    registerCloseEvents(): void {
        // eventlistener für close setzen
        document.addEventListener('click', this.mouseOutsideClickEvent, false);
        $(this.popup).on('keydown', {}, this.execOnKeyDownEvent.bind(this));
        $(this.popup).click(function (event) {
            event.stopPropagation();
        });
    }
    
    unregisterCloseEvents(): void {
       // eventlistener für close wieder löschen
       document.removeEventListener('click', this.mouseOutsideClickEvent, false);
    }
 
    execOnKeyDownEvent(event: KeyboardEvent): void {
        event.stopPropagation();
        if (event.key === 'Escape') {
            this.close(true);
        } else if (event.key === ' ') {
            // Wenn wir nur eine Leezeile haben, dann können wir schließen
            if (this.listSelector.getInput().toString().trim() == '') {
                this.close(false);
            }
        }
    }

    initPopDown(): void {
        this.popup = $('#' + this.sobjectlistid + '-container');
        if (this.popup.length == 0) {
            this.popup = $('<div id="' + this.sobjectlistid + '-container" class="cs-popdown cs-width-lg" style="display:none;"></div>');
            // Popover MUSS den Modal hinzugefügt werden, damit der Fokus geht!!! VORSICHT: Nur seinen parent Modal!
            if ($('#' + this.sobjectlistid).parents('.modal:visible').length > 0) {
                $('#' + this.sobjectlistid).parents('.modal:visible').append(this.popup);
            } else {
                $(document.body).append(this.popup);
            }

            var input = $('<input id="' + this.sobjectlistid + '-input" class="form-control" placeholder="' + this.placeholdertext + '">');
            $(this.popup).append(input);
        }

        let coords = this.getSelectionCoords();
        this.popup.css({
            left: coords.x + 10 + window.scrollX
        }).css({
            top: coords.y + 10 + window.scrollY
        });
        this.registerCloseEvents();
    }

    initObjectfinder(): void {
        // Sonderlogik: Die PoPDowns werden hier erst bei Bedarf, sprich wenn einer @ drückt, manuell on the run erstellt
        // Die haben im HTML auch KEINEN data-type, werden daher über das Framework auch nicht automatisiert erstellt.
        this.listSelector = findComponent(this.sobjectlistid) as TwcObjectListSelector;
        if (!assigned(this.listSelector)) {
            this.listSelector = new TwcObjectListSelectorPopDown(document.getElementById(this.sobjectlistid));
            registerComponent(this.sobjectlistid, this.listSelector);
        }
        this.listSelector.OnClickEvent = function (k, c, cr) {
            this.autocompleteCallback(k, c, cr);
        }.bind(this);
    }

    autocompleteCallback(key: string, caption: string, captionraw: string): void {
        this.dataSet.key = key;
        this.dataSet.caption = caption;
        this.dataSet.captionraw = captionraw;
        this.select();
    }

    openPopDown(): void {
        this.triggerEvent(CSPopDown.constEventShow(), null);
        this.popup.show(1, function () {
            this.isActive = true;
            if (this.listSelector) {
                this.listSelector.setFocus();
            }
            this.triggerEvent(CSPopDown.constEventShown(), null);
        }.bind(this));
    }

    close(closeByEscape: boolean): void {
        this.closePopDown();
        this.triggerEvent(CSPopDown.constEventClose(), closeByEscape);
    }

    select(): void {
        this.closePopDown();
        this.triggerEvent(CSPopDown.constEventSelect(), null);
    }

    closePopDown(): void {
        if (this.isActive) {
            this.popup.css({
                display: 'none'
            });
            this.isActive = false;
            this.popup.hide(100, function () {
                this.triggerEvent(CSPopDown.constEventHidden(), null);
            }.bind(this));
        }
    }

    triggerEvent(event: string, data: boolean): void {
        this.events.dispatch(event + '.cspopdown', data);
    }

    on(eventName: any, callback: any): void {
        this.events.on(eventName, callback);
    }

    off(eventName: any, callback: any): void {
        this.events.off(eventName, callback);
    }

    getSelectionCoords(): Point {
        let sel: Selection, range: Range, rects: string | any[] | DOMRectList, rect: DOMRect;
        let x = 0,
            y = 0;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.rangeCount) {
                range = sel.getRangeAt(0).cloneRange();
                if (range.getClientRects) {
                    range.collapse(true);
                    rects = range.getClientRects();
                    if (rects.length > 0) {
                        rect = rects[0];

                        x = rect.left;
                        y = rect.top;
                    }
                }
                // Fall back to inserting a temporary element
                if (x == 0 && y == 0) {
                    var span = document.createElement('span');
                    if (span.getClientRects) {
                        // firefox schreibt <br> in das Feld oder fügt dahinter ein
                        if (navigator.userAgent.toLowerCase().includes('firefox')) {
                            if ((range.commonAncestorContainer as Element).innerHTML == range.commonAncestorContainer.textContent + '<br>' || (range.commonAncestorContainer as Element).innerHTML == '<br>' + range.commonAncestorContainer.textContent) {
                                $(range.commonAncestorContainer).html(range.commonAncestorContainer.textContent);
                            }
                        }
                        // Ensure span has dimensions and position by
                        // adding a zero-width space character
                        span.appendChild(document.createTextNode("\u200b"));
                        range.insertNode(span);
                        rect = span.getClientRects()[0];
                        x = rect.left;
                        y = rect.top;
                        var spanParent = span.parentNode;
                        spanParent.removeChild(span);
                        // Glue any broken text nodes back together
                        spanParent.normalize();
                    }
                }
            }
        }
        return new Point(x, y);
    }

    // UPS // ENUM!!!
    static constEventShow(): string {
        return 'show';
    }
    static constEventShown(): string {
        return 'shown';
    }
    static constEventSelect(): string {
        return 'select';
    }
    static constEventHide(): string {
        return 'hide';
    }
    static constEventHidden(): string {
        return 'hidden';
    }
    static constEventClose(): string {
        return 'close';
    }
};


/********************************************************/
/* popdown event*/

class CSPopDownEvent {
    eventName: any;
    callbacks: any[];
    constructor(eventName) {
        this.eventName = eventName;
        this.callbacks = [];
    }

    registerCallback(callback) {
        this.callbacks.push(callback);
    }

    unregisterCallback(callback) {
        const index = this.callbacks.indexOf(callback);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }

    fire(data) {
        const callbacks = this.callbacks.slice(0);
        callbacks.forEach((callback) => {
            callback(data);
        });
    }
}
/********************************************************/
/* popdown events*/
class CSPopDownEvents {
    cspopdown: any;
    events: {};
    constructor(cspopdown) {
        this.cspopdown = cspopdown
        this.cspopdown.events = this;
        this.events = {};
    }

    dispatch(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            event.fire(data);
        }
    }

    on(eventName, callback) {
        let event = this.events[eventName];
        if (!event) {
            event = new CSPopDownEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
    }

    off(eventName, callback) {
        const event = this.events[eventName];
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }
}