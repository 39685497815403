import bootstrap from "bootstrap";
import { assigned } from '../../../utils/helper';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcAccordionSection extends TWebComponent {

    collapseElement: HTMLElement;
    captionElement: HTMLElement;
    collapseObj: bootstrap.Collapse;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcAccordionSection';
        this.captionElement = document.getElementById(`${this.id}-caption`);
        this.collapseElement = document.getElementById(`${this.id}-collapse`);
        this.collapseObj = new bootstrap.Collapse(this.collapseElement, {
            toggle: false
        });
    }

    override initDomElement(): void {
        super.initDomElement();
        this.refreshListItems();
    }

    refreshListItems(): void {
        // neu strukturieren: Wir müssen die Klassen neu berechnen: nth-child nimmt alle li 
        // optimale Lösung wäre li:nth-child(2n-1):not('d-none') -> LEIDER kann das Css nicht, nth-child selektiert alle li und nicht skippen kann bei d-none
        // Deswegen erstmal der Umweg über js und hier erstmal nur bei halfspace listen
        if (assigned(this.collapseElement) && this.obj.classList.contains('halfspace-items')) {
            let items = this.collapseElement.querySelectorAll('.accordion-section-list-item');
            for (let i = 0; i < items.length; i++) {
                let visibleItems = Array.from(items[i].children).filter(item => !item.classList?.contains('d-none'));
                items[i].classList.toggle('d-none', visibleItems.length === 0);
            }
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'IsCollapsed', this.captionElement.getAttribute('aria-expanded')]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Enabled':
                this.obj.classList.toggle('disabled', !boolFromStr(value));
                break;
            case 'Caption':
                this.captionElement.textContent = value;
                break;
            case 'IsCollapsed':
                if (boolFromStr(value)) {
                    this.collapseObj.hide();
                } else {
                    this.collapseObj.show();
                }
                break;
        }
    }
}

export class TwcAccordionContainer extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcAccordionContainer';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Enabled':
                this.obj.classList.toggle('disabled', !boolFromStr(value));
                break;
        }
    }
}