﻿import { ISignal, SignalDispatcher } from 'strongly-typed-events';
import { MessageType, MsgNotify } from '../../../core/utils/msgnotify';
import { assigned } from '../../../utils/helper';
import { AsType } from '../../../utils/html';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { requireComponent } from '../../base/controlling';
import { TwcCustomFileTransfer } from '../FileTransfer/class.web.comp.filetransfer.base';

export class TwcFileUpload extends TWebComponent {
    private _onFileUploaded = new SignalDispatcher();

    hasServerUploadEvent: boolean;
    hasCustomUploadButton: boolean;
    uploadInput: HTMLInputElement;
    fileInputButton: HTMLButtonElement;
    fileTypes: Array<string>;
    fileTransferID: string;
    fileTransfer: TwcCustomFileTransfer;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwcFileUpload';

        this.hasServerUploadEvent = boolFromStr(this.obj.dataset.hasServerUploadEvent);
        this.hasCustomUploadButton = boolFromStr(this.obj.dataset.hasCustomUploadButton);

        this.fileInputButton = AsType<HTMLButtonElement>(this.obj.querySelector('.fileinput-button'));
        this.uploadInput = AsType<HTMLInputElement>(this.obj.querySelector('.cs-upload'));
        this.fileTransferID = this.obj.dataset.filetransferid;
        this.updateFileTypes();
    }

    override initDomElement(): void {
        super.initDomElement();

        if (this.hasCustomUploadButton)
            this.fileInputButton.addEventListener('click', () => this.triggerFileSelect());

        this.uploadInput.addEventListener('change', (event) => this.handleFileSelect(event));
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    updateFileTypes() {
        // erlaubte Dateinendungen holen
        let fileTypes = this.uploadInput.accept.toLowerCase();

        if (fileTypes === '') {
            this.fileTypes = [];
            return;
        }
        // und im Array speichern, dabei den ersten Punkt entfernen. Aus ".exe,.jpg" wird "['exe','jpg']"
        this.fileTypes = fileTypes.split(',').map(ext => ext.slice(1));
    }

    triggerFileSelect() {
        this.uploadInput.click();
    }

    handleFileSelect(evt: Event) {
        evt.stopPropagation();
        evt.preventDefault();

        let file = this.uploadInput.files[0];
        let extension = file.name.split('.').pop().toLowerCase();
        let extensionAllowed = this.fileTypes.length == 0 || this.fileTypes.indexOf(extension) > -1;

        if (extensionAllowed) {
            if (!assigned(this.fileTransfer)) {
                this.fileTransfer = requireComponent<TwcCustomFileTransfer>(this.fileTransferID);

                this.fileTransfer.onError.subscribe(() => this.onFileTransferError());
                this.fileTransfer.onSuccess.subscribe(() => this.onFileTransferSuccess());
            }

            this.fileTransfer.uploadFile(file);
        } else {
            MsgNotify('File extension not allowed', MessageType.Danger);
        }
    }


    onFileTransferError() {
        this.uploadInput.value = '';
    }

    onFileTransferSuccess() {
        this.uploadInput.value = '';
    }

    writeProperties(key: string, value: string) {
        switch (key) {
            case 'FileExtensions':
                this.uploadInput.setAttribute('accept', value);
                this.updateFileTypes();
                break;
            case 'Enabled':
                this.fileInputButton.disabled = !boolFromStr(value);
                this.uploadInput.disabled = !boolFromStr(value);
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }

    public onFileUploaded(): ISignal {
        return this._onFileUploaded.asEvent();
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.InvalidFileExt':
                MsgNotify(params, MessageType.Danger);
                break;
            case 'Action.UploadSuccess':
                MsgNotify(params, MessageType.Success);
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
}