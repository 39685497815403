import Color from 'color';
import isEmpty from 'lodash.isempty';
import { Point, SizeDefinition } from '../../../classes/geometry';
import { WebCompEventHandlerAsync, sendComponentRequestGetJsonNoError } from '../../../core/communication';
import { boolFromStr } from '../../../utils/strings';
import { SVGNS, getIDSuffix, svgSetSize } from '../../../utils/svg';
import { TRenderWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';
import { TCustomTutorialEditorAction } from './Actions/TTutorialEditorAction';
import { TTutorialEditorActionList } from './Actions/TTutorialEditorActionList';
import { TBaseTutorialShape } from './Shapes/TBaseTutorialShape';
import { TBaseTutorialObject } from './TBaseTutorialObject';
import { getTutorialActionByTypeID } from './Utils/TTutorialEditorActionHandling';
import { initMoveEvents } from './Utils/TTutorialEditorMoveUtils';
import { initResizeEvents } from './Utils/TTutorialEditorResizeUtils';
import { initRotateEvents } from './Utils/TTutorialEditorRotateUtils';

export class TwsTutorialEditor extends TRenderWebComponent {
    private isInitialized: boolean;

    actionList: TTutorialEditorActionList;
    svg: SVGSVGElement;

    width: number;
    height: number;
    objectList: Array<TBaseTutorialObject>;
    background: SVGRectElement;
    minWidth: number;
    minHeight: number;
    fullscreen: boolean;
    svgElement: HTMLElement;
    backgroundColor: Color;
    zoomSlider: HTMLElement;
    zoomFactor: number;
    svgDefs: SVGDefsElement;
    useGrid: boolean;
    gridSize: SizeDefinition;
    grid: SVGRectElement;

    //#region Component Init
    override initComponent() {
        super.initComponent();
        this.classtype = 'TwsTutorialEditor';

        // DO FIRST
        this.isInitialized = false;

        this.svgElement = document.getElementById(getIDSuffix(this.id, 'svg'));

        this.width = 1600;
        this.height = 900;
        this.backgroundColor = Color(this.svgElement.dataset.color) ?? Color('#CCC');
        this.fullscreen = false;
        this.zoomFactor = parseInt(this.svgElement.dataset.zoom) / 100;
        this.zoomSlider = document.getElementById(getIDSuffix(this.id, 'zoom'));

        this.objectList = [];
        this.actionList = new TTutorialEditorActionList();
        this.actionList.registerChangeEvent(action => this.handleActionListChanged(action));

        this.grid = null;
        this.useGrid = false;
        this.gridSize = { width: 50, height: 50 };
    }

    override initDomElement(): void {
        super.initDomElement();

        // Unser "Canvas":
        // Hier werden alle Shapes / Edges / Elemente abgelegt
        this.svg = document.createElementNS(SVGNS, 'svg');
        this.svg.setAttribute('id', getIDSuffix(this.id, 'svg'));
        svgSetSize(this.svg, this.width, this.height);

        // Canvas unter Div hängen
        this.svgElement.appendChild(this.svg);

        // Defs
        this.svgDefs = document.createElementNS(SVGNS, 'defs');
        this.svg.appendChild(this.svgDefs);

        // background        
        this.background = document.createElementNS(SVGNS, 'rect');
        this.svg.appendChild(this.background);

        this.background.setAttribute('id', getIDSuffix(this.id, 'bg'));
        this.background.setAttribute('fill', this.backgroundColor.hex());
        this.background.setAttribute('stroke', Color('#000').hex());
        this.background.setAttribute('stroke-opacity', String(0.5));
        this.background.setAttribute('width', '100%');
        this.background.setAttribute('height', '100%');

        // GRID SECTION
        this.grid = document.createElementNS(SVGNS, 'rect');
        this.svg.appendChild(this.grid);
        this.grid.setAttribute('id', getIDSuffix(getIDSuffix(this.id, 'svg'), 'grid'));

        let gridPattern = document.createElementNS(SVGNS, 'pattern');
        this.svgDefs.appendChild(gridPattern);

        let gridPath = document.createElementNS(SVGNS, 'path');
        gridPattern.appendChild(gridPath);

        let brightnessTreshhold = 85;
        let rgb = [this.backgroundColor.red(), this.backgroundColor.green(), this.backgroundColor.blue()];
        let yiq = (rgb[0] * 300 + rgb[1] * 590 + rgb[2] * 110) / 1000;
        let gridColor = (yiq < brightnessTreshhold) ? this.backgroundColor.lighten(0.2) : this.backgroundColor.darken(0.2);

        gridPattern.setAttribute('id', getIDSuffix(getIDSuffix(this.id, 'svg'), 'gridPattern'));
        gridPattern.setAttribute('width', String(this.gridSize.width));
        gridPattern.setAttribute('height', String(this.gridSize.height));
        gridPattern.setAttribute('patternUnits', 'userSpaceOnUse');
        gridPath.setAttribute('d', `M ${this.gridSize.width} 0 L 0 0 0 ${this.gridSize.height}`);
        gridPath.setAttribute('fill', 'none');
        gridPath.setAttribute('stroke', gridColor.hex()); // vorher gray
        gridPath.setAttribute('stroke-width', '2'); // vorher 0.5

        this.grid.setAttribute('width', '100%');
        this.grid.setAttribute('height', '100%');
        this.grid.setAttribute('fill', 'url(#' + getIDSuffix(getIDSuffix(this.id, 'svg'), 'gridPattern') + ')');
        this.grid.setAttribute('pointer-events', 'none');
        this.grid.classList.toggle('d-none', !this.useGrid);

        this.initEvents();

        this.parseFromJSON(this.svgElement.dataset.json);
        this.isInitialized = true;
    }
    //#endregion

    //#region SVG Init
    parseFromJSON(jsonsrc: string): void {
        let src = JSON.parse(jsonsrc);

        if (isEmpty(src)) {
            return;
        }

        let action = getTutorialActionByTypeID(src.actionType, this.objectById(src.refElementID), this);
        action.fromJSON(src);
        action.performAction();

        // die "create" Action brauchen wir auch für den initialen Zustand
        this.actionList.addClientAction(action);
    }

    initEvents(): void {
        this.background.addEventListener('mousedown', event => this.handleMouseDownEvent(event));

        this.svg.addEventListener('mousedown', event => {
            this.invalidate();
        });

        this.svg.addEventListener('wheel', event => {
            if (event.ctrlKey) {
                event.preventDefault();
                let newZoom = Math.round(this.zoomFactor * 100 + event.deltaY * -0.1);

                if (newZoom < 10 || newZoom > 400) {
                    // Außerhalb des gültigen Bereichs
                }
                else {
                    this.zoomEditor(newZoom);
                    WebCompEventHandlerAsync('OnZoomChange', this.id, null, String(newZoom));
                }
            }
        });

        window.addEventListener('keyup', event => {

            let selectedShapes = this.getSelectedShapes();
            let isSingleShapeSelected = this.isSingleShapeSelected();

            // Inline Textedit
            if ((isSingleShapeSelected) && event.key === 'F2') {
                event.preventDefault();

                let tutorialObj: TBaseTutorialObject;

                tutorialObj = selectedShapes[0];

                let tutorialObjText = tutorialObj.textContentElement.innerDiv;

                if (tutorialObj.inlineEdit) {
                    // this.elementCaptionEditLock = true;
                    tutorialObjText.setAttribute('contenteditable', String(true));

                    // extras TBaseShape
                    if (tutorialObj instanceof TBaseTutorialShape) {
                        tutorialObjText.classList.add('cs-edit-shape-textcontent');
                    }

                    const selection = window.getSelection();
                    const range = document.createRange();
                    selection.removeAllRanges();
                    range.selectNodeContents(tutorialObjText);
                    range.collapse(false);
                    selection.addRange(range);
                    tutorialObjText.focus();
                }

            }
        });

        initMoveEvents(this);
        initRotateEvents(this);
        initResizeEvents(this);
    }

    //#region Events
    getLocalCoordinatesFromMouseEvent(evt: { clientX: number; clientY: number; }) {
        let pt = this.svg.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;
        return pt.matrixTransform(this.svg.getScreenCTM().inverse());
    }

    handleMouseDownEvent(event: MouseEvent): void {
        if (!event.shiftKey) {
            this.clearSelectedElements();
        }

        let editor = this;

        let pointStart = this.getLocalCoordinatesFromMouseEvent(event);
        let selectionRect = document.createElementNS(SVGNS, 'rect');
        selectionRect.setAttribute('class', 'selectionRect');
        updateSelectionRect(selectionRect, pointStart, pointStart);
        editor.svg.appendChild(selectionRect);

        document.documentElement.addEventListener('mousemove', trackMouseMove, false);
        document.documentElement.addEventListener('mouseup', stopTrackingMove, false);

        function trackMouseMove(evt: { clientX: number; clientY: number; }) {
            let pointEnd = editor.getLocalCoordinatesFromMouseEvent(evt);
            updateSelectionRect(selectionRect, pointStart, pointEnd);
        }

        function stopTrackingMove() {
            document.documentElement.removeEventListener('mousemove', trackMouseMove, false);
            document.documentElement.removeEventListener('mouseup', stopTrackingMove, false);
            editor.svg.removeChild(selectionRect);
            updateSelection(selectionRect);
        }

        function updateSelectionRect(rect: SVGRectElement, ptStart: DOMPoint, ptEnd: DOMPoint) {
            let xs = [ptStart.x, ptEnd.x].sort(sortByNumber),
                ys = [ptStart.y, ptEnd.y].sort(sortByNumber);
            rect.setAttribute('x', String(xs[0]));
            rect.setAttribute('y', String(ys[0]));
            rect.setAttribute('width', String(xs[1] - xs[0]));
            rect.setAttribute('height', String(ys[1] - ys[0]));
        }

        function sortByNumber(a: number, b: number) {
            return a - b
        }

        function updateSelection(selection: SVGRectElement) {

            let x = parseInt(selection.getAttribute('x')),
                y = parseInt(selection.getAttribute('y')),
                w = parseInt(selection.getAttribute('width')),
                h = parseInt(selection.getAttribute('height'));

            function overlap(obj: TBaseTutorialShape): boolean {
                // Shape und multiSelect sind nebeneinander  
                if (x >= obj.x + obj.w || obj.x >= x + w) {
                    return false;
                }

                // Shape und multiSelect sind übereinander
                if (y >= obj.y + obj.h || obj.y >= y + h) {
                    return false;
                }

                return true;
            }

            editor.objectList.forEach(obj => {
                if (obj instanceof TBaseTutorialShape)
                    if (overlap(obj) && !obj.isFixedElement()) {
                        obj.selected = true;
                    }
            });

            editor.notifySelectedElementsChanged();
        }
    }
    //#endregion

    //#region Component Properties
    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];

        properties.push([this.id, 'ActionListPointer', this.actionList.listPointer]);
        properties.push([this.id, 'ActionListLength', this.actionList.actions.length]);
        properties.push([this.id, 'Fullscreen', this.fullscreen]);
        return properties;
    }

    writeProperties(key: string, value: any): void {
        switch (key) {
            case 'ServerActions':
                let src = JSON.parse(value);

                src.forEach(element => {
                    let action = getTutorialActionByTypeID(element.actionType, this.objectById(element.refElementID), this);
                    action.fromJSON(element);
                    this.actionList.registerServerAction(action);
                    this.notifyComponentChanged();
                    action.performAction();
                });
                this.invalidate();
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '1');
                break;
            case 'Fullscreen':
                this.fullscreen = boolFromStr(value);
                this.toggleFullscreen();
                break;
        }
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.Redo':
                this.beginUpdate();
                try {
                    let redoAction = this.actionList.getRedoAction();
                    redoAction.performAction();
                    this.loadServerAction(this.actionList.getCurrentStateActionGuid());
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.Undo':
                this.beginUpdate();
                try {
                    let undoAction = this.actionList.getUndoAction();
                    undoAction.performAction();
                    this.loadServerAction(this.actionList.getCurrentStateActionGuid());
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'ActionList.Clear':
                this.actionList.reset();
                break;
            case 'Action.SelectAllShapes':
                this.beginUpdate();
                try {
                    this.objectList.forEach(obj => {
                        if (obj instanceof TBaseTutorialShape) {
                            obj.selected = true;
                        }
                    });
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SelectShapes':
                this.beginUpdate();
                try {
                    this.objectList.forEach(obj => {
                        if (obj instanceof TBaseTutorialShape) {
                            if (params.includes(';' + obj.id + ';')) {
                                obj.selected = true;
                            } else {
                                obj.selected = false;
                            }
                        }
                    });
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.ClearSelection':
                this.beginUpdate();
                try {
                    this.clearSelectedElements();
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.CheckEditorSize':
                this.beginUpdate();
                try {
                    this.checkEditorSize();
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.SetImage':
                this.beginUpdate();
                try {
                    let shape = this.objectList.find(x => x.id === params);
                    // Und hier das Bild aktualisieren
                    shape.setImage();
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.ToggleFullscreen':
                this.beginUpdate();
                try {
                    this.toggleFullscreen();
                }
                finally {
                    this.endUpdate();
                }
                this.notifyComponentChanged();
                break;
            case 'Action.SetZoom':
                this.beginUpdate();
                try {
                    this.zoomEditor(parseInt(params));
                }
                finally {
                    this.endUpdate();
                }
                break;
            case 'Action.ToggleGrid':
                this.useGrid = !this.useGrid;
                this.grid.classList.toggle('d-none', !this.useGrid);
                break;
            default:
                super.execAction(action, params);
                break;
        }
    }
    //#endregion

    //#region Action Handling
    private handleActionListChanged(action: TCustomTutorialEditorAction<any>): void {
        this.notifyComponentChanged();

        if (this.isInitialized) {
            this.forceServerAction(action);
        }

        // Wenn es Aktionen gab müssen wir auch immer das UI updaten
        this.invalidate();
    }

    private forceServerAction(action: TCustomTutorialEditorAction<any>): void {
        sendComponentRequestGetJsonNoError(this.id, { Action: 'ServerAction', ActionData: action }, true);
        WebCompEventHandlerAsync('OnClientActionAdded', this.id, function () { });
    }

    private loadServerAction(action: TCustomTutorialEditorAction<any>): void;
    private loadServerAction(guid: string): void;
    private loadServerAction(param: string | TCustomTutorialEditorAction<any>): void {
        if (typeof param === 'string') {
            this.notifyComponentChanged();
            sendComponentRequestGetJsonNoError(this.id, { Action: 'LoadAction', ActionGuid: param }, true);
        }
        else {
            return this.loadServerAction(param.actionGuid);
        }
    }
    //#endregion

    //#region Data API
    registerObj(obj: TBaseTutorialObject): void {
        if (this.objectList.indexOf(obj) < 0) {
            this.objectList.push(obj);
        }
    }

    unregisterObj(obj: TBaseTutorialObject): void {
        let i = this.objectList.indexOf(obj);
        if (i < 0) {
            return;
        }

        this.objectList.splice(i, 1);
    }

    objectById(id: string): TBaseTutorialObject {
        return this.objectList.find(obj => obj.id === id);
    }

    getSelectedShapes(): Array<TBaseTutorialShape> {
        // Selektierte Filtern
        return this.objectList.filter(obj => obj.selected).filter(obj => obj instanceof TBaseTutorialShape) as Array<TBaseTutorialShape>;
    }

    getSelectedShapeIDs(): string {
        return `;${this.getSelectedShapes()
            // IDs sammeln
            .map(shape => shape.id)
            // Zu String joinen
            .join(';')
            };`;
    }

    clearSelectedElements(): void {
        this.objectList.forEach(obj => {
            obj.selected = false;
        });

        this.invalidate();
    }

    isSingleShapeSelected(): boolean {
        return this.getSelectedShapes().length === 1;
    }

    notifySelectedElementsChanged(): void {
        WebCompEventHandlerAsync('OnSelectedShapesChanged', this.id, null, this.getSelectedShapeIDs());
        this.invalidate();
    }
    //#endregion

    //#region Rendering
    override doRender(timestamp: DOMHighResTimeStamp): void {
        // Durch den Core wird sonst ggf. versucht hier was zu zeichnen, ohne das der Editor sichtbar initialisiert wurde, was natürlich nicht geht....
        if (!this.isInitialized) {
            return;
        }

        let realWidth = this.width;
        let realHeight = this.height;

        this.svg.setAttribute('width', String(realWidth * this.zoomFactor));
        this.svg.setAttribute('height', String(realHeight * this.zoomFactor));
        this.svg.setAttribute('viewBox', '0 0 ' + String(realWidth) + ' ' + String(realHeight));

        // den Offset für das SVG setzen
        // Beim marginTop müssen wir noch die Höhe der Toolbar berücksichtigen
        this.svg.style.marginTop = String(Math.max(((this.obj.parentElement.clientHeight - realHeight * this.zoomFactor) / 2) - 30, 0));
        this.svg.style.marginLeft = String(Math.max(((this.obj.parentElement.clientWidth - realWidth * this.zoomFactor) / 2), 0));

        this.updateZOrder();

        // selected
        this.objectList.forEach(obj => {
            obj.invalidate();

            if (obj instanceof TBaseTutorialShape) {
                obj.svgElement.classList.toggle('shapeSelected', obj.selected);
            }
        });
    }

    getLocalCoordinate(value: MouseEvent | Point): Point {
        let domPoint = this.svg.createSVGPoint();
        domPoint.x = value.x
        domPoint.y = value.y
        let transformedDomPoint = domPoint.matrixTransform(this.svg.getScreenCTM().inverse());

        return new Point(transformedDomPoint.x, transformedDomPoint.y);
    }

    toggleFullscreen(): void {
        document.getElementById(this.id).classList.toggle('panel-fullscreen', this.fullscreen);
        // toolbar.classList.toggle('d-none', !value);
        // wir markieren auch am page-inner, dass wir nun fullscreen sind, da sonst manche Komponenten, die ausserhalb des ProzessEditorDivs liegen nicht mitbekommen.
        document.getElementsByClassName('page-inner')[0].classList.toggle('has-panel-fullscreen', this.fullscreen);

        this.checkEditorSize();
    }

    zoomEditor(value: number): void {
        // Höhe und Breite steuern den Zoom über das Verhältnis
        // zu Höhe und Breite der viewBox
        this.zoomFactor = value / 100;

        // im Anschluss die größe Aktualisieren
        this.checkEditorSize();
    }

    checkEditorSize(): void {
        // wir müssen die UI auf die neue Size update
        this.invalidate();
    }

    updateZOrder() {
        this.objectList.forEach(obj => {
            if (obj instanceof TBaseTutorialShape) {

                // z-order
                if (obj.getOwnerPos() === 0) {
                    if (obj.svgElement != this.grid.nextSibling) {
                        this.svg.insertBefore(obj.svgElement, this.grid.nextSibling);
                        this.svg.insertBefore(obj.textContentElement.svgElement, obj.svgElement.nextSibling);
                    }
                }
                else {
                    let nextSibling = this.objectList.find(x => (x instanceof TBaseTutorialShape) && (x as TBaseTutorialShape).getOwnerPos() === obj.getOwnerPos() - 1).textContentElement.svgElement.nextSibling;

                    if (obj.svgElement != nextSibling) {
                        this.svg.insertBefore(obj.svgElement, nextSibling);
                        this.svg.insertBefore(obj.textContentElement.svgElement, obj.svgElement.nextSibling);
                    }
                }

                // Bild
                if (obj.hasImage)
                    this.svg.insertBefore(obj.image, obj.svgElement.nextSibling);

            }
        });
    }
    //#endregion
}