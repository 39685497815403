﻿import { WebCompEventHandler } from "../../../core/communication";
import { assigned, X } from "../../../utils/helper";
import { getParents } from "../../../utils/html";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

enum TDropdownAlignment { dalLeftRight, dalTopBottom };

export class TwcMultiSelect extends TWebComponent {
    emptytext: string;
    hasChangeEvent: boolean;
    dropdown: HTMLElement;
    dropdownAlignment: TDropdownAlignment;
    insideModal: boolean;
    modalParent: HTMLElement;
    mouseOutsideClickEvent: (e: MouseEvent) => void;
    keyEventEscapePressed: (e: KeyboardEvent) => void;
    dropdownPostionEvent: (e: MouseEvent) => void;
    dropdownVisible: boolean;
    canEdit: boolean;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcMultiSelect';
        this.emptytext = this.obj.dataset.emptytext ?? '';
        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
        this.dropdown = document.getElementById(`${this.id}-dropdown`);
        this.dropdownAlignment = this.obj.dataset.dropdownalignment == '0' ? TDropdownAlignment.dalLeftRight : TDropdownAlignment.dalTopBottom;
        this.modalParent = null;
        this.insideModal = this.isInsideModal();
        this.mouseOutsideClickEvent = e => this.handleMouseOutsideClick(e);
        this.keyEventEscapePressed = e => this.handlekeyEventEscapePressed(e);
        this.dropdownPostionEvent = e => this.handleDropdownPosition(e);
        this.dropdownVisible = false;
        this.canEdit = !this.obj.hasAttribute('disabled');
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initButtonClickEvent();
        this.initContainerClickEvent();
        this.initSearch();
        this.initLabelChange();
        this.initBadgeRemove();
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    isInsideModal(): boolean {
        let parents = getParents(this.obj);

        for (let i = 0; i < parents.length; i++) {
            // wir sind oben? dann raus...
            if (parents[i] === document.body)
                break;

            // wir sind also in einem Modal
            if (parents[i].classList.contains('modal')) {
                this.modalParent = parents[i] as HTMLElement;
                return true;
            }

        }
        return false;
    }

    handlekeyEventEscapePressed(e: KeyboardEvent): void {
        if (e.key === 'Escape') {
            e.stopPropagation();

            this.dropdown.style.display = 'none';
            this.dropdownVisible = false;
            this.unregisterDropdownEvents();
            // falls wir im Modal waren, den Focus wieder setzen!
            if (this.insideModal && assigned(this.modalParent))
                this.modalParent.focus();
        }
    }

    handleMouseOutsideClick(e: MouseEvent): void {
        // schließen falls der Klick außerhalb des Auswahlfensters war!
        if (!this.dropdown.contains(e.target as Node)) {
            this.dropdown.style.display = 'none';
            this.dropdownVisible = false;
            this.unregisterDropdownEvents();
            // falls wir im Modal waren, den Focus wieder setzen!
            if (this.insideModal && assigned(this.modalParent))
                this.modalParent.focus();
        }
    }

    handleDropdownPosition(e: MouseEvent): void {
        // this.obj ist die MultiSeclect Komponente
        // this.dropdown ist das Popup

        let btn = document.getElementById(`${this.id}-btn`);

        let height = this.dropdown.offsetHeight;
        let width = this.dropdown.offsetWidth;

        // Hat das Dropdown genug Platz? -> dropdown kurz "unsichtbar" einblenden, damit wir an die Werte kommen
        if (!this.dropdownVisible && (height || width) == 0) {
            if (this.insideModal || this.dropdownAlignment == TDropdownAlignment.dalTopBottom)
                this.dropdown.style.left = '-10000px';

            this.dropdown.style.display = 'block';
            height = this.dropdown.offsetHeight;
            width = this.dropdown.offsetWidth;
            this.dropdown.style.display = 'none';
        }

        let dropdownMarginTop = parseFloat(window.getComputedStyle(this.dropdown).marginTop);

        if (this.insideModal) {
            // rect liefert bessere Koordianten
            let rect = this.obj.getBoundingClientRect();

            // volle Breite nach oben/unten
            if (this.dropdownAlignment == TDropdownAlignment.dalTopBottom) {
                // hier orientieren wir uns an der Komponente
                this.dropdown.style.left = rect.left + 'px';

                // nach unten genug Platz?
                if (height + rect.bottom >= window.innerHeight) {
                    // nein, also nach oben aufgehen
                    this.dropdown.style.top = (rect.top - height - dropdownMarginTop) + 'px';
                } else
                    this.dropdown.style.top = (rect.bottom - dropdownMarginTop) + 'px';

                this.dropdown.style.width = rect.width + 'px';
            } else {
                // hier orientieren wir uns an dem Mausklick
                // links vom Mausklick Button (Breite abziehen)
                this.dropdown.style.left = (e.clientX - width) + 'px';

                // nach unten genug Platz? immer Links
                if (height + e.clientY >= window.innerHeight) {
                    // nein, also nach oben aufgehen
                    this.dropdown.style.top = (e.clientY - height - dropdownMarginTop) + 'px';
                }
                else
                    this.dropdown.style.top = (e.clientY - dropdownMarginTop) + 'px';
            }
        } else if (this.dropdownAlignment == TDropdownAlignment.dalTopBottom) {
            this.dropdown.style.left = this.obj.offsetLeft + 'px';

            // nach unten genug Platz?
            if (height + this.obj.offsetHeight + this.obj.offsetTop >= window.innerHeight) {
                // nein, also nach oben aufgehen
                this.dropdown.style.top = this.obj.offsetTop - height - dropdownMarginTop + 'px';
            }
            else
                this.dropdown.style.top = (this.obj.offsetHeight + this.obj.offsetTop - dropdownMarginTop) + 'px';
            this.dropdown.style.width = this.obj.offsetWidth + 'px';
        } else {
            // nach unten genug Platz? -> ansonsten nach oben verschieben
            if (height + this.obj.offsetTop >= window.innerHeight) {
                // nein, also nach oben aufgehen
                this.dropdown.style.top = 0 - height - dropdownMarginTop + 'px';
            } else {
                // der parent hat position-relative, also sind wir mit top: 0px auf der gleichen Ebene
                this.dropdown.style.top = '0px';
            }
        }

        if (this.dropdownVisible)
            this.dropdown.style.display = 'block';
    }

    initButtonClickEvent(): void {
        document.getElementById(`${this.id}-btn`).addEventListener('click', (event) => {
            this.toggleDropdown(event);
        });
    }

    initContainerClickEvent(): void {
        document.getElementById(`${this.id}-text`).addEventListener('click', (event) => {
            let target = event.target as Element;
            //bei klick auf ein badge passiert nichts, wir triggern das dropdown nur beim klick auf den editierbaren container
            if (target.matches('.cs-multi-badge') || target.closest('.cs-multi-badge') || !this.canEdit) {
                return;
            }
            this.toggleDropdown(event);
        });
    }

    toggleDropdown(event: MouseEvent): void {
        event.stopPropagation();
        let wasVisible = this.dropdownVisible;

        // click event auf document triggern, damit andere Popover, Dropdowns, etc. sich schließen
        document.dispatchEvent(new Event('click'));

        // wenn wir sichtbar waren, dann soll ein erneuter Klick das Dropdown schließen
        if (wasVisible) {
            return;
        }

        // für Modal oder unterhalb der Komponente müssen wir das Dropdown im Baum verschieben
        if (this.insideModal || this.dropdownAlignment == TDropdownAlignment.dalTopBottom) {
            // sind wir in einem Modal? 
            if (this.insideModal) {
                this.dropdown.closest('.modal').append(this.dropdown);
            } else {
                this.obj.parentNode.insertBefore(this.dropdown, this.obj.nextSibling);
            }
        }

        this.handleDropdownPosition(event);
        // Events registrieren
        this.registerDropdownEvents();

        let search = document.getElementById(`${this.id}-search`);
        search.style.boxShadow = 'none';
        search.style.border = 'none';

        // und anzeigen
        this.dropdown.style.display = 'block';
        this.dropdownVisible = true;

        search.focus();
    }


    unregisterDropdownEvents(): void {
        document.removeEventListener('click', this.mouseOutsideClickEvent, false);
        // Reszie des Fensters
        window.removeEventListener('resize', this.dropdownPostionEvent, false);
        // das Displayfeld ändert sich?
        let displaytext = document.getElementById(`${this.id}-text`);
        if (assigned(displaytext)) {
            displaytext.removeEventListener('resize', this.dropdownPostionEvent, false);
        }
        // esc
        this.dropdown.addEventListener('keydown', this.keyEventEscapePressed, false);
    }

    registerDropdownEvents(): void {
        // Event zum Schließen
        document.addEventListener('click', this.mouseOutsideClickEvent, false);
        // Reszie des Fensters
        window.addEventListener('resize', this.dropdownPostionEvent, false);
        // das Displayfeld ändert sich?
        document.getElementById(`${this.id}-text`).addEventListener('resize', this.dropdownPostionEvent, false);
        // esc
        this.dropdown.addEventListener('keydown', this.keyEventEscapePressed, false);
    }

    initBadgeRemove(): void {
        let self = this;
        $('#' + this.id + ' .cs-multi-remove').each(function (index) {
            $(this).on('click', function () {
                // checkbox unchecked
                $('#' + self.id + '-dropdown .cs-multi-checkbox[data-id="' + $(this).data('id') + '"]').prop('checked', false);

                // Badge entfernen
                $('#' + self.id + ' #badge-' + $(this).data('id')).remove();
                // und ggf. <keine Angabe> falls nicht gecheckt ist
                if ($('#' + self.id + '-dropdown .cs-multi-checkbox:checked').length == 0) {
                    $('#' + self.id + '-text').text(self.emptytext);
                }

                self.notifyComponentChanged();
                if (self.hasChangeEvent) {
                    WebCompEventHandler('OnChange', self.id)
                }
            });
        });
    }

    initLabelChange(): void {
        let self = this;
        $('#' + this.id + '-dropdown .cs-multi-checkbox').each(function (index) {
            $(this).on('change', function () {
                self.handleLabelChanged();

                self.notifyComponentChanged();
                if (self.hasChangeEvent) {
                    WebCompEventHandler('OnChange', self.id)
                }
            })
        });
    }

    handleLabelChanged(): void {
        function badgeWithText(text: string, id: string, lockedAttr: string, canEdit: string): string {
            return '<div id="badge-' + id + '" class="badge cs-multi-badge my-1 ' + lockedAttr + ' " ' + canEdit + ' >' + X(text) + '<button type="button" class="cs-multi-remove" data-id="' + id + '">&times</button></div>';
        }

        let text = '';
        let self = this;
        $('#' + this.id + '-dropdown .cs-multi-checkbox:checked').each(function (index) {
            text = `${text} ${badgeWithText($(this).data('label'), $(this).data('id'), $(this).prop('disabled') ? 'locked' : '', !self.canEdit ? 'disabled' : '')}`.trim();
        });

        // nichts ausgewählt?
        if (text == '') {
            $('#' + this.id + '-text').text(this.emptytext);
            return;
        }

        $('#' + this.id + '-text').html(text);

        this.initBadgeRemove();
    }

    initSearch(): void {
        let self = this;
        $('#' + this.id + '-search').on('keyup', function () {
            let value = $(this).val().toString().toLowerCase();

            $(`#${self.id}-dropdown.dropdown-menu label`).each((index: number, item: HTMLElement) => {
                // nach Text filtern
                let hasText = $(item).text().toLowerCase().indexOf(value) > -1;
                // nach Kategorie filtern
                let hasCategory = $(item).data('category') !== undefined && $(item).data('category').toLowerCase().indexOf(value) > -1;

                $(item).toggle(hasText || hasCategory);
            });
        });
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'SelectedIds', this.getSelectedIds()]);
        return properties;
    }

    getSelectedIds(): string {
        let tagIDs = [];
        $('#' + this.id + '-dropdown .cs-multi-checkbox:checked').each(function (index) {
            tagIDs.push($(this).data('id'));
        });
        return ';' + tagIDs.join(';') + ';';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                if (value == '1') {
                    $('#' + this.id).removeClass('d-none');
                } else if (value == '0') {
                    $('#' + this.id).addClass('d-none');
                }
                break;
            case 'State':
                if (value == '0') {
                    //Disabled
                    $('#' + this.id + '-btn').prop('disabled', true);
                    $('#' + this.id + '-btn').removeClass('d-none');
                    $('#' + this.id).attr('disabled', 'true');
                    $('#' + this.id + ' .cs-multi-badge').each(function () {
                        $(this).attr('disabled', 'true');
                        $(this).find('.cs-multi-remove').prop('disabled', true);
                    });
                    this.canEdit = false;
                } else if (value == '1') {
                    // ReadOnly -> machen wir auch disabled
                    $('#' + this.id + '-btn').prop('disabled', true);
                    $('#' + this.id + '-btn').addClass('d-none');
                    $('#' + this.id).attr('disabled', 'true');
                    $('#' + this.id + ' .cs-multi-badge').each(function () {
                        $(this).attr('disabled', 'true');
                        $(this).find('.cs-multi-remove').prop('disabled', true);
                    });
                    this.canEdit = false;
                } else if (value == '2') {
                    //Enabled
                    $('#' + this.id + '-btn').prop('disabled', false);
                    $('#' + this.id + '-btn').removeClass('d-none');
                    // $('#' + this.id).attr('disabled', 'false');
                    this.obj.removeAttribute('disabled');
                    $('#' + this.id + ' .cs-multi-badge').each(function () {
                        this.removeAttribute('disabled');
                        // nur die nicht gelockten wieder enablen
                        if (!($(this).hasClass('locked')))
                            $(this).find('.cs-multi-remove').prop('disabled', false);
                    });
                    this.canEdit = true;
                }
                break;
            case 'Lock':
                var splitted = value.split(';');
                // erstmal alles entlocken
                $('#' + this.id + '-dropdown .cs-multi-checkbox:checked').each(function () {
                    if (!splitted.includes($(this).data('id'))) {
                        $(this).prop('disabled', false);
                        $('#badge-' + $(this).data('id')).removeClass('locked');
                    }
                });
                for (var i = 0, len = splitted.length; i < len; i++) {
                    if (splitted[i].trim() != '') {
                        $('#' + this.id).find('#badge-' + splitted[i] + '.cs-multi-badge').addClass('locked');
                        $('#' + this.id + '-dropdown .cs-multi-checkbox[data-id="' + splitted[i] + '"]').prop('disabled', true);
                    }
                }
                break;
            case 'SelectedIds':
                let self = this
                var splitted = value.split(';');
                // erstmal alles unselecten und removen
                $('#' + this.id + '-dropdown .cs-multi-checkbox:checked').each(function () {
                    if (!splitted.includes(String($(this).data('id')))) {
                        $(this).prop('disabled', false);
                        $(this).prop('checked', false);
                        $('#' + self.id + ' #badge-' + $(this).data('id')).remove();
                    }
                });
                for (var i = 0, len = splitted.length; i < len; i++) {
                    if (splitted[i].trim() != '') {
                        $('#' + this.id + '-dropdown .cs-multi-checkbox[data-id="' + splitted[i] + '"]').prop('checked', true).prop('disabled', !this.canEdit);
                    }
                }
                // und ggf. <keine Angabe> falls nicht gecheckt ist
                if ($('#' + this.id + '-dropdown .cs-multi-checkbox:checked').length == 0) {
                    $('#' + this.id + '-text').text(this.emptytext);
                }
                this.handleLabelChanged();
                WebCompEventHandler('OnChange', this.id);
                break;
        }
    }
}



export class TwcMultiSelectIcon extends TwcMultiSelect {
    constructor(obj) {
        super(obj);
        this.classtype = 'TwcMultiSelectIcon';
    }

    override handleLabelChanged() {
        function badgeWithText(text, id, locked, icon) {
            return '<div id="badge-' + id + '" class="badge cs-multi-badge my-1' + locked + '">' + '<i class="fa-solid ' + icon + ' me-1"></i>' + text + '<button type="button" class="cs-multi-remove " data-id="' + id + '">&times</button></div>';
        }

        let text = '';
        $('#' + this.id + '-dropdown .cs-multi-checkbox:checked').each(function (index) {
            text = `${text} ${badgeWithText($(this).data('label'), $(this).data('id'), $(this).prop('disabled') ? ' locked' : '', $(this).data('icon'))}`.trim();
        });

        // nichts ausgewählt?
        if (text == '') {
            $('#' + this.id + '-text').text(this.emptytext);
            return;
        }

        $('#' + this.id + '-text').html(text);

        this.initBadgeRemove();
    }
}

export class TwcMultiSelectImg extends TwcMultiSelect {
    constructor(obj) {
        super(obj);
        this.classtype = 'TwcMultiSelectImg';
    }

    override handleLabelChanged() {
        function badgeWithText(text, id, locked, imgpath) {
            return '<div id="badge-' + id + '" class="badge cs-multi-badge cs-multi-img-badge m-1 p-1' + locked + '">' + '<img src="' + imgpath + '" class="cs-preview-icon rounded p-1" alt="' + text + '" title="' + text + '">' + '<button type="button" class="cs-multi-remove cs-multi-img-remove px-2" data-id="' + id + '">&times</button></div>';
        }

        let text = '';
        $('#' + this.id + '-dropdown .cs-multi-checkbox:checked').each(function (index) {
            text = `${text} ${badgeWithText($(this).data('label'), $(this).data('id'), $(this).prop('disabled') ? ' locked' : '', $(this).data('imgpath'))}`.trim();
        });

        // nichts ausgewählt?
        if (text == '') {
            $('#' + this.id + '-text').text(this.emptytext);
            return;
        }

        $('#' + this.id + '-text').html(text);

        this.initBadgeRemove();
    }
}