import { Bounds, Rectangle } from "../../../classes/geometry";
import { assigned } from "../../../utils/helper";
import { AsType, getParentBySelectors } from "../../../utils/html";
import { AfterRenderCallback, TRenderWebComponent } from "../../base/class.web.comps";
import { requireComponent } from "../../base/controlling";
import { ComponentProperty } from "../../interfaces/class.web.comps.intf";
import { TwcTileboard } from "./class.web.comp.tileboard";

export class TwcTileboardItem extends TRenderWebComponent {

    x: number;
    y: number;

    width: number;
    height: number;

    caption: string;

    private virtualX: number;
    private virtualY: number;
    private virtualWidth: number;
    private virtualHeight: number;

    locked = false;
    private owner: TwcTileboard;

    captionContainer: HTMLDivElement;
    captionElement: HTMLSpanElement;
    contentContainer: HTMLDivElement;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcTileboardItem';

        this.x = parseInt(this.obj.dataset.x);
        this.y = parseInt(this.obj.dataset.y);

        this.width = parseInt(this.obj.dataset.width ?? '1');
        this.height = parseInt(this.obj.dataset.height ?? '1');

        this.caption = this.obj.dataset?.caption ?? '';
        this.captionContainer = AsType<HTMLDivElement>(this.obj.querySelector('.cs-tileboard-caption'));
        this.captionElement = AsType<HTMLSpanElement>(this.captionContainer.querySelector('span'));

        this.contentContainer = AsType<HTMLDivElement>(this.obj.querySelector('.cs-tileboard-content-inner'));

        this.initTileboardItem();
    }

    override initDomElement() {
        super.initDomElement();
    }

    initTileboardItem() {
        let parent = getParentBySelectors(this.obj, '[data-type="TwcTileboard"],[data-type="TwcTileboardEditor"]');
        if (!assigned(parent)) {
            throw `Parent board for tileboard item not found [${this.id}].`;
        }

        let tileboard = requireComponent<TwcTileboard>(parent);
        if (!assigned(tileboard)) {
            throw `Cannot create tileboard instance from parent.`;
        }

        this.owner = tileboard;
        this.owner.registerTileboardItem(this);
    }

    override invalidate(forced?: boolean, afterRender?: AfterRenderCallback, updateParent?: boolean) {
        updateParent = updateParent ?? false;

        if (updateParent) {
            this.owner.invalidate(forced, afterRender); // via parent landen wir später wieder hier, aber im else Zweig, falls was getan werden muss 
        }
        else {
            super.invalidate(forced, afterRender);
        }
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        let pos = this.getCurrentPosition();
        let usedWidth = pos.right - pos.left + 1;
        let usedHeight = pos.bottom - pos.top + 1;

        this.obj.style.left = `calc((100% + ${this.owner.usedColumnSpaceAsPx()}) / ${this.owner.usedColumnCount()} * ${pos.left})`;
        this.obj.style.top = `calc((${this.owner.usedRowHeightAsPx()} + ${this.owner.usedRowSpaceAsPx()}) * ${pos.top})`;

        this.obj.style.width = `calc(((100% - ${this.owner.usedColumnSpaceAsPx()} * (${this.owner.usedColumnCount()} - 1)) / ${this.owner.usedColumnCount()}) * ${usedWidth} + (${usedWidth} - 1) * ${this.owner.usedColumnSpaceAsPx()})`;
        this.obj.style.height = `calc(${this.owner.usedRowHeightAsPx()} * ${usedHeight} + ${this.owner.usedRowSpaceAsPx()} * (${usedHeight} - 1))`;


        this.captionElement.innerText = this.caption;
        this.captionElement.title = this.caption;
        this.captionContainer.classList.toggle('d-none', (this.caption ?? '') === '');

        // generic stuff
        this.checkApplyReadonly()
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    override execAction(action: string, params: string): void {
        switch (action) {
            case 'Action.ReplaceContent':
                {
                    this.contentContainer.innerHTML = params;
                    break;
                }
            default: super.execAction(action, params);
        }
    }

    protected doAfterItemChangedByServer(): void {
        this.owner.afterTileboardItemChanged(this);
    }

    setDraggable(value: boolean) {
        this.obj.draggable = value;
    }

    /**
     * Current position as Boungs
     * @returns Bounds
     * @remarks The Bounds mean the occupied cells. To calculate width and height you have to add 1.
     */
    getCurrentPosition(): Bounds {
        let x = this.virtualX ?? this.x;
        let y = this.virtualY ?? this.y;

        let width = this.virtualWidth ?? this.width;
        let height = this.virtualHeight ?? this.height;

        return new Rectangle(x, y, x + width - 1, y + height - 1);
    }

    private checkApplyReadonly(): void {
        let editMode = this.owner.isEditMode();
        this.setDraggable(editMode);
    }

    isInPosition(position: Bounds): boolean {
        let itemPos = this.getCurrentPosition();

        return itemPos.left <= position.right && itemPos.right >= position.left && itemPos.top <= position.bottom && itemPos.bottom >= position.top;
    }

    applyPendingChanges(): void {
        let pos = this.getCurrentPosition()

        this.x = pos.left;
        this.y = pos.top;

        this.width = pos.right - pos.left + 1;
        this.height = pos.bottom - pos.top + 1;

        this.resetPendingChanges();
        this.updateDataset();

        this.invalidate(false);

        this.notifyComponentChanged();
    }

    setVirtualX(x: number): void {
        if (isNaN(x)) {
            throw new Error(`Value for "X" is not a number [${x}].`); // NaN unter keinen Umständen in die Koordinaten schreiben, sonst triggern wir Endlosschleifen.
        }

        if (x < 0) {
            throw 'Negative positions not allowed [x].';
        }

        this.virtualX = x;
    }

    setVirtualY(y: number): void {
        if (isNaN(y)) {
            throw new Error(`Value for "Y" is not a number [${y}].`); // NaN unter keinen Umständen in die Koordinaten schreiben, sonst triggern wir Endlosschleifen.
        }

        if (y < 0) {
            throw 'Negative positions not allowed [y].';
        }

        this.virtualY = y;
    }

    setVirtualWidth(width: number): void {
        if (isNaN(width)) {
            throw new Error(`Value for "Width" is not a number [${width}].`); // NaN unter keinen Umständen in die Koordinaten schreiben, sonst triggern wir Endlosschleifen.
        }

        this.virtualWidth = width;
    }

    setVirtualHeight(height: number): void {
        if (isNaN(height)) {
            throw new Error(`Value for "Height" is not a number [${height}].`); // NaN unter keinen Umständen in die Koordinaten schreiben, sonst triggern wir Endlosschleifen.
        }

        this.virtualHeight = height;
    }

    setVirtualPosition(x: number, y: number): void {
        this.setVirtualX(x);
        this.setVirtualY(y);
    }

    resetPendingChanges(): void {
        this.virtualX = undefined;
        this.virtualY = undefined;
        this.virtualWidth = undefined;
        this.virtualHeight = undefined;
    }

    removeDeprecatedChanges(): void {
        if (this.virtualX !== undefined && this.virtualX == this.x) {
            this.virtualX = undefined;
        }

        if (this.virtualY !== undefined && this.virtualY == this.y) {
            this.virtualY = undefined;
        }

        if (this.virtualWidth !== undefined && this.virtualWidth == this.width) {
            this.virtualWidth = undefined;
        }

        if (this.virtualHeight !== undefined && this.virtualHeight == this.height) {
            this.virtualHeight = undefined;
        }
    }

    updateDataset(): void {
        this.obj.dataset.x = String(this.x);
        this.obj.dataset.y = String(this.y);

        if (this.virtualX !== undefined) {
            this.obj.dataset.virtualX = String(this.virtualX);
        }
        else {
            delete this.obj.dataset.virtualX;
        }

        if (this.virtualY !== undefined) {
            this.obj.dataset.virtualY = String(this.virtualY);
        }
        else {
            delete this.obj.dataset.virtualY;
        }

        if (this.virtualWidth !== undefined) {
            this.obj.dataset.virtualWidth = String(this.virtualWidth);
        }
        else {
            delete this.obj.dataset.virtualWidth;
        }

        if (this.virtualHeight !== undefined) {
            this.obj.dataset.virtualHeight = String(this.virtualHeight);
        }
        else {
            delete this.obj.dataset.virtualHeight;
        }
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];

        properties.push([this.id, 'X', this.x]);
        properties.push([this.id, 'Y', this.y]);
        properties.push([this.id, 'Width', this.width]);
        properties.push([this.id, 'Height', this.height]);

        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'X':
                this.x = parseInt(value);
                this.doAfterItemChangedByServer();
                break;
            case 'Y':
                this.y = parseInt(value);
                this.doAfterItemChangedByServer();
                break;
            case 'Width':
                this.width = parseInt(value);
                this.doAfterItemChangedByServer();
                break;
            case 'Height':
                this.height = parseInt(value);
                this.doAfterItemChangedByServer();
                break;
            case 'Caption':
                this.caption = value;
        }

        this.invalidate(null, null, true); // wir lassen via Dashboard neu rendern
    }
}