import { TWebComponent } from '../../base/class.web.comps';

export class TwcBanner extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcBanner';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Caption':
                this.obj.innerHTML = value;
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0');
                break;
        }
    }
}