import { TWebComponent } from '../../base/class.web.comps';

export class TwsActivityItem extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwsActivityItem';

    }

    writeProperties(key: string, value: string | number): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == 0);
                break;
        }
    }
}


export class TwsActivityComment extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwsActivityComment';

    }

    writeProperties(key: string, value: string | number): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value == 0);
                break;
        }
    }
}