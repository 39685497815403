import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcDivEx extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcDivEx';        
    }
    
    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
            case 'Enabled':
                this.obj.setAttribute('disabled', String(value == '0'));
                break;
            case 'Readonly':
                // UPS // Hat ein Div überhaupt ReadOnly?
                if (value == '1') {
                    $(this.obj).prop('readonly', true);
                } else if (value == '0') {
                    $(this.obj).prop('readonly', false);
                }
                break;
        }
    }
}