import { assigned } from '../../../utils/helper';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty, IWebComponent } from "../../interfaces/class.web.comps.intf";

export class TwcNavBar extends TWebComponent {
    private selectedItem: HTMLElement;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcNavBar';

        if (this.obj.dataset.activeid !== '') {
            this.onNavItemClicked(this.obj.querySelector(`#${this.obj.dataset.activeid}`) as HTMLElement);
        }

        this.obj.querySelectorAll('.nav-link').forEach(
            navitem => {
                // "inaktive" Targets unsichtbar setzen
                if ((navitem as HTMLElement) != this.selectedItem) {
                    let target = document.getElementById((navitem as HTMLElement).dataset.target);
                    target.classList.add('d-none');
                }

                navitem.addEventListener('click', event => {
                    this.onNavItemClicked(event.target as HTMLElement);
                });
            }
        );
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        if (assigned(this.selectedItem)) {
            properties.push([this.id, 'ActiveID', this.selectedItem.id]);
        }
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'ActiveID':
                this.onNavItemClicked(this.obj.querySelector(`#${value}`) as HTMLElement);
                break;
        }
    }

    onNavItemClicked(item: HTMLElement) {
        if (item != this.selectedItem) {
            let target: HTMLElement;

            if (!this.selectedItem) {
                this.selectedItem = this.obj.children[this.obj.dataset.nav];
            }

            // altes item deaktivieren
            if (this.selectedItem) {
                this.selectedItem.classList.remove('active');
                target = document.getElementById(this.selectedItem.dataset.target);
                target.classList.remove('active');
                target.classList.add('d-none');
            }

            this.selectedItem = item;

            // neues item aktivieren
            item.classList.add('active');
            target = document.getElementById(item.dataset.target);
            target.classList.remove('d-none');
            target.classList.add('active');

            this.notifyComponentChanged();
        }
    }
}

export class TwcNavBarItem extends TWebComponent {
    targetID: string;
    targetComponent: IWebComponent;
    targetFocusFunction: () => void;
    navBar: TwcNavBar;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcNavBarItem';

        this.targetID = this.obj.dataset.target;

        this.navBar = findComponent(this.obj.closest(`[data-type="${TwcNavBar.name}"]`).id) as TwcNavBar;
        if (!assigned(this.navBar)) {
            throw `Tab item ${this.id} has no owner`;
        }

        // WARNUNG: Code Gore voraus 😐🔪
        // wir tauschen heimlich die setFocus Funktionen aus. Das ist notwendig, da wir im HTML für das bringToFront keinen COntainer um die Inhalte haben
        this.targetComponent = findComponent(this.targetID);
        this.targetFocusFunction = this.targetComponent.setFocus.bind(this.targetComponent);
        this.targetComponent.setFocus = this.setFocus.bind(this);
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                // NavItem un-/sichtbar
                this.obj.classList.toggle('d-none', value == '0');

                // Target un-/sichtbar, aber nur, wenn es aktiv ist
                let target = document.getElementById(this.targetID);
                if (target.classList.contains('active'))
                    target.classList.toggle('d-none', value == '0');
                break;
        }
    }

    override setFocus(): void {
        this.navBar.onNavItemClicked(this.obj); // Nav Element markieren
        this.targetFocusFunction(); // Und das "echte" setFocus der eigentlichen Komponente
    }
}

