import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { AnchorHandle, TEdgeBase } from '../Edges/TEdgeBase';
import { ActionType, TCustomEdgeAction } from './TCustomAction';
import { TEdgeDeleteAction } from './TEdgeDeleteAction';

export class TEdgeCreateAction extends TCustomEdgeAction {
    fromNode: string;
    toNode: string;
    fromAnchor: AnchorHandle;
    toAnchor: AnchorHandle;

    constructor(refElement: TEdgeBase, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.fromNode = '';
        this.toNode = '';
        this.fromAnchor = AnchorHandle.ahCenter;
        this.toAnchor = AnchorHandle.ahCenter;
    }

    typeID(): ActionType {
        return ActionType.patEdgeCreate;
    }

    override fromJSON(obj: any): void {
        super.fromJSON(obj);

        this.refElement = new TEdgeBase;
        this.refElement.id = obj.refElementID;

        // wir brauchen initial die nodes zum erstellen
        let ids = obj.refElementID.split('#');
        this.refElement.fromNode = ids[0];
        this.refElement.toNode = ids[1];

        this.svgEditor.registerObj(this.refElement);

        this.fromNode = obj.fromNode;
        this.toNode = obj.toNode;
        this.fromAnchor = obj.fromAnchor;
        this.toAnchor = obj.toAnchor;
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.refElementID = this.refElement.id;

        obj.fromNode = this.fromNode;
        obj.toNode = this.toNode;
        obj.fromAnchor = this.fromAnchor;
        obj.toAnchor = this.toAnchor;

        return obj;
    }

    setValue(fromNode: string, fromAnchor: AnchorHandle, toNode: string, toAnchor: AnchorHandle): void {
        if (!assigned(this.refElement)) {
            this.refElement = new TEdgeBase;
            this.refElement.id = fromNode + '#' + toNode;

            this.svgEditor.registerObj(this.refElement);
        }
        this.fromNode = fromNode;
        this.fromAnchor = fromAnchor;
        this.toNode = toNode;
        this.toAnchor = toAnchor;
    }

    performAction(): void {
        this.refElement.editor = this.svgEditor;
        this.refElement.fromNode = this.fromNode;
        this.refElement.fromAnchor = this.fromAnchor;
        this.refElement.toNode = this.toNode;
        this.refElement.toAnchor = this.toAnchor;
        this.refElement.create();
        this.svgEditor.registerObj(this.refElement);
    }

    inverseAction(): TCustomEdgeAction {
        let result = new TEdgeDeleteAction(this.refElement, this.svgEditor);
        result.setValue(this.fromNode, this.fromAnchor, this.toNode, this.toAnchor);
        this.svgEditor.unregisterObj(result.refElement);

        return result;
    }
}