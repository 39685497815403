// Typeahead ist so nen Sonderfall wie man es lädt, siehe:
// https://github.com/twitter/typeahead.js/issues/1211
// https://github.com/twitter/typeahead.js/issues/1627
import $ from 'jquery';
import { WebCompEventHandler } from '../../../core/communication';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

require('typeahead.js/dist/typeahead.jquery.min.js');
const Bloodhound = require('typeahead.js/dist/bloodhound.min.js');

class SuggestionObj {
    text: number;

    constructor(suggestion: number) {
        this.text = suggestion;
    }
}

export class TwcSuggestionsText extends TWebComponent {
    text: string;
    suggestions: string;
    input: HTMLElement;
    hasServerChangeEvent: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcSuggestionsText';
        this.text = '';
        this.suggestions = '';
        this.input = document.getElementById(this.id + '-suggestionstext');

        this.hasServerChangeEvent = boolFromStr(this.obj.dataset?.hasServerChangeEvent);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initSuggestionsText();
    }

    initSuggestionsText(): void {
        $('#' + this.id).suggestionsText();

        $('#' + this.id).on('typeahead:change', () => this.handleChangeEvent());
    }

    handleChangeEvent(): void {
        this.notifyComponentChanged();

        if (this.hasServerChangeEvent) {
            WebCompEventHandler('OnChange', this.id);
        }
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'Text', (this.input as HTMLInputElement).value]);
        return properties;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                if (value == '1') {
                    $('#' + this.id + '-input-group').removeClass('d-none');
                } else if (value == '0') {
                    $('#' + this.id + '-input-group').addClass('d-none');
                }
                break;
            case 'State':
                if (value == '0') {
                    //Disabled
                    $('#' + this.id + '-suggestionstext').prop('disabled', true);
                    $('#' + this.id + '-input-group').addClass('suggestions-disabled');
                } else if (value == '1') {
                    //ReadOnly
                    $('#' + this.id + '-suggestionstext').prop('disabled', true);
                    $('#' + this.id + '-input-group').addClass('suggestions-disabled');
                } else if (value == '2') {
                    //Enabled
                    $('#' + this.id + '-suggestionstext').prop('disabled', false);
                    $('#' + this.id + '-input-group').removeClass('suggestions-disabled');
                }
        }
    }
}

/***********************************************/
$.fn.extend({
    suggestionsText: function () {
        let id = this.data('id');

        let dataSuggestions = this.data('suggestions');
        let arrData = dataSuggestions.split("\n");

        let local = [];
        // -1 da das letzte leer gesplittet wird
        for (let i = 0; i < arrData.length - 1; i++) {
            local.push(new SuggestionObj(arrData[i]));
        }

        let input = $('#' + id + '-suggestionstext');

        const suggestions = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            identify: function (obj: SuggestionObj): number {
                return obj.text;
            },
            local: local,
        })


        const suggestionsWithDefaults = (query: string, sync: (results: any[]) => void): void => {
            sync(suggestions.all());
        }

        const options = {
            highlight: true,
            minLength: 0,
            hint: false,
        }

        const settings = {
            limit: local.length,
            display: 'text',
            source: suggestionsWithDefaults
        }

        input.typeahead(options, settings)
    }
})
