/*------------------------------------------*/

// var readyCalls = [];
// var readyCounter = 0;

// function registerReady(fn) {
//     readyCounter++;

//     if (assigned(fn))
//         readyCalls.unshift(fn);

//     console.debug(`Registered Ready. Counter: ${readyCounter}`);
// }

// function handleReady(event) {
//     console.debug(event?.target);
//     readyCounter--;
//     console.debug(`Handle Ready. Counter: ${readyCounter}`);

//     if (readyCounter === 0) {
//         for (let i = readyCalls.length - 1; i > 0; i--) {
//             readyCalls.pop()(); // pop() returned die Funktion die mit () danach direkt gecallt wird.
//         }
//     }
// }

// function onReadyNext(fn) {
//     console.debug(`OnReady (${document.readyState}) ${readyCounter}: ${fn}`);
//     if (document.readyState == 'complete') {
//         if (readyCounter === 0) {
//             fn();
//         }
//         else {
//             readyCalls.unshift(fn);
//         }
//     } else {
//         document.addEventListener('DOMContentLoaded', (event) => {
//             registerReady(fn);
//             handleReady();
//         });
//     }
// }

// export function executeScriptsInElement(element: Element) {
//     let iterateChildren = script => {
//         if (script.tagName === 'SCRIPT') {
//             let newScript = document.createElement('script')
//             script.parentNode.replaceChild(newScript, script);

//             // Load Events bei externen Skripten
//             if (script.src) {
//                 registerReady();
//                 newScript.onload = handleReady;
//             }

//             newScript.text = script.innerHTML;

//             for (let i = 0; i < script.attributes.length; i++) {
//                 let attr = script.attributes[i]
//                 newScript.setAttribute(attr.name, attr.value);
//             }
//         }
//         else {
//             let children = script.childNodes;
//             children.forEach(newChild => iterateChildren(newChild));
//         }
//     }

//     if (!assigned(element)) {
//         console.warn(`Element does not exist [${id}].`);
//         return;
//     }

//     let children = element.childNodes;
//     children.forEach(newChild => iterateChildren(newChild));
// }

// export function executeScriptsInElementById(id) {
//     let element = document.getElementById(id);
//     executeScriptsInElement(element);
// }


// Replace: Wir tauschen das Container-Element (mit der ID) komplett aus
// ACHTUNG: DIE NEXT FUNKTIONEN NOCH NICHT VERWENDEN
// Hier gibt es noch Probleme, wenn aufeinander aufbauende Scripte darüber eingefügt werden
// Das warum ist mir noch nicht klar, aktuell dürfte das auch nur beim prozesseditor der Fall sein
// Solange die alten Funktionen mit jQuery nutzen, die schedulen das intern anders irgendwie...
// function replaceHtmlNext(id, html) {
//     let element = document.getElementById(id);
//     element.outerHTML = html;

//     executeScriptsInElement(element);
// }

// function prependHtmlNext(id, html) {
//     let element = document.getElementById(id);
//     element.insertAdjacentHTML('afterbegin', html);

//     executeScriptsInElement(element);
// }

// function appendHtmlNext(id, html) {
//     let element = document.getElementById(id);
//     element.insertAdjacentHTML('beforeend', html);

//     executeScriptsInElement(element);
// }

// // setHTML: Wir tauschen nur den Content aus
// function setHtmlNext(id, html) {
//     let element = document.getElementById(id);
//     element.innerHTML = html;

//     executeScriptsInElement(element);
// }

export function replaceHtml(id: string, html: string) {
    // setTimeout 0ms, da sonst die events verloren gehen, falls replaceHtml mehrfach hintereinander aufgerufen wird 
    window.setTimeout(() => {
        $('#' + id).replaceWith(html);
    }, 0);
}

export function addHtml(id: string, html: string) {
    $('#' + id).append(html);
}

// setHTML: Wir tauschen nur den Content aus
export function setHtml(id: string, html: string) {
    $('#' + id).html('');
    $('#' + id).html(html);
}

export function addMultipleEventListeners(element: Element, eventNames: string, listener: (event: Event) => void): void {
    let eventsArr = eventNames?.trim()?.split(' ') ?? [];
    eventsArr.forEach(event => {
        element.addEventListener(event, listener);
    })
}

/**
 * Importiert rekursiv die Element aus source in target
 * @param target 
 * @param source 
 * @remarks Es werden dabei keine bestehenden Objekte überschrieben  <- stimmt das wirklich?
 * @returns true, falls das target verändert wurde
 */
export function importConfiguration(target: Object, source: Object): boolean {
    let result = false;

    for (const [key, value] of Object.entries(source)) {
        if (typeof (target[key]) === 'object') {
            result = result || importConfiguration(target[key], value);
        } else {
            if (target[key] != value) {
                result = true;
            }

            target[key] = value;
        }
    }

    return result
}

export function toJSLocale(consense_loc: string) {
    let js_loc = consense_loc;
    // in Delphi haben wir eine Codierung von z.B. 'NL_BE'
    // JavaScript erwartet allerdings 'nl-BE'
    if (consense_loc && consense_loc.includes('_')) {
        let parts = consense_loc.split('_');
        js_loc = parts[0].toLowerCase() + '-' + parts[1];
    }
    return js_loc;
}

export function isInViewport(element: Element): boolean {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.width > 0 &&
        rect.height > 0
    );
}