import isEmpty from 'lodash.isempty';
import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { getIDSuffix, SVGNS } from '../Utils/TProcessEditorIDUtils';
import { BPMNBorderStyles, TBaseShapeBPMN } from './TBaseShapeBPMN ';

export class TShapeBPMNActivity extends TBaseShapeBPMN {
    doubleBorder: SVGRectElement;
    iconBPMNMarkers: Array<SVGImageElement>;

    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 965;
        this.roundCorners = true;
        this.doubleBorder = null;
        this.iconBPMNMarkers = [null, null, null, null, null, null];
    }

    getSvgElementType(): string {
        return 'rect';
    }

    override create() {
        super.create();
        // sonderfall für dieses BPMN Element..
        this.svgElement.setAttribute('rx', '10');
        this.svgElement.setAttribute('ry', '10');
    }

    override repaint() {
        super.repaint();
        // sonderfall für dieses BPMN Element..
        this.svgElement.setAttribute('rx', '10');
        this.svgElement.setAttribute('ry', '10');
    }

    iconByCode(code: string): string {
        switch (code.trim()) {
            case 'A':
                return 'bpmn-activity-type-none';
            case 'B':
                return 'bpmn-activity-type-send';
            case 'C':
                return 'bpmn-activity-type-receive';
            case 'D':
                return 'bpmn-activity-type-user';
            case 'E':
                return 'bpmn-activity-type-manual';
            case 'F':
                return 'bpmn-activity-type-businessrule';
            case 'G':
                return 'bpmn-activity-type-service';
            case 'H':
                return 'bpmn-activity-type-script';
            default:
                return '';
        }
    }

    markerByCode(code: number): string {
        switch (code) {
            case 0:
                return 'bpmn-activity-marker-none';
            case 1:
                return 'bpmn-activity-marker-subprocess';
            case 2:
                return 'bpmn-activity-marker-loop';
            case 3:
                return 'bpmn-activity-marker-parallel';
            case 4:
                return 'bpmn-activity-marker-sequential';
            case 5:
                return 'bpmn-activity-marker-adhoc';
            case 6:
                return 'bpmn-activity-marker-compensation';
            default:
                return '';
        }
    }

    borderStyleByCode(code: string): BPMNBorderStyles {
        switch (code.trim()) {
            case 'A':
                return BPMNBorderStyles.lmNormal;
            case 'B':
                return BPMNBorderStyles.lmDouble;
            case 'C':
                return BPMNBorderStyles.lmDash;
            case 'D':
                return BPMNBorderStyles.lmBold;
            default:
                return undefined;
        }
    }

    setAttributesBPMNActivity(index: number, iconType: string, x: number, y: number, w: number, h: number) {
        if (!isEmpty(this.itemData)) {
            if (!assigned(this.iconBPMNMarkers[index])) {
                this.iconBPMNMarkers[index] = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconBPMNMarkers[index], this.captionElement.svgElement);
            }

            this.iconBPMNMarkers[index].setAttribute('id', getIDSuffix(this.id, iconType));
            this.iconBPMNMarkers[index].setAttribute('href', '/assets/images/shapes/bpmn/' + iconType + '.svg');
            this.iconBPMNMarkers[index].setAttribute('x', String(x));
            this.iconBPMNMarkers[index].setAttribute('y', String(y));
            this.iconBPMNMarkers[index].setAttribute('width', String(w));
            this.iconBPMNMarkers[index].setAttribute('height', String(h));
            this.iconBPMNMarkers[index].setAttribute('pointer-events', 'none');
        }
        else {
            if (assigned(this.iconBPMNMarkers[index])) {
                this.editor.editorSVG.removeChild(this.iconBPMNMarkers[index]);
                this.iconBPMNMarkers[index] = null;
            }
        }
    }

    repaintIconBPMN() {
        const MARKERS = 7;

        if (isEmpty(this.itemData)) {
            return;
        }

        // Der Typ oben links
        if (!isEmpty(this.iconByCode(this.itemData.substr(2, 2)))) {
            this.setAttributesBPMN(this.iconByCode(this.itemData.substr(2, 2)),
                this.boundingRect.left, this.boundingRect.top, this.boundingRect.width / 4,
                this.boundingRect.height / 4);
        }

        // Die Marker unten in der Mitte
        let markerCount = 0;

        // Höhe ist 25% der Shapehöhe
        let markerHeight = this.boundingRect.height / 4;
        let aIconRectTop = this.boundingRect.top + 3 * markerHeight;

        let s = this.itemData.substr(4, MARKERS);

        if (s.length === MARKERS) {
            for (let i = 0; i < MARKERS - 1; i++) {
                if (s[i + 1] === 'X') {
                    markerCount++;
                }
            }
        }

        // maximale Breite der Shapes
        if (markerCount > 0) {
            let iconSize = this.boundingRect.width / markerCount;

            if (markerHeight < iconSize) {
                iconSize = markerHeight;
            }

            // Startpunkt um die Shapes zu zentrieren
            let aIconRectLeft = this.boundingRect.left + (this.boundingRect.width - markerCount * iconSize) / 2;

            // Wenn "Kein Marker" markiert ist, ignorieren wir alles andere gesetzte
            if (s[0] === 'X') {
                this.iconBPMNMarkers.forEach((marker, index) => {
                    if (assigned(marker)) {
                        this.editor.editorSVG.removeChild(marker);
                        this.iconBPMNMarkers[index] = null;
                    }
                });
            }
            else {
                // Die Positionen der Shapes
                for (let i = 0; i < MARKERS - 1; i++) {
                    if (s[i + 1] === 'X') {
                        this.setAttributesBPMNActivity(i, this.markerByCode(i + 1),
                            aIconRectLeft, aIconRectTop, iconSize, iconSize);
                        aIconRectLeft += iconSize;
                    }
                    else {
                        if (assigned(this.iconBPMNMarkers[i])) {
                            this.editor.editorSVG.removeChild(this.iconBPMNMarkers[i]);
                            this.iconBPMNMarkers[i] = null;
                        }
                    }
                }
            }
        }

        // borderStyle
        const innerRectDistance = 3;

        switch (this.borderStyleByCode(this.itemData.substr(0, 2))) {
            case BPMNBorderStyles.lmNormal:
                if (assigned(this.doubleBorder)) {
                    this.editor.editorSVG.removeChild(this.doubleBorder);
                    this.doubleBorder = null;
                }
                this.svgElement.removeAttribute('stroke-dasharray');
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);
                break;
            case BPMNBorderStyles.lmDouble:
                if (!assigned(this.doubleBorder)) {
                    this.doubleBorder = document.createElementNS(SVGNS, 'rect');
                    this.editor.editorSVG.insertBefore(this.doubleBorder, this.iconBPMN);
                }
                this.svgElement.removeAttribute('stroke-dasharray');
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);

                this.doubleBorder.setAttribute('id', getIDSuffix(this.id, 'dblBorder'));
                this.doubleBorder.setAttribute('x', String(this.boundingRect.left + innerRectDistance));
                this.doubleBorder.setAttribute('y', String(this.boundingRect.top + innerRectDistance));
                this.doubleBorder.setAttribute('width', String(this.boundingRect.width - 2 * innerRectDistance));
                this.doubleBorder.setAttribute('height', String(this.boundingRect.height - 2 * innerRectDistance));
                this.doubleBorder.setAttribute('rx', '7');
                this.doubleBorder.setAttribute('ry', '7');
                this.doubleBorder.setAttribute('stroke', this.strokeColor.hex());
                this.doubleBorder.setAttribute('fill', 'none');
                break;
            case BPMNBorderStyles.lmBold:
                if (assigned(this.doubleBorder)) {
                    this.editor.editorSVG.removeChild(this.doubleBorder);
                    this.doubleBorder = null;
                }
                this.svgElement.removeAttribute('stroke-dasharray');

                this.svgElement.setAttribute('stroke-width', '3');
                break;
            case BPMNBorderStyles.lmDash:
                if (assigned(this.doubleBorder)) {
                    this.editor.editorSVG.removeChild(this.doubleBorder);
                    this.doubleBorder = null;
                }
                this.svgElement.setAttribute('stroke-width', this.strokeWidth);

                this.svgElement.setAttribute('stroke-dasharray', '20,10');
                break;
            default:
                // nothing
                break;
        }
    }

    override delete() {
        super.delete();

        // Marker
        this.iconBPMNMarkers.forEach(marker => {
            if (assigned(marker)) {
                this.editor.editorSVG.removeChild(marker);
                this.iconBPMNMarkers[this.iconBPMNMarkers.indexOf(marker)] = null;
            }
        });

        // falls ein doppelter rand vorhanden ist
        if (assigned(this.doubleBorder)) {
            this.editor.editorSVG.removeChild(this.doubleBorder);
            this.doubleBorder = null;
        }
    }
}