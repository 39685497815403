﻿import Color from 'color';
import $ from 'jquery';
import { WebCompEventHandlerAsync } from '../../../core/communication';
import { assigned } from '../../../utils/helper';
import { AsType } from '../../../utils/html';
import { b64DecodeUnicode, boolFromStr, csJSONParse } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';


require('jquery.fancytree/dist/modules/jquery.fancytree');
require('jquery.fancytree/dist/modules/jquery.fancytree.glyph');
require('jquery.fancytree/dist/modules/jquery.fancytree.table');

export class TwsReport extends TWebComponent {
    Source: string;
    ColorList: any;
    IsInitialized: boolean;
    tableElement: HTMLTableElement;
    noEntryDiv: HTMLElement;
    tooManyEntryDiv: HTMLElement;
    HasOnRightClick: Boolean;
    tooManyEntries: any;

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwsReport';
        this.Source = '';
        this.ColorList = '';
        this.IsInitialized = false;
        this.tooManyEntries = false;

        this.tableElement = AsType<HTMLTableElement>(document.querySelector(this.getTreeSelector()), false);
        this.noEntryDiv = document.getElementById(`${this.id}-noEntryDiv`);
        this.tooManyEntryDiv = document.getElementById(`${this.id}-tooManyEntryDiv`);
        this.HasOnRightClick = boolFromStr(this.obj.dataset.hasonrightclick);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.tableElement.dataset.reportComponentId = this.id;
        this.obj.addEventListener('contextmenu', event => this.internalOnRightClick(event));
    }

    internalOnRightClick(event: MouseEvent) {
        if (this.HasOnRightClick) {
            event.preventDefault();
            var node = $.ui.fancytree.getNode(event.target as Element);
            node.setActive(true);
            WebCompEventHandlerAsync('OnRightClick', this.id);
        }
    }

    setSourceB64(dataB64: string) {
        this.Source = String.raw`${b64DecodeUnicode(dataB64)}`;
    }

    getSource(): string | any {
        if (this.Source != '') {
            try {
                return csJSONParse(this.Source);
            } catch (err) {
                console.error('Fehler beim Laden des Baumes');
                console.error(err);
                return '';
            }
        }
    }

    updateNoEntryDiv(): void {
        this.noEntryDiv.classList.toggle('d-none', this.tableElement.tHead.rows.length > 0);
    }

    updateTooManyEntryDiv(): void {
        this.tooManyEntryDiv.classList.toggle('d-none', !this.tooManyEntries);
    }

    updateColorList(): void {
        if (this.ColorList != '') {
            try {
                // UPS // Ist das jetzt ein Objekt oder ein String? -> Folgestory?
                this.ColorList = csJSONParse(this.ColorList);
            } catch (err) {
                console.error('Fehler beim Laden der Zellenfarben');
                console.error(err);
            }
        }
    }

    updateTree({ header, json, colors, tooManyEntries }: { header: any; json: any; colors: any; tooManyEntries: any }) {
        this.Source = json;
        this.ColorList = colors;
        this.tooManyEntries = tooManyEntries;

        if (this.IsInitialized) {
            $(this.getTreeSelector()).fancytree('destroy');
            this.IsInitialized = false;
        }

        $('#' + this.id + '-table table>thead>tr').empty();
        $('#' + this.id + '-table table>thead>tr').html(header);

        this.initTree();
    }

    writeProperties(key: string, value: string): void {
        //
    }

    override execAction(action: string, params: string) {
        switch (action) {
            case 'Action.ReloadReportTree':
                try {
                    let params_split = csJSONParse(params);
                    this.updateTree({ header: params_split.header, json: params_split.json, colors: params_split.colors, tooManyEntries: params_split.tooManyEntries });
                } catch (e) {
                    console.log(e);
                }
                break;
        }
    }

    getTreeSelector(): string {
        return `#${this.id}-table table`;
    }

    initTree(): void {
        // wir dürfen uns nur einmal initialisieren
        if (this.IsInitialized) {
            return;
        }

        this.updateColorList();
        this.updateNoEntryDiv();
        this.updateTooManyEntryDiv();
        this.IsInitialized = true;
        let tree_obj = this;
        let selector = this.getTreeSelector();

        // Wir bauen ein Mapping von ColumnIndex im HTML zur col ID in den Daten
        let colIdMapping = new Map<number, number>();
        let headerRow = this.tableElement.tHead.rows[0];
        for (let i = 0; i < headerRow.cells.length; i++) {
            colIdMapping.set(i, parseInt(headerRow.cells[i].dataset?.colid) ?? -1)
        };

        $(selector).fancytree({
            source: tree_obj.getSource(),
            init: tree_obj.afterRender,
            glyph: {
                preset: 'awesome5',
                map: {
                    expanderClosed: 'fa-solid fa-chevron-right',
                    expanderLazy: 'fa-solid fa-chevron-right',
                    expanderOpen: 'fa-solid fa-chevron-down',
                    nodata: '',
                }
            },
            strings: {
                loading: 'Laden...', // &#8230; would be escaped when escapeTitles is true
                loadError: 'Ladefehler!',
                moreData: 'Weitere Daten laden...',
                noData: '',
            },
            extensions: ['table', 'glyph'],
            dblclick: tree_obj.onDblClick,
            table: {
                checkboxColumnIdx: null, // default
                indentation: 16, // default
                nodeColumnIdx: 0
            },
            renderColumns: (event, data) => {
                function cellaligmentMapping(cellaligment): string | undefined {
                    switch (cellaligment) {
                        case 'R':
                            return 'text-end';
                        case 'C':
                            return 'text-center';
                        case 'L':
                        default: // L ist auch default
                            return undefined;
                    }
                }

                let node = data.node;
                let cells = node.tr.cells;

                // bei Gruppen löschen wir Zellen im DOM, damit dort der ColSpan stimmt müssne wir uns also die echte Anzahl Zellen merken
                let originalCellLength = cells.length;

                for (let i = cells.length - 1; i >= 0; i--) {
                    let colId = colIdMapping.get(i);
                    let colData = node.data.row[colId];

                    if (!node.data.isGroup) {
                        if (i === 0) {
                            // in der ersten Spalte sind noch expander und weitere HtmlTags, deswegen suchen wir die richtige Stelle.
                            let titleSpan = cells[i].querySelector('.fancytree-title');
                            titleSpan.textContent = colData.text;
                            // Hover kommt via Title, da wollen wir ggf. was anderes
                            if (assigned(colData.title)) {
                                titleSpan.setAttribute('title', colData.title);
                            } else {
                                titleSpan.setAttribute('title', colData.text);
                            }
                        } else {
                            // Hover kommt via Title, da wollen wir ggf. was anderes
                            if (assigned(colData.title)) {
                                cells[i].setAttribute('title', colData.title);
                            } else {
                                cells[i].setAttribute('title', colData.text);
                            }

                            // da wir hier ggf. HTML und nicht escapten Text darstellen wollen, müssen wir Nodes erstellen
                            let newNode: HTMLElement;

                            // gibt es ein Icon?
                            if (assigned(colData.ci) && String(colData.ci).trim() !== '') {
                                newNode = document.createElement('i');
                                newNode.classList.add(...String(colData.ci).split(' '));
                                newNode.classList.add('pe-1');
                                cells[i].appendChild(newNode);
                            }


                            if (assigned(colData.html) && String(colData.html).trim() !== '') {
                                cells[i].innerHTML = colData.html;
                            } else {
                                newNode = document.createElement('span');
                                // textContent schützt vor XSS
                                newNode.textContent = colData.text;
                                cells[i].appendChild(newNode);
                            }
                        }

                        // Alignment
                        if (assigned(colData.ca)) {
                            let alignmentClass = cellaligmentMapping(colData.ca);
                            if (assigned(alignmentClass)) {
                                cells[i].classList.add(alignmentClass);
                            }
                        }

                    } else {
                        if (i === 0) {
                            cells[i].textContent = colData.text;
                            cells[i].colSpan = originalCellLength;
                            cells[i].classList.add('report-group', 'fw-bold', 'bg-secondary', 'text-black', 'ps-3');
                        }
                        else {
                            cells[i].parentNode.removeChild(cells[i]);
                        }
                    }

                    // Muss die Spalte mehrzeilige Daten darstellen können?
                    if (assigned(colData.multiline) && assigned(cells[i])) {
                        cells[i].classList.add('multiline');
                    }

                    // wir müssen die Zelle hier auf assigned prüfen, da die ggf. schon undefined ist wenn wir sie removen
                    if (assigned(colData.col) && assigned(cells[i])) {
                        let colour = tree_obj.ColorList[colData.col]
                        cells[i].style.backgroundColor = colour;
                        if (new Color(colour).isDark()) {
                            cells[i].style.color = '#FFFFFF';
                        } else {
                            cells[i].style.color = '#000000';
                        }
                    }
                }
            }
        });

        tree_obj.setTreeOptions(selector);
    }

    setTreeOptions(selector: string): void {
        $(selector).fancytree('option', 'activeVisible', false);
    }

    afterRender(event, data) {
        // this ist hier der Baum, nicht das Component Object!
        let reportComponent = findComponent(event.target.dataset.reportComponentId) as TwsReport;

        if (!assigned(reportComponent)) {
            return;
        }

        // erstmal nur an erster Stelle
        let fullWidth = 0;
        let sorterdir = '', sorterIconWeightclass = '', sorterIconclass = '';

        let header = reportComponent.tableElement.tHead.rows[0];
        for (let i = 0; i < header.cells.length; i++) {
            let cell = header.cells[i] as HTMLTableCellElement;
            let colId = parseInt(cell.dataset.colid);

            cell.classList.add('cs-report-th-sorter');
            // wir werden etwas größer
            if (i !== 0) {
                let celltBoundingClientRect = cell.getBoundingClientRect();
                if (celltBoundingClientRect.width != 0) {
                    cell.style.width = celltBoundingClientRect.width + 15 + 'px';
                    fullWidth += celltBoundingClientRect.width + 15;
                } else {
                    cell.style.width = parseFloat(cell.style.width) + 15 + 'px';
                    fullWidth += parseFloat(cell.style.width) + 15;
                }
            } else {
                fullWidth += parseFloat(cell.style.minWidth) + 15;
            }

            // sortspan erzeugen
            let span = document.createElement('span');
            span.classList.add('cs-report-sort');
            // wir gehen davon aus, dass der erste immer sortiert ist
            if (i === 0) {
                sorterdir = 'asc';
                cell.classList.add('sorted');
                span.classList.add('fa-solid', 'fa-sort-up');
            } else {
                sorterdir = 'desc';
            }
            span.dataset.colid = String(colId);
            span.dataset.sortdirection = sorterdir;

            // und einfügen 
            cell.append(span);
            cell.addEventListener('click', event => reportComponent.sorter(event));
        }
        if (window.innerWidth < fullWidth) {
            reportComponent.tableElement.style.width = fullWidth + 'px';
        }
    }

    sorter(event: Event): void {
        // geklickten merken
        if (event.target instanceof HTMLElement) {
            // unser gesuchtes Element soll hier die th sein. Das checken wir mal 
            let target = event.target;
            if (target.nodeName == 'SPAN') {
                target = event.target.parentElement;
            }
            let sorterElement = target.querySelector('.cs-report-sort') as HTMLElement;

            if (assigned(sorterElement)) {
                let sortdirection = sorterElement.dataset.sortdirection;
                let colid = sorterElement.dataset.colid;
                // alle resetten
                this.obj.querySelectorAll('.cs-report-sort').forEach(element => {
                    element.classList.remove('fa-solid', 'fa-sort-up', 'fa-sort-down');
                    if (element instanceof HTMLElement) {
                        element.dataset.sortdirection = 'desc';
                    }
                });
                this.obj.querySelector('.cs-report-th-sorter.sorted').classList.remove('sorted');
                // geklickten anpassen und sortieren
                target.classList.add('sorted');
                if (sortdirection == 'desc') {
                    sorterElement.dataset.sortdirection = 'asc';
                    sorterElement.classList.remove('fa-sort-down');
                    sorterElement.classList.add('fa-sort-up', 'fa-solid');
                    this.sortTreeUp(colid);
                } else if (sortdirection == 'asc') {
                    sorterElement.dataset.sortdirection = 'desc';
                    sorterElement.classList.remove('fa-sort-up');
                    sorterElement.classList.add('fa-sort-down', 'fa-solid');
                    this.sortTreeDown(colid);
                }
            } else {
                throw `SorterElement not found`;
            }
        } else {
            throw `Event target is not of type HTMLElement.`;
        }
    }

    sortTreeUp(col_index) {
        let node = $(`#${this.id} table`).fancytree('getRootNode');
        let cmp = function (a, b) {
            let x = a.data.row[col_index].sort.toLowerCase(),
                y = b.data.row[col_index].sort.toLowerCase();
            return x === y ? 0 : x > y ? 1 : -1;
        };
        node.sortChildren(cmp, true);
    }

    sortTreeDown(col_index) {
        let node = $(`#${this.id} table`).fancytree('getRootNode');
        let cmp = function (a, b) {
            let x = b.data.row[col_index].sort.toLowerCase(),
                y = a.data.row[col_index].sort.toLowerCase();
            return x === y ? 0 : x > y ? 1 : -1;
        };
        node.sortChildren(cmp, true);
    }

    onDblClick(event: JQueryEventObject, data: Fancytree.EventData): boolean {
        WebCompEventHandlerAsync('OnDblClick', $(this).data('reportComponentId'));
        return true;
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        if (this.IsInitialized) {
            let tree = $(this.getTreeSelector()).fancytree('getTree');
            let node = tree.getActiveNode();
            let selectedNode = '';
            // wir haben noch keine node
            if (assigned(node)) {
                selectedNode = node.key;
            }
            properties.push([this.id, 'SelectedNode', selectedNode]);
        }
        return properties;
    }
}