import isEmpty from 'lodash.isempty';
import { assigned } from '../../../../utils/helper';
import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { getIDSuffix, SVGNS } from '../Utils/TProcessEditorIDUtils';
import { TBaseShape } from './TBaseShape';

export enum BPMNBorderStyles {
    lmNormal, lmDouble, lmBold, lmDash, lmDoubleDash
}

export abstract class TBaseShapeBPMN extends TBaseShape {
    iconBPMN: SVGImageElement;
    itemData: string;

    constructor(editor: TwsProcessEditorCustom) {
        super(editor);

        this.iconBPMN = null;
        this.itemData = undefined;
    }

    abstract repaintIconBPMN(): void;

    setAttributesBPMN(iconType: string, x: number, y: number, w: number, h: number) {
        if (!isEmpty(this.itemData)) {
            if (!assigned(this.iconBPMN)) {
                this.iconBPMN = document.createElementNS(SVGNS, 'image');
                this.editor.editorSVG.insertBefore(this.iconBPMN, this.captionElement.svgElement);
            }

            this.iconBPMN.setAttribute('id', getIDSuffix(this.id, iconType));
            this.iconBPMN.setAttribute('href', '/assets/images/shapes/bpmn/' + iconType + '.svg');
            this.iconBPMN.setAttribute('x', String(x));
            this.iconBPMN.setAttribute('y', String(y));
            this.iconBPMN.setAttribute('width', String(w));
            this.iconBPMN.setAttribute('height', String(h));
            this.iconBPMN.setAttribute('pointer-events', 'none');
        }
        else {
            if (assigned(this.iconBPMN)) {
                this.editor.editorSVG.removeChild(this.iconBPMN);
                this.iconBPMN = null;
            }
        }
    }

    override delete() {
        super.delete();

        if (assigned(this.iconBPMN)) {
            this.editor.editorSVG.removeChild(this.iconBPMN);
            this.iconBPMN = null;
        }
    }

    protected override repaintDynamic(dx: number, dy: number, dw: number, dh: number) {
        super.repaintDynamic(dx, dy, dw, dh);

        this.repaintIconBPMN();
    }
}