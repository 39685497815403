import $ from 'jquery';
import select2 from 'select2/dist/js/select2.full';
import { sendComponentRequestGetJson, WebCompEventHandler } from '../../../core/communication';
import { boolFromStr } from '../../../utils/strings';
import { TWebComponent } from '../../base/class.web.comps';
import { findComponent } from '../../base/controlling';

//  Hook up select2 to jQuery
select2($);

export class TwsTagEditor extends TWebComponent {
    hasChangeEvent: boolean;

    override initComponent(): void {
        super.initComponent();
        this.classtype = 'TwsTagEditor';

        this.hasChangeEvent = boolFromStr(this.obj.dataset.hasChangeEvent);
    }

    override initDomElement(): void {
        super.initDomElement();

        this.initTageditor();
    }

    initTageditor(): void {
        $('#' + this.id).tageditor();
    }

    override supportsTransferDirty(): boolean {
        return true;
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                if (value == '1') {
                    $('#' + this.id + '-input-group').removeClass('d-none');
                } else if (value == '0') {
                    $('#' + this.id + '-input-group').addClass('d-none');
                }
                break;
            case 'State':
                if (value == '0') {
                    //Disabled
                    $('#' + this.id + '-tageditor').prop('disabled', true);
                } else if (value == '1') {
                    //ReadOnly
                    $('#' + this.id + '-tageditor').prop('disabled', true);
                } else if (value == '2') {
                    //Enabled
                    $('#' + this.id + '-tageditor').prop('disabled', false);
                }
        }
    }
}

/***********************************************/
$.fn.extend({
    tageditor: function () {
        let id = this.data('id');

        let tagEditor = $('#' + id + '-tageditor');

        let placeholder = this.data('placeholder');

        // vorhandene Schlagworte einladen
        let tags = sendComponentRequestGetJson(id, 'getTags') as any;
        let colors = {};
        let fonts = {};

        for (let i = 0; i < tags.length; i++) {
            let tag = tags[i];
            let newOption;
            if (tag.selected == 'true') {
                newOption = new Option(tag.displayname, tag.id, false, true);
            } else {
                newOption = new Option(tag.displayname, tag.id, false, false);
            }
            colors[tag.id] = tag.color;
            fonts[tag.id] = tag.font;
            tagEditor.append(newOption);
        }

        // Editor als Select2 initialisieren
        // wegen jquery ändert sich das this in der nächsten inneren function; für das Template brauchen wir das aber also zwischenspeichern. Langfristig weniger jQuery nutzen = weniger solcher Probleme
        let oldThis = this;

        // Sonderlogik, damit backspace ganze tags löscht, siehe https://github.com/select2/select2/issues/3354#issuecomment-132389291

        $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
            var oldRemoveChoice = Search.prototype.searchRemoveChoice;

            Search.prototype.searchRemoveChoice = function () {
                oldRemoveChoice.apply(this, arguments);
                this.$search.val('');
            };

            tagEditor.select2({
                tags: false,
                // UPS // Parameter passen nicht -> in Klärung https://github.com/DefinitelyTyped/DefinitelyTyped/issues/48975
                templateSelection: function (data: Select2.IdTextPair, container: JQuery) {
                    // Background und Schriftfarbe der Selection anpassen
                    container.css({
                        'cssText': 'background-color: ' + colors[data.id] + ' !important; color: ' + fonts[data.id] + ' !important;'
                    });
                    return data.text;
                },
                // UPS // Laut Doku gibt es nur einen Parameter -> https://select2.org/dropdown#templating
                templateResult: function (data) {
                    // Wir stylen unsere Options ein bisschen
                    var result = $('<div class="cs-tageditor-option">' + data.text + '</div>');
                    result.css({
                        'cssText': 'background-color: ' + colors[data.id] + ' !important; color: ' + fonts[data.id] + ' !important;'
                    });
                    return result;
                },
                disabled: !oldThis.data('enabled') || oldThis.data('readonly'),
                placeholder: placeholder
            });
        });

        // Daten haben sich geändert
        tagEditor.trigger('change');

        function storeTags() {
            var selectedTags = tagEditor.select2('data');

            var tagIDs = [];

            for (var i = 0; i < selectedTags.length; i++) {
                tagIDs.push(selectedTags[i].id);
            }

            var requestIDs = {};
            requestIDs['tagIDs'] = tagIDs;
            sendComponentRequestGetJson(id, requestIDs);
        }

        function doSomethingChanged(): void {
            let comp = <TwsTagEditor>findComponent(id);
            comp.notifyComponentChanged();
            if (comp.hasChangeEvent) {
                WebCompEventHandler('OnChange', id);
            }
        }

        tagEditor.on({
            // neues Element
            'select2:select': function () {
                storeTags();
                doSomethingChanged();
            },
            //Element weg
            'select2:unselect': function () {
                storeTags();
                doSomethingChanged();
            },
            // Dropdown schließen
            'select2:closing': function () {
                storeTags();
            }
        });
    }
});