import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { TBaseShape } from './TBaseShape';

export class TShapeConditionLink extends TBaseShape {
    constructor(editor: TwsProcessEditorCustom) {
        super(editor);
        this.cid = 910;

        this.canInPlaceEdit = false;
    }

    getSvgElementType(): string {
        return 'path';
    }

    override getSVGPath() {
        let wc = this.boundingRect.height / 4;
        if (wc > this.boundingRect.width / 2) {
            wc = this.boundingRect.width / 4;
        }

        let path = [
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'v', this.boundingRect.height,
            'l', -wc, -this.boundingRect.height / 2,
            'Z',
            'M', this.boundingRect.left + wc, this.boundingRect.top,
            'h', this.boundingRect.width - 2 * wc,
            'v', this.boundingRect.height,
            'h', -(this.boundingRect.width - 2 * wc),
            'Z',
            'M', this.boundingRect.left + this.boundingRect.width - wc, this.boundingRect.top,
            'l', wc, this.boundingRect.height / 2,
            'l', -wc, this.boundingRect.height / 2,
            'Z'

        ].join(' ');

        return path;
    }
}