import Mousetrap, { ExtendedKeyboardEvent } from "mousetrap";
import { WebCompEventHandlerAsync } from "../../../core/communication";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";

export class TwcAction extends TWebComponent {
    enabled: boolean;
    visible: boolean;
    shortcut: string;
    mousetrap: Mousetrap.MousetrapInstance;
    parentId: string;
    hasExecute: boolean;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcAction';

        this.enabled = boolFromStr(this.obj.dataset.enabled, true);
        this.visible = boolFromStr(this.obj.dataset.visible, true);
        this.shortcut = this.obj.dataset?.shortcut ?? '';
        this.parentId = this.obj.dataset?.parent ?? '';
        this.hasExecute = boolFromStr(this.obj.dataset.hasexecute, false);

        let parentElement = document.documentElement;
        if (this.parentId !== '') {
            parentElement = document.getElementById(this.parentId) ?? parentElement;
        }

        this.mousetrap = new Mousetrap(parentElement);
    }

    override initDomElement() {
        super.initDomElement();

        this.rebindHotkey();
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Enabled':
                // Besonderheit: Action als sich ist nichts sichtbares sondern nur eine Datenkomponente, also keine Klassen togglen!
                this.enabled = boolFromStr(value);
                break;
            case 'Visible':
                // Besonderheit: Action als sich ist nichts sichtbares sondern nur eine Datenkomponente, also keine Klassen togglen!
                this.visible = boolFromStr(value);
                break;
            case 'Shortcut':
                if (value !== this.shortcut) {
                    this.shortcut = value;
                    this.rebindHotkey();
                }
                break;
            case 'HasExecute':
                this.hasExecute = boolFromStr(value);
                break;
        }
    }

    rebindHotkey() {
        this.mousetrap.reset();

        if (this.shortcut !== '') {
            this.mousetrap.bind(this.shortcut, (event: ExtendedKeyboardEvent, keys: string) => this.handleShortcutEvent(event, keys));
        }
    }

    handleShortcutEvent(event: ExtendedKeyboardEvent, keys: string) {
        console.debug(`Shortcut "${keys}" triggered [${this.id}].`);


        if (this.hasExecute && this.visible && this.enabled) {
            WebCompEventHandlerAsync('OnShortcutExecute', this.id, () => { });

            event.stopPropagation();
            event.preventDefault();
        }
    }

}