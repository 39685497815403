import { AsType } from '../../../utils/html';
import { SyncImageLoader } from '../../../utils/imageloader';
import { TWebComponent } from '../../base/class.web.comps';
import { ComponentProperty } from '../../interfaces/class.web.comps.intf';

export class TwcImage extends TWebComponent {
	imageObj: HTMLImageElement;

	override initComponent() {
		super.initComponent();
		
		this.classtype = 'TwcImage';
		this.imageObj = AsType<HTMLImageElement>(this.obj);
	}

	setSource(imageSrc: string): void {
        SyncImageLoader.instance.addSource(this.obj.id, imageSrc);
	}

	override readProperties(): Array<ComponentProperty> {
		var properties = [];
		return properties;
	}

	writeProperties(key: string, value: string): void {
		switch (key) {
			case 'Source':
				this.setSource(value);
				break;
		}
	}
}