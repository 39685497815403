import { TRenderWebComponent } from "../../base/class.web.comps";
import { ComponentProperty } from "../../interfaces/class.web.comps.intf";
import { boolFromStr } from "../../../utils/strings";
import { addID, removeID } from "../../../utils/idutils";

export class TwcCheckListBox extends TRenderWebComponent {
    visible: boolean;
    enabled: boolean;
    selectedIDs: string;
    inputElems: HTMLInputElement[];

    override initComponent(): void {
        super.initComponent();

        this.classtype = 'TwcCheckListBox';
        this.inputElems = Array.from(this.obj.querySelectorAll('input'));
        this.visible = !this.obj.classList.contains('d-none');
        this.enabled = this.inputElems.every((elem) => { return !elem.disabled });
        this.selectedIDs = '';
        this.inputElems.forEach((elem) => {
            if (elem.checked) {
                this.selectedIDs = addID(this.selectedIDs, parseInt(elem.dataset.itemid));
            }
        });
    }

    override initDomElement(): void {
        super.initDomElement();
        this.initClick();
    }

    initClick(): void {
        this.inputElems.forEach((checkbox) => {
            checkbox.addEventListener('input', () => {
                this.notifyComponentChanged();
                this.selectedIDs = checkbox.checked ? addID(this.selectedIDs, parseInt(checkbox.dataset.itemid)) : removeID(this.selectedIDs, parseInt(checkbox.dataset.itemid));
            });
        });
    }

    setItemStatus(itemIDs: string): void {
        let splittedIds = itemIDs.split(';');
        this.inputElems.forEach(function (checkbox) {
            checkbox.checked = splittedIds.includes(checkbox.dataset.itemid);
        });
    }

    override readProperties(): Array<ComponentProperty> {
        let properties = [];
        properties.push([this.id, 'SelectedIDs', this.selectedIDs]);
        return properties;
    }

    override writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.visible = boolFromStr(value);
                break;
            case 'Enabled':
                this.enabled = boolFromStr(value);
                break;
            case 'SelectedIDs':
                this.selectedIDs = value;
                break;
        }
    }

    protected override doRender(timestamp: DOMHighResTimeStamp): void {
        super.doRender(timestamp);

        this.obj.classList.toggle('d-none', !this.visible);
        this.inputElems.forEach(element => {
            element.disabled = !this.enabled;
        });
        this.setItemStatus(this.selectedIDs);
    }
}