import { WebCompEventHandlerAsync } from "../../../core/communication";
import { boolFromStr } from "../../../utils/strings";
import { TWebComponent } from "../../base/class.web.comps";

export class TwcDownloadPicker extends TWebComponent {
    lazyload: boolean;
    filename: string;

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcDownloadPicker';
    }

    override  initDomElement() {
        super.initDomElement();

        // da wir mehrere Links auf dem Downloadpicker haben - setzen wir den Listener auf alle
        let subLinks = this.obj.getElementsByTagName('a');
        for (let i = 0; i < subLinks.length; i++) {
            let element = subLinks.item(i);
            element.addEventListener('click', event => {
                WebCompEventHandlerAsync('onclick', this.id);
            });
        };

    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', !boolFromStr(value));
                break;
        }
    }
}
