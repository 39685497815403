import { TWebComponent } from '../../base/class.web.comps';

export class TwcMetric extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcMetric';
    }

    writeProperties(key: string, value: string): void {
        switch (key) {

            case 'Enabled':
                // UPS // Div hat kein disabled
                // this.obj.disabled = value == '0';
                break;
            case 'Readonly':
                if (value == '1') {
                    $(this.obj).prop('readonly', true);
                } else if (value == '0') {
                    $(this.obj).prop('readonly', false);
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }
    }
}

export class TwcMetricButton extends TWebComponent {

    constructor(obj: HTMLElement) {
        super(obj);
        this.classtype = 'TwcMetricButton';
    }

    writeProperties(key: string, value: string):void {
        switch (key) {

            case 'Enabled':
                // UPS // Div hat kein disabled
                // this.obj.disabled = value == '0';
                break;
            case 'Readonly':
                if (value == '1') {
                    $(this.obj).prop('readonly', true);
                } else if (value == '0') {
                    $(this.obj).prop('readonly', false);
                }
                break;
            case 'Visible':
                this.obj.classList.toggle('d-none', value == '0')
                break;
        }

    }
}
