﻿import { assigned } from '../../../utils/helper';
import { TWebComponent } from '../../base/class.web.comps';

export class TwcButtonToolbar extends TWebComponent {

    override initComponent() {
        super.initComponent();
        this.classtype = 'TwcButtonToolbar';
    }
    override initDomElement() {
        super.initDomElement();

        this.refreshButtonList();
    }

    refreshButtonList(): void {
        let btnGroups = this.obj.querySelectorAll('.btn-group');
        // wir setzen auf den letztem sichtbaren button eine eigene Klasse, die die buttons-Border-radius richtig setzt
        for (let i = 0; i < btnGroups.length; i++) {
            // erstmal alle markierten löschen
            let lastvisiblebutton = btnGroups[i].querySelector('.lastvisiblebutton');
            if (assigned(lastvisiblebutton)) {
                lastvisiblebutton.classList.remove('lastvisiblebutton');
            }
            // nun holen wir uns alle ohne d-none + dropdown-item und setzen auf den letzen unsere Klasse
            let visibleButtons = btnGroups[i].querySelectorAll('button:not(.d-none,.dropdown-item)');
            if (visibleButtons.length > 0) {
                var lastVisibleButton = visibleButtons[visibleButtons.length - 1];
                lastVisibleButton.classList.add('lastvisiblebutton');
            }
        }
    }

    writeProperties(key: string, value: string): void {
        switch (key) {
            case 'Visible':
                this.obj.classList.toggle('d-none', value === '0');
                break;
        }
    }
}

