import { TwsProcessEditorCustom } from '../class.web.comp.process.editor.custom';
import { getActionByTypeID } from '../Utils/TProcessEditorActionHandling';
import { ActionType, TCustomAction } from './TCustomAction';

export class TCustomActionGroup extends TCustomAction<any> {
    actions: Array<TCustomAction<any>>;

    // wir gehen hier von null aus für das refElement
    constructor(refElement: any, editor: TwsProcessEditorCustom) {
        super(refElement, editor);

        this.actions = [];
    }

    typeID(): ActionType {
        return ActionType.patGroupAction;
    }

    override fromJSON(obj: any) {
        super.fromJSON(obj);

        obj.actions.forEach(action => {
            let actionObj = getActionByTypeID(action.actionType, this.svgEditor.objectById(action.refElementID), this.svgEditor);
            actionObj.fromJSON(action);
            this.actions.push(actionObj);
        });
    }

    override toJSON(): any {
        let obj = super.toJSON();

        obj.actions = [];
        this.actions.forEach(action => {
            obj.actions.push(action.toJSON());
        });

        return obj;
    }

    setValue(...args: Array<TCustomAction<any>>): void {
        this.actions = args;
    }

    performAction(): void {
        this.actions.forEach(action => {
            action.performAction();
        });
    }

    inverseAction(): TCustomAction<any> {
        let result = new TCustomActionGroup(this.refElement, this.svgEditor);
        let inverseActions: Array<TCustomAction<any>> = [];

        [...this.actions].reverse().forEach(action => {
            inverseActions.push(action.inverseAction());
        });

        result.setValue(...inverseActions);
        return result;
    }
}